import {useCallback, useEffect, useMemo, useState} from 'react';
import authTokensStore from '../utils/auth/authTokensStore';
import useLoadingCallback from './useLoadingCallback';

export type OnTokensUpdate = (
  accessToken: string,
  refreshToken: string,
) => Promise<void>;
export type OnTokensRemove = () => Promise<void>;

const usePersistedAuthTokens = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);

  const {
    handleCallback: updateAccessToken,
    isLoading: accessTokenLoading,
    reset: resetAccessToken,
  } = useLoadingCallback(
    async (newAccessToken: string | null) => {
      await authTokensStore.updateAccessToken(newAccessToken);
      setAccessToken(newAccessToken);
    },
    {loading: true},
  );

  const {
    handleCallback: updateRefreshToken,
    isLoading: refreshTokenLoading,
    reset: resetRefreshToken,
  } = useLoadingCallback(
    async (newRefreshToken: string | null) => {
      await authTokensStore.updateRefreshToken(newRefreshToken);
      setRefreshToken(newRefreshToken);
    },
    {loading: true},
  );

  const updateTokens = useCallback<OnTokensUpdate>(
    async (newAccessToken, newRefreshToken) => {
      await updateAccessToken(newAccessToken);
      await updateRefreshToken(newRefreshToken);
    },
    [updateAccessToken, updateRefreshToken],
  );

  const removeTokens = useCallback<OnTokensRemove>(async () => {
    await authTokensStore.removeTokens();
    setAccessToken(null);
    setRefreshToken(null);

    resetAccessToken();
    resetRefreshToken();
  }, [resetAccessToken, resetRefreshToken]);

  useEffect(() => {
    (async () => {
      const token = await authTokensStore.getAccessToken();
      await updateAccessToken(token);
    })();
    (async () => {
      const token = await authTokensStore.getRefreshToken();
      await updateRefreshToken(token);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => ({
      accessToken,
      refreshToken,
      updateTokens,
      removeTokens,
      accessTokenLoading,
      refreshTokenLoading,
    }),
    [
      accessToken,
      accessTokenLoading,
      refreshToken,
      refreshTokenLoading,
      removeTokens,
      updateTokens,
    ],
  );
};

export default usePersistedAuthTokens;
