import React, {useCallback, useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import Background from '../components/Background/Background';
import {useModalContext} from '../contexts/ModalContext';
import useShowNoSubscriptionsOverlay from '../mindance-libs/hooks/useShowNoSubscriptionsOverlay';
import {useAppContext} from '../mindance-libs/store/contexts/AppContext';
import {useInfoOverlaysContext} from '../mindance-libs/store/contexts/InfoOverlaysContext';
import {RouteKeys} from '../navigation/RouteKeys';
import useTypedNavigate from '../navigation/typed/useTypedNavigate';
import {handleInfoOverlayScreenFocus} from '../utils/infoOverlays';
import {MODAL_SCREENS} from '../utils/modal';
import styles from './layout.module.scss';

export default function RootLayout() {
  const {modalType} = useModalContext();
  const {infoOverlays, loading, showInfoOverlay} = useInfoOverlaysContext();
  const {isLoading, isLoggedIn, userSubscriptions} = useAppContext();
  const {pathname} = useLocation();
  const navigate = useTypedNavigate();

  const openRedeemActivationCodeScreen = useCallback(() => {
    navigate({
      to: RouteKeys.SettingsRedeemActivationCode,
      params: {},
    });
  }, [navigate]);

  const showNoSubscriptionsOverlay = useShowNoSubscriptionsOverlay({
    onSubmit: openRedeemActivationCodeScreen,
  });

  useEffect(() => {
    if (loading) {
      return;
    }

    handleInfoOverlayScreenFocus({
      infoOverlays,
      onInfoOverlay: showInfoOverlay,
      route: pathname,
    });
  }, [infoOverlays, loading, pathname, showInfoOverlay]);

  useEffect(() => {
    if (isLoading || !isLoggedIn || userSubscriptions.length > 0) {
      return;
    }

    showNoSubscriptionsOverlay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isLoggedIn, userSubscriptions.length]);

  return (
    <div className={styles.container}>
      <Outlet />
      {modalType && MODAL_SCREENS[modalType] && (
        <div className={styles.modal}>
          <Background>{MODAL_SCREENS[modalType]}</Background>
        </div>
      )}
    </div>
  );
}
