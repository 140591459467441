export const removeTags = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  let text = '';

  if (doc.body && doc.body.childNodes) {
    for (let i = 0; i < doc.body.childNodes.length; i++) {
      const node = doc.body.childNodes[i];
      if (node.nodeName.toLowerCase() === 'p') {
        text += node.textContent + '\n'; // Add new line after each <p>
      }
    }
  }

  return text.trim(); // Remove trailing newline
};
