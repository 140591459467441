import React, {forwardRef, useCallback, useMemo} from 'react';
import {StyleProp, TextStyle} from 'react-native';
import {TextInput, TextInputProps} from 'react-native-paper';
import useBooleanValue from '../../hooks/useBooleanValue';
import AlertIcon from '../../icons/AlertIcon';
import EyeOff from '../../icons/EyeOff';
import EyeOn from '../../icons/EyeOn';
import colors from '../../theme/colors';
import {MAX_FONT_SIZE_MULTIPLIER} from '../../utils/constants';
import styles from './styles';

type Props = Omit<TextInputProps, 'label' | 'placeholder'> & {
  label?: string | null | undefined;
  placeholder?: string | null | undefined;
  errorColor?: string;
  isPassword?: boolean;
  showLabel?: boolean;
  right?: React.ReactNode;
  isRequired?: boolean;
};

const AlertIconComponent = () => <AlertIcon height={20} width={20} />;

const AppInput: React.FC<Props> = forwardRef(
  (
    {
      mode = 'outlined',
      label,
      error,
      errorColor = colors.alert,
      autoFocus,
      onBlur,
      onFocus,
      placeholder,
      value,
      isPassword,
      style,
      showLabel = false,
      right,
      isRequired = false,
      ...props
    },
    ref,
  ) => {
    const {
      value: isFocused,
      setTrue: addFocus,
      setFalse: removeFocus,
    } = useBooleanValue(autoFocus);

    const {
      value: isSecure,
      toggleValue: togglePasswordDisplayed,
      setTrue: hidePassword,
    } = useBooleanValue(isPassword);

    const formattedLabel = isRequired && label ? `${label} *` : label;

    const handleFocus = useCallback<NonNullable<TextInputProps['onFocus']>>(
      args => {
        addFocus();
        onFocus?.(args);
      },
      [onFocus, addFocus],
    );

    const handleBlur = useCallback<NonNullable<TextInputProps['onBlur']>>(
      args => {
        if (isPassword && !isSecure) {
          hidePassword();
        }
        removeFocus();
        onBlur?.(args);
      },
      [hidePassword, isPassword, isSecure, onBlur, removeFocus],
    );

    const ErrorPostfix = useMemo(
      () => (
        <TextInput.Icon icon={AlertIconComponent} style={styles.inputIcon} />
      ),
      [],
    );

    const EyeOnComponent = useCallback(
      () => (
        <EyeOn
          height={14}
          width={14}
          fill={isFocused ? colors.white : colors.whiteAlpha40}
        />
      ),
      [isFocused],
    );

    const EyeOffComponent = useCallback(
      () => (
        <EyeOff
          height={14}
          width={14}
          fill={isFocused ? colors.white : colors.whiteAlpha40}
        />
      ),
      [isFocused],
    );

    const PasswordPostfix = useMemo(
      () => (
        <TextInput.Icon
          icon={isSecure ? EyeOnComponent : EyeOffComponent}
          onPress={togglePasswordDisplayed}
          style={styles.inputIcon}
        />
      ),
      [EyeOffComponent, EyeOnComponent, isSecure, togglePasswordDisplayed],
    );

    const memoizedStyle = useMemo<StyleProp<TextStyle>>(
      () => [styles.container, style],
      [style],
    );

    return (
      <TextInput
        ref={ref}
        mode={mode}
        value={value}
        outlineColor={error ? errorColor : colors.whiteAlpha40}
        activeOutlineColor={error ? errorColor : colors.white}
        right={
          right
            ? right
            : error
            ? ErrorPostfix
            : isPassword
            ? PasswordPostfix
            : undefined
        }
        style={memoizedStyle}
        onFocus={handleFocus}
        onBlur={handleBlur}
        label={
          formattedLabel && ((!isFocused && !value) || showLabel)
            ? formattedLabel
            : undefined
        }
        autoFocus={autoFocus}
        placeholder={placeholder ?? undefined}
        textColor={colors.white}
        secureTextEntry={isSecure}
        placeholderTextColor={colors.whiteAlpha40}
        outlineStyle={styles.outline}
        maxFontSizeMultiplier={MAX_FONT_SIZE_MULTIPLIER}
        {...props}
      />
    );
  },
);

export default React.memo(AppInput);
