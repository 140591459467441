import {StyleSheet, ViewStyle} from 'react-native';
import colors from '../../theme/colors';
import spacings, {
  DEFAULT_BORDER_RADIUS,
  SMALL_BORDER_RADIUS,
} from '../../theme/spacings';
import typography from '../../theme/typography';

const elementWrapper: ViewStyle = {
  backgroundColor: colors.black95Alpha50,
  borderRadius: DEFAULT_BORDER_RADIUS,
  alignItems: 'center',
  justifyContent: 'center',
  paddingHorizontal: spacings.xs,
  paddingVertical: spacings.xxs,
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    borderRadius: SMALL_BORDER_RADIUS,
    paddingHorizontal: spacings.m,
    paddingTop: spacings.m,
    paddingBottom: spacings.l,
    justifyContent: 'space-between',
  },
  timeContainer: {
    ...elementWrapper,
    alignSelf: 'flex-start',
    flexDirection: 'row',
  },
  timingViewAppText: {
    ...typography.footnoteDemiBold,
    color: colors.whiteAlpha95,
    marginLeft: spacings.xxxxs,
  },
  calendarImage: {
    width: 12,
    height: 12,
    tintColor: colors.white,
  },
  title: {
    ...typography.bodyDemiBold,
    color: colors.whiteAlpha95,
    marginTop: spacings.m,
  },
});

export default styles;
