import React from 'react';
import {Link, LinkProps} from 'react-router-dom';
import {PathParams} from '../NavigationTypes';
import {RouteKeys} from '../RouteKeys';
import {buildUrl} from '../utils';

type TypedLinkProps<P extends RouteKeys> = LinkProps & {
  to: P;
  params?: PathParams<P>;
};

/**
 * Type-safe version of `react-router-dom/Link`.
 */
const TypedLink = <P extends RouteKeys>({
  to,
  params,
  children,
  ...props
}: TypedLinkProps<P>) => {
  return (
    <Link to={params ? buildUrl(to, params) : to} {...props}>
      {children}
    </Link>
  );
};

export default TypedLink;
