import React from 'react';
import Svg, {Ellipse, Path, SvgProps} from 'react-native-svg';
import colors from '../theme/colors';

const FilterIcon: React.FC<SvgProps> = ({...props}) => {
  return (
    <Svg
      stroke={colors.white}
      width="20px"
      height="20px"
      strokeWidth={1.5}
      strokeLinecap="round"
      viewBox="0 0 24 24"
      {...props}>
      <Path d="M10 8L20 8" />
      <Path d="M4 16L14 16" />
      <Ellipse
        cx={7}
        cy={8}
        rx={3}
        ry={3}
        transform="rotate(90 7 8)"
        fill="transparent"
      />
      <Ellipse
        cx={17}
        cy={16}
        rx={3}
        ry={3}
        transform="rotate(90 17 16)"
        fill="transparent"
      />
    </Svg>
  );
};

export default React.memo(FilterIcon);
