import {Platform, StyleSheet} from 'react-native';
import spacings, {DEFAULT_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  button: {
    justifyContent: 'center',
    alignSelf: 'stretch',
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
  buttonContentForNextButton: {
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: spacings.xxs,
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
  buttonContentForBackButton: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: spacings.xxs,
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
  label: {
    ...typography.bodyBold,
  },
  icon: {
    marginTop: Platform.select({
      web: 0,
      android: spacings.xxxs,
      ios: spacings.xxxs,
    }),
  },
});

export default styles;
