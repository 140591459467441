import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {SMALL_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';

const SIZE = 14;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: SMALL_BORDER_RADIUS,
    paddingVertical: spacings.xs,
    paddingHorizontal: spacings.s,
  },
  label: {
    ...typography.input,
    color: colors.white,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    width: SIZE,
    height: SIZE,
    marginLeft: spacings.xxs,
    marginTop: Platform.OS === 'web' ? spacings.xxxs : 0,
  },
});

export default styles;
