import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import Lottie from 'react-lottie';
import {TextStyle, ViewStyle} from 'react-native';
import Button from '../../components/Button/Button';
import {ButtonStyle} from '../../components/Button/Button.props';
import errorAnimation from '../../mindance-libs/assets/animations/error_animation.json';
import ContactUs, {
  TitleVariants,
} from '../../mindance-libs/components/ContactUs';
import ScreenBase from '../../mindance-libs/components/ScreenBase';
import colors from '../../mindance-libs/theme/colors';
import spacings from '../../mindance-libs/theme/spacings';
import useTypedNavigate from '../../navigation/typed/useTypedNavigate';
import styles from './page.module.scss';

const defaultAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: errorAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const contactUsStyle: ViewStyle = {
  alignItems: 'center',
  marginTop: spacings.xl,
};
const contactUsTextStyle: TextStyle = {color: colors.white};

export default function Page() {
  const {t} = useTranslation();
  const navigate = useTypedNavigate();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <ScreenBase>
      <div className={styles['not-found__container']}>
        <Button
          showLeftArrow
          button={{title: t('common.return') || '', onClick: goBack}}
          className={styles['not-found__back']}
          buttonStyle={ButtonStyle.INLINE_WHITE}
        />

        <div className={styles['not-found__content']}>
          <div className={styles['not-found__image']}>
            <Lottie
              options={defaultAnimationOptions}
              width={'100%'}
              height={'100%'}
            />
          </div>

          <h1 className={styles['not-found__title']}>
            {t('errors.maybe_take_a_breath')}
          </h1>
          <h2 className={styles['not-found__subtitle']}>
            {t('errors.something_went_wrong')}
          </h2>
        </div>
        <ContactUs
          variant={TitleVariants.STILL_HAVE_PROBLEM}
          style={contactUsStyle}
          textStyle={contactUsTextStyle}
        />
      </div>
    </ScreenBase>
  );
}
