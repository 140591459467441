import Lottie from 'lottie-react-native';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../../components/Button/Button';
import {ButtonStyle} from '../../../../../../components/Button/Button.props';
import RoundBackButton from '../../../../../../mindance-libs/components/RoundBackButton';
import ScreenBase from '../../../../../../mindance-libs/components/ScreenBase';
import colors from '../../../../../../mindance-libs/theme/colors';
import useTypedNavigate from '../../../../../../navigation/typed/useTypedNavigate';
import styles from './page.module.scss';

export default function Page() {
  const {t} = useTranslation();

  const navigate = useTypedNavigate();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const button = useMemo(() => ({onClick: goBack}), [goBack]);

  return (
    <ScreenBase>
      <div className={styles['booking-success__container']}>
        <RoundBackButton
          size={40}
          borderColor={colors.whiteAlpha50}
          iconColor={colors.white}
          iconSize={30}
          onPress={goBack}
        />

        <div className={styles['booking-success__content']}>
          <Lottie
            source={require('../../../../../../mindance-libs/assets/animations/sent_email_animation.json')}
            autoPlay
            loop
            style={{width: 160, height: 160}}
          />
          <h1>{t('booking.applied_successfully')}</h1>
          <p>{t('booking.you_will_receive_registration_email')}</p>
          <Button
            button={button}
            buttonStyle={ButtonStyle.SECONDARY}
            className={styles['booking-success__content__button']}>
            {t('booking.return_to_overview')}
          </Button>
        </div>
      </div>
    </ScreenBase>
  );
}
