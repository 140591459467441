import {BlurView} from '@react-native-community/blur';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Image, TouchableOpacity, View, ViewStyle} from 'react-native';
import HandHeartIcon from '../../icons/HandHeartIcon';
import NextArrowIcon from '../../icons/NextArrowIcon';
import colors from '../../theme/colors';
import AppText from '../AppText';
import styles from './styles';

type Props = {
  onPress?: () => void;
  style?: ViewStyle;
};

const ExpertlineConnection: React.FC<Props> = ({onPress, style}) => {
  const {t} = useTranslation();
  const containerStyle = useMemo(() => [styles.container, style], [style]);

  return (
    <View style={containerStyle}>
      <Image
        source={require('../../assets/images/psychologist_onboarding.jpg')}
        style={styles.psychologistImage}
      />
      <View>
        <TouchableOpacity
          onPress={onPress}
          activeOpacity={0.8}
          style={styles.messageContainer}>
          <View style={styles.header}>
            <HandHeartIcon />
            <AppText style={styles.headerText}>
              {t('expertline.psychological_counseling')}
            </AppText>
          </View>
          <AppText style={styles.subtitleText}>
            {t('expertline.support_needed')}
          </AppText>
          <AppText style={styles.subtitleText}>
            {t('expertline.available_24_7')}
          </AppText>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={onPress}
          style={styles.button}
          activeOpacity={0.8}>
          <BlurView blurType="light" style={styles.blur} />
          <NextArrowIcon
            width={16}
            height={16}
            stroke={colors.white}
            style={styles.arrow}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default React.memo(ExpertlineConnection);
