import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, TouchableHighlight, ViewStyle} from 'react-native';
import FilterIcon from '../../icons/FilterIcon';
import colors from '../../theme/colors';
import AppText from '../AppText';
import styles from './styles';

type Props = {
  selectedFiltersNumber?: number;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  iconPosition?: 'left' | 'right';
};

const ExtendedFilterButton: React.FC<Props> = ({
  selectedFiltersNumber,
  onPress,
  style,
  testID,
  iconPosition = 'left',
}) => {
  const {t} = useTranslation();

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, style],
    [style],
  );

  const label =
    selectedFiltersNumber && selectedFiltersNumber > 0
      ? t('common.filter') + ` (${selectedFiltersNumber})`
      : t('common.filter');

  return (
    <TouchableHighlight
      style={containerStyle}
      onPress={onPress}
      underlayColor={colors.whiteAlpha20}
      testID={testID}>
      <>
        {iconPosition === 'left' ? (
          <FilterIcon height={14} width={14} style={styles.icon} />
        ) : null}
        <AppText style={styles.label}>{label}</AppText>
        {iconPosition === 'right' ? (
          <FilterIcon height={14} width={14} style={styles.icon} />
        ) : null}
      </>
    </TouchableHighlight>
  );
};

export default React.memo(ExtendedFilterButton);
