import React, {useCallback, useMemo, useState} from 'react';
import {
  GestureResponderEvent,
  TouchableHighlight,
  TouchableHighlightProps,
} from 'react-native';
import PauseIcon from '../../icons/PauseIcon';
import PlayIcon from '../../icons/PlayIcon';
import colors from '../../theme/colors';
import styles, {PLAY_BUTTON_SIZE} from './styles';

type Props = TouchableHighlightProps & {
  size?: number;
  playing: boolean;
};

const AVPlayButton: React.FC<Props> = ({
  style,
  playing,
  onPress,
  onPressIn,
  onPressOut,
  size = PLAY_BUTTON_SIZE,
  ...props
}) => {
  const [scale, setScale] = useState(1);

  const iconSize = 0.4 * size;

  const animatedViewStyle = useMemo(
    () => [
      styles.container,
      {
        width: size,
        height: size,
        borderRadius: size,
      },
      style,
      {transform: [{scale}]},
    ],
    [scale, size, style],
  );

  const handlePressIn = useCallback<(event: GestureResponderEvent) => void>(
    event => {
      onPressIn?.(event);
      setScale(0.95);
    },
    [onPressIn],
  );

  const handlePressOut = useCallback<(event: GestureResponderEvent) => void>(
    event => {
      onPressOut?.(event);
      setScale(1);
    },
    [onPressOut],
  );

  return (
    <TouchableHighlight
      style={animatedViewStyle}
      underlayColor={colors.black95Alpha60}
      onPress={onPress}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      {...props}>
      {playing ? (
        <PauseIcon
          fill={colors.white}
          width={iconSize - 4}
          height={iconSize - 4}
        />
      ) : (
        <PlayIcon fill={colors.white} width={iconSize} height={iconSize} />
      )}
    </TouchableHighlight>
  );
};

export default React.memo(AVPlayButton);
