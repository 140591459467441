import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
import colors from '../theme/colors';

const GlobalIcon: React.FC<SvgProps> = ({...props}) => {
  return (
    <Svg
      fill={colors.white}
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      {...props}>
      <Path d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.34591 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34872 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM17.2058 5.83333H14.5217C13.9208 4.44082 13.1297 3.1384 12.1708 1.96333C14.2929 2.54077 16.1019 3.93123 17.2058 5.83333ZM13.75 10C13.7432 10.8485 13.6095 11.6911 13.3533 12.5H6.64667C6.39055 11.6911 6.25685 10.8485 6.25 10C6.25685 9.15155 6.39055 8.3089 6.64667 7.5H13.3533C13.6095 8.3089 13.7432 9.15155 13.75 10ZM7.315 14.1667H12.685C11.9777 15.5631 11.0735 16.8507 10 17.99C8.92615 16.851 8.02187 15.5634 7.315 14.1667ZM7.315 5.83333C8.02231 4.43689 8.92654 3.1493 10 2.01C11.0739 3.14898 11.9781 4.43663 12.685 5.83333H7.315ZM7.83334 1.96333C6.87305 3.13815 6.08049 4.44058 5.47834 5.83333H2.79417C3.89905 3.93036 5.70972 2.5398 7.83334 1.96333ZM2.05084 7.5H4.91667C4.70064 8.31595 4.58864 9.15595 4.58334 10C4.58864 10.8441 4.70064 11.684 4.91667 12.5H2.05084C1.53862 10.8727 1.53862 9.12731 2.05084 7.5ZM2.79417 14.1667H5.47834C6.08049 15.5594 6.87305 16.8618 7.83334 18.0367C5.70972 17.4602 3.89905 16.0696 2.79417 14.1667ZM12.1708 18.0367C13.1297 16.8616 13.9208 15.5592 14.5217 14.1667H17.2058C16.1019 16.0688 14.2929 17.4592 12.1708 18.0367ZM17.9492 12.5H15.0833C15.2994 11.684 15.4114 10.8441 15.4167 10C15.4114 9.15595 15.2994 8.31595 15.0833 7.5H17.9475C18.4597 9.12731 18.4597 10.8727 17.9475 12.5H17.9492Z" />
    </Svg>
  );
};

export default React.memo(GlobalIcon);
