import React, {forwardRef, useCallback, useImperativeHandle} from 'react';
import {useTranslation} from 'react-i18next';
import {ListRenderItem} from 'react-native';
import {
  BaseBookingFragment,
  useFindManyBookingsV3Query,
} from '../../generated/graphql';
import useBookingQueryVariables from '../../hooks/useBookingQueryVariables';
import useListQuery from '../../hooks/useListQuery';
import {BookingItemType} from '../../types/booking';
import {
  HOME_SCREEN_LIST_ITEMS_LIMIT,
  HOME_SCREEN_LIST_ITEMS_TAKE_NUMBER,
  SCREEN_WIDTH,
} from '../../utils/constants';
import {getImageUrl} from '../../utils/files';
import EventHomeCard from '../EventHomeCard';
import HomeCardsCarousel, {HomeCardsCarouselProps} from '../HomeCardsCarousel';
import styles from './styles';

type Props = Partial<HomeCardsCarouselProps<BaseBookingFragment>> & {
  onItemPress: (itemId: BaseBookingFragment['id'], language: string) => void;
};

export type EventsHomeSectionRef = {
  refresh: () => Promise<void>;
};

const IMAGE_ASPECT_RATIO = 0.82;

const EventsHomeSection = forwardRef(
  (
    {
      testID,
      onItemPress,
      itemWidth = SCREEN_WIDTH * 0.5,
      tagsIds,
      ...props
    }: Props,
    ref,
  ) => {
    const {t} = useTranslation();

    const getBookingQueryVariables = useBookingQueryVariables({
      selectedCategory: BookingItemType.EVENT,
      selectedTags: tagsIds,
    });

    const {data, loading, error, fetchNext, refetchWithParams} = useListQuery(
      useFindManyBookingsV3Query,
      getBookingQueryVariables,
      'findManyBookingsV3',
      {
        take: HOME_SCREEN_LIST_ITEMS_TAKE_NUMBER,
        limit: HOME_SCREEN_LIST_ITEMS_LIMIT,
      },
    );

    const renderItem = useCallback<ListRenderItem<BaseBookingFragment>>(
      ({item}) => (
        <EventHomeCard
          {...item}
          onPress={onItemPress}
          imageUrl={getImageUrl(item.directus_files, {
            width: itemWidth,
            aspectRatio: IMAGE_ASPECT_RATIO,
          })}
          width={itemWidth}
          aspectRatio={IMAGE_ASPECT_RATIO}
          testID={`${testID}.eventHomeCard`}
        />
      ),
      [itemWidth, testID, onItemPress],
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          async refresh() {
            await refetchWithParams({force_refresh: true});
          },
        };
      },
      [refetchWithParams],
    );

    return (
      <HomeCardsCarousel
        {...props}
        title={t('home.events_you_might_like')}
        subtitle={t('home.specialist_lectures')}
        testID={testID}
        data={data}
        loading={loading}
        error={error}
        renderItem={renderItem}
        itemWidth={itemWidth}
        itemAspectRatio={IMAGE_ASPECT_RATIO}
        footerItemStyle={styles.item}
        emptyLabel={t('home.all_your_events_are_done')}
        onEndReached={fetchNext}
      />
    );
  },
);

export default React.memo(EventsHomeSection);
