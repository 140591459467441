import addDays from 'date-fns/addDays';
import {TFunction} from 'i18next';
import * as R from 'ramda';
import {
  AudioUnits,
  BaseCourseFragment,
  BaseCourseResponseFragment,
  BaseSessionFragment,
  BaseSessionResponseFragment,
  InputMaybe,
  Maybe,
  NoteUnits,
  ProgressReportsListRelationFilter,
  SessionUnitsFragment,
  TextUnits,
  VideoUnits,
} from '../generated/graphql';
import {GeneralUnits, SessionUnits, UnitRuleToInsert} from '../types/courses';
import {BaseLibraryItem, LibraryItemType} from '../types/library';

export const isTextUnits = (unit: any): unit is TextUnits => {
  return unit.__typename === 'TextUnits';
};

export const isAudioUnits = (unit: any): unit is AudioUnits => {
  return unit.__typename === 'AudioUnits';
};

export const isVideoUnits = (unit: any): unit is VideoUnits => {
  return unit.__typename === 'VideoUnits';
};

export const isNoteUnits = (unit: any): unit is NoteUnits => {
  return unit.__typename === 'NoteUnits';
};

export const isGeneralUnits = (unit: any): unit is GeneralUnits => {
  return unit.__typename === 'GeneralUnits';
};

type Typenames = NonNullable<SessionUnits['__typename']>;
const availableTypenames: Typenames[] = [
  'AudioUnits',
  'VideoUnits',
  'TextUnits',
  'NoteUnits',
];

export const getSessionUnitsList = (
  sessionUnits: SessionUnitsFragment['Sessions_Units'] | null | undefined,
  {unitsRules}: {unitsRules: UnitRuleToInsert[]},
) => {
  const extractedUnits =
    sessionUnits?.map(sessionUnit => {
      const filledUnits: SessionUnits[] = R.pipe(
        R.pickBy<NonNullable<SessionUnitsFragment['Sessions_Units']>[number]>(
          (value, key) =>
            R.isNotNil(value) && R.includes(key, availableTypenames),
        ),
        R.values,
      )(sessionUnit);
      return filledUnits;
    }) ?? [];

  const units = R.flatten(extractedUnits);

  const extendedUnits = unitsRules.reduce<SessionUnits[]>(
    (updatedUnits, {rule, unit}) => {
      const indexToInsert = rule(updatedUnits);

      if (indexToInsert < 0) {
        return updatedUnits;
      }

      return R.insert(indexToInsert, unit, updatedUnits);
    },
    units,
  );

  return extendedUnits;
};

export const isCourseLibraryItem = (
  item: any,
): item is BaseCourseFragment | BaseCourseResponseFragment => {
  return ['CoursesExtended', 'CoursesResponseExtended'].includes(
    item.__typename,
  );
};

export const isSessionLibraryItem = (
  item: any,
): item is BaseSessionFragment | BaseSessionResponseFragment => {
  return ['SessionsExtended', 'Sessions'].includes(item.__typename);
};

export const getLibraryItemType = (label?: Maybe<string>) => {
  if (!label) {
    return LibraryItemType.MIXED;
  }

  const translationsList: Record<LibraryItemType, string[]> = {
    [LibraryItemType.COURSES]: ['courses', 'course', 'kurse', 'kurs'],
    [LibraryItemType.SESSIONS]: ['sessions', 'session', 'übungen', 'übung'],
    [LibraryItemType.MIXED]: [],
  };

  const itemType =
    R.toPairs(translationsList).find(
      ([_, list]) =>
        !!list.find(listLabel => label.toLowerCase().includes(listLabel)),
    )?.[0] ?? LibraryItemType.MIXED;

  return itemType;
};

export const getLibrariesItemsScreenName =
  (t: TFunction) => (itemType: LibraryItemType) => {
    const screenName = {
      [LibraryItemType.COURSES]: t('library.courses'),
      [LibraryItemType.SESSIONS]: t('library.sessions'),
      [LibraryItemType.MIXED]: t('library.topics_and_formats'),
    }[itemType];

    return screenName;
  };

export const sortLibraryItems = (
  items: BaseLibraryItem[] | undefined,
  itemComparator: (item: BaseLibraryItem) => boolean,
): BaseLibraryItem[] | undefined => {
  if (!items) {
    return;
  }

  const sorted = R.sort((a, b) => {
    const aComputed = itemComparator(a);
    const bComputed = itemComparator(b);

    if (aComputed && !bComputed) {
      return 1;
    }

    if (!aComputed && bComputed) {
      return -1;
    }

    if (a.date_updated && b.date_updated) {
      return a.date_updated > b.date_updated ? -1 : 1;
    }

    return 0;
  }, items);

  return sorted;
};

export const getProgressReportUpperTimestamp = () => {
  const time = addDays(new Date(), 1);

  return time.toISOString();
};

export const getProgressReportCondition = (
  input?: InputMaybe<ProgressReportsListRelationFilter>,
) => {
  const timestampCondition: InputMaybe<ProgressReportsListRelationFilter> = {
    some: {
      Timestamp: {lt: getProgressReportUpperTimestamp()},
    },
  };

  const withoutProgressReportsCondition: InputMaybe<ProgressReportsListRelationFilter> =
    {none: {}};

  const mergedTimestampCondition = R.mergeDeepRight<
    ProgressReportsListRelationFilter,
    ProgressReportsListRelationFilter
  >(timestampCondition, input ?? {}) as ProgressReportsListRelationFilter;

  const mergedWithoutProgressReportsCondition = R.mergeDeepRight<
    ProgressReportsListRelationFilter,
    ProgressReportsListRelationFilter
  >(
    withoutProgressReportsCondition,
    input ?? {},
  ) as ProgressReportsListRelationFilter;

  return [
    {ProgressReports: mergedTimestampCondition},
    {ProgressReports: mergedWithoutProgressReportsCondition},
  ];
};
