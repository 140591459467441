import React, {useEffect, useMemo, useRef} from 'react';
import {ScrollView, StyleProp, View, ViewStyle} from 'react-native';
import AppButton from '../AppButton';
import AppText from '../AppText';
import RoundCloseButton from '../RoundCloseButton';
import {ModalContentProps} from './props';
import styles from './styles';

const ModalContent: React.FC<ModalContentProps> = ({
  onDismiss,
  title,
  children,
  testID,
  submitButtonTitle,
  submitButtonDisabled,
  submitButtonLoading,
  onSubmit,
  buttonStyle,
  contentStyle,
  buttonContentStyle,
  showCloseButton = true,
  showBackIcon,
  showNextIcon,
}) => {
  const scrollViewRef = useRef<ScrollView>(null);

  const mergedContentStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.content, contentStyle],
    [contentStyle],
  );

  const mergedButtonStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.submitButton, buttonStyle],
    [buttonStyle],
  );

  const mergedButtonContentStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.buttonContent, buttonContentStyle],
    [buttonContentStyle],
  );

  useEffect(() => {
    scrollViewRef.current?.flashScrollIndicators();
  }, []);

  return (
    <>
      <View style={styles.controlsWrapper}>
        {title ? <AppText style={styles.title}>{title}</AppText> : <View />}
        {showCloseButton ? (
          <RoundCloseButton
            onPress={onDismiss}
            testID={`${testID}.closeButton`}
          />
        ) : null}
      </View>
      <ScrollView
        ref={scrollViewRef}
        style={styles.scrollView}
        contentContainerStyle={mergedContentStyle}
        showsVerticalScrollIndicator
        indicatorStyle="white"
        persistentScrollbar
        nativeID="modalScrollBarId">
        {children}
      </ScrollView>
      {onSubmit && submitButtonTitle ? (
        <AppButton
          style={mergedButtonStyle}
          onPress={onSubmit}
          disabled={submitButtonDisabled}
          loading={submitButtonLoading}
          contentStyle={mergedButtonContentStyle}
          showNextIcon={showNextIcon}
          showBackIcon={showBackIcon}>
          {submitButtonTitle}
        </AppButton>
      ) : null}
    </>
  );
};

export default React.memo(ModalContent);
