import {ApolloError} from '@apollo/client';
import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useCreateNoteMutation} from '../../generated/graphql';
import {useAppContext} from '../../store/contexts/AppContext';
import {DataStatuses} from '../../types/common';
import {reportError} from '../../utils/loggingHelpers';
import AppText from '../AppText';
import {NoteUnitsSlideProps} from './props';
import RNstyles from './styles';
import styles from './styles.web.module.scss';

const NoteUnitsSlide: React.FC<NoteUnitsSlideProps> = ({
  id: unitId,
  index,
  currentProgress,
  Label,
  testID,
  focused,
  onError,
  onNoteSaved,
}) => {
  const {t} = useTranslation();
  const {user} = useAppContext();

  const [previousFocused, setPreviousFocused] = useState(focused);

  const textareaId = `${unitId}_textarea`;

  const handleError = useCallback<(error: ApolloError) => void>(
    error => {
      if (focused) {
        onError?.(error.message);
      }
    },
    [focused, onError],
  );

  const [createNote] = useCreateNoteMutation({onError: handleError});

  const [note, setNote] = useState('');

  const updateNote = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    ({target: {value}}) => {
      setNote(value);
    },
    [],
  );

  const saveNote = useCallback(async () => {
    try {
      const trimmedNote = note.trim();
      if (trimmedNote && trimmedNote.length > 0) {
        await createNote({
          variables: {
            data: {
              status: DataStatuses.PUBLISHED,
              Description: trimmedNote,
              NoteUnits: {connect: {id: Number(unitId)}},
              Users: {connect: {id: Number(user?.id)}},
              date_created: new Date().toISOString(),
            },
          },
        });
        onNoteSaved?.(unitId, index);
        setNote('');
      }
    } catch (error) {
      reportError('createNote error', error);
    }
  }, [createNote, index, note, onNoteSaved, unitId, user?.id]);

  const updateTextAreaSize = useCallback(() => {
    const textareaElement = document.getElementById(textareaId);

    if (textareaElement) {
      textareaElement.style.height = 'auto';
      textareaElement.style.height = textareaElement.scrollHeight + 'px';
    }
  }, [textareaId]);

  useEffect(() => {
    // save a note on navigating to the next slide
    if (
      !focused &&
      previousFocused &&
      currentProgress &&
      currentProgress > index
    ) {
      saveNote();
    }

    setPreviousFocused(focused);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused]);

  return (
    <div className={styles['note-units-slide']}>
      {Label ? (
        <AppText style={RNstyles.label} testID={`${testID}.label`}>
          {Label}
        </AppText>
      ) : null}
      <textarea
        id={textareaId}
        autoCapitalize="sentences"
        autoCorrect="off"
        spellCheck="false"
        onChange={updateNote}
        onInput={updateTextAreaSize}
        placeholder={t('courses.write_down_your_note') ?? ''}
        className={styles['note-units-slide__textarea']}
        value={note}
      />
    </div>
  );
};

export default React.memo(NoteUnitsSlide);
