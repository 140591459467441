import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, ViewStyle, useWindowDimensions} from 'react-native';
import AppModal from '../../../mindance-libs/components/AppModal';
import AppUsageStatistics from '../../../mindance-libs/components/MyArea/AppUsageStatistics';
import MyInterests from '../../../mindance-libs/components/MyArea/MyInterests';
import MyNotes from '../../../mindance-libs/components/MyArea/MyNotes';
import RoundInfoButton from '../../../mindance-libs/components/RoundInfoButton';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import SettingsIconButton from '../../../mindance-libs/components/SettingsIconButton';
import UserTagsModal from '../../../mindance-libs/components/UserTagsModal';
import useAppModal from '../../../mindance-libs/hooks/useAppModal';
import useHomeInterestsModal from '../../../mindance-libs/hooks/useHomeInterestsModal';
import useMyAreaScreen, {
  INTERESTS_SECTION,
  NOTES_SECTION,
  STATISTICS_SECTION,
} from '../../../mindance-libs/hooks/useMyAreaScreen';
import {useAppContext} from '../../../mindance-libs/store/contexts/AppContext';
import colors from '../../../mindance-libs/theme/colors';
import spacings from '../../../mindance-libs/theme/spacings';
import {
  MyAreaInfoDetails,
  MyAreaSection,
} from '../../../mindance-libs/types/myArea';
import breakpointProvider from '../../../mindance-libs/utils/breakpointProvider';
import {getToFromDates} from '../../../mindance-libs/utils/myArea';
import {RouteKeys} from '../../../navigation/RouteKeys';
import styles from './page.module.scss';
import {sectionContentStyle} from './page.styles';

const TODAY = new Date().toISOString();

export default function Page() {
  const {t} = useTranslation();
  const {width: windowWidth} = useWindowDimensions();

  const datesRange = useMemo(() => {
    const daysRange =
      windowWidth >= breakpointProvider.$gridBreakpoints.xl
        ? 21
        : windowWidth >= breakpointProvider.$gridBreakpoints.lg
        ? 16
        : windowWidth >= breakpointProvider.$gridBreakpoints.md
        ? 12
        : windowWidth >= breakpointProvider.$gridBreakpoints.sm
        ? 8
        : 6;

    return getToFromDates(TODAY, daysRange);
  }, [windowWidth]);

  const {
    note,
    notesError,
    notesLoading,
    statistics,
    statisticsError,
    statisticsLoading,
  } = useMyAreaScreen({
    from: datesRange.fromDate,
    to: datesRange.toDate,
  });

  const {
    hideModal: hideInfoModal,
    isModalVisible: isInfoModalVisible,
    showModal: showInterestsModal,
  } = useAppModal();

  const {homeScreenSetup, screenSetupError} = useAppContext();
  const {
    closeModal: closeInterestsModal,
    isModalVisible: isInterestsModalVisible,
    openInterestsModal,
    selectTag,
    selectedTagsRecord,
    submitTags,
    tagsFilters,
    tags,
    userTags,
    deselectAllTags,
    selectAllTags,
  } = useHomeInterestsModal(
    homeScreenSetup?.GeneralSetting_Home_GeneralSettingToGeneralSetting,
  );

  const [infoDetails, setInfoDetails] = useState<
    MyAreaInfoDetails | undefined
  >();

  const renderSectionTitle = useCallback<
    (section: MyAreaSection) => React.ReactElement
  >(
    ({Icon, title, info}) => {
      const handleInfoPress = () => {
        if (info) {
          setInfoDetails(info);
          showInterestsModal();
        }
      };

      return (
        <div className={styles['my-area__section__title-container']}>
          <Icon fill={colors.whiteAlpha13} width={24} height={24} />
          <h2>{t(title)}</h2>
          {info ? <RoundInfoButton onPress={handleInfoPress} /> : null}
        </div>
      );
    },
    [showInterestsModal, t],
  );

  const StatisticsSectionTitle = useMemo(
    () => renderSectionTitle(STATISTICS_SECTION),
    [renderSectionTitle],
  );
  const NotesSectionTitle = useMemo(
    () => renderSectionTitle(NOTES_SECTION),
    [renderSectionTitle],
  );
  const InterestsSectionTitle = useMemo(
    () => renderSectionTitle(INTERESTS_SECTION),
    [renderSectionTitle],
  );

  const selectedTags = useMemo(
    () => tags.filter(({id}) => userTags.includes(id)),
    [tags, userTags],
  );

  const statisticsStyle = useMemo<StyleProp<ViewStyle>>(
    () =>
      windowWidth >= breakpointProvider.$gridBreakpoints.sm
        ? {}
        : {paddingHorizontal: spacings.xs},
    [windowWidth],
  );

  const closeInfoModal = useCallback(() => {
    hideInfoModal();
    setInfoDetails(undefined);
  }, [hideInfoModal]);

  return (
    <ScreenBase>
      <UserTagsModal
        isVisible={isInterestsModalVisible}
        onDismiss={closeInterestsModal}
        tagGroups={tagsFilters}
        selectedTagsRecord={selectedTagsRecord}
        onTagSelect={selectTag}
        onSubmit={submitTags}
        title={t('home.your_interests')}
        onDeselectAll={deselectAllTags}
        onSelectAll={selectAllTags}
      />

      <AppModal
        onDismiss={closeInfoModal}
        isVisible={isInfoModalVisible}
        title={infoDetails?.title ? t(infoDetails?.title) : undefined}
        useDefaultHeight={false}>
        {infoDetails?.description ? (
          <p className={styles['my-area__modal-description']}>
            {t(infoDetails?.description)}
          </p>
        ) : null}
      </AppModal>

      <div className={styles['my-area__container']}>
        <div className={styles['my-area__header']}>
          <h1>{t('screenTitle.MyAreaScreen')}</h1>
          <SettingsIconButton href={RouteKeys.Settings} />
        </div>

        <div className={styles['my-area__content']}>
          <div className={styles['my-area__section']}>
            {StatisticsSectionTitle}
            <AppUsageStatistics
              appUsage={statistics}
              fromDate={datesRange.fromDate}
              toDate={datesRange.toDate}
              empty={!statistics}
              error={!!statisticsError}
              loading={statisticsLoading}
              style={statisticsStyle}
            />
          </div>
          <div className={styles['my-area__content__row']}>
            <div className={styles['my-area__section']}>
              {NotesSectionTitle}
              <MyNotes
                empty={!note}
                lastDate={note?.dateCreated}
                error={!!notesError}
                loading={notesLoading}
                href={RouteKeys.MyNotes}
                className={styles['my-area__section__content']}
                contentStyle={sectionContentStyle}
              />
            </div>
            <div className={styles['my-area__section']}>
              {InterestsSectionTitle}
              <MyInterests
                tags={selectedTags}
                empty={selectedTags.length === 0}
                error={!!screenSetupError}
                onPress={openInterestsModal}
                style={sectionContentStyle}
              />
            </div>
          </div>
        </div>
      </div>
    </ScreenBase>
  );
}
