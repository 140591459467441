import React, {useEffect, useMemo} from 'react';
import {ControlBottomButtonsProps} from './ControlBottomButtons.props';
import {useTranslation} from 'react-i18next';
import classnames from 'classnames';
import styles from './ControlBottomButtons.module.scss';
import Button from '../Button/Button';
import {ButtonStyle} from '../Button/Button.props';
import classNames from 'classnames';
import {useNavigate} from 'react-router-dom';

const ControlBottomButtons: React.FC<ControlBottomButtonsProps> = ({
  onSubmit,
  onReturn,
  returnButtonLabel,
  submitButtonDisabled = false,
  submitButtonLabel,
  showNextIcon = true,
  showReturnButton = true,
  submitButtonLoading = false,
  className,
  submitLink,
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key !== 'Enter') {
        return;
      }

      if (submitLink) {
        navigate(submitLink);
        return;
      }

      onSubmit?.();
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [navigate, onSubmit, submitLink]);

  const submitButton = useMemo(() => {
    const sbClassName = styles['ControlBottomButtons__submit-button'];

    const props = submitLink
      ? {anchor: {href: submitLink, className: sbClassName}}
      : {button: {onClick: onSubmit, className: sbClassName}};

    return props;
  }, [onSubmit, submitLink]);

  return (
    <div
      className={classnames(styles.ControlBottomButtons__container, className)}>
      {showReturnButton && (
        <div className={styles.ControlBottomButtons__space}>
          <Button
            button={{
              onClick: onReturn,
              className: styles['ControlBottomButtons__return-button'],
            }}
            buttonStyle={ButtonStyle.SECONDARY}>
            {returnButtonLabel ?? t('common.return')}
          </Button>
        </div>
      )}
      <div
        className={classNames(
          styles.ControlBottomButtons__space,
          styles.ControlBottomButtons__space__right,
        )}>
        <Button
          {...submitButton}
          disabled={submitButtonDisabled}
          loading={submitButtonLoading}
          showArrow={showNextIcon}
          buttonStyle={ButtonStyle.SECONDARY}>
          {submitButtonLabel ?? t('common.next')}
        </Button>
      </div>
    </div>
  );
};

export default ControlBottomButtons;
