import React from 'react';
import {RouteKeys} from '../navigation/RouteKeys';
import TermsAndConditions from '../pages/policies/terms-and-conditions/page';
import PrivacyPolicy from '../pages/policies/privacy-policy/page';

export type ModalKeys = RouteKeys.TermsAndConditions | RouteKeys.PrivacyPolicy;

export type OpenModalType = (routeKey: ModalKeys) => void;
export type CloseModalType = () => void;

export const MODAL_SCREENS = {
  [RouteKeys.TermsAndConditions]: <TermsAndConditions modal />,
  [RouteKeys.PrivacyPolicy]: <PrivacyPolicy modal />,
};
