import {Platform} from 'react-native';
import {
  BaseAnimationBuilder,
  EntryExitAnimationFunction,
  LayoutAnimationFunction,
} from 'react-native-reanimated';
import {ReanimatedKeyframe} from 'react-native-reanimated/lib/typescript/reanimated2/layoutReanimation/animationBuilder/Keyframe';

export const getReanimatedLayoutAnimation = (
  animation:
    | BaseAnimationBuilder
    | LayoutAnimationFunction
    | typeof BaseAnimationBuilder,
) => {
  return Platform.OS === 'web' ? undefined : animation;
};

export const getEntryOrExitLayoutAnimation = (
  animation:
    | BaseAnimationBuilder
    | typeof BaseAnimationBuilder
    | EntryExitAnimationFunction
    | ReanimatedKeyframe,
) => {
  return Platform.OS === 'web' ? undefined : animation;
};
