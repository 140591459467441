import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ScrollView, ScrollViewProps, View} from 'react-native';
import FastImage, {Source} from 'react-native-fast-image';
import {
  CoachDetailsFragment,
  Coaches_Translations,
  Maybe,
} from '../../generated/graphql';
import {getCoachName} from '../../utils/booking';
import {getImageUrl} from '../../utils/files';
import AppText from '../AppText';
import styles, {IMAGE_SIZE} from './styles';

type Props = ScrollViewProps & (Maybe<CoachDetailsFragment> | undefined);

const SEMICOLON_DELIMITER = ':';

const EMPTY_COACH_TRANSLATIONS: Coaches_Translations = {
  id: '',
  MainQualification: null,
  Motivation: null,
  Qualifications: null,
};

const CourseCoachDetails: React.FC<Props> = ({
  Firstname: FirstName,
  Lastname: LastName,
  Coaches_translations,
  directus_files,
  testID,
  ...props
}) => {
  const {t} = useTranslation();

  const {MainQualification, Motivation, Qualifications, Topics} =
    Coaches_translations?.[0] ?? EMPTY_COACH_TRANSLATIONS;

  const coachName = getCoachName(FirstName, LastName);

  const imageUrl = getImageUrl(directus_files, {
    aspectRatio: 1,
    width: IMAGE_SIZE,
  });

  const image = useMemo<Source>(
    () =>
      imageUrl
        ? {uri: imageUrl}
        : require('../../assets/images/placeholder.png'),
    [imageUrl],
  );

  const data = useMemo(
    () => [
      {label: Qualifications, title: t('booking.qualification')},
      {label: Topics, title: t('booking.focus')},
      {label: Motivation, title: t('booking.motivation')},
    ],
    [Motivation, Qualifications, Topics, t],
  );

  const PointItems = useMemo(
    () =>
      data.map(({title: pointTitle, label}) =>
        label ? (
          <View
            style={styles.pointWrapper}
            key={label}
            testID={`${testID}.label`}>
            <AppText style={styles.point}>
              {pointTitle}
              {SEMICOLON_DELIMITER}
            </AppText>
            <AppText style={styles.point}>{label}</AppText>
          </View>
        ) : null,
      ),
    [data, testID],
  );

  return (
    <ScrollView showsVerticalScrollIndicator={false} testID={testID} {...props}>
      {coachName ? (
        <View style={styles.coachWrapper}>
          <FastImage
            source={image}
            style={styles.image}
            testID={`${testID}.coachImage`}
          />
          <View style={styles.coachNameWrapper}>
            <AppText style={styles.coachName} testID={`${testID}.coachName`}>
              {coachName}
            </AppText>
            {MainQualification ? (
              <AppText
                style={styles.coachQualification}
                testID={`${testID}.coachQualification`}>
                {MainQualification}
              </AppText>
            ) : null}
          </View>
        </View>
      ) : null}
      {PointItems}
    </ScrollView>
  );
};

export default React.memo(CourseCoachDetails);
