import {Platform, StyleSheet, ViewStyle} from 'react-native';
import colors from '../../theme/colors';
import spacings, {
  DEFAULT_BORDER_RADIUS,
  SMALL_BORDER_RADIUS,
} from '../../theme/spacings';
import typography from '../../theme/typography';

const title = Platform.select({
  default: typography.bodyBold,
  web: typography.bodyDemiBold,
});

const elementWrapper: ViewStyle = {
  backgroundColor: colors.black95Alpha50,
  borderRadius: DEFAULT_BORDER_RADIUS,
  alignItems: 'center',
  justifyContent: 'center',
  paddingHorizontal: spacings.xs,
  paddingVertical: spacings.xxs,
};

const styles = StyleSheet.create({
  container: {},
  image: {
    position: 'absolute',
    borderRadius: SMALL_BORDER_RADIUS,
    backgroundColor: colors.whiteAlpha30,
  },
  content: {
    flex: 1,
    borderRadius: SMALL_BORDER_RADIUS,
  },
  upperContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: spacings.l,
    marginTop: spacings.m,
  },
  groupAndAppointments: {
    flexDirection: 'row',
    gap: spacings.xxxs,
  },
  groupIconView: {
    ...elementWrapper,
  },
  appointmentsLabelContainer: {
    ...elementWrapper,
  },
  appointmentsLabel: {
    ...typography.footnote,
  },
  timeContainer: {
    ...elementWrapper,
  },
  timingViewAppText: {
    ...typography.footnote,
  },
  title: {
    ...title,
    marginTop: spacings.m,
  },
  subtitle: {
    ...typography.caption,
    fontSize: 14,
    color: colors.whiteAlpha80,
    marginTop: spacings.xxs,
  },
  companyItem: {
    alignSelf: 'flex-start',
    marginTop: spacings.s,
  },
});

export default styles;
