import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {SMALL_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  container: {
    padding: Platform.select({web: spacings.xxxl, default: spacings.l}),
    backgroundColor: colors.whiteAlpha09,
    borderWidth: 1,
    borderColor: colors.whiteAlpha13,
    borderRadius: SMALL_BORDER_RADIUS,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    ...typography.input,
    color: colors.whiteAlpha75,
    textAlign: 'center',
  },
});

export default styles;
