import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
import colors from '../theme/colors';

const HandHeartIcon: React.FC<SvgProps> = ({fill = colors.white, ...props}) => {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <Path
        d="M5.691 5.158c-1.02-1.18-1.02-3.094 0-4.274 1.02-1.179 2.674-1.179 3.695 0l.614.713.615-.713c1.02-1.179 2.673-1.179 3.694 0 1.021 1.18 1.021 3.095 0 4.274L10.781 9.24c-.215.25-.496.372-.78.372-.286 0-.567-.122-.782-.372L5.69 5.158zm14.038 7.89c.455.704.323 1.693-.295 2.212l-4.396 3.691c-.812.681-1.791 1.049-2.802 1.049H1.111C.497 20 0 19.434 0 18.734V16.2c0-.7.497-1.266 1.111-1.266H2.39l1.559-1.424c.788-.72 1.767-1.108 2.778-1.108h5.496c.615 0 1.112.566 1.112 1.266 0 .7-.497 1.266-1.112 1.266H9.445c-.306 0-.556.285-.556.633 0 .348.25.633.556.633h4.187l4.156-3.49c.619-.518 1.486-.367 1.941.337zM6.722 14.935h-.031.031z"
        fill={fill}
      />
    </Svg>
  );
};

export default React.memo(HandHeartIcon);
