import {Platform, StyleSheet} from 'react-native';
import {BOOKING_DETAILS_HORIZONTAL_PADDING} from '../../utils/constants';
import spacings from '../../theme/spacings';
import colors from '../../theme/colors';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  container: {},
  description: {
    gap: spacings.xl,
    paddingHorizontal: Platform.select({
      web: 0,
      default: BOOKING_DETAILS_HORIZONTAL_PADDING,
    }),
    paddingVertical: spacings.xxxl,
  },
  point: {
    ...typography.body,
    color: colors.whiteAlpha75,
  },
  pointTitle: {
    ...typography.bodyBold,
    color: colors.whiteAlpha75,
  },
});

export const htmlCustomStyle = {
  body: {
    color: colors.whiteAlpha75,
  },
};

export const htmlBookingInfoStyle = {
  body: {
    fontSize: 12,
  },
};

export default styles;
