import './quill.snow.scss';

import classNames from 'classnames';
import React from 'react';
import ReactQuill from 'react-quill';
import styles from './HTMLEditor.module.scss';
import HTMLEditorProps from './HTMLEditor.props';

const MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{list: 'ordered'}, {list: 'bullet'}],
    ['link'],
  ],
};

const HTMLEditor: React.FC<HTMLEditorProps> = ({
  id,
  onValueChange,
  value,
  placeholder,
  onFocus,
  onBlur,
  isFocused,
  className,
  readOnly,
  backgroundColor,
  borderColor,
}) => {
  const mergedStyle = classNames(styles['html-editor'], className);

  const elementById = id ? document.getElementById(id) : null;
  const parentElement = elementById ?? document;

  const toolbars = parentElement.getElementsByClassName('ql-toolbar ql-snow');
  for (let i = 0; i < toolbars?.length; i++) {
    if (!toolbars[i]) {
      break;
    }
    const toolbar = toolbars[i] as HTMLElement;

    const focusedStyle = styles['html-editor__toolbar--focused'];
    isFocused
      ? toolbar.classList.add(focusedStyle)
      : toolbar.classList.remove(focusedStyle);

    toolbar.style.backgroundColor = backgroundColor ?? '';
    toolbar.style.borderColor = borderColor ?? '';
  }

  const editorContainers = parentElement.getElementsByClassName(
    'ql-container ql-snow',
  );
  for (let i = 0; i < editorContainers?.length; i++) {
    if (!editorContainers[i]) {
      break;
    }

    const editorContainer = editorContainers[i] as HTMLElement;

    const focusedStyle = styles['html-editor__editor--focused'];
    isFocused
      ? editorContainers[i].classList.add(focusedStyle)
      : editorContainers[i].classList.remove(focusedStyle);

    editorContainer.style.backgroundColor = backgroundColor ?? '';
    editorContainer.style.borderColor = borderColor ?? '';
  }

  return (
    <ReactQuill
      id={id}
      theme="snow"
      value={value}
      onChange={onValueChange}
      className={mergedStyle}
      placeholder={placeholder ?? undefined}
      onFocus={!readOnly ? onFocus : undefined}
      onBlur={!readOnly ? onBlur : undefined}
      modules={MODULES}
      readOnly={readOnly}
    />
  );
};

export default React.memo(HTMLEditor);
