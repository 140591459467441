import format from 'date-fns/format';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  LayoutAnimation,
  Platform,
  StyleProp,
  TouchableHighlight,
  View,
  ViewStyle,
} from 'react-native';
import colors from '../../theme/colors';
import {AppNote} from '../../types/myArea';
import AppText from '../AppText';
import styles from './styles';

type Props = AppNote & {
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

const TEXT_LIMIT = 150;

const NotesItem: React.FC<Props> = ({
  dateCreated,
  description,
  label,
  style,
  testID,
}) => {
  const {t} = useTranslation();

  const isLargeDescription =
    Platform.OS === 'web'
      ? false
      : description?.length
      ? description?.length > TEXT_LIMIT
      : false;

  const shortDescription =
    isLargeDescription && description
      ? description.slice(0, TEXT_LIMIT) + '... '
      : description;

  const [currentDescription, setCurrentDescription] =
    useState(shortDescription);

  const expanded = currentDescription === description;

  const createdAt = dateCreated
    ? format(new Date(dateCreated), 'dd. MMMM yyyy')
    : undefined;

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, style],
    [style],
  );

  const showFullDescription = useCallback(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setCurrentDescription(description);
  }, [description]);

  const hideFullDescription = useCallback(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setCurrentDescription(shortDescription);
  }, [shortDescription]);

  return (
    <View style={containerStyle} testID={testID}>
      {createdAt ? <AppText style={styles.date}>{createdAt}</AppText> : null}

      <View style={styles.content}>
        {label ? <AppText style={styles.title}>{label}</AppText> : null}

        {currentDescription ? (
          <>
            <AppText style={styles.description}>{currentDescription}</AppText>
            {isLargeDescription ? (
              <TouchableHighlight
                underlayColor={colors.transparent}
                onPress={expanded ? hideFullDescription : showFullDescription}>
                <AppText style={styles.showMore}>
                  {expanded ? t('news.show_less') : t('news.show_more')}
                </AppText>
              </TouchableHighlight>
            ) : null}
          </>
        ) : null}
      </View>
    </View>
  );
};

export default React.memo(NotesItem);
