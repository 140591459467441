import {useLayout} from '@react-native-community/hooks';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Image,
  StyleProp,
  TouchableHighlight,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import {
  BaseBookingFragment,
  Tags,
  useFindManyBookingsV3Query,
} from '../../generated/graphql';
import useBookingQueryVariables from '../../hooks/useBookingQueryVariables';
import useListQuery from '../../hooks/useListQuery';
import BellIcon from '../../icons/BellIcon';
import {useAppContext} from '../../store/contexts/AppContext';
import colors from '../../theme/colors';
import {BookingItemType} from '../../types/booking';
import {AppProductsLabels} from '../../types/common';
import {getImageUrl} from '../../utils/files';
import {getEventDate} from '../../utils/home';
import AppText from '../AppText';
import ImageWithLoadingState from '../ImageWithLoadingState';
import LoadingContent from '../LoadingContent';
import styles from './styles';

type Props = ViewProps & {
  tagsIds?: Tags['id'][];
  onPress?: (eventId: BaseBookingFragment['id'], language: string) => void;
};

export type NewsBannerRef = {
  refresh: () => Promise<void>;
};

const NewsBanner = forwardRef<NewsBannerRef, Props>(
  ({style, testID, onPress, tagsIds, ...props}, ref) => {
    const {t, i18n} = useTranslation();
    const {width, onLayout} = useLayout();
    const {screensSetupCategoriesAvailability} = useAppContext();

    const getBookingQueryVariables = useBookingQueryVariables({
      selectedCategory: BookingItemType.EVENT,
      selectedTags: tagsIds,
      initialInput: {take: 1},
    });

    const {data, loading, error, previousData, refetchWithParams} =
      useListQuery(
        useFindManyBookingsV3Query,
        getBookingQueryVariables,
        'findManyBookingsV3',
        {
          shouldSkip:
            !screensSetupCategoriesAvailability[AppProductsLabels.EVENT],
        },
      );

    const upcomingEvent = useMemo(() => data?.[0], [data]);

    const eventId = upcomingEvent?.id;
    const title = upcomingEvent?.Label;
    const language = upcomingEvent?.Language;

    const handlePress = useCallback(
      () => eventId && language && onPress?.(eventId, language),
      [eventId, language, onPress],
    );

    const containerStyle = useMemo<StyleProp<ViewStyle>>(
      () => [styles.container, style],
      [style],
    );

    const imageWidth = width * 0.35;

    const imageUrl = getImageUrl(upcomingEvent?.directus_files, {
      width: imageWidth,
      aspectRatio: 1,
    });

    const dateLabel = getEventDate({
      t,
      startDate: upcomingEvent?.StartDate,
      startTime: upcomingEvent?.StartTime,
      currentLanguage: i18n.language,
      localizedTime: true,
    });

    useImperativeHandle(
      ref,
      () => {
        return {
          async refresh() {
            await refetchWithParams({force_refresh: true});
          },
        };
      },
      [refetchWithParams],
    );

    if (loading && !previousData) {
      return <LoadingContent />;
    }

    if ((!loading && !upcomingEvent) || error) {
      return null;
    }

    if (!screensSetupCategoriesAvailability[AppProductsLabels.EVENT]) {
      return null;
    }

    return (
      <TouchableHighlight
        style={containerStyle}
        underlayColor={colors.whiteAlpha20}
        testID={testID}
        onPress={handlePress}
        onLayout={onLayout}
        {...props}>
        <>
          <ImageWithLoadingState
            uri={imageUrl}
            style={styles.image}
            testID={`${testID}.image`}
          />
          <View style={styles.content}>
            <View style={styles.notification}>
              <BellIcon />
              <AppText style={styles.notificationLabel}>
                {t('home.do_not_miss').toUpperCase()}
              </AppText>
            </View>

            <View style={styles.dateContainer}>
              <Image
                source={require('../../assets/images/calendar_icon.png')}
                style={styles.calendarImage}
              />
              {dateLabel ? (
                <AppText
                  style={styles.dateLabel}
                  testID={`${testID}.dateLabel`}>
                  {dateLabel}
                </AppText>
              ) : null}
            </View>

            {title ? (
              <AppText
                style={styles.title}
                testID={`${testID}.title`}
                ellipsizeMode="tail"
                numberOfLines={2}>
                {title}
              </AppText>
            ) : null}
          </View>
        </>
      </TouchableHighlight>
    );
  },
);

export default React.memo(NewsBanner);
