import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const TimeIcon: React.FC<SvgProps> = ({...props}) => {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="white" {...props}>
      <Path d="M0 8C0 6.92267 0.213333 5.888 0.64 4.896C1.06667 3.904 1.632 3.05067 2.336 2.336C3.04 1.62133 3.89333 1.056 4.896 0.64C5.89867 0.224 6.93333 0.0106667 8 0C9.088 0 10.128 0.213333 11.12 0.64C12.112 1.06667 12.96 1.632 13.664 2.336C14.368 3.04 14.9333 3.89333 15.36 4.896C15.7867 5.89867 16 6.93333 16 8C16 9.088 15.7867 10.1227 15.36 11.104C14.9333 12.0853 14.368 12.9387 13.664 13.664C12.96 14.3893 12.1067 14.96 11.104 15.376C10.1013 15.792 9.06667 16 8 16C6.93333 16 5.89867 15.792 4.896 15.376C3.89333 14.96 3.04 14.3893 2.336 13.664C1.632 12.9387 1.06667 12.0853 0.64 11.104C0.213333 10.1227 0 9.088 0 8ZM2 8C2 9.088 2.26667 10.096 2.8 11.024C3.33333 11.952 4.064 12.6773 4.992 13.2C5.92 13.7227 6.92267 13.9893 8 14C9.07733 14.0107 10.08 13.744 11.008 13.2C11.936 12.656 12.6667 11.9307 13.2 11.024C13.7333 10.1173 14 9.10933 14 8C14 6.89067 13.7333 5.888 13.2 4.992C12.6667 4.096 11.936 3.37067 11.008 2.816C10.08 2.26133 9.07733 1.98933 8 2C6.92267 2.01067 5.92 2.28267 4.992 2.816C4.064 3.34933 3.33333 4.07467 2.8 4.992C2.26667 5.90933 2 6.912 2 8ZM7.008 8V5.008C7.008 4.73067 7.104 4.496 7.296 4.304C7.488 4.112 7.72267 4.01067 8 4C8.27733 3.98933 8.512 4.09067 8.704 4.304C8.896 4.51733 8.99733 4.752 9.008 5.008V7.008H11.008C11.2747 7.008 11.5093 7.104 11.712 7.296C11.9147 7.488 12.0107 7.72267 12 8C11.9893 8.27733 11.8933 8.51733 11.712 8.72C11.5307 8.92267 11.296 9.01867 11.008 9.008H8C7.72267 9.008 7.488 8.912 7.296 8.72C7.104 8.528 7.008 8.288 7.008 8Z" />
    </Svg>
  );
};

export default React.memo(TimeIcon);
