import {ProductLabels} from '../mindance-libs/types/products';
import {RouteKeys} from '../navigation/RouteKeys';
import {MindManagerProductLabel} from '../types/MindManagerProducts';

export const HOME_MIND_MANAGER_TOOLS_ROUTES: Record<
  MindManagerProductLabel,
  RouteKeys
> = {
  [ProductLabels.DASHBOARD_SHOW_RELATED_SUBSCRIPTIONS]: RouteKeys.Dashboard,
  [ProductLabels.EVENTTOOL_SHOW_RELATED_SUBSCRIPTIONS]: RouteKeys.Eventtool,
  [ProductLabels.NEWSTOOL_SHOW_RELATED_SUBSCRIPTIONS]: RouteKeys.Newstool,
  [ProductLabels.GBU_PSYCHE_SHOW_RELATED_SUBSCRIPTIONS]: RouteKeys.GBUPsyche,
};
