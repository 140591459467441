import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import useBooleanValue from './useBooleanValue';

type Options = {
  key: string;
  initialValue: string;
  validation: yup.ObjectSchema<any>;
  onBlur?: (value: string) => Promise<void>;
  onError?: (error: string) => void;
};

type ValidationResponse =
  | {
      success: true;
    }
  | {
      success: false;
      error: string;
    };

type ValidationError = {
  message: string;
};

const isValidationError = (err: any): err is ValidationError => {
  return err?.hasOwnProperty('message');
};

const useOneFieldForm = ({
  key,
  initialValue,
  validation,
  onBlur: handleBlur,
  onError,
}: Options) => {
  const {t} = useTranslation();

  const [value, setValue] = useState(initialValue);

  const {value: touched, setTrue: setTouchedTrue} = useBooleanValue(false);
  const {
    value: isFocused,
    setTrue: setIsFocusedTrue,
    setFalse: setIsFocusedFalse,
  } = useBooleanValue(false);

  const [error, setError] = useState<string | undefined>(undefined);

  const validate = useCallback<
    (newValue: string) => Promise<ValidationResponse>
  >(
    async newValue => {
      try {
        await validation.validate({[key]: newValue});
        setError(undefined);
        return {success: true};
      } catch (e) {
        if (isValidationError(e)) {
          const errorMsg = t(e.message) ?? '';
          setError(errorMsg);
          return {success: false, error: errorMsg};
        }

        const message = t('errors.something_went_wrong');
        setError(message);
        return {success: false, error: message};
      }
    },
    [key, t, validation],
  );

  const onChange = useCallback<(newValue: string) => void>(
    newValue => {
      setValue(newValue);

      if (touched) {
        validate(newValue);
      }
    },
    [touched, validate],
  );

  const onFocus = useCallback(() => {
    setIsFocusedTrue();
  }, [setIsFocusedTrue]);

  const onBlur = useCallback(async () => {
    setTouchedTrue();
    setIsFocusedFalse();

    const response = await validate(value);

    if (response.success) {
      handleBlur?.(value);
    } else {
      onError?.(response.error);
    }
  }, [handleBlur, onError, setIsFocusedFalse, setTouchedTrue, validate, value]);

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return useMemo(
    () => ({
      hasError: !!error,
      isFocused,
      onFocus,
      onBlur,
      onChange,
      value,
      error,
    }),
    [error, isFocused, onBlur, onChange, onFocus, value],
  );
};

export default useOneFieldForm;
