import * as Sentry from '@sentry/react';
import Config from './config';

Sentry.init({
  dsn: 'https://40022dadc4d7255a656f19905a19c523@o96915.ingest.sentry.io/4506540913917952',
  environment: Config.ENVIRONMENT,
  integrations: [new Sentry.Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
});

export default Sentry;
