import {useMemo} from 'react';
import {LibraryItemFragment} from '../generated/graphql';
import {useAppContext} from '../store/contexts/AppContext';
import {CardType, FormattedLibraryItem} from '../types/library';
import {getMeinFSLink} from '../utils/generalSettings';

const formatLibraryItems = (
  item: LibraryItemFragment,
  type: CardType,
): FormattedLibraryItem => {
  const tagGroup = item.TagGroups;
  const tags = tagGroup?.TagGroups_Tags?.reduce<string[]>(
    (accumulated, {Tags}) =>
      Tags?.id ? [...accumulated, Tags?.id] : accumulated,
    [],
  );

  return {
    id: item.id,
    tags,
    color: item.Color,
    label: tagGroup?.TagGroups_translations?.[0]?.Label,
    imageFile: item.directus_files,
    type,
  };
};

const useLibraryTopics = () => {
  const {
    libraryScreenSetup,
    screenSetupLoading,
    screenSetupRefreshing,
    screenSetupError,
    refetchScreenSetupData,
  } = useAppContext();

  const meinFSLink = getMeinFSLink(
    libraryScreenSetup?.GeneralSetting_Library_GeneralSettingToGeneralSetting,
  );

  const formatItems = useMemo<FormattedLibraryItem[]>(() => {
    const items =
      libraryScreenSetup?.Library_translations?.[0]?.FormatItems?.reduce<
        FormattedLibraryItem[]
      >((acc, {LibraryItems: item}) => {
        if (!item) {
          return acc;
        }

        const formattedItem = formatLibraryItems(item, CardType.Format);

        return [...acc, formattedItem];
      }, []) ?? [];

    return items;
  }, [libraryScreenSetup?.Library_translations]);

  const topicItems = useMemo<FormattedLibraryItem[]>(() => {
    const items =
      libraryScreenSetup?.Library_translations?.[0]?.TopicItems?.reduce<
        FormattedLibraryItem[]
      >((acc, {LibraryItems: item}) => {
        if (!item) {
          return acc;
        }

        const formattedItem = formatLibraryItems(item, CardType.Topic);

        return [...acc, formattedItem];
      }, []) ?? [];

    return items;
  }, [libraryScreenSetup?.Library_translations]);

  return useMemo(
    () => ({
      formatItems,
      topicItems,
      error: screenSetupError,
      loading: screenSetupLoading,
      refreshing: screenSetupRefreshing,
      meinFSLink,
      refetch: refetchScreenSetupData,
    }),
    [
      formatItems,
      meinFSLink,
      refetchScreenSetupData,
      screenSetupError,
      screenSetupLoading,
      screenSetupRefreshing,
      topicItems,
    ],
  );
};

export default useLibraryTopics;
