import React, {useCallback, useMemo} from 'react';
import styles from './Input.module.scss';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import colors from '../../mindance-libs/theme/colors';
import InputProps, {InputPostfix} from './Input.props';
import {useTranslation} from 'react-i18next';

const Input: React.FC<InputProps> = ({
  label,
  error,
  errorColor = colors.alert,
  autoFocus,
  onBlur,
  onFocus,
  placeholder,
  value,
  showLabel = true,
  isRequired = false,
  className,
  isSecure,
  postfix,
  inputClass,
  hasError,
  onClick,
  style,
  onChangeText,
  onChange,
  ...props
}) => {
  const {t} = useTranslation();
  const formattedLabel = isRequired && label ? `${label} *` : label;

  const isShowError = hasError && error;

  const PostfixIcon = useMemo(() => {
    if (!postfix) return '';

    if (postfix === InputPostfix.EDIT) {
      return <FontAwesomeIcon icon={faPen} className={styles.input__icon} />;
    }
  }, [postfix]);

  const inputStyle = useMemo<React.CSSProperties>(
    () => ({
      borderColor: isShowError ? errorColor : colors.whiteAlpha60,
      ...style,
    }),
    [errorColor, isShowError, style],
  );

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    e => {
      onChange?.(e);
      onChangeText?.(e.target.value);
    },
    [onChange, onChangeText],
  );

  return (
    <div className={classNames(styles.input__container, className)}>
      {showLabel && <p className={styles.input__label}>{formattedLabel}</p>}
      <input
        type={isSecure ? 'password' : 'text'}
        value={value}
        className={classNames(styles.input__field, inputClass)}
        autoFocus={autoFocus}
        placeholder={placeholder ?? undefined}
        style={inputStyle}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={handleChange}
        {...props}
      />
      {isShowError && <p className={styles.input__error}>{t(error)}</p>}
      <div className={styles.input__postfix} onClick={onClick}>
        {PostfixIcon}
      </div>
    </div>
  );
};

export default React.memo(Input);
