import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, TextStyle, useWindowDimensions} from 'react-native';
import Button from '../../../../../components/Button/Button';
import BookingFooter from '../../../../../mindance-libs/components/BookingFooter';
import BookingFormFields from '../../../../../mindance-libs/components/BookingFormFields';
import BookingMetaInfo from '../../../../../mindance-libs/components/BookingMetaInfo';
import BookingNoteField from '../../../../../mindance-libs/components/BookingNoteField';
import LinearSeparator from '../../../../../mindance-libs/components/LinearSeparator';
import RoundBackButton from '../../../../../mindance-libs/components/RoundBackButton';
import ScreenBase from '../../../../../mindance-libs/components/ScreenBase';
import useBookingFormFields from '../../../../../mindance-libs/hooks/useBookingFormFields';
import useBookingFormRequests from '../../../../../mindance-libs/hooks/useBookingFormRequests';
import useErrorMessage from '../../../../../mindance-libs/hooks/useErrorMessage';
import useOneFieldForm from '../../../../../mindance-libs/hooks/useOneFieldForm';
import HistoryIcon from '../../../../../mindance-libs/icons/HistoryIcon';
import {useAppContext} from '../../../../../mindance-libs/store/contexts/AppContext';
import colors from '../../../../../mindance-libs/theme/colors';
import breakpointProvider from '../../../../../mindance-libs/utils/breakpointProvider';
import {noteFormValidationSchema} from '../../../../../mindance-libs/utils/formValidations';
import {RouteKeys} from '../../../../../navigation/RouteKeys';
import useTypedLocation from '../../../../../navigation/typed/useTypedLocation';
import useTypedNavigate from '../../../../../navigation/typed/useTypedNavigate';
import useTypedParams from '../../../../../navigation/typed/useTypedParams';
import {footerStyle} from './page.data';
import styles from './page.module.scss';

const HistoryIconComponent = <HistoryIcon height={18} width={18} />;

export default function Page() {
  const {t} = useTranslation();
  const {error, showMessage} = useErrorMessage();
  const {width} = useWindowDimensions();

  const {id} = useTypedParams<RouteKeys.EventBookingForm>();
  const {
    state: {
      bookingInfo,
      cost,
      endDate,
      endTime,
      startDate,
      startTime,
      title,
      venue,
    },
  } = useTypedLocation<RouteKeys.EventBookingForm>();

  const navigate = useTypedNavigate();

  const {isBookingDataFilled} = useAppContext();

  const screenTitle = isBookingDataFilled
    ? t('screenTitle.BookingFormScreen')
    : t('booking.almost_there');

  const {
    value: note,
    hasError: hasNoteError,
    onChange: setNote,
    onBlur: handleNoteBlur,
  } = useOneFieldForm({
    initialValue: '',
    key: 'note',
    validation: noteFormValidationSchema,
    onError: showMessage,
  });

  const noteFieldStyle = useMemo<StyleProp<TextStyle>>(
    () => ({
      width:
        width <= breakpointProvider.$gridBreakpoints.md
          ? '100%'
          : width <= breakpointProvider.$gridBreakpoints.xl
          ? '80%'
          : '60%',
    }),
    [width],
  );

  const navigateToSuccessfulScreen = useCallback(() => {
    id &&
      navigate(
        {to: RouteKeys.EventBookingSuccess, params: {id}},
        {replace: true},
      );
  }, [id, navigate]);

  const {loading, applyChanges, saveData} = useBookingFormRequests({
    eventId: id,
    note,
    onError: showMessage,
    onBookingOrderCompleted: navigateToSuccessfulScreen,
  });

  const {
    values,
    handleFormBlur,
    handleFormFocus,
    handleFormChange,
    hasError,
    personalStatusError,
    personalStatusItems,
    personalStatusPlaceholder,
    isSubmitting,
    isValid,
    handleSubmit,
    isFocused,
  } = useBookingFormFields({onSubmit: saveData, onError: showMessage});

  const submit = useCallback(() => {
    isBookingDataFilled ? handleSubmit() : applyChanges(values);
  }, [applyChanges, handleSubmit, isBookingDataFilled, values]);

  const changeUserData = useCallback(() => {
    id && navigate({to: RouteKeys.UserBookingForm, params: {id}});
  }, [id, navigate]);

  const ChangeDataButton = useMemo(
    () => (
      <Button
        leftIcon={HistoryIconComponent}
        button={{
          onClick: changeUserData,
          style: {color: colors.white, fontWeight: '600'},
        }}>
        {t('booking.change_data')}
      </Button>
    ),
    [changeUserData, t],
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <ScreenBase error={error}>
      <div className={styles['booking-form__container']}>
        <div className={styles['booking-form__header']}>
          <RoundBackButton
            size={40}
            borderColor={colors.whiteAlpha50}
            iconColor={colors.white}
            iconSize={30}
            onPress={goBack}
          />
        </div>
        <div className={styles['booking-form__content']}>
          <div className={styles['booking-form__content__header']}>
            <h1>{screenTitle}</h1>

            {!isBookingDataFilled ? (
              <p>{t('booking.complete_your_first_booking')}</p>
            ) : null}
          </div>
          <LinearSeparator />

          <div className={styles['booking-form__content__info']}>
            {title ? <h2>{title}</h2> : null}

            <BookingMetaInfo
              cost={cost}
              venue={venue}
              startDate={startDate}
              startTime={startTime}
              endTime={endTime}
              className={styles['booking-form__content__info__meta-info']}
              oneColumn
            />
          </div>
          <LinearSeparator />

          <div className={styles['booking-form__content__fields']}>
            {isBookingDataFilled ? (
              <div className={styles['booking-form__content__fields__note']}>
                <BookingNoteField
                  bookingInfo={bookingInfo}
                  onChangeValue={setNote}
                  value={note}
                  error={hasNoteError}
                  onBlur={handleNoteBlur}
                  inputStyle={noteFieldStyle}
                />
              </div>
            ) : (
              <BookingFormFields
                values={values}
                handleBlur={handleFormBlur}
                handleFocus={handleFormFocus}
                handleChange={handleFormChange}
                hasError={hasError}
                personalStatusItems={personalStatusItems}
                personalStatusPlaceholder={personalStatusPlaceholder}
                personalStatusError={personalStatusError}
                isFocused={isFocused}
                editMode={isBookingDataFilled}
              />
            )}
          </div>
        </div>

        <BookingFooter
          style={footerStyle}
          cost={cost}
          startDate={startDate}
          endDate={endDate}
          left={isBookingDataFilled ? ChangeDataButton : undefined}
          buttonLoading={isBookingDataFilled ? loading : isSubmitting}
          buttonDisabled={
            isBookingDataFilled ? loading : !isValid || isSubmitting
          }
          onButtonPress={submit}
          buttonLabel={
            isBookingDataFilled ? t('booking.book_now') : t('common.next')
          }
        />
      </div>
    </ScreenBase>
  );
}
