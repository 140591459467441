import classNames from 'classnames';
import React from 'react';
import styles from './DashboardSectionTitle.module.scss';
import DashboardSectionTitleProps from './DashboardSectionTitle.props';

const DashboardSectionTitle: React.FC<DashboardSectionTitleProps> = ({
  className,
  title,
}) => {
  return (
    <div className={classNames(styles['dashboard-section-title'], className)}>
      <i
        className={classNames(
          'fa-solid',
          'fa-chart-simple',
          styles['dashboard-section-title__icon'],
        )}
      />
      <h2 className={styles['dashboard-section-title__text']}>{title}</h2>
    </div>
  );
};

export default DashboardSectionTitle;
