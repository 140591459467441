import React, {useMemo} from 'react';
import {View} from 'react-native';
import {AnimatedProgressBarProps} from './props';
import styles from './styles';

const AnimatedProgressBar: React.FC<AnimatedProgressBarProps> = ({
  style,
  current,
  total,
  testID,
  ...props
}) => {
  const barWidth = total === 0 ? 0 : current >= total ? 1 : current / total;

  const containerStyle = useMemo(() => [styles.container, style], [style]);

  const topBarStyle = useMemo<React.CSSProperties>(
    () => ({...styles.topBar, flex: barWidth, transition: 'flex 0.25s ease'}),
    [barWidth],
  );

  return (
    <View style={containerStyle} testID={testID} {...props}>
      <View style={styles.background} testID={`${testID}.background`} />
      <div style={topBarStyle} />
    </View>
  );
};

export default React.memo(AnimatedProgressBar);
