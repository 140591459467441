import classNames from 'classnames';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import useLanguage from '../../mindance-libs/hooks/useLanguage';
import {Languages} from '../../mindance-libs/types/common';
import styles from './LocaleSwitcher.module.scss';
import LocaleSwitcherProps from './LocaleSwitcher.props';

const LANGUAGES = Object.values(Languages).map(lng => ({
  label: lng.split('-')[0].toUpperCase(),
  value: lng,
}));

const LocaleSwitcher: React.FC<LocaleSwitcherProps> = props => {
  const {i18n} = useTranslation();

  const {changeLanguage} = useLanguage();

  const mergedStyle = classNames(styles['locale-switcher'], props.className);

  const Switcher = useMemo(
    () =>
      LANGUAGES.map(({label, value}, index, lngList) => {
        const isCurrent = value === i18n.language;
        const isLast = index === lngList.length - 1;
        const updateLanguage = () => changeLanguage(value);

        return (
          <div
            key={label}
            className={styles['locale-switcher__item-container']}>
            <button
              className={classNames(styles['locale-switcher__item'], {
                [styles['locale-switcher__item--current']]: isCurrent,
              })}
              onClick={updateLanguage}>
              {label}
            </button>

            {!isLast && <div className={styles['locale-switcher__divider']} />}
          </div>
        );
      }),
    [changeLanguage, i18n.language],
  );

  if (LANGUAGES.length < 2) {
    return null;
  }

  return (
    <div className={mergedStyle} data-testid={props.testID}>
      <div className={styles['locale-switcher__container']}>{Switcher}</div>
    </div>
  );
};

export default LocaleSwitcher;
