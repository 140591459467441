import * as R from 'ramda';
import {useCallback, useMemo} from 'react';
import {
  FindManyNotesQueryVariables,
  NullsOrder,
  SortOrder,
  useFindManyNotesQuery,
} from '../generated/graphql';
import {AppNote} from '../types/myArea';
import useListQuery from './useListQuery';

const useMyNotes = () => {
  const getQueryVariables = useCallback<
    (
      input?: Partial<FindManyNotesQueryVariables>,
    ) => FindManyNotesQueryVariables
  >((input = {}) => {
    const baseVariables: FindManyNotesQueryVariables = {
      orderBy: {
        date_created: {
          sort: SortOrder.Desc,
          nulls: NullsOrder.Last,
        },
      },
    };

    return R.mergeDeepRight(
      baseVariables,
      input,
    ) as FindManyNotesQueryVariables;
  }, []);

  const {data, error, fetchNext, loading, loadingMore, previousData} =
    useListQuery(useFindManyNotesQuery, getQueryVariables, 'findManyNotes');

  const notes = useMemo<AppNote[]>(() => {
    const filteredData = R.filter(R.isNotNil, data);

    return filteredData.map(({id, Description, NoteUnits, date_created}) => ({
      id,
      dateCreated: date_created,
      label: NoteUnits?.Label,
      description: Description,
    }));
  }, [data]);

  return useMemo(
    () => ({
      data: notes,
      error,
      loading: loading && !previousData,
      loadingMore,
      fetchNext,
    }),
    [error, fetchNext, loading, loadingMore, notes, previousData],
  );
};

export default useMyNotes;
