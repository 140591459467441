import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

export const SLIDER_THUMB_WIDTH = 14;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingTop: spacings.l,
  },
  slider: {
    borderRadius: 60,
  },
  timeWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: spacings.s,
  },
  time: {
    ...typography.input,
    color: colors.white,
  },
  buttonsWrapper: {
    marginTop: spacings.xxxxs,
    paddingHorizontal: spacings.l,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  playButton: {
    marginHorizontal: spacings.l,
  },
});

export default styles;
