import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.whiteAlpha13,
    backgroundColor: colors.whiteAlpha13,
    paddingHorizontal: spacings.xs,
    paddingBottom: Platform.select({web: spacings.xxs, default: spacings.xs}),
    paddingTop: Platform.select({web: spacings.xxs, default: spacings.xxxs}),
  },
  selectedContainer: {
    backgroundColor: colors.white,
  },
  label: {
    ...typography.body,
    ...(Platform.OS === 'web' ? {lineHeight: 26} : {}),
    color: colors.white,
  },
  selectedLabel: {
    ...typography.bodyDemiBold,
    ...(Platform.OS === 'web' ? {lineHeight: 26} : {}),
    color: colors.black100,
  },
});

export default styles;
