import {animate} from 'framer-motion';
import Lottie, {LottieViewProps} from 'lottie-react-native';
import * as R from 'ramda';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, ScrollView, View} from 'react-native';
import Animated, {
  FadeIn,
  Layout,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {ReText} from 'react-native-redash';
import RoundCheckBoxIcon from '../../../icons/RoundCheckBoxIcon';
import colors from '../../../theme/colors';
import {OnboardingSlideProps} from '../../../types/onboarding';
import {
  getEntryOrExitLayoutAnimation,
  getReanimatedLayoutAnimation,
} from '../../../utils/animation';
import {getDisplayedInitialState} from '../../../utils/onboarding';
import AppText from '../../AppText';
import LayoutAnimationWrapper from '../../LayoutAnimationWrapper';
import styles from './styles';

type Props = OnboardingSlideProps & {
  onAnimationEnd?: () => void;
};

const ANIMATION_COLORS: LottieViewProps['colorFilters'] = [
  {keypath: 'back', color: colors.whiteAlpha10},
  {keypath: 'top', color: colors.whiteAlpha50},
];

export const DESCRIPTION_ITEMS = [
  'onboarding.theme_courses',
  'onboarding.topics_exercises',
  'onboarding.mood_music',
  'onboarding.individual_coaching',
];

export const LIST_ITEM_DURATION = 1000;
export const STALE_STATE_DURATION = 500;
export const ANIMATION_DURATION = DESCRIPTION_ITEMS.length * LIST_ITEM_DURATION;

const ITEM_ANIMATION_DURATION = 500;

const START_PERCENT = 0;
const END_PERCENT = 100;

const OnboardingSummarySlide: React.FC<Props> = ({
  onAnimationEnd,
  style,
  focused,
}) => {
  const {t} = useTranslation();

  const [displayedMessages, setDisplayedMessages] = useState(
    getDisplayedInitialState(DESCRIPTION_ITEMS),
  );

  const percent = useSharedValue(START_PERCENT);
  const targetValue = useSharedValue(END_PERCENT);

  const [percentValue, setPercentValue] = useState(0);

  const interpolatedValue = useDerivedValue(
    () => `${Math.round(percent.value)}%`,
  );

  const ItemsList = useMemo(
    () =>
      DESCRIPTION_ITEMS.map(
        item =>
          displayedMessages[item] && (
            <LayoutAnimationWrapper
              duration={ITEM_ANIMATION_DURATION}
              initial="hidden"
              exit="hidden"
              animate="visible"
              key={item}>
              <Animated.View
                style={styles.listItem}
                testID={item}
                layout={getReanimatedLayoutAnimation(
                  Layout.duration(ITEM_ANIMATION_DURATION),
                )}
                entering={getEntryOrExitLayoutAnimation(
                  FadeIn.duration(ITEM_ANIMATION_DURATION),
                )}>
                <RoundCheckBoxIcon />
                <AppText style={styles.listItemText}>{t(item)}</AppText>
              </Animated.View>
            </LayoutAnimationWrapper>
          ),
      ),
    [displayedMessages, t],
  );

  const containerStyle = useMemo(() => [styles.container, style], [style]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (focused) {
      if (Platform.OS === 'web') {
        animate(START_PERCENT, END_PERCENT, {
          duration: ANIMATION_DURATION / 1000,
          onUpdate: setPercentValue,
        });
      } else {
        percent.value = withTiming(targetValue.value, {
          duration: ANIMATION_DURATION,
        });
      }

      timer = setTimeout(
        () => onAnimationEnd?.(),
        ANIMATION_DURATION + STALE_STATE_DURATION,
      );
    }

    return () => clearTimeout(timer);
  }, [focused, onAnimationEnd, percent, targetValue.value]);

  useEffect(() => {
    const timers: NodeJS.Timeout[] = [];

    if (focused) {
      DESCRIPTION_ITEMS.map((messageKey, index) => {
        const timer = setTimeout(() => {
          setDisplayedMessages(R.assoc(messageKey, true));
        }, LIST_ITEM_DURATION * index);

        timers.push(timer);
      });
    }

    return () => {
      timers.map(clearTimeout);
    };
  }, [focused]);

  return (
    <View style={containerStyle}>
      <ScrollView
        contentContainerStyle={styles.scrollView}
        showsVerticalScrollIndicator={false}>
        <View style={styles.top}>
          <View style={styles.animationLoadingWrapper}>
            {Platform.OS === 'web' ? (
              <AppText
                style={styles.percentsText}
                testID="onboardingSummaryPercents">
                {`${Math.round(percentValue)}%`}
              </AppText>
            ) : (
              <ReText
                testID="onboardingSummaryPercents"
                text={interpolatedValue}
                style={styles.percentsText}
              />
            )}
            <Lottie
              source={require('../../../assets/animations/circle_loader.json')}
              autoPlay
              loop
              colorFilters={ANIMATION_COLORS}
              style={styles.animation}
            />
          </View>
        </View>

        <View style={styles.bottom}>
          <AppText testID="onboardingSummaryTitle" style={styles.title}>
            {t('onboarding.mindance_world_created')}
          </AppText>
          <View style={styles.listWrapper}>{ItemsList}</View>
        </View>
      </ScrollView>
    </View>
  );
};

export default React.memo(OnboardingSummarySlide);
