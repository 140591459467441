import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: spacings.xl,
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  backButton: {
    marginTop: spacings.l,
  },
  animation: {
    width: '100%',
    aspectRatio: 1.5,
  },
  title: {
    ...typography.title1,
    color: colors.white,
    textAlign: 'center',
  },
  subtitle: {
    ...typography.subtitle3,
    color: colors.white,
    textAlign: 'center',
    marginTop: spacings.m,
    width: '70%',
  },
});

export default styles;
