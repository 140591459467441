import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {SMALL_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  container: {
    borderRadius: SMALL_BORDER_RADIUS,
    backgroundColor: colors.whiteAlpha09,
    borderColor: colors.whiteAlpha13,
    borderWidth: 1,
    padding: spacings.s,
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    aspectRatio: 1,
    borderRadius: 7,
    width: '35%',
    maxWidth: 130,
  },
  content: {
    marginLeft: spacings.xl,
    paddingVertical: spacings.xs,
    flexShrink: 1,
  },
  title: {
    ...typography.bodyDemiBold,
    color: colors.white,
    marginTop: spacings.xxs,
  },
  notification: {
    backgroundColor: colors.white,
    borderRadius: 16,
    paddingVertical: spacings.xs,
    paddingHorizontal: spacings.m,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  notificationLabel: {
    ...typography.tabBarLabel,
    color: colors.black100Alpha95,
    marginLeft: spacings.xxs,
  },
  dateContainer: {
    marginTop: spacings.l,
    flexDirection: 'row',
    alignItems: 'center',
  },
  calendarImage: {
    width: 12,
    height: 12,
    tintColor: colors.white,
  },
  dateLabel: {
    marginLeft: spacings.xs,
    ...typography.footnote,
    color: colors.whiteAlpha95,
  },
});

export default styles;
