import format from 'date-fns/format';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ViewProps} from 'react-native';
import {
  InfoDetailsFragment,
  Maybe,
  MediumDetailsFragment,
} from '../../generated/graphql';
import {getMediumLabel} from '../../utils/booking';
import AppText from '../AppText';
import HTMLRenderer from '../HTMLRenderer';
import styles, {htmlCustomStyle} from './styles';

type Props = ViewProps & {
  registrationDeadline?: string | null;
  address?: string | null;
  mediums?: Maybe<MediumDetailsFragment>[] | null;
  bookingState?: string | null;
  bookingInfo?: string | null;
  info?: Maybe<InfoDetailsFragment> | null;
};

const SEMICOLON_DELIMITER = ': ';

const BookingInfoContent: React.FC<Props> = ({
  registrationDeadline,
  address,
  mediums,
  bookingState,
  bookingInfo,
  info,
  ...props
}) => {
  const {t} = useTranslation();

  const description = info?.Infos_translations?.[0]?.Description;

  const registrationDeadlineLabel = registrationDeadline
    ? format(new Date(registrationDeadline), 'dd.MM.yyyy')
    : undefined;
  const mediumLabel = getMediumLabel(mediums);

  const data = useMemo(
    () => [
      {label: address, title: t('booking.address')},
      {label: bookingState, title: t('booking.booking_state')},
      {
        label: registrationDeadlineLabel,
        title: t('booking.registration_deadline'),
      },
      {label: mediumLabel, title: t('booking.medium')},
    ],
    [address, bookingState, mediumLabel, registrationDeadlineLabel, t],
  );

  const PointItems = useMemo(
    () =>
      data.map(({title, label}) =>
        label ? (
          <AppText style={styles.description} key={title}>
            {title}
            {SEMICOLON_DELIMITER}
            {label}
          </AppText>
        ) : null,
      ),
    [data],
  );

  return (
    <View {...props}>
      {description ? (
        <>
          <HTMLRenderer content={description} tagsStyles={htmlCustomStyle} />
          <View style={styles.spacer} />
        </>
      ) : null}

      {PointItems}
      {PointItems ? <View style={styles.spacer} /> : null}

      {bookingInfo ? (
        <HTMLRenderer content={bookingInfo} tagsStyles={htmlCustomStyle} />
      ) : null}
    </View>
  );
};

export default React.memo(BookingInfoContent);
