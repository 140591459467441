import {ApolloError, useApolloClient} from '@apollo/client';
import {FormikConfig, useFormik} from 'formik';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useUpdateUserDataMutation} from '../generated/graphql';
import {useAppContext} from '../store/contexts/AppContext';
import {useAuthContext} from '../store/contexts/AuthContext';
import {isJsonString} from '../utils/common';
import {errorMessagesMapper, isErrorMessage} from '../utils/errors';
import {redeemActivationCodeFormValidationSchema} from '../utils/formValidations';
import {reportError} from '../utils/loggingHelpers';
import useErrorMessage from './useErrorMessage';
import useInputFormikHandlers from './useInputFormikHandlers';

type RedeemActivationCodeFormTypes = {
  activationCode: string;
};

const INITIAL_VALUES: RedeemActivationCodeFormTypes = {
  activationCode: '',
};

const useRedeemActivationCode = () => {
  const {t} = useTranslation();
  const {
    showGlobalMessage: showSuccessfulMessage,
    globalMessage: successfulMessage,
  } = useAppContext();
  const {obtainNewTokens, updateTokens} = useAuthContext();
  const client = useApolloClient();

  const {showMessage: showErrorMessage, error} = useErrorMessage();

  const handleError = useCallback<(error: ApolloError) => void>(
    ({message}) => {
      reportError('updateUserData error', message);
      const parsedMessage: string = isJsonString(message)
        ? JSON.parse(message).message
        : message;

      if (isErrorMessage(parsedMessage)) {
        showErrorMessage(t(errorMessagesMapper[parsedMessage]));
      }
    },
    [showErrorMessage, t],
  );

  const handleComplete = useCallback(async () => {
    showSuccessfulMessage(t('settings.activation_code_applied_successfully'));

    try {
      const {accessToken, refreshToken} = await obtainNewTokens();
      await updateTokens(accessToken, refreshToken);
      await client.refetchQueries({include: 'active'});
    } catch (e) {
      reportError('update tokens after redeeming activation code error', e);
    }
  }, [client, obtainNewTokens, showSuccessfulMessage, t, updateTokens]);

  const [updateUserData] = useUpdateUserDataMutation({
    onCompleted: handleComplete,
    onError: handleError,
  });

  const submitForm = useCallback<
    FormikConfig<RedeemActivationCodeFormTypes>['onSubmit']
  >(
    async ({activationCode}) => {
      await updateUserData({
        variables: {data: {activation_code: activationCode}},
      });
    },
    [updateUserData],
  );

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    validationSchema: redeemActivationCodeFormValidationSchema,
    validateOnMount: true,
    initialValues: INITIAL_VALUES,
    onSubmit: submitForm,
  });

  const {
    handleValueBlur: handleActivationCodeBlur,
    hasError: hasActivationCodeError,
    setValue: setActivationCode,
  } = useInputFormikHandlers<RedeemActivationCodeFormTypes>('activationCode', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError: showErrorMessage,
  });

  return useMemo(
    () => ({
      error,
      successfulMessage,
      hasActivationCodeError,
      values,
      isValid,
      isSubmitting,
      setActivationCode,
      handleActivationCodeBlur,
      handleSubmit,
    }),
    [
      error,
      handleActivationCodeBlur,
      handleSubmit,
      hasActivationCodeError,
      isSubmitting,
      isValid,
      setActivationCode,
      successfulMessage,
      values,
    ],
  );
};

export default useRedeemActivationCode;
