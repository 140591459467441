import React from 'react';
import {View} from 'react-native';
import PhoneIcon from '../../icons/PhoneIcon';
import {PhoneButtonProps} from './props';
import styles from './styles';

const PhoneButton: React.FC<PhoneButtonProps> = ({phoneNumber}) => {
  return (
    <a href={`tel:${phoneNumber}`}>
      <View style={styles.phoneButton}>
        <PhoneIcon width={20} height={20} />
      </View>
    </a>
  );
};

export default React.memo(PhoneButton);
