import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import ControlBottomButtons from '../../../components/ControlBottomButtons/ControlBottomButtons';
import ContactUs, {
  TitleVariants,
} from '../../../mindance-libs/components/ContactUs';
import {RouteKeys} from '../../../navigation/RouteKeys';
import useTypedNavigate from '../../../navigation/typed/useTypedNavigate';
import styles from './page.module.scss';

const ConfirmationContent: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useTypedNavigate();

  const submit = useCallback(async () => {
    navigate({to: RouteKeys.Login, params: {}});
  }, [navigate]);

  return (
    <>
      <div className={styles.password_change__space} />
      <div className={styles.password_change__title}>
        {t('auth.change_password_success')}
      </div>
      <div className={styles.password_change__description}>
        {t('auth.change_password_ready_to_login')}
      </div>
      <div className={styles.password_change__space} />
      <div className={styles.password_change__button}>
        <ControlBottomButtons
          onSubmit={submit}
          showReturnButton={false}
          submitButtonTestID="common.next"
        />
      </div>
      <div className={styles.password_change__space} />
      <div className={styles['password_change__contact-us']}>
        <ContactUs variant={TitleVariants.AUTH_PROBLEM} />
      </div>
    </>
  );
};

export default React.memo(ConfirmationContent);
