import {useCallback} from 'react';
import {DirectusFileFragment} from '../generated/graphql';
import {BookingItemType} from '../types/booking';
import {getImageUrl} from '../utils/files';
import useCompanyConfig from './useCompanyConfig';

const useCompanyEventImage = () => {
  const {getEventsLogoUrl} = useCompanyConfig();

  const getEventImage = useCallback<
    (
      incomingData?: DirectusFileFragment | null | string,
      bookingType?: string | null,
      options?: {
        width?: number;
        aspectRatio?: number;
        height?: number;
      },
    ) => string | undefined
  >(
    (incomingData, bookingType, options) => {
      const itemImage = getImageUrl(incomingData, options);

      if (itemImage || bookingType !== BookingItemType.EVENT_EVENTTOOL) {
        return itemImage;
      }

      return getEventsLogoUrl(options ?? {});
    },
    [getEventsLogoUrl],
  );

  return getEventImage;
};

export default useCompanyEventImage;
