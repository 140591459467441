import {useCallback, useMemo} from 'react';
import useAnimatedOpacity from './useAnimatedOpacity';

const useAVControlsAnimation = (options?: {
  onHide?: () => void;
  onShow?: () => void;
}) => {
  const {
    decreaseOpacity,
    displayed,
    increaseOpacity,
    opacityAnimatedStyle,
    toggleOpacity,
    cancelAnimation,
    opacity,
  } = useAnimatedOpacity({
    initialDisplayed: true,
    onHide: options?.onHide,
    onShow: options?.onShow,
  });

  const hideControlsWithDelay = useCallback(() => {
    cancelAnimation();
    decreaseOpacity(1000);
  }, [cancelAnimation, decreaseOpacity]);

  const hideControls = useCallback(() => {
    cancelAnimation();
    decreaseOpacity();
  }, [cancelAnimation, decreaseOpacity]);

  const showControls = useCallback(() => {
    cancelAnimation();
    increaseOpacity();
  }, [cancelAnimation, increaseOpacity]);

  const showControlsAndHideWidthDelay = useCallback(() => {
    showControls();
    hideControlsWithDelay();
  }, [hideControlsWithDelay, showControls]);

  const toggleControls = useCallback(() => {
    toggleOpacity();
  }, [toggleOpacity]);

  return useMemo(
    () => ({
      displayed,
      opacityAnimatedStyle,
      hideControlsWithDelay,
      hideControls,
      showControls,
      showControlsAndHideWidthDelay,
      toggleControls,
      cancelAnimation,
      opacity,
    }),
    [
      displayed,
      opacityAnimatedStyle,
      hideControlsWithDelay,
      hideControls,
      showControls,
      showControlsAndHideWidthDelay,
      toggleControls,
      cancelAnimation,
      opacity,
    ],
  );
};

export default useAVControlsAnimation;
