import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {SMALL_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';

const ORDER_SIZE = 32;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: SMALL_BORDER_RADIUS,
  },
  wrapper: {
    flex: 1,
    borderRadius: SMALL_BORDER_RADIUS,
    shadowColor: colors.black100,
    shadowOffset: {
      height: 2,
      width: 2,
    },
    shadowOpacity: 0.55,
    shadowRadius: 12,
    elevation: 3,
    backgroundColor: colors.black100,
  },
  image: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: SMALL_BORDER_RADIUS,
  },
  gradientBackground: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: SMALL_BORDER_RADIUS,
  },
  playButtonWrapper: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  bottom: {
    flexDirection: 'row',
    padding: spacings.l,
    alignItems: 'flex-end',
  },
  orderWrapper: {
    width: ORDER_SIZE,
    height: ORDER_SIZE,
    borderRadius: ORDER_SIZE / 2,
    borderWidth: 1,
    borderColor: colors.whiteAlpha50,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: spacings.xxxs,
  },
  order: {
    ...typography.inputBold,
    color: colors.white,
  },
  labelWrapper: {
    flex: 1,
    paddingBottom: spacings.xxxxs,
  },
  time: {
    ...typography.input,
    color: colors.whiteAlpha50,
  },
  title: {
    ...typography.title3DemiBold,
    color: colors.whiteAlpha95,
    marginTop: spacings.xxxs,
  },
});

export default styles;
