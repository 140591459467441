import * as R from 'ramda';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SessionUnitsFragment} from '../generated/graphql';
import {
  GeneralUnits,
  TextUnitAlignment,
  UnitRuleToInsert,
} from '../types/courses';
import {getSessionUnitsList} from '../utils/courses';

const useSessionUnitsRules = (
  sessionUnits: SessionUnitsFragment['Sessions_Units'] | null | undefined,
  sessionId: string | undefined,
) => {
  const {t} = useTranslation();

  const firstNoteSaved = useRef(false);

  const summaryUnit = useMemo<GeneralUnits>(
    () => ({
      id: 'summaryUnit',
      __typename: 'GeneralUnits',
      Alignment: TextUnitAlignment.CENTER,
      Label: t('courses.you_completed_session'),
    }),
    [t],
  );

  const getSavedNoteUnit = useCallback<(noteId: string) => GeneralUnits>(
    noteId => ({
      id: 'savedNoteUnit_' + noteId,
      __typename: 'GeneralUnits',
      Alignment: TextUnitAlignment.CENTER,
      Description: t('courses.we_saved_your_note'),
      descriptionTextAlign: 'center',
    }),
    [t],
  );

  const initialUnitsRules = useMemo<UnitRuleToInsert[]>(
    () => [{rule: R.length, unit: summaryUnit}],
    [summaryUnit],
  );

  const [unitsRules, setUnitsRules] =
    useState<UnitRuleToInsert[]>(initialUnitsRules);

  const units = useMemo(
    () => getSessionUnitsList(sessionUnits, {unitsRules}),
    [sessionUnits, unitsRules],
  );

  const onNoteSaved = useCallback<(id: string, index: number) => void>(
    (id, index) => {
      if (firstNoteSaved.current) {
        return;
      }

      setUnitsRules(previous =>
        R.prepend({rule: _ => index + 1, unit: getSavedNoteUnit(id)}, previous),
      );
      firstNoteSaved.current = true;
    },
    [getSavedNoteUnit],
  );

  // Reset units rules on a new session
  useEffect(() => {
    firstNoteSaved.current = false;
    setUnitsRules(initialUnitsRules);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  return useMemo(() => ({units, onNoteSaved}), [onNoteSaved, units]);
};

export default useSessionUnitsRules;
