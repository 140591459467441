import React, {useMemo} from 'react';
import {
  Insets,
  StyleProp,
  TouchableHighlight,
  View,
  ViewStyle,
} from 'react-native';
import colors from '../../theme/colors';
import styles from './styles';
import InfoIcon from '../../icons/InfoIcon';
import AppText from '../AppText';
import {useTranslation} from 'react-i18next';

type Props = {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
};

const HIT_SLOP: Insets = {bottom: 10, left: 10, right: 10, top: 10};

const ImportantInfo: React.FC<Props> = ({onPress, style}) => {
  const {t} = useTranslation();

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, style],
    [style],
  );

  return (
    <TouchableHighlight
      onPress={onPress}
      underlayColor={colors.whiteAlpha10}
      hitSlop={HIT_SLOP}
      style={containerStyle}>
      <>
        <AppText style={styles.label}>
          {t('common.important_information')}
        </AppText>
        <View style={styles.button}>
          <InfoIcon fillOpacity={0.56} />
        </View>
      </>
    </TouchableHighlight>
  );
};

export default React.memo(ImportantInfo);
