import {useMemo} from 'react';
import {
  BaseAppUsageByUserFragment,
  NullsOrder,
  SortOrder,
  useAppUsageByUserQuery,
  useFindManyNotesQuery,
} from '../generated/graphql';
import InterestsIcon from '../icons/InterestsIcon';
import NotesIcon from '../icons/NotesIcon';
import StatisticsIcon from '../icons/StatisticsIcon';
import {AppNote, MyAreaSection, MyAreaSectionKey} from '../types/myArea';
import {reportError} from '../utils/loggingHelpers';

export const STATISTICS_SECTION: MyAreaSection = {
  key: MyAreaSectionKey.STATISTICS,
  title: 'my_area.development',
  Icon: StatisticsIcon,
};

export const NOTES_SECTION: MyAreaSection = {
  key: MyAreaSectionKey.NOTES,
  title: 'my_area.your_notes',
  Icon: NotesIcon,
};

export const INTERESTS_SECTION: MyAreaSection = {
  key: MyAreaSectionKey.INTERESTS,
  title: 'my_area.your_interests',
  Icon: InterestsIcon,
  info: {title: 'home.your_interests', description: 'my_area.interests_info'},
};

export const SECTIONS: MyAreaSection[] = [
  STATISTICS_SECTION,
  NOTES_SECTION,
  INTERESTS_SECTION,
];

const useMyAreaScreen = ({from, to}: {from: string; to: string}) => {
  const {
    data: notesData,
    loading: notesLoading,
    error: notesError,
  } = useFindManyNotesQuery({
    variables: {
      skip: 0,
      take: 1,
      orderBy: {
        date_created: {
          sort: SortOrder.Desc,
          nulls: NullsOrder.Last,
        },
      },
    },
    onError: err => reportError('useFindManyNotesQuery error', err),
  });

  const note = useMemo<AppNote | undefined>(() => {
    if (
      !notesData ||
      !notesData?.findManyNotes ||
      !notesData?.findManyNotes?.[0]
    ) {
      return;
    }

    const incomingNote = notesData.findManyNotes[0];

    return {
      id: incomingNote.id,
      dateCreated: incomingNote.date_created,
      label: incomingNote.NoteUnits?.Label,
      description: incomingNote.Description,
    };
  }, [notesData]);

  const {
    data: statisticsData,
    loading: statisticsLoading,
    error: statisticsError,
  } = useAppUsageByUserQuery({
    variables: {from, to},
    onError: err => reportError('useAppUsageByUserQuery error', err),
  });

  const statistics = useMemo<BaseAppUsageByUserFragment | undefined>(
    () => statisticsData?.appUsageByUser,
    [statisticsData?.appUsageByUser],
  );

  return useMemo(
    () => ({
      note,
      notesLoading,
      notesError,
      statistics,
      statisticsLoading,
      statisticsError,
    }),
    [
      note,
      notesError,
      notesLoading,
      statistics,
      statisticsError,
      statisticsLoading,
    ],
  );
};

export default useMyAreaScreen;
