import * as R from 'ramda';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TagGroups_Tags, useFindManyBookingsV3Query} from '../generated/graphql';
import {useAppContext} from '../store/contexts/AppContext';
import {BookingItemType, ProductFilterItem} from '../types/booking';
import {CustomTag, CustomTagGroup, FilterButtonType} from '../types/common';
import {ALL_FILTER_KEY} from '../utils/constants';
import {getTagsIdsFromTagGroups} from '../utils/tags';
import useBookingQueryVariables from './useBookingQueryVariables';
import useListQuery from './useListQuery';

const processTagGroups = (tagGroups: TagGroups_Tags[]) => {
  if (!tagGroups) {
    return undefined;
  }
  const result: CustomTagGroup[] = [];

  tagGroups.forEach(({TagGroups}) => {
    if (
      !TagGroups?.id ||
      !TagGroups?.TagGroups_translations?.[0]?.Label ||
      !TagGroups?.TagGroups_Tags
    ) {
      return;
    }

    result.push({
      id: TagGroups.id,
      label: TagGroups.TagGroups_translations[0].Label,
      tags: TagGroups.TagGroups_Tags.map(({Tags}) => ({
        id: Tags!.id,
        label: Tags!.Tags_translations?.[0]?.Label || 'NO_TRANSLATION_FROM_CMS',
      })),
    });
  });

  return result;
};

type CategoryType = {
  [key in BookingItemType.ONE_TO_ONE_SESSION | typeof ALL_FILTER_KEY]: string[];
};

const INITIAL_TAGS: CategoryType = {
  ALL: [],
  [BookingItemType.ONE_TO_ONE_SESSION]: [],
};

const useBookingItems = () => {
  const {t} = useTranslation();

  const {bookingScreenSetup, screenSetupLoading, screenSetupError} =
    useAppContext();

  const [selectedCategory, setSelectedCategory] =
    useState<string>(ALL_FILTER_KEY);

  const [selectedTagIds, setSelectedTagIds] =
    useState<CategoryType>(INITIAL_TAGS);

  const selectTagIds = useCallback(
    (tagIds: string[]) =>
      setSelectedTagIds(prevState => {
        return selectedCategory === BookingItemType.ONE_TO_ONE_SESSION
          ? {
              ...prevState,
              [BookingItemType.ONE_TO_ONE_SESSION]: tagIds,
            }
          : {
              ...prevState,
              [ALL_FILTER_KEY]: tagIds,
            };
      }),
    [selectedCategory],
  );

  const selectedTags = useMemo(
    () =>
      selectedCategory === BookingItemType.ONE_TO_ONE_SESSION
        ? selectedTagIds[selectedCategory]
        : selectedTagIds[ALL_FILTER_KEY],
    [selectedCategory, selectedTagIds],
  );

  const productFilterItems = useMemo<ProductFilterItem[]>(() => {
    const bookingProducts =
      bookingScreenSetup?.Booking_translations?.[0]
        ?.Booking_translations_Products;

    const filteredProducts = R.sort(
      R.ascend(({sort}) => sort ?? 1000),
      bookingProducts ?? [],
    );

    const items = filteredProducts.map(({Products}) => ({
      id: Products?.id,
      label: Products?.Products_translations?.[0].Label,
      value: Products?.Label,
    }));

    return items;
  }, [bookingScreenSetup?.Booking_translations]);

  const tagsFilters = useMemo<CustomTagGroup[] | undefined>(() => {
    const eventTagGroups =
      bookingScreenSetup?.GeneralSetting_Booking_GeneralSettingToGeneralSetting
        ?.GeneralSetting_translations?.[0]
        ?.GeneralSetting_translations_TagGroups;

    const coachingTagGroups =
      bookingScreenSetup?.GeneralSetting_Booking_GeneralSettingToGeneralSetting
        ?.GeneralSetting_translations?.[0]
        ?.GeneralSetting_translations_TagGroupsOneToOneSessions;

    const tagGroups =
      selectedCategory === BookingItemType.ONE_TO_ONE_SESSION
        ? coachingTagGroups
        : eventTagGroups;

    return processTagGroups(tagGroups as TagGroups_Tags[]);
  }, [
    bookingScreenSetup?.GeneralSetting_Booking_GeneralSettingToGeneralSetting
      ?.GeneralSetting_translations,
    selectedCategory,
  ]);

  const tagsIds = useMemo<CustomTag['id'][]>(
    () => getTagsIdsFromTagGroups(tagsFilters ?? []),
    [tagsFilters],
  );

  const selectedTagsForRequest = useMemo(
    () => (selectedTags && selectedTags.length > 0 ? selectedTags : tagsIds),
    [selectedTags, tagsIds],
  );

  const formattedFilters = useMemo<FilterButtonType[]>(
    () =>
      productFilterItems.reduce<FilterButtonType[]>(
        (accumulated, {label, value}) => {
          if (!label || !value) {
            return accumulated;
          }

          const item: FilterButtonType = {
            isSelected: selectedCategory === value,
            label: label,
            id: value,
          };

          return [...accumulated, item];
        },
        [],
      ),
    [productFilterItems, selectedCategory],
  );

  const formattedFiltersLabels = useMemo(
    () => R.pluck('id', formattedFilters),
    [formattedFilters],
  );

  const categories = useMemo<FilterButtonType[]>(() => {
    const allFilter: FilterButtonType = {
      id: ALL_FILTER_KEY,
      isSelected: selectedCategory === ALL_FILTER_KEY,
      label: t('booking.all'),
    };

    return [allFilter, ...formattedFilters];
  }, [formattedFilters, selectedCategory, t]);

  const selectCategory = useCallback<(key: string) => void>(
    key => {
      if (key === ALL_FILTER_KEY) {
        setSelectedCategory(ALL_FILTER_KEY);
        return;
      }

      const isAllowedCategory = formattedFiltersLabels.includes(key);

      isAllowedCategory
        ? setSelectedCategory(key)
        : setSelectedCategory(ALL_FILTER_KEY);
    },
    [formattedFiltersLabels],
  );

  const getBookingQueryVariables = useBookingQueryVariables({
    selectedTags: selectedTagsForRequest,
    selectedCategory:
      selectedCategory === ALL_FILTER_KEY ? null : selectedCategory,
    allCategories: formattedFiltersLabels,
  });

  const {
    data: bookingData,
    loading: itemsLoading,
    error: itemsError,
    fetchNext,
    refetch,
    previousData: previousBookingData,
    loadingMore,
    refetchWithParams,
    refreshing,
  } = useListQuery(
    useFindManyBookingsV3Query,
    getBookingQueryVariables,
    'findManyBookingsV3',
  );

  useEffect(() => {
    if (selectedTags) {
      refetch();
    }
  }, [refetch, selectedTags]);

  return useMemo(
    () => ({
      tagsFilters,
      categories,
      bookingData,
      refreshing,
      refetchWithParams,
      loading: screenSetupLoading || (itemsLoading && !previousBookingData),
      error: screenSetupError ?? itemsError,
      fetchNext,
      selectedCategory,
      selectCategory,
      previousBookingData,
      selectedTags,
      selectTagIds,
      loadingMore,
    }),
    [
      tagsFilters,
      categories,
      bookingData,
      refreshing,
      refetchWithParams,
      screenSetupLoading,
      itemsLoading,
      previousBookingData,
      screenSetupError,
      itemsError,
      fetchNext,
      selectedCategory,
      selectCategory,
      selectedTags,
      selectTagIds,
      loadingMore,
    ],
  );
};

export default useBookingItems;
