import {useLayout} from '@react-native-community/hooks';
import Lottie from 'lottie-react-native';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import FastImage, {ImageStyle, Source} from 'react-native-fast-image';
import {AppInfoOverlay} from '../../types/infoOverlays';
import {getImageUrl} from '../../utils/files';
import AppModal from '../AppModal';
import HTMLRenderer from '../HTMLRenderer';
import styles, {IMAGE_RATIO, descriptionTagStyles} from './styles';

type Props = AppInfoOverlay & {
  isVisible: boolean;
  onClose: () => void;
};

const InfoOverlay: React.FC<Props> = ({
  animation,
  buttonLabel,
  description,
  directus_files,
  title,
  isVisible,
  showNextIcon,
  showBackIcon,
  showCloseButton = true,
  loopAnimation = true,
  onClose,
  onSubmit,
}) => {
  const {t} = useTranslation();
  const {width, onLayout} = useLayout();

  const buttonTitle = buttonLabel ?? t('common.ok');

  const hasFile = !!directus_files?.filename_disk;

  const image = useMemo<Source | null>(() => {
    if (!hasFile) {
      return null;
    }

    const imageUrl = getImageUrl(directus_files, {
      width,
      aspectRatio: IMAGE_RATIO,
      resizeMode: 'contain',
    });
    return imageUrl ? {uri: imageUrl} : null;
  }, [directus_files, hasFile, width]);

  const imageStyle = useMemo<ImageStyle>(
    () => ({...styles.image, width, height: width / IMAGE_RATIO}),
    [width],
  );

  const submit = useCallback(() => {
    onSubmit?.();
    onClose();
  }, [onClose, onSubmit]);

  return (
    <AppModal
      showCloseButton={showCloseButton}
      onDismiss={showCloseButton ? onClose : undefined}
      isVisible={isVisible}
      title={title}
      contentStyle={styles.modalContent}
      submitButtonTitle={buttonTitle}
      showNextIcon={showNextIcon}
      showBackIcon={showBackIcon}
      onSubmit={submit}
      dismissable={false}
      useDefaultHeight={false}>
      <View onLayout={onLayout}>
        {animation ? (
          <Lottie
            source={animation}
            autoPlay
            loop={loopAnimation}
            style={imageStyle}
          />
        ) : hasFile ? (
          <View style={styles.imageContainer}>
            {image ? <FastImage source={image} style={imageStyle} /> : null}
          </View>
        ) : null}
        <View>
          {description ? (
            <HTMLRenderer
              content={description}
              tagsStyles={descriptionTagStyles}
            />
          ) : null}
        </View>
      </View>
    </AppModal>
  );
};

export default React.memo(InfoOverlay);
