import {useCallback, useMemo} from 'react';
import NavigationKeys from '../../navigation/NavigationKeys';
import {
  BaseCourseFragment,
  BaseSessionFragment,
  Tags,
} from '../generated/graphql';
import {useAppContext} from '../store/contexts/AppContext';
import useAppModal from './useAppModal';
import useLibraryTags from './useLibraryTags';
import useProgressReports from './useProgressReports';

const useLibraryItemsInterestsModal = (
  tags?: Tags['id'][] | undefined,
  options?: {onTagsSelect?: (tagsIds: Tags['id'][]) => void},
) => {
  const {hideModal, isModalVisible, showModal} = useAppModal();

  const {reportOpenSession, reportOpenCourse} = useProgressReports();

  const {user} = useAppContext();

  const {
    selectTag,
    selectedTagsWithExternal,
    selectedTagsRecord,
    tagsFilters,
    tagsIds,
    selectedTagsIds,
    resetSelectedTags,
    updateSelectedTagsWithExternal,
    deselectAllTags,
    selectAllTags,
  } = useLibraryTags(tags, {onTagsSelect: options?.onTagsSelect});

  const closeModal = useCallback(() => {
    hideModal();
    resetSelectedTags();
  }, [hideModal, resetSelectedTags]);

  const submitTags = useCallback(() => {
    updateSelectedTagsWithExternal();
    hideModal();
  }, [hideModal, updateSelectedTagsWithExternal]);

  const onSessionPress = useCallback<(id: BaseSessionFragment['id']) => void>(
    id => {
      reportOpenSession({
        screen: NavigationKeys.LibraryScreen,
        sessionId: id,
        email: user?.EMail,
      });
    },
    [reportOpenSession, user?.EMail],
  );

  const onCoursePress = useCallback<
    (id: BaseCourseFragment['id'], difference: number) => void
  >(
    (id, difference) => {
      reportOpenCourse({
        screen: NavigationKeys.LibraryScreen,
        courseId: id,
        courseProgress: difference,
        email: user?.EMail,
      });
    },
    [reportOpenCourse, user?.EMail],
  );

  return useMemo(
    () => ({
      isModalVisible,
      showModal,
      closeModal,
      submitTags,
      onCoursePress,
      onSessionPress,
      selectTag,
      selectAllTags,
      deselectAllTags,
      selectedTagsWithExternal,
      selectedTagsRecord,
      selectedTagsIds,
      tagsFilters,
      tagsIds,
    }),
    [
      isModalVisible,
      showModal,
      closeModal,
      submitTags,
      onCoursePress,
      onSessionPress,
      selectTag,
      selectAllTags,
      deselectAllTags,
      selectedTagsWithExternal,
      selectedTagsRecord,
      selectedTagsIds,
      tagsFilters,
      tagsIds,
    ],
  );
};

export default useLibraryItemsInterestsModal;
