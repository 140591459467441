import React, {forwardRef, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View, ViewProps} from 'react-native';
import Animated, {FadeIn, FadeOut, Layout} from 'react-native-reanimated';
import {
  getEntryOrExitLayoutAnimation,
  getReanimatedLayoutAnimation,
} from '../../utils/animation';
import AnimatedProgressBar from '../AnimatedProgressBar';
import AppButton from '../AppButton';
import LayoutAnimationWrapper from '../LayoutAnimationWrapper';
import styles from './styles';

type Props = ViewProps & {
  canGoBack: boolean;
  currentPage: number;
  totalPages: number;
  onBack?: () => void;
};

const ANIMATION_DURATION = 300;

const OnboardingHeader: React.ForwardRefExoticComponent<
  Props & React.RefAttributes<View>
> = forwardRef(
  (
    {canGoBack, currentPage, totalPages, style, testID, onBack, ...props},
    ref,
  ) => {
    const {t} = useTranslation();

    const containerStyle = useMemo(() => [styles.container, style], [style]);

    return (
      <LayoutAnimationWrapper
        duration={ANIMATION_DURATION}
        initial="hidden"
        exit="hidden"
        animate="visible">
        <Animated.View
          ref={ref}
          style={containerStyle}
          layout={getReanimatedLayoutAnimation(
            Layout.duration(ANIMATION_DURATION),
          )}
          entering={getEntryOrExitLayoutAnimation(
            FadeIn.duration(ANIMATION_DURATION),
          )}
          exiting={getEntryOrExitLayoutAnimation(
            FadeOut.duration(ANIMATION_DURATION),
          )}
          testID={testID}
          {...props}>
          <View style={styles.space}>
            <AppButton
              mode="text"
              onPress={onBack}
              testID={`${testID}.returnButton`}
              disabled={!canGoBack}>
              {canGoBack ? t('common.return') : ' '}
            </AppButton>
          </View>
          <View style={styles.progressBar}>
            <AnimatedProgressBar current={currentPage} total={totalPages} />
          </View>
          <View style={styles.space} />
        </Animated.View>
      </LayoutAnimationWrapper>
    );
  },
);

export default React.memo(OnboardingHeader);
