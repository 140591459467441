import {StyleSheet} from 'react-native';
import colors from '../../../theme/colors';
import spacings from '../../../theme/spacings';
import typography from '../../../theme/typography';

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'row',
    gap: spacings.xxl,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  labelWrapper: {
    flex: 1,
    justifyContent: 'center',
  },
  label: {
    ...typography.input,
    color: colors.whiteAlpha75,
  },
});

export default styles;
