import React, {useCallback, useEffect, useMemo} from 'react';
import ExpertlineConnection from '../components/ExpertlineConnection';
import {BaseBookingFragment} from '../generated/graphql';
import {BookingItemType} from '../types/booking';
import useAppModal from './useAppModal';
import useBookingItems from './useBookingItems';
import useExpertlines from './useExpertlines';
import useSelectTags from './useSelectTags';

const useBookingScreen = ({
  selectedCategory: initiallySelectedCategory,
  onUpdateCategory,
  onExpertlinePress,
}: {
  selectedCategory?: BookingItemType;
  onUpdateCategory?: (category?: string, data?: BaseBookingFragment[]) => void;
  onExpertlinePress?: () => void;
}) => {
  const {
    tagsFilters,
    selectCategory,
    selectTagIds,
    selectedTags,
    selectedCategory,
    bookingData,
    ...rest
  } = useBookingItems();

  const {
    selectedTagsIds: draftSelectedTags,
    selectedTagsRecord,
    selectTag,
    selectAllTags,
    deselectAllTags,
    resetSelectedTags,
  } = useSelectTags({
    initiallySelectedTags: selectedTags,
    tagsFilters,
  });

  const {data: expertlines} = useExpertlines();

  const {hideModal, isModalVisible, showModal} = useAppModal();

  const closeModal = useCallback(() => {
    hideModal({onModalHide: resetSelectedTags});
  }, [hideModal, resetSelectedTags]);

  const submitTags = useCallback(async () => {
    selectTagIds(draftSelectedTags);
    hideModal();
  }, [draftSelectedTags, hideModal, selectTagIds]);

  const switchCategory = useCallback<(key: string) => void>(
    key => {
      selectCategory(key);

      onUpdateCategory?.(key, bookingData);
    },
    [bookingData, onUpdateCategory, selectCategory],
  );

  const ExpertlineElement = useMemo<React.ReactElement>(
    () =>
      selectedCategory === BookingItemType.ONE_TO_ONE_SESSION &&
      expertlines &&
      expertlines.length > 0 ? (
        <ExpertlineConnection onPress={onExpertlinePress} key="expertline" />
      ) : (
        <></>
      ),
    [expertlines, onExpertlinePress, selectedCategory],
  );

  useEffect(() => {
    initiallySelectedCategory && selectCategory(initiallySelectedCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiallySelectedCategory]);

  useEffect(() => {
    // keep a synchronous info about the selected tags
    resetSelectedTags();
  }, [tagsFilters, resetSelectedTags]);

  return useMemo(
    () => ({
      bookingData,
      tagsFilters,
      selectedTags,
      selectedTagsRecord,

      isModalVisible,
      showModal,
      closeModal,

      selectTag,
      submitTags,
      switchCategory,
      deselectAllTags,
      selectAllTags,

      ExpertlineElement,
      ...rest,
    }),
    [
      ExpertlineElement,
      bookingData,
      closeModal,
      deselectAllTags,
      isModalVisible,
      rest,
      selectAllTags,
      selectTag,
      selectedTags,
      selectedTagsRecord,
      showModal,
      submitTags,
      switchCategory,
      tagsFilters,
    ],
  );
};

export default useBookingScreen;
