import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  content: {
    gap: Platform.select({default: spacings.xxxs, web: spacings.m}),
  },
  progress: {
    width: Platform.select({default: '50%', web: '40%'}),
  },
  subtitle: {
    ...typography.inputDemiBold,
    color: colors.whiteAlpha50,
  },
});

export default styles;
