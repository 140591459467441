import Lottie from 'lottie-react-native';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, View, ViewProps, ViewStyle} from 'react-native';
import AppText from '../AppText';
import styles from './styles';
import RoundBackButton from '../RoundBackButton';

type Props = ViewProps & {
  errorTitle?: string;
  errorSubtitle?: string;
  onPressBack?: () => void;
};

const ErrorContent: React.FC<Props> = ({
  errorTitle,
  errorSubtitle,
  style,
  onPressBack,
  ...props
}) => {
  const {t} = useTranslation();

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, style],
    [style],
  );

  return (
    <View style={containerStyle} {...props}>
      {onPressBack ? (
        <RoundBackButton onPress={onPressBack} style={styles.backButton} />
      ) : null}
      <View style={styles.content}>
        <Lottie
          source={require('../../assets/animations/error_animation.json')}
          autoPlay
          loop={true}
          style={styles.animation}
        />
        <AppText style={styles.title}>
          {errorTitle ?? t('errors.maybe_take_a_breath')}
        </AppText>
        <AppText style={styles.subtitle}>
          {errorSubtitle ?? t('errors.something_went_wrong')}
        </AppText>
      </View>
    </View>
  );
};

export default React.memo(ErrorContent);
