import React from 'react';
import styles from './page.module.scss';
import {useTranslation} from 'react-i18next';
import AppInput from '../../../mindance-libs/components/AppInput';
import ControlBottomButtons from '../../../components/ControlBottomButtons/ControlBottomButtons';
import ContactUs, {
  TitleVariants,
} from '../../../mindance-libs/components/ContactUs';
import {TextInputProps} from 'react-native';

type Props = {
  password: string;
  setPassword: (value: string) => void;
  hasPasswordError: boolean;
  confirmPassword: string;
  setConfirmPassword: (value: string) => void;
  hasConfirmPasswordError: boolean;
  onSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  isValid: boolean;
  isSubmitting: boolean;
  handlePasswordBlur: NonNullable<TextInputProps['onBlur']>;
  handleConfirmPasswordBlur: NonNullable<TextInputProps['onBlur']>;
};

const SuccessContent: React.FC<Props> = ({
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  hasConfirmPasswordError,
  hasPasswordError,
  onSubmit,
  isSubmitting,
  isValid,
  handleConfirmPasswordBlur,
  handlePasswordBlur,
}) => {
  const {t} = useTranslation();

  return (
    <>
      <div className={styles['password_change__big-space']} />
      <h1 className={styles.password_change__title}>
        {t('auth.change_password')}
      </h1>
      <span className={styles.password_change__description}>
        {t('auth.change_password_enter_new_password')}
      </span>
      <div className={styles.password_change__space} />
      <form>
        <div className={styles.password_change__input}>
          <AppInput
            label={t('auth.password')}
            value={password}
            onChangeText={setPassword}
            onBlur={handlePasswordBlur}
            autoCapitalize="none"
            isPassword
            error={hasPasswordError}
            testID="resetPassword.passwordInput"
          />
        </div>
        <div className={styles.password_change__input}>
          <AppInput
            label={t('auth.confirm_password')}
            value={confirmPassword}
            onChangeText={setConfirmPassword}
            onBlur={handleConfirmPasswordBlur}
            autoCapitalize="none"
            isPassword
            error={hasConfirmPasswordError}
            testID="resetPassword.confirmPasswordInput"
          />
        </div>
      </form>
      <div className={styles.password_change__space} />
      <div className={styles.password_change__bottom}>
        <ControlBottomButtons
          showNextIcon
          showReturnButton={false}
          submitButtonLabel={t('common.confirm')}
          onSubmit={onSubmit}
          submitButtonDisabled={!isValid || isSubmitting}
        />
      </div>

      <div className={styles.password_change__space} />

      <div className={styles['password_change__contact-us']}>
        <ContactUs variant={TitleVariants.AUTH_PROBLEM} />
      </div>
    </>
  );
};

export default React.memo(SuccessContent);
