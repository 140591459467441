import {Platform, StyleSheet} from 'react-native';
import spacings from '../../theme/spacings';
import {BOOKING_DETAILS_HORIZONTAL_PADDING} from '../../utils/constants';
import colors from '../../theme/colors';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  container: {
    paddingTop: spacings.l,
    paddingHorizontal: BOOKING_DETAILS_HORIZONTAL_PADDING,
  },
  itemWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: spacings.xl,
  },
  itemLabel: {
    marginLeft: spacings.l,
    ...typography.input,
    color: colors.whiteAlpha80,
    marginBottom: Platform.select({default: 0, web: spacings.xxxs}),
  },
});

export default styles;
