import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import * as R from 'ramda';
import {useMemo} from 'react';
import {useAppContext} from '../store/contexts/AppContext';
import {DataStatuses} from '../types/common';

export type CodeInfo = {
  id: string;
  code: string;
  expirationDate?: string;
};

const getEarliestDate = (
  dates: (string | undefined | null)[],
): string | undefined => {
  const filteredDates = R.filter(R.isNotNil, dates);

  if (filteredDates.length === 0) {
    return;
  }

  const earliestDate = filteredDates.reduce((earliest, current) =>
    current < earliest ? current : earliest,
  );

  return earliestDate;
};

const useRedeemActivationCodeScreen = () => {
  const {user} = useAppContext();

  const codesInfo = useMemo<CodeInfo[] | undefined>(() => {
    const list = user?.Users_ActivationCodes?.reduce<CodeInfo[]>(
      (acc, {ActivationCodes, redeemed_at, status}) => {
        if (!ActivationCodes || status !== DataStatuses.PUBLISHED) {
          return acc;
        }

        const id = ActivationCodes.id;
        const code = ActivationCodes.Code;

        const subscriptionsInfo =
          ActivationCodes.Subscriptions_ActivationCodes?.flatMap(
            ({Subscriptions}) => Subscriptions,
          );

        const subscriptionsExpirationDates = R.filter(
          R.isNotNil,
          subscriptionsInfo ?? [],
        ).map(({ActivationPeriodInDays, ActivationUntil, ContractEnd}) => {
          const periodEndDate =
            redeemed_at && ActivationPeriodInDays
              ? addDays(
                  new Date(redeemed_at),
                  ActivationPeriodInDays,
                ).toISOString()
              : undefined;

          return getEarliestDate([periodEndDate, ActivationUntil, ContractEnd]);
        });

        const earliestDate = getEarliestDate(subscriptionsExpirationDates);

        if (earliestDate && isAfter(new Date(), new Date(earliestDate))) {
          return acc;
        }

        return [...acc, {id, code, expirationDate: earliestDate}];
      },
      [],
    );

    return list && list.length > 0 ? list : undefined;
  }, [user?.Users_ActivationCodes]);

  return useMemo(() => ({codesInfo}), [codesInfo]);
};

export default useRedeemActivationCodeScreen;
