import * as R from 'ramda';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {NavSection} from '../components/Sidebar/SidebarNavigation/SidebarNavSection/SidebarNavSection';
import {useSubscriptionsContext} from '../contexts/SubscriptionsContext';
import {useAppContext} from '../mindance-libs/store/contexts/AppContext';
import {AppProductsLabels} from '../mindance-libs/types/common';
import {RouteKeys} from '../navigation/RouteKeys';
import useCompanyConfig from '../mindance-libs/hooks/useCompanyConfig';

const DEFAULT_DATA = {
  home: {
    label: 'Home',
    iconKey: 'fa-solid fa-house',
    routeKey: RouteKeys.Home,
  },

  appTitle: 'Mindance App',
  managerTitle: 'Mind Manager',
};

const useSidebarNavigationItems = () => {
  const {t} = useTranslation();
  const {
    screenSetupLoading,
    screensSetupCategoriesAvailability,
    isMindManager,
  } = useAppContext();
  const {mindManagerTools, loading} = useSubscriptionsContext();

  const {highlightColor} = useCompanyConfig();

  const sectionsData = useMemo<NavSection[]>(() => {
    const appPages = [
      screensSetupCategoriesAvailability[AppProductsLabels.HOME]
        ? {
            id: 'home',
            label: t('tabNavigator.home'),
            shortTitle: t('tabNavigator.home'),
            iconKey: 'fa-solid fa-house',
            routeKey: RouteKeys.Start,
          }
        : null,
      screensSetupCategoriesAvailability[AppProductsLabels.LIBRARY]
        ? {
            id: 'library',
            label: t('tabNavigator.library'),
            shortTitle: t('tabNavigator.library'),
            iconKey: 'fa-solid fa-book',
            routeKey: RouteKeys.Library,
          }
        : null,
      screensSetupCategoriesAvailability[AppProductsLabels.BOOKING]
        ? {
            id: 'booking',
            label: t('tabNavigator.booking'),
            shortTitle: t('tabNavigator.booking'),
            iconKey: 'fa-solid fa-calendar-days',
            routeKey: RouteKeys.Booking,
            highlightColor,
          }
        : null,
      screensSetupCategoriesAvailability[AppProductsLabels.NEWS]
        ? {
            id: 'news',
            label: t('tabNavigator.news'),
            shortTitle: t('tabNavigator.news'),
            iconKey: 'fa-regular fa-envelope',
            routeKey: RouteKeys.News,
          }
        : null,
      {
        id: 'myArea',
        label: t('tabNavigator.myArea'),
        shortTitle: t('tabNavigator.myArea'),
        iconKey: 'fa-solid fa-user',
        routeKey: RouteKeys.MyArea,
      },
    ];

    const appItems = R.filter(R.isNotNil, appPages);
    const appItemsExist = appItems && appItems.length;

    const managerPages = mindManagerTools
      ?.filter(({isAvailable}) => !!isAvailable)
      ?.map(item => ({
        id: item.id,
        iconKey: item.IconKey ?? '',
        label: item?.ManagerTools_translations?.[0]?.Label ?? '',
        shortTitle: item.ManagerTools_translations?.[0]?.ShortTitle ?? '',
        routeKey: item.routeKey ?? '',
      }));

    const managerItems = [
      {
        id: 'home',
        iconKey: DEFAULT_DATA.home.iconKey,
        label: DEFAULT_DATA.home.label,
        shortTitle: DEFAULT_DATA.home.label,
        routeKey: DEFAULT_DATA.home.routeKey,
      },
      ...managerPages,
    ];

    const sections = [
      appItemsExist
        ? {
            title: DEFAULT_DATA.appTitle,
            items: appItems,
          }
        : null,
      isMindManager
        ? {
            title: DEFAULT_DATA.managerTitle,
            items: managerItems,
          }
        : null,
    ];

    return R.filter(R.isNotNil, sections);
  }, [
    highlightColor,
    isMindManager,
    mindManagerTools,
    screensSetupCategoriesAvailability,
    t,
  ]);

  return useMemo(
    () => ({
      loading: loading || screenSetupLoading,
      sectionsData,
    }),
    [loading, screenSetupLoading, sectionsData],
  );
};

export default useSidebarNavigationItems;
