import {Platform, StyleSheet} from 'react-native';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  input: {
    marginHorizontal: Platform.select({default: spacings.xs, web: 0}),
    marginTop: spacings.xxl,
  },
  text: {
    ...typography.footnote,
  },
  link: {
    ...typography.footnote,
    textDecorationLine: 'underline',
  },
  textBold: {
    ...typography.footnoteBold,
  },
  linkWrapper: {
    marginTop: spacings.xl,
  },
});

export default styles;
