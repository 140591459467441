import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import packageJson from '../../../../package.json';
import AlertModal from '../../../components/AlertModal/AlertModal';
import Button from '../../../components/Button/Button';
import {ButtonStyle} from '../../../components/Button/Button.props';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import useAppModal from '../../../mindance-libs/hooks/useAppModal';
import useLogout from '../../../mindance-libs/hooks/useLogout';
import AlertIcon from '../../../mindance-libs/icons/AlertIcon';
import {useAppContext} from '../../../mindance-libs/store/contexts/AppContext';
import colors from '../../../mindance-libs/theme/colors';
import {RouteKeys} from '../../../navigation/RouteKeys';
import TypedLink from '../../../navigation/typed/TypedLink';
import useTypedNavigate from '../../../navigation/typed/useTypedNavigate';
import {SettingsList} from './page.data';
import styles from './page.module.scss';

export default function Page({
  showBackButton = true,
}: {
  showBackButton?: boolean;
}) {
  const {t} = useTranslation();

  const navigate = useTypedNavigate();

  const {hideModal, showModal, isModalVisible} = useAppModal();

  const {onDeleteAccount} = useLogout();

  const {userSubscriptions} = useAppContext();

  const appVersion = packageJson.version;

  const SettingsData = useMemo(
    () =>
      SettingsList(t).map(item => {
        const hasWarningIcon =
          item.href === RouteKeys.SettingsRedeemActivationCode
            ? userSubscriptions.length === 0
            : false;

        return (
          <li className={styles['settings__list__list-item']} key={item.href}>
            <TypedLink
              to={item.href}
              className={styles['settings__list__list-item__link']}>
              <div
                className={
                  styles['settings__list__list-item__link__icon-container']
                }>
                <p>{item.title}</p>
                {hasWarningIcon ? (
                  <AlertIcon fill={colors.bitterLemon} />
                ) : null}
              </div>

              <FontAwesomeIcon icon={faArrowRight} />
            </TypedLink>
          </li>
        );
      }),
    [t, userSubscriptions.length],
  );

  const backButton = useMemo(
    () => ({title: t('common.return') || '', onClick: () => navigate(-1)}),
    [navigate, t],
  );

  const deleteButton = useMemo(
    () => ({
      title: t('settings.delete_account') || '',
      onClick: () => showModal(),
    }),
    [showModal, t],
  );

  return (
    <ScreenBase>
      <AlertModal
        isOpened={isModalVisible}
        closeModal={hideModal}
        title={t('settings.delete_account')}
        description={t('settings.do_you_want_delete_account')}
        onConfirm={onDeleteAccount}
        onDecline={hideModal}
        declineButtonTitle={t('common.cancel')}
        confirmButtonTitle={t('common.ok')}
      />
      <div className={styles.settings__container}>
        {showBackButton ? (
          <Button
            showLeftArrow
            button={backButton}
            className={styles.settings__button}
            buttonStyle={ButtonStyle.INLINE_WHITE}
          />
        ) : null}
        <h1>{t('mindManagerHome.settings')}</h1>

        <div className={styles.settings__list}>
          <ul className={styles['settings__list__list-group']}>
            {SettingsData}
          </ul>
          <div className={styles.settings__bottom}>
            <p>
              {t('settings.version')} {appVersion}
            </p>
            <Button
              button={deleteButton}
              className={styles.settings__bottom__button}
              buttonStyle={ButtonStyle.INLINE_WHITE}
            />
          </div>
        </div>
      </div>
    </ScreenBase>
  );
}
