import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {CloseModalType, ModalKeys, OpenModalType} from '../utils/modal';

type ModalContextType = {
  modalType: ModalKeys | null;
  openModal: OpenModalType;
  closeModal: CloseModalType;
};

const ModalContext = createContext<ModalContextType | null>(null);

export const useModalContext = (): ModalContextType => {
  const modalContext = useContext(ModalContext);

  if (modalContext === null) {
    throw new Error(
      'Modal context cannot be null, please add a context provider.',
    );
  }

  return modalContext;
};

export const ModalContextProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [modalType, setModalType] = useState<ModalKeys | null>(null);

  const openModal = useCallback<OpenModalType>(routeKey => {
    setModalType(routeKey);
    window.history.pushState({path: routeKey}, '', routeKey);
  }, []);

  const closeModal = useCallback<CloseModalType>(() => {
    setModalType(null);
    window.history.back();
  }, []);

  const value = useMemo<ModalContextType>(
    () => ({
      modalType,
      openModal,
      closeModal,
    }),
    [closeModal, modalType, openModal],
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
