import SrtParser2 from 'srt-parser-2';
import vttToJson from 'vtt-to-json';
import {Subtitle} from '../types/subtitles';
import {reportError} from './loggingHelpers';
import urlParser from 'url-parse';

const timeToSeconds = (formattedTime: string): number => {
  const time: string[] = formattedTime.split(':');

  return time[0] && time[1] && time[2]
    ? +time[0] * 60 * 60 + +time[1] * 60 + +time[2]
    : 0;
};

export const subtitleParser = async (
  subtitleUrl: string,
): Promise<Subtitle[]> => {
  try {
    const fetchResult = await fetch(subtitleUrl, {method: 'GET'});

    const subtitleData = await fetchResult.text();

    const urlPathName = subtitleUrl
      ? new urlParser(subtitleUrl).pathname
      : undefined;
    const subtitleType = urlPathName
      ? urlPathName.split('.')[urlPathName.split('.').length - 1].toLowerCase()
      : undefined;

    if (subtitleType === 'srt') {
      const parser = new SrtParser2();

      return parser.fromSrt(subtitleData).map(({endTime, startTime, text}) => ({
        part: text,
        start: timeToSeconds(startTime),
        end: timeToSeconds(endTime),
      }));
    }

    if (subtitleType === 'vtt') {
      const parsedSubtitle = await vttToJson(subtitleData);

      return parsedSubtitle.map(({start, end, part}) => {
        return {
          start: start / 1000,
          end: end / 1000,
          // For some reason this library adds the index of the subtitle at the end of the part, so we cut it
          part: part.slice(
            0,
            part.length - part.split(' ')[part.split(' ').length - 1].length,
          ),
        };
      });
    }

    return [];
  } catch (error) {
    reportError('subtitleParser error', error);
    return [];
  }
};
