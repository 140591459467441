export enum LoadingState {
  LAZY = 'lazy',
  EAGER = 'eager',
}

export interface ExtendedImageProps extends ImageProps {
  width: number;
  height: number;
}

export default interface ImageProps {
  src: string;
  title: string;
  alt: string;
  loading: LoadingState;
}
