import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
import colors from '../theme/colors';

const BellIcon: React.FC<SvgProps> = ({...props}) => {
  return (
    <Svg
      fill={colors.black100}
      stroke={colors.black100}
      strokeWidth={1.2}
      width="11px"
      height="13px"
      viewBox="0 0 11 13"
      {...props}>
      <Path d="M4.83687 1.88723L5.31375 1.78725V1.3V0.8125C5.31375 0.675332 5.41504 0.6 5.49946 0.6C5.58387 0.6 5.68516 0.675332 5.68516 0.8125V1.3V1.78725L6.16205 1.88723C7.66877 2.20312 8.82798 3.59191 8.82798 5.28125V5.75859C8.82798 7.09628 9.30306 8.38533 10.1633 9.38891L10.1644 9.39022L10.3461 9.60097L10.3465 9.60148C10.4005 9.66393 10.4167 9.76032 10.3809 9.84224C10.3445 9.92543 10.2743 9.9625 10.2137 9.9625H0.785234C0.721788 9.9625 0.652724 9.92312 0.61971 9.84614C0.582185 9.75863 0.600802 9.66118 0.652391 9.60148L0.652837 9.60097L0.834533 9.39023L0.835662 9.38891C1.69596 8.38521 2.17094 7.09359 2.17094 5.75859V5.28125C2.17094 3.59191 3.33015 2.20312 4.83687 1.88723ZM6.28859 11.975C6.25633 12.0224 6.22016 12.067 6.1804 12.1081C5.99761 12.2971 5.75253 12.4 5.49946 12.4C5.24638 12.4 5.0013 12.2971 4.81851 12.1081C4.77875 12.067 4.74259 12.0224 4.71032 11.975H5.49946H6.28859Z" />
    </Svg>
  );
};

export default React.memo(BellIcon);
