import {useCallback, useEffect, useMemo, useState} from 'react';
import {FileState} from '../types/File';

const useFileState = (remoteFileUrl?: string) => {
  const [fileState, setFileState] = useState<FileState>({shouldBeEmpty: true});

  useEffect(() => {
    if (remoteFileUrl) {
      setFileState(previous => ({
        ...previous,
        shouldBeEmpty: false,
        hasRemote: {state: true, fileUrl: remoteFileUrl},
      }));
    }
  }, [remoteFileUrl]);

  const selectLocalFile = useCallback<(file: File) => void>(file => {
    setFileState(previous => ({
      ...previous,
      shouldBeEmpty: false,
      hasLocalFile: {state: true, file},
    }));
  }, []);

  const removeSelectedFile = useCallback(() => {
    setFileState(previous => ({
      ...previous,
      shouldBeEmpty: true,
      hasLocalFile: {state: false},
    }));
  }, []);

  return useMemo(
    () => ({fileState, selectLocalFile, removeSelectedFile}),
    [fileState, removeSelectedFile, selectLocalFile],
  );
};

export default useFileState;
