import {Platform, StyleSheet} from 'react-native';
import spacings from '../../theme/spacings';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: Platform.select({
      default: spacings.xxs,
      web: spacings.l,
    }),
  },
  item: {
    flex: 1,
  },
});

export default styles;
