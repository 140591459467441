import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  container: {
    borderBottomColor: colors.whiteAlpha06,
    borderBottomWidth: 1,
    paddingVertical: Platform.select({
      default: spacings.l,
      web: spacings.xxl,
    }),
  },
  date: {
    ...typography.input,
    color: colors.whiteAlpha30,
  },
  content: {
    marginTop: spacings.m,
  },
  title: {
    ...typography.inputBold,
  },
  description: {
    ...typography.input,
  },
  showMore: {
    ...typography.input,
    textDecorationLine: 'underline',
  },
});

export default styles;
