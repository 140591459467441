import {Platform, StyleSheet} from 'react-native';
import {BOOKING_DETAILS_HORIZONTAL_PADDING} from '../../utils/constants';
import spacings from '../../theme/spacings';
import colors from '../../theme/colors';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  container: {
    paddingVertical: spacings.xxl,
    paddingHorizontal: Platform.select({
      web: 0,
      default: BOOKING_DETAILS_HORIZONTAL_PADDING,
    }),
  },
  title: {
    ...typography.subtitleBold,
  },
  pointWrapper: {
    marginTop: spacings.xl,
  },
  point: {
    ...typography.body,
    color: colors.whiteAlpha75,
  },
  pointTitle: {
    ...typography.bodyBold,
    color: colors.whiteAlpha95,
  },
});

export default styles;
