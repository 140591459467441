import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
import colors from '../theme/colors';

const RotatedPinIcon: React.FC<SvgProps> = ({
  fill = colors.backgroundPrimary,
  ...props
}) => {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M6.73808 6.11519L9.85151 3.00176C10.3896 2.46365 9.85775 1.38633 10.4236 0.820464C10.9895 0.254596 11.6436 0.644073 11.9264 0.926879L15.0596 4.06008C15.3063 4.29328 15.6559 4.90336 15.0812 5.47806C14.5065 6.05277 13.5029 5.6168 12.9847 6.13496L9.87128 9.24839C10.5212 11.7825 9.461 13.667 8.84963 14.2925L5.22221 10.6651L1.77857 7.22143C3.42285 5.57715 5.77003 5.79882 6.73808 6.11519Z"
        fill={fill}
      />
      <Path
        d="M5.22221 10.6651C3.93283 9.3757 2.64017 8.08303 1.77857 7.22143C3.42285 5.57715 5.77003 5.79882 6.73808 6.11519C6.73808 6.11519 9.31339 3.53988 9.85151 3.00176C10.3896 2.46365 9.85775 1.38633 10.4236 0.820464C10.9895 0.254596 11.6436 0.644073 11.9264 0.926879L15.0596 4.06008C15.3063 4.29328 15.6559 4.90336 15.0812 5.47806C14.5065 6.05277 13.5029 5.6168 12.9847 6.13496C12.213 6.90661 10.5101 8.60961 9.87128 9.24839C10.5212 11.7825 9.461 13.667 8.84963 14.2925C8.01284 13.4557 6.61945 12.0623 5.22221 10.6651ZM5.22221 10.6651L0.715719 15.1716"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default React.memo(RotatedPinIcon);
