import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

const NEXT_BUTTON_SIZE = 55;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  keyboardView: {
    flex: 1,
  },
  label: {
    ...typography.title4,
    color: colors.whiteAlpha95,
    marginBottom: spacings.m,
  },
  content: {
    flex: 1,
  },
  outlineInput: {
    borderWidth: 0,
  },
  input: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: -6,
    marginRight: -6,
    lineHeight: (typography.input.fontSize ?? 16) + 4,
  },
  nextButtonWrapper: {
    marginVertical: spacings.m,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  nextButton: {
    width: NEXT_BUTTON_SIZE,
    height: NEXT_BUTTON_SIZE,
    borderRadius: NEXT_BUTTON_SIZE / 2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.blue,
  },
});

export default styles;
