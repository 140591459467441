import {differenceInSeconds} from 'date-fns';
import {useCallback, useMemo} from 'react';
import NavigationKeys from '../../navigation/NavigationKeys';
import {
  BaseCourseFragment,
  BaseSessionFragment,
  ProgressReportsCreateInput,
  useCreateProgressReportMutation,
} from '../generated/graphql';
import {DataStatuses} from '../types/common';
import {SessionUnits} from '../types/courses';
import {ProgressReportTypes} from '../types/progressReport';
import {updateCourseItemsProgress} from '../utils/apolloCache';
import {DEFAULT_LABEL} from '../utils/constants';
// import {
//   isAudioUnits,
//   isNoteUnits,
//   isTextUnits,
//   isVideoUnits,
// } from '../utils/courses';
import {isEmail} from '../utils/email';
import {reportError} from '../utils/loggingHelpers';
import useGetDeviceInfo from './useGetDeviceInfo';

// TODO: create unique type for each event
type ProgressReportPayloadType = {
  email?: string | null;
  unit?: SessionUnits;
  courseId?: string;
  sessionId?: string;
  screen?: NavigationKeys;
  courseProgress?: number;
  sessionProgress?: number;
  startTimestamp?: string;
};

type OpenedCoursePayload = {
  email: string | null | undefined;
  courseId: BaseCourseFragment['id'];
  screen: NavigationKeys;
  courseProgress: number;
};

type OpenScreenPayload = {
  email: string;
  screen: NavigationKeys;
};

type FinishedCoursePayload = {
  email: string;
  courseId: BaseCourseFragment['id'];
  screen?: NavigationKeys;
};

type FinishedSessionPayload = {
  email: string;
  sessionId: BaseSessionFragment['id'];
  courseId?: BaseCourseFragment['id'];
  screen?: NavigationKeys;
};

type FinishedUnitPayload = {
  email: string;
  unit: SessionUnits;
  sessionId: BaseSessionFragment['id'];
  courseId?: BaseCourseFragment['id'];
  screen?: NavigationKeys;
};

type NotificationsPermissionPayload = {
  email: string;
  screen: NavigationKeys;
};

const calculateSessionDuration = (
  startTimestamp: string,
  stopTimestamp: string,
): number => {
  const durationSeconds = differenceInSeconds(
    new Date(stopTimestamp),
    new Date(startTimestamp),
  );
  return durationSeconds;
};

const getCourseParams = (
  payload: ProgressReportPayloadType,
): ProgressReportsCreateInput | undefined => {
  const courseId = Number(payload?.courseId);

  if (!courseId) {
    return;
  }
  return {Courses: {connect: {id: courseId}}};
};

const getSessionParams = (
  payload: ProgressReportPayloadType,
): ProgressReportsCreateInput | undefined => {
  const sessionId = Number(payload?.sessionId);

  if (!sessionId) {
    return;
  }
  return {Sessions: {connect: {id: sessionId}}};
};

// const getUnitParams = (
//   payload: ProgressReportPayloadType,
// ): ProgressReportsCreateInput | undefined => {
//   const unit = payload?.unit;

//   if (!unit) {
//     return;
//   }

//   if (isTextUnits(unit)) {
//     return {TextUnits: {connect: {id: Number(unit.id)}}};
//   }

//   if (isNoteUnits(unit)) {
//     return {NoteUnits: {connect: {id: Number(unit.id)}}};
//   }

//   if (isVideoUnits(unit)) {
//     return {VideoUnits: {connect: {id: Number(unit.id)}}};
//   }

//   if (isAudioUnits(unit)) {
//     return {AudioUnits: {connect: {id: Number(unit.id)}}};
//   }
// };

const getScreenParams = (
  payload: ProgressReportPayloadType,
): ProgressReportsCreateInput | undefined => {
  const screen = payload?.screen;

  if (!screen) {
    return;
  }

  if (screen === NavigationKeys.HomeScreen) {
    return {
      Home_ProgressReports_HomeToHome: {
        connect: {
          Label: DEFAULT_LABEL,
        },
      },
    };
  }

  if (screen === NavigationKeys.LibraryScreen) {
    return {
      Library_ProgressReports_LibraryToLibrary: {
        connect: {
          Label: DEFAULT_LABEL,
        },
      },
    };
  }

  if (screen === NavigationKeys.MyAreaScreen) {
    return {
      MyArea_ProgressReports_MyAreaToMyArea: {
        connect: {
          Label: DEFAULT_LABEL,
        },
      },
    };
  }

  if (screen === NavigationKeys.OnboardingScreen) {
    return {
      Onboarding_ProgressReports_OnboardingToOnboarding: {
        connect: {
          Label: DEFAULT_LABEL,
        },
      },
    };
  }

  if (screen === NavigationKeys.BookingScreen) {
    return {
      Booking_ProgressReports_BookingToBooking: {
        connect: {
          Label: DEFAULT_LABEL,
        },
      },
    };
  }
};

const useProgressReports = () => {
  const [createProgressReport] = useCreateProgressReportMutation();

  const getDeviceInfo = useGetDeviceInfo();

  const getDefaultParams = useCallback<
    (
      payload: ProgressReportPayloadType,
    ) => ProgressReportsCreateInput | undefined
  >(
    payload => {
      const deviceInfo = getDeviceInfo({email: payload.email});

      return {
        status: DataStatuses.PUBLISHED,
        Users: {connect: {EMail: payload.email}},
        Timestamp: new Date().toISOString(),
        Devices: {
          connectOrCreate: {
            where: {
              id: deviceInfo.id,
            },
            create: {
              ...deviceInfo,
              status: DataStatuses.PUBLISHED,
            },
          },
        },
      };
    },
    [getDeviceInfo],
  );

  // MIN-565: Stopped tracking
  const reportOpenScreen = useCallback<
    (payload: OpenScreenPayload) => Promise<void>
  >(async () => {
    // const screenParams = getScreenParams(payload);
    // if (!isEmail(payload.email) || !screenParams) {
    //   return;
    // }
    // try {
    //   const defaultParams = getDefaultParams(payload);
    //   await createProgressReport({
    //     variables: {
    //       data: {
    //         ...defaultParams,
    //         ...screenParams,
    //         Label: ProgressReportTypes.OPEN_SCREEN,
    //       },
    //     },
    //   });
    // } catch (error) {
    //   reportError('reportOpenScreen', error);
    // }
  }, []);

  const reportOpenCourse = useCallback<
    (payload: OpenedCoursePayload) => Promise<void>
  >(
    async payload => {
      if (!isEmail(payload.email)) {
        return;
      }

      try {
        const defaultParams = getDefaultParams(payload);

        await createProgressReport({
          variables: {
            data: {
              ...defaultParams,
              ...getScreenParams(payload),
              ...getCourseParams(payload),
              Progress: payload.courseProgress ?? 1,
              Label: ProgressReportTypes.OPEN_COURSE,
            },
          },
        });
      } catch (error) {
        reportError('reportOpenCourse', error);
      }
    },
    [createProgressReport, getDefaultParams],
  );

  const reportOpenSession = useCallback<
    (payload: ProgressReportPayloadType) => Promise<void>
  >(
    async payload => {
      if (!isEmail(payload.email)) {
        return;
      }

      try {
        const defaultParams = getDefaultParams(payload);

        await createProgressReport({
          variables: {
            data: {
              ...defaultParams,
              ...getScreenParams(payload),
              ...getCourseParams(payload),
              ...getSessionParams(payload),
              Progress: payload.sessionProgress ?? 0,
              Label: ProgressReportTypes.OPEN_SESSION,
            },
          },
        });
      } catch (error) {
        reportError('reportOpenSession', error);
      }
    },
    [createProgressReport, getDefaultParams],
  );

  // MIN-565: Stopped tracking
  const reportFinishedUnit = useCallback<
    (payload: FinishedUnitPayload) => Promise<void>
  >(async () => {
    // if (!isEmail(payload.email)) {
    //   return;
    // }
    // try {
    //   const defaultParams = getDefaultParams(payload);
    //   await createProgressReport({
    //     variables: {
    //       data: {
    //         ...defaultParams,
    //         ...getScreenParams(payload),
    //         ...getCourseParams(payload),
    //         ...getSessionParams(payload),
    //         ...getUnitParams(payload),
    //         Progress: 1,
    //         Label: ProgressReportTypes.FINISHED_UNIT,
    //       },
    //     },
    //   });
    // } catch (error) {
    //   reportError('reportFinishedUnit', error);
    // }
  }, []);

  const reportFinishedSession = useCallback<
    (payload: FinishedSessionPayload) => Promise<void>
  >(
    async payload => {
      if (!isEmail(payload.email)) {
        return;
      }

      try {
        const defaultParams = getDefaultParams(payload);

        await createProgressReport({
          variables: {
            data: {
              ...defaultParams,
              ...getScreenParams(payload),
              ...getCourseParams(payload),
              ...getSessionParams(payload),
              Progress: 1,
              Label: ProgressReportTypes.FINISHED_SESSION,
            },
          },
          update: updateCourseItemsProgress,
          ...(payload.courseId ? {refetchQueries: ['findManyCourses']} : []),
        });
      } catch (error) {
        reportError('reportFinishedSession', error);
      }
    },
    [createProgressReport, getDefaultParams],
  );

  const reportFinishedCourse = useCallback<
    (payload: FinishedCoursePayload) => Promise<void>
  >(
    async payload => {
      if (!isEmail(payload.email)) {
        return;
      }

      try {
        const defaultParams = getDefaultParams(payload);

        await createProgressReport({
          variables: {
            data: {
              ...defaultParams,
              ...getCourseParams(payload),
              ...getScreenParams(payload),
              Progress: 1,
              Label: ProgressReportTypes.FINISHED_COURSE,
            },
          },
          update: updateCourseItemsProgress,
        });
      } catch (error) {
        reportError('reportFinishedCourse', error);
      }
    },
    [createProgressReport, getDefaultParams],
  );

  const reportAppUsage = useCallback<
    (payload: ProgressReportPayloadType) => Promise<void>
  >(
    async payload => {
      if (!isEmail(payload.email) || !payload.startTimestamp) {
        return;
      }

      try {
        const defaultParams = getDefaultParams(payload);

        const sessionDuration = calculateSessionDuration(
          payload.startTimestamp,
          defaultParams?.Timestamp,
        );

        if (sessionDuration < 1) {
          return;
        }

        await createProgressReport({
          variables: {
            data: {
              ...defaultParams,
              Label: ProgressReportTypes.APP_USAGE,
              Progress: sessionDuration,
            },
          },
        });
      } catch (error) {
        reportError('reportAppUsage', error);
      }
    },
    [createProgressReport, getDefaultParams],
  );

  const reportNotificationsPermissionGranted = useCallback<
    (payload: NotificationsPermissionPayload) => void
  >(
    payload => {
      if (!isEmail(payload.email) || !payload.screen) {
        return;
      }

      try {
        const defaultParams = getDefaultParams(payload);

        createProgressReport({
          variables: {
            data: {
              ...defaultParams,
              ...getScreenParams(payload),
              Label: ProgressReportTypes.NOTIFICATIONS_PERMISSION_GRANTED,
            },
          },
        });
      } catch (error) {
        reportError('reportNotificationsPermissionGranted', error);
      }
    },
    [createProgressReport, getDefaultParams],
  );

  const reportNotificationsPermissionDenied = useCallback<
    (payload: NotificationsPermissionPayload) => void
  >(
    payload => {
      if (!isEmail(payload.email) || !payload.screen) {
        return;
      }

      try {
        const defaultParams = getDefaultParams(payload);

        createProgressReport({
          variables: {
            data: {
              ...defaultParams,
              ...getScreenParams(payload),
              Label: ProgressReportTypes.NOTIFICATIONS_PERMISSION_DENIED,
            },
          },
        });
      } catch (error) {
        reportError('reportNotificationsPermissionDenied', error);
      }
    },
    [createProgressReport, getDefaultParams],
  );

  return useMemo(
    () => ({
      reportFinishedUnit,
      reportFinishedSession,
      reportFinishedCourse,
      reportOpenScreen,
      reportOpenCourse,
      reportOpenSession,
      reportAppUsage,
      reportNotificationsPermissionGranted,
      reportNotificationsPermissionDenied,
    }),
    [
      reportAppUsage,
      reportFinishedCourse,
      reportFinishedSession,
      reportFinishedUnit,
      reportNotificationsPermissionDenied,
      reportNotificationsPermissionGranted,
      reportOpenCourse,
      reportOpenScreen,
      reportOpenSession,
    ],
  );
};

export default useProgressReports;
