import {AuthStorage} from '../../../utils/authStorage';
import {reportError} from '../loggingHelpers';

export class AuthTokensStore extends AuthStorage {
  private refreshTokenKey: string;
  private accessTokenKey: string;

  constructor({
    refreshTokenKey,
    accessTokenKey,
  }: {
    refreshTokenKey: string;
    accessTokenKey: string;
  }) {
    super();

    this.refreshTokenKey = refreshTokenKey;
    this.accessTokenKey = accessTokenKey;
  }

  private updateToken = async (key: string, token: string | null) => {
    try {
      if (token) {
        await this.setItem(key, token);
      }
    } catch (error) {
      reportError('updateToken error', error);
      throw error;
    }
  };

  public updateAccessToken = async (token: string | null) => {
    try {
      await this.updateToken(this.accessTokenKey, token);
    } catch (error) {
      reportError('updateAccessToken error', error);
      throw error;
    }
  };

  public updateRefreshToken = async (token: string | null) => {
    try {
      await this.updateToken(this.refreshTokenKey, token);
    } catch (error) {
      reportError('updateRefreshToken error', error);
      throw error;
    }
  };

  public getAccessToken = async () => {
    try {
      return await this.getItem(this.accessTokenKey);
    } catch (error) {
      reportError('getAccessToken error', error);
      throw error;
    }
  };

  public getRefreshToken = async () => {
    try {
      return await this.getItem(this.refreshTokenKey);
    } catch (error) {
      reportError('getRefreshToken error', error);
      throw error;
    }
  };

  public removeTokens = async () => {
    try {
      await this.removeItem(this.refreshTokenKey);
      await this.removeItem(this.accessTokenKey);
    } catch (error) {
      reportError('removeTokens error', error);
      throw error;
    }
  };
}

const REFRESH_TOKEN_KEY = 'refreshToken';
const ACCESS_TOKEN_KEY = 'accessToken';
const authTokensStore = new AuthTokensStore({
  accessTokenKey: ACCESS_TOKEN_KEY,
  refreshTokenKey: REFRESH_TOKEN_KEY,
});

export default authTokensStore;
