import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {SMALL_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';

const ICON_WRAPPER_SIZE = 54;

const styles = StyleSheet.create({
  outer: {
    borderRadius: SMALL_BORDER_RADIUS,
    overflow: 'hidden',
  },
  container: {
    backgroundColor: colors.transparent,
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacings.s,
    borderRadius: SMALL_BORDER_RADIUS,
  },
  iconWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    width: ICON_WRAPPER_SIZE,
    height: ICON_WRAPPER_SIZE,
    borderRadius: ICON_WRAPPER_SIZE / 2,
    backgroundColor: colors.white,
  },
  label: {
    ...typography.input,
    textAlign: 'center',
    color: colors.white,
    marginTop: spacings.xl,
  },
  gradientBackground: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: SMALL_BORDER_RADIUS,
  },
});

export default styles;
