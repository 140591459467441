import React, {useMemo} from 'react';
import Modal from 'react-modal';
import {
  DimensionValue,
  StyleProp,
  ViewStyle,
  useWindowDimensions,
} from 'react-native';
import colors from '../../theme/colors';
import breakpointProvider from '../../utils/breakpointProvider';
import ModalContent from './ModalContent';
import {AppModalProps} from './props';
import styles from './styles';

Modal.setAppElement('#root');

type BreakpointWidth = {
  breakpoint: number;
  width: DimensionValue;
};

const gridBreakpoints = breakpointProvider.$gridBreakpoints;

const modalWidthBreakpoints: BreakpointWidth[] = [
  {breakpoint: gridBreakpoints.sm, width: '95%'},
  {breakpoint: gridBreakpoints.md, width: '80%'},
  {breakpoint: gridBreakpoints.lg, width: '70%'},
  {breakpoint: gridBreakpoints.xl, width: '60%'},
];

const contentMaxWidthBreakpoints: BreakpointWidth[] = [
  {breakpoint: gridBreakpoints.sm, width: '100%'},
  {breakpoint: gridBreakpoints.md, width: '95%'},
  {breakpoint: gridBreakpoints.lg, width: '90%'},
  {breakpoint: gridBreakpoints.xl, width: '85%'},
];

const getWidth = (
  windowWidth: number,
  breakpoints: BreakpointWidth[],
  defaultWidth: BreakpointWidth['width'] = '50%',
): BreakpointWidth['width'] => {
  const matchedBreakpoint = breakpoints.find(
    breakpoint => windowWidth <= breakpoint.breakpoint,
  );

  return matchedBreakpoint ? matchedBreakpoint.width : defaultWidth;
};

const AppModal: React.FC<
  Omit<AppModalProps, 'style'> & {style: React.CSSProperties}
> = ({
  isVisible,
  onDismiss,
  style,
  children,
  testID,
  useDefaultHeight = true,
  contentStyle,
  ...props
}) => {
  const {width} = useWindowDimensions();

  const modalStyle = useMemo<Modal.Styles>(
    () => ({
      overlay: {
        backgroundColor: colors.black95Alpha68,
      },
      content: {
        ...styles.containerWeb,
        height: useDefaultHeight ? '80%' : 'auto',
        width: getWidth(width, modalWidthBreakpoints),
        ...(Array.isArray(style)
          ? style.reduce((acc, current) => ({...acc, ...current}))
          : style),
      },
    }),
    [style, useDefaultHeight, width],
  );

  const mergedContentStyle = useMemo<StyleProp<ViewStyle>>(
    () => [
      {
        alignSelf: 'center',
        maxWidth: getWidth(width, contentMaxWidthBreakpoints, '80%'),
      },
      styles.contentWeb,
      contentStyle,
    ],
    [contentStyle, width],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Modal isOpen={isVisible} onRequestClose={onDismiss} style={modalStyle}>
      <ModalContent
        {...props}
        contentStyle={mergedContentStyle}
        onDismiss={onDismiss}
        testID={testID}>
        {children}
      </ModalContent>
    </Modal>
  );
};

export default React.memo(AppModal);
