import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
  },
  loading: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.black100,
  },
  image: {
    ...StyleSheet.absoluteFillObject,
  },
});

export default styles;
