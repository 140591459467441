import * as R from 'ramda';
import storage from '../../utils/storage';
import {BaseInfoOverlayFragment} from '../generated/graphql';
import {
  AppInfoOverlay,
  DisplayedInfoOverlaysCounter,
} from '../types/infoOverlays';
import {reportError} from './loggingHelpers';

class DisplayedInfoOverlaysStore {
  constructor(private displayedInfoOverlaysKey: string) {}

  private getSavedInfoOverlaysData = async () => {
    const savedInfoOverlaysData = await storage.getItem(
      this.displayedInfoOverlaysKey,
    );

    if (!savedInfoOverlaysData) {
      return null;
    }

    const parsedInfoOverlaysData: DisplayedInfoOverlaysCounter = JSON.parse(
      savedInfoOverlaysData,
    );
    return parsedInfoOverlaysData;
  };

  private getDisplayedTimesFromSavedData = async (
    id: BaseInfoOverlayFragment['id'],
    savedInfoOverlaysData: DisplayedInfoOverlaysCounter | null,
  ) => {
    return savedInfoOverlaysData?.[id] ? savedInfoOverlaysData[id] : 0;
  };

  public getDisplayedTimes = async (id: BaseInfoOverlayFragment['id']) => {
    const savedInfoOverlaysData = await this.getSavedInfoOverlaysData();

    return await this.getDisplayedTimesFromSavedData(id, savedInfoOverlaysData);
  };

  public increaseDisplayedTimes = async (id: BaseInfoOverlayFragment['id']) => {
    const savedData = await this.getSavedInfoOverlaysData();
    const displayedTimes = await this.getDisplayedTimesFromSavedData(
      id,
      savedData,
    );

    const updatedData: DisplayedInfoOverlaysCounter = R.assoc(
      id,
      displayedTimes + 1,
      savedData,
    );

    await storage.setItem(
      this.displayedInfoOverlaysKey,
      JSON.stringify(updatedData),
    );
  };
}

const storageKey = 'DISPLAYED_INFO_OVERLAYS';
export const displayedInfoOverlaysStore = new DisplayedInfoOverlaysStore(
  storageKey,
);

export const filterByDisplayedTimesAsync = async ({
  id,
  HowOftenToShow,
}: BaseInfoOverlayFragment): Promise<boolean> => {
  if (!HowOftenToShow) {
    return false;
  }

  try {
    const displayedTimes = await displayedInfoOverlaysStore.getDisplayedTimes(
      id,
    );

    return displayedTimes < HowOftenToShow;
  } catch (error) {
    reportError('error during getting displayed times of info overlays', error);
    return false;
  }
};

export const mapItemToAppInfoOverlay = ({
  id,
  HowOftenToShow,
  InfoOverlay_translations,
  ShowFrom,
  ShowOn,
  ShowUntil,
  directus_files,
}: BaseInfoOverlayFragment): AppInfoOverlay => ({
  id,
  title: InfoOverlay_translations?.[0]?.Label,
  description: InfoOverlay_translations?.[0]?.Description,
  buttonLabel: InfoOverlay_translations?.[0]?.ButtonLabel,
  directus_files,
  HowOftenToShow,
  ShowFrom,
  ShowUntil,
  ShowOn,
});
