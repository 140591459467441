import humps from 'humps';
import queryString, {StringifyOptions} from 'query-string';
import * as R from 'ramda';
import {RouteKeysQueryParams} from './NavigationTypes';
import {RouteKeys} from './RouteKeys';

class QueryParamsClass {
  private arrayFormat: StringifyOptions['arrayFormat'] = 'bracket';

  public stringify = <P extends RouteKeys>(
    queryParams?: RouteKeysQueryParams[P],
  ) => {
    if (!queryParams) {
      return '';
    }

    const filteredQueryParams = R.filter(R.isNotNil, queryParams);

    return queryString.stringify(
      humps.decamelizeKeys(filteredQueryParams, {separator: '-'}),
      {arrayFormat: this.arrayFormat},
    );
  };

  public parse = <P extends RouteKeys>(searchParams: URLSearchParams) => {
    const parsed = queryString.parse(searchParams.toString(), {
      arrayFormat: this.arrayFormat,
      parseBooleans: true,
      parseNumbers: true,
    });

    return humps.camelizeKeys(parsed) as RouteKeysQueryParams[P];
  };
}

const queryParamsHelper = new QueryParamsClass();
export default queryParamsHelper;
