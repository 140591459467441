import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {SMALL_BORDER_RADIUS} from '../../theme/spacings';

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.transparent,
    borderRadius: SMALL_BORDER_RADIUS,
  },
  gradientBackground: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: SMALL_BORDER_RADIUS,
  },
  colorfulGradient: {
    position: 'absolute',
    left: 0,
    width: 3,
    top: 0,
    bottom: 0,
  },
  image: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: SMALL_BORDER_RADIUS,
  },
  content: {
    flex: 1,
    borderRadius: SMALL_BORDER_RADIUS,
    paddingHorizontal: spacings.m,
    paddingTop: spacings.m,
    paddingBottom: spacings.l,
  },
});

export default styles;
