import classNames from 'classnames';
import React from 'react';
import styles from './Switch.module.scss';
import SwitchProps from './Switch.props';

const Switch: React.FC<SwitchProps> = ({
  className,
  onToggle,
  isChecked,
  readOnly = false,
}) => {
  const mergedStyle = classNames(styles.switch, className);

  return (
    <label className={mergedStyle}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onToggle}
        readOnly={readOnly}
      />
      <span className={styles.slider} />
    </label>
  );
};

export default React.memo(Switch);
