export enum RouteKeys {
  Root = '/',
  Welcome = '/welcome',
  Login = '/login',
  ForgotPassword = '/forgot-password',
  ForgotPasswordSuccess = '/forgot-password/success',
  PasswordChange = '/password-change/:token?',
  PasswordChangeSuccess = '/password-change/success',
  EmailConfirmation = '/email-confirmation/:token?',
  EmailConfirmationSuccess = '/email-confirmation/success',
  EmailConfirmationRequest = '/email-confirmation/request',

  Registration = '/registration',
  RegistrationEmailVerification = '/registration/email-verification',

  Onboarding = '/onboarding',

  Home = '/home',

  Help = '/help',
  HealthRequirements = '/health-requirements',
  PrivacyPolicy = '/privacy-policy',
  TermsAndConditions = '/terms-and-conditions',

  Settings = '/settings',
  SettingsAccountDetails = '/settings/account-details',
  SettingsChangePassword = '/settings/account-details/change-password',
  SettingsRedeemActivationCode = '/settings/redeem-activation-code',
  SettingsHelp = '/settings/help',
  SettingsHealthRequirements = '/settings/health-requirements',
  SettingsPrivacyPolicy = '/settings/privacy-policy',
  SettingsTermsAndConditions = '/settings/terms-and-conditions',

  Dashboard = '/dashboard',

  Newstool = '/newstool',
  NewstoolDetails = '/newstool/details/:id?',
  NewstoolCreate = '/newstool/details/?create=true',

  Eventtool = '/eventtool',

  GBUPsyche = '/gbu',

  Start = '/start',

  Library = '/library',
  LibraryItems = '/library/items',

  Booking = '/booking',
  EventDetails = '/events/:id',
  EventBookingForm = '/events/:id/booking',
  EventBookingSuccess = '/events/:id/booking/success',
  UserBookingForm = '/events/:id/booking/user-data',
  OneToOneSessionDetails = '/one-to-one-coaching/:id',
  Expertline = '/expertline',

  News = '/news',

  Course = '/courses/:id',
  Session = '/sessions/:id',

  MyArea = '/my-area',
  MyNotes = '/my-area/notes',
}
