import React, {ReactNode, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {useSubscriptionsContext} from '../../contexts/SubscriptionsContext';
import {useAppContext} from '../../mindance-libs/store/contexts/AppContext';
import {AppProductsLabels} from '../../mindance-libs/types/common';
import LoadingPage from '../../pages/loading/page';
import {HOME_MIND_MANAGER_TOOLS_ROUTES} from '../../utils/products';
import {RouteKeys} from '../RouteKeys';
import TypedNavigate from '../typed/TypedNavigate';

const ProtectedRoute: React.FC<{children?: ReactNode}> = ({children}) => {
  const {
    isLoading,
    isLoggedIn,
    isOnboardingPassed,
    screensSetupCategoriesAvailability,
    screenSetupLoading,
    isMindManager,
  } = useAppContext();
  const {availableRoutes, loading} = useSubscriptionsContext();

  const location = useLocation();

  const isRouteSecured = !!Object.values(HOME_MIND_MANAGER_TOOLS_ROUTES).find(
    route => location.pathname.includes(route),
  );
  const isRouteAvailable = !!availableRoutes?.find(route =>
    location.pathname.includes(route),
  );

  const state = useMemo(() => ({from: location}), [location]);

  if (isLoading || loading || screenSetupLoading) {
    return <LoadingPage />;
  }

  if (!isLoggedIn) {
    return <TypedNavigate to={RouteKeys.Welcome} replace state={state} />;
  }

  if (
    !isOnboardingPassed &&
    screensSetupCategoriesAvailability[AppProductsLabels.ONBOARDING] &&
    !isMindManager
  ) {
    return <TypedNavigate to={RouteKeys.Onboarding} replace state={state} />;
  }

  if (location.pathname === RouteKeys.Root) {
    return (
      <TypedNavigate
        to={isMindManager ? RouteKeys.Home : RouteKeys.Start}
        replace
      />
    );
  }

  if (location.pathname === RouteKeys.Home && !isMindManager) {
    return <TypedNavigate to={RouteKeys.Start} replace />;
  }

  if (isRouteSecured && !isRouteAvailable) {
    return <TypedNavigate to={RouteKeys.Root} replace />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default ProtectedRoute;
