import {FormikConfig, useFormik} from 'formik';
import * as R from 'ramda';
import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Item} from 'react-native-picker-select';
import {
  UserFieldsFragment,
  useFindManyPersonalStatusesQuery,
} from '../generated/graphql';
import {useAppContext} from '../store/contexts/AppContext';
import {
  BookingForm,
  BookingFormFocused,
  BookingFormHandleBlur,
  BookingFormHandleChange,
  BookingFormHandleFocus,
  BookingFormHasError,
} from '../types/booking';
import {bookingFormValidationSchema} from '../utils/formValidations';
import {reportError} from '../utils/loggingHelpers';
import useInputFormikHandlers from './useInputFormikHandlers';

const useBookingFormFields = ({
  onSubmit,
  onError,
  data: dataValues,
}: {
  onSubmit: FormikConfig<BookingForm>['onSubmit'];
  onError?: (error: string) => void;
  data?: BookingForm;
}) => {
  const {t, i18n} = useTranslation();
  const {user} = useAppContext();

  const initialValues = useMemo<BookingForm>(() => {
    const getValue = (
      dataKey: keyof BookingForm,
      userKey: keyof UserFieldsFragment,
      defaultValue: string = '',
    ) =>
      dataValues?.[dataKey]?.toString() ||
      user?.[userKey]?.toString() ||
      defaultValue?.toString();

    return {
      city: getValue('city', 'City'),
      department: getValue('department', 'Department'),
      employer: getValue('employer', 'Employer'),
      personalStatus: getValue('personalStatus', 'PersonalStatus'),
      phone: getValue('phone', 'Phone'),
      postCode: getValue('postCode', 'PostalCode'),
    };
  }, [dataValues, user]);

  const {handleChange, handleBlur, errors, touched, ...data} = useFormik({
    validationSchema: bookingFormValidationSchema,
    validateOnMount: true,
    initialValues: initialValues,
    onSubmit,
  });

  const {data: personalStatuses} = useFindManyPersonalStatusesQuery({
    variables: {languages_code: i18n.language},
    onError: error =>
      reportError('useFindManyPersonalStatusesQuery error', error),
  });

  const [personalStatusError, setPersonalStatusError] = useState<
    string | undefined
  >();

  const personalStatusItems = useMemo<Item[]>(() => {
    const sortedStatuses = R.sort(
      R.ascend(({sort}) => sort ?? 1000),
      personalStatuses?.findManyPersonalStatuses ?? [],
    );

    const result = sortedStatuses.map(({id, PersonalStatus_translations}) => ({
      value: id,
      label: PersonalStatus_translations?.[0]?.Label ?? undefined,
    }));

    const filtered = R.filter(({label}) => !!label, result ?? []);
    return filtered;
  }, [personalStatuses?.findManyPersonalStatuses]);

  const personalStatusPlaceholder = useMemo<Item>(
    () => ({
      label: t('booking.I_am') ?? undefined,
      value: undefined,
      inputLabel: t('booking.I_am') ?? '',
    }),
    [t],
  );

  const {setValue: setPersonalStatus} = useInputFormikHandlers<BookingForm>(
    'personalStatus',
    {
      errors,
      handleBlur,
      handleChange,
      touched,
      onError,
    },
  );

  useEffect(() => {
    if (personalStatusItems.length > 0 && !initialValues.personalStatus) {
      setPersonalStatus(personalStatusItems[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.personalStatus, personalStatusItems]);

  const updatePersonalStatus = useCallback<
    (e: string | ChangeEvent<any>) => void
  >(
    value => {
      setPersonalStatus(value ?? '');

      const updatedError = t('auth.all_fields_required') ?? '';
      setPersonalStatusError(!value ? updatedError : undefined);

      if (!value) {
        onError?.(updatedError);
      }
    },
    [onError, setPersonalStatus, t],
  );

  const {
    handleValueBlur: handleCityBlur,
    handleValueFocus: handleCityFocus,
    hasError: hasCityError,
    setValue: setCity,
    isFocused: isCityFocused,
  } = useInputFormikHandlers<BookingForm>('city', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError,
  });

  const {
    handleValueBlur: handleDepartmentBlur,
    handleValueFocus: handleDepartmentFocus,
    hasError: hasDepartmentError,
    setValue: setDepartment,
    isFocused: isDepartmentFocused,
  } = useInputFormikHandlers<BookingForm>('department', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError,
  });

  const {
    handleValueBlur: handleEmployerBlur,
    handleValueFocus: handleEmployerFocus,
    hasError: hasEmployerError,
    setValue: setEmployer,
    isFocused: isEmployerFocused,
  } = useInputFormikHandlers<BookingForm>('employer', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError,
  });

  const {
    handleValueBlur: handlePhoneBlur,
    handleValueFocus: handlePhoneFocus,
    hasError: hasPhoneError,
    setValue: setPhone,
    isFocused: isPhoneFocused,
  } = useInputFormikHandlers<BookingForm>('phone', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError,
  });

  const {
    handleValueBlur: handlePostalCodeBlur,
    handleValueFocus: handlePostalCodeFocus,
    hasError: hasPostalCodeError,
    setValue: setPostalCode,
    isFocused: isPostalCodeFocused,
  } = useInputFormikHandlers<BookingForm>('postCode', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError,
  });

  const handleFormBlur = useMemo<BookingFormHandleBlur>(
    () => ({
      city: handleCityBlur,
      department: handleDepartmentBlur,
      employer: handleEmployerBlur,
      phone: handlePhoneBlur,
      postCode: handlePostalCodeBlur,
      personalStatus: undefined,
    }),
    [
      handleCityBlur,
      handleDepartmentBlur,
      handleEmployerBlur,
      handlePhoneBlur,
      handlePostalCodeBlur,
    ],
  );

  const handleFormFocus = useMemo<BookingFormHandleFocus>(
    () => ({
      city: handleCityFocus,
      department: handleDepartmentFocus,
      employer: handleEmployerFocus,
      phone: handlePhoneFocus,
      postCode: handlePostalCodeFocus,
      personalStatus: undefined,
    }),
    [
      handleCityFocus,
      handleDepartmentFocus,
      handleEmployerFocus,
      handlePhoneFocus,
      handlePostalCodeFocus,
    ],
  );

  const handleFormChange = useMemo<BookingFormHandleChange>(
    () => ({
      city: setCity,
      department: setDepartment,
      employer: setEmployer,
      phone: setPhone,
      postCode: setPostalCode,
      personalStatus: updatePersonalStatus,
    }),
    [
      setCity,
      setDepartment,
      setEmployer,
      setPhone,
      setPostalCode,
      updatePersonalStatus,
    ],
  );

  const hasError = useMemo<BookingFormHasError>(
    () => ({
      city: hasCityError,
      department: hasDepartmentError,
      employer: hasEmployerError,
      phone: hasPhoneError,
      postCode: hasPostalCodeError,
      personalStatus: !!personalStatusError,
    }),
    [
      hasCityError,
      hasDepartmentError,
      hasEmployerError,
      hasPhoneError,
      hasPostalCodeError,
      personalStatusError,
    ],
  );

  const isFocused = useMemo<BookingFormFocused>(
    () => ({
      city: isCityFocused,
      department: isDepartmentFocused,
      employer: isEmployerFocused,
      phone: isPhoneFocused,
      postCode: isPostalCodeFocused,
      personalStatus: undefined,
    }),
    [
      isCityFocused,
      isDepartmentFocused,
      isEmployerFocused,
      isPhoneFocused,
      isPostalCodeFocused,
    ],
  );

  return useMemo(
    () => ({
      handleFormBlur,
      handleFormFocus,
      hasError,
      handleFormChange,
      handleChange,
      handleBlur,
      errors,
      touched,
      isFocused,
      personalStatusItems,
      personalStatusPlaceholder,
      personalStatusError,
      ...data,
      isValid: data.isValid && !personalStatusError,
    }),
    [
      handleFormBlur,
      handleFormFocus,
      hasError,
      handleFormChange,
      handleChange,
      handleBlur,
      errors,
      touched,
      isFocused,
      personalStatusItems,
      personalStatusPlaceholder,
      personalStatusError,
      data,
    ],
  );
};

export default useBookingFormFields;
