import {useEffect} from 'react';

/**
 * A hook to track when the window is not active.
 * This hook is for web use only.
 */
const useWindowStateTracking = ({
  handleBlur,
  handleFocus,
}: {
  handleBlur: () => Promise<void>;
  handleFocus: () => Promise<void>;
}) => {
  useEffect(() => {
    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
    };
  }, [handleBlur, handleFocus]);
};

export default useWindowStateTracking;
