import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {SMALL_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';

export const LOGO_SIZE = Platform.select({
  default: 30,
  web: 38,
});
export const PIN_ICON_SIZE = Platform.select({default: 16, web: 20});

const description = {
  ...typography.footnote,
  fontSize: Platform.select({
    default: typography.footnote.fontSize ?? 12,
    web: 16,
  }),
  lineHeight: Platform.select({
    default: typography.footnote.lineHeight ?? 16,
    web: 20,
  }),
  color: colors.whiteAlpha75,
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.whiteAlpha10,
    borderRadius: SMALL_BORDER_RADIUS,
    padding: Platform.select({default: spacings.l, web: spacings.xxl}),
    justifyContent: 'space-between',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    width: LOGO_SIZE,
    height: LOGO_SIZE,
    borderRadius: LOGO_SIZE,
    marginRight: spacings.xs,
  },
  nameContainer: {
    flex: 1,
    marginRight: spacings.xs,
  },
  name: {
    ...typography.inputDemiBold,
    fontSize: Platform.select({
      default: typography.inputDemiBold.fontSize ?? 14,
      web: 16,
    }),
    lineHeight: Platform.select({
      default: typography.inputDemiBold.lineHeight ?? 16,
      web: 18,
    }),
    marginBottom: Platform.select({
      default: 0,
      web: 4,
    }),
  },
  content: {
    marginTop: spacings.l,
    flexDirection: 'row',
    alignItems: 'center',
  },
  textContainer: {
    flex: 1,
  },
  title: {
    ...typography.bodyDemiBold,
    fontSize: Platform.select({
      default: typography.bodyDemiBold.fontSize ?? 16,
      web: 18,
    }),
    lineHeight: Platform.select({
      default: typography.bodyDemiBold.lineHeight ?? 22,
      web: 22,
    }),
  },
  description: {
    ...description,
  },
  imageContainer: {
    flex: 0.25,
    aspectRatio: 1,
    marginLeft: spacings.l,
    borderRadius: 6,
    overflow: 'hidden',
    maxWidth: 130,
  },
  image: {
    ...StyleSheet.absoluteFillObject,
  },
  date: {
    ...typography.small,
    color: colors.lightGray,
  },
  showMore: {
    ...description,
    textDecorationLine: 'underline',
    marginBottom: Platform.select({default: 0, ios: -2, web: 2}),
  },
  pinIcon: {
    position: 'absolute',
    right: -PIN_ICON_SIZE / 8,
    top: -PIN_ICON_SIZE / 8,
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: spacings.xs,
    justifyContent: 'space-between',
  },
  likeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  like: {
    marginRight: spacings.xxs,
  },
  p: {
    marginTop: spacings.xxxxs,
    marginBottom: 0,
  },
  br: {
    margin: 0,
    padding: 0,
  },
  blockquote: {
    marginLeft: 0,
    paddingLeft: spacings.xs,
    borderLeftWidth: 3,
    borderLeftColor: colors.lightGray,
  },
  a: {
    ...description,
    textDecorationLine: 'underline',
    textDecorationColor: colors.white,
  },
});

export const descriptionTagStyles = {
  body: styles.description,
  a: styles.a,
  p: {...styles.p},
  br: {...styles.br},
  li: {...styles.description, marginTop: -1},
  blockquote: {...styles.blockquote},
};

export default styles;
