import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Languages} from '../types/common';
import {localizationStore} from '../utils/i18next/helpers';
import {reportError} from '../utils/loggingHelpers';

const useLanguage = () => {
  const {i18n} = useTranslation();

  const changeLanguage = useCallback<(language: Languages) => Promise<void>>(
    async language => {
      try {
        await i18n.changeLanguage(language);
        await localizationStore.setLanguage(language);
      } catch (error) {
        reportError('changeLanguage error', error);
      }
    },
    [i18n],
  );

  return useMemo(() => ({changeLanguage}), [changeLanguage]);
};

export default useLanguage;
