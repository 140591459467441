import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';

const SIZE = 6;

const styles = StyleSheet.create({
  container: {
    height: SIZE,
    width: SIZE,
    borderRadius: SIZE / 2,
    backgroundColor: colors.white,
  },
});

export default styles;
