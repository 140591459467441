import React from 'react';
import Svg, {Circle, Path, SvgProps} from 'react-native-svg';
import colors from '../theme/colors';

const RoundCheckBoxIcon: React.FC<SvgProps> = ({
  fill = colors.white,
  stroke = '#53A9BD',
  ...props
}) => {
  return (
    <Svg width={21} height={21} viewBox="0 0 21 21" {...props}>
      <Circle cx={10.5} cy={10.5} r={10.5} fill={fill} />
      <Path
        d="M14.7364 8.76316L9.98851 13.5107C9.63692 13.8624 9.0666 13.8624 8.71468 13.5107L6.26379 11.0596C5.91207 10.708 5.91207 10.1376 6.26379 9.78587C6.61558 9.43409 7.18586 9.43409 7.53749 9.78574L9.35178 11.6001L13.4625 7.48933C13.8143 7.13754 14.3846 7.13781 14.7363 7.48933C15.0879 7.84104 15.0879 8.41124 14.7364 8.76316Z"
        fill={stroke}
      />
    </Svg>
  );
};

export default React.memo(RoundCheckBoxIcon);
