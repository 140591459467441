import React, {useMemo} from 'react';
import {
  Image,
  ImageStyle,
  StyleProp,
  View,
  ViewStyle,
  useWindowDimensions,
} from 'react-native';
import {Link} from 'react-router-dom';
import useCompanyEventImage from '../../hooks/useCompanyEventImage';
import colors from '../../theme/colors';
import {BookingItemType} from '../../types/booking';
import {checkIsSmallWindow} from '../../utils/common';
import AppText from '../AppText';
import {BookingCardProps} from './props';
import RNstyles from './styles';
import styles from './styles.web.module.scss';
import useBookingCard from './useBookingCard';

const IMAGE_RATIO = 1.9;
const MAX_IMAGE_SIZE = 300;

const BookingCard: React.FC<BookingCardProps> = ({
  id,
  style,
  testID,
  href,
  width,
  BookingType,
  ...rest
}) => {
  const {width: windowWidth} = useWindowDimensions();
  const getEventImage = useCompanyEventImage();

  const isSmallWindow = checkIsSmallWindow(windowWidth);

  const imageWidth =
    (width > 0 && width <= MAX_IMAGE_SIZE) || isSmallWindow
      ? width
      : MAX_IMAGE_SIZE;

  const imageUrl = getEventImage(rest?.directus_files, BookingType, {
    width: imageWidth,
    aspectRatio: IMAGE_RATIO,
  });

  const {
    AppointmentNumberElement,
    CompanyItemElement,
    TimeElement,
    GroupCoachingIconElement,
    image,
    imageHeight,
    subtitle,
    Label,
  } = useBookingCard({
    id,
    width: imageWidth,
    labelsBackgroundColor: !isSmallWindow ? colors.whiteAlpha10 : undefined,
    isSmallWindow,
    imageUrl,
    imageRatio: IMAGE_RATIO,
    ...rest,
  });

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [RNstyles.container, style],
    [style],
  );

  const contentStyle = useMemo<StyleProp<ViewStyle>>(
    () => ({width: imageWidth, alignSelf: 'stretch', minHeight: imageHeight}),
    [imageHeight, imageWidth],
  );

  const imageStyle = useMemo<StyleProp<ImageStyle>>(
    () => [
      RNstyles.image,
      {width: imageWidth, minHeight: imageHeight, height: '100%'},
    ],
    [imageHeight, imageWidth],
  );

  const ImageContent = useMemo(
    () => (
      <View style={contentStyle} testID={`${testID}.content`}>
        <Image
          style={imageStyle}
          source={image}
          testID={`${testID}.image`}
          resizeMode={
            BookingType === BookingItemType.EVENT_EVENTTOOL
              ? 'contain'
              : 'cover'
          }
        />
        <View style={RNstyles.content}>
          <View style={RNstyles.upperContent}>
            <View style={RNstyles.groupAndAppointments}>
              {GroupCoachingIconElement}
              {isSmallWindow ? AppointmentNumberElement : null}
            </View>
            {isSmallWindow ? TimeElement : null}
          </View>
        </View>
      </View>
    ),
    [
      AppointmentNumberElement,
      BookingType,
      GroupCoachingIconElement,
      TimeElement,
      contentStyle,
      image,
      imageStyle,
      isSmallWindow,
      testID,
    ],
  );

  const titleStyle = useMemo(
    () => [RNstyles.title, {marginTop: !isSmallWindow ? 0 : undefined}],
    [isSmallWindow],
  );

  const subtitleStyle = useMemo(
    () => [RNstyles.subtitle, {marginTop: !isSmallWindow ? 0 : undefined}],
    [isSmallWindow],
  );

  if (isSmallWindow) {
    return (
      <Link to={href ?? ''} className={styles['booking-card__link']}>
        <View style={containerStyle}>
          {ImageContent}

          {Label ? (
            <AppText style={titleStyle} testID={`${testID}.title`}>
              {Label}
            </AppText>
          ) : null}
          {subtitle ? (
            <AppText style={subtitleStyle} testID={`${testID}.subtitle`}>
              {subtitle}
            </AppText>
          ) : null}
          {CompanyItemElement}
        </View>
      </Link>
    );
  }

  return (
    <Link to={href ?? ''} className={styles['booking-card__link']}>
      <div className={styles['booking-card__container']}>
        {ImageContent}

        <div className={styles['booking-card__container__content']}>
          {Label ? (
            <AppText style={titleStyle} testID={`${testID}.title`}>
              {Label}
            </AppText>
          ) : null}
          {subtitle ? (
            <AppText style={subtitleStyle} testID={`${testID}.subtitle`}>
              {subtitle}
            </AppText>
          ) : null}

          <div />
          {TimeElement}
          {AppointmentNumberElement}
          {CompanyItemElement}
        </div>
      </div>
    </Link>
  );
};

export default React.memo(BookingCard);
