import {StyleProp, ViewStyle} from 'react-native';
import {Gender} from './user';
import {Languages} from './common';
import {OnErrorMessageShow} from '../hooks/useErrorMessage';

export enum RegistrationSlides {
  PRIVACY = 'PRIVACY',
  PERSONAL_INFO = 'PERSONAL_INFO',
  CREDENTIALS = 'CREDENTIALS',
}

export type RegistrationSlideProps = {
  focused: boolean;
  onSubmitButtonPress: (values?: any) => void;
  onBackButtonPress: (values?: any) => void;
  onError: OnErrorMessageShow;
  style?: StyleProp<ViewStyle>;
  contentButtonStyle?: StyleProp<ViewStyle>;
};

export type PersonalInfo = {
  firstName: string;
  lastName: string;
  gender: Gender | undefined;
  age: number | undefined;
};

export type Credentials = {
  email: string;
  password: string;
  activationCode: string | undefined;
  language: Languages;
};
