import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import NavigationKeys from '../../navigation/NavigationKeys';
import {useAppContext} from '../store/contexts/AppContext';
import {getMeinFSLink} from '../utils/generalSettings';

const useHomeScreen = () => {
  const {t} = useTranslation();
  const {user, homeScreenSetup, screensSetupCategoriesAvailability} =
    useAppContext();

  const email = user?.EMail;

  const meinFSLink = getMeinFSLink(
    homeScreenSetup?.GeneralSetting_Home_GeneralSettingToGeneralSetting,
  );

  const title =
    t(`screenTitle.${NavigationKeys.HomeScreen}`) +
    ` ${user?.FirstName ? user?.FirstName : ''}`;

  const subtitle = t('home.what_do_you_want_to_do');

  return useMemo(
    () => ({
      ...homeScreenSetup,
      meinFSLink,
      title,
      subtitle,
      screensSetupCategoriesAvailability,
      email,
    }),
    [
      email,
      homeScreenSetup,
      meinFSLink,
      screensSetupCategoriesAvailability,
      subtitle,
      title,
    ],
  );
};

export default useHomeScreen;
