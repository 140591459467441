import {StyleSheet} from 'react-native';
import spacings from '../../../theme/spacings';
import typography from '../../../theme/typography';

const IMAGE_SIZE = 130;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: spacings.xl,
  },
  scrollView: {
    flexGrow: 1,
  },
  psychologistWrapper: {
    flex: 0.45,
    alignItems: 'center',
    marginTop: spacings.l,
  },
  psychologistImage: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    borderRadius: IMAGE_SIZE / 2,
  },
  psychologistTitleWrapper: {
    marginTop: spacings.xxl,
  },
  psychologistTitle: {
    ...typography.input,
    lineHeight: 20,
    fontWeight: '600',
    textAlign: 'center',
  },
  psychologistTitleBold: {
    ...typography.placeholder,
  },
  space: {
    flex: 1,
  },
  bottomContainer: {
    flex: 0.55,
  },
  content: {
    flex: 1,
    paddingHorizontal: spacings.s,
    paddingVertical: spacings.l,
  },
  buttonWrapper: {
    flexDirection: 'row',
    paddingHorizontal: spacings.xxxl,
    justifyContent: 'center',
  },
});

export default styles;
