import classNames from 'classnames';
import React, {PropsWithChildren} from 'react';
import {Link} from 'react-router-dom';
import {MyNotesWrapperProps} from './props';
import styles from './styles.web.module.scss';

const MyNotesWrapper: React.FC<PropsWithChildren<MyNotesWrapperProps>> = ({
  href,
  children,
  className,
}) => {
  return (
    <Link to={href ?? ''} className={classNames(styles['my-notes'], className)}>
      {children}
    </Link>
  );
};

export default React.memo(MyNotesWrapper);
