import {useLayout} from '@react-native-community/hooks';
import React, {forwardRef, useImperativeHandle, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, View, ViewStyle} from 'react-native';
import {
  BaseBookingFragment,
  Tags,
  useFindManyBookingsV3Query,
} from '../../generated/graphql';
import useBookingQueryVariables from '../../hooks/useBookingQueryVariables';
import useListQuery from '../../hooks/useListQuery';
import {BookingItemType} from '../../types/booking';
import {getOneToOnceCoachingLanguageParam} from '../../utils/booking';
import {HOME_SCREEN_LIST_ITEMS_TAKE_NUMBER} from '../../utils/constants';
import AppText from '../AppText';
import EmptyState from '../EmptyState';
import LoadingContent from '../LoadingContent';
import OneToOneCoachingBookingCard from '../OneToOneCoachingBookingCard';
import styles from './styles';

type Props = {
  tagsIds?: Tags['id'][];
  style?: StyleProp<ViewStyle>;
  onItemPress?: (itemId: BaseBookingFragment['id'], language: string) => void;
  onHrefGenerated?: (
    itemId: BaseBookingFragment['id'],
    language: string,
  ) => string;
};

export type OneToOneCoachingHomeSectionRef = {
  refresh: () => Promise<void>;
  fetchNext: () => Promise<void>;
};

const OneToOneCoachingHomeSection = forwardRef<
  OneToOneCoachingHomeSectionRef,
  Props
>(({onItemPress, tagsIds, style, onHrefGenerated}, ref) => {
  const {t, i18n} = useTranslation();

  const getBookingQueryVariables = useBookingQueryVariables({
    selectedCategory: BookingItemType.ONE_TO_ONE_SESSION,
    selectedTags: tagsIds,
  });

  const {data, loading, fetchNext, refetchWithParams} = useListQuery(
    useFindManyBookingsV3Query,
    getBookingQueryVariables,
    'findManyBookingsV3',
    {take: HOME_SCREEN_LIST_ITEMS_TAKE_NUMBER},
  );

  const {width, onLayout} = useLayout();

  const Items = useMemo(
    () =>
      data?.map(item => {
        const language = getOneToOnceCoachingLanguageParam(
          i18n.language,
          item.OneToOneSessions_languages,
        );
        const href = onHrefGenerated?.(item.id, language);

        return item.Coaches ? (
          <OneToOneCoachingBookingCard
            {...item}
            key={item.id}
            onPress={onItemPress}
            width={width}
            href={href}
          />
        ) : null;
      }),
    [data, i18n.language, onHrefGenerated, onItemPress, width],
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        async refresh() {
          await refetchWithParams({force_refresh: true});
        },
        async fetchNext() {
          await fetchNext();
        },
      };
    },
    [fetchNext, refetchWithParams],
  );

  return (
    <View style={style} onLayout={onLayout}>
      <AppText style={styles.title}>{t('booking.ONE_TO_ONE_SESSION')}</AppText>
      <View style={styles.container}>
        {loading ? (
          <LoadingContent />
        ) : !data || data.length === 0 ? (
          <EmptyState label={t('home.all_your_events_are_done')} />
        ) : (
          <View style={styles.list}>{Items}</View>
        )}
      </View>
    </View>
  );
});

export default React.memo(OneToOneCoachingHomeSection);
