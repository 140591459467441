import React, {useCallback, useMemo} from 'react';
import {
  StyleProp,
  TextStyle,
  TouchableHighlight,
  ViewStyle,
} from 'react-native';
import colors from '../../theme/colors';
import {FilterButtonType} from '../../types/common';
import AppText from '../AppText';
import styles from './styles';

type Props = FilterButtonType & {
  onPress: (key: string) => void;
  style?: StyleProp<ViewStyle>;
};

const FilterButton: React.FC<Props> = ({
  id,
  label,
  isSelected,
  onPress,
  style,
}) => {
  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, isSelected ? styles.selectedContainer : {}, style],
    [isSelected, style],
  );

  const labelStyle = useMemo<StyleProp<TextStyle>>(
    () => [styles.label, isSelected ? styles.selectedLabel : {}],
    [isSelected],
  );

  const handlePress = useCallback(() => onPress(id), [id, onPress]);

  return (
    <TouchableHighlight
      style={containerStyle}
      onPress={handlePress}
      underlayColor={colors.whiteAlpha40}>
      <AppText style={labelStyle}>{label}</AppText>
    </TouchableHighlight>
  );
};

export default React.memo(FilterButton);
