import format from 'date-fns/format';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {TextInputProps} from 'react-native';
import Button from '../../../../components/Button/Button';
import {ButtonStyle} from '../../../../components/Button/Button.props';
import AppInput from '../../../../mindance-libs/components/AppInput';
import ContactUs from '../../../../mindance-libs/components/ContactUs';
import ScreenBase from '../../../../mindance-libs/components/ScreenBase';
import useRedeemActivationCode from '../../../../mindance-libs/hooks/useRedeemActivationCode';
import useRedeemActivationCodeScreen from '../../../../mindance-libs/hooks/useRedeemActivationCodeScreen';
import {useAppContext} from '../../../../mindance-libs/store/contexts/AppContext';
import useTypedNavigate from '../../../../navigation/typed/useTypedNavigate';
import styles from './page.module.scss';
import RNstyles from './rn.styles';

export default function Page() {
  const {t} = useTranslation();
  const {userSubscriptions} = useAppContext();

  const {
    successfulMessage,
    error,
    handleActivationCodeBlur,
    handleSubmit,
    hasActivationCodeError,
    setActivationCode,
    values,
  } = useRedeemActivationCode();

  const {codesInfo} = useRedeemActivationCodeScreen();

  const navigate = useTypedNavigate();

  const hasSubscriptions = userSubscriptions.length > 0;

  const CodePoints = useMemo(
    () =>
      codesInfo?.map(({id, code, expirationDate}) => {
        const expirationDatePart = expirationDate
          ? t('settings.valid_until', {
              date: format(new Date(expirationDate), 'd.M.y'),
            })
          : '';

        return (
          <p
            key={id}
            className={
              styles[
                'redeem-activation-code__content__wrapper__description__point'
              ]
            }>
            {t('settings.code_point', {code}) + ' ' + expirationDatePart}
          </p>
        );
      }),
    [codesInfo, t],
  );

  const backButton = useMemo(
    () => ({title: t('common.return') || '', onClick: () => navigate(-1)}),
    [navigate, t],
  );

  const handleBlur = useCallback<NonNullable<TextInputProps['onBlur']>>(
    e => {
      handleActivationCodeBlur(e);
      handleSubmit();
    },
    [handleActivationCodeBlur, handleSubmit],
  );

  return (
    <ScreenBase error={error} message={successfulMessage}>
      <div className={styles['redeem-activation-code__container']}>
        <Button
          showLeftArrow
          button={backButton}
          className={styles['redeem-activation-code__button']}
          buttonStyle={ButtonStyle.INLINE_WHITE}
        />
        <h1>{t('screenTitle.RedeemActivationCodeScreen')}</h1>
        <div className={styles['redeem-activation-code__content']}>
          <div className={styles['redeem-activation-code__content__wrapper']}>
            {!hasSubscriptions ? (
              <p
                className={
                  styles[
                    'redeem-activation-code__content__wrapper__description'
                  ]
                }>
                {t('settings.you_have_not_redeemed_code_yet')}
              </p>
            ) : CodePoints ? (
              <>
                <p
                  className={
                    styles[
                      'redeem-activation-code__content__wrapper__description'
                    ]
                  }>
                  {t(
                    'settings.you_have_successfully_redeemed_following_activation_codes',
                  )}
                </p>
                {CodePoints}
              </>
            ) : null}
            <div
              className={
                styles['redeem-activation-code__content__wrapper__fields']
              }>
              <AppInput
                label={t('settings.activation_code')}
                placeholder={t('settings.activation_code')}
                value={values.activationCode}
                onChangeText={setActivationCode}
                onBlur={handleBlur}
                autoCapitalize="none"
                autoCorrect={false}
                error={hasActivationCodeError}
                testID="redeemActivationCode.activationCodeInput"
              />
              <ContactUs
                title={t('settings.activation_code_failed')}
                linkTitle={t('settings.contact_support')}
                style={RNstyles.contactUs}
                textStyle={RNstyles.contactUsTitle}
              />
            </div>
          </div>
        </div>
      </div>
    </ScreenBase>
  );
}
