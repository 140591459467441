import React, {useMemo} from 'react';
import {
  Image,
  ImageSourcePropType,
  StyleProp,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import Animated, {Layout, SlideInDown} from 'react-native-reanimated';
import {
  getEntryOrExitLayoutAnimation,
  getReanimatedLayoutAnimation,
} from '../../utils/animation';
import AppText from '../AppText';
import LayoutAnimationWrapper from '../LayoutAnimationWrapper';
import styles from './styles';

type Props = ViewProps & {
  animated?: boolean;
  image?: ImageSourcePropType;
  message: string;
  messageStyle?: StyleProp<ViewStyle>;
};

const AnimatedView = Animated.createAnimatedComponent(View);

const ANIMATION_DURATION = 1200;

const PsychologistMessage: React.FC<Props> = ({
  animated = true,
  image,
  message,
  style,
  messageStyle,
  testID,
  children,
  ...props
}) => {
  const containerStyle = useMemo(() => [styles.container, style], [style]);

  const mergedMessageStyle = useMemo(
    () => [styles.message, messageStyle],
    [messageStyle],
  );

  return (
    <LayoutAnimationWrapper
      duration={animated ? ANIMATION_DURATION : 1}
      initial="hidden"
      exit="hidden"
      animate="visible">
      <AnimatedView
        style={containerStyle}
        testID={testID}
        layout={
          animated
            ? getReanimatedLayoutAnimation(Layout.duration(ANIMATION_DURATION))
            : undefined
        }
        entering={
          animated
            ? getEntryOrExitLayoutAnimation(
                SlideInDown.duration(ANIMATION_DURATION),
              )
            : undefined
        }
        {...props}>
        <Image
          source={
            image ?? require('../../assets/images/psychologist_onboarding.jpg')
          }
          style={styles.image}
          testID={`${testID}.image`}
        />
        <View style={mergedMessageStyle} testID={`${testID}.messageContainer`}>
          <AppText style={styles.messageText} testID={`${testID}.message`}>
            {message}
          </AppText>
          {children}
        </View>
      </AnimatedView>
    </LayoutAnimationWrapper>
  );
};

export default React.memo(PsychologistMessage);
