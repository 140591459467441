import React from 'react';
import {useTranslation} from 'react-i18next';
import {ActivityIndicator} from 'react-native-paper';
import Button from '../../../components/Button/Button';
import ErrorOverlay from '../../../components/ErrorOverlay/ErrorOverlay';
import DashboardHomeCard from '../../../components/Home/DashboardHomeCard/DashboardHomeCard';
import EventtoolHomeCard from '../../../components/Home/EventtoolHomeCard/EventtoolHomeCard';
import GbuPsycheHomeCard from '../../../components/Home/GbuPsycheHomeCard/GbuPsycheHomeCard';
import ManagerToolHomeCard from '../../../components/Home/ManagerToolHomeCard/ManagerToolHomeCard';
import NewstoolHomeCard from '../../../components/Home/NewstoolHomeCard/NewstoolHomeCard';
import {useSubscriptionsContext} from '../../../contexts/SubscriptionsContext';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import SettingsIcon from '../../../mindance-libs/icons/SettingsIcon';
import {useAppContext} from '../../../mindance-libs/store/contexts/AppContext';
import colors from '../../../mindance-libs/theme/colors';
import {RouteKeys} from '../../../navigation/RouteKeys';
import {replacePlaceholders} from './page.data';
import styles from './page.module.scss';

export default function Page() {
  const {t} = useTranslation();
  const {user} = useAppContext();
  const {loading, error, mindManagerHome, mindManagerTools} =
    useSubscriptionsContext();

  const title =
    mindManagerHome?.MindManager_translations?.[0]?.Title ??
    t('mindManagerHome.title');
  const subtitle =
    mindManagerHome?.MindManager_translations?.[0]?.Subtitle ??
    t('mindManagerHome.subtitle');

  const updatedSubtitle = replacePlaceholders(subtitle, {
    FirstName: user?.FirstName,
  });

  const CardsContent: Record<string, React.JSX.Element> = {
    DASHBOARD_SHOW_RELATED_SUBSCRIPTIONS: <DashboardHomeCard />,
    EVENTTOOL_SHOW_RELATED_SUBSCRIPTIONS: <EventtoolHomeCard />,
    NEWSTOOL_SHOW_RELATED_SUBSCRIPTIONS: <NewstoolHomeCard />,
    GBU_PSYCHE_SHOW_RELATED_SUBSCRIPTIONS: <GbuPsycheHomeCard />,
  };

  if (error) {
    return <ErrorOverlay />;
  }

  return (
    <ScreenBase>
      <div className={styles.home__container}>
        {loading ? (
          <div className={styles.home__loading}>
            <ActivityIndicator color={colors.whiteAlpha50} size={80} />
          </div>
        ) : (
          <>
            <div className={styles.home__header}>
              <div className={styles['home__header__titles-wrapper']}>
                {title ? <h1>{title}</h1> : null}
                <div
                  className={styles['home__header__titles-wrapper__subtitle']}>
                  {updatedSubtitle ? <h1>{updatedSubtitle}</h1> : null}
                </div>
              </div>
              <Button
                className={styles['home__header__settings-wrapper']}
                anchor={{href: RouteKeys.Settings}}>
                <div className={styles['home__header__settings-wrapper__icon']}>
                  <SettingsIcon />
                </div>
                <p>{t('mindManagerHome.settings')}</p>
              </Button>
            </div>
            <div className={styles.home__content}>
              {mindManagerTools.map(tool => (
                <ManagerToolHomeCard
                  {...tool}
                  key={tool.id}
                  className={styles.home__content__card}>
                  {tool.Products?.Label
                    ? CardsContent[tool.Products.Label] ?? null
                    : null}
                </ManagerToolHomeCard>
              ))}
            </div>
          </>
        )}
      </div>
    </ScreenBase>
  );
}
