import i18n, {Resource} from 'i18next';
import {initReactI18next} from 'react-i18next';
import de from '../../strings/de.json';
import en from '../../strings/en.json';
import {Languages} from '../../types/common';
import {AVAILABLE_LANGUAGES, localizationStore} from './helpers';

const resources: Resource = {
  [Languages.ENGLISH]: en,
  [Languages.GERMAN]: de,
};

localizationStore.getLanguage().then(async languageCode => {
  await i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    lng: languageCode,
    fallbackLng: Languages.GERMAN,
    supportedLngs: AVAILABLE_LANGUAGES,
    resources,
  });
});

export default i18n;
