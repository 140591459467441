import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useInView} from 'react-intersection-observer';
import {useWindowDimensions} from 'react-native';
import Button from '../../../components/Button/Button';
import {ButtonStyle} from '../../../components/Button/Button.props';
import MeinFSConnection from '../../../components/MeinFSConnection/MeinFSConnection';
import useElementSize from '../../../hooks/useElementSize';
import CompanyItemsCarousel from '../../../mindance-libs/components/CompanyItemsCarousel';
import CoursesHomeSection, {
  CoursesHomeSectionProps,
} from '../../../mindance-libs/components/CoursesHomeSection';
import EventsHomeSection from '../../../mindance-libs/components/EventsHomeSection';
import GroupCoachingHomeSection from '../../../mindance-libs/components/GroupCoachingHomeSection';
import OneToOneCoachingHomeSection, {
  OneToOneCoachingHomeSectionRef,
} from '../../../mindance-libs/components/OneToOneCoachingHomeSection';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import SessionsHomeSection from '../../../mindance-libs/components/SessionsHomeSection';
import UserTagsModal from '../../../mindance-libs/components/UserTagsModal';
import {BaseBookingFragment} from '../../../mindance-libs/generated/graphql';
import useHomeInterestsModal from '../../../mindance-libs/hooks/useHomeInterestsModal';
import useHomeScreen from '../../../mindance-libs/hooks/useHomeScreen';
import FilterIcon from '../../../mindance-libs/icons/FilterIcon';
import spacings from '../../../mindance-libs/theme/spacings';
import {BookingItemType} from '../../../mindance-libs/types/booking';
import {AppProductsLabels} from '../../../mindance-libs/types/common';
import {LibraryItemType} from '../../../mindance-libs/types/library';
import breakpointProvider from '../../../mindance-libs/utils/breakpointProvider';
import NavigationKeys from '../../../navigation/NavigationKeys';
import {RouteKeys} from '../../../navigation/RouteKeys';
import useTypedNavigate from '../../../navigation/typed/useTypedNavigate';
import {generateUrlWithParams} from '../../../navigation/utils';
import styles from './page.module.scss';

const FilterIconComponent = <FilterIcon height={16} width={16} />;

const DEFAULT_PADDING_HORIZONTAL = spacings.xxxxl;
const MIN_PADDING_HORIZONTAL = spacings.xxl;
const COURSE_MAX_WIDTH = 280;
const GROUP_COACHING_MAX_WIDTH = 280;
const EVENT_MAX_WIDTH = 230;
const SESSION_MAX_WIDTH = 230;

export default function Page() {
  const {t} = useTranslation();
  const {width} = useWindowDimensions();
  const {
    onLoad: onContentDivLoad,
    ref: contentDiv,
    size: contentDivSize,
  } = useElementSize<HTMLDivElement>();

  const navigate = useTypedNavigate();

  const [oneToOneCoachingBottomRef, oneToOneCoachingBottomInView] = useInView({
    threshold: 0,
  });
  const oneToOneCoachingRef = useRef<OneToOneCoachingHomeSectionRef>(null);

  const {
    meinFSLink,
    title,
    subtitle,
    GeneralSetting_Home_GeneralSettingToGeneralSetting,
    Infos_Home_InfosEventsToInfos,
    Infos_Home_InfosGroupCoachingsToInfos,
    Infos_Home_InfosYourCoursesToInfos,
    screensSetupCategoriesAvailability,
  } = useHomeScreen();

  const {
    closeModal,
    isModalVisible,
    openInterestsModal,
    selectTag,
    selectedTagsRecord,
    submitTags,
    tagsFilters,
    deselectAllTags,
    selectAllTags,
    userTags,
  } = useHomeInterestsModal(GeneralSetting_Home_GeneralSettingToGeneralSetting);

  const paddingHorizontal =
    width <= breakpointProvider.$gridBreakpoints.md
      ? MIN_PADDING_HORIZONTAL
      : DEFAULT_PADDING_HORIZONTAL;

  const itemsStyle = useMemo<React.CSSProperties>(
    () => ({paddingLeft: paddingHorizontal, paddingRight: paddingHorizontal}),
    [paddingHorizontal],
  );

  const interestsButtonProps = useMemo(
    () => ({onClick: openInterestsModal}),
    [openInterestsModal],
  );

  const courseItemWidth =
    width * 0.75 > COURSE_MAX_WIDTH ? COURSE_MAX_WIDTH : width * 0.75;
  const groupCoachingItemWidth =
    width * 0.75 > GROUP_COACHING_MAX_WIDTH
      ? GROUP_COACHING_MAX_WIDTH
      : width * 0.75;
  const eventItemWidth =
    width * 0.5 > EVENT_MAX_WIDTH ? EVENT_MAX_WIDTH : width * 0.5;
  const sessionItemWidth =
    width * 0.5 > SESSION_MAX_WIDTH ? SESSION_MAX_WIDTH : width * 0.5;

  const handleMeinFSLink = useCallback(() => {
    if (meinFSLink) {
      window.open(meinFSLink, '_blank');
    }
  }, [meinFSLink]);

  const openCourse = useCallback<(courseId: string) => void>(
    courseId => {
      navigate(
        {to: RouteKeys.Course, params: {id: courseId}},
        {state: {screen: NavigationKeys.HomeScreen}},
      );
    },
    [navigate],
  );

  const openEvent = useCallback<
    (eventId: BaseBookingFragment['id'], language: string) => void
  >(
    (eventId, language) => {
      navigate({
        to: RouteKeys.EventDetails,
        params: {id: eventId},
        queryParams: {language},
      });
    },
    [navigate],
  );

  const openSession = useCallback<(sessionId: string) => void>(
    sessionId => {
      navigate(
        {to: RouteKeys.Session, params: {id: sessionId}},
        {state: {screen: NavigationKeys.HomeScreen}},
      );
    },
    [navigate],
  );

  const openNewsScreen = useCallback<(id: string) => void>(
    id => {
      navigate({to: RouteKeys.News, params: {}, queryParams: {newsId: id}});
    },
    [navigate],
  );

  const coursesCommonProps = useMemo<CoursesHomeSectionProps>(
    () => ({
      tagsIds: userTags,
      itemWidth: courseItemWidth,
      info: Infos_Home_InfosYourCoursesToInfos,
      onItemPress: openCourse,
      discoverMoreHref: generateUrlWithParams(
        RouteKeys.LibraryItems,
        {},
        {itemsType: LibraryItemType.COURSES},
      ),
      paddingHorizontal,
    }),
    [
      Infos_Home_InfosYourCoursesToInfos,
      courseItemWidth,
      openCourse,
      paddingHorizontal,
      userTags,
    ],
  );

  const generateHrfToOneToOneCoachingPage = useCallback<
    (id: BaseBookingFragment['id'], language: string) => string
  >(
    (id, language) =>
      generateUrlWithParams(RouteKeys.OneToOneSessionDetails, {id}, {language}),
    [],
  );

  useEffect(() => {
    if (oneToOneCoachingBottomInView) {
      oneToOneCoachingRef.current?.fetchNext();
    }
  }, [oneToOneCoachingBottomInView]);

  return (
    <ScreenBase>
      <div className={styles.start__container}>
        <div className={styles.start__header} style={itemsStyle}>
          <div>
            <h1 className={styles.start__header__title}>{title}</h1>
            <p className={styles.start__header__subtitle}>{subtitle}</p>
          </div>
          <Button
            button={interestsButtonProps}
            buttonStyle={ButtonStyle.INLINE}
            rightIcon={FilterIconComponent}>
            <p>{t('home.interests')}</p>
          </Button>
        </div>

        <div
          className={styles.start__content}
          onLoad={onContentDivLoad}
          ref={contentDiv}>
          {contentDivSize.width > 0 ? (
            <div className={styles.start__content__news}>
              <CompanyItemsCarousel
                tagsIds={userTags}
                width={contentDivSize.width - 2 * paddingHorizontal}
                gap={paddingHorizontal}
                onNewsBannerPress={openEvent}
                onNewsItemPress={openNewsScreen}
              />
            </div>
          ) : null}

          {screensSetupCategoriesAvailability[AppProductsLabels.COURSES] ? (
            <div className={styles.start__content__section}>
              <CoursesHomeSection
                {...coursesCommonProps}
                title={t('home.your_started_courses')}
                subtitle={t('home.pick_up_where_you_left_off')}
                emptyLabel={t('home.empty_started_sessions')}
                isInProgress={true}
              />
            </div>
          ) : null}
          {screensSetupCategoriesAvailability[AppProductsLabels.COURSES] ? (
            <div className={styles.start__content__section}>
              <CoursesHomeSection
                {...coursesCommonProps}
                title={t('home.courses_for_your_interests')}
                subtitle={t('home.video_and_audio_courses_for_you')}
                isInProgress={false}
              />
            </div>
          ) : null}
          {screensSetupCategoriesAvailability[
            AppProductsLabels.GROUP_COACHING
          ] ? (
            <div className={styles.start__content__section}>
              <GroupCoachingHomeSection
                tagsIds={userTags}
                itemWidth={groupCoachingItemWidth}
                info={Infos_Home_InfosGroupCoachingsToInfos}
                onItemPress={openEvent}
                discoverMoreHref={generateUrlWithParams(
                  RouteKeys.Booking,
                  {},
                  {selectedCategory: BookingItemType.GROUP_COACHING},
                )}
                paddingHorizontal={paddingHorizontal}
              />
            </div>
          ) : null}
          {screensSetupCategoriesAvailability[AppProductsLabels.EVENT] ? (
            <div className={styles.start__content__section}>
              <EventsHomeSection
                tagsIds={userTags}
                itemWidth={eventItemWidth}
                info={Infos_Home_InfosEventsToInfos}
                onItemPress={openEvent}
                discoverMoreHref={generateUrlWithParams(
                  RouteKeys.Booking,
                  {},
                  {selectedCategory: BookingItemType.EVENT},
                )}
                paddingHorizontal={paddingHorizontal}
              />
            </div>
          ) : null}
          {screensSetupCategoriesAvailability[AppProductsLabels.SESSIONS] ? (
            <div className={styles.start__content__section}>
              <SessionsHomeSection
                tagsIds={userTags}
                itemWidth={sessionItemWidth}
                onItemPress={openSession}
                discoverMoreHref={generateUrlWithParams(
                  RouteKeys.LibraryItems,
                  {},
                  {itemsType: LibraryItemType.SESSIONS},
                )}
                paddingHorizontal={paddingHorizontal}
              />
            </div>
          ) : null}

          {meinFSLink ? (
            <div
              className={styles['start__content__mein-fs']}
              style={itemsStyle}>
              <MeinFSConnection onPress={handleMeinFSLink} />
            </div>
          ) : null}

          {screensSetupCategoriesAvailability[
            AppProductsLabels.ONE_TO_ONE_SESSION
          ] ? (
            <div
              className={styles.start__content__section}
              style={{
                paddingLeft: paddingHorizontal,
                paddingRight: paddingHorizontal,
              }}>
              <OneToOneCoachingHomeSection
                ref={oneToOneCoachingRef}
                onHrefGenerated={generateHrfToOneToOneCoachingPage}
                tagsIds={userTags}
              />
              <div ref={oneToOneCoachingBottomRef} />
            </div>
          ) : null}
        </div>
      </div>

      <UserTagsModal
        isVisible={isModalVisible}
        onDismiss={closeModal}
        tagGroups={tagsFilters}
        selectedTagsRecord={selectedTagsRecord}
        onTagSelect={selectTag}
        onSubmit={submitTags}
        title={t('home.your_interests')}
        onDeselectAll={deselectAllTags}
        onSelectAll={selectAllTags}
      />
    </ScreenBase>
  );
}
