import {useFocusEffect} from '@react-navigation/native';
import * as R from 'ramda';
import React, {forwardRef, useCallback, useImperativeHandle} from 'react';
import {useTranslation} from 'react-i18next';
import {FlatListProps, ListRenderItem} from 'react-native';
import NavigationKeys from '../../../navigation/NavigationKeys';
import {
  BaseCourseFragment,
  FindManyCoursesQueryVariables,
  useFindManyCoursesQuery,
} from '../../generated/graphql';
import useListQuery from '../../hooks/useListQuery';
import useProgressReports from '../../hooks/useProgressReports';
import {useAppContext} from '../../store/contexts/AppContext';
import {ProgressReportTypes} from '../../types/progressReport';
import {
  HOME_SCREEN_LIST_ITEMS_LIMIT,
  HOME_SCREEN_LIST_ITEMS_TAKE_NUMBER,
  SCREEN_WIDTH,
} from '../../utils/constants';
import {getImageUrl} from '../../utils/files';
import {getCurrentProgress} from '../../utils/progress';
import CourseHomeCard from '../CourseHomeCard';
import HomeCardsCarousel, {HomeCardsCarouselProps} from '../HomeCardsCarousel';
import styles from './styles';

export type CoursesHomeSectionProps = Partial<
  HomeCardsCarouselProps<BaseCourseFragment>
> & {
  isInProgress?: boolean;
  onItemPress: (itemId: BaseCourseFragment['id']) => void;
};

export type CoursesHomeSectionRef = {
  refresh: () => Promise<void>;
};

const IMAGE_RATIO = 1;

const keyExtractor: FlatListProps<BaseCourseFragment>['keyExtractor'] = item =>
  item.id;

const CoursesHomeSection = forwardRef<
  CoursesHomeSectionRef,
  CoursesHomeSectionProps
>(
  (
    {
      itemWidth = SCREEN_WIDTH * 0.75,
      testID,
      onItemPress,
      tagsIds,
      isInProgress,
      ...props
    },
    ref,
  ) => {
    const {t, i18n} = useTranslation();

    const {user} = useAppContext();

    const {reportOpenCourse} = useProgressReports();

    const getQueryVariables = useCallback<
      (
        input?: Partial<FindManyCoursesQueryVariables>,
      ) => FindManyCoursesQueryVariables
    >(
      (input = {}) => {
        const progressReportCondition: NonNullable<
          FindManyCoursesQueryVariables['where']
        > = isInProgress
          ? {
              AND: [
                {
                  ProgressReports: {
                    some: {
                      AND: [
                        {Label: {equals: ProgressReportTypes.FINISHED_SESSION}},
                        {User: {equals: Number(user?.id ?? 0)}},
                      ],
                    },
                  },
                },
                {
                  ProgressReports: {
                    every: {
                      NOT: [
                        {
                          AND: [
                            {
                              Label: {
                                equals: ProgressReportTypes.FINISHED_COURSE,
                              },
                            },
                            {User: {equals: Number(user?.id ?? 0)}},
                          ],
                        },
                      ],
                    },
                  },
                },
              ],
            }
          : {
              ProgressReports: {
                every: {
                  NOT: [
                    {
                      AND: [
                        {Label: {equals: ProgressReportTypes.FINISHED_COURSE}},
                        {User: {equals: Number(user?.id ?? 0)}},
                      ],
                    },
                    {
                      AND: [
                        {Label: {equals: ProgressReportTypes.FINISHED_SESSION}},
                        {User: {equals: Number(user?.id ?? 0)}},
                      ],
                    },
                  ],
                },
              },
            };

        const baseVariables: FindManyCoursesQueryVariables = {
          where: {
            ...(tagsIds && tagsIds?.length > 0
              ? {Courses_Tags: {some: {Tags_id: {in: tagsIds}}}}
              : {}),
            ...progressReportCondition,
          },
          language: i18n.language,
          force_refresh: true,
        };

        return R.mergeDeepRight(
          baseVariables,
          input,
        ) as FindManyCoursesQueryVariables;
      },
      [i18n.language, isInProgress, tagsIds, user?.id],
    );

    const {data, loading, error, refetch, fetchNext, refetchWithParams} =
      useListQuery(
        useFindManyCoursesQuery,
        getQueryVariables,
        'findManyCourses',
        {
          take: HOME_SCREEN_LIST_ITEMS_TAKE_NUMBER,
          limit: HOME_SCREEN_LIST_ITEMS_LIMIT,
        },
      );

    const renderItem = useCallback<ListRenderItem<BaseCourseFragment>>(
      ({
        item: {
          id: courseId,
          Label,
          TimeRecommendation,
          directus_files,
          ProgressReports,
          Courses_Sessions,
        },
      }) => {
        const sessionIds =
          Courses_Sessions?.reduce<string[]>(
            (acc, {Sessions}) => (Sessions?.id ? [...acc, Sessions?.id] : acc),
            [],
          ) ?? [];

        const {total, current, difference} = getCurrentProgress({
          sessionIds,
          progressReports: ProgressReports,
        });

        const handleOpenCourse = () => {
          onItemPress(courseId);

          reportOpenCourse({
            email: user?.EMail,
            screen: NavigationKeys.HomeScreen,
            courseId,
            courseProgress: difference,
          });
        };

        return (
          <CourseHomeCard
            title={Label}
            timeRecommendation={
              TimeRecommendation
                ? t('courses.course_time_recommendation', {
                    timeRecommendation: TimeRecommendation,
                  })
                : null
            }
            imageUrl={getImageUrl(directus_files, {
              width: itemWidth,
              aspectRatio: IMAGE_RATIO,
            })}
            onPress={handleOpenCourse}
            width={itemWidth}
            aspectRatio={IMAGE_RATIO}
            testID={`${testID}.courseHomeCard`}
            currentProgress={current}
            totalProgress={total}
          />
        );
      },
      [itemWidth, onItemPress, reportOpenCourse, t, testID, user?.EMail],
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          async refresh() {
            await refetchWithParams({force_refresh: true});
          },
        };
      },
      [refetchWithParams],
    );

    useFocusEffect(
      useCallback(() => {
        const completedCourse = data?.find(({ProgressReports, id}) =>
          ProgressReports?.find(
            ({Label, Course}) =>
              Number(id) === Course &&
              Label === ProgressReportTypes.FINISHED_COURSE,
          ),
        );

        if (completedCourse) {
          refetch();
        }
      }, [data, refetch]),
    );

    return (
      <HomeCardsCarousel
        title={t('home.your_courses')}
        subtitle={t('home.video_and_audio_courses_for_you')}
        itemWidth={itemWidth}
        itemAspectRatio={IMAGE_RATIO}
        testID={testID}
        data={data}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        footerItemStyle={styles.item}
        error={error}
        loading={loading}
        emptyLabel={t('home.all_your_courses_are_done')}
        onEndReached={fetchNext}
        {...props}
      />
    );
  },
);

export default React.memo(CoursesHomeSection);
