import React, {useCallback, useEffect, useState} from 'react';
import {ActivityIndicator} from 'react-native-paper';
import {useSubscriptionsContext} from '../../../contexts/SubscriptionsContext';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import colors from '../../../mindance-libs/theme/colors';
import styles from './page.module.scss';
import {useAuthContext} from '../../../mindance-libs/store/contexts/AuthContext';
import {useTranslation} from 'react-i18next';
import useElementSize from '../../../hooks/useElementSize';
import useErrorMessage from '../../../mindance-libs/hooks/useErrorMessage';
import classNames from 'classnames';

export default function Page() {
  const {t} = useTranslation();
  const {obtainMindManagerEventtoolSSOLink} = useAuthContext();
  const [frameSrc, setFrameSrc] = useState<string>();
  const {showMessage, error} = useErrorMessage();

  const {
    ref: containerRef,
    size,
    onLoad: onContainerLoad,
  } = useElementSize<HTMLDivElement>();

  const {loading: subscriptionsLoading, selectedSubscriptionId} =
    useSubscriptionsContext();
  const [frameLoading, setFrameLoading] = useState(true);
  const disableFrameLoading = useCallback(() => setFrameLoading(false), []);

  useEffect(() => {
    const obtainFrameSrc = async () => {
      if (!selectedSubscriptionId) {
        return;
      }

      try {
        const response = await obtainMindManagerEventtoolSSOLink(
          Number(selectedSubscriptionId),
        );
        if (response?.link) {
          setFrameSrc(response?.link);
        }
      } catch (e) {
        showMessage(t('errors.something_went_wrong'));
      }
    };

    obtainFrameSrc();
  }, [
    obtainMindManagerEventtoolSSOLink,
    selectedSubscriptionId,
    showMessage,
    t,
  ]);

  const loading = subscriptionsLoading || frameLoading || !frameSrc;

  return (
    <ScreenBase error={error}>
      <div
        className={styles.eventtool__container}
        ref={containerRef}
        onLoad={onContainerLoad}>
        {loading && (
          <div className={styles.eventtool__loading}>
            <ActivityIndicator color={colors.whiteAlpha50} size={80} />
          </div>
        )}
        <iframe
          title={t('mindManagerEventtool.title') ?? ''}
          loading="eager"
          width={size.width}
          height={size.height}
          src={frameSrc}
          className={classNames(styles.eventtool__frame, {
            [styles['eventtool__frame--loading']]: frameLoading,
          })}
          onLoad={disableFrameLoading}
        />
      </div>
    </ScreenBase>
  );
}
