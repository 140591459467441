import React from 'react';
import styles from './SidebarNavSection.module.scss';

import SidebarNavItem, {NavItem} from '../SidebarNavItem/SidebarNavItem';

export type NavSection = {
  title: string;
  items: NavItem[];
};

type Props = {
  section: NavSection;
  isLastSection: boolean;
  pathname: string;
};

const SidebarNavSection = ({section, isLastSection, pathname}: Props) => (
  <>
    <div className={styles['sidebar-nav-section']}>
      <p className={styles['sidebar-nav-section__title']}>{section.title}</p>
      <ul className={styles['sidebar-nav-section__items']}>
        {section.items.map(item => {
          const isCurrent =
            pathname.split('/')?.[1] === item.routeKey.split('/')?.[1];
          return (
            <SidebarNavItem item={item} isCurrent={isCurrent} key={item.id} />
          );
        })}
      </ul>
    </div>
    {!isLastSection && (
      <div className={styles['sidebar-nav-section__separator']} />
    )}
  </>
);

export default React.memo(SidebarNavSection);
