import * as R from 'ramda';
import {SelectedTagsRecord} from '../types/common';
import {OnboardingSlides} from '../types/onboarding';

export const ONBOARDING_SLIDES = [
  OnboardingSlides.INTRO,
  OnboardingSlides.DESCRIPTION,
  OnboardingSlides.SURVEY,
  OnboardingSlides.SUMMARY,
];

export const getIsButtonDisabled = (selectedTags: SelectedTagsRecord) =>
  R.isEmpty(selectedTags)
    ? true
    : R.all(R.equals(false), R.values(selectedTags));

export const getDisplayedInitialState = (keys: string[]) =>
  keys.reduce<Record<string, boolean>>(
    (acc, key) => ({...acc, [key]: false}),
    {},
  );
