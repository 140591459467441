import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {SMALL_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  container: {
    borderRadius: SMALL_BORDER_RADIUS,
    borderWidth: 1,
    borderColor: colors.whiteAlpha13,
    backgroundColor: colors.whiteAlpha09,
    paddingHorizontal: spacings.xxxxxxl,
    paddingVertical: spacings.xxxl,
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    ...typography.input,
    color: colors.white,
    textAlign: 'center',
  },
});

export default styles;
