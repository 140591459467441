import React from 'react';
import {useTranslation} from 'react-i18next';
import ContactUs, {
  TitleVariants,
} from '../../../mindance-libs/components/ContactUs';
import styles from './page.module.scss';

const TechnicalIssuesContent: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className={styles.password_change__space} />
      <div className={styles.password_change__content}>
        <h1 className={styles.password_change__title}>
          {t('auth.change_password_fail')}
        </h1>
        <span className={styles.password_change__description}>
          {t('auth.change_password_technical_issues')}
        </span>
      </div>
      <div className={styles['password_change__big-space']} />
      <div className={styles['password_change__contact-us']}>
        <ContactUs variant={TitleVariants.DEFAULT} />
      </div>
    </>
  );
};

export default React.memo(TechnicalIssuesContent);
