import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const MindanceMiniLogo: React.FC<SvgProps> = ({...props}) => {
  return (
    <Svg width={41} height={45} viewBox="0 0 41 45" fill="none" {...props}>
      <Path
        d="M37.6397 31.5539C39.8379 28.2293 41 24.3675 41 20.3842C41 9.14647 31.8038 0.00397491 20.5 0.00397491C9.19628 0.00397491 0 9.14647 0 20.3842C0 24.3677 1.16199 28.2293 3.36035 31.5539C3.40674 31.6262 3.45963 31.694 3.52049 31.7544C3.56836 31.8027 3.62125 31.8441 3.6758 31.8826C3.68063 31.8861 3.68471 31.8905 3.68972 31.8939C4.07161 32.155 4.58822 32.1758 4.9974 31.904C5.3542 31.6668 5.53457 31.2677 5.51491 30.8682V23.1398C5.51491 22.4922 4.99051 21.9671 4.34383 21.9671C3.69715 21.9671 3.17275 22.4922 3.17275 23.1398V25.7579C2.62849 24.03 2.34217 22.2228 2.34217 20.3842C2.34217 10.4397 10.4878 2.34935 20.5 2.34935C30.5122 2.34935 38.6579 10.4397 38.6579 20.3842C38.6579 22.2228 38.3713 24.03 37.8271 25.7579V23.1398C37.8271 22.4922 37.3027 21.9671 36.656 21.9671C36.0093 21.9671 35.4849 22.4922 35.4849 23.1398V30.8682C35.4653 31.2677 35.6456 31.6668 36.0026 31.904C36.0431 31.9309 36.0884 31.9428 36.1308 31.9641C36.1644 31.9811 36.1977 31.9981 36.2331 32.0121C36.2875 32.0331 36.3411 32.0508 36.3972 32.063C36.4326 32.0712 36.4695 32.0742 36.5061 32.0788C36.554 32.0847 36.6011 32.0996 36.649 32.0996C36.731 32.0996 36.812 32.0871 36.8919 32.0703H36.892C36.9416 32.0597 36.9883 32.0422 37.0362 32.0255C37.0724 32.0128 37.1084 32.0008 37.1431 31.9848C37.1806 31.9675 37.216 31.9472 37.2518 31.9257C37.2965 31.8991 37.3383 31.8696 37.379 31.8374C37.4036 31.8175 37.4289 31.799 37.4523 31.7769C37.5235 31.7103 37.5868 31.6362 37.6397 31.5539Z"
        fill="white"
      />
      <Path
        d="M8.38347 32.1416C7.7368 32.1416 7.2124 31.6165 7.2124 30.9689V23.1125C7.2124 22.4649 7.7368 21.9398 8.38347 21.9398C9.03014 21.9398 9.55459 22.4649 9.55459 23.1125V30.9689C9.55459 31.6165 9.03014 32.1416 8.38347 32.1416Z"
        fill="white"
      />
      <Path
        d="M16.4606 44.5352C15.8139 44.5352 15.2896 44.01 15.2896 43.3625V10.7152C15.2896 10.0676 15.8139 9.54251 16.4606 9.54251C17.1073 9.54251 17.6317 10.0676 17.6317 10.7152V43.3625C17.6317 44.01 17.1073 44.5352 16.4606 44.5352Z"
        fill="white"
      />
      <Path
        d="M20.4995 40.4824C19.8528 40.4824 19.3284 39.9573 19.3284 39.3098V14.7655C19.3284 14.1179 19.8528 13.5928 20.4995 13.5928C21.1462 13.5928 21.6706 14.1179 21.6706 14.7655V39.3098C21.6706 39.9573 21.1462 40.4824 20.4995 40.4824Z"
        fill="white"
      />
      <Path
        d="M24.5392 38.5684C23.8926 38.5684 23.3682 38.0432 23.3682 37.3957V16.6788C23.3682 16.0313 23.8926 15.5061 24.5392 15.5061C25.1859 15.5061 25.7103 16.0313 25.7103 16.6788V37.3957C25.7103 38.0432 25.1859 38.5684 24.5392 38.5684Z"
        fill="white"
      />
      <Path
        d="M28.578 32.1416C27.9314 32.1416 27.407 31.6165 27.407 30.9689V23.1125C27.407 22.4649 27.9314 21.9398 28.578 21.9398C29.2248 21.9398 29.7492 22.4649 29.7492 23.1125V30.9689C29.7492 31.6165 29.2248 32.1416 28.578 32.1416Z"
        fill="white"
      />
      <Path
        d="M32.6167 40.4824C31.97 40.4824 31.4456 39.9573 31.4456 39.3098V14.7655C31.4456 14.1179 31.97 13.5928 32.6167 13.5928C33.2633 13.5928 33.7877 14.1179 33.7877 14.7655V39.3098C33.7877 39.9573 33.2633 40.4824 32.6167 40.4824Z"
        fill="white"
      />
      <Path
        d="M12.4221 38.5684C11.7754 38.5684 11.251 38.0432 11.251 37.3957V16.6788C11.251 16.0313 11.7754 15.5061 12.4221 15.5061C13.069 15.5061 13.5932 16.0313 13.5932 16.6788V37.3957C13.5932 38.0432 13.069 38.5684 12.4221 38.5684Z"
        fill="white"
      />
    </Svg>
  );
};

export default React.memo(MindanceMiniLogo);
