import {useLayout} from '@react-native-community/hooks';
import format from 'date-fns/format';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  LayoutAnimation,
  Platform,
  StyleProp,
  TouchableHighlight,
  View,
  ViewStyle,
  useWindowDimensions,
} from 'react-native';
import FastImage, {Source} from 'react-native-fast-image';
import {
  HTMLContentModel,
  HTMLElementModel,
  HTMLElementModelRecord,
} from 'react-native-render-html';
import {AppNewsFragment} from '../../generated/graphql';
import useCompanyConfig from '../../hooks/useCompanyConfig';
import RotatedPinIcon from '../../icons/RotatedPinIcon';
import {useAppContext} from '../../store/contexts/AppContext';
import colors from '../../theme/colors';
import {CompanyItemType} from '../../types/companyItem';
import breakpointProvider from '../../utils/breakpointProvider';
import {MAX_FONT_SIZE_MULTIPLIER} from '../../utils/constants';
import {getImageUrl} from '../../utils/files';
import {removeTags} from '../../utils/html';
import AppText from '../AppText';
import CompanyItemLabel from '../CompanyItemLabel';
import HTMLRenderer from '../HTMLRenderer';
// import {LOGO_SIZE} from '../HomeScreenHeader/styles';
import LikeButton from '../LikeButton';
import styles, {PIN_ICON_SIZE, descriptionTagStyles} from './styles';

type Props = AppNewsFragment & {
  onLikePress?: (id: AppNewsFragment['id']) => Promise<void>;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  onPress?: (id: AppNewsFragment['id']) => void;
  initiallyExpanded?: boolean;
  disableShowMore?: boolean;
  enableFullDescription?: boolean;
  enablePinnedBorder?: boolean;
};
type TextSize = {default: number; max: number};

const TEXT_LIMIT_WITH_IMAGE: TextSize = {default: 100, max: 150};
const TEXT_LIMIT_WITHOUT_IMAGE: TextSize = {default: 150, max: 200};

const NewsItem: React.FC<Props> = ({
  id,
  _count: {NewsLikes: likesCount},
  NewsLikes,
  date_created,
  Pinned,
  Users_News_AuthorCreatedToUsers,
  News_translations,
  directus_files,
  style,
  testID,
  onLikePress,
  onPress,
  disableShowMore = false,
  enableFullDescription = false,
  enablePinnedBorder = false,
  initiallyExpanded = false,
}) => {
  const {t} = useTranslation();
  const {width: imageWidth, onLayout: onImageContainerLayout} = useLayout();
  const {width: windowWidth} = useWindowDimensions();

  const {user} = useAppContext();
  const {highlightColor = colors.backgroundPrimary} = useCompanyConfig();

  const firsName = Users_News_AuthorCreatedToUsers?.FirstName ?? '';
  const lastName = Users_News_AuthorCreatedToUsers?.LastName ?? '';

  const hasImage = !!directus_files?.filename_disk;

  const isLiked = !!NewsLikes?.find(({Users}) => Users?.id === user?.id);

  const isLargeWindow = windowWidth > breakpointProvider.$gridBreakpoints.md;
  const textSizeKey: keyof TextSize = isLargeWindow ? 'max' : 'default';
  const textLimit = hasImage
    ? TEXT_LIMIT_WITH_IMAGE[textSizeKey]
    : TEXT_LIMIT_WITHOUT_IMAGE[textSizeKey];

  const Label = News_translations?.[0]?.Label;
  const Description = News_translations?.[0]?.Description;

  const isLargeDescription = enableFullDescription
    ? false
    : Description && Description?.length > 0
    ? (Label ?? '').length + Description.length > textLimit
    : false;

  const shortDescription =
    isLargeDescription && Description
      ? removeTags(Description).slice(
          0,
          textLimit - (Label ? Label.length : 0),
        ) + '... '
      : Description;

  const [currentDescription, setCurrentDescription] =
    useState(shortDescription);

  const expanded = currentDescription === Description;

  const content = !isLargeDescription
    ? currentDescription
    : expanded
    ? currentDescription +
      `<button style="background-color:transparent;">${t(
        'news.show_less',
      )}</button>`
    : currentDescription;

  const createdAt = date_created
    ? format(new Date(date_created), 'dd.MM.yyyy, HH:mm')
    : undefined;

  const showFullDescription = useCallback(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setCurrentDescription(Description);
  }, [Description]);

  const hideFullDescription = useCallback(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setCurrentDescription(shortDescription);
  }, [shortDescription]);

  useEffect(() => {
    if (initiallyExpanded) {
      showFullDescription();
    }
  }, [initiallyExpanded, showFullDescription]);

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [
      styles.container,
      style,
      Pinned && enablePinnedBorder
        ? {borderColor: highlightColor, borderWidth: 1}
        : {},
    ],
    [Pinned, enablePinnedBorder, highlightColor, style],
  );

  // const logoSource = useMemo<Source | undefined>(() => {
  //   const uri = getLogoUrl({width: LOGO_SIZE, height: LOGO_SIZE});
  //   return uri ? {uri} : undefined;
  // }, [getLogoUrl]);

  const imageSource = useMemo<Source | undefined>(() => {
    const uri = getImageUrl(directus_files, {
      width: imageWidth,
      aspectRatio: 1,
    });
    return uri ? {uri} : undefined;
  }, [directus_files, imageWidth]);

  const handlePress = useCallback(() => {
    onPress?.(id);
  }, [id, onPress]);

  const customHTMLElementModels = useMemo<HTMLElementModelRecord>(
    () => ({
      button: HTMLElementModel.fromCustomModel({
        tagName: 'button',
        reactNativeProps: {
          text: {
            suppressHighlighting: true,
            maxFontSizeMultiplier: MAX_FONT_SIZE_MULTIPLIER,
          },
          native: {
            onPress: disableShowMore
              ? handlePress
              : expanded
              ? hideFullDescription
              : showFullDescription,
          },
        },
        mixedUAStyles: {
          ...styles.showMore,
          alignSelf: expanded ? 'flex-start' : undefined,
        },
        contentModel: HTMLContentModel.textual,
      }),
    }),
    [
      disableShowMore,
      expanded,
      handlePress,
      hideFullDescription,
      showFullDescription,
    ],
  );

  const handleLike = useCallback(() => {
    onLikePress?.(id);
  }, [id, onLikePress]);

  return (
    <TouchableHighlight
      style={containerStyle}
      underlayColor={colors.whiteAlpha13}
      testID={testID}
      disabled={!onPress}
      onPress={onPress ? handlePress : undefined}>
      <>
        {Pinned ? (
          <RotatedPinIcon
            style={styles.pinIcon}
            width={PIN_ICON_SIZE}
            height={PIN_ICON_SIZE}
            fill={highlightColor}
          />
        ) : null}

        <View>
          <View style={styles.header}>
            {/* {logoSource ? (
              <FastImage
                style={styles.logo}
                source={logoSource}
                resizeMode="contain"
              />
            ) : null} */}
            <View style={styles.nameContainer}>
              <AppText style={styles.name}>{`${firsName} ${lastName}`}</AppText>
            </View>

            <CompanyItemLabel itemType={CompanyItemType.NEWS} />
          </View>
          <View style={styles.content}>
            <View style={styles.textContainer}>
              {Label ? <AppText style={styles.title}>{Label}</AppText> : null}
              {!content ? null : !isLargeDescription ||
                (isLargeDescription && expanded) ? (
                <HTMLRenderer
                  content={content}
                  tagsStyles={descriptionTagStyles}
                  customHTMLElementModels={customHTMLElementModels}
                />
              ) : (
                <AppText style={styles.description}>
                  {content}
                  <TouchableHighlight
                    underlayColor={colors.transparent}
                    onPress={
                      disableShowMore ? handlePress : showFullDescription
                    }>
                    <AppText style={styles.showMore}>
                      {t('news.show_more')}
                    </AppText>
                  </TouchableHighlight>
                </AppText>
              )}
            </View>
            {hasImage ? (
              <View
                style={styles.imageContainer}
                onLayout={onImageContainerLayout}>
                <FastImage source={imageSource} style={styles.image} />
              </View>
            ) : null}
          </View>
        </View>
        <View style={styles.bottom}>
          <View
            style={
              !expanded && Platform.OS === 'android' && isLargeDescription
                ? undefined
                : styles.likeContainer
            }>
            {onLikePress ? (
              <>
                <LikeButton
                  style={styles.like}
                  isLiked={isLiked}
                  onPress={handleLike}
                />
                {likesCount > 0 ? (
                  <AppText style={styles.date}>{likesCount}</AppText>
                ) : null}
              </>
            ) : null}
          </View>

          {createdAt ? (
            <AppText style={styles.date}>
              {t('booking.event_time', {time: createdAt})}
            </AppText>
          ) : (
            <View />
          )}
        </View>
      </>
    </TouchableHighlight>
  );
};

export default React.memo(NewsItem);
