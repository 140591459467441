import {LoadingState} from '../../interfaces/ImageProps';
import {RouteKeys} from '../../navigation/RouteKeys';
import {AnchorTargetAttribute} from '../Button/Button.props';
import SidebarProps from './Sidebar.props';

export const SidebarDataLang: Record<string, SidebarProps> = {};

SidebarDataLang['de-DE'] = {
  testID: 'sidebar',

  logo: {
    link: {
      anchor: {
        href: '/',
        target: AnchorTargetAttribute.SELF,
        title: 'Startseite',
      },
    },
  },

  home: {
    label: 'Home',
    iconKey: 'fa-solid fa-house',
    routeKey: RouteKeys.Home,
  },

  logoutButton: {
    button: {
      title: 'Logout',
    },
  },

  profile: {
    link: {
      anchor: {
        href: '/home/settings',
        target: AnchorTargetAttribute.SELF,
        title: 'Einstellungen',
      },
    },
    image: {
      src: '/images/avatar-placeholder.png',
      title: 'profil',
      alt: 'Profilbild',
      loading: LoadingState.LAZY,
      width: 41,
      height: 41,
    },
  },

  appTitle: 'Mindance App',
  managerTitle: 'Mind Manager',
};

SidebarDataLang['en-US'] = {
  ...SidebarDataLang['de-DE'],

  logo: {
    link: {
      anchor: {
        href: '/',
        target: AnchorTargetAttribute.SELF,
        title: 'Homepage',
      },
    },
  },

  profile: {
    image: {
      src: '/images/avatar-placeholder.png',
      title: 'profile',
      alt: 'profile image',
      loading: LoadingState.LAZY,
      width: 41,
      height: 41,
    },
    link: {
      anchor: {
        href: '/home/settings',
        target: AnchorTargetAttribute.SELF,
        title: 'Settings',
      },
    },
  },

  logoutButton: {
    button: {
      title: 'Logout',
    },
  },
};

export function getSidebarDataLang(lang = 'de-DE') {
  return SidebarDataLang[lang] || SidebarDataLang.en;
}
