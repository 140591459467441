import format from 'date-fns/format';
import hoursToMilliseconds from 'date-fns/hoursToMilliseconds';
import intervalToDuration from 'date-fns/intervalToDuration';
import {de, enUS} from 'date-fns/locale';
import millisecondsToHours from 'date-fns/millisecondsToHours';
import millisecondsToMinutes from 'date-fns/millisecondsToMinutes';
import * as R from 'ramda';
import {Languages} from '../types/common';
import {getLanguage} from './i18next/helpers';

export const getDateFnsLocale = (language: string) => {
  return {
    [Languages.ENGLISH]: enUS,
    [Languages.GERMAN]: de,
  }[getLanguage(language)];
};

export const getStringTime = (time: string) => {
  const date = new Date(time);
  return format(date, 'HH:mm');
};

export const getHardcodedLocalizedDate = (incomingDate: string) => {
  const date = new Date(incomingDate);
  const offset = date.getTimezoneOffset();
  date.setTime(date.getTime() + offset * MINUTE_IN_MILLISECONDS);

  return date;
};

const MINUTE_IN_MILLISECONDS = 60 * 1000;

export const getHardcodedLocalizedStringTime = (time: string) => {
  const date = getHardcodedLocalizedDate(time);

  return format(date, 'HH:mm');
};

export const getStringRoundedDecimalMinutes = (time: string) => {
  const date = new Date(time);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  return hours * 60 + minutes + 1;
};

export const getStringRoundedMinutes = (time: string) => {
  const date = new Date(time);
  const seconds = date.getSeconds();
  const minutes = date.getMinutes();

  if (seconds > 0) {
    date.setMinutes(minutes + 1);
  }

  return format(date, 'm');
};

export type DurationOptions = {
  duration?: number | null; // milliseconds
  startTime?: string;
  endTime?: string;
  options: {locale: {hours: string; minutes: string}};
};

export const getTimeDuration = ({
  options: {locale},
  endTime,
  startTime,
  duration: durationMS,
}: DurationOptions): string | undefined => {
  if (!durationMS && (!startTime || !endTime)) {
    return;
  }

  const hoursFromDurationMs = durationMS ? millisecondsToHours(durationMS) : 0;

  const duration: Duration | undefined = durationMS
    ? {
        hours: hoursFromDurationMs,
        minutes: millisecondsToMinutes(
          durationMS - hoursFromDurationMs * hoursToMilliseconds(1),
        ),
      }
    : startTime && endTime
    ? intervalToDuration({
        start: new Date(startTime),
        end: new Date(endTime),
      })
    : undefined;

  if (!duration || (!duration.hours && !duration.minutes)) {
    return;
  }

  const hours =
    duration.hours && duration.hours > 0
      ? `${duration.hours} ${locale.hours}`
      : '';

  const minutes =
    duration.minutes && duration.minutes > 0
      ? `${duration.minutes} ${locale.minutes}`
      : '';

  const separator = hours ? ' ' : '';

  return `${hours}${separator}${minutes}`;
};

export const getDateInterval = ({
  currentLanguage,
  startDate,
  endDate,
}: {
  startDate: string;
  endDate?: string | null;
  currentLanguage: string;
}) => {
  const preparedStartDate = new Date(startDate);
  const preparedEndDate = endDate ? new Date(endDate) : undefined;

  const isSameYear = preparedEndDate
    ? preparedStartDate.getFullYear() === preparedEndDate.getFullYear()
    : false;

  const isSameDay = preparedEndDate
    ? preparedStartDate.toString() === preparedEndDate.toString()
    : false;

  const formattedStartDate = format(
    preparedStartDate,
    `dd MMM${isSameYear ? '' : ' yyyy'}`,
    {
      locale: getDateFnsLocale(currentLanguage),
    },
  );

  if (!preparedEndDate) {
    return formattedStartDate;
  }

  const formattedEndDate = format(preparedEndDate, 'dd MMM yyyy', {
    locale: getDateFnsLocale(currentLanguage),
  });

  return isSameDay
    ? formattedEndDate
    : `${formattedStartDate} - ${formattedEndDate}`;
};

const toTwoDigitInteger = (value: number): string => {
  const roundedValue = Math.round(value);
  return roundedValue >= 0 && roundedValue < 10
    ? `0${roundedValue}`
    : String(roundedValue);
};

/**
 * Formats the media duration from milliseconds to a human-readable string format.
 * e.g. format 320000 milliseconds to 5:20 (5 min 20 seconds)
 *
 * @param {number} duration - The duration of the media in milliseconds.
 * @returns {string} A human-readable string representing the formatted duration.
 */
export const formatMediaDuration = (duration: number): string => {
  const durationObject = intervalToDuration({start: 0, end: duration});

  const hours = durationObject.hours ? `${durationObject.hours}:` : '';
  const minutes = R.isNotNil(durationObject.minutes)
    ? `${durationObject.minutes}:`
    : '0:';
  const seconds = R.isNotNil(durationObject.seconds)
    ? `${toTwoDigitInteger(durationObject.seconds)}`
    : '00';

  return `${hours}${minutes}${seconds}`;
};

export const createDateRange = (fromDate: string, toDate: string) => {
  const dateRange: string[] = [];

  const currentDate = new Date(fromDate);
  const endDate = new Date(toDate);

  while (currentDate <= endDate) {
    dateRange.push(currentDate.toISOString().split('T')[0]); // Get ISO string and remove time part
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  return dateRange;
};
