import * as R from 'ramda';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, View, ViewStyle} from 'react-native';
import {Tags} from '../../generated/graphql';
import CloseIcon from '../../icons/CloseIcon';
import colors from '../../theme/colors';
import {
  CustomTag,
  CustomTagGroup,
  SelectedTagsRecord,
} from '../../types/common';
import AppModal from '../AppModal';
import TagGroupItem from '../TagGroupItem';
import RoundTickIcon from './RoundTickIcon';
import styles, {ICON_SIZE} from './styles';

export type UserTagsModalProps = {
  isVisible: boolean;
  onDismiss: () => void;
  tagGroups?: CustomTagGroup[];
  selectedTagsRecord?: SelectedTagsRecord;
  onTagSelect: (tagId: string) => void;
  onSubmit: (() => Promise<void>) | (() => void);
  onSelectAll?: () => void;
  onDeselectAll?: () => void;
  tagSelectionRequired?: boolean;
  title?: string | null;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  buttonStyle?: StyleProp<ViewStyle>;
  buttonContentStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
};

const CONTROL_TAG_ID = 'control_tag';

const CloseIconElement: React.FC = () => (
  <View style={styles.closeIconContainer}>
    <CloseIcon
      width={ICON_SIZE / 2}
      height={ICON_SIZE / 2}
      fill={colors.black100}
    />
  </View>
);

const UserTagsModal: React.FC<UserTagsModalProps> = ({
  testID,
  tagGroups,
  selectedTagsRecord,
  onTagSelect,
  onSubmit,
  tagSelectionRequired = true,
  title,
  onDeselectAll,
  onSelectAll,
  ...props
}) => {
  const {t} = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  const areAllTagsSelected = selectedTagsRecord
    ? Object.values(selectedTagsRecord).every(isSelected => isSelected === true)
    : false;

  const shouldAddControlTag = !!onDeselectAll && !!onSelectAll;

  const handleTagPress = useCallback<(tagId: Tags['id']) => void>(
    tagId => {
      if (tagId === CONTROL_TAG_ID) {
        areAllTagsSelected ? onDeselectAll?.() : onSelectAll?.();
        return;
      }

      onTagSelect(tagId);
    },
    [areAllTagsSelected, onDeselectAll, onSelectAll, onTagSelect],
  );

  const TagGroupsList = useMemo(() => {
    if (!tagGroups || !selectedTagsRecord) {
      return null;
    }

    const controlTag: CustomTag = {
      id: CONTROL_TAG_ID,
      label: areAllTagsSelected
        ? t('common.clear_all')
        : t('common.choose_all'),
      Icon: areAllTagsSelected ? CloseIconElement : RoundTickIcon,
    };

    const controlTagGroup: CustomTagGroup = {
      id: 'control_tag_group',
      tags: [controlTag],
    };

    const groups = shouldAddControlTag
      ? [controlTagGroup, ...tagGroups]
      : tagGroups;

    const selectedTags = shouldAddControlTag
      ? {...selectedTagsRecord, [CONTROL_TAG_ID]: areAllTagsSelected}
      : selectedTagsRecord;

    return groups.map(tagGroup => (
      <TagGroupItem
        {...tagGroup}
        onPress={handleTagPress}
        testID={`${testID}.tagGroupItem`}
        selectedTags={selectedTags}
        key={tagGroup.id}
        style={styles.tagGroup}
        tagMode="outlined"
      />
    ));
  }, [
    areAllTagsSelected,
    handleTagPress,
    selectedTagsRecord,
    shouldAddControlTag,
    t,
    tagGroups,
    testID,
  ]);

  const isSubmitButtonDisabled = tagSelectionRequired
    ? !selectedTagsRecord ||
      Object.values(selectedTagsRecord).every(R.equals(false))
    : false;

  const submit = useCallback(async () => {
    try {
      setSubmitting(true);
      await onSubmit();
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  }, [onSubmit]);

  return (
    <AppModal
      {...props}
      title={title ?? t('common.filter')}
      testID={testID}
      submitButtonTitle={t('common.apply')}
      onSubmit={submit}
      submitButtonDisabled={isSubmitButtonDisabled}
      submitButtonLoading={submitting}>
      {TagGroupsList}
    </AppModal>
  );
};

export default React.memo(UserTagsModal);
