import {faPen, faXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import useElementSize from '../../hooks/useElementSize';
import colors from '../../mindance-libs/theme/colors';
import {getImageUrl} from '../../mindance-libs/utils/files';
import Button from '../Button/Button';
import styles from './ImageUploader.module.scss';
import ImageUploaderProps from './ImageUploader.props';

const ImageUploader: React.FC<ImageUploaderProps> = ({
  className,
  testID,
  fileState,
  onFileSelect: onFileUpload,
  onFileRemove,
  editMode,
}) => {
  const {t} = useTranslation();

  const {
    ref: containerRef,
    size,
    onLoad: onContainerLoad,
  } = useElementSize<HTMLDivElement>();

  const src = fileState.shouldBeEmpty
    ? null
    : fileState.hasLocalFile?.state
    ? URL.createObjectURL(fileState.hasLocalFile.file)
    : fileState.hasRemote?.state && size.height && size.width
    ? getImageUrl(fileState.hasRemote.fileUrl, {...size})
    : null;

  const hasImage = !!src;

  const mergedStyle = classNames(styles['image-uploader'], className);

  const selectFile = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    ({target}) => {
      const selectedFile = target.files?.[0];

      selectedFile && onFileUpload?.(selectedFile);
    },
    [onFileUpload],
  );

  const EditButtons = useMemo(
    () => (
      <div className={styles['image-uploader__buttons']}>
        {hasImage ? (
          <Button
            className={styles['image-uploader__button-container']}
            button={{onClick: onFileRemove}}>
            <FontAwesomeIcon
              icon={faXmark}
              color={colors.black100}
              className={styles['image-uploader__button-container__cross-icon']}
            />
          </Button>
        ) : null}

        <label
          className={styles['image-uploader__button-container']}
          htmlFor="image">
          <FontAwesomeIcon
            icon={faPen}
            color={colors.black100}
            className={styles['image-uploader__button-container__edit-icon']}
          />
          <input
            id="image"
            type="file"
            accept="image/*"
            className={styles['image-uploader__empty__input']}
            onChange={selectFile}
          />
        </label>
      </div>
    ),
    [hasImage, onFileRemove, selectFile],
  );

  return (
    <div
      className={mergedStyle}
      data-testid={testID}
      ref={containerRef}
      onLoad={onContainerLoad}>
      {!hasImage ? (
        <div className={styles['image-uploader__empty']}>
          <span className={styles['image-uploader__empty__placeholder']}>
            {t('mindManagerNewstool.cover_image')}
          </span>

          {editMode ? EditButtons : null}
        </div>
      ) : (
        <div className={styles['image-uploader__filled']}>
          <img src={src} className={styles['image-uploader__filled__image']} />

          {editMode ? EditButtons : null}
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
