import * as R from 'ramda';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  FindManyBookingsV3QueryVariables,
  SortOrder,
} from '../generated/graphql';

export const TAKE_SIZE = 6;
export const INITIAL_OFFSET = 0;

type Params = {
  selectedTags?: string[];
  selectedCategory?: string | null;
  allCategories?: string[];
  initialInput?: Partial<FindManyBookingsV3QueryVariables>;
};

const useBookingQueryVariables = (params?: Params) => {
  const {i18n} = useTranslation();

  const selectedTags = params?.selectedTags;
  const selectedCategory = params?.selectedCategory;
  const allCategories = useMemo(
    () => params?.allCategories,
    [params?.allCategories],
  );
  const initialInput = useMemo(
    () => params?.initialInput ?? {},
    [params?.initialInput],
  );

  const getBookingQueryVariables = useCallback<
    (
      input?: Partial<FindManyBookingsV3QueryVariables>,
    ) => FindManyBookingsV3QueryVariables
  >(
    (input = {}) => {
      const baseVariables = {
        languages_code: i18n.language,
        selectedTags: selectedTags,
        orderBy: [
          {StartDate: {sort: SortOrder.Asc}},
          {StartTime: {sort: SortOrder.Asc}},
          {sort: {sort: SortOrder.Asc}},
        ],
        skip: INITIAL_OFFSET,
        take: TAKE_SIZE,
      } as FindManyBookingsV3QueryVariables;

      const initialVariables: FindManyBookingsV3QueryVariables =
        R.mergeDeepRight(
          baseVariables,
          initialInput,
        ) as FindManyBookingsV3QueryVariables;

      const mergedVariables: FindManyBookingsV3QueryVariables =
        R.mergeDeepRight(
          initialVariables,
          input,
        ) as FindManyBookingsV3QueryVariables;

      const variablesWithFilter = R.mergeDeepRight(mergedVariables, {
        bookingType: selectedCategory ? [selectedCategory] : allCategories,
      });

      return variablesWithFilter;
    },
    [
      allCategories,
      i18n.language,
      initialInput,
      selectedCategory,
      selectedTags,
    ],
  );

  return getBookingQueryVariables;
};

export default useBookingQueryVariables;
