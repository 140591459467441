import React, {memo, useMemo} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {FilterButtonType} from '../../types/common';
import FilterButton from '../FilterButton';
import styles from './styles';

type Props = {
  data: FilterButtonType[];
  onItemPress: (key: any) => void;
  style?: StyleProp<ViewStyle>;
};

const FilterModesBar: React.FC<Props> = ({data, onItemPress, style}) => {
  const containerStyle = useMemo(() => [styles.container, style], [style]);

  const Buttons = useMemo(
    () =>
      data.map(item => (
        <FilterButton
          {...item}
          onPress={onItemPress}
          style={styles.item}
          key={item.id}
        />
      )),
    [data, onItemPress],
  );

  return <View style={containerStyle}>{Buttons}</View>;
};

export default memo(FilterModesBar);
