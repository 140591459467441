import React from 'react';
import {ActivityIndicator} from 'react-native-paper';
import ScreenBase from '../../mindance-libs/components/ScreenBase';
import colors from '../../mindance-libs/theme/colors';
import styles from './LoadingOverlay.module.scss';

const LoadingOverlay: React.FC<any> = ({}) => {
  return (
    <ScreenBase>
      <div className={styles.overlay}>
        <ActivityIndicator color={colors.whiteAlpha70} size={80} />
      </div>
    </ScreenBase>
  );
};

export default React.memo(LoadingOverlay);
