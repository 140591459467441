import {
  GbuPsycheHomeCardData,
  GbuPsycheHomeCardType,
} from './GbuPsycheHomeCard.props';

export const GbuPsycheHomeCardDataLang: Record<
  string,
  GbuPsycheHomeCardData[]
> = {};

GbuPsycheHomeCardDataLang['de-DE'] = [
  {
    label: GbuPsycheHomeCardType.LEADERSHIP,
    title: 'Führungskraft',
  },
  {
    label: GbuPsycheHomeCardType.WORKING_ENVIRONMENT,
    title: 'Arbeitsumgebung',
  },
];

GbuPsycheHomeCardDataLang['en-US'] = [
  {
    label: GbuPsycheHomeCardType.LEADERSHIP,
    title: 'Leadership',
  },
  {
    label: GbuPsycheHomeCardType.WORKING_ENVIRONMENT,
    title: 'Environment',
  },
];

export function getGbuPsycheHomeCardDataLang(lang = 'de-DE') {
  return GbuPsycheHomeCardDataLang[lang] || GbuPsycheHomeCardDataLang['de-DE'];
}
