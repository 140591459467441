export const replacePlaceholders = (
  inputString: string | null,
  replacements: Record<string, string | null | undefined>,
) => {
  if (!inputString) {
    return;
  }

  return inputString.replace(
    /{(.*?)}/g,
    (match, p1) => replacements[p1.trim()] || match,
  );
};
