import format from 'date-fns/format';
import {TFunction} from 'i18next';
import {Maybe} from '../generated/graphql';
import {Languages} from '../types/common';
import {getLanguage} from './i18next/helpers';
import {getDateFnsLocale, getHardcodedLocalizedDate} from './time';

export const getEventDate = ({
  t,
  startDate,
  startTime,
  currentLanguage,
  localizedTime = false,
}: {
  startDate?: Maybe<string>;
  startTime?: Maybe<string>;
  t: TFunction;
  currentLanguage: string;
  localizedTime?: boolean;
}) => {
  if (!startDate) {
    return null;
  }

  const locale = getDateFnsLocale(currentLanguage);

  const formattedStartDate = format(new Date(startDate), 'dd. MMMM', {
    locale,
  });

  if (!startTime) {
    return formattedStartDate;
  }

  const isEnglish = getLanguage(currentLanguage) === Languages.ENGLISH;

  const startTimeDate = localizedTime
    ? getHardcodedLocalizedDate(startTime)
    : new Date(startTime);

  const formattedTime = isEnglish
    ? format(startTimeDate, 'h aaa', {locale})
    : format(startTimeDate, 'H', {locale});

  const timePart = isEnglish
    ? formattedTime
    : t('booking.event_time', {time: formattedTime});

  return `${formattedStartDate}, ${timePart}`;
};
