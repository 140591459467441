import {StyleProp, TextStyle, ViewProps} from 'react-native';
import {
  AudioUnits,
  Maybe,
  NoteUnits,
  TextUnits,
  VideoUnits,
} from '../generated/graphql';

export enum CourseDetailsModeType {
  COURSE = 'course',
  DETAILS = 'details',
  COACH = 'coach',
}

export enum TextUnitAlignment {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}

type TextAlign = 'left' | 'center' | 'right';

export type GeneralUnits = {
  __typename: 'GeneralUnits';
  id?: string;
  Label?: Maybe<string>;
  Description?: Maybe<string>;
  Alignment?: TextUnitAlignment;
  labelTextAlign?: TextAlign;
  descriptionTextAlign?: TextAlign;
  labelStyle?: StyleProp<TextStyle>;
  descriptionStyle?: StyleProp<TextStyle>;
};

export type SessionUnits =
  | TextUnits
  | AudioUnits
  | VideoUnits
  | NoteUnits
  | GeneralUnits;

export type UnitSlideProps = ViewProps & {
  key?: string;
  focused?: boolean;
  onError?: (error: string) => void;
  index: number;
  currentProgress?: number;
};

export type UnitRuleToInsert = {
  rule: (units: SessionUnits[]) => number;
  unit: SessionUnits;
};
