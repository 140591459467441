import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAuthContext} from '../store/contexts/AuthContext';
import {Credentials, PersonalInfo} from '../types/registration';
import {reportError} from '../utils/loggingHelpers';
import {removeUndefined} from '../utils/utils';

const useRegistration = ({
  initialState,
  onSaveInfo,
  onSubmit,
  onError,
}: {
  initialState?: Credentials & PersonalInfo;
  onSaveInfo: () => void;
  onSubmit: (state: Credentials & PersonalInfo) => void;
  onError: (message: string) => void;
}) => {
  const {t} = useTranslation();
  const {register} = useAuthContext({onError});
  const [personalInfo, setPersonalInfo] = useState<PersonalInfo | undefined>(
    initialState
      ? {
          age: initialState.age,
          firstName: initialState.firstName,
          gender: initialState.gender,
          lastName: initialState.lastName,
        }
      : undefined,
  );

  const submit = useCallback(
    async (credentials: Credentials) => {
      try {
        if (!credentials || !personalInfo) {
          throw new Error('No credentials or personal info');
        }
        const dataSubmit = {...credentials, ...personalInfo};
        const data = removeUndefined(dataSubmit);
        const result = await register(data);
        if (result.success) {
          onSubmit(data);
        }
      } catch (err) {
        reportError('registration submit error', err);
        onError(t('errors.something_went_wrong'));
      }
    },
    [personalInfo, register, onSubmit, onError, t],
  );

  const fillPersonalInfo = useCallback(
    (values: PersonalInfo) => {
      setPersonalInfo(values);
      onSaveInfo();
    },
    [onSaveInfo],
  );
  return useMemo(
    () => ({fillPersonalInfo, submit, personalInfo}),
    [fillPersonalInfo, submit, personalInfo],
  );
};

export default useRegistration;
