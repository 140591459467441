import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  description: {
    ...typography.body,
    color: colors.whiteAlpha75,
  },
  spacer: {
    height: spacings.xxl,
  },
});

export const htmlCustomStyle = {
  body: {
    color: colors.whiteAlpha75,
  },
};

export default styles;
