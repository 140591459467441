import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import typography from '../../theme/typography';
import spacings, {DEFAULT_BORDER_RADIUS} from '../../theme/spacings';

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.transparent,
    paddingVertical: spacings.xxs,
    paddingHorizontal: spacings.xs,
    borderRadius: 50,
    ...typography.input,
  },
  outline: {
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
  inputIcon: {
    marginTop: Platform.select({
      android: 0,
      ios: 0,
      web: spacings.l,
    }),
  },
});

export default styles;
