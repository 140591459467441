import {BlurView} from '@react-native-community/blur';
import {useLayout} from '@react-native-community/hooks';
import Color from 'color';
import {LinearGradient} from 'expo-linear-gradient';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, ScrollView, StyleProp, View, ViewStyle} from 'react-native';
import Animated, {Layout, SlideInDown} from 'react-native-reanimated';
import colors from '../../../theme/colors';
import {CustomTagGroup, SelectedTagsRecord} from '../../../types/common';
import {OnboardingSlideProps} from '../../../types/onboarding';
import {
  getEntryOrExitLayoutAnimation,
  getReanimatedLayoutAnimation,
} from '../../../utils/animation';
import {getIsButtonDisabled} from '../../../utils/onboarding';
import AppButton from '../../AppButton';
import LayoutAnimationWrapper from '../../LayoutAnimationWrapper';
import LoadingContent from '../../LoadingContent';
import PsychologistMessage from '../../PsychologistMessage';
import TagGroupItem from '../../TagGroupItem';
import styles from './styles';

type Props = OnboardingSlideProps & {
  loading: boolean;
  tagGroups: CustomTagGroup[];
  selectedTagsRecord: SelectedTagsRecord;
  onSelectTag: (tagId: string) => void;
  buttonStyle?: StyleProp<ViewStyle>;
};

export const QUESTION_TEST_ID = 'onboardingSurveyQuestion';
export const ANSWER_TEXT_ID = 'onboardingSurveyAnswer';

export const DISPLAY_TIMEOUT = 1200;

const ANIMATION_DURATION = 500;

const GRADIENT_COLORS = [
  Color(colors.greenGray).alpha(0).string(),
  colors.black100,
];

const OnboardingSurveySlide: React.FC<Props> = ({
  loading,
  onSelectTag,
  selectedTagsRecord,
  tagGroups,
  onButtonPress,
  style,
  focused,
  buttonStyle,
  contentButtonStyle,
}) => {
  const {t} = useTranslation();

  const [isMessageDisplayed, setIsMessageDisplayed] = useState(false);
  const [areAnswersDisplayed, setAreAnswersDisplayed] = useState(false);

  const {onLayout: onButtonWrapperLayout, height: buttonWrapperHeight} =
    useLayout();

  const isMinWidthDetermined = useRef(false);
  const {onLayout: onButtonLayout, width: buttonWidth} = useLayout();
  const [minButtonWidth, setMinButtonWidth] = useState(buttonWidth);

  const containerStyle = useMemo(() => [styles.container, style], [style]);

  const mergedButtonStyle = useMemo(
    () => [styles.button, buttonStyle],
    [buttonStyle],
  );

  const isButtonDisabled = getIsButtonDisabled(selectedTagsRecord);

  const contentStyle = useMemo(
    () => [{paddingBottom: buttonWrapperHeight}],
    [buttonWrapperHeight],
  );

  const mergedContentButtonStyle = useMemo(
    () => [{minWidth: minButtonWidth}, contentButtonStyle],
    [contentButtonStyle, minButtonWidth],
  );

  const TagGroupsList = useMemo(
    () =>
      tagGroups.map(tagGroup => (
        <TagGroupItem
          {...tagGroup}
          onPress={onSelectTag}
          testID="onboardingTagGroup"
          selectedTags={selectedTagsRecord}
          key={tagGroup.id}
          style={styles.category}
        />
      )),
    [tagGroups, onSelectTag, selectedTagsRecord],
  );

  useEffect(() => {
    let messageTimer: NodeJS.Timeout | undefined;
    let answersTimer: NodeJS.Timeout | undefined;

    if (focused) {
      messageTimer = setTimeout(() => {
        setIsMessageDisplayed(true);
      }, 0);

      answersTimer = setTimeout(() => {
        setAreAnswersDisplayed(true);
      }, DISPLAY_TIMEOUT);
    }

    return () => {
      clearTimeout(messageTimer);
      clearTimeout(answersTimer);
      messageTimer;
      answersTimer = undefined;
    };
  }, [focused]);

  useEffect(() => {
    if (
      buttonWidth > 0 &&
      (!isMinWidthDetermined.current || buttonWidth > minButtonWidth)
    ) {
      setMinButtonWidth(buttonWidth);
      isMinWidthDetermined.current = true;
    }
  }, [buttonWidth, minButtonWidth]);

  if (loading) {
    return <LoadingContent style={style} />;
  }

  return (
    <>
      <View style={containerStyle}>
        <ScrollView
          style={styles.content}
          contentContainerStyle={contentStyle}
          showsVerticalScrollIndicator={false}>
          {isMessageDisplayed && (
            <PsychologistMessage
              testID={QUESTION_TEST_ID}
              message={t('onboarding.select_categories')}
            />
          )}
          {areAnswersDisplayed && (
            <LayoutAnimationWrapper
              duration={ANIMATION_DURATION}
              initial="hidden"
              exit="hidden"
              animate="visible">
              <Animated.View
                testID={ANSWER_TEXT_ID}
                style={styles.categoriesWrapper}
                layout={getReanimatedLayoutAnimation(
                  Layout.duration(ANIMATION_DURATION),
                )}
                entering={getEntryOrExitLayoutAnimation(
                  SlideInDown.duration(ANIMATION_DURATION),
                )}>
                {TagGroupsList}
              </Animated.View>
            </LayoutAnimationWrapper>
          )}
        </ScrollView>
      </View>
      <View
        style={styles.buttonWrapper}
        onLayout={onButtonWrapperLayout}
        pointerEvents="box-none">
        {Platform.OS === 'web' ? (
          <View style={styles.blurBackground} pointerEvents="none">
            <BlurView
              key="topBlur"
              style={styles.topBlur}
              pointerEvents="none"
              blurAmount={0.5}
            />
            <BlurView
              key="bottomBlur"
              style={styles.space}
              pointerEvents="none"
              blurAmount={2}
            />
          </View>
        ) : (
          <LinearGradient
            style={styles.blurBackground}
            colors={GRADIENT_COLORS}
            pointerEvents="none"
          />
        )}

        <AppButton
          onPress={onButtonPress}
          showNextIcon={!isButtonDisabled}
          testID="onboardingSurvey.nextButton"
          contentStyle={mergedContentButtonStyle}
          style={mergedButtonStyle}
          disabled={isButtonDisabled}
          onLayout={onButtonLayout}>
          {isButtonDisabled
            ? t('onboarding.choose_interests')
            : t('common.next')}
        </AppButton>
      </View>
    </>
  );
};

export default React.memo(OnboardingSurveySlide);
