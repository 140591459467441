import {useCallback, useMemo, useState} from 'react';

export type LoadingCallback<T extends (...args: any[]) => Promise<any>> = (
  ...args: Parameters<T>
) => ReturnType<T>;
export type ResetFunc = () => void;
export type AnyError = any;

const useLoadingCallback = <T extends (...args: any[]) => Promise<any>>(
  callback: T,
  initialState?: {loading?: boolean; error?: any},
): {
  handleCallback: LoadingCallback<T>;
  isLoading: boolean;
  error: AnyError | undefined;
  reset: ResetFunc;
} => {
  const [isLoading, setIsLoading] = useState(initialState?.loading ?? false);
  const [error, setError] = useState<any | undefined>();

  const handleCallback = useCallback(
    async (...args: Parameters<T>) => {
      setError(undefined);
      setIsLoading(true);

      try {
        const value = await callback(...args);
        setIsLoading(false);
        return value;
      } catch (e) {
        setError(e);
        setIsLoading(false);
        throw e;
      }
    },
    [callback],
  );

  const reset = useCallback(() => {
    setIsLoading(false);
    setError(undefined);
  }, []);

  return useMemo(
    () => ({
      handleCallback: handleCallback as LoadingCallback<T>,
      isLoading,
      error,
      reset,
    }),
    [error, handleCallback, isLoading, reset],
  );
};

export default useLoadingCallback;
