import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

export const IMAGE_SIZE = 64;

const styles = StyleSheet.create({
  pointWrapper: {
    marginTop: spacings.xl,
  },
  point: {
    ...typography.body,
    color: colors.whiteAlpha75,
  },
  coachWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    height: IMAGE_SIZE,
    width: IMAGE_SIZE,
    borderRadius: IMAGE_SIZE / 2,
  },
  coachNameWrapper: {
    marginLeft: spacings.l,
    flexShrink: 1,
  },
  coachName: {
    ...typography.subtitleDemiBold,
    color: colors.whiteAlpha95,
  },
  coachQualification: {
    ...typography.body,
    marginTop: spacings.xxxxs,
    color: colors.whiteAlpha95,
  },
});

export default styles;
