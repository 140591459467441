import classNames from 'classnames';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import MeinFSConnection from '../../../components/MeinFSConnection/MeinFSConnection';
import EmptyState from '../../../mindance-libs/components/EmptyState';
import ErrorContent from '../../../mindance-libs/components/ErrorContent';
import LibraryCardBase from '../../../mindance-libs/components/LibraryCardBase';
import LoadingContent from '../../../mindance-libs/components/LoadingContent';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import {Maybe} from '../../../mindance-libs/generated/graphql';
import useLibraryTopics from '../../../mindance-libs/hooks/useLibraryTopics';
import {
  CardType,
  FormattedLibraryItem,
  LibraryItemType,
} from '../../../mindance-libs/types/library';
import {getLibraryItemType} from '../../../mindance-libs/utils/courses';
import {RouteKeys} from '../../../navigation/RouteKeys';
import useTypedNavigate from '../../../navigation/typed/useTypedNavigate';
import styles from './page.module.scss';

const FORMAT_ASPECT_RATIO = 2;
const TOPIC_ASPECT_RATIO = 1.4;

export default function Page() {
  const {t} = useTranslation();
  const {error, formatItems, loading, topicItems, meinFSLink} =
    useLibraryTopics();

  const navigate = useTypedNavigate();

  const openLibraryItem = useCallback<
    (cardType: CardType, label?: Maybe<string>, tags?: string[]) => void
  >(
    (cardType, label, tags) => {
      const itemsType = {
        [CardType.Topic]: LibraryItemType.MIXED,
        [CardType.Format]: getLibraryItemType(label),
      }[cardType];

      const tagsParam = {
        [CardType.Topic]: tags,
        [CardType.Format]: undefined,
      }[cardType];

      navigate({
        to: RouteKeys.LibraryItems,
        params: {},
        queryParams: {itemsType, tags: tagsParam},
      });
    },
    [navigate],
  );

  const renderItems = useCallback(
    (items: FormattedLibraryItem[]) =>
      items.map(item => {
        const cardType = item?.type ?? CardType.Format;
        const aspectRatio = {
          [CardType.Format]: FORMAT_ASPECT_RATIO,
          [CardType.Topic]: TOPIC_ASPECT_RATIO,
        }[cardType];

        return (
          <LibraryCardBase
            {...item}
            aspectRatio={aspectRatio}
            key={item.id}
            onPress={openLibraryItem}
          />
        );
      }),
    [openLibraryItem],
  );

  const FormatItemsCards = useMemo(
    () => renderItems(formatItems),
    [formatItems, renderItems],
  );

  const TopicItemsCards = useMemo(
    () => renderItems(topicItems),
    [topicItems, renderItems],
  );

  const handleMeinFSLink = useCallback(() => {
    if (meinFSLink) {
      window.open(meinFSLink, '_blank');
    }
  }, [meinFSLink]);

  if (loading) {
    return (
      <ScreenBase>
        <LoadingContent />
      </ScreenBase>
    );
  }

  if (error) {
    return (
      <ScreenBase>
        <ErrorContent />
      </ScreenBase>
    );
  }

  return (
    <>
      <div className={styles.library__container}>
        <div className={styles.library__header}>
          <h1 className={styles.library__title}>
            {t('screenTitle.LibraryScreen')}
          </h1>
          <h2 className={styles['library__sub-title']}>
            {t('library.what_are_you_searching')}
          </h2>
        </div>
        <div className={styles.library__content}>
          {formatItems.length === 0 && topicItems.length === 0 ? (
            <div className={styles['library__empty-state']}>
              <EmptyState />
            </div>
          ) : (
            <>
              <div
                className={classNames(
                  styles['library__cards-container'],
                  styles['library__format-cards-container'],
                )}>
                {FormatItemsCards}
              </div>
              <div
                className={classNames(
                  styles['library__cards-container'],
                  styles['library__topic-cards-container'],
                )}>
                {TopicItemsCards}
              </div>
            </>
          )}
          {meinFSLink ? (
            <div className={styles['library__mein-fs']}>
              <MeinFSConnection onPress={handleMeinFSLink} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
