import React from 'react';
import styles from './Background.module.scss';
import BackgroundProps from './Background.props';
import useCompanyConfig from '../../mindance-libs/hooks/useCompanyConfig';
import GradientBackground from '../../mindance-libs/components/GradientBackground';
import classNames from 'classnames';

const Background: React.FC<BackgroundProps> = ({children, className = ''}) => {
  const {primaryColor, secondaryColor, tertiaryColor, backdropColor} =
    useCompanyConfig();

  const containerStyle = classNames(styles.background__container, className);

  return (
    <div className={containerStyle}>
      <div className={styles.background__gradient}>
        <GradientBackground
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          tertiaryColor={tertiaryColor}
          backdropColor={backdropColor}
          testID="gradientBackground"
        />
      </div>
      <div className={styles.background__content}>{children}</div>
    </div>
  );
};

export default Background;
