import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';

const BORDER_RADIUS = 100;
const HEIGHT = 4;

const barStyle = {
  flex: 1,
  height: HEIGHT,
  borderRadius: BORDER_RADIUS,
  backgroundColor: colors.whiteAlpha20,
  marginHorizontal: spacings.xxxxs,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  bar: {
    ...barStyle,
  },
  filledBar: {
    ...barStyle,
    backgroundColor: colors.white,
  },
});

export default styles;
