import * as R from 'ramda';
import {Maybe, TagsGeneralSettingFragment} from '../generated/graphql';
import {CustomTagGroup, SelectedTagsRecord} from '../types/common';

export const getFormattedTagsGroups = (
  tagsGeneralSettingFragment?: Maybe<TagsGeneralSettingFragment>,
): CustomTagGroup[] => {
  const generalSettingsTagGroups =
    tagsGeneralSettingFragment?.GeneralSetting_translations?.map(
      ({GeneralSetting_translations_TagGroups}) =>
        GeneralSetting_translations_TagGroups,
    ) ?? [];

  const filteredGeneralSettingsTagGroups = R.flatten(
    R.reject(R.isNil, generalSettingsTagGroups),
  );

  const sortedTagGroups = R.sort(
    R.ascend(({sort}) => sort ?? 1000),
    filteredGeneralSettingsTagGroups,
  );

  const tagGroups = sortedTagGroups.reduce<CustomTagGroup[]>(
    (acc, {TagGroups}) => {
      if (!TagGroups) {
        return acc;
      }

      const {id, TagGroups_Tags, TagGroups_translations} = TagGroups;

      const label = TagGroups_translations?.[0]?.Label ?? '';

      const sortedTags = R.sort(
        R.ascend(({sort}) => sort ?? 1000),
        TagGroups_Tags ?? [],
      );
      const allTags = sortedTags.map(({Tags}) => Tags);
      const filteredTags = R.reject(R.isNil, allTags);

      const tags = filteredTags.map<
        NonNullable<CustomTagGroup['tags']>[number]
      >(e => ({
        id: e.id,
        label: e.Tags_translations?.[0]?.Label ?? '',
      }));

      return [...acc, {id, label, tags}];
    },
    [],
  );

  const filteredTagGroups = R.filter(
    ({label, tags}) =>
      !!label && !!tags && Array.isArray(tags) && tags.length > 0,
    tagGroups,
  );

  return filteredTagGroups;
};

export const getTagsFromTagGroups = (groups: CustomTagGroup[]) => {
  const tags = groups.flatMap(group => group.tags);
  const filteredTags = R.filter(R.isNotNil, tags);

  return filteredTags;
};

export const getTagsIdsFromTagGroups = (groups: CustomTagGroup[]) => {
  const tags = getTagsFromTagGroups(groups);
  const allTags = R.pluck('id', tags);

  return R.uniq(allTags);
};

export const getTagsRecord = (
  tagsFilters: CustomTagGroup[] | undefined,
  selectedTags: string[],
): SelectedTagsRecord => {
  if (!tagsFilters) {
    return {};
  }

  const tags = R.pluck('tags', tagsFilters);
  const filteredTags = R.reject(R.isNil, tags);
  const tagsIds = R.pluck('id', R.flatten(filteredTags));
  const filteredUserTags = R.filter(tag => tagsIds.includes(tag), selectedTags);
  const unselectedTags = R.without(filteredUserTags, tagsIds);

  const selectedTagsRecord = filteredUserTags.reduce<SelectedTagsRecord>(
    (acc, id) => ({...acc, [id]: true}),
    {},
  );
  const unselectedTagsRecord = unselectedTags.reduce<SelectedTagsRecord>(
    (acc, id) => ({...acc, [id]: false}),
    {},
  );

  return {...selectedTagsRecord, ...unselectedTagsRecord};
};
