import {useLayout} from '@react-native-community/hooks';
import Color from 'color';
import React, {useCallback, useMemo} from 'react';
import {StyleProp, TouchableOpacity, View, ViewStyle} from 'react-native';
import FastImage, {Source} from 'react-native-fast-image';
import {Maybe} from '../../generated/graphql';
import colors from '../../theme/colors';
import {CardType, FormattedLibraryItem} from '../../types/library';
import {getImageUrl} from '../../utils/files';
import AppText from '../AppText';
import styles from './styles';

type Props = FormattedLibraryItem & {
  style?: StyleProp<ViewStyle>;
  onPress?: (
    cardType: CardType,
    label?: Maybe<string>,
    tags?: string[],
  ) => void;
  testID?: string;
  aspectRatio: number;
};

const LibraryCardBase: React.FC<Props> = ({
  onPress,
  imageFile,
  style,
  testID,
  color,
  aspectRatio,
  label: title,
  type: cardType,
  tags,
}) => {
  const {width, onLayout} = useLayout();

  const height = width / aspectRatio;

  const backgroundColor = color ?? colors.black90;

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [
      styles.container,
      {height},
      cardType === CardType.Format
        ? {borderColor: colors.blueGreenGray, borderWidth: 1}
        : {},
      style,
    ],
    [cardType, height, style],
  );

  const backgroundStyle = useMemo<StyleProp<ViewStyle>>(() => {
    const opacity = {
      [CardType.Format]: 1,
      [CardType.Topic]: 0.5,
    }[cardType];

    return [styles.background, {backgroundColor, opacity}];
  }, [cardType, backgroundColor]);

  const imageWidth = height / 1.6;

  const imageContainerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.imageContainer, {height, width: imageWidth, right: 0}],
    [height, imageWidth],
  );

  const imageShadowStyle = useMemo<StyleProp<ViewStyle>>(() => {
    const opacity = {
      [CardType.Format]: 0.8,
      [CardType.Topic]: 0.5,
    }[cardType];

    return [
      styles.imageShadow,
      {backgroundColor: Color(backgroundColor).alpha(opacity).string()},
    ];
  }, [cardType, backgroundColor]);

  const image = useMemo<Source | undefined>(() => {
    const uri = getImageUrl(imageFile, {width: imageWidth, height});
    return uri ? {uri} : undefined;
  }, [height, imageFile, imageWidth]);

  const handlePress = useCallback(() => {
    onPress?.(cardType, title, tags);
  }, [cardType, onPress, tags, title]);

  return (
    <TouchableOpacity
      style={containerStyle}
      onPress={handlePress}
      testID={testID}
      onLayout={onLayout}
      activeOpacity={0.8}>
      <View style={backgroundStyle} />

      <View style={imageContainerStyle}>
        <FastImage
          style={styles.image}
          source={image}
          testID={`${testID}.image`}
        />
        <View style={imageShadowStyle} />
      </View>

      {title ? (
        <AppText style={styles.title} testID={`${testID}.title`}>
          {title}
        </AppText>
      ) : null}
    </TouchableOpacity>
  );
};

export default React.memo(LibraryCardBase);
