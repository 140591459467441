import {useFormik} from 'formik';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ActivityIndicator} from 'react-native-paper';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import useErrorMessage from '../../../mindance-libs/hooks/useErrorMessage';
import useInputFormikHandlers from '../../../mindance-libs/hooks/useInputFormikHandlers';
import useMessage from '../../../mindance-libs/hooks/useMessage';
import MindanceBigLogo from '../../../mindance-libs/icons/MindanceBigLogo';
import {useAuthContext} from '../../../mindance-libs/store/contexts/AuthContext';
import {SystemCodes, TokenStatus} from '../../../mindance-libs/types/auth';
import {changePasswordFormValidationSchema} from '../../../mindance-libs/utils/formValidations';
import {LastPathSegment} from '../../../navigation/NavigationTypes';
import {RouteKeys} from '../../../navigation/RouteKeys';
import useTypedNavigate from '../../../navigation/typed/useTypedNavigate';
import useTypedParams from '../../../navigation/typed/useTypedParams';
import ConfirmationContent from './ConfirmationContent';
import LinkExpiredContent from './LinkExpiredContent';
import SuccessContent from './SuccessContent';
import TechnicalIssuesContent from './TechnicalIssuesContent';
import styles from './page.module.scss';

type FormTypes = {
  password: string;
  confirmPassword: string;
};

const INITIAL_VALUES: FormTypes = {
  password: '',
  confirmPassword: '',
};

const SUCCESS_ENDPOINT: LastPathSegment<RouteKeys.PasswordChangeSuccess> =
  'success';

export default function Page() {
  const {showMessage: showErrorMessage, error: errorMessage} =
    useErrorMessage();
  const {showMessage, message} = useMessage();

  const {token} = useTypedParams<RouteKeys.PasswordChange>();

  const navigate = useTypedNavigate();

  const [accessState, setAccessState] = useState<TokenStatus | undefined>();
  const [loading, setLoading] = useState(token !== SUCCESS_ENDPOINT);

  const {validateResetPassword} = useAuthContext({onError: showMessage});

  useEffect(() => {
    if (!token || token === SUCCESS_ENDPOINT) {
      return;
    }

    const fetchAccessState = async () => {
      setLoading(true);
      try {
        const response = await validateResetPassword(token);
        const tokenState = response.state;
        if (tokenState) {
          setAccessState(tokenState ?? 'FAIL');
        }
      } catch (e) {
        showErrorMessage('');
      } finally {
        setLoading(false);
      }
    };
    fetchAccessState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const {resetPassword} = useAuthContext({onError: showErrorMessage});

  const submit = useCallback(
    async (values: FormTypes) => {
      const newPassword = values.password;

      if (!newPassword || !token) {
        return;
      }

      try {
        const response = await resetPassword(token, newPassword);

        if (response.systemCode === SystemCodes.SUCCESS) {
          navigate({to: RouteKeys.PasswordChangeSuccess, params: {}});
          return;
        }

        showErrorMessage('');
      } catch (e) {
        showErrorMessage('');
      }
    },
    [navigate, resetPassword, showErrorMessage, token],
  );

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    validationSchema: changePasswordFormValidationSchema,
    validateOnMount: true,
    initialValues: INITIAL_VALUES,
    onSubmit: submit,
  });

  const {
    handleValueBlur: handlePasswordBlur,
    hasError: hasPasswordError,
    setValue: setPassword,
  } = useInputFormikHandlers<FormTypes>('password', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError: showErrorMessage,
  });

  const {
    handleValueBlur: handleConfirmPasswordBlur,
    hasError: hasConfirmPasswordError,
    setValue: setConfirmPassword,
  } = useInputFormikHandlers<FormTypes>('confirmPassword', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError: showErrorMessage,
  });

  const Content = useMemo(() => {
    if (token === SUCCESS_ENDPOINT) {
      return <ConfirmationContent />;
    }

    switch (accessState) {
      case 'EXPIRED':
        return <LinkExpiredContent />;

      case 'SUCCESS':
        return (
          <SuccessContent
            confirmPassword={values.confirmPassword}
            handleConfirmPasswordBlur={handleConfirmPasswordBlur}
            handlePasswordBlur={handlePasswordBlur}
            hasConfirmPasswordError={hasConfirmPasswordError}
            hasPasswordError={hasPasswordError}
            isSubmitting={isSubmitting}
            isValid={isValid}
            onSubmit={handleSubmit}
            password={values.password}
            setConfirmPassword={setConfirmPassword}
            setPassword={setPassword}
          />
        );

      case 'FAIL':
        return <TechnicalIssuesContent />;

      default:
        return <TechnicalIssuesContent />;
    }
  }, [
    accessState,
    handleConfirmPasswordBlur,
    handlePasswordBlur,
    handleSubmit,
    hasConfirmPasswordError,
    hasPasswordError,
    isSubmitting,
    isValid,
    setConfirmPassword,
    setPassword,
    token,
    values.confirmPassword,
    values.password,
  ]);

  if (!token) {
    navigate({to: RouteKeys.Login, params: {}}, {replace: true});
  }

  if (loading) {
    return (
      <ScreenBase error={errorMessage}>
        <div className={styles.loading}>
          <ActivityIndicator />
        </div>
      </ScreenBase>
    );
  }

  return (
    <ScreenBase error={errorMessage} message={message}>
      <div className={styles.password_change__container}>
        <div className={styles.password_change__content}>
          <div className={styles.password_change__logo}>
            <MindanceBigLogo />
          </div>
          {Content}
        </div>
      </div>
    </ScreenBase>
  );
}
