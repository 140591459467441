import {fromPromise} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {GraphQLError} from 'graphql';
import * as R from 'ramda';
import {useCallback, useMemo, useRef, useState} from 'react';
import {useAuthContext} from '../store/contexts/AuthContext';
import {devError} from '../utils/loggingHelpers';

const tokenError = (graphQLErrors: readonly GraphQLError[]) =>
  R.find(
    R.pathSatisfies(R.equals('UNAUTHENTICATED'), ['extensions', 'code']),
    graphQLErrors,
  );

const useApolloErrorLink = () => {
  const isRefreshing = useRef(false);
  const [pendingRequests, setPendingResults] = useState<Function[]>([]);

  const {obtainNewTokens, isLoggedIn, isLoading, updateTokens} =
    useAuthContext();

  const resolvePendingRequests = useCallback(() => {
    pendingRequests.map(callback => {
      callback();
    });

    setPendingResults([]);
  }, [pendingRequests]);

  const errorLink = useMemo(
    () =>
      onError(({graphQLErrors, operation, forward}) => {
        if (!isLoggedIn || !graphQLErrors || isLoading) {
          return;
        }

        if (!tokenError(graphQLErrors)) {
          return;
        }

        let forward$;

        if (!isRefreshing.current) {
          isRefreshing.current = true;

          forward$ = fromPromise(
            obtainNewTokens()
              .then(async response => {
                if (response) {
                  await updateTokens(
                    response.accessToken,
                    response.refreshToken,
                  );
                  resolvePendingRequests();
                }

                isRefreshing.current = false;
                return response;
              })
              .catch(error => {
                setPendingResults([]);
                isRefreshing.current = false;
                devError('Update tokens error', error);
              }),
          ).filter(value => !!value);
        } else {
          forward$ = fromPromise(
            new Promise(resolve => {
              pendingRequests.push(resolve);
            }),
          );
        }

        return forward$.flatMap((payload: any) => {
          if (payload && payload.accessToken) {
            operation.setContext({
              headers: {
                ...operation.getContext().headers,
                Authorization: `Bearer ${payload.accessToken}`,
              },
            });
          }

          return forward(operation);
        });
      }),
    [
      isLoading,
      isLoggedIn,
      obtainNewTokens,
      pendingRequests,
      resolvePendingRequests,
      updateTokens,
    ],
  );

  return errorLink;
};

export default useApolloErrorLink;
