import {useCallback, useMemo} from 'react';
import {useAppContext} from '../store/contexts/AppContext';
import {getImageUrl} from '../utils/files';

const useCompanyConfig = () => {
  const {corporateBrandingCompany} = useAppContext();

  const primaryColor = corporateBrandingCompany?.PrimaryColor ?? undefined;
  const secondaryColor = corporateBrandingCompany?.SecondaryColor ?? undefined;
  const tertiaryColor = corporateBrandingCompany?.TertiaryColor ?? undefined;
  const backdropColor = corporateBrandingCompany?.BackdropColor ?? undefined;
  const highlightColor = corporateBrandingCompany?.HighlightColor ?? undefined;

  const getLogoUrl = useCallback(
    ({
      width,
      aspectRatio,
      height,
    }: {
      width?: number;
      aspectRatio?: number;
      height?: number;
    }) =>
      getImageUrl(corporateBrandingCompany?.directus_files, {
        width,
        height,
        aspectRatio,
        resizeMode: 'contain',
      }),
    [corporateBrandingCompany?.directus_files],
  );

  const getEventsLogoUrl = useCallback(
    ({
      width,
      aspectRatio,
      height,
    }: {
      width?: number;
      aspectRatio?: number;
      height?: number;
    }) =>
      getImageUrl(
        corporateBrandingCompany?.directus_files_Companies_EventsLogoTodirectus_files,
        {width, height, aspectRatio, resizeMode: 'contain'},
      ),
    [
      corporateBrandingCompany?.directus_files_Companies_EventsLogoTodirectus_files,
    ],
  );

  return useMemo(
    () => ({
      primaryColor,
      secondaryColor,
      backdropColor,
      tertiaryColor,
      highlightColor,
      getLogoUrl,
      getEventsLogoUrl,
    }),
    [
      backdropColor,
      getEventsLogoUrl,
      getLogoUrl,
      highlightColor,
      primaryColor,
      secondaryColor,
      tertiaryColor,
    ],
  );
};

export default useCompanyConfig;
