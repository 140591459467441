import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import DateIcon from '../icons/DateIcon';
import EuroIcon from '../icons/EuroIcon';
import LocationIcon from '../icons/LocationIcon';
import PersonIcon from '../icons/PersonIcon';
import TimeIcon from '../icons/TimeIcon';
import {
  BookingMetaInfoItem,
  BookingMetaInfoItemType,
  EventtoolEventType,
} from '../types/booking';
import {
  getCostLabel,
  getEventTime,
  getFormattedEventDate,
} from '../utils/booking';
import {BookingMetaInfoProps} from '../components/BookingMetaInfo/props';

const getIcon = (itemType: BookingMetaInfoItemType) => {
  return {
    [BookingMetaInfoItemType.COACH]: PersonIcon,
    [BookingMetaInfoItemType.COST]: EuroIcon,
    [BookingMetaInfoItemType.LOCATION]: LocationIcon,
    [BookingMetaInfoItemType.START_DATE]: DateIcon,
    [BookingMetaInfoItemType.END_DATE]: DateIcon,
    [BookingMetaInfoItemType.DURATION]: TimeIcon,
  }[itemType];
};

const useBookingMetaInfoData = ({
  coach,
  cost,
  endDate,
  endTime,
  model,
  startDate,
  startTime,
  venue,
}: BookingMetaInfoProps) => {
  const {t, i18n} = useTranslation();

  const costLabel = getCostLabel(t)(cost, false);

  const venueLabel = venue?.Venues_translations?.[0].Label;

  const {dayOfWeek: startDateDayOfWeek, formattedDate: formattedStartDate} =
    startDate
      ? getFormattedEventDate(startDate, i18n.language)
      : {formattedDate: '', dayOfWeek: ''};

  const {dayOfWeek: endDateDayOfWeek, formattedDate: formattedEndDate} = endDate
    ? getFormattedEventDate(endDate, i18n.language)
    : {formattedDate: '', dayOfWeek: ''};

  const startDateLocalizationKey =
    model && model === EventtoolEventType.INDEPENDENT_SERIAL_APPOINTMENT
      ? 'booking.serial_event_start'
      : model && model === EventtoolEventType.CONNECTED_SERIAL_APPOINTMENT
      ? 'booking.connected_serial_event_start'
      : 'booking.event_start';

  const startDateLabel = startDate
    ? t(startDateLocalizationKey, {
        dayOfWeek: startDateDayOfWeek,
        startDate: formattedStartDate,
      })
    : null;

  const endDateLabel =
    endDate &&
    model &&
    model === EventtoolEventType.CONNECTED_SERIAL_APPOINTMENT
      ? t('booking.connected_serial_event_end', {
          dayOfWeek: endDateDayOfWeek,
          endDate: formattedEndDate,
        })
      : null;

  const time = getEventTime(startTime, endTime);
  const timeLabel = time ? t('booking.event_time', {time}) : undefined;

  const getLabel = useCallback<
    (itemType: BookingMetaInfoItemType) => string | null | undefined
  >(
    itemType => {
      return {
        [BookingMetaInfoItemType.COACH]: coach,
        [BookingMetaInfoItemType.COST]: costLabel,
        [BookingMetaInfoItemType.LOCATION]: venueLabel,
        [BookingMetaInfoItemType.START_DATE]: startDateLabel,
        [BookingMetaInfoItemType.END_DATE]: endDateLabel,
        [BookingMetaInfoItemType.DURATION]: timeLabel,
      }[itemType];
    },
    [coach, costLabel, endDateLabel, startDateLabel, timeLabel, venueLabel],
  );

  const data = useMemo<BookingMetaInfoItem[]>(
    () =>
      Object.values(BookingMetaInfoItemType)
        .map(itemType => ({
          type: itemType,
          label: getLabel(itemType),
          Icon: getIcon(itemType),
        }))
        .filter(({label}) => !!label),
    [getLabel],
  );

  return data;
};

export default useBookingMetaInfoData;
