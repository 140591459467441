import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
import colors from '../theme/colors';

const BackArrowIcon: React.FC<SvgProps> = ({
  stroke = colors.black100,
  ...props
}) => {
  return (
    <Svg width={26} height={26} viewBox="0 0 26 26" {...props}>
      <Path
        d="M12.6251 17.8854L7.36467 12.625M7.36467 12.625L12.6251 7.36458M7.36467 12.625L17.8855 12.625"
        stroke={stroke}
        strokeWidth={1.85984}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default React.memo(BackArrowIcon);
