import {LinearGradient} from 'expo-linear-gradient';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, View, ViewStyle} from 'react-native';
import NextArrowIcon from '../../icons/NextArrowIcon';
import colors from '../../theme/colors';
import AppText from '../AppText';
import {DiscoverMoreCardProps} from './props';
import styles from './styles';

const BACKGROUND_GRADIENT_COLORS = [colors.transparent, colors.black95Alpha10];
const BACKGROUND_GRADIENT_POINTS = {
  start: {x: 0, y: 0},
  end: {x: 0, y: 1},
};

const DiscoverMoreCardContent: React.FC<DiscoverMoreCardProps> = ({
  width,
  aspectRatio,
  testID,
}) => {
  const {t} = useTranslation();

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, {width, height: width / aspectRatio}],
    [aspectRatio, width],
  );

  return (
    <View style={containerStyle}>
      <LinearGradient
        colors={BACKGROUND_GRADIENT_COLORS}
        style={styles.gradientBackground}
        pointerEvents="none"
        start={BACKGROUND_GRADIENT_POINTS.start}
        end={BACKGROUND_GRADIENT_POINTS.end}
        testID={`${testID}.backgroundGradient`}
      />
      <View style={styles.content}>
        <View style={styles.iconWrapper} testID={`${testID}.nextArrowIcon`}>
          <NextArrowIcon />
        </View>
        <AppText style={styles.label} testID={`${testID}.label`}>
          {t('common.discover_more')}
        </AppText>
      </View>
    </View>
  );
};

export default React.memo(DiscoverMoreCardContent);
