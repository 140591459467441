import classNames from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getStatusInfoType} from '../StatusInfo/StatusInfo.data';
import {StatusInfoType} from '../StatusInfo/StatusInfo.props';
import Switch from '../Switch/Switch';
import styles from './StatusInfoSwitcher.module.scss';
import StatusInfoSwitcherProps from './StatusInfoSwitcher.props';

const StatusInfoSwitcher: React.FC<StatusInfoSwitcherProps> = ({
  className,
  onToggle,
  currentStatus,
  readOnly = false,
}) => {
  const {t} = useTranslation();

  const statusType = getStatusInfoType(currentStatus);

  const isChecked = statusType === StatusInfoType.ONLINE;

  const mergedStyle = classNames(styles['status-info-switcher'], className, {
    [styles['status-info-switcher--checked']]: isChecked,
  });

  return (
    <div className={mergedStyle}>
      <p>{t(`mindManagerNewstool.${statusType}`)}</p>
      <Switch isChecked={isChecked} onToggle={onToggle} readOnly={readOnly} />
    </div>
  );
};

export default React.memo(StatusInfoSwitcher);
