export enum Breakpoints {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
  xxxl = 'xxxl',
}

export enum ContainerMaxWidths {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
  xxxl = 'xxxl',
}

export class BreakpointProvider {
  public $gridBreakpoints: Record<keyof typeof Breakpoints, number>;
  public $containerMaxWidths: Record<keyof typeof ContainerMaxWidths, number>;
  public sizesFullWidth: string;

  constructor(
    gridBreakpoints?: Record<keyof typeof Breakpoints, number>,
    containerMaxWidths?: Record<keyof typeof ContainerMaxWidths, number>,
  ) {
    this.$gridBreakpoints = gridBreakpoints || {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
      xxxl: 1920,
    };

    this.$containerMaxWidths = containerMaxWidths || {
      sm: 540,
      md: 720,
      lg: 960,
      xl: 1140,
      xxl: 1320,
      xxxl: 1702,
    };

    this.sizesFullWidth = '';
  }

  createSizes(
    sizes: Record<keyof typeof Breakpoints | string, number> = {
      xs: 575,
      sm: 767,
      md: 991,
      lg: 1199,
      xl: 1399,
      xxl: 1920,
    },
  ): string {
    let result = '';
    const breakpoints = Object.keys(sizes) as (keyof typeof Breakpoints)[];

    for (let i = 0; i < breakpoints.length; i++) {
      const maxWidth = sizes[breakpoints[i]];
      const nextBreakpointWidth =
        i < breakpoints.length - 1
          ? this.$gridBreakpoints[breakpoints[i + 1]]
          : null;
      const prefix = i === 0 ? '' : ', ';

      if (nextBreakpointWidth) {
        result += `${prefix}(max-width: ${nextBreakpointWidth}px) ${maxWidth}px`;
      } else {
        result += `${prefix}${maxWidth}px`;
      }
    }

    return result;
  }
}

const breakpointProvider = new BreakpointProvider();
export default breakpointProvider;
