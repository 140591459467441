import React from 'react';
import {Navigate, NavigateProps} from 'react-router-dom';
import {PathParams} from '../NavigationTypes';
import {RouteKeys} from '../RouteKeys';
import {buildUrl} from '../utils';

type TypedNavigateProps<P extends RouteKeys> = NavigateProps & {
  to: P;
  params?: PathParams<P>;
};

/**
 * Type-safe version of `react-router-dom/Navigate`.
 */
const TypedNavigate = <P extends RouteKeys>({
  to,
  params,
  ...props
}: TypedNavigateProps<P>) => {
  return <Navigate to={params ? buildUrl(to, params) : to} {...props} />;
};

export default TypedNavigate;
