import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';

export const PLAY_BUTTON_SIZE = Platform.select({
  default: 100,
  web: 65,
});

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.black95Alpha50,
  },
});

export default styles;
