import React, {useCallback, useMemo} from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import {Button, ButtonProps} from 'react-native-paper';
import BackArrowIcon from '../../icons/BackArrowIcon';
import NextArrowIcon from '../../icons/NextArrowIcon';
import colors from '../../theme/colors';
import styles from './styles';
import {MAX_FONT_SIZE_MULTIPLIER} from '../../utils/constants';

type Props = Omit<ButtonProps, 'onPress'> & {
  showNextIcon?: boolean;
  showBackIcon?: boolean;
  onPress?: () => void;
};

const AppButton: React.FC<Props> = ({
  style,
  mode = 'contained',
  labelStyle,
  uppercase = false,
  showNextIcon = false,
  showBackIcon = false,
  contentStyle,
  onPress,
  disabled,
  ...props
}) => {
  const buttonStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.button, style],
    [style],
  );

  const labelMemoizedStyle = useMemo(
    () => [styles.label, labelStyle],
    [labelStyle],
  );

  const contentMemoizedStyle = useMemo(
    () => [
      showNextIcon
        ? styles.buttonContentForNextButton
        : styles.buttonContentForBackButton,
      contentStyle,
    ],
    [contentStyle, showNextIcon],
  );

  const iconColor = disabled ? colors.black90Alpha90 : colors.black100;

  const BackArrowIconSource = useCallback(
    () => <BackArrowIcon style={styles.icon} stroke={iconColor} />,
    [iconColor],
  );

  const NextArrowIconSource = useCallback(
    () => <NextArrowIcon style={styles.icon} stroke={iconColor} />,
    [iconColor],
  );

  return (
    <Button
      uppercase={uppercase}
      mode={mode}
      style={buttonStyle}
      labelStyle={labelMemoizedStyle}
      contentStyle={contentMemoizedStyle}
      icon={
        showNextIcon
          ? NextArrowIconSource
          : showBackIcon
          ? BackArrowIconSource
          : undefined
      }
      onPress={onPress}
      disabled={disabled}
      maxFontSizeMultiplier={MAX_FONT_SIZE_MULTIPLIER}
      {...props}
    />
  );
};

export default React.memo(AppButton);
