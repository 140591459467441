import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import formatDate from 'date-fns/format';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ActivityIndicator} from 'react-native-paper';
import useElementSize from '../../../hooks/useElementSize';
import {
  FindManyMindManagerBookingsQueryVariables,
  SortOrder,
  useFindManyMindManagerBookingsQuery,
} from '../../../mindance-libs/generated/graphql';
import useCompanyEventImage from '../../../mindance-libs/hooks/useCompanyEventImage';
import useListQuery from '../../../mindance-libs/hooks/useListQuery';
import colors from '../../../mindance-libs/theme/colors';
import {BookingItemType} from '../../../mindance-libs/types/booking';
import {RouteKeys} from '../../../navigation/RouteKeys';
import Button from '../../Button/Button';
import {AnchorTargetAttribute} from '../../Button/Button.props';
import styles from './EventtoolHomeCard.module.scss';
import EventtoolHomeCardProps from './EventtoolHomeCard.props';

const CONTENT_IMAGE_SIZE = 100;
const ITEMS_SIZE = 2;

const EventtoolHomeCard: React.FC<EventtoolHomeCardProps> = ({
  className,
  testID,
}) => {
  const {t, i18n} = useTranslation();

  const {
    onLoad: onContainerLoad,
    ref: containerRef,
    size: containerSize,
  } = useElementSize<HTMLDivElement>({
    height: CONTENT_IMAGE_SIZE,
    width: CONTENT_IMAGE_SIZE,
  });

  const getEventImage = useCompanyEventImage();

  const getBookingQueryVariables = useCallback<
    () => FindManyMindManagerBookingsQueryVariables
  >(() => {
    const baseVariables = {
      languages_code: i18n.language,
      orderBy: [
        {StartDate: {sort: SortOrder.Asc}},
        {StartTime: {sort: SortOrder.Asc}},
      ],
      skip: 0,
      take: ITEMS_SIZE,
    };

    return baseVariables;
  }, [i18n.language]);

  const {data, loading} = useListQuery(
    useFindManyMindManagerBookingsQuery,
    getBookingQueryVariables,
    'findManyMindManagerBookings',
  );

  const mergedStyle = classNames(styles['eventtool-home-card'], className);

  const isEmpty = data?.length === 0;

  const Content = useMemo(() => {
    if (!data) {
      return null;
    }

    return data
      .slice(0, ITEMS_SIZE)
      .map(({id, directus_files, StartDate, Label}) => {
        const imageUrl = getEventImage(
          directus_files,
          BookingItemType.EVENT_EVENTTOOL,
          {width: containerSize.width, aspectRatio: 1},
        );

        return (
          <div
            className={styles['eventtool-home-card__container']}
            key={id}
            ref={containerRef}>
            <div className={styles['eventtool-home-card__container__box']}>
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt={Label ?? undefined}
                  className={
                    styles['eventtool-home-card__container__box__image']
                  }
                />
              ) : null}
              <div
                className={
                  styles['eventtool-home-card__container__box__content']
                }>
                {StartDate ? (
                  <div
                    className={
                      styles[
                        'eventtool-home-card__container__box__content__date'
                      ]
                    }>
                    <p
                      className={
                        styles[
                          'eventtool-home-card__container__box__content__date__label'
                        ]
                      }>
                      {formatDate(new Date(StartDate), 'dd.MM.yyyy')}
                    </p>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>

            <div className={styles['eventtool-home-card__container__text']}>
              {Label ? (
                <p
                  className={
                    styles['eventtool-home-card__container__text__title']
                  }>
                  {Label}
                </p>
              ) : null}
            </div>
          </div>
        );
      });
  }, [containerRef, containerSize.width, data, getEventImage]);

  const EmptyView = useMemo(
    () => (
      <div className={styles['eventtool-home-card__empty']}>
        <div>
          <h3 className={styles['eventtool-home-card__empty__title']}>
            {t('mindManagerHome.eventtool_title')}
          </h3>
          <p className={styles['eventtool-home-card__empty__description']}>
            {t('mindManagerHome.eventtool_description')}
          </p>
        </div>
        <div className={styles['eventtool-home-card__empty__button-container']}>
          <Button
            className={styles['eventtool-home-card__empty__button']}
            anchor={{
              href: RouteKeys.Eventtool,
              title: t('mindManagerHome.create_new_event') ?? '',
              target: AnchorTargetAttribute.SELF,
            }}>
            <div
              className={
                styles['eventtool-home-card__empty__button__icon-border']
              }>
              <FontAwesomeIcon
                icon={faPlus}
                className={styles['eventtool-home-card__empty__button__icon']}
              />
            </div>
            <p className={styles['eventtool-home-card__empty__button__title']}>
              {t('mindManagerHome.create_new_event')}
            </p>
          </Button>
        </div>
      </div>
    ),
    [t],
  );

  useEffect(() => {
    if (!loading && !isEmpty) {
      onContainerLoad();
    }
  }, [isEmpty, loading, onContainerLoad]);

  return (
    <div className={mergedStyle} data-testid={testID}>
      {loading ? (
        <div className={styles['eventtool-home-card__loading']}>
          <ActivityIndicator color={colors.whiteAlpha70} size={50} />
        </div>
      ) : isEmpty ? (
        EmptyView
      ) : (
        Content
      )}
    </div>
  );
};

export default EventtoolHomeCard;
