export enum DashboardComponentTemplates {
  'SECTION_TITLE' = 'SECTION_TITLE',
  'REGISTRATIONS' = 'REGISTRATIONS',
  'ACTIVE_USERS' = 'ACTIVE_USERS',
  'AGES' = 'AGES',
  'GENDER' = 'GENDER',
  'GROUP_COACHING_COUNT' = 'GROUP_COACHING_COUNT',
  'GROUP_COACHING_TOP' = 'GROUP_COACHING_TOP',
  'EVENTS_COUNT' = 'EVENTS_COUNT',
  'EVENTS_TOP' = 'EVENTS_TOP',
  'SESSIONS_PER_USER' = 'SESSIONS_PER_USER',
  'STORE_RATING' = 'STORE_RATING',
  'COMPANY_INSIGHTS_EVENT_BOOKINGS' = 'COMPANY_INSIGHTS_EVENT_BOOKINGS',
  'COMPANY_INSIGHTS_NEWS_LIKES' = 'COMPANY_INSIGHTS_NEWS_LIKES',
  'COMPANY_INSIGHTS_EVENTS_TOP' = 'COMPANY_INSIGHTS_EVENTS_TOP',
  'COMPANY_INSIGHTS_NEWS_TOP' = 'COMPANY_INSIGHTS_NEWS_TOP',
  'COMPANY_INSIGHTS_EVENTS_TOP_PER_MONTH' = 'COMPANY_INSIGHTS_EVENTS_TOP_PER_MONTH',
  'COMPANY_INSIGHTS_NEWS_TOP_PER_MONTH' = 'COMPANY_INSIGHTS_NEWS_TOP_PER_MONTH',
}

export enum DashboardComponentGridSizes {
  'COLUMNS_1/4' = 1,
  'COLUMNS_2/4' = 2,
  'COLUMNS_4/4' = 4,
}
