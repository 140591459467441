import * as R from 'ramda';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import NavigationKeys from '../../navigation/NavigationKeys';
import {
  BaseProgressReportFragment,
  BaseSessionFragment,
  CoachDetailsFragment,
  CourseInfoFragment,
  CoursesInfoQueryVariables,
  Maybe,
  useCoursesInfoQuery,
} from '../generated/graphql';
import {useAppContext} from '../store/contexts/AppContext';
import {FilterButtonType} from '../types/common';
import {CourseDetailsModeType} from '../types/courses';
import {ProgressReportTypes} from '../types/progressReport';
import {getCoachName} from '../utils/booking';
import {getProgressReportCondition} from '../utils/courses';
import {getImageUrl} from '../utils/files';
import {reportError} from '../utils/loggingHelpers';
import useProgressReports from './useProgressReports';

const useCourseDetails = (
  courseId?: string,
  options?: {
    previewImageWidth?: number;
    previewImageHeight?: number;
    previewImageAspectRatio?: number;
    screen?: NavigationKeys;
  },
) => {
  const {t, i18n} = useTranslation();

  const {reportFinishedCourse, reportOpenSession} = useProgressReports();

  const {user} = useAppContext();

  const [selectedMode, setSelectedMode] = useState(
    CourseDetailsModeType.COURSE,
  );

  const queryVariables = useMemo<CoursesInfoQueryVariables>(
    () => ({
      where: {
        id: {equals: Number(courseId)},
        OR: [...getProgressReportCondition()],
      },
      language: i18n.language,
      take: 1,
    }),
    [courseId, i18n.language],
  );

  const {data, loading, error, previousData} = useCoursesInfoQuery({
    variables: queryVariables,
    onError: err => reportError('useCoursesInfoQuery error', err),
    skip: !courseId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const course = useMemo<CourseInfoFragment | undefined>(
    () => data?.findManyCourses?.[0],
    [data?.findManyCourses],
  );

  const coach = useMemo<Maybe<CoachDetailsFragment> | undefined>(
    () => course?.Coaches,
    [course?.Coaches],
  );

  const coachName = getCoachName(coach?.Firstname, coach?.Lastname);

  const allSessions = useMemo<BaseSessionFragment[]>(() => {
    const sortedCourseSessions = [...(course?.Courses_Sessions ?? [])]?.sort(
      (a, b) => {
        const aSort = a.sort;
        const bSort = b.sort;

        if (R.isNil(aSort) || R.isNil(bSort)) {
          return 0;
        }

        return aSort - bSort;
      },
    );

    const unfilteredSessions = R.pluck('Sessions', sortedCourseSessions);
    return R.filter(R.isNotNil, unfilteredSessions);
  }, [course?.Courses_Sessions]);

  const sessionsProgressReports = useMemo<BaseProgressReportFragment[]>(
    () =>
      course?.ProgressReports?.filter(({Session}) => R.isNotNil(Session)) ?? [],
    [course?.ProgressReports],
  );

  const courseCompleted = !!course?.ProgressReports?.find(
    ({Label}) => Label === ProgressReportTypes.FINISHED_COURSE,
  );

  const sessionsProgress = useMemo<Record<string, boolean>>(() => {
    const sessionsIds = R.pluck('id', allSessions);

    const result = sessionsIds.reduce<Record<string, boolean>>(
      (acc, sessionId) => {
        const completed = !!sessionsProgressReports?.find(
          ({Session, Label}) =>
            String(Session) === sessionId &&
            Label === ProgressReportTypes.FINISHED_SESSION,
        );

        return {...acc, [sessionId]: completed};
      },
      {},
    );

    return result;
  }, [allSessions, sessionsProgressReports]);

  const finishedSessionIds = useMemo(() => {
    const allFinishedSessions = R.map(
      (report: BaseProgressReportFragment) => report.Session?.toString(),
      R.filter(
        report => report.Label === ProgressReportTypes.FINISHED_SESSION,
        sessionsProgressReports,
      ),
    );
    const filteredFinishedSessions = R.filter(R.isNotNil, allFinishedSessions);
    return filteredFinishedSessions;
  }, [sessionsProgressReports]);

  const courseSessionIds = useMemo(
    () => R.pluck('id', allSessions),
    [allSessions],
  );

  const unfinishedSessionIds = useMemo(
    () =>
      R.reject(
        sessionId => R.includes(sessionId, finishedSessionIds),
        courseSessionIds,
      ),
    [courseSessionIds, finishedSessionIds],
  );

  const previewImage = getImageUrl(course?.directus_files, {
    height: options?.previewImageHeight,
    aspectRatio: options?.previewImageAspectRatio,
    width: options?.previewImageWidth,
  });

  const detailsModes = useMemo<FilterButtonType[]>(
    () =>
      Object.values(CourseDetailsModeType).map(mode => ({
        id: mode,
        isSelected: selectedMode === mode,
        label: t(`courses.${mode}`),
      })),
    [selectedMode, t],
  );

  const reportOpenedSession = useCallback<(sessionId: string) => void>(
    sessionId => {
      reportOpenSession({
        email: user?.EMail,
        screen: options?.screen,
        courseId,
        sessionId,
      });
    },
    [courseId, options?.screen, reportOpenSession, user?.EMail],
  );

  useEffect(() => {
    if (courseCompleted || !courseId || loading) {
      return;
    }

    if (R.all(R.equals(true), Object.values(sessionsProgress))) {
      reportFinishedCourse({
        email: user?.EMail ?? '',
        courseId,
        screen: options?.screen,
      });
    }
  }, [
    courseCompleted,
    courseId,
    loading,
    options?.screen,
    reportFinishedCourse,
    sessionsProgress,
    user?.EMail,
  ]);

  return useMemo(
    () => ({
      ...course,
      previewImage,
      sessions: allSessions,
      finishedSessionIds,
      unfinishedSessionIds,
      sessionsProgressReports,
      coach,
      coachName,
      loading: loading && !previousData,
      error,
      selectedMode,
      detailsModes,
      updateSelectedMode: setSelectedMode,
      reportOpenedSession,
    }),
    [
      course,
      previewImage,
      allSessions,
      finishedSessionIds,
      unfinishedSessionIds,
      sessionsProgressReports,
      coach,
      coachName,
      loading,
      previousData,
      error,
      selectedMode,
      detailsModes,
      reportOpenedSession,
    ],
  );
};

export default useCourseDetails;
