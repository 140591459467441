export default {
  xxxxs: 2,
  xxxs: 4,
  xxs: 6,
  xs: 8,
  s: 10,
  m: 12,
  l: 16,
  xl: 18,
  xxl: 24,
  xxxl: 32,
  xxxxl: 40,
  xxxxxl: 48,
  xxxxxxl: 52,
  xxxxxxxl: 56,
  xxxxxxxxl: 60,
  xxxxxxxxxl: 64,
  xxxxxxxxxxl: 68,
  xxxxxxxxxxxl: 72,
  xxxxxxxxxxxxl: 76,
  xxxxxxxxxxxxxl: 80,
};

export const DEFAULT_BORDER_RADIUS = 22;
export const SMALL_BORDER_RADIUS = 11;
