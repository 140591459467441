import {StyleProp, ViewStyle} from 'react-native';

export enum OnboardingSlides {
  INTRO = 'INTRO',
  DESCRIPTION = 'DESCRIPTION',
  SURVEY = 'SURVEY',
  SUMMARY = 'SUMMARY',
}

export type OnboardingSlideProps = {
  focused: boolean;
  index: number;
  onButtonPress?: () => void;
  style?: StyleProp<ViewStyle>;
  contentButtonStyle?: StyleProp<ViewStyle>;
};
