import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftButton: {
    alignItems: 'flex-start',
  },
  space: {
    flex: 1,
  },
});

export default styles;
