import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import typography from '../../theme/typography';

const SKIP_BUTTON_SIZE = Platform.select({
  default: 52,
  web: 35,
});

const styles = StyleSheet.create({
  container: {
    width: SKIP_BUTTON_SIZE,
    height: SKIP_BUTTON_SIZE,
    borderRadius: SKIP_BUTTON_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.black95Alpha50,
  },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  value: {
    ...typography.tabBarLabel,
    color: colors.white,
    marginBottom: 1,
  },
  icon: {
    position: 'absolute',
    alignSelf: 'center',
  },
});

export default styles;
