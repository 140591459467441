import {DOMAttributes, useMemo} from 'react';
import DOMPurify from 'dompurify';

const useDangerouslySetInnerHtml = (text: string) => {
  return useMemo<
    NonNullable<DOMAttributes<HTMLDivElement>['dangerouslySetInnerHTML']>
  >(() => ({__html: DOMPurify.sanitize(text)}), [text]);
};

export default useDangerouslySetInnerHtml;
