import {useCallback} from 'react';
import {NavigateOptions, useNavigate} from 'react-router-dom';
import {
  PathParams,
  RouteKeysQueryParams,
  RouteKeysState,
} from '../NavigationTypes';
import {RouteKeys} from '../RouteKeys';
import {generateUrlWithParams} from '../utils';

export interface TypedNavigateFunction {
  <P extends RouteKeys>(
    path: {to: P; params: PathParams<P>; queryParams?: RouteKeysQueryParams[P]},
    options?: Omit<NavigateOptions, 'state'> & {state?: RouteKeysState[P]},
  ): void;
  (delta: number): void;
}

/**
 * Type-safe version of `react-router-dom/useNavigate`.
 * @returns an imperative method for changing the location. Used by `<Link>`s, but
 * may also be used by other elements to change the location.ø
 */
const useTypedNavigate = (): TypedNavigateFunction => {
  const navigate = useNavigate();

  const typedNavigate: TypedNavigateFunction = useCallback(
    <P extends RouteKeys>(
      path:
        | {to: P; params: PathParams<P>; queryParams?: RouteKeysQueryParams[P]}
        | number,
      options?: Omit<NavigateOptions, 'state'> & {state?: RouteKeysState[P]},
    ) => {
      if (typeof path === 'number') {
        return navigate(path);
      }

      const {to, params, queryParams} = path;

      return navigate(generateUrlWithParams(to, params, queryParams), options);
    },
    [navigate],
  );

  return typedNavigate;
};

export default useTypedNavigate;
