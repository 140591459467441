import * as R from 'ramda';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  AppNewsFragment,
  FindManyNewsQueryVariables,
  NullsOrder,
  SortOrder,
  useFindManyNewsQuery,
  useToggleNewsLikesMutation,
} from '../generated/graphql';
import {useAppContext} from '../store/contexts/AppContext';
import {AppProductsLabels} from '../types/common';
import {reportError} from '../utils/loggingHelpers';
import useListQuery from './useListQuery';

export type NewsSection = {
  title?: string | null;
  data: AppNewsFragment[];
};

const useNewsItems = () => {
  const {t, i18n} = useTranslation();
  const {screensSetupCategoriesAvailability} = useAppContext();

  const language = i18n.language;

  const getQueryVariables = useCallback<
    (input?: Partial<FindManyNewsQueryVariables>) => FindManyNewsQueryVariables
  >(
    (input = {}) => {
      const baseVariables: FindManyNewsQueryVariables = {
        where: {
          News_translations: {
            some: {
              languages_code: {equals: language},
              Enabled: {equals: true},
            },
          },
        },
        orderBy: [
          {
            Pinned: {
              nulls: NullsOrder.Last,
              sort: SortOrder.Desc,
            },
          },
          {
            date_created: {
              sort: SortOrder.Desc,
              nulls: NullsOrder.Last,
            },
          },
        ],
        languages_code: language,
        force_refresh: true,
      };

      return R.mergeDeepLeft(
        baseVariables,
        input,
      ) as FindManyNewsQueryVariables;
    },
    [language],
  );

  const {
    data,
    loading,
    error,
    previousData,
    fetchNext,
    loadingMore,
    refetchWithParams,
    refreshing,
  } = useListQuery(useFindManyNewsQuery, getQueryVariables, 'findManyNews', {
    shouldSkip: !screensSetupCategoriesAvailability[AppProductsLabels.NEWS],
  });

  const filteredContentData = useMemo<AppNewsFragment[]>(
    () =>
      data.map(item => ({
        ...item,
        News_translations: item.News_translations?.filter(
          ({languages_code}) => languages_code === language,
        ),
      })),
    [data, language],
  );

  const [toggleOneNewsLike] = useToggleNewsLikesMutation();

  const pinnedItems = useMemo<AppNewsFragment[]>(
    () => filteredContentData.filter(({Pinned}) => Pinned === true),
    [filteredContentData],
  );

  const unpinnedItems = useMemo<AppNewsFragment[]>(
    () => filteredContentData.filter(({Pinned}) => !Pinned),
    [filteredContentData],
  );

  const sections = useMemo<NewsSection[]>(() => {
    if (
      (!pinnedItems || pinnedItems.length === 0) &&
      (!unpinnedItems || unpinnedItems.length === 0)
    ) {
      return [];
    }

    if (!pinnedItems || pinnedItems.length === 0) {
      return [{data: unpinnedItems}];
    }

    if (!unpinnedItems || unpinnedItems.length === 0) {
      return [{data: pinnedItems}];
    }

    return [
      {title: t('news.pinned'), data: pinnedItems},
      {title: t('news.other_posts'), data: unpinnedItems},
    ];
  }, [pinnedItems, t, unpinnedItems]);

  const toggleNewsLike = useCallback<
    (id: AppNewsFragment['id']) => Promise<void>
  >(
    async id => {
      try {
        await toggleOneNewsLike({variables: {id: Number(id)}});
      } catch (err) {
        reportError('toggleOneNewsLike error', err);
      }
    },
    [toggleOneNewsLike],
  );

  return useMemo(
    () => ({
      pinnedItems,
      unpinnedItems,
      sections,
      loading: loading && !previousData,
      loadingMore,
      error,
      refreshing,
      toggleNewsLike,
      fetchNext,
      refetchWithParams,
    }),
    [
      error,
      fetchNext,
      loading,
      loadingMore,
      pinnedItems,
      previousData,
      refetchWithParams,
      refreshing,
      sections,
      toggleNewsLike,
      unpinnedItems,
    ],
  );
};

export default useNewsItems;
