import {
  ApolloClient,
  FieldFunctionOptions,
  HttpLink,
  InMemoryCache,
  from,
  split,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {AsyncStorageWrapper, persistCache} from 'apollo3-cache-persist';
import {useEffect, useMemo} from 'react';
import Config from '../../utils/config';
import {useAuthContext} from '../store/contexts/AuthContext';
import {devLog, reportError} from '../utils/loggingHelpers';
import useApolloErrorLink from './useApolloErrorLink';

const CONTENT_URL = Config.CONTENT_URL;
const USER_URL = Config.USER_URL;
const PAYMENT_URL = Config.PAYMENT_URL;
devLog('config', Config);

const mergeListData = <
  TOptions extends FieldFunctionOptions = FieldFunctionOptions,
>(
  existing: Array<any> = [],
  incoming: Array<any> = [],
  options: TOptions,
) => {
  const skip = options?.variables?.skip;
  if (!skip || skip === 0) {
    return incoming;
  }

  const merged = [...existing, ...incoming];
  return merged;
};

const returnIncomingData = (existing: any | undefined, incoming: any) => {
  return incoming;
};

export const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        findManyBookings: {
          keyArgs: [
            'where',
            ['OR', ['BookingType', 'Events_Tags'], 'BookingType'],
          ],
          merge: mergeListData,
        },
        findManyBookingsV2: {
          keyArgs: ['selectedTags', 'bookingType'],
          merge: mergeListData,
        },
        findManyBookingsV3: {
          keyArgs: ['selectedTags', 'bookingType'],
          merge: mergeListData,
        },
        findManyEvents: {
          keyArgs: ['where', ['BookingType']],
          merge: mergeListData,
        },
        findManyCourses: {
          keyArgs: ['where', ['Courses_Tags', 'id', 'ProgressReports']],
          merge: mergeListData,
        },
        findManySessions: {
          keyArgs: ['where', ['Sessions_Tags', 'id']],
          merge: mergeListData,
        },
        findManyCoursesAndSessions: {
          keyArgs: ['tags'],
          merge: mergeListData,
        },
        findManyNews: {
          keyArgs: ['where'],
          merge: mergeListData,
        },
        findManyMindManagerNews: {
          keyArgs: ['where', ['Subscriptions_News', 'id']],
          merge: mergeListData,
        },
        defaultOnboarding: {
          keyArgs: [],
          merge: returnIncomingData,
        },
      },
    },
    Bookings: {
      fields: {
        OneToOneSessions_Tags: {
          merge: returnIncomingData,
        },
      },
    },
    Users: {
      fields: {
        Users_Tags: {
          merge: returnIncomingData,
        },
        Users_ActivationCodes: {
          merge: returnIncomingData,
        },
      },
    },
    GeneralSetting_translations: {
      fields: {
        GeneralSetting_translations_TagGroups: {
          merge: returnIncomingData,
        },
      },
    },
    TagGroups: {
      fields: {
        TagGroups_Tags: {
          merge: returnIncomingData,
        },
      },
    },
    Library_translations: {
      fields: {
        TopicItems: {
          merge: returnIncomingData,
        },
      },
    },
    News: {
      fields: {
        NewsLikes: {
          merge: returnIncomingData,
        },
        News_translations: {
          merge: returnIncomingData,
        },
      },
    },
  },
});

const USER_OPERATIONS_NAMES = [
  'profile',
  'updateUserData',
  'updateUserTags',
  'findManyPersonalStatuses',
  'appUsageByUser',
  'serviceLevel',
];
const PAYMENT_OPERATIONS_NAMES = ['bookingOrder'];

export const httpLink = split(
  ({operationName}) => USER_OPERATIONS_NAMES.includes(operationName),
  new HttpLink({uri: USER_URL}),
  split(
    ({operationName}) => PAYMENT_OPERATIONS_NAMES.includes(operationName),
    new HttpLink({uri: PAYMENT_URL}),
    new HttpLink({uri: CONTENT_URL}),
  ),
);

const useRootApolloClient = () => {
  const {accessToken} = useAuthContext();

  const authLink = useMemo(
    () =>
      setContext(async (_, {headers}) => ({
        headers: {
          ...headers,
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      })),
    [accessToken],
  );

  const errorLink = useApolloErrorLink();

  const client = useMemo(() => {
    return new ApolloClient({
      cache: apolloCache,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
        },
      },
      link: from([authLink, errorLink, httpLink]),
    });
  }, [authLink, errorLink]);

  useEffect(() => {
    persistCache({
      cache: apolloCache,
      debug: __DEV__,
      storage: new AsyncStorageWrapper(AsyncStorage),
    }).catch(error => reportError('Update tokens error', error));
  }, []);

  return client;
};

export default useRootApolloClient;
