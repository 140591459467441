import {ApexOptions} from 'apexcharts';
import classNames from 'classnames';
import React, {useMemo} from 'react';
import Chart from 'react-apexcharts';
import colors from '../../mindance-libs/theme/colors';
import styles from './DashboardItem.module.scss';
import DashboardItemProps from './DashboardItem.props';
import {DEFAULT_PIE_CHART_OPTIONS} from './DefaultPieChartOptions';
import DashboardItemInfo from './DashboardItemInfo';

const DEFAULT_BAR_CHART_OPTIONS: ApexOptions = {
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  chart: {
    type: 'bar',
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    enabled: false,
  },
  xaxis: {
    labels: {
      show: true,
      style: {
        cssClass: styles['dashboard-item__bar-chart-x'],
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    tickAmount: 4,
    labels: {
      formatter: (value: number | string) => `${value}%`,
      show: true,
      style: {
        cssClass: styles['dashboard-item__bar-chart-y'],
      },
    },
  },
  grid: {
    show: true,
    strokeDashArray: 5,
    yaxis: {
      lines: {
        show: false,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      type: 'vertical',
      shadeIntensity: 1,
      opacityFrom: 0,
      opacityTo: 1,
      colorStops: [
        [
          {
            offset: 0,
            color: colors.barChart,
            opacity: 1,
          },
          {
            offset: 100,
            color: colors.barChart,
            opacity: 0,
          },
        ],
      ],
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: '20px',
    },
  },
};

const getPieChartData = (content: any): ApexAxisChartSeries =>
  content ? Object.values(content) : [];

const getPieChartOptions = (content: any) =>
  content
    ? {
        ...DEFAULT_PIE_CHART_OPTIONS,
        labels: Object.keys(content),
      }
    : {};

const getBarChartData = (content: any): ApexAxisChartSeries => [
  {data: (content ? Object.values(content) : []) as number[]},
];

const getBarChartOptions = (content: any): ApexOptions =>
  content
    ? {
        ...DEFAULT_BAR_CHART_OPTIONS,
        xaxis: {
          ...DEFAULT_BAR_CHART_OPTIONS.xaxis,
          categories: Object.keys(content),
        },
      }
    : {};

const DashboardItem: React.FC<DashboardItemProps> = ({
  className,
  title,
  iconKey,
  content,
  contentType,
}) => {
  const Content = useMemo(() => {
    switch (contentType) {
      case 'value':
        if (!Number.isInteger(content) && typeof content !== 'string') {
          return null;
        }
        return (
          <p className={styles['dashboard-item__value']}>{content as any}</p>
        );

      case 'top-chart':
        if (!(content instanceof Array)) {
          return null;
        }
        return (
          <ol className={styles['dashboard-item__top-list']}>
            {content.map((item: string, index) => (
              <li
                key={item + index.toString()}
                className={styles['dashboard-item__top-item']}>
                {item}
              </li>
            ))}
          </ol>
        );

      case 'bar-chart':
        if (!(content instanceof Object)) {
          return null;
        }
        const barChartData = getBarChartData(content);
        const barChartOptions = getBarChartOptions(content);

        return (
          <div className={styles['dashboard-item__bar-chart']}>
            <Chart
              options={barChartOptions}
              series={barChartData}
              type="bar"
              width="100%"
              height="100%"
            />
          </div>
        );

      case 'pie-chart':
        if (!(content instanceof Object)) {
          return null;
        }
        const pieChartData = getPieChartData(content);
        const pieChartOptions = getPieChartOptions(content);

        return (
          <div className={styles['dashboard-item__pie-chart-container']}>
            <div className={styles['dashboard-item__pie-chart']}>
              <Chart
                options={pieChartOptions}
                series={pieChartData}
                type="pie"
                width="100%"
                height="100%"
              />
            </div>
            {<DashboardItemInfo content={content} />}
          </div>
        );

      default:
        return null;
    }
  }, [content, contentType]);

  if (Content === null) {
    return null;
  }

  return (
    <div className={classNames(styles['dashboard-item__container'], className)}>
      <div className={styles['dashboard-item__header']}>
        {iconKey && (
          <i className={classNames(iconKey, styles['dashboard-item__icon'])} />
        )}
        {title && <p className={styles['dashboard-item__text']}>{title}</p>}
      </div>
      <div className={styles['dashboard-item__content']}>{Content}</div>
    </div>
  );
};

export default DashboardItem;
