import React from 'react';
import {ScrollView, ScrollViewProps} from 'react-native';
import {Maybe} from '../../generated/graphql';
import HTMLRenderer from '../HTMLRenderer';
import {htmlCustomStyle} from './styles';

type Props = ScrollViewProps & {description?: Maybe<string>};

const CourseDetailsContent: React.FC<Props> = ({description, ...props}) => {
  return (
    <ScrollView showsVerticalScrollIndicator={false} {...props}>
      {description ? (
        <HTMLRenderer content={description} tagsStyles={htmlCustomStyle} />
      ) : null}
    </ScrollView>
  );
};

export default React.memo(CourseDetailsContent);
