import * as R from 'ramda';
import {useCallback, useMemo, useState} from 'react';
import {Tags} from '../generated/graphql';
import {useAppContext} from '../store/contexts/AppContext';
import {CustomTagGroup} from '../types/common';
import {getFormattedTagsGroups} from '../utils/tags';
import useSelectTags from './useSelectTags';

const useLibraryTags = (
  tags?: Tags['id'][],
  options?: {onTagsSelect?: (tagsIds: Tags['id'][]) => void},
) => {
  const [selectedTagsWithExternal, setSelectedTagsWithExternal] = useState(
    tags ?? [],
  );

  const {libraryScreenSetup} = useAppContext();

  const tagsFilters = useMemo<CustomTagGroup[]>(
    () =>
      getFormattedTagsGroups(
        libraryScreenSetup?.GeneralSetting_Library_GeneralSettingToGeneralSetting,
      ),
    [libraryScreenSetup?.GeneralSetting_Library_GeneralSettingToGeneralSetting],
  );

  const {
    tagsIds,
    selectedTagsIds,
    selectedTagsRecord,
    selectTag,
    selectAllTags,
    deselectAllTags,
    resetSelectedTags,
  } = useSelectTags({tagsFilters, initiallySelectedTags: tags});

  const externalTags = useMemo<Tags['id'][]>(
    () => R.without(tagsIds, tags ?? []),
    [tagsIds, tags],
  );

  const updateSelectedTagsWithExternal = useCallback(() => {
    const mergedTags = R.pipe(R.concat(externalTags), R.uniq)(selectedTagsIds);

    setSelectedTagsWithExternal(mergedTags);
    options?.onTagsSelect?.(mergedTags);
  }, [externalTags, selectedTagsIds, options]);

  const selectedTagGroups = useMemo<CustomTagGroup[]>(() => {
    const tagGroups = tagsFilters.filter(e => {
      if (!e.tags || e.tags.length === 0) {
        return false;
      }

      const ids = R.pluck('id', e.tags);
      const commonTags = R.intersection(ids, selectedTagsWithExternal);

      return commonTags.length > 0;
    });

    return tagGroups;
  }, [selectedTagsWithExternal, tagsFilters]);

  const selectedTagGroupLabel =
    selectedTagGroups.length === 1 ? selectedTagGroups[0].label : undefined;

  return useMemo(
    () => ({
      selectedTagGroups,
      selectedTagGroupLabel,
      selectedTagsRecord,
      selectedTagsWithExternal,
      tagsFilters,
      tagsIds,
      selectedTagsIds,
      externalTags,
      selectTag,
      updateSelectedTagsWithExternal,
      resetSelectedTags,
      selectAllTags,
      deselectAllTags,
    }),
    [
      selectedTagGroups,
      selectedTagGroupLabel,
      selectedTagsRecord,
      selectedTagsWithExternal,
      tagsFilters,
      tagsIds,
      selectedTagsIds,
      externalTags,
      selectTag,
      updateSelectedTagsWithExternal,
      resetSelectedTags,
      selectAllTags,
      deselectAllTags,
    ],
  );
};

export default useLibraryTags;
