import React from 'react';
import {useTranslation} from 'react-i18next';
import AppPicker from '../AppPicker';
import EditAppInput from '../EditAppInput';
import {BookingFormFieldsProps} from './props';
import styles from './styles.web.module.scss';

const RNStyles = {
  input: {
    flex: 1,
  },
  picker: {
    marginTop: 6,
  },
};

const BookingFormFields: React.FC<BookingFormFieldsProps> = ({
  editMode = false,
  testID,
  values,
  handleChange,
  handleBlur,
  handleFocus,
  hasError,
  personalStatusItems,
  personalStatusPlaceholder,
  personalStatusError,
  isFocused,
}) => {
  const {t} = useTranslation();

  return (
    <div className={styles['booking-form-fields__container']}>
      <div className={styles['booking-form-fields__first-row']}>
        <EditAppInput
          label={t('booking.postal_code')}
          testID={`${testID}.postcodeInput`}
          showIcon={editMode}
          isRequired
          value={values.postCode}
          onChangeText={handleChange.postCode}
          onBlur={handleBlur.postCode}
          onFocus={handleFocus.postCode}
          autoCapitalize="none"
          autoCorrect={false}
          error={hasError.postCode}
          isFocused={isFocused.postCode}
          style={RNStyles.input}
        />
        <EditAppInput
          label={t('booking.city')}
          testID={`${testID}.cityInput`}
          value={values.city}
          showIcon={editMode}
          isRequired
          onChangeText={handleChange.city}
          onBlur={handleBlur.city}
          onFocus={handleFocus.city}
          autoCapitalize="words"
          autoCorrect={false}
          error={hasError.city}
          isFocused={isFocused.city}
          style={RNStyles.input}
        />
      </div>
      <div className={styles['booking-form-fields__columns-wrapper']}>
        <div className={styles['booking-form-fields__columns-wrapper__column']}>
          <EditAppInput
            label={t('booking.phone')}
            testID={`${testID}.phoneInput`}
            value={values.phone}
            showIcon={editMode}
            isRequired
            onChangeText={handleChange.phone}
            onBlur={handleBlur.phone}
            onFocus={handleFocus.phone}
            autoCapitalize="none"
            keyboardType="phone-pad"
            autoCorrect={false}
            error={hasError.phone}
            isFocused={isFocused.phone}
          />
          <EditAppInput
            label={t('booking.employer')}
            testID={`${testID}.employerInput`}
            value={values.employer}
            showIcon={editMode}
            isRequired
            onChangeText={handleChange.employer}
            onBlur={handleBlur.employer}
            onFocus={handleFocus.employer}
            autoCapitalize="sentences"
            autoCorrect={false}
            error={hasError.employer}
            isFocused={isFocused.employer}
          />
        </div>
        <div className={styles['booking-form-fields__columns-wrapper__column']}>
          <EditAppInput
            label={t('booking.department')}
            testID={`${testID}.departmentInput`}
            value={values.department}
            showIcon={editMode}
            isRequired
            onChangeText={handleChange.department}
            onBlur={handleBlur.department}
            onFocus={handleFocus.department}
            autoCapitalize="sentences"
            autoCorrect={false}
            error={hasError.department}
            isFocused={isFocused.department}
          />
          <AppPicker
            onValueChange={handleChange.personalStatus}
            items={personalStatusItems}
            value={values.personalStatus ?? ''}
            placeholder={personalStatusPlaceholder}
            error={personalStatusError}
            testID={`${testID}.personalStatusPicker`}
            style={RNStyles.picker}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(BookingFormFields);
