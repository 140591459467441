import React, {ReactNode, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppContext} from '../../mindance-libs/store/contexts/AppContext';
import {AppProductsLabels} from '../../mindance-libs/types/common';
import LoadingPage from '../../pages/loading/page';
import {RouteKeys} from '../RouteKeys';
import TypedNavigate from '../typed/TypedNavigate';

const OnboardingRoute: React.FC<{children?: ReactNode}> = ({children}) => {
  const {
    isLoading,
    isLoggedIn,
    screenSetupLoading,
    screensSetupCategoriesAvailability,
    isOnboardingPassed,
    isMindManager,
  } = useAppContext();

  const location = useLocation();

  const state = useMemo(() => ({from: location}), [location]);

  if (isLoading || screenSetupLoading) {
    return <LoadingPage />;
  }

  if (!isLoggedIn) {
    return <TypedNavigate to={RouteKeys.Login} replace state={state} />;
  }

  if (
    isOnboardingPassed ||
    !screensSetupCategoriesAvailability[AppProductsLabels.ONBOARDING] ||
    isMindManager
  ) {
    return (
      <TypedNavigate
        to={isMindManager ? RouteKeys.Home : RouteKeys.Start}
        replace
      />
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default OnboardingRoute;
