import {Platform, StyleSheet, ViewStyle} from 'react-native';
import spacings from '../../theme/spacings';
import colors from '../../theme/colors';

export const ICON_SIZE = 18;
export const TICK_ICON_COLOR = colors.white;

const commonIconContainerStyle: ViewStyle = {
  width: ICON_SIZE,
  height: ICON_SIZE,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: ICON_SIZE / 2,
  marginTop: Platform.select({
    default: 0,
    ios: spacings.xxxxs,
  }),
};

const styles = StyleSheet.create({
  tagGroup: {
    marginTop: spacings.m,
  },
  tickIconContainer: {
    ...commonIconContainerStyle,
    borderWidth: 1,
    borderColor: TICK_ICON_COLOR,
  },
  closeIconContainer: {
    ...commonIconContainerStyle,
    backgroundColor: colors.lightGray,
  },
});

export default styles;
