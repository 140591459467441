import {NativeModules, Platform} from 'react-native';
import {reportError} from './loggingHelpers';

const {PasswordManager: PasswordManagerModule} = NativeModules;

export const PasswordManager = {
  savePassword: (email: string, password: string) => {
    switch (Platform.OS) {
      case 'android':
        try {
          PasswordManagerModule.savePassword(email, password);
        } catch (error) {
          reportError('PasswordManager savePassword error', error);
        }
        break;

      default:
        break;
    }
  },
};
