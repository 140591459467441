export enum StatusFilterOptions {
  ALL = 'all',
  ONLINE = 'online',
  OFFLINE = 'offline',
}

type StatusFilterProps = {
  selected: StatusFilterOptions;
  changeSelected: (item: StatusFilterOptions) => void;
  className?: string;
  testID?: string;
};

export default StatusFilterProps;
