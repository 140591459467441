import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {SMALL_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: colors.black90Alpha90,
    borderRadius: SMALL_BORDER_RADIUS,
    padding: spacings.xs,
    gap: spacings.m,
  },
  image: {
    borderRadius: SMALL_BORDER_RADIUS,
  },
  content: {
    flex: 1,
    paddingVertical: Platform.select({default: undefined, web: spacings.m}),
    justifyContent: 'flex-start',
  },
  label: {
    ...typography.bodyDemiBold,
    color: colors.white,
    lineHeight: Platform.OS === 'ios' ? 20 : 24,
    marginTop: Platform.select({default: undefined, web: spacings.xxxs}),
  },
});

export default styles;
