import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import ErrorOverlay from '../../../../components/ErrorOverlay/ErrorOverlay';
import LoadingOverlay from '../../../../components/LoadingOverlay/LoadingOverlay';
import AppModal from '../../../../mindance-libs/components/AppModal';
import BookingFooter from '../../../../mindance-libs/components/BookingFooter';
import BookingInfoContent from '../../../../mindance-libs/components/BookingInfoContent';
import BookingMetaInfo from '../../../../mindance-libs/components/BookingMetaInfo';
import CoachDetailsContent from '../../../../mindance-libs/components/CoachDetailsContent';
import ImportantInfo from '../../../../mindance-libs/components/ImportantInfo';
import LinearSeparator from '../../../../mindance-libs/components/LinearSeparator';
import RoundBackButton from '../../../../mindance-libs/components/RoundBackButton';
import ScreenBase from '../../../../mindance-libs/components/ScreenBase';
import useAppModal from '../../../../mindance-libs/hooks/useAppModal';
import useOneToOneSessionDetails from '../../../../mindance-libs/hooks/useOneToOneSessionDetails';
import colors from '../../../../mindance-libs/theme/colors';
import {getCoachName} from '../../../../mindance-libs/utils/booking';
import {getImageUrl} from '../../../../mindance-libs/utils/files';
import {RouteKeys} from '../../../../navigation/RouteKeys';
import useTypedNavigate from '../../../../navigation/typed/useTypedNavigate';
import useTypedParams from '../../../../navigation/typed/useTypedParams';
import useTypedSearchParams from '../../../../navigation/typed/useTypedSearchParams';
import styles from './page.module.scss';
import {COACH_HEIGHT, COACH_WIDTH, imageStyle} from './page.options';

export default function Page() {
  const {t} = useTranslation();

  const {id} = useTypedParams<RouteKeys.OneToOneSessionDetails>();
  const [{language}] = useTypedSearchParams<RouteKeys.OneToOneSessionDetails>();

  const navigate = useTypedNavigate();

  const {
    loading,
    error,
    Venues,
    coach,
    BookingLink,
    Infos,
    oneToOneSessionsTranslations,
  } = useOneToOneSessionDetails({id: id ? Number(id) : undefined, language});

  const {isModalVisible, hideModal, showModal} = useAppModal();

  const coachImage = getImageUrl(coach?.directus_files, {
    height: COACH_HEIGHT,
    width: COACH_WIDTH,
  });

  const duration = oneToOneSessionsTranslations?.Duration;

  const title =
    coach?.Firstname || coach?.Lastname
      ? t('booking.ONE_TO_ONE_SESSION') +
        ' ' +
        t('common.with') +
        '\n' +
        getCoachName(coach?.Firstname, coach?.Lastname)
      : undefined;

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const bookSession = useCallback(() => {
    if (BookingLink) {
      window.open(BookingLink, '_blank');
    }
  }, [BookingLink]);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay />;
  }

  return (
    <ScreenBase>
      <div className={styles['one-to-one-session__container']}>
        <div className={styles['one-to-one-session__header']}>
          <RoundBackButton
            size={40}
            borderColor={colors.whiteAlpha50}
            iconColor={colors.white}
            iconSize={30}
            onPress={goBack}
          />

          <div className={styles['one-to-one-session__header__coach-wrapper']}>
            <img
              className={
                styles['one-to-one-session__header__coach-wrapper__image']
              }
              src={coachImage}
              style={imageStyle}
            />
            <div
              className={
                styles['one-to-one-session__header__coach-wrapper__info']
              }>
              {duration ? (
                <div className={styles['one-to-one-session__header__duration']}>
                  <p>{duration}</p>
                </div>
              ) : null}

              <div
                className={
                  styles[
                    'one-to-one-session__header__coach-wrapper__info__subheader'
                  ]
                }>
                {title ? <h1>{title}</h1> : <div />}
                <ImportantInfo onPress={showModal} />
              </div>
              <LinearSeparator />

              <BookingMetaInfo
                venue={Venues}
                cost={oneToOneSessionsTranslations?.Cost}
                className={
                  styles[
                    'one-to-one-session__header__coach-wrapper__info__subheader__meta'
                  ]
                }
              />
            </div>
          </div>

          <LinearSeparator />
        </div>
        <div className={styles['one-to-one-session__content']}>
          <CoachDetailsContent {...coach} />
        </div>

        <LinearSeparator />
        <footer className={styles['one-to-one-session__footer']}>
          <BookingFooter showLeft={false} onButtonPress={bookSession} />
        </footer>
      </div>

      <AppModal
        isVisible={isModalVisible}
        onDismiss={hideModal}
        title={t('common.info')}>
        <BookingInfoContent info={Infos} />
      </AppModal>
    </ScreenBase>
  );
}
