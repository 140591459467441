import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import ErrorOverlay from '../../../components/ErrorOverlay/ErrorOverlay';
import ItemsList from '../../../components/ItemsList/ItemsList';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay';
import useElementSize from '../../../hooks/useElementSize';
import BookingCard from '../../../mindance-libs/components/BookingCard';
import ExtendedFilterButton from '../../../mindance-libs/components/ExtendedFilterButton';
import FiltersBar from '../../../mindance-libs/components/FiltersBar';
import OneToOneCoachingBookingCard from '../../../mindance-libs/components/OneToOneCoachingBookingCard';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import UserTagsModal from '../../../mindance-libs/components/UserTagsModal';
import {
  BaseBookingFragment,
  Expertlines,
} from '../../../mindance-libs/generated/graphql';
import useBookingScreen from '../../../mindance-libs/hooks/useBookingScreen';
import {BookingItemType} from '../../../mindance-libs/types/booking';
import {
  getOneToOnceCoachingLanguageParam,
  isEventItem,
  isOneToOneSessionItem,
} from '../../../mindance-libs/utils/booking';
import {RouteKeys} from '../../../navigation/RouteKeys';
import useTypedNavigate from '../../../navigation/typed/useTypedNavigate';
import useTypedSearchParams from '../../../navigation/typed/useTypedSearchParams';
import {generateUrlWithParams} from '../../../navigation/utils';
import styles from './page.module.scss';

export default function Page() {
  const {t, i18n} = useTranslation();

  const [searchParams, setSearchParams] =
    useTypedSearchParams<RouteKeys.Booking>();

  const navigate = useTypedNavigate();

  const openExpertline = useCallback(() => {
    navigate({to: RouteKeys.Expertline, params: {}});
  }, [navigate]);

  const updateSearchParams = useCallback<
    (category?: string, data?: BaseBookingFragment[]) => void
  >(
    category => {
      setSearchParams({
        selectedCategory: category as BookingItemType | undefined,
      });
    },
    [setSearchParams],
  );

  const {
    bookingData,
    categories,
    tagsFilters,
    selectedTags,
    selectedTagsRecord,
    isModalVisible,
    closeModal,
    showModal,
    error,
    loading,
    loadingMore,
    selectTag,
    selectAllTags,
    deselectAllTags,
    fetchNext,
    submitTags,
    switchCategory,
    ExpertlineElement,
  } = useBookingScreen({
    onUpdateCategory: updateSearchParams,
    selectedCategory: searchParams?.selectedCategory,
    onExpertlinePress: openExpertline,
  });

  const {
    onLoad: onContentContainerLoad,
    ref: contentContainerRef,
    size: contentContainerSize,
  } = useElementSize<HTMLDivElement>();

  const renderItem = useCallback<
    (item: BaseBookingFragment, index: number) => JSX.Element | null
  >(
    item => {
      if (isEventItem(item)) {
        return (
          <BookingCard
            {...item}
            key={item.__typename + item.id}
            Label={item?.Label || item?.Infos?.Infos_translations?.[0]?.Label}
            width={contentContainerSize.width}
            href={generateUrlWithParams(
              RouteKeys.EventDetails,
              {id: item.id},
              {
                companyId: item.companyId,
                courseId: item.courseId,
                language: item.Language,
                serviceId: item.serviceId,
              },
            )}
          />
        );
      }

      if (isOneToOneSessionItem(item)) {
        const language = getOneToOnceCoachingLanguageParam(
          i18n.language,
          item.OneToOneSessions_languages,
        );

        return item.Coaches ? (
          <OneToOneCoachingBookingCard
            {...item}
            key={item.__typename + item.id}
            width={contentContainerSize.width}
            href={generateUrlWithParams(
              RouteKeys.OneToOneSessionDetails,
              {id: item.id},
              {language},
            )}
          />
        ) : null;
      }

      return null;
    },
    [contentContainerSize.width, i18n.language],
  );

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay />;
  }

  return (
    <ScreenBase>
      <div className={styles.booking__container}>
        <div className={styles.booking__header}>
          <h1 className={styles.booking__header__title}>
            {t('screenTitle.BookingScreen')}
          </h1>
          <div className={styles['booking__header__modes-bar']}>
            <div className={styles['booking__header__modes-bar__filters-bar']}>
              <FiltersBar data={categories} onItemPress={switchCategory} />
            </div>
            <ExtendedFilterButton
              selectedFiltersNumber={selectedTags.length}
              onPress={showModal}
              iconPosition="right"
            />
          </div>
        </div>

        <div
          className={styles.booking__content}
          ref={contentContainerRef}
          onLoad={onContentContainerLoad}>
          <ItemsList
            className={styles.booking__content__items}
            data={bookingData}
            loading={loading}
            loadingMore={loadingMore}
            renderItem={renderItem}
            emptyLabel={t('common.empty_libs')}
            error={error}
            onEndReached={fetchNext}
            ListHeader={ExpertlineElement}
          />
        </div>
      </div>

      <UserTagsModal
        isVisible={isModalVisible}
        onDismiss={closeModal}
        tagGroups={tagsFilters}
        selectedTagsRecord={selectedTagsRecord}
        onTagSelect={selectTag}
        onSubmit={submitTags}
        tagSelectionRequired={false}
        onDeselectAll={deselectAllTags}
        onSelectAll={selectAllTags}
      />
    </ScreenBase>
  );
}
