import {Platform, StyleSheet} from 'react-native';
import spacings from '../../../theme/spacings';
import {
  MESSAGE_IMAGE_SPACE,
  PSYCHOLOGIST_IMAGE_SIZE,
} from '../../PsychologistMessage/styles';

const BUTTON_TOP_SPACE = Platform.select({
  default: spacings.xxxxl,
  web: spacings.xxl,
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  space: {
    flex: 1,
  },
  content: {
    paddingHorizontal: spacings.s,
    paddingVertical: spacings.l,
  },
  message: {
    marginTop: spacings.l,
  },
  buttonWrapper: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
  },
  button: {
    marginTop: BUTTON_TOP_SPACE,
    marginHorizontal: spacings.xxxxxxl,
    marginBottom: spacings.xl,
    alignSelf: 'center',
  },
  topBlur: {
    height: 1.5 * BUTTON_TOP_SPACE,
  },
  blurBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },
  categoriesWrapper: {
    paddingLeft: PSYCHOLOGIST_IMAGE_SIZE + MESSAGE_IMAGE_SPACE,
  },
  category: {
    marginTop: spacings.m,
  },
});

export default styles;
