import {useFormik} from 'formik';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import ControlBottomButtons from '../../../../components/ControlBottomButtons/ControlBottomButtons';
import AppInput from '../../../../mindance-libs/components/AppInput';
import ContactUs, {
  TitleVariants,
} from '../../../../mindance-libs/components/ContactUs';
import ScreenBase from '../../../../mindance-libs/components/ScreenBase';
import useErrorMessage from '../../../../mindance-libs/hooks/useErrorMessage';
import useInputFormikHandlers from '../../../../mindance-libs/hooks/useInputFormikHandlers';
import MindanceBigLogo from '../../../../mindance-libs/icons/MindanceBigLogo';
import {useAuthContext} from '../../../../mindance-libs/store/contexts/AuthContext';
import {forgetPasswordEmailFormValidationSchema} from '../../../../mindance-libs/utils/formValidations';
import {reportError} from '../../../../mindance-libs/utils/loggingHelpers';
import {RouteKeys} from '../../../../navigation/RouteKeys';
import useTypedNavigate from '../../../../navigation/typed/useTypedNavigate';
import styles from '../page.module.scss';

type FormTypes = {
  email: string;
};

const INITIAL_VALUES: FormTypes = {
  email: '',
};

export default function Page() {
  const {t} = useTranslation();

  const {showMessage: showErrorMessage, error: errorMessage} =
    useErrorMessage();

  const {resendVerificationToken} = useAuthContext({onError: showErrorMessage});

  const navigate = useTypedNavigate();

  const submit = useCallback(
    async ({email}: FormTypes) => {
      if (!email) {
        return;
      }

      try {
        const result = await resendVerificationToken(email);

        if (result.success) {
          navigate({to: RouteKeys.EmailConfirmationSuccess, params: {}});
        }
      } catch (e) {
        reportError('submit resendVerificationToken error', e);
      }
    },
    [navigate, resendVerificationToken],
  );

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    validationSchema: forgetPasswordEmailFormValidationSchema,
    validateOnMount: true,
    initialValues: INITIAL_VALUES,
    onSubmit: submit,
  });

  const {
    handleValueBlur: handleEmailBlur,
    hasError: hasEmailError,
    setValue: setEmail,
  } = useInputFormikHandlers<FormTypes>('email', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError: showErrorMessage,
  });

  return (
    <ScreenBase error={errorMessage}>
      <div className={styles.email_confirmation__container}>
        <div className={styles.email_confirmation__content}>
          <div className={styles.email_confirmation__logo}>
            <MindanceBigLogo />
          </div>
          <div className={styles.email_confirmation__space} />
          <div className={styles.email_confirmation__title}>
            {t('auth.enter_email')}
          </div>
          <div className={styles.email_confirmation__description}>
            {t('auth.enter_email_desc')}
          </div>
          <div className={styles.email_confirmation__space} />
          <AppInput
            label={t('auth.email')}
            value={values.email}
            onChangeText={setEmail}
            onBlur={handleEmailBlur}
            autoCapitalize="none"
            keyboardType="email-address"
            error={hasEmailError}
            testID="requestEmailConfirmation.emailInput"
          />
          <div className={styles.email_confirmation__space} />
          <div className={styles.email_confirmation__button}>
            <ControlBottomButtons
              showNextIcon
              showReturnButton={false}
              onSubmit={handleSubmit}
              submitButtonLabel={t('common.confirm')}
              submitButtonDisabled={!isValid || isSubmitting}
            />
          </div>
          <div className={styles.email_confirmation__space} />

          <div className={styles.email_confirmation__contactUs}>
            <ContactUs variant={TitleVariants.YOU_NEED_HELP} />
          </div>
        </div>
      </div>
    </ScreenBase>
  );
}
