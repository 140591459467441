import {ErrorMessages} from '../types/error';

export const isErrorMessage = (message: any): message is ErrorMessages => {
  return Object.values(ErrorMessages).includes(message);
};

export const errorMessagesMapper: Record<ErrorMessages, string> = {
  [ErrorMessages.ACTIVATION_CODE_DOES_NOT_EXISTS]:
    'errors.activation_code_is_not_valid',
  [ErrorMessages.ACTIVATION_FROM_DOES_NOT_MATCH]:
    'errors.activation_code_is_not_valid',
  [ErrorMessages.ACTIVATION_UNTIL_DOES_NOT_MATCH]:
    'errors.activation_code_is_not_valid',
  [ErrorMessages.EXCEED_LIMIT_OF_AMOUNT_ACTIVATIONS]:
    'errors.activation_code_is_not_valid',
  [ErrorMessages.ALREADY_SUBSCRIBED]: 'errors.activation_code_already_applied',
  [ErrorMessages.BAD_REQUEST]: 'errors.something_went_wrong',
  [ErrorMessages.FORBIDDEN]: 'errors.something_went_wrong',
  [ErrorMessages.NOT_FOUND]: 'errors.something_went_wrong',
  [ErrorMessages.SORRY_SOMETHING_WENT_WRONG]: 'errors.something_went_wrong',
};
