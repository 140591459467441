import {BaseProgressReportFragment} from '../generated/graphql';
import {ProgressReportTypes} from '../types/progressReport';
import * as R from 'ramda';

type Props = {
  sessionIds?: string[];
  progressReports?: BaseProgressReportFragment[] | null;
};

type Result = {
  current: number;
  total: number;
  difference: number;
};

export const getCurrentProgress = ({
  sessionIds,
  progressReports,
}: Props): Result => {
  const numberOfSessions = sessionIds?.length ?? 0;

  if (!progressReports || !sessionIds) {
    return {
      current: 0,
      total: numberOfSessions,
      difference: 0,
    };
  }

  const sessionsProgress = sessionIds.reduce<Record<string, boolean>>(
    (acc, sessionId) => {
      if (!sessionId) {
        return acc;
      }

      const completed = !!progressReports?.find(
        ({Session, Label: label}) =>
          String(Session) === sessionId &&
          label === ProgressReportTypes.FINISHED_SESSION,
      );

      return {...acc, [sessionId]: completed};
    },
    {},
  );

  const numberOfProgressReports = R.filter(
    R.equals(true),
    Object.values(sessionsProgress),
  ).length;

  const diff = Number((numberOfProgressReports / numberOfSessions).toFixed(1));

  return {
    current: numberOfProgressReports,
    total: numberOfSessions,
    difference: diff,
  };
};
