import * as R from 'ramda';
import {SelectedTagsRecord} from '../types/common';
import {RegistrationSlides} from '../types/registration';

export const REGISTRATION_SLIDES = [
  RegistrationSlides.PRIVACY,
  RegistrationSlides.PERSONAL_INFO,
  RegistrationSlides.CREDENTIALS,
];

export const getIsButtonDisabled = (selectedTags: SelectedTagsRecord) =>
  R.isEmpty(selectedTags)
    ? true
    : R.all(R.equals(false), R.values(selectedTags));

export const getDisplayedInitialState = (keys: string[]) =>
  keys.reduce<Record<string, boolean>>(
    (acc, key) => ({...acc, [key]: false}),
    {},
  );
