import React from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet} from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import {getSidebarDataLang} from '../../components/Sidebar/Sidebar.data';
import styles from './layout.module.scss';

export default function SidebarLayout() {
  const {i18n} = useTranslation();

  return (
    <div className={styles.container}>
      <Sidebar {...getSidebarDataLang(i18n.language)} />
      <Outlet />
    </div>
  );
}
