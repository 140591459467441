import {faHeart} from '@fortawesome/free-regular-svg-icons';
import {faPen, faThumbtack} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import formatDate from 'date-fns/format';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import useElementSize from '../../hooks/useElementSize';
// import useCompanyConfig from '../../mindance-libs/hooks/useCompanyConfig';
import {getImageUrl} from '../../mindance-libs/utils/files';
import {getLanguageAsset} from '../../mindance-libs/utils/i18next/helpers';
import Button from '../Button/Button';
import {AnchorTargetAttribute} from '../Button/Button.props';
// import Image from '../Image/Image';
import useDangerouslySetInnerHtml from '../../hooks/useDangerouslySetInnerHtml';
import useCompanyConfig from '../../mindance-libs/hooks/useCompanyConfig';
import colors from '../../mindance-libs/theme/colors';
import StatusInfo from '../StatusInfo/StatusInfo';
import styles from './NewsItem.module.scss';
import NewsItemProps from './NewsItem.props';

// const AUTHOR_AVATAR_SIZE = 50;
const DEFAULT_IMAGE_RATIO = 1;
const MIN_IMAGE_SIZE = 100;

const NewsItem: React.FC<NewsItemProps> = ({
  detailsHref,
  className,
  isPinned,
  content,
  imageContent,
  title,
  firstName,
  lastName,
  enabledLocales,
  disabledLocales,
  timestamp,
  likesCount,
  subscriptionsLabels,
}) => {
  const {t} = useTranslation();
  // const {getLogoUrl} = useCompanyConfig();

  const {highlightColor} = useCompanyConfig();

  const dangerouslySetInnerHTML = useDangerouslySetInnerHtml(content ?? '');

  const pinColor = highlightColor ?? colors.backgroundPrimary;

  const containerStyle = useMemo<React.CSSProperties>(
    () => ({borderColor: pinColor}),
    [pinColor],
  );

  const {
    ref: imageContainerRef,
    size: imageContainerSize,
    onLoad: onImageContainerLoad,
  } = useElementSize<HTMLImageElement>();

  // const authorLogo = getLogoUrl({
  //   width: AUTHOR_AVATAR_SIZE,
  //   height: AUTHOR_AVATAR_SIZE,
  // });

  const imageSize =
    imageContainerSize.width > MIN_IMAGE_SIZE
      ? imageContainerSize.width
      : MIN_IMAGE_SIZE;

  const contentImage = getImageUrl(imageContent, {
    width: imageSize,
    aspectRatio: DEFAULT_IMAGE_RATIO,
  });

  const subscriptionsLabel =
    !subscriptionsLabels || subscriptionsLabels.length < 1
      ? null
      : subscriptionsLabels.length > 1
      ? t('mindManagerHome.locations', {value: subscriptionsLabels.length})
      : subscriptionsLabels[0];

  return (
    <div
      className={classNames(
        styles['news-item__container'],
        {[styles['news-item__container--pinned']]: isPinned},
        className,
      )}
      style={containerStyle}>
      {isPinned && (
        <FontAwesomeIcon
          className={styles['news-item__pin']}
          color={pinColor}
          icon={faThumbtack}
        />
      )}
      <div className={styles['news-item__header']}>
        <div className={styles['news-item__author']}>
          {/* <Image
            src={authorLogo}
            height={AUTHOR_AVATAR_SIZE}
            width={AUTHOR_AVATAR_SIZE}
            alt={t('mindManagerNewstool.author') ?? undefined}
            className={styles['news-item__author-avatar']}
          /> */}

          <p className={styles['news-item__author-name']}>
            {firstName} {lastName}
          </p>
        </div>

        <Button
          className={styles['news-item__edit-button']}
          anchor={{
            href: detailsHref,
            title: t('mindManagerNewstool.title') ?? '',
            target: AnchorTargetAttribute.SELF,
          }}>
          <FontAwesomeIcon
            className={styles['news-item__edit-icon']}
            icon={faPen}
          />
        </Button>
      </div>
      <div className={styles['news-item__body']}>
        <div className={styles['news-item__text']}>
          {title && <p className={styles['news-item__text-title']}>{title}</p>}
          {content && (
            <div
              className={styles['news-item__text-content']}
              dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            />
          )}
        </div>

        {contentImage ? (
          <img
            ref={imageContainerRef}
            onLoad={onImageContainerLoad}
            className={styles['news-item__image-content']}
            src={contentImage}
            alt={t('mindManagerNewstool.content') ?? undefined}
          />
        ) : null}
      </div>
      <div className={styles['news-item__footer']}>
        <div className={styles['news-item__likes']}>
          <FontAwesomeIcon
            className={styles['news-item__likes-icon']}
            icon={faHeart}
          />
          <p className={styles['news-item__likes-count']}>{likesCount ?? 0}</p>
        </div>
        <div className={styles['news-item__info']}>
          {subscriptionsLabel ? <p>{subscriptionsLabel}</p> : null}
          {enabledLocales && enabledLocales.length > 0 && (
            <div className={styles['news-item__locales']}>
              {enabledLocales.map(locale => (
                <img
                  key={locale}
                  src={getLanguageAsset(locale) as string}
                  className={styles['news-item__locales-item']}
                  alt={t('mindManagerNewstool.language') ?? undefined}
                />
              ))}
              <StatusInfo status="online" />
            </div>
          )}

          {disabledLocales && disabledLocales.length > 0 && (
            <div className={styles['news-item__locales']}>
              {disabledLocales.map(locale => (
                <img
                  key={locale}
                  src={getLanguageAsset(locale) as string}
                  className={styles['news-item__locales-item']}
                  alt={t('mindManagerNewstool.language') ?? undefined}
                />
              ))}
              <StatusInfo status="offline" />
            </div>
          )}
          {timestamp ? (
            <p className={styles['news-item__date']}>
              {t('mindManagerNewstool.time', {
                time: formatDate(new Date(timestamp), 'dd.MM.yy, H:mm'),
              })}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NewsItem;
