import {faHeart} from '@fortawesome/free-regular-svg-icons';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import format from 'date-fns/format';
import * as R from 'ramda';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import AlertModal from '../../../../components/AlertModal/AlertModal';
import Button from '../../../../components/Button/Button';
import {ButtonStyle} from '../../../../components/Button/Button.props';
import ErrorOverlay from '../../../../components/ErrorOverlay/ErrorOverlay';
import HTMLEditor from '../../../../components/HTMLEditor/HTMLEditor';
import ImageUploader from '../../../../components/ImageUploader/ImageUploader';
import Input from '../../../../components/Input/Input';
import LoadingOverlay from '../../../../components/LoadingOverlay/LoadingOverlay';
import PinButton from '../../../../components/PinButton/PinButton';
import StatusInfoSwitcher from '../../../../components/StatusInfoSwitcher/StatusInfoSwitcher';
import SubscriptionsSharedAlertModal from '../../../../components/SubscriptionsSharedAlertModal/SubscriptionsSharedAlertModal';
import useBlockerAlert from '../../../../hooks/useBlockerAlert';
import useNewsDetails from '../../../../hooks/useNewsDetails';
import useUploadFile from '../../../../hooks/useUploadFile';
import RoundBackButton from '../../../../mindance-libs/components/RoundBackButton';
import ScreenBase from '../../../../mindance-libs/components/ScreenBase';
import useAppModal from '../../../../mindance-libs/hooks/useAppModal';
import useErrorMessage from '../../../../mindance-libs/hooks/useErrorMessage';
import GermanyIcon from '../../../../mindance-libs/icons/GermanyIcon';
import UnitedStatesIcon from '../../../../mindance-libs/icons/UnitedStatesIcon';
import colors from '../../../../mindance-libs/theme/colors';
import {Languages} from '../../../../mindance-libs/types/common';
import {RouteKeys} from '../../../../navigation/RouteKeys';
import useTypedNavigate from '../../../../navigation/typed/useTypedNavigate';
import useTypedParams from '../../../../navigation/typed/useTypedParams';
import useTypedSearchParams from '../../../../navigation/typed/useTypedSearchParams';
import {NEWS_LANGUAGES} from './page.data';
import styles from './page.module.scss';

const LanguageIcon = {
  [Languages.GERMAN]: <GermanyIcon width="100%" height="100%" />,
  [Languages.ENGLISH]: <UnitedStatesIcon width="100%" height="100%" />,
};

const AngleDownIcon = (
  <FontAwesomeIcon icon={faAngleDown} color={colors.white} />
);

export default function Page() {
  const {t} = useTranslation();

  const navigate = useTypedNavigate();
  const location = useLocation();
  const {id} = useTypedParams<RouteKeys.NewstoolDetails>();

  const [searchParams, setSearchParams] =
    useTypedSearchParams<RouteKeys.NewstoolDetails>();

  const createMode = !!searchParams.create;
  const editMode = searchParams.edit ? searchParams.edit : createMode;

  const {error: errorMessage, hideMessage, showMessage} = useErrorMessage();

  const uploadFile = useUploadFile({
    onError: showMessage,
    onResetError: hideMessage,
  });

  const navigateToCreatedNews = useCallback<(id: string) => void>(
    newsId => {
      navigate(
        {to: RouteKeys.NewstoolDetails, params: {id: newsId}},
        {
          replace: true,
          state: {origin: location.pathname},
        },
      );
    },
    [location.pathname, navigate],
  );

  const disableEditMode = useCallback<() => void>(() => {
    setSearchParams({edit: false});
  }, [setSearchParams]);

  const {
    data,
    inputValue,
    loading,
    error,
    editorName,
    isDataChanged,
    changePinnedState,
    changeInputValues,
    changeEnabledStatus,
    createOneNews,
    updateOneNews,
    fileState,
    removeSelectedFile,
    selectLocalFile,
    SubscriptionModal,
    showSubscriptionModal,
    subscriptionsRecord,
    selectedSubscriptionIds,
  } = useNewsDetails(id, editMode, {
    onError: showMessage,
    onResetError: hideMessage,
    onFileUpload: uploadFile,
    onCreatedOneNews: navigateToCreatedNews,
    onUpdatedOneNews: disableEditMode,
  });

  const [editorsFocused, setEditorsFocused] = useState<
    Record<Languages, boolean>
  >({
    [Languages.ENGLISH]: false,
    [Languages.GERMAN]: false,
  });

  const focusEditor = useCallback<(language: Languages) => () => void>(
    language => () => {
      setEditorsFocused(R.assoc(language, true));
    },
    [],
  );

  const blurEditor = useCallback<(language: Languages) => () => void>(
    language => () => {
      setEditorsFocused(R.assoc(language, false));
    },
    [],
  );

  const enableEditMode = useCallback(() => {
    setSearchParams({edit: true});
  }, [setSearchParams]);

  const goBack = useCallback(() => {
    navigate({to: RouteKeys.Newstool, params: {}});
  }, [navigate]);

  const onSaveData = useCallback(async () => {
    if (createMode) {
      await createOneNews();
      return;
    }

    if (editMode) {
      await updateOneNews();
      return;
    }
  }, [createMode, createOneNews, editMode, updateOneNews]);

  const {
    hideModal: closeSaveDataAlertModal,
    isModalVisible: isSaveDataAlertModalVisible,
    runAction: saveData,
    proceedAlert: proceedSaveDataAlert,
  } = useBlockerAlert({
    isBlocked: editMode && isDataChanged,
    onAction: onSaveData,
  });

  const {
    hideModal: closeSubscriptionsAlert,
    isModalVisible: isSubscriptionsAlertVisible,
    showModal: showSubscriptionsAlert,
  } = useAppModal();

  const saveButtonProps = useMemo(
    () => ({
      onClick: editMode
        ? selectedSubscriptionIds.length > 1
          ? () => showSubscriptionsAlert()
          : saveData
        : enableEditMode,
    }),
    [
      editMode,
      enableEditMode,
      saveData,
      selectedSubscriptionIds.length,
      showSubscriptionsAlert,
    ],
  );

  const subscriptionsButtonProps = useMemo(
    () => ({
      onClick: () => showSubscriptionModal(),
    }),
    [showSubscriptionModal],
  );

  const subscriptionButtonLabel =
    selectedSubscriptionIds.length > 1
      ? t('mindManagerHome.locations', {value: selectedSubscriptionIds.length})
      : selectedSubscriptionIds.length === 1
      ? subscriptionsRecord?.[selectedSubscriptionIds?.[0]]?.Label ?? ''
      : '';

  const editData = data?.date_updated ?? data?.date_created;
  const editedAt = editData
    ? format(new Date(editData), 'dd.MM.yyyy, HH:mm')
    : undefined;

  const inputFieldStyle = useMemo(
    () => ({
      backgroundColor: !editMode ? colors.whiteAlpha13 : undefined,
      borderColor: !editMode ? colors.transparent : undefined,
    }),
    [editMode],
  );

  if (
    (!id && !createMode) ||
    (!error && !loading && id && data === undefined)
  ) {
    navigate({to: RouteKeys.Newstool, params: {}}, {replace: true});
  }

  if (error) {
    return <ErrorOverlay />;
  }

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <ScreenBase error={errorMessage}>
      <AlertModal
        isOpened={isSaveDataAlertModalVisible}
        closeModal={closeSaveDataAlertModal}
        title={t('mindManagerNewstool.you_have_unsaved_changes')}
        description={t('mindManagerNewstool.discard_or_proceed')}
        onConfirm={saveData}
        onDecline={proceedSaveDataAlert}
      />
      {SubscriptionModal}
      <SubscriptionsSharedAlertModal
        isOpened={isSubscriptionsAlertVisible}
        closeModal={closeSubscriptionsAlert}
        onConfirm={saveData}
        onDecline={closeSubscriptionsAlert}
        locationsNumber={selectedSubscriptionIds.length}
      />

      <div className={styles['news-edit']}>
        <div className={styles['news-edit__header']}>
          <div className={styles['news-edit__header__back-button']}>
            <RoundBackButton onPress={goBack} />
          </div>

          <div
            className={styles['news-edit__header__image-uploader__container']}>
            <ImageUploader
              className={styles['news-edit__header__image-uploader']}
              editMode={editMode}
              fileState={fileState}
              onFileRemove={removeSelectedFile}
              onFileSelect={selectLocalFile}
            />
          </div>
        </div>

        <div className={styles['news-edit__input-fields']}>
          {NEWS_LANGUAGES.map((language, index) => {
            return (
              <div
                className={styles['news-edit__input-fields__container']}
                key={language}>
                <div
                  className={
                    styles['news-edit__input-fields__title-container']
                  }>
                  <Input
                    readOnly={!editMode}
                    value={inputValue[language].title}
                    label={t('common.title')}
                    showLabel
                    placeholder={t('common.title')}
                    onChangeText={changeInputValues(language, 'title')}
                    style={inputFieldStyle}
                    inputClass={styles['news-edit__input-fields__title']}
                  />
                  <div
                    className={
                      styles['news-edit__input-fields__language-icon']
                    }>
                    {LanguageIcon[language]}
                  </div>
                </div>
                <HTMLEditor
                  id={`news-edit_${language}`}
                  value={inputValue[language].description}
                  onValueChange={changeInputValues(language, 'description')}
                  isFocused={editorsFocused[language]}
                  onFocus={focusEditor(language)}
                  onBlur={blurEditor(language)}
                  placeholder={t('common.text')}
                  readOnly={!editMode}
                  {...inputFieldStyle}
                />
                <div className={styles['news-edit__input-fields__bottom']}>
                  <div className={styles['news-edit__input-fields__likes']}>
                    {index === 0 && !createMode ? (
                      <>
                        <FontAwesomeIcon
                          className={
                            styles['news-edit__input-fields__likes__icon']
                          }
                          icon={faHeart}
                        />
                        <p
                          className={
                            styles['news-edit__input-fields__likes__count']
                          }>
                          {data._count?.NewsLikes ?? 0}
                        </p>
                      </>
                    ) : null}
                  </div>
                  <StatusInfoSwitcher
                    currentStatus={inputValue[language].enabled ?? false}
                    onToggle={
                      editMode ? changeEnabledStatus(language) : undefined
                    }
                    readOnly={!editMode}
                    className={styles['news-edit__input-fields__switcher']}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <div className={styles['news-edit__footer']}>
          <div
            className={classNames(
              styles['news-edit__footer__wrapper'],
              styles['news-edit__footer__wrapper--left'],
            )}>
            <div className={styles['news-edit__footer__edited-info']}>
              {editorName ? (
                <p className={styles['news-edit__footer__edited-info__editor']}>
                  {t('mindManagerNewstool.last_edited_by', {
                    name: editorName,
                  })}
                </p>
              ) : null}
              {editedAt ? (
                <p className={styles['news-edit__footer__edited-info__time']}>
                  {t('mindManagerNewstool.time', {time: editedAt})}
                </p>
              ) : null}
            </div>
          </div>

          <div
            className={classNames(
              styles['news-edit__footer__wrapper'],
              styles['news-edit__footer__wrapper--right'],
            )}>
            <Button
              rightIcon={AngleDownIcon}
              button={subscriptionsButtonProps}
              disabled={!editMode}
              className={styles['news-edit__footer__subscriptions-button']}>
              <p
                className={
                  styles['news-edit__footer__subscriptions-button__label']
                }>
                {subscriptionButtonLabel}
              </p>
            </Button>
            <PinButton
              isPinned={data?.Pinned ?? false}
              onClick={changePinnedState}
              className={styles['news-edit__footer__pin-button']}
            />
            <Button
              button={saveButtonProps}
              buttonStyle={ButtonStyle.SECONDARY}
              className={classNames(
                styles['news-edit__footer__submit-button'],
                {
                  [styles['news-edit__footer__submit-button--edit']]: !editMode,
                },
              )}>
              {t(editMode ? 'common.save' : 'common.edit')}
            </Button>
          </div>
        </div>
      </div>
    </ScreenBase>
  );
}
