import React from 'react';
import GbuPsycheHomeCardProps, {
  GbuPsycheHomeCardType,
} from './GbuPsycheHomeCard.props';
import styles from './GbuPsycheHomeCard.module.scss';
import classNames from 'classnames';
import {
  IconDefinition,
  faArrowTrendUp,
  faBolt,
} from '@fortawesome/free-solid-svg-icons';
import {getGbuPsycheHomeCardDataLang} from './GbuPsycheHomeCard.data';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import colors from '../../../mindance-libs/theme/colors';

// TODO: change according to the data
const Icons: Record<GbuPsycheHomeCardType, IconDefinition> = {
  [GbuPsycheHomeCardType.LEADERSHIP]: faArrowTrendUp,
  [GbuPsycheHomeCardType.WORKING_ENVIRONMENT]: faBolt,
};

const iconsColor: Record<GbuPsycheHomeCardType, string> = {
  [GbuPsycheHomeCardType.LEADERSHIP]: colors.darkOrangeAlpha60,
  [GbuPsycheHomeCardType.WORKING_ENVIRONMENT]: colors.persianPlumAlpha60,
};

const GbuPsycheHomeCard: React.FC<GbuPsycheHomeCardProps> = ({
  className,
  testID,
}) => {
  const {t, i18n} = useTranslation();

  const cardsData = getGbuPsycheHomeCardDataLang(i18n.language);

  // TODO: change with real data
  const calculations: Record<GbuPsycheHomeCardType, string> = {
    [GbuPsycheHomeCardType.LEADERSHIP]: '+18%',
    [GbuPsycheHomeCardType.WORKING_ENVIRONMENT]: '+54%',
  };

  // TODO: change with real data
  const riskFactors: Record<GbuPsycheHomeCardType, string> = {
    [GbuPsycheHomeCardType.LEADERSHIP]: '2/8',
    [GbuPsycheHomeCardType.WORKING_ENVIRONMENT]: '4/6',
  };

  const mergedStyle = classNames(styles['gbu-psyche-home-card'], className);

  return (
    <div className={mergedStyle} data-testid={testID}>
      {cardsData.map(({label, title}) => (
        <div className={styles['gbu-psyche-home-card__container']} key={label}>
          <div className={styles['gbu-psyche-home-card__container__box']}>
            <FontAwesomeIcon
              icon={Icons[label]}
              color={iconsColor[label]}
              className={styles['gbu-psyche-home-card__container__box__icon']}
            />
            <span>{calculations[label]}</span>
          </div>

          <div className={styles['gbu-psyche-home-card__container__text']}>
            <p
              className={
                styles['gbu-psyche-home-card__container__text__title']
              }>
              {title}
            </p>
            <p
              className={
                styles['gbu-psyche-home-card__container__text__subtitle']
              }>
              {riskFactors[label]} {t('mindManagerHome.risk_factors')}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GbuPsycheHomeCard;
