import {StatusFilterOptions} from '../../../components/StatusFilter/StatusFilter.props';
import {MindManagerNewsDetailsFragment} from '../../../mindance-libs/generated/graphql';

export const getFilteredDataByStatus = (
  data: MindManagerNewsDetailsFragment[],
  selectedFilter: StatusFilterOptions,
) => {
  if (selectedFilter === StatusFilterOptions.ONLINE) {
    return data.filter(({News_translations}) =>
      News_translations?.some(({Enabled}) => !!Enabled),
    );
  }

  if (selectedFilter === StatusFilterOptions.OFFLINE) {
    return data.filter(
      ({News_translations}) =>
        !News_translations?.some(({Enabled}) => !!Enabled),
    );
  }

  return data;
};
