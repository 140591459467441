import {motion, Transition} from 'framer-motion';
import React, {PropsWithChildren, useMemo} from 'react';
import {Props} from './props';

const variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const LayoutAnimationWrapper: React.FC<PropsWithChildren<Props>> = ({
  children,
  duration,
  initial,
  animate,
  exit,
}) => {
  const transition = useMemo<Transition>(
    () => ({layout: {duration}}),
    [duration],
  );

  return (
    <motion.div
      layout
      variants={variants}
      transition={transition}
      initial={initial}
      animate={animate}
      exit={exit}>
      {children}
    </motion.div>
  );
};

export default React.memo(LayoutAnimationWrapper);
