import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
import colors from '../theme/colors';

const AlertIcon: React.FC<SvgProps> = ({...props}) => {
  return (
    <Svg
      fill={colors.alert}
      width="20px"
      height="20px"
      viewBox="-1.7 0 20.4 20.4"
      {...props}>
      <Path d="M16.406 10.283a7.917 7.917 0 1 1-7.917-7.917 7.916 7.916 0 0 1 7.917 7.917zM9.48 14.367a1.003 1.003 0 1 0-1.004 1.003 1.003 1.003 0 0 0 1.004-1.003zM7.697 11.53a.792.792 0 0 0 1.583 0V5.262a.792.792 0 0 0-1.583 0z" />
    </Svg>
  );
};

export default React.memo(AlertIcon);
