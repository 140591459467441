import React, {forwardRef, useCallback, useImperativeHandle} from 'react';
import {useTranslation} from 'react-i18next';
import {ListRenderItem} from 'react-native';
import {
  BaseBookingFragment,
  useFindManyBookingsV3Query,
} from '../../generated/graphql';
import useBookingQueryVariables from '../../hooks/useBookingQueryVariables';
import useListQuery from '../../hooks/useListQuery';
import {BookingItemType} from '../../types/booking';
import {
  HOME_SCREEN_LIST_ITEMS_LIMIT,
  HOME_SCREEN_LIST_ITEMS_TAKE_NUMBER,
  SCREEN_WIDTH,
} from '../../utils/constants';
import {getImageUrl} from '../../utils/files';
import GroupCoachingHomeCard from '../GroupCoachingHomeCard';
import HomeCardsCarousel, {HomeCardsCarouselProps} from '../HomeCardsCarousel';
import styles from './styles';

type Props = Partial<HomeCardsCarouselProps<BaseBookingFragment>> & {
  onItemPress: (itemId: BaseBookingFragment['id'], language: string) => void;
};

export type GroupCoachingHomeSectionRef = {
  refresh: () => Promise<void>;
};

const IMAGE_RATIO = 1.65;

const GroupCoachingHomeSection = forwardRef<GroupCoachingHomeSectionRef, Props>(
  (
    {testID, onItemPress, itemWidth = SCREEN_WIDTH * 0.75, tagsIds, ...props},
    ref,
  ) => {
    const {t} = useTranslation();

    const getBookingQueryVariables = useBookingQueryVariables({
      selectedCategory: BookingItemType.GROUP_COACHING,
      selectedTags: tagsIds,
    });

    const {data, loading, error, fetchNext, refetchWithParams} = useListQuery(
      useFindManyBookingsV3Query,
      getBookingQueryVariables,
      'findManyBookingsV3',
      {
        take: HOME_SCREEN_LIST_ITEMS_TAKE_NUMBER,
        limit: HOME_SCREEN_LIST_ITEMS_LIMIT,
      },
    );

    const renderItem = useCallback<ListRenderItem<BaseBookingFragment>>(
      ({item}) => (
        <GroupCoachingHomeCard
          {...item}
          onPress={onItemPress}
          imageUrl={getImageUrl(item.directus_files, {
            width: itemWidth,
            aspectRatio: IMAGE_RATIO,
          })}
          width={itemWidth}
          aspectRatio={IMAGE_RATIO}
          testID={`${testID}.groupCoachingHomeCard`}
        />
      ),
      [itemWidth, onItemPress, testID],
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          async refresh() {
            await refetchWithParams({force_refresh: true});
          },
        };
      },
      [refetchWithParams],
    );

    return (
      <HomeCardsCarousel
        {...props}
        title={t('home.group_coaching_for_you')}
        subtitle={t('home.professional_coaching')}
        testID={testID}
        data={data}
        loading={loading}
        error={error}
        renderItem={renderItem}
        itemWidth={itemWidth}
        itemAspectRatio={IMAGE_RATIO}
        footerItemStyle={styles.item}
        emptyLabel={t('home.all_your_events_are_done')}
        onEndReached={fetchNext}
      />
    );
  },
);

export default React.memo(GroupCoachingHomeSection);
