import Lottie from 'lottie-react-native';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import AppText from '../../../../mindance-libs/components/AppText';
import ControlBottomButtons from '../../../../mindance-libs/components/ControlBottomButtons';
import ScreenBase from '../../../../mindance-libs/components/ScreenBase';
import useErrorMessage from '../../../../mindance-libs/hooks/useErrorMessage';
import useVerifiedLogin from '../../../../mindance-libs/hooks/useVerifiedLogin';
import {useAuthContext} from '../../../../mindance-libs/store/contexts/AuthContext';
import {RouteKeys} from '../../../../navigation/RouteKeys';
import useTypedLocation from '../../../../navigation/typed/useTypedLocation';
import useTypedNavigate from '../../../../navigation/typed/useTypedNavigate';
import styles from '../page.module.scss';
import RNstyles from './styles';

export default function Page() {
  const {error, showMessage} = useErrorMessage();

  const {state} = useTypedLocation<RouteKeys.RegistrationEmailVerification>();
  const navigate = useTypedNavigate();

  const [isResent, setIsResent] = useState(false);

  const {t} = useTranslation();

  const handleError = useCallback<(error: string) => void>(
    e => {
      setIsResent(false);
      showMessage(e);
    },
    [showMessage],
  );

  const {isLoading, isLoggedIn, login, resendVerificationToken} =
    useAuthContext({onError: handleError});

  const resendVerification = useCallback(async () => {
    setIsResent(true);
    await resendVerificationToken();
  }, [resendVerificationToken]);

  useVerifiedLogin({isLoading: isLoading, isLoggedIn, login});

  const goBack = useCallback(() => {
    navigate(
      {to: RouteKeys.Registration, params: {}, queryParams: state.queryParams},
      {replace: true, state},
    );
  }, [navigate, state]);

  return (
    <ScreenBase error={error}>
      <div className={styles.registration__container}>
        <View style={RNstyles.container}>
          <ScrollView contentContainerStyle={RNstyles.container}>
            <View style={RNstyles.space2} />
            <Lottie
              source={require('../../../../mindance-libs/assets/animations/sent_email_animation.json')}
              autoPlay
              loop
              style={RNstyles.animation}
            />
            <View style={RNstyles.space1} />
            <AppText style={RNstyles.title}>
              {t('auth.great_almost_there')}
            </AppText>
            <AppText style={RNstyles.description}>
              {t('auth.we_have_sent_confirmation_code')}
            </AppText>
            <View style={RNstyles.space2} />

            <ControlBottomButtons
              style={RNstyles.buttonsWrapper}
              onSubmit={resendVerification}
              onReturn={goBack}
              submitButtonDisabled={isResent}
              returnButtonLabel={t('auth.use_another_email')}
              submitButtonLabel={t('auth.request_link_again_button')}
            />
          </ScrollView>
        </View>
      </div>
    </ScreenBase>
  );
}
