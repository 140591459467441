import storage from '../../utils/storage';

class AppUsageStore {
  constructor(private appUsageKey: string) {}

  public getAppStartedTimestamp = async () => {
    const savedAppStartedTimestamp = await storage.getItem(this.appUsageKey);

    if (!savedAppStartedTimestamp) {
      return undefined;
    }

    return savedAppStartedTimestamp;
  };

  public setAppStartedTimestamp = async () => {
    await storage.setItem(this.appUsageKey, new Date().toISOString());
  };
}

const storageKey = 'APP_USAGE';
const appUsageStore = new AppUsageStore(storageKey);

export default appUsageStore;
