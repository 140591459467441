import {useParams} from 'react-router-dom';
import {PathParams} from '../NavigationTypes';
import {RouteKeys} from '../RouteKeys';

/**
 * Type-safe version of `react-router-dom/useParams`.
 .
 * @returns parameter object if route matches.
 */
const useTypedParams = <P extends RouteKeys>() => {
  const params = useParams<PathParams<P>>();

  return params;
};

export default useTypedParams;
