import {StyleSheet} from 'react-native';
import colors from '../../../theme/colors';
import spacings from '../../../theme/spacings';
import typography from '../../../theme/typography';

const LOTTIE_FILE_SIZE = 160;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flexGrow: 1,
    paddingHorizontal: spacings.xxxxxl,
  },
  animationLoadingWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  animation: {
    width: LOTTIE_FILE_SIZE,
    height: LOTTIE_FILE_SIZE,
  },
  percentsText: {
    position: 'absolute',
    top: LOTTIE_FILE_SIZE / 2 - (typography.subtitle.lineHeight ?? 24) / 2,
    ...typography.subtitle,
    textAlign: 'center',
    color: colors.white,
  },
  title: {
    ...typography.title1,
    textAlign: 'center',
    marginTop: spacings.xl,
  },
  top: {
    height: '35%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottom: {
    flex: 0.6,
    paddingHorizontal: spacings.xxl,
  },
  listWrapper: {
    marginTop: spacings.xxxxl,
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: spacings.l,
  },
  listItemText: {
    marginLeft: spacings.l,
    ...typography.input,
  },
});

export default styles;
