import classNames from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getStatusInfoType} from './StatusInfo.data';
import styles from './StatusInfo.module.scss';
import StatusInfoProps, {StatusInfoType} from './StatusInfo.props';

const StatusInfo: React.FC<StatusInfoProps> = ({status, className, testID}) => {
  const {t} = useTranslation();

  const statusType = getStatusInfoType(status);

  const mergedStyle = classNames(styles['status-info'], className, {
    [styles['status-info--offline']]: statusType === StatusInfoType.OFFLINE,
    [styles['status-info--online']]: statusType === StatusInfoType.ONLINE,
  });

  return (
    <div className={mergedStyle} data-testid={testID}>
      <p>{t(`mindManagerNewstool.${statusType}`)}</p>
    </div>
  );
};

export default StatusInfo;
