import {useFocusEffect} from '@react-navigation/native';
import * as R from 'ramda';
import React, {forwardRef, useCallback, useImperativeHandle} from 'react';
import {useTranslation} from 'react-i18next';
import {FlatListProps, ListRenderItem} from 'react-native';
import NavigationKeys from '../../../navigation/NavigationKeys';
import {
  BaseSessionFragment,
  FindManySessionsQueryVariables,
  useFindManySessionsQuery,
} from '../../generated/graphql';
import useListQuery from '../../hooks/useListQuery';
import useProgressReports from '../../hooks/useProgressReports';
import {useAppContext} from '../../store/contexts/AppContext';
import {ProgressReportTypes} from '../../types/progressReport';
import {
  HOME_SCREEN_LIST_ITEMS_LIMIT,
  HOME_SCREEN_LIST_ITEMS_TAKE_NUMBER,
  SCREEN_WIDTH,
} from '../../utils/constants';
import {getProgressReportCondition} from '../../utils/courses';
import {getImageUrl} from '../../utils/files';
import {getStringRoundedMinutes} from '../../utils/time';
import CourseHomeCard from '../CourseHomeCard';
import HomeCardsCarousel, {HomeCardsCarouselProps} from '../HomeCardsCarousel';
import styles from './styles';

type Props = Partial<HomeCardsCarouselProps<BaseSessionFragment>> & {
  onItemPress: (itemId: BaseSessionFragment['id']) => void;
};

export type SessionsHomeSectionRef = {
  refresh: () => Promise<void>;
};

const IMAGE_RATIO = 0.82;

const keyExtractor: FlatListProps<BaseSessionFragment>['keyExtractor'] = item =>
  item.id;

const SessionsHomeSection = forwardRef<SessionsHomeSectionRef, Props>(
  (
    {testID, onItemPress, itemWidth = SCREEN_WIDTH * 0.5, tagsIds, ...props},
    ref,
  ) => {
    const {t, i18n} = useTranslation();
    const {user} = useAppContext();
    const {reportOpenSession} = useProgressReports();

    const getQueryVariables = useCallback<
      (
        input?: Partial<FindManySessionsQueryVariables>,
      ) => FindManySessionsQueryVariables
    >(
      (input = {}) => {
        const baseVariables: FindManySessionsQueryVariables = {
          language: i18n.language,
          where: {
            ...(tagsIds && tagsIds?.length > 0
              ? {Sessions_Tags: {some: {Tags_id: {in: tagsIds}}}}
              : {}),
            OR: [
              ...getProgressReportCondition({
                every: {
                  NOT: [
                    {
                      AND: [
                        {Label: {equals: ProgressReportTypes.FINISHED_SESSION}},
                        {User: {equals: Number(user?.id ?? 0)}},
                      ],
                    },
                  ],
                },
              }),
            ],
          },
        };

        return R.mergeDeepLeft(
          baseVariables,
          input,
        ) as FindManySessionsQueryVariables;
      },
      [i18n.language, tagsIds, user?.id],
    );

    const {data, loading, error, refetch, fetchNext, refetchWithParams} =
      useListQuery(
        useFindManySessionsQuery,
        getQueryVariables,
        'findManySessions',
        {
          take: HOME_SCREEN_LIST_ITEMS_TAKE_NUMBER,
          limit: HOME_SCREEN_LIST_ITEMS_LIMIT,
        },
      );

    const renderItem = useCallback<ListRenderItem<BaseSessionFragment>>(
      ({item: {id, Label, Duration, directus_files}}) => {
        const handleOpenSession = () => {
          onItemPress(id);

          reportOpenSession({
            email: user?.EMail,
            screen: NavigationKeys.HomeScreen,
            sessionId: id,
          });
        };

        const timeRecommendation = Duration
          ? `${getStringRoundedMinutes(Duration)} ${t('time.minutes_short')}`
          : undefined;

        return (
          <CourseHomeCard
            title={Label}
            timeRecommendation={timeRecommendation}
            imageUrl={getImageUrl(directus_files, {
              width: itemWidth,
              aspectRatio: IMAGE_RATIO,
            })}
            onPress={handleOpenSession}
            width={itemWidth}
            aspectRatio={IMAGE_RATIO}
            testID={`${testID}.sessionHomeCard`}
          />
        );
      },
      [itemWidth, onItemPress, reportOpenSession, t, testID, user?.EMail],
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          async refresh() {
            await refetchWithParams({force_refresh: true});
          },
        };
      },
      [refetchWithParams],
    );

    useFocusEffect(
      useCallback(() => {
        const completedSession = data?.find(
          ({ProgressReports, id}) =>
            !!ProgressReports?.find(
              ({Session, Label}) =>
                Session === Number(id) &&
                Label === ProgressReportTypes.FINISHED_SESSION,
            ),
        );

        if (completedSession) {
          refetch();
        }
      }, [data, refetch]),
    );

    return (
      <HomeCardsCarousel
        {...props}
        title={t('home.exercises_and_quick_wins')}
        subtitle={t('home.for_in_between')}
        itemWidth={itemWidth}
        itemAspectRatio={IMAGE_RATIO}
        testID={testID}
        data={data}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        footerItemStyle={styles.item}
        error={error}
        loading={loading}
        emptyLabel={t('home.all_your_sessions_are_done')}
        onEndReached={fetchNext}
      />
    );
  },
);

export default React.memo(SessionsHomeSection);
