import {LottieRefCurrentProps} from 'lottie-react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {reportError} from '../utils/loggingHelpers';

export type VideoOrientation = 'landscape' | 'portrait';

const useWebAVStatus = (options?: {
  isAudioUnitType?: boolean;
  src?: string | null;
  onPlay?: () => void;
  onPause?: () => void;
  onUpdateProgress?: () => void;
  onFinish?: () => void;
}) => {
  const isAudioUnitType = options?.isAudioUnitType ?? true;

  const [isPlayable, setIsPlayable] = useState(true);
  const [durationMillis, setDurationMillis] = useState(0);
  const [positionMillis, setPositionMillis] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);

  const audioAnimation = useRef<LottieRefCurrentProps>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const avRefCurrent = isAudioUnitType ? audioRef.current : videoRef.current;

  const videoOrientation: VideoOrientation | undefined = isAudioUnitType
    ? undefined
    : !videoRef.current?.videoHeight || !videoRef.current?.videoWidth
    ? undefined
    : videoRef.current.videoHeight > videoRef.current.videoWidth
    ? 'portrait'
    : 'landscape';

  const updateMetadata = useCallback(() => {
    const duration = avRefCurrent?.duration;

    if (duration) {
      setDurationMillis(duration * 1000);
    }
  }, [avRefCurrent?.duration]);

  const updateProgress = useCallback(() => {
    const position = avRefCurrent?.currentTime;

    if (position) {
      setPositionMillis(position * 1000);
    }
  }, [avRefCurrent?.currentTime]);

  const handlePlayEvent = useCallback(() => {
    setPlaying(true);
  }, []);

  const handlePauseEvent = useCallback(() => {
    setPlaying(false);
  }, []);

  const playAV = useCallback(() => {
    if (isPlayable) {
      avRefCurrent?.play();
      options?.onPlay?.();
    }
  }, [avRefCurrent, isPlayable, options]);

  const pauseAV = useCallback(() => {
    if (isPlayable) {
      avRefCurrent?.pause();
      options?.onPause?.();
    }
  }, [avRefCurrent, isPlayable, options]);

  const updatePositionMillis = useCallback<(value: number) => void>(
    value => {
      if (avRefCurrent && isPlayable) {
        avRefCurrent.currentTime = value / 1000;
        options?.onUpdateProgress?.();
      }
    },
    [avRefCurrent, isPlayable, options],
  );

  const handleAVError = useCallback(() => {
    reportError('could not load source', options?.src);
    setIsPlayable(false);
  }, [options?.src]);

  const loadingStart = useCallback(() => {
    setLoading(true);
  }, []);

  const loadingEnd = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!isAudioUnitType) {
      return;
    }

    playing ? audioAnimation.current?.play() : audioAnimation.current?.pause();
  }, [isAudioUnitType, playing]);

  return useMemo(
    () => ({
      audioRef,
      videoRef,
      audioAnimation,
      durationMillis,
      positionMillis,
      playing,
      loading,
      videoOrientation,

      handlePauseEvent,
      handlePlayEvent,

      updateMetadata,
      updateProgress,
      playAV,
      pauseAV,
      updatePositionMillis,
      handleAVError,

      loadingStart,
      loadingEnd,
    }),
    [
      durationMillis,
      positionMillis,
      playing,
      loading,
      videoOrientation,
      handlePauseEvent,
      handlePlayEvent,
      updateMetadata,
      updateProgress,
      playAV,
      pauseAV,
      updatePositionMillis,
      handleAVError,
      loadingStart,
      loadingEnd,
    ],
  );
};

export default useWebAVStatus;
