import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

const NEXT_BUTTON_SIZE = 76;
const WEB_ANIMATION_SIZE = 200;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  animation: {
    width: '100%',
    height: '100%',
  },
  animationWeb: {
    aspectRatio: 1,
    height: WEB_ANIMATION_SIZE,
  },
  label: {
    ...typography.title4,
    color: colors.whiteAlpha95,
    marginBottom: spacings.m,
  },
  content: {
    flex: 1,
  },
  bottomContent: {
    flexGrow: 1,
  },
  bottomContentTop: {
    flex: 0.6,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: spacings.xl,
  },
  bottomContentBottom: {
    flex: 0.4,
  },
  topContent: {
    justifyContent: 'flex-start',
  },
  centerContent: {
    justifyContent: 'center',
    flexGrow: 1,
  },
  nextButtonWrapper: {
    marginTop: spacings.m,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  nextButton: {
    width: NEXT_BUTTON_SIZE,
    height: NEXT_BUTTON_SIZE,
    borderRadius: NEXT_BUTTON_SIZE / 2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
});

export const htmlCustomStyle = {
  body: {
    color: colors.whiteAlpha75,
  },
};

export default styles;
