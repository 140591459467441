import React from 'react';
import SidebarModal from '../SidebarModal/SidebarModal';
import styles from './AlertModal.module.scss';
import AlertModalProps from './AlertModal.props';

const AlertModal: React.FC<AlertModalProps> = ({
  title,
  description,
  ...props
}) => {
  return (
    <SidebarModal className={styles['alert-modal']} {...props}>
      <div className={styles['alert-modal__content']}>
        {title ? <p className={styles['alert-modal__title']}>{title}</p> : null}
        {description ? (
          <p className={styles['alert-modal__description']}>{description}</p>
        ) : null}
      </div>
    </SidebarModal>
  );
};

export default React.memo(AlertModal);
