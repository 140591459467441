import {StyleSheet} from 'react-native';
import typography from '../../theme/typography';
import spacings from '../../theme/spacings';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  title: {
    ...typography.body,
    marginBottom: spacings.xl,
    textAlign: 'center',
  },
  link: {
    textDecorationLine: 'underline',
  },
});

export default styles;
