export enum ProgressReportTypes {
  FINISHED_UNIT = 'FINISHED_UNIT',
  FINISHED_SESSION = 'FINISHED_SESSION',
  FINISHED_COURSE = 'FINISHED_COURSE',

  OPEN_SESSION = 'OPEN_SESSION',
  OPEN_COURSE = 'OPEN_COURSE',
  OPEN_SCREEN = 'OPEN_SCREEN',

  APP_USAGE = 'APP_USAGE',

  NOTIFICATIONS_PERMISSION_GRANTED = 'NOTIFICATIONS_PERMISSION_GRANTED',
  NOTIFICATIONS_PERMISSION_DENIED = 'NOTIFICATIONS_PERMISSION_DENIED',
}
