import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  container: {
    borderColor: colors.blueGreenGray,
    borderWidth: 1,
  },
  content: {
    padding: 0,
    paddingVertical: 0,
    paddingHorizontal: 0,
    gap: spacings.m,
  },
  label: {
    marginHorizontal: spacings.s,
    marginVertical: spacings.s,
    ...typography.input,
    lineHeight: 20,
  },
  selectedLabel: {
    ...typography.inputDemiBold,
    lineHeight: 20,
    letterSpacing: 0.12,
  },
});

export default styles;
