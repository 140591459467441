import {ApolloError} from '@apollo/client';
import {FormikHelpers} from 'formik';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  useBookingOrderMutation,
  useUpdateUserDataMutation,
} from '../generated/graphql';
import {BookingForm} from '../types/booking';
import {trimObjectValues} from '../utils/common';
import {reportError} from '../utils/loggingHelpers';

const useBookingFormRequests = ({
  eventId,
  note,
  onError,
  onBookingOrderCompleted,
}: {
  eventId: string | undefined;
  note: string | undefined;
  onError: (message: string) => void;
  onBookingOrderCompleted: () => void;
}) => {
  const {t, i18n} = useTranslation();

  const updateRequestError = useCallback<(error: ApolloError) => void>(
    apolloError => {
      reportError('submit booking order', apolloError);
      onError(t('errors.something_went_wrong'));
    },
    [onError, t],
  );

  const [updateUserData] = useUpdateUserDataMutation({
    refetchQueries: ['profile'],
    onError: updateRequestError,
  });

  const [submitBooking, {loading}] = useBookingOrderMutation({
    onCompleted: onBookingOrderCompleted,
    refetchQueries: ['profile'],
    onError: updateRequestError,
  });

  const saveData = useCallback<
    (
      values: BookingForm,
      formikHelpers: FormikHelpers<BookingForm>,
    ) => Promise<void>
  >(async () => {
    if (!eventId) {
      return;
    }

    await submitBooking({
      variables: {
        data: {
          eventId: Number(eventId),
          language: i18n.language,
          message: note && note.length > 0 ? note : undefined,
        },
      },
    });
  }, [eventId, i18n.language, note, submitBooking]);

  const applyChanges = useCallback<(values: BookingForm) => Promise<void>>(
    async values => {
      if (
        values.city &&
        values.phone &&
        values.department &&
        values.employer &&
        values.postCode &&
        values.personalStatus
      ) {
        await updateUserData({
          variables: {
            data: {
              ...trimObjectValues(values),
              personalStatus: Number(values.personalStatus),
            },
          },
        });
      }
    },
    [updateUserData],
  );

  return useMemo(
    () => ({
      loading,
      saveData,
      applyChanges,
    }),
    [applyChanges, loading, saveData],
  );
};

export default useBookingFormRequests;
