import {useFormik} from 'formik';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import {ButtonStyle} from '../../../../../components/Button/Button.props';
import ControlBottomButtons from '../../../../../components/ControlBottomButtons/ControlBottomButtons';
import Input from '../../../../../components/Input/Input';
import ScreenBase from '../../../../../mindance-libs/components/ScreenBase';
import useErrorMessage from '../../../../../mindance-libs/hooks/useErrorMessage';
import useInputFormikHandlers from '../../../../../mindance-libs/hooks/useInputFormikHandlers';
import {useAuthContext} from '../../../../../mindance-libs/store/contexts/AuthContext';
import {changeCurrentPasswordFormValidationSchema} from '../../../../../mindance-libs/utils/formValidations';
import useTypedNavigate from '../../../../../navigation/typed/useTypedNavigate';
import {ChangePasswordData, INITIAL_FORM_VALUES} from './page.data';
import styles from './page.module.scss';

export default function Page() {
  const {t} = useTranslation();
  const {showMessage, error} = useErrorMessage();
  const navigate = useTypedNavigate();

  const {changePassword} = useAuthContext();

  const submitForm = useCallback<(values: ChangePasswordData) => Promise<void>>(
    async ({currentPassword, password}) => {
      const result = await changePassword(currentPassword, password);
      if (result.success) {
        navigate(-1);
        return;
      }
      if (result.message) {
        showMessage(result.message);
      }
    },
    [changePassword, navigate, showMessage],
  );

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    validationSchema: changeCurrentPasswordFormValidationSchema,
    validateOnMount: true,
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: submitForm,
  });

  const {
    handleValueBlur: handleCurrentPasswordBlur,
    hasError: hasCurrentPasswordError,
    setValue: setCurrentPassword,
  } = useInputFormikHandlers<ChangePasswordData>('currentPassword', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError: showMessage,
  });

  const {
    handleValueBlur: handleNewPasswordBlur,
    hasError: hasNewPasswordError,
    setValue: setNewPassword,
  } = useInputFormikHandlers<ChangePasswordData>('password', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError: showMessage,
  });

  const {
    handleValueBlur: handleConfirmNewPasswordBlur,
    hasError: hasConfirmNewPasswordError,
    setValue: setConfirmNewPassword,
  } = useInputFormikHandlers<ChangePasswordData>('confirmPassword', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError: showMessage,
  });

  const backButton = useMemo(
    () => ({title: t('common.return') || '', onClick: () => navigate(-1)}),
    [navigate, t],
  );

  return (
    <ScreenBase error={error}>
      <div className={styles.password__container}>
        <Button
          showLeftArrow
          button={backButton}
          className={styles.password__button}
          buttonStyle={ButtonStyle.INLINE_WHITE}
        />
        <h1>{t('auth.change_password')}</h1>

        <div className={styles.password__wrapper}>
          <Input
            className={styles['password__input-wrapper']}
            inputClass={styles['password__input-field']}
            isSecure
            placeholder={t('settings.current_password')}
            value={values.currentPassword}
            onChangeText={setCurrentPassword}
            onBlur={handleCurrentPasswordBlur}
            hasError={hasCurrentPasswordError}
            error={errors?.currentPassword}
          />
          <Input
            className={styles['password__input-wrapper']}
            inputClass={styles['password__input-field']}
            placeholder={t('settings.new_password')}
            isSecure
            value={values.password}
            onChangeText={setNewPassword}
            onBlur={handleNewPasswordBlur}
            hasError={hasNewPasswordError}
            error={errors?.password}
          />
          <Input
            className={styles['password__input-wrapper']}
            inputClass={styles['password__input-field']}
            placeholder={t('settings.confirm_new_password')}
            isSecure
            value={values.confirmPassword}
            onChangeText={setConfirmNewPassword}
            onBlur={handleConfirmNewPasswordBlur}
            hasError={hasConfirmNewPasswordError}
            error={errors?.confirmPassword}
          />
        </div>
        <div className={styles.password__bottom}>
          <ControlBottomButtons
            submitButtonLabel={t('common.confirm')}
            onSubmit={handleSubmit}
            showReturnButton={false}
            submitButtonDisabled={!isValid || isSubmitting}
            returnButtonTestID="changePassword.returnButton"
            submitButtonTestID="changePassword.submitButton"
            submitButtonLoading={isSubmitting}
          />
        </div>
      </div>
    </ScreenBase>
  );
}
