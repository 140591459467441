import React, {useMemo} from 'react';
import {StyleProp, TouchableHighlight, ViewStyle} from 'react-native';
import CloseIcon from '../../icons/CloseIcon';
import colors from '../../theme/colors';
import styles from './styles';

type Props = {
  size?: number;
  iconSize?: number;
  borderColor?: string;
  iconColor?: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

const RoundCloseButton: React.FC<Props> = ({
  iconSize = 9,
  size = 36,
  borderColor = colors.blueGreenGray,
  iconColor = colors.white,
  onPress,
  style,
  testID,
}) => {
  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [
      styles.container,
      {width: size, height: size, borderRadius: size / 2, borderColor},
      style,
    ],
    [borderColor, size, style],
  );

  return (
    <TouchableHighlight
      style={containerStyle}
      onPress={onPress}
      underlayColor={colors.whiteAlpha20}
      testID={testID}>
      <CloseIcon width={iconSize} height={iconSize} fill={iconColor} />
    </TouchableHighlight>
  );
};

export default React.memo(RoundCloseButton);
