import {faArrowUpRightFromSquare} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import {TouchableHighlightProps} from 'react-native';
import RNMeinFSConnection from '../../mindance-libs/components/MeinFSConnection';
import colors from '../../mindance-libs/theme/colors';
import styles from './MeinFSConnection.module.scss';

const MeinFSConnection: React.FC<
  TouchableHighlightProps & {onPress?: () => void; className?: string}
> = ({className, onPress, ...props}) => {
  const mergedStyle = classNames(styles['mein-fs-connection'], className);

  return (
    <div className={mergedStyle}>
      <RNMeinFSConnection onPress={onPress} {...props} />
      <div className={styles['mein-fs-connection__link']} onClick={onPress}>
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} color={colors.white} />
      </div>
    </div>
  );
};

export default React.memo(MeinFSConnection);
