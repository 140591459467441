import {useCallback, useEffect, useMemo} from 'react';
import {useBlocker} from 'react-router-dom';
import useAppModal from '../mindance-libs/hooks/useAppModal';

const useBlockerAlert = ({
  isBlocked,
  onAction,
}: {
  isBlocked: boolean;
  onAction: () => Promise<void>;
}) => {
  const {hideModal, showModal, isModalVisible} = useAppModal();

  const blocker = useBlocker(
    ({currentLocation, nextLocation}) =>
      isBlocked &&
      currentLocation.pathname !== nextLocation.pathname &&
      currentLocation.pathname !== nextLocation.state?.origin,
  );

  const runAction = useCallback(async () => {
    blocker.reset?.();
    await onAction();
  }, [blocker, onAction]);

  const proceedAlert = useCallback(() => {
    blocker.proceed?.();
  }, [blocker]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      showModal();
      return;
    }
  }, [blocker, showModal]);

  return useMemo(
    () => ({
      hideModal,
      isModalVisible,
      proceedAlert,
      runAction,
    }),
    [hideModal, isModalVisible, proceedAlert, runAction],
  );
};

export default useBlockerAlert;
