import * as yup from 'yup';
import {Gender} from '../types/user';

export const EMAIL_REGEX =
  /^[a-zA-Z0-9_.+-]+(?!\.\.)@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,4})+$/;
const emailValidation = (options?: {
  wrongEmailMessage?: string;
  requiredMessage?: string;
}) =>
  yup
    .string()
    .trim()
    .matches(
      EMAIL_REGEX,
      options?.wrongEmailMessage ?? 'common.info_is_not_correct',
    )
    .required(options?.requiredMessage ?? 'auth.email_not_empty');

const commonPasswordValidation = (options?: {requiredMessage?: string}) =>
  yup
    .string()
    .min(8, 'auth.password_length')
    .max(32, 'auth.password_length')
    .required(options?.requiredMessage ?? 'auth.password_is_required');

export const firstNameValidation = yup
  .string()
  .trim()
  .max(255, 'errors.too_long')
  .required('auth.first_name_is_required');

export const lastNameValidation = yup
  .string()
  .trim()
  .max(255, 'errors.too_long')
  .required('auth.last_name_is_required');

export const genderValidation = yup
  .string()
  .oneOf(Object.values(Gender), 'auth.gender_is_invalid');

export const ageValidation = yup
  .number()
  .typeError('common.info_is_not_correct')
  .min(0, 'common.value_not_valid')
  .max(120, 'common.value_not_valid');

export const loginFormValidationSchema = yup.object().shape({
  email: emailValidation({requiredMessage: 'auth.all_fields_required'}),
  password: commonPasswordValidation({
    requiredMessage: 'auth.all_fields_required',
  }),
});

export const forgetPasswordEmailFormValidationSchema = yup.object().shape({
  email: emailValidation(),
});

export const authPersonalInfoFormValidationSchema = yup.object().shape({
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  gender: genderValidation,
  age: ageValidation,
});

export const authCredentialsFormValidationSchema = yup.object().shape({
  email: emailValidation({requiredMessage: 'auth.all_fields_required'}),
  activationCode: yup.string().trim(),
  password: commonPasswordValidation({
    requiredMessage: 'auth.all_fields_required',
  })
    .matches(/[a-z]/, 'auth.password_contain_uppercase')
    .matches(/[A-Z]/, 'auth.password_contain_lowercase')
    .test('no-email-match', 'auth.password_not_match_email', function (value) {
      const email = this.parent.email;
      return email ? value.toLowerCase() !== email.toLowerCase() : true;
    }),
  confirmPassword: yup
    .string()
    .required('auth.all_fields_required')
    .oneOf([yup.ref('password')], 'auth.passwords_must_match'),
});

export const redeemActivationCodeFormValidationSchema = yup.object().shape({
  activationCode: yup.string().trim(),
});

export const changePasswordFormValidationSchema = yup.object().shape({
  password: commonPasswordValidation({
    requiredMessage: 'auth.all_fields_required',
  })
    .matches(/[a-z]/, 'auth.password_contain_uppercase')
    .matches(/[A-Z]/, 'auth.password_contain_lowercase'),
  confirmPassword: yup
    .string()
    .required('auth.password_is_required')
    .oneOf([yup.ref('password')], 'auth.passwords_must_match'),
});

export const firstNameFormValidationSchema = yup.object().shape({
  firstName: firstNameValidation,
});

export const lastNameFormValidationSchema = yup.object().shape({
  lastName: lastNameValidation,
});

export const changeCurrentPasswordFormValidationSchema = yup.object().shape({
  currentPassword: commonPasswordValidation({
    requiredMessage: 'auth.all_fields_required',
  }),
  password: commonPasswordValidation({
    requiredMessage: 'auth.all_fields_required',
  })
    .matches(/[a-z]/, 'auth.password_contain_uppercase')
    .matches(/[A-Z]/, 'auth.password_contain_lowercase'),
  confirmPassword: yup
    .string()
    .required('auth.password_is_required')
    .oneOf([yup.ref('password')], 'auth.passwords_must_match'),
});

export const bookingFormValidationSchema = yup.object().shape({
  postCode: yup
    .string()
    .matches(/^\d{5}$/, 'auth.all_fields_required')
    .required('auth.all_fields_required'),
  city: yup
    .string()
    .matches(
      /^[a-zA-Z\u0080-\u024F]+(?:([ |-|']|(.| ))[a-zA-Z\u0080-\u024F]+)*$/,
      'auth.all_fields_required',
    )
    .min(2, 'auth.all_fields_required')
    .required('auth.all_fields_required'),
  phone: yup
    .string()
    .matches(/^\+?[0-9]+(?:-[0-9]+)*$/, 'auth.all_fields_required')
    .min(4)
    .max(15, 'errors.too_long')
    .required('auth.all_fields_required'),
  department: yup
    .string()
    .trim()
    .max(255, 'errors.too_long')
    .required('auth.all_fields_required'),
  employer: yup
    .string()
    .trim()
    .max(255, 'errors.too_long')
    .required('auth.all_fields_required'),
  personalStatus: yup.number().required('auth.all_fields_required'),
});

export const noteFormValidationSchema = yup.object().shape({
  note: yup.string().trim().max(255, 'errors.too_long'),
});
