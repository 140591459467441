import {useMemo} from 'react';
import {GeneralSetting} from '../generated/graphql';
import {useAppContext} from '../store/contexts/AppContext';
import {getExpertlines} from '../utils/generalSettings';

const useExpertlines = () => {
  const {bookingScreenSetup, screenSetupLoading, screenSetupError} =
    useAppContext();

  const expertlines = useMemo(
    () =>
      getExpertlines(
        bookingScreenSetup?.GeneralSetting_Booking_GeneralSettingToGeneralSetting as
          | GeneralSetting
          | null
          | undefined,
      ),
    [bookingScreenSetup?.GeneralSetting_Booking_GeneralSettingToGeneralSetting],
  );

  return useMemo(
    () => ({
      data: expertlines,
      loading: screenSetupLoading,
      error: screenSetupError,
    }),
    [expertlines, screenSetupError, screenSetupLoading],
  );
};

export default useExpertlines;
