import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import typography from '../../theme/typography';
import spacings from '../../theme/spacings';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: spacings.xxxs,
    paddingHorizontal: spacings.xxs,
    borderRadius: spacings.xs,
    gap: spacings.xs,
  },
  label: {
    ...typography.input,
    color: colors.white,
  },
  icon: {
    marginTop: Platform.select({default: undefined, web: 2}),
  },
});

export default styles;
