import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  LayoutChangeEvent,
  StyleProp,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import {CoachDetailsFragment} from '../../generated/graphql';
import AppText from '../AppText';
import styles from './styles';

type Props = ViewProps &
  Partial<CoachDetailsFragment> & {
    onContentLayout?: (e: LayoutChangeEvent) => void;
    placeholderHeight?: number;
  };

const SEMICOLON_DELIMITER = ':';

const CoachDetailsContent: React.FC<Props> = ({
  Firstname,
  Lastname,
  Coaches_translations,
  style,
  placeholderHeight,
  onContentLayout,
  ...props
}) => {
  const {t} = useTranslation();

  const title =
    Firstname || Lastname
      ? t('booking.about_coach', {
          firstName: Firstname ?? '',
          lastName: Lastname ?? '',
        })
      : null;

  const {Motivation, Qualifications, Topics} = Coaches_translations?.[0] ?? {};

  const data = useMemo(
    () => [
      {label: Qualifications, title: t('booking.qualification')},
      {label: Topics, title: t('booking.focus')},
      {label: Motivation, title: t('booking.motivation')},
    ],
    [Motivation, Qualifications, Topics, t],
  );

  const PointItems = useMemo(
    () =>
      data.map(({title: pointTitle, label}) =>
        label ? (
          <View style={styles.pointWrapper} key={label}>
            <AppText style={styles.pointTitle}>
              {pointTitle}
              {SEMICOLON_DELIMITER}
            </AppText>
            <AppText style={styles.point}>{label}</AppText>
          </View>
        ) : null,
      ),
    [data],
  );

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, style],
    [style],
  );

  const placeholderStyle = useMemo<StyleProp<ViewStyle>>(
    () => ({
      height:
        placeholderHeight && placeholderHeight > 0 ? placeholderHeight : 0,
    }),
    [placeholderHeight],
  );

  return (
    <View style={containerStyle} {...props}>
      <View onLayout={onContentLayout}>
        {title ? <AppText style={styles.title}>{title}</AppText> : null}
        {PointItems}
      </View>
      <View style={placeholderStyle} />
    </View>
  );
};

export default React.memo(CoachDetailsContent);
