import React, {useMemo} from 'react';
import {TextUnitAlignment} from '../../types/courses';
import {GeneralUnitSlideProps} from './props';
import styles from './styles.web.module.scss';

const GeneralUnitSlide: React.FC<GeneralUnitSlideProps> = ({
  Label,
  Description,
  Alignment = TextUnitAlignment.TOP,
  labelTextAlign = 'left',
  descriptionTextAlign = 'left',
}) => {
  const contentClassNamePostfix =
    Alignment === TextUnitAlignment.CENTER
      ? 'center'
      : Alignment === TextUnitAlignment.BOTTOM
      ? 'bottom'
      : 'top';

  const labelStyle = useMemo<React.CSSProperties>(
    () => ({textAlign: labelTextAlign}),
    [labelTextAlign],
  );

  const descriptionStyle = useMemo<React.CSSProperties>(
    () => ({textAlign: descriptionTextAlign}),
    [descriptionTextAlign],
  );

  return (
    <div className={styles['general-units-slide']}>
      <div
        className={styles[`general-units-slide__${contentClassNamePostfix}`]}>
        {Label ? (
          <p
            className={styles['general-units-slide__label']}
            style={labelStyle}>
            {Label}
          </p>
        ) : null}
        {Description ? (
          <p
            className={styles['general-units-slide__description']}
            style={descriptionStyle}>
            {Description}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(GeneralUnitSlide);
