import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import {TextInput, View} from 'react-native';
import AppText from '../../AppText';
import styles from './styles';
import {Credentials, RegistrationSlideProps} from '../../../types/registration';
import {useLayout} from '@react-native-community/hooks';
import useBooleanValue from '../../../hooks/useBooleanValue';
import {getLanguage} from '../../../utils/i18next/helpers';
import {useFormik} from 'formik';
import useInputFormikHandlers from '../../../hooks/useInputFormikHandlers';
import {SafeAreaView} from 'react-native-safe-area-context';
import {authCredentialsFormValidationSchema} from '../../../utils/formValidations';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import AppInput from '../../AppInput';
import TermsAndConditionsAgreement from '../../TermsAndConditionsAgreement';
import ControlBottomButtons from '../../ControlBottomButtons';

type FormTypes = {
  email: string;
  activationCode?: string;
  password: string;
  confirmPassword: string;
};

type Props = RegistrationSlideProps & {
  initialValues?: FormTypes;
  onPrivacyPolicyPress: () => void;
  onTermsAndConditionsPress: () => void;
  onSubmitButtonPress: (values: Credentials) => void;
  isActivationCodeFieldDisplayed?: boolean;
};

const INITIAL_VALUES: FormTypes = {
  email: '',
  activationCode: '',
  password: '',
  confirmPassword: '',
};

const RegistrationCredentialsSlide: React.FC<Props> = ({
  initialValues,
  style,
  onBackButtonPress,
  onSubmitButtonPress,
  onPrivacyPolicyPress,
  onTermsAndConditionsPress,
  onError,
  isActivationCodeFieldDisplayed = true,
}) => {
  const {t, i18n} = useTranslation();

  const {height, onLayout} = useLayout();

  const {value: areTermsConfirmed, toggleValue: toggleAreTermsConfirmed} =
    useBooleanValue(initialValues?.password ? true : false);

  const {
    value: hasTermsConfirmedError,
    setFalse: removeTermsConfirmedError,
    setTrue: applyTermsConfirmedError,
  } = useBooleanValue(false);

  const contentStyle = useMemo(
    () => [styles.space, {minHeight: height}],
    [height],
  );

  const hideTermsConfirmedError = useCallback(() => {
    removeTermsConfirmedError();
  }, [removeTermsConfirmedError]);

  const submitForm = useCallback<(values: FormTypes) => Promise<void>>(
    async ({email, password, activationCode}) => {
      if (!areTermsConfirmed) {
        applyTermsConfirmedError();
        onError(t('auth.you_must_agree_terms'));
        return;
      }

      hideTermsConfirmedError();

      const data: Credentials = {
        email,
        password,
        activationCode,
        language: getLanguage(i18n.language),
      };

      onSubmitButtonPress(data);
    },
    [
      applyTermsConfirmedError,
      areTermsConfirmed,
      hideTermsConfirmedError,
      i18n.language,
      onError,
      onSubmitButtonPress,
      t,
    ],
  );

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    validationSchema: authCredentialsFormValidationSchema,
    validateOnMount: true,
    initialValues: initialValues ?? INITIAL_VALUES,
    onSubmit: submitForm,
  });

  const {
    handleValueBlur: handleEmailBlur,
    hasError: hasEmailError,
    setValue: setEmail,
  } = useInputFormikHandlers<FormTypes>('email', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError,
  });

  const {
    handleValueBlur: handleActivationCodeBlur,
    hasError: hasActivationCodeError,
    setValue: setActivationCode,
  } = useInputFormikHandlers<FormTypes>('activationCode', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError,
  });

  const {
    handleValueBlur: handlePasswordBlur,
    hasError: hasPasswordError,
    setValue: setPassword,
  } = useInputFormikHandlers<FormTypes>('password', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError,
  });

  const {
    handleValueBlur: handleConfirmPasswordBlur,
    hasError: hasConfirmPasswordError,
    setValue: setConfirmPassword,
  } = useInputFormikHandlers<FormTypes>('confirmPassword', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError,
  });

  const handleCheckboxPress = useCallback(() => {
    if (!hasTermsConfirmedError) {
      toggleAreTermsConfirmed();
      return;
    }

    if (!areTermsConfirmed) {
      hideTermsConfirmedError();
    }

    toggleAreTermsConfirmed();
  }, [
    areTermsConfirmed,
    hasTermsConfirmedError,
    hideTermsConfirmedError,
    toggleAreTermsConfirmed,
  ]);

  const containerStyle = useMemo(() => [styles.container, style], [style]);

  return (
    <View style={containerStyle}>
      <SafeAreaView style={styles.container}>
        <KeyboardAwareScrollView
          enableOnAndroid
          enableAutomaticScroll
          showsVerticalScrollIndicator={false}
          onLayout={onLayout}
          keyboardShouldPersistTaps="handled">
          <View style={contentStyle}>
            <AppText style={styles.headline}>{t('auth.step2')}</AppText>
            <AppText style={styles.title}>{t('auth.create_account')}</AppText>

            <View style={styles.placeholder} />

            <AppInput
              label={t('auth.email')}
              placeholder={t('auth.email_placeholder')}
              value={values.email}
              onChangeText={setEmail}
              onBlur={handleEmailBlur}
              autoCapitalize="none"
              autoCorrect={false}
              keyboardType="email-address"
              error={hasEmailError}
            />

            {isActivationCodeFieldDisplayed ? (
              <>
                <AppText style={styles.inputDescription}>
                  {t('auth.enter_activation_code')}
                </AppText>

                <AppInput
                  label={t('auth.activation_code')}
                  placeholder={t('auth.activation_code')}
                  value={values.activationCode}
                  onChangeText={setActivationCode}
                  onBlur={handleActivationCodeBlur}
                  autoCapitalize="none"
                  autoCorrect={false}
                  error={hasActivationCodeError}
                  style={styles.inputWithDescription}
                />
              </>
            ) : null}

            <View style={styles.placeholder} />

            <AppText style={styles.inputDescription}>
              {t('auth.password_requirements')}
            </AppText>

            <TextInput
              textContentType="username"
              style={styles.hiddenInput}
              pointerEvents="none"
              autoComplete="username"
              importantForAutofill="yes"
              value={values.email}
              onChangeText={setEmail}
            />

            <AppInput
              textContentType="newPassword"
              autoComplete="new-password"
              label={t('auth.password')}
              value={values.password}
              onChangeText={setPassword}
              onBlur={handlePasswordBlur}
              autoCapitalize="none"
              error={hasPasswordError}
              style={styles.inputWithDescription}
              isPassword
            />

            <AppInput
              textContentType="newPassword"
              autoComplete="new-password"
              label={t('auth.confirm_password')}
              value={values.confirmPassword}
              onChangeText={setConfirmPassword}
              onBlur={handleConfirmPasswordBlur}
              autoCapitalize="none"
              style={styles.input}
              error={hasConfirmPasswordError}
              isPassword
            />

            <TermsAndConditionsAgreement
              areTermsConfirmed={areTermsConfirmed}
              onCheckBoxPress={handleCheckboxPress}
              error={hasTermsConfirmedError}
              style={styles.termsContainer}
              onPrivacyPolicyPress={onPrivacyPolicyPress}
              onTermsAndConditionsPress={onTermsAndConditionsPress}
            />

            <View style={styles.placeholder} />

            <ControlBottomButtons
              style={styles.buttonsWrapper}
              onSubmit={handleSubmit}
              onReturn={onBackButtonPress}
              submitButtonDisabled={!isValid || isSubmitting}
              submitButtonLoading={isSubmitting}
            />
          </View>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    </View>
  );
};

export default React.memo(RegistrationCredentialsSlide);
