import React from 'react';

export enum InputPostfix {
  EDIT = 'edit',
}

type InputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'placeholder'
> & {
  label?: string | null | undefined;
  placeholder?: string | null | undefined;
  errorColor?: string;
  isPassword?: boolean;
  showLabel?: boolean;
  right?: React.ReactNode;
  isRequired?: boolean;
  error?: string;
  isSecure?: boolean;
  postfix?: InputPostfix;
  inputClass?: string;
  hasError?: boolean;
  isFocused?: boolean;
  onChangeText?: (value: string) => void;
};

export default InputProps;
