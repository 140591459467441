import {Platform, StyleSheet} from 'react-native';
import {MixedStyleRecord} from 'react-native-render-html';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

const GAP = spacings.xl;

const getStyles = (paddingHorizontal: number) =>
  StyleSheet.create({
    header: {
      paddingHorizontal,
    },
    titleWrapper: {
      flexDirection: 'row',
      flexShrink: 1,
      alignItems: 'center',
    },
    title: {
      ...typography.subtitleBold,
      color: colors.whiteAlpha95,
      flexShrink: 1,
    },
    infoButton: {
      marginLeft: spacings.xxs,
      marginTop: spacings.xxxs,
    },
    subtitle: {
      marginTop: spacings.xxxxs,
      color: colors.whiteAlpha75,
      ...typography.input,
    },
    list: {
      marginTop: spacings.xxl,
    },
    listContent: {
      paddingHorizontal,
      gap: GAP,
    },
    modal: {
      paddingBottom: Platform.select({default: spacings.xxxxl, web: undefined}),
    },
    modalContent: {
      justifyContent: Platform.select({default: undefined, web: 'center'}),
      alignItems: Platform.select({default: undefined, web: 'center'}),
    },
    label: {
      ...typography.input,
      color: colors.white,
      textAlign: 'center',
    },
    emptyContainer: {
      marginHorizontal: paddingHorizontal,
      marginTop: spacings.l,
    },
    errorContainer: {
      marginTop: spacings.l,
      marginHorizontal: paddingHorizontal,
      paddingVertical: spacings.xxl,
      paddingHorizontal: spacings.l,
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

export const htmlCustomStyle: MixedStyleRecord = {
  body: {
    ...typography.body,
    color: colors.whiteAlpha75,
  },
};

export default getStyles;
