import * as R from 'ramda';
import {useCallback, useMemo} from 'react';
import {
  SubscriptionId,
  useSubscriptionsContext,
} from '../contexts/SubscriptionsContext';
import {
  FindManyMindManagerNewsQueryVariables,
  NullsOrder,
  SortOrder,
  useFindManyMindManagerNewsQuery,
} from '../mindance-libs/generated/graphql';
import useListQuery from '../mindance-libs/hooks/useListQuery';
import {ProductLabels} from '../mindance-libs/types/products';

const useMindManagerNewsList = (options?: {
  take?: number;
  shouldUseSingleSubscription?: boolean;
}) => {
  const shouldUseSingleSubscription =
    options?.shouldUseSingleSubscription ?? true;
  const {selectedSubscriptionId, selectedSubscriptionsByProducts} =
    useSubscriptionsContext();

  const subscriptionsIds = useMemo<SubscriptionId[] | undefined>(() => {
    if (shouldUseSingleSubscription) {
      return selectedSubscriptionId ? [selectedSubscriptionId] : undefined;
    }

    return selectedSubscriptionsByProducts[
      ProductLabels.NEWSTOOL_SHOW_RELATED_SUBSCRIPTIONS
    ];
  }, [
    selectedSubscriptionId,
    selectedSubscriptionsByProducts,
    shouldUseSingleSubscription,
  ]);

  const getQueryVariables = useCallback<
    (
      input?: Partial<FindManyMindManagerNewsQueryVariables>,
    ) => FindManyMindManagerNewsQueryVariables
  >(
    (input = {}) => {
      const baseVariables: FindManyMindManagerNewsQueryVariables = {
        where: {
          Subscriptions_News: {
            some: {
              Subscriptions_id: {
                in: (subscriptionsIds ?? []).map(Number),
              },
            },
          },
        },
        orderBy: [
          {
            Pinned: {
              nulls: NullsOrder.Last,
              sort: SortOrder.Desc,
            },
          },
          {
            date_created: {
              sort: SortOrder.Desc,
              nulls: NullsOrder.Last,
            },
          },
        ],
      };

      return R.mergeDeepLeft(
        baseVariables,
        input,
      ) as FindManyMindManagerNewsQueryVariables;
    },
    [subscriptionsIds],
  );

  const result = useListQuery(
    useFindManyMindManagerNewsQuery,
    getQueryVariables,
    'findManyMindManagerNews',
    {
      shouldSkip:
        R.isNil(subscriptionsIds) ||
        (subscriptionsIds && R.isEmpty(subscriptionsIds)),
      take: options?.take,
    },
  );

  return result;
};

export default useMindManagerNewsList;
