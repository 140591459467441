import {useApolloClient} from '@apollo/client';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {AppState, Platform} from 'react-native';
import {useAppContext} from '../store/contexts/AppContext';
import {useAuthContext} from '../store/contexts/AuthContext';
import {useInfoOverlaysContext} from '../store/contexts/InfoOverlaysContext';
import {UsersRolesUpdateType} from '../types/auth';
import {AppInfoOverlay} from '../types/infoOverlays';
import {CONTACT_US_EMAIL} from '../utils/constants';
import {reportError} from '../utils/loggingHelpers';
import useLogout from './useLogout';

const HOUR_IN_MS = 60 * 60 * 1000;

const useValidateUserRoles = () => {
  const {validateUsersRoles, isLoggedIn, isLoading} = useAuthContext();
  const {showInfoOverlay} = useInfoOverlaysContext();
  const {user} = useAppContext();
  const {t} = useTranslation();
  const {onLogout} = useLogout();
  const client = useApolloClient();

  const appState = useRef(AppState.currentState);

  const isValidatedOnStart = useRef(false);

  const timer = useRef<NodeJS.Timeout | null>(null);

  const forceLogoutOverlay = useMemo<AppInfoOverlay>(
    () => ({
      title: t('infoOverlays.everything_is_up_to_date'),
      description: t('infoOverlays.log_in_to_account_now', {
        userEmail: user?.EMail,
        supportEmail: CONTACT_US_EMAIL,
      }),
      buttonLabel: t('infoOverlays.log_in_now'),
      animation: require('../assets/animations/screen_locked_animation.json'),
      showNextIcon: true,
      showCloseButton: false,
      onSubmit: onLogout,
    }),
    [onLogout, t, user?.EMail],
  );

  const clearReminder = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, []);

  const setReminder = useCallback(() => {
    clearReminder();

    timer.current = setTimeout(() => {
      showInfoOverlay(forceLogoutOverlay);
    }, HOUR_IN_MS);
  }, [clearReminder, forceLogoutOverlay, showInfoOverlay]);

  const reminderOverlay = useMemo<AppInfoOverlay>(
    () => ({
      title: t('infoOverlays.everything_is_up_to_date'),
      description: t('infoOverlays.re_log_in_within_hour'),
      buttonLabel: t('infoOverlays.log_out_now'),
      animation: require('../assets/animations/rocket_animation.json'),
      showNextIcon: true,
      showCloseButton: true,
      onSubmit: onLogout,
      onClose: setReminder,
    }),
    [onLogout, setReminder, t],
  );

  const checkUserRoles = useCallback(async () => {
    try {
      const result = await validateUsersRoles();

      if (!result || result?.isValid) {
        return;
      }

      if (
        Platform.OS === 'web' &&
        result.usersRolesUpdateType === UsersRolesUpdateType.MINDMANAGER_REMOVED
      ) {
        showInfoOverlay(forceLogoutOverlay);
        return;
      }

      if (
        Platform.OS === 'web' &&
        result.usersRolesUpdateType === UsersRolesUpdateType.MINDMANAGER_ADDED
      ) {
        showInfoOverlay(reminderOverlay);
        return;
      }

      await client.refetchQueries({include: 'active'});
    } catch (error) {
      reportError('checkUserRoles error', error);
    }
  }, [
    validateUsersRoles,
    client,
    showInfoOverlay,
    forceLogoutOverlay,
    reminderOverlay,
  ]);

  useEffect(() => {
    if (!isLoggedIn) {
      clearReminder();
    }
  }, [clearReminder, isLoggedIn]);

  useEffect(() => {
    if (isValidatedOnStart.current || isLoading) {
      return;
    }

    checkUserRoles();
    isValidatedOnStart.current = true;
  }, [isLoading, checkUserRoles]);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', nextAppState => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        checkUserRoles();
      }

      appState.current = nextAppState;
    });

    return () => {
      subscription.remove();
    };
  }, [checkUserRoles]);
};

export default useValidateUserRoles;
