import {useHeaderHeight} from '@react-navigation/elements';
import React, {PropsWithChildren, useMemo} from 'react';
import {Platform, StyleProp, View, ViewProps, ViewStyle} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import useCompanyConfig from '../../hooks/useCompanyConfig';
import spacings from '../../theme/spacings';
import MessageSnackBar from '../MessageSnackBar';
import ScreenBackground from '../ScreenBackground';
import styles from './styles';

type Props = ViewProps & {
  error?: string;
  message?: string;
  contentStyle?: ViewStyle;
  includeTop?: boolean;
  includeHeader?: boolean;
  includeBottom?: boolean;
  includeBackground?: boolean;
  snackBarTopSpace?: number;
};

const ScreenBase: React.FC<PropsWithChildren<Props>> = ({
  children,
  style,
  contentStyle,
  error,
  message,
  includeTop = false,
  includeHeader = false,
  includeBottom = false,
  includeBackground = Platform.OS !== 'web',
  snackBarTopSpace,
  ...props
}) => {
  const height = useHeaderHeight();
  const {top, bottom} = useSafeAreaInsets();

  const {primaryColor, secondaryColor, backdropColor, tertiaryColor} =
    useCompanyConfig();

  const mergedContainerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [
      styles.container,
      {
        paddingTop: includeHeader ? height : includeTop ? top : undefined,
        paddingBottom: includeBottom ? bottom + spacings.l : 0,
      },
      style,
    ],
    [bottom, height, includeBottom, includeHeader, includeTop, style, top],
  );

  const mergedContentStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.content, contentStyle],
    [contentStyle],
  );

  return (
    <ScreenBackground
      style={mergedContainerStyle}
      includeBackground={includeBackground}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      backdropColor={backdropColor}
      tertiaryColor={tertiaryColor}>
      <View testID="screenBaseWrapper" style={mergedContentStyle} {...props}>
        {children}
      </View>
      <MessageSnackBar
        message={error ?? message}
        isError={!!error}
        topSpace={
          snackBarTopSpace ?? (includeHeader ? height - top : undefined)
        }
      />
    </ScreenBackground>
  );
};

export default React.memo(ScreenBase);
