import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import ErrorOverlay from '../../../components/ErrorOverlay/ErrorOverlay';
import ItemsList from '../../../components/ItemsList/ItemsList';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay';
import EmptyState from '../../../mindance-libs/components/EmptyState';
import NewsItem from '../../../mindance-libs/components/NewsItem';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import {AppNewsFragment} from '../../../mindance-libs/generated/graphql';
import useNewsItems from '../../../mindance-libs/hooks/useNewsItems';
import {RouteKeys} from '../../../navigation/RouteKeys';
import useTypedSearchParams from '../../../navigation/typed/useTypedSearchParams';
import styles from './page.module.scss';

export default function Page() {
  const {t} = useTranslation();

  const [{newsId}] = useTypedSearchParams<RouteKeys.News>();

  const newsItemRef = useRef<HTMLDivElement>(null);

  const {sections, loading, error, loadingMore, fetchNext, toggleNewsLike} =
    useNewsItems();

  const renderItem = useCallback<
    (item: AppNewsFragment, index: number) => JSX.Element | null
  >(
    item => (
      <div
        key={item.id}
        ref={String(item.id) === String(newsId) ? newsItemRef : undefined}>
        <NewsItem
          {...item}
          onLikePress={toggleNewsLike}
          enableFullDescription
          enablePinnedBorder
        />
      </div>
    ),
    [newsId, toggleNewsLike],
  );

  const Content = useMemo(() => {
    const isEmpty =
      sections.length === 0 ||
      sections.every(({data}) => !data || data.length === 0);

    if (isEmpty) {
      return <EmptyState label={t('news.empty_state')} />;
    }

    return sections.map(({data, title}, index, list) => (
      <ItemsList
        key={index.toString()}
        className={styles.news__content__items}
        data={data}
        loading={loading}
        renderItem={renderItem}
        error={error}
        loadingMore={index === list.length - 1 ? loadingMore : undefined}
        onEndReached={index === list.length - 1 ? fetchNext : undefined}
        ListHeader={
          title ? (
            <h2 className={styles.news__content__title}>{title}</h2>
          ) : undefined
        }
      />
    ));
  }, [error, fetchNext, loading, loadingMore, renderItem, sections, t]);

  useEffect(() => {
    if (newsItemRef && newsItemRef.current) {
      newsItemRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, []);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay />;
  }

  return (
    <ScreenBase>
      <div className={styles.news__container}>
        <div className={styles.news__header}>
          <h1 className={styles.news__header__title}>
            {t('screenTitle.NewsScreen')}
          </h1>
        </div>

        <div className={styles.news__content}>{Content}</div>
      </div>
    </ScreenBase>
  );
}
