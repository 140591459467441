import {StyleSheet} from 'react-native';
import spacings from '../../theme/spacings';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: spacings.l,
    paddingVertical: spacings.xs,
  },
  dot: {
    marginHorizontal: spacings.xxxxs,
  },
});

export default styles;
