import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../components/Button/Button';
import {ButtonStyle} from '../../../../components/Button/Button.props';
import ErrorOverlay from '../../../../components/ErrorOverlay/ErrorOverlay';
import ItemsList from '../../../../components/ItemsList/ItemsList';
import LoadingOverlay from '../../../../components/LoadingOverlay/LoadingOverlay';
import NotesItem from '../../../../mindance-libs/components/NotesItem';
import PsychologistMessage from '../../../../mindance-libs/components/PsychologistMessage';
import ScreenBase from '../../../../mindance-libs/components/ScreenBase';
import useMyNotes from '../../../../mindance-libs/hooks/useMyNotes';
import {AppNote} from '../../../../mindance-libs/types/myArea';
import useTypedNavigate from '../../../../navigation/typed/useTypedNavigate';
import styles from './page.module.scss';

export default function Page() {
  const {t} = useTranslation();

  const {data, error, fetchNext, loading, loadingMore} = useMyNotes();

  const navigate = useTypedNavigate();

  const backButton = useMemo(
    () => ({title: t('common.return') || '', onClick: () => navigate(-1)}),
    [navigate, t],
  );

  const HeaderComponent = useMemo<React.ReactElement>(
    () => (
      <div className={styles['my-notes__content__psychologist']}>
        <PsychologistMessage
          message={t('my_area.notes_psychologist_message')}
          animated={false}
        />
      </div>
    ),
    [t],
  );

  const renderItem = useCallback<
    (item: AppNote, index: number) => JSX.Element | null
  >(item => <NotesItem key={item.id} {...item} />, []);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay />;
  }

  return (
    <ScreenBase>
      <div className={styles['my-notes__container']}>
        <Button
          showLeftArrow
          button={backButton}
          className={styles['my-notes__button']}
          buttonStyle={ButtonStyle.INLINE_WHITE}
        />
        <h1>{t('screenTitle.MyNotesScreen')}</h1>

        <div className={styles['my-notes__content']}>
          <ItemsList
            className={styles['my-notes__content__items']}
            data={data}
            loading={loading}
            loadingMore={loadingMore}
            renderItem={renderItem}
            error={error}
            onEndReached={fetchNext}
            ListHeader={HeaderComponent}
          />
        </div>
      </div>
    </ScreenBase>
  );
}
