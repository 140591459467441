import React from 'react';
import Svg, {ClipPath, Defs, G, Path, Rect, SvgProps} from 'react-native-svg';

const UnitedStatesIcon: React.FC<SvgProps> = props => {
  return (
    <Svg width="31" height="31" viewBox="0 0 31 31" fill="none" {...props}>
      <G clipPath="url(#clip0_3359_9145)">
        <Path
          d="M15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93959 24.0604 0 15.5 0C6.93959 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31Z"
          fill="#F0F0F0"
        />
        <Path
          d="M14.8281 15.4966H31.002C31.002 14.0976 30.8155 12.7423 30.468 11.4531H14.8281V15.4966Z"
          fill="#D80027"
        />
        <Path
          d="M14.8281 7.41069H28.727C27.7782 5.86238 26.565 4.49384 25.1505 3.36719H14.8281V7.41069Z"
          fill="#D80027"
        />
        <Path
          d="M15.5001 30.9946C19.148 30.9946 22.5009 29.7337 25.1486 27.625H5.85156C8.49928 29.7337 11.8522 30.9946 15.5001 30.9946Z"
          fill="#D80027"
        />
        <Path
          d="M2.27618 23.5826H28.7262C29.4879 22.3396 30.0787 20.9809 30.4672 19.5391H0.535156C0.923686 20.9809 1.51444 22.3396 2.27618 23.5826Z"
          fill="#D80027"
        />
        <Path
          d="M7.17989 2.42054H8.59239L7.27852 3.37506L7.78039 4.91955L6.46659 3.96503L5.15278 4.91955L5.5863 3.58528C4.42949 4.54889 3.41557 5.67784 2.58002 6.93577H3.03261L2.19628 7.54335C2.06598 7.76072 1.94101 7.98153 1.82125 8.20562L2.22062 9.43478L1.47553 8.89343C1.29031 9.28583 1.1209 9.68708 0.968629 10.0967L1.40862 11.451H3.03261L1.71874 12.4055L2.22062 13.95L0.906811 12.9955L0.119822 13.5673C0.0410508 14.2005 0 14.8454 0 15.5H15.5C15.5 6.93964 15.5 5.93045 15.5 0C12.438 0 9.58366 0.888223 7.17989 2.42054ZM7.78039 13.95L6.46659 12.9955L5.15278 13.95L5.65465 12.4055L4.34079 11.451H5.96478L6.46659 9.9065L6.9684 11.451H8.59239L7.27852 12.4055L7.78039 13.95ZM7.27852 7.89029L7.78039 9.43478L6.46659 8.48026L5.15278 9.43478L5.65465 7.89029L4.34079 6.93577H5.96478L6.46659 5.39128L6.9684 6.93577H8.59239L7.27852 7.89029ZM13.3402 13.95L12.0264 12.9955L10.7126 13.95L11.2144 12.4055L9.90056 11.451H11.5246L12.0264 9.9065L12.5282 11.451H14.1522L12.8383 12.4055L13.3402 13.95ZM12.8383 7.89029L13.3402 9.43478L12.0264 8.48026L10.7126 9.43478L11.2144 7.89029L9.90056 6.93577H11.5246L12.0264 5.39128L12.5282 6.93577H14.1522L12.8383 7.89029ZM12.8383 3.37506L13.3402 4.91955L12.0264 3.96503L10.7126 4.91955L11.2144 3.37506L9.90056 2.42054H11.5246L12.0264 0.876053L12.5282 2.42054H14.1522L12.8383 3.37506Z"
          fill="#0052B4"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_3359_9145">
          <Rect width="31" height="31" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default React.memo(UnitedStatesIcon);
