import {SvgProps} from 'react-native-svg';
import {BaseNoteFragment, BaseNoteUnitFragment} from '../generated/graphql';

export enum MyAreaSectionKey {
  STATISTICS = 'STATISTICS',
  NOTES = 'NOTES',
  INTERESTS = 'INTERESTS',
}

export type MyAreaInfoDetails = {
  title: string;
  description: string;
};

export type MyAreaSection = {
  key: MyAreaSectionKey;
  title: string;
  Icon: React.FC<SvgProps>;
  info?: MyAreaInfoDetails;
};

export type AppNote = {
  id: BaseNoteFragment['id'];
  label: BaseNoteUnitFragment['Label'];
  description: BaseNoteFragment['Description'];
  dateCreated: BaseNoteFragment['date_created'];
};
