import * as R from 'ramda';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import SubscriptionsSelectModal from '../components/SubscriptionsSelectModal/SubscriptionsSelectModal';
import {
  SubscriptionId,
  useSubscriptionsContext,
} from '../contexts/SubscriptionsContext';
import {MindManagerNewsDetailsFragment} from '../mindance-libs/generated/graphql';
import useAppModal from '../mindance-libs/hooks/useAppModal';
import {ProductLabels} from '../mindance-libs/types/products';
import useSelectSubscriptions from './useSelectSubscriptions';

const useNewsDetailsSubscriptions = (
  newsSubscriptions?: MindManagerNewsDetailsFragment['Subscriptions_News'],
) => {
  const {t} = useTranslation();

  const {
    subscriptionsByProducts,
    selectedSubscriptionsByProducts,
    subscriptionsRecord,
  } = useSubscriptionsContext();

  const allNewsSubscriptions = useMemo(
    () =>
      subscriptionsByProducts?.[
        ProductLabels.NEWSTOOL_SHOW_RELATED_SUBSCRIPTIONS
      ],
    [subscriptionsByProducts],
  );

  const initialNewsSubscriptionsIds = useMemo<SubscriptionId[]>(() => {
    if (!newsSubscriptions) {
      const defaultSubscriptions =
        selectedSubscriptionsByProducts?.[
          ProductLabels.NEWSTOOL_SHOW_RELATED_SUBSCRIPTIONS
        ];
      return defaultSubscriptions ?? [];
    }

    const ids = newsSubscriptions.map(({Subscriptions_id}) => Subscriptions_id);
    const filteredIds = R.filter(R.isNotNil, ids);
    const stringifiedIds = filteredIds.map(id => String(id));

    return R.uniq(stringifiedIds);
  }, [newsSubscriptions, selectedSubscriptionsByProducts]);

  const [selectedSubscriptionIds, setSelectedSubscriptionIds] = useState<
    SubscriptionId[]
  >(initialNewsSubscriptionsIds);

  useEffect(() => {
    setSelectedSubscriptionIds(initialNewsSubscriptionsIds);
  }, [initialNewsSubscriptionsIds]);

  const {
    confirmSelection,
    resetSelectedSubscriptions,
    selectSubscription,
    deselectAll,
    selectAll,
    selectedSubscriptionsRecord,
  } = useSelectSubscriptions({
    onSelectSubscriptions: setSelectedSubscriptionIds,
    initiallySelectedSubscriptionsIds: selectedSubscriptionIds,
    subscriptions: allNewsSubscriptions,
  });

  const {hideModal, isModalVisible, showModal} = useAppModal();

  const SubscriptionModal = useMemo(() => {
    return (
      <SubscriptionsSelectModal
        isOpened={isModalVisible}
        selectedSubscriptionsRecord={selectedSubscriptionsRecord}
        subscriptions={allNewsSubscriptions}
        onSelectAll={selectAll}
        onDeselectAll={deselectAll}
        onSelectSubscription={selectSubscription}
        onConfirm={confirmSelection}
        onClose={resetSelectedSubscriptions}
        closeModal={hideModal}
        description={t('mindManagerNewstool.select_locations_descriptions')}
      />
    );
  }, [
    allNewsSubscriptions,
    confirmSelection,
    deselectAll,
    hideModal,
    isModalVisible,
    resetSelectedSubscriptions,
    selectAll,
    selectSubscription,
    selectedSubscriptionsRecord,
    t,
  ]);

  return useMemo(
    () => ({
      selectedSubscriptionIds,
      showModal,
      SubscriptionModal,
      subscriptionsRecord,
    }),
    [
      SubscriptionModal,
      selectedSubscriptionIds,
      showModal,
      subscriptionsRecord,
    ],
  );
};

export default useNewsDetailsSubscriptions;
