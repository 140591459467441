import React from 'react';
import Svg, {ClipPath, Defs, G, Path, Rect, SvgProps} from 'react-native-svg';

const GermanyIcon: React.FC<SvgProps> = props => {
  return (
    <Svg width="13" height="13" viewBox="0 0 13 13" fill="none" {...props}>
      <G clipPath="url(#clip0_7013_19743)">
        <Path
          d="M0.404297 8.76051C1.3227 11.2357 3.70523 12.9997 6.5 12.9997C9.29478 12.9997 11.6773 11.2357 12.5957 8.76051L6.5 8.19531L0.404297 8.76051Z"
          fill="#FFB84F"
        />
        <Path
          d="M6.5 0C3.70523 0 1.3227 1.76394 0.404297 4.23914L6.5 4.80434L12.5957 4.23912C11.6773 1.76394 9.29478 0 6.5 0Z"
          fill="black"
        />
        <Path
          d="M0.404295 4.23828C0.143025 4.94244 0 5.70406 0 6.49914C0 7.29422 0.143025 8.05584 0.404295 8.76H12.5957C12.857 8.05584 13 7.29422 13 6.49914C13 5.70406 12.857 4.94244 12.5957 4.23828H0.404295Z"
          fill="#C22A2A"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_7013_19743">
          <Rect width="13" height="13" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default React.memo(GermanyIcon);
