import React, {ReactNode} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppContext} from '../../mindance-libs/store/contexts/AppContext';
import LoadingPage from '../../pages/loading/page';
import {RouteKeys} from '../RouteKeys';
import TypedNavigate from '../typed/TypedNavigate';

const AuthRoute: React.FC<{children?: ReactNode}> = ({children}) => {
  const {isLoggedInLoading: isLoading, isLoggedIn} = useAppContext();
  const location = useLocation();

  const origin = location.state?.from?.pathname ?? RouteKeys.Start;

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isLoggedIn) {
    return <TypedNavigate to={origin} replace />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthRoute;
