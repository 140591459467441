import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import AppText from '../../AppText';
import styles from './styles';
import {
  PersonalInfo,
  RegistrationSlideProps,
} from '../../../types/registration';
import {Gender} from '../../../types/user';
import {useLayout} from '@react-native-community/hooks';
import {useFormik} from 'formik';
import {authPersonalInfoFormValidationSchema} from '../../../utils/formValidations';
import useInputFormikHandlers from '../../../hooks/useInputFormikHandlers';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import AppInput from '../../AppInput';
import AppPicker from '../../AppPicker';
import ControlBottomButtons from '../../ControlBottomButtons';
import {SafeAreaView} from 'react-native-safe-area-context';
import {isGender} from '../../../utils/user';

type FormTypes = {
  firstName: string;
  lastName: string;
  gender: Gender | undefined;
  age: number | undefined;
};

type Props = RegistrationSlideProps & {
  initialValues?: FormTypes;
  onSubmitButtonPress: (values: PersonalInfo) => void;
};

const INITIAL_VALUES: FormTypes = {
  firstName: '',
  lastName: '',
  gender: undefined,
  age: undefined,
};

const RegistrationPersonalInfoSlide: React.FC<Props> = ({
  initialValues,
  onSubmitButtonPress,
  onBackButtonPress,
  onError,
  style,
}) => {
  const {t} = useTranslation();

  const {height, onLayout} = useLayout();

  const contentStyle = useMemo(
    () => [styles.space, {minHeight: height}],
    [height],
  );

  const submitPersonalInfoForm = useCallback<
    (values: FormTypes) => Promise<void>
  >(
    async ({age, firstName, gender, lastName}) => {
      onSubmitButtonPress({
        age: age ? Number(age) : undefined,
        firstName,
        lastName,
        gender: isGender(gender) ? gender : undefined,
      });
    },
    [onSubmitButtonPress],
  );

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    validationSchema: authPersonalInfoFormValidationSchema,
    validateOnMount: true,
    initialValues: initialValues ?? INITIAL_VALUES,
    onSubmit: submitPersonalInfoForm,
  });

  const {
    handleValueBlur: handleFirstNameBlur,
    hasError: hasFirstNameError,
    setValue: setFirstName,
  } = useInputFormikHandlers<FormTypes>('firstName', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError,
  });

  const {
    handleValueBlur: handleLastNameBlur,
    hasError: hasLastNameError,
    setValue: setLastName,
  } = useInputFormikHandlers<FormTypes>('lastName', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError,
  });

  const genderPickerItems = useMemo(
    () => [
      {label: t('user.female'), value: Gender.FEMALE},
      {label: t('user.male'), value: Gender.MALE},
      {label: t('user.diverse'), value: Gender.DIVERSE},
    ],
    [t],
  );

  const genderInputPlaceholder = useMemo(
    () => ({
      label: t('auth.select_gender'),
      value: undefined,
      inputLabel: t('user.gender') ?? '',
    }),
    [t],
  );

  const {
    error: genderError,
    hasError: hasGenderError,
    setValue: setGender,
  } = useInputFormikHandlers<FormTypes>('gender', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError,
  });

  const {
    handleValueBlur: handleAgeBlur,
    hasError: hasAgeError,
    setValue: setAge,
  } = useInputFormikHandlers<FormTypes>('age', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError,
  });

  const handleAgeInput = useCallback<(value: string) => void>(
    value => {
      const filteredValue = value.replace(/[^0-9]/g, '').trim();
      if (filteredValue === '0' && filteredValue.length === 1) {
        return;
      }

      setAge(filteredValue);
    },
    [setAge],
  );

  const updateGenderValue = useCallback<(value: string | undefined) => void>(
    value => {
      setGender(value ?? '');
    },
    [setGender],
  );
  const containerStyle = useMemo(() => [styles.container, style], [style]);

  return (
    <View style={containerStyle}>
      <SafeAreaView style={styles.container}>
        <KeyboardAwareScrollView
          enableOnAndroid
          enableAutomaticScroll
          showsVerticalScrollIndicator={false}
          onLayout={onLayout}>
          <View style={contentStyle}>
            <AppText style={styles.headline}>{t('auth.step1')}</AppText>
            <AppText style={styles.title}>{t('auth.create_account')}</AppText>

            <View style={styles.placeholder} />

            <AppInput
              label={t('user.first_name')}
              placeholder={t('user.first_name')}
              value={values.firstName}
              onChangeText={setFirstName}
              onBlur={handleFirstNameBlur}
              autoCapitalize="words"
              error={hasFirstNameError}
              testID="authPersonalInfo.firstNameInput"
            />
            <AppInput
              label={t('user.last_name')}
              placeholder={t('user.last_name')}
              value={values.lastName}
              onChangeText={setLastName}
              onBlur={handleLastNameBlur}
              autoCapitalize="words"
              error={hasLastNameError}
              testID="authPersonalInfo.lastNameInput"
              style={styles.input}
            />

            <View style={styles.placeholder} />

            <AppPicker
              onValueChange={updateGenderValue}
              items={genderPickerItems}
              value={values.gender ?? ''}
              placeholder={genderInputPlaceholder}
              error={hasGenderError ? genderError : undefined}
              testID="authPersonalInfo.genderPicker"
            />

            <AppInput
              label={t('user.age')}
              placeholder={t('user.age')}
              value={values.age ? String(values.age) : ''}
              onChangeText={handleAgeInput}
              onBlur={handleAgeBlur}
              autoCapitalize="none"
              keyboardType="number-pad"
              style={styles.input}
              error={hasAgeError}
              testID="authPersonalInfo.ageInput"
            />

            <View style={styles.placeholder} />

            <ControlBottomButtons
              style={styles.buttonsWrapper}
              onSubmit={handleSubmit}
              onReturn={onBackButtonPress}
              submitButtonDisabled={!isValid || isSubmitting}
              returnButtonTestID="authPersonalInfo.returnButton"
              submitButtonTestID="authPersonalInfo.submitButton"
            />
          </View>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    </View>
  );
};

export default React.memo(RegistrationPersonalInfoSlide);
