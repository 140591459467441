import {ApolloError} from '@apollo/client';
import * as R from 'ramda';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  CoachDetailsFragment,
  Coaches_Translations,
  Maybe,
  OneToOneSessionDetailsFragment,
  OneToOneSessions_Translations,
  useFindUniqueOneToOneSessionQuery,
} from '../generated/graphql';
import {reportError} from '../utils/loggingHelpers';

type OneToOneSessionDetailsInfo = Partial<OneToOneSessionDetailsFragment> & {
  loading: boolean;
  error?: ApolloError;
  oneToOneSessionsTranslations?: OneToOneSessions_Translations;
  coach?: Maybe<CoachDetailsFragment> | undefined;
  coachTranslations?: Coaches_Translations;
};

const useOneToOneSessionDetails = ({
  id,
  language,
}: {
  id?: number;
  language?: string | null;
}): OneToOneSessionDetailsInfo => {
  const {i18n} = useTranslation();

  const {data, loading, error} = useFindUniqueOneToOneSessionQuery({
    variables: {where: {id}, languages_code: language ?? i18n.language},
    skip: R.isNil(id) || isNaN(id),
    onError: e => reportError('useFindUniqueOneToOneSessionQuery', e),
  });

  const oneToOneSessionsTranslations = useMemo<
    OneToOneSessions_Translations | undefined
  >(
    () => data?.findUniqueOneToOneSession?.OneToOneSessions_translations?.[0],
    [data?.findUniqueOneToOneSession?.OneToOneSessions_translations],
  );

  const coach = useMemo<Maybe<CoachDetailsFragment> | undefined>(
    () => data?.findUniqueOneToOneSession.Coaches,
    [data?.findUniqueOneToOneSession.Coaches],
  );

  const coachTranslations = useMemo<Coaches_Translations | undefined>(
    () => coach?.Coaches_translations?.[0],
    [coach?.Coaches_translations],
  );

  return useMemo<OneToOneSessionDetailsInfo>(
    () => ({
      ...data?.findUniqueOneToOneSession,
      loading,
      coachTranslations,
      error,
      oneToOneSessionsTranslations,
      coach,
    }),
    [
      coach,
      coachTranslations,
      data?.findUniqueOneToOneSession,
      error,
      loading,
      oneToOneSessionsTranslations,
    ],
  );
};

export default useOneToOneSessionDetails;
