import React, {PropsWithChildren, useMemo} from 'react';
import {StyleProp, TouchableHighlight, View, ViewStyle} from 'react-native';
import FastImage, {Source, ImageStyle} from 'react-native-fast-image';
import styles from './styles';
import AppText from '../AppText';
import colors from '../../theme/colors';

type Props = {
  onPress: () => void;
  label?: string | null;
  imageUrl?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  imageWidth: number;
  imageRatio: number;
};

const LibraryWideCardBase: React.FC<PropsWithChildren<Props>> = ({
  onPress,
  imageUrl,
  style,
  testID,
  label,
  imageRatio,
  imageWidth,
  children,
}) => {
  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, style],
    [style],
  );

  const imageStyle = useMemo<StyleProp<ImageStyle>>(
    () => [
      styles.image,
      {width: imageWidth, minHeight: imageWidth / imageRatio},
    ],
    [imageRatio, imageWidth],
  );

  const image = useMemo<Source>(
    () =>
      imageUrl
        ? {uri: imageUrl}
        : require('../../assets/images/placeholder.png'),
    [imageUrl],
  );

  return (
    <TouchableHighlight
      style={containerStyle}
      testID={testID}
      onPress={onPress}
      underlayColor={colors.black90}>
      <>
        <FastImage
          source={image}
          style={imageStyle}
          testID={`${testID}.image`}
        />
        <View style={styles.content}>
          {children}
          {label && (
            <AppText style={styles.label} testID={`${testID}.label`}>
              {label}
            </AppText>
          )}
        </View>
      </>
    </TouchableHighlight>
  );
};

export default React.memo(LibraryWideCardBase);
