import {LinearGradient} from 'expo-linear-gradient';
import * as R from 'ramda';
import React, {useCallback, useMemo} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {BaseButton} from 'react-native-gesture-handler';
import {BaseSessionFragment} from '../../generated/graphql';
import TickIcon from '../../icons/TickIcon';
import colors from '../../theme/colors';
import {getImageUrl} from '../../utils/files';
import {getStringRoundedDecimalMinutes} from '../../utils/time';
import AppText from '../AppText';
import ImageWithLoadingState from '../ImageWithLoadingState';
import PlayButton from '../PlayButton';
import styles from './styles';

const IMAGE_ASPECT_RATIO = 1;

type Props = BaseSessionFragment & {
  width?: number;
  order?: number;
  isCompleted?: boolean;
  onPress?: (id: BaseSessionFragment['id']) => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

const BACKGROUND_GRADIENT_COLORS = [colors.transparent, colors.black95Alpha90];
const BACKGROUND_GRADIENT_POINTS = {
  start: {x: 0, y: 0},
  end: {x: 0, y: 0.8},
};

const CourseSessionsCarouselItem: React.FC<Props> = ({
  id,
  order,
  Label,
  directus_files,
  Duration,
  isCompleted,
  style,
  testID,
  onPress,
  width,
}) => {
  const containerStyle = useMemo(() => [styles.container, style], [style]);

  const imageUrl = getImageUrl(directus_files, {
    aspectRatio: IMAGE_ASPECT_RATIO,
    width,
  });

  const handlePress = useCallback(() => onPress?.(id), [id, onPress]);

  return (
    <BaseButton style={containerStyle} onPress={handlePress} testID={testID}>
      <View style={styles.wrapper}>
        <ImageWithLoadingState
          style={styles.image}
          uri={imageUrl}
          testID={`${testID}.image`}
        />
        <LinearGradient
          colors={BACKGROUND_GRADIENT_COLORS}
          style={styles.gradientBackground}
          pointerEvents="none"
          start={BACKGROUND_GRADIENT_POINTS.start}
          end={BACKGROUND_GRADIENT_POINTS.end}
          testID={`${testID}.backgroundGradient`}
        />
        <View style={styles.playButtonWrapper}>
          <PlayButton
            size={54}
            testID={`${testID}.playButton`}
            onPress={handlePress}
          />
        </View>

        <View style={styles.content}>
          <View style={styles.bottom}>
            <View style={styles.labelWrapper}>
              {Duration ? (
                <AppText style={styles.time} testID={`${testID}.time`}>
                  {`${getStringRoundedDecimalMinutes(Duration)} Min.`}
                </AppText>
              ) : null}
              {Label ? (
                <AppText style={styles.title} testID={`${testID}.title`}>
                  {Label}
                </AppText>
              ) : null}
            </View>
            {isCompleted || R.isNotNil(order) ? (
              <View
                style={styles.orderWrapper}
                testID={`${testID}.orderWrapper`}>
                {isCompleted ? (
                  <TickIcon width={14} testID={`${testID}.sessionCompleted`} />
                ) : (
                  <AppText
                    style={styles.order}
                    testID={`${testID}.sessionOrder`}>
                    {order}
                  </AppText>
                )}
              </View>
            ) : null}
          </View>
        </View>
      </View>
    </BaseButton>
  );
};

export default React.memo(CourseSessionsCarouselItem);
