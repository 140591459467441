export interface NativeConfig {
  [name: string]: string | undefined;
}

const Config: NativeConfig = new Proxy(
  {},
  {
    get: function (_, property: string) {
      const envProperty = `REACT_APP_${property}`;

      if (process.env[envProperty]) {
        return process.env[envProperty];
      } else {
        throw new Error(`Configuration property '${property}' not found.`);
      }
    },
  },
);

export default Config;
