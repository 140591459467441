import {PathParams, RouteKeysQueryParams} from './NavigationTypes';
import {RouteKeys} from './RouteKeys';
import queryParamsHelper from './queryParams';

/**
 * Build an url with a path and its parameters.
 * @example
 * buildUrl(
 *   '/a/:first/:last',
 *   { first: 'p', last: 'q' },
 * ) // returns '/a/p/q'
 * @param path target path.
 * @param params parameters.
 */
export const buildUrl = <P extends RouteKeys>(
  path: P,
  params: PathParams<P>,
): string => {
  let result: string = path;

  // Upcast `params` to be used in string replacement.
  const paramObj: {[key: string]: string} = params;

  for (const key of Object.keys(paramObj)) {
    const value = paramObj[key] ?? '';

    if (result.includes(`:${key}?`)) {
      result = result.replace(`:${key}?`, value);
    } else {
      result = result.replace(`:${key}`, value);
    }
  }

  return result;
};

/**
 * Type predicate for checking whether params match the path specs.
 * @example
 * areParams(
 *   '/something/:id',
 *   { id: 'abcd' },
 * ) // returns true.
 *
 * areParams(
 *   '/else/:one',
 *   { two: 'efg' },
 * ) // returns false.
 * @param path target path.
 * @param params params to be checked.
 */
export function areParams<P extends RouteKeys>(
  path: P,
  params: unknown,
): params is PathParams<P> {
  if (!(params instanceof Object)) {
    return false;
  }

  const paramSet = new Set(Object.keys(params));

  // Validate params.
  const requiredParams = path
    .split('/')
    .filter(s => s.startsWith(':'))
    .map(s => s.substr(1));

  for (const x of requiredParams) {
    if (!paramSet.has(x)) {
      return false;
    }
  }

  return true;
}

export const generateUrlWithParams = <P extends RouteKeys>(
  path: P,
  params: PathParams<P>,
  queryParams?: RouteKeysQueryParams[P],
): string => {
  const url = buildUrl(path, params);

  const urlParams = queryParamsHelper.stringify(queryParams);

  return urlParams ? `${url}?${urlParams}` : url;
};
