import {useCallback, useEffect, useMemo, useState} from 'react';

type Size = {
  height: number;
  width: number;
};

const useElementSize = <T extends HTMLElement>(initial?: Size) => {
  const [isInViewport, setIsInViewport] = useState(false);
  const [ref, setRefElement] = useState<T | null>(null);

  const setRef = useCallback((node: T | null) => {
    if (node !== null) {
      setRefElement(node);
    }
  }, []);

  useEffect(() => {
    if (ref && !isInViewport) {
      const observer = new IntersectionObserver(
        ([entry]) => entry.isIntersecting && setIsInViewport(true),
      );
      observer.observe(ref);

      return () => {
        observer.disconnect();
      };
    }
  }, [isInViewport, ref]);

  const [size, setSize] = useState<Size>({
    height: initial?.height ?? 0,
    width: initial?.width ?? 0,
  });

  const handleWindowResize = useCallback(() => {
    const width = ref?.offsetWidth ?? 0;
    const height = ref?.offsetHeight ?? 0;

    setSize({width, height});
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  useEffect(() => {
    if (isInViewport) {
      handleWindowResize();
    }
  }, [handleWindowResize, isInViewport]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  return useMemo(
    () => ({size, ref: setRef, onLoad: handleWindowResize}),
    [handleWindowResize, setRef, size],
  );
};

export default useElementSize;
