import {ApolloError} from '@apollo/client';
import * as R from 'ramda';
import {useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  FindManyCoursesAndSessionsQueryVariables,
  FindManyCoursesQueryVariables,
  FindManySessionsQueryVariables,
  InputMaybe,
  useFindManyCoursesAndSessionsQuery,
  useFindManyCoursesQuery,
  useFindManySessionsQuery,
} from '../generated/graphql';
import {useAppContext} from '../store/contexts/AppContext';
import {AppProductsLabels, DataStatuses} from '../types/common';
import {BaseLibraryItem, LibraryItemType} from '../types/library';
import {ProgressReportTypes} from '../types/progressReport';
import {
  getProgressReportCondition,
  isCourseLibraryItem,
  isSessionLibraryItem,
  sortLibraryItems,
} from '../utils/courses';
import useInitialData from './useInitialData';
import useListQuery from './useListQuery';

const TAKE_SIZE: number = 10;

const finishedCourseChecker: (item: BaseLibraryItem) => boolean = item =>
  !!item.ProgressReports?.find(
    ({Label}) => Label === ProgressReportTypes.FINISHED_COURSE,
  );
const finishedSessionChecker: (item: BaseLibraryItem) => boolean = item =>
  !!item.ProgressReports?.find(
    ({Label}) => Label === ProgressReportTypes.FINISHED_SESSION,
  );
const finishedCoursesAndSessionsChecker: (
  item: BaseLibraryItem,
) => boolean = item => {
  if (isCourseLibraryItem(item)) {
    return finishedCourseChecker(item);
  }
  if (isSessionLibraryItem(item)) {
    return finishedSessionChecker(item);
  }
  return false;
};

const getItemKey = (item: BaseLibraryItem) =>
  item.__typename ? `${item.__typename}_${item.id}` : item.id;

const useLibraryItems = (
  itemType: LibraryItemType,
  tags?: string[],
  allTags?: string[],
) => {
  const {i18n} = useTranslation();
  const language = i18n.language;

  const {screensSetupCategoriesAvailability} = useAppContext();

  const areCoursesAvailable =
    screensSetupCategoriesAvailability[AppProductsLabels.COURSES];
  const areSessionsAvailable =
    screensSetupCategoriesAvailability[AppProductsLabels.SESSIONS];

  const selectedTags = useMemo(
    () => (tags && Array.isArray(tags) && tags.length > 0 ? tags : allTags),
    [allTags, tags],
  );

  const getCoursesVariables = useCallback<
    (
      input?: Partial<FindManyCoursesQueryVariables>,
    ) => FindManyCoursesQueryVariables
  >(
    (input = {}) => {
      const tagsCondition: FindManyCoursesQueryVariables['where'] = selectedTags
        ? {Courses_Tags: {some: {Tags_id: {in: selectedTags}}}}
        : {};

      const initialVariables: FindManyCoursesQueryVariables = {
        where: {
          OR: [...getProgressReportCondition()],
          status: {equals: DataStatuses.PUBLISHED},
          ...tagsCondition,
        },
        language,
      };

      return R.mergeDeepRight(
        initialVariables,
        input,
      ) as FindManyCoursesQueryVariables;
    },
    [language, selectedTags],
  );

  const getSessionsVariables = useCallback<
    (
      input?: Partial<FindManySessionsQueryVariables>,
    ) => FindManySessionsQueryVariables
  >(
    (input = {}) => {
      const tagsCondition: FindManySessionsQueryVariables['where'] =
        selectedTags
          ? {Sessions_Tags: {some: {Tags_id: {in: selectedTags}}}}
          : {};

      const initialVariables: FindManySessionsQueryVariables = {
        where: {
          OR: [...getProgressReportCondition()],
          Sessions_Library: {
            some: {},
          },
          status: {
            equals: DataStatuses.PUBLISHED,
          },
          ...tagsCondition,
        },
        language,
      };

      return R.mergeDeepRight(
        initialVariables,
        input,
      ) as FindManySessionsQueryVariables;
    },
    [language, selectedTags],
  );

  const getCoursesAndSessionsVariables = useCallback<
    (
      input?: Partial<FindManyCoursesAndSessionsQueryVariables>,
    ) => FindManyCoursesAndSessionsQueryVariables
  >(
    (input = {}) => {
      const tagsObj = selectedTags ? {tags: selectedTags} : {};

      const initialVariables: FindManyCoursesAndSessionsQueryVariables = {
        languages_code: language,
        ...tagsObj,
      };

      return R.mergeDeepRight(initialVariables, input);
    },
    [language, selectedTags],
  );

  const {
    data: coursesData,
    loading: coursesLoading,
    error: coursesError,
    refetch: refetchCourses,
    fetchNext: fetchNextCourses,
    loadingMore: coursesLoadingMore,
    refreshing: coursesRefreshing,
    refetchWithParams: refetchWithParamsCourses,
  } = useListQuery(
    useFindManyCoursesQuery,
    getCoursesVariables,
    'findManyCourses',
    {
      take: TAKE_SIZE,
      fetchPolicy: 'network-only',
      shouldSkip: itemType === LibraryItemType.SESSIONS || !areCoursesAvailable,
    },
  );

  const {
    data: sessionsData,
    loading: sessionsLoading,
    error: sessionsError,
    refetch: refetchSessions,
    fetchNext: fetchNextSessions,
    loadingMore: sessionsLoadingMore,
    refreshing: sessionsRefreshing,
    refetchWithParams: refetchWithParamsSessions,
  } = useListQuery(
    useFindManySessionsQuery,
    getSessionsVariables,
    'findManySessions',
    {
      take: TAKE_SIZE,
      fetchPolicy: 'network-only',
      shouldSkip: itemType === LibraryItemType.COURSES || !areSessionsAvailable,
    },
  );

  const {
    data: mixedData,
    loading: mixedLoading,
    error: mixedError,
    refetch: refetchCoursesAndSessions,
    fetchNext: fetchNextCoursesAndSessions,
    loadingMore: mixedLoadingMore,
    refreshing: mixedRefreshing,
    refetchWithParams: refetchWithParamsMixed,
  } = useListQuery(
    useFindManyCoursesAndSessionsQuery,
    getCoursesAndSessionsVariables,
    'findManyCoursesAndSessions',
    {
      take: TAKE_SIZE,
      fetchPolicy: 'network-only',
      shouldSkip:
        itemType !== LibraryItemType.MIXED ||
        (!areSessionsAvailable && !areCoursesAvailable),
    },
  );

  const getAvailableItem = useCallback(
    <T>(courseItem: T, sessionItem: T, mixedItem: T) => {
      return {
        [LibraryItemType.COURSES]: areCoursesAvailable ? courseItem : undefined,
        [LibraryItemType.SESSIONS]: areSessionsAvailable
          ? sessionItem
          : undefined,
        [LibraryItemType.MIXED]:
          areCoursesAvailable && areSessionsAvailable
            ? mixedItem
            : areCoursesAvailable
            ? courseItem
            : areSessionsAvailable
            ? sessionItem
            : undefined,
      }[itemType];
    },
    [areCoursesAvailable, areSessionsAvailable, itemType],
  );

  const unsortedData = useMemo<BaseLibraryItem[]>(
    () =>
      getAvailableItem<BaseLibraryItem[]>(
        coursesData,
        sessionsData,
        mixedData,
      ) ?? [],
    [coursesData, getAvailableItem, mixedData, sessionsData],
  );

  const initialData = useInitialData(unsortedData);

  const sortedInitialData = useMemo(() => {
    const sortFunction = getAvailableItem(
      finishedCourseChecker,
      finishedSessionChecker,
      finishedCoursesAndSessionsChecker,
    );

    const listToSort =
      !initialData || initialData?.length === 0 ? unsortedData : initialData;

    const sorted = sortFunction
      ? sortLibraryItems(listToSort, sortFunction)
      : undefined;
    return sorted ?? [];
  }, [getAvailableItem, initialData, unsortedData]);

  const sortedInitialDataRecord = useMemo(
    () =>
      sortedInitialData.reduce<Record<string, BaseLibraryItem>>((acc, item) => {
        const key = getItemKey(item);
        return {...acc, [key]: item};
      }, {}),
    [sortedInitialData],
  );

  const data = useMemo<BaseLibraryItem[]>(() => {
    const unsortedDataDataRecord = unsortedData.reduce<
      Record<string, BaseLibraryItem>
    >((acc, item) => {
      const key = getItemKey(item);
      return {...acc, [key]: item};
    }, {});

    const headItems = sortedInitialData.map(item => {
      const key = getItemKey(item);
      return unsortedDataDataRecord[key];
    });

    const filteredHeadItems = R.filter(R.isNotNil, headItems);

    const tailItems = unsortedData.filter(item => {
      const key = getItemKey(item);
      return !sortedInitialDataRecord[key];
    });

    return [...filteredHeadItems, ...tailItems];
  }, [sortedInitialData, sortedInitialDataRecord, unsortedData]);

  const loading =
    getAvailableItem<boolean>(coursesLoading, sessionsLoading, mixedLoading) ??
    false;

  const loadingMore =
    getAvailableItem<boolean>(
      coursesLoadingMore,
      sessionsLoadingMore,
      mixedLoadingMore,
    ) ?? false;

  const refreshing =
    getAvailableItem<boolean>(
      coursesRefreshing,
      sessionsRefreshing,
      mixedRefreshing,
    ) ?? false;

  const error = useMemo<ApolloError | undefined>(
    () =>
      getAvailableItem<ApolloError | undefined>(
        coursesError,
        sessionsError,
        mixedError,
      ),
    [coursesError, getAvailableItem, mixedError, sessionsError],
  );

  const refetch = useCallback(() => {
    const option = getAvailableItem(
      refetchCourses,
      refetchSessions,
      refetchCoursesAndSessions,
    );

    option?.();
  }, [
    getAvailableItem,
    refetchCourses,
    refetchCoursesAndSessions,
    refetchSessions,
  ]);

  const refetchWithParams = useCallback<
    (
      params?: Partial<{
        take?: InputMaybe<number>;
        skip?: InputMaybe<number>;
        language?: InputMaybe<string>;
        force_refresh?: InputMaybe<boolean>;
      }>,
    ) => Promise<void>
  >(
    async params => {
      const option = getAvailableItem(
        refetchWithParamsCourses,
        refetchWithParamsSessions,
        refetchWithParamsMixed,
      );

      await option?.(params);
    },
    [
      getAvailableItem,
      refetchWithParamsCourses,
      refetchWithParamsMixed,
      refetchWithParamsSessions,
    ],
  );

  const fetchNext = useCallback(() => {
    const option = getAvailableItem(
      fetchNextCourses,
      fetchNextSessions,
      fetchNextCoursesAndSessions,
    );

    option?.();
  }, [
    fetchNextCourses,
    fetchNextCoursesAndSessions,
    fetchNextSessions,
    getAvailableItem,
  ]);

  useEffect(() => {
    if (loading) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  return useMemo(
    () => ({
      data,
      loading,
      loadingMore,
      refreshing,
      error,
      refetch,
      refetchWithParams,
      fetchNext,
    }),
    [
      data,
      error,
      fetchNext,
      loading,
      loadingMore,
      refetch,
      refetchWithParams,
      refreshing,
    ],
  );
};

export default useLibraryItems;
