import {LottieViewProps} from 'lottie-react-native';
import {BaseInfoOverlayFragment} from '../generated/graphql';

export type DisplayedInfoOverlaysCounter = Record<
  BaseInfoOverlayFragment['id'],
  number
>;

export enum InfoOverlaysLocations {
  General = 'GENERAL',
  Welcome = 'WELCOME',
  Registration = 'REGISTRATION',
  Login = 'LOGIN',
  Onboarding = 'ONBOARDING',
  Home = 'HOME',
  Library = 'LIBRARY',
  Booking = 'BOOKING',
  News = 'NEWS',
  MyArea = 'MY_AREA',
  Settings = 'SETTINGS',
  MindManagerHome = 'MIND_MANAGER_HOME',
  MindManagerDashboard = 'MIND_MANAGER_DASHBOARD',
  MindManagerNewstool = 'MIND_MANAGER_NEWSTOOL',
  MindManagerEventtool = 'MIND_MANAGER_EVENTTOOL',
  MindManagerGBU = 'MIND_MANAGER_GBU',
}

export type AppInfoOverlay = Pick<
  BaseInfoOverlayFragment,
  'HowOftenToShow' | 'ShowFrom' | 'ShowOn' | 'ShowUntil' | 'directus_files'
> & {
  id?: BaseInfoOverlayFragment['id'];
  title?: string | null;
  description?: string | null;
  buttonLabel?: string | null;
  animation?: LottieViewProps['source'];
  onClose?: () => void;
  onSubmit?: () => void;
  showNextIcon?: boolean;
  showBackIcon?: boolean;
  showCloseButton?: boolean;
  loopAnimation?: boolean;
};
