import {ApolloError} from '@apollo/client';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  FlatList,
  FlatListProps,
  ListRenderItem,
  Platform,
  StyleProp,
  View,
  ViewStyle,
  useWindowDimensions,
} from 'react-native';
import {InfoDetailsFragment, Maybe, Tags} from '../../generated/graphql';
import useAppModal from '../../hooks/useAppModal';
import spacings from '../../theme/spacings';
import breakpointProvider from '../../utils/breakpointProvider';
import {genericMemo} from '../../utils/common';
import AppModal from '../AppModal';
import AppText from '../AppText';
import DiscoverMoreCard from '../DiscoverMoreCard';
import EmptyState from '../EmptyState';
import HTMLRenderer from '../HTMLRenderer';
import LoadingContent from '../LoadingContent';
import RoundInfoButton from '../RoundInfoButton';
import getStyles, {htmlCustomStyle} from './styles';

export type HomeCardsCarouselProps<T> = {
  title?: Maybe<string>;
  subtitle?: Maybe<string>;
  info?: Maybe<InfoDetailsFragment>;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  itemWidth: number;
  itemAspectRatio: number;
  data: T[];
  renderItem: ListRenderItem<T>;
  keyExtractor?: FlatListProps<T>['keyExtractor'];
  footerItemStyle?: StyleProp<ViewStyle>;
  error?: ApolloError;
  loading?: boolean;
  emptyLabel?: Maybe<string>;
  discoverMoreHref?: string;
  onDiscoverMorePress?: () => void;
  onEndReached?: () => void;
  paddingHorizontal?: number;
  tagsIds?: Tags['id'][];
};

const HomeCardsCarousel = <T,>({
  style,
  subtitle,
  title,
  testID,
  info,
  data,
  renderItem,
  keyExtractor,
  itemAspectRatio,
  itemWidth,
  footerItemStyle,
  error,
  loading = false,
  emptyLabel,
  discoverMoreHref,
  onDiscoverMorePress,
  onEndReached,
  paddingHorizontal = spacings.xl,
}: HomeCardsCarouselProps<T>) => {
  const {t} = useTranslation();
  const {hideModal, isModalVisible, showModal} = useAppModal();
  const {width} = useWindowDimensions();

  const infoTitle = info?.Infos_translations?.[0]?.Label ?? title;
  const infoDescription = info?.Infos_translations?.[0]?.Description;

  const styles = useMemo(
    () => getStyles(paddingHorizontal),
    [paddingHorizontal],
  );

  const modalStyle = useMemo<StyleProp<ViewStyle>>(
    () => [
      styles.modal,
      {
        height: Platform.select({
          default: undefined,
          web:
            width >= breakpointProvider.$gridBreakpoints.md ? '30%' : undefined,
        }),
      },
    ],
    [styles.modal, width],
  );

  const DiscoverMoreComponent = useMemo<
    FlatListProps<T>['ListFooterComponent']
  >(
    () => (
      <DiscoverMoreCard
        aspectRatio={itemAspectRatio}
        width={itemWidth}
        style={footerItemStyle}
        onPress={onDiscoverMorePress}
        href={discoverMoreHref}
      />
    ),
    [
      discoverMoreHref,
      footerItemStyle,
      itemAspectRatio,
      itemWidth,
      onDiscoverMorePress,
    ],
  );

  const Content = useMemo(() => {
    if (data && Array.isArray(data)) {
      return data.length === 0 ? (
        <EmptyState label={emptyLabel} style={styles.emptyContainer} />
      ) : (
        <FlatList
          data={data}
          style={styles.list}
          renderItem={renderItem}
          ListFooterComponent={DiscoverMoreComponent}
          keyExtractor={keyExtractor}
          onEndReached={onEndReached}
          onEndReachedThreshold={2}
          contentContainerStyle={styles.listContent}
          horizontal
          showsHorizontalScrollIndicator={false}
          testID={`${testID}.list`}
        />
      );
    }

    if (error) {
      return (
        <View style={styles.errorContainer}>
          <AppText style={styles.label}>
            {t('errors.something_went_wrong')}
          </AppText>
        </View>
      );
    }

    if (loading) {
      return <LoadingContent />;
    }

    return null;
  }, [
    DiscoverMoreComponent,
    data,
    emptyLabel,
    error,
    keyExtractor,
    loading,
    onEndReached,
    renderItem,
    styles.emptyContainer,
    styles.errorContainer,
    styles.label,
    styles.list,
    styles.listContent,
    t,
    testID,
  ]);

  const showInfo = useCallback(() => showModal(), [showModal]);

  return (
    <View style={style} testID={testID}>
      <AppModal
        onDismiss={hideModal}
        isVisible={isModalVisible}
        title={infoTitle}
        useDefaultHeight={false}
        style={modalStyle}
        contentStyle={styles.modalContent}
        testID={`${testID}.modal`}>
        <HTMLRenderer
          content={infoDescription ?? ''}
          tagsStyles={htmlCustomStyle}
        />
      </AppModal>

      <View style={styles.header}>
        {title ? (
          <View style={styles.titleWrapper}>
            <AppText style={styles.title} testID={`${testID}.title`}>
              {title}
            </AppText>
            {infoDescription ? (
              <RoundInfoButton
                onPress={showInfo}
                style={styles.infoButton}
                testID={`${testID}.infoButton`}
              />
            ) : null}
          </View>
        ) : null}
        {subtitle ? (
          <AppText style={styles.subtitle} testID={`${testID}.subtitle`}>
            {subtitle}
          </AppText>
        ) : null}
      </View>

      {Content}
    </View>
  );
};

export default genericMemo(HomeCardsCarousel);
