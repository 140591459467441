import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const UpdateIcon: React.FC<SvgProps> = ({...props}) => {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="white" {...props}>
      <Path d="M15,6V1.76l-1.7,1.7A7,7,0,1,0,14.92,9H13.51a5.63,5.63,0,1,1-1.2-4.55L10.76,6Z" />
    </Svg>
  );
};

export default React.memo(UpdateIcon);
