import React, {useMemo} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {Link} from 'react-router-dom';
import Content from './Content';
import {DiscoverMoreCardProps} from './props';
import styles from './styles';

const LINK_STYLE: React.CSSProperties = {
  textDecoration: 'none',
};

const DiscoverMoreCard: React.FC<DiscoverMoreCardProps> = ({
  href,
  style,
  ...rest
}) => {
  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.outer, style],
    [style],
  );

  return (
    <View style={containerStyle}>
      <Link to={href ?? ''} style={LINK_STYLE}>
        <Content {...rest} />
      </Link>
    </View>
  );
};

export default React.memo(DiscoverMoreCard);
