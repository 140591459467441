import Color from 'color';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, View, ViewStyle} from 'react-native';
import useCompanyConfig from '../../hooks/useCompanyConfig';
import BellIcon from '../../icons/BellIcon';
import StarIcon from '../../icons/StarIcon';
import colors from '../../theme/colors';
import {CompanyItemType} from '../../types/companyItem';
import AppText from '../AppText';
import styles from './styles';

type Props = {
  itemType: CompanyItemType;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

const CompanyItemLabel: React.FC<Props> = ({itemType, style, testID}) => {
  const {t} = useTranslation();
  const {highlightColor} = useCompanyConfig();

  const backgroundColor = Color(highlightColor ?? colors.backgroundPrimary)
    .alpha(0.3)
    .string();

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, {backgroundColor}, style],
    [backgroundColor, style],
  );

  const label = {
    [CompanyItemType.EVENT]: t('company.company_event'),
    [CompanyItemType.NEWS]: t('company.company_news'),
  }[itemType];

  const Icon = useMemo(() => {
    return {
      [CompanyItemType.EVENT]: (
        <StarIcon width={13} height={13} fill={colors.white} />
      ),
      [CompanyItemType.NEWS]: (
        <BellIcon
          width={11}
          height={13}
          stroke={colors.white}
          fill={colors.transparent}
        />
      ),
    }[itemType];
  }, [itemType]);

  return (
    <View style={containerStyle} testID={testID}>
      {Icon}
      <AppText style={styles.label}>{label}</AppText>
    </View>
  );
};

export default React.memo(CompanyItemLabel);
