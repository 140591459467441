import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, ViewStyle} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import Carousel, {
  CarouselRenderItem,
  ICarouselInstance,
} from 'react-native-reanimated-carousel';
import {useModalContext} from '../../../contexts/ModalContext';
import useElementSize from '../../../hooks/useElementSize';
import RegistrationCredentialsSlide from '../../../mindance-libs/components/RegistrationSlides/RegistrationCredentialsSlide';
import RegistrationPersonalInfoSlide from '../../../mindance-libs/components/RegistrationSlides/RegistrationPersonalInfoSlide';
import RegistrationPrivacySlide from '../../../mindance-libs/components/RegistrationSlides/RegistrationPrivacySlide';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import useErrorMessage from '../../../mindance-libs/hooks/useErrorMessage';
import useRegistration from '../../../mindance-libs/hooks/useRegistration';
import spacings from '../../../mindance-libs/theme/spacings';
import {
  Credentials,
  PersonalInfo,
  RegistrationSlideProps,
  RegistrationSlides,
} from '../../../mindance-libs/types/registration';
import breakpointProvider from '../../../mindance-libs/utils/breakpointProvider';
import {getLanguage} from '../../../mindance-libs/utils/i18next/helpers';
import {REGISTRATION_SLIDES} from '../../../mindance-libs/utils/registration';
import {RouteKeys} from '../../../navigation/RouteKeys';
import useTypedLocation from '../../../navigation/typed/useTypedLocation';
import useTypedNavigate from '../../../navigation/typed/useTypedNavigate';
import useTypedSearchParams from '../../../navigation/typed/useTypedSearchParams';
import styles from './page.module.scss';

const DEFAULT_BOTTOM_SPACE = 100;
const MIN_BOTTOM_SPACE = spacings.xxxl;

const slideContainerStyle: StyleProp<ViewStyle> = {
  display: 'flex',
  flex: 1,
};

export default function Page() {
  const {i18n} = useTranslation();
  const navigate = useTypedNavigate();
  const {state: initialState} = useTypedLocation<RouteKeys.Registration>();
  const [{ac: activationCode}] = useTypedSearchParams<RouteKeys.Registration>();

  const {error, showMessage} = useErrorMessage();
  const {openModal} = useModalContext();

  const carouselRef = useRef<ICarouselInstance>(null);

  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    onLoad: onCarouselLoad,
    ref: carouselContainerRef,
    size: carouselContainerSize,
  } = useElementSize<HTMLDivElement>();

  const goToNextSlide = useCallback(() => {
    carouselRef.current?.next({animated: true, count: 1});
  }, []);

  const goToPrevSlide = useCallback(() => {
    carouselRef.current?.prev({animated: true, count: 1});
  }, []);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const openEmailVerificationScreen = useCallback<
    (state: Credentials & PersonalInfo) => void
  >(
    state => {
      navigate(
        {to: RouteKeys.RegistrationEmailVerification, params: {}},
        {
          state: {
            ...state,
            queryParams: activationCode ? {ac: activationCode} : undefined,
          },
          replace: true,
        },
      );
    },
    [activationCode, navigate],
  );

  const registrationInitialState = useMemo<
    (Credentials & PersonalInfo) | undefined
  >(() => {
    if (!initialState && !activationCode) {
      return;
    }

    return {
      email: initialState?.email || '',
      firstName: initialState?.firstName || '',
      lastName: initialState?.lastName || '',
      password: initialState?.password || '',
      activationCode: initialState?.activationCode || activationCode,
      language: initialState?.language || getLanguage(i18n.language),
      gender: initialState?.gender || undefined,
      age: initialState?.age || undefined,
    };
  }, [activationCode, i18n.language, initialState]);

  const {submit, fillPersonalInfo} = useRegistration({
    initialState: registrationInitialState,
    onSaveInfo: goToNextSlide,
    onSubmit: openEmailVerificationScreen,
    onError: showMessage,
  });

  const bottomSpace =
    window.innerWidth <= breakpointProvider.$gridBreakpoints.sm
      ? MIN_BOTTOM_SPACE
      : DEFAULT_BOTTOM_SPACE;

  const slideStyle = useMemo<StyleProp<ViewStyle>>(
    () => ({
      paddingBottom: bottomSpace,
      maxWidth: breakpointProvider.$gridBreakpoints.sm,
      alignSelf: 'center',
    }),
    [bottomSpace],
  );

  const openPrivacyPolicy = useCallback(() => {
    openModal(RouteKeys.PrivacyPolicy);
  }, [openModal]);

  const openTermsAndConditions = useCallback(() => {
    openModal(RouteKeys.TermsAndConditions);
  }, [openModal]);

  const renderItem = useCallback<CarouselRenderItem<RegistrationSlides>>(
    ({item, index}) => {
      const commonProps: RegistrationSlideProps = {
        focused: currentIndex === index,
        style: slideStyle,
        contentButtonStyle: {paddingHorizontal: spacings.xxxxl},
        onBackButtonPress: goToPrevSlide,
        onSubmitButtonPress: goToNextSlide,
        onError: showMessage,
      };

      const Slide = {
        [RegistrationSlides.PRIVACY]: (
          <RegistrationPrivacySlide
            {...commonProps}
            onBackButtonPress={goBack}
          />
        ),
        [RegistrationSlides.PERSONAL_INFO]: (
          <RegistrationPersonalInfoSlide
            {...commonProps}
            initialValues={{
              age: registrationInitialState?.age,
              firstName: registrationInitialState?.firstName ?? '',
              lastName: registrationInitialState?.lastName ?? '',
              gender: registrationInitialState?.gender,
            }}
            onSubmitButtonPress={fillPersonalInfo}
          />
        ),
        [RegistrationSlides.CREDENTIALS]: (
          <RegistrationCredentialsSlide
            {...commonProps}
            initialValues={{
              activationCode: registrationInitialState?.activationCode ?? '',
              confirmPassword: registrationInitialState?.password ?? '',
              email: registrationInitialState?.email ?? '',
              password: registrationInitialState?.password ?? '',
            }}
            onSubmitButtonPress={submit}
            onPrivacyPolicyPress={openPrivacyPolicy}
            onTermsAndConditionsPress={openTermsAndConditions}
            isActivationCodeFieldDisplayed={!activationCode}
          />
        ),
      }[item];

      return (
        <ScrollView contentContainerStyle={slideContainerStyle}>
          {Slide}
        </ScrollView>
      );
    },
    [
      activationCode,
      currentIndex,
      fillPersonalInfo,
      goBack,
      goToNextSlide,
      goToPrevSlide,
      openPrivacyPolicy,
      openTermsAndConditions,
      registrationInitialState?.activationCode,
      registrationInitialState?.age,
      registrationInitialState?.email,
      registrationInitialState?.firstName,
      registrationInitialState?.gender,
      registrationInitialState?.lastName,
      registrationInitialState?.password,
      showMessage,
      slideStyle,
      submit,
    ],
  );

  return (
    <ScreenBase error={error}>
      <div className={styles.registration__container}>
        <div
          className={styles.registration__carousel}
          ref={carouselContainerRef}
          onLoad={onCarouselLoad}>
          {carouselContainerSize.width > 0 ? (
            <Carousel
              defaultIndex={initialState ? REGISTRATION_SLIDES.length - 1 : 0}
              data={REGISTRATION_SLIDES}
              renderItem={renderItem}
              vertical={false}
              loop={false}
              autoPlay={false}
              width={carouselContainerSize.width}
              height={carouselContainerSize.height}
              windowSize={REGISTRATION_SLIDES.length}
              ref={carouselRef}
              enabled={false}
              pagingEnabled
              onSnapToItem={setCurrentIndex}
            />
          ) : null}
        </div>
      </div>
    </ScreenBase>
  );
}
