import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
import colors from '../theme/colors';

const StatisticsIcon: React.FC<SvgProps> = ({...props}) => {
  return (
    <Svg
      fill={colors.white}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      {...props}>
      <Path d="M6,13H2c-0.6,0-1,0.4-1,1c0,0,0,0,0,0v8c0,0.6,0.4,1,1,1c0,0,0,0,0,0h4c0.6,0,1-0.4,1-1c0,0,0,0,0,0v-8C7,13.4,6.6,13,6,13C6,13,6,13,6,13z M22,9h-4c-0.6,0-1,0.4-1,1c0,0,0,0,0,0v12c0,0.6,0.4,1,1,1c0,0,0,0,0,0h4c0.6,0,1-0.4,1-1c0,0,0,0,0,0V10C23,9.4,22.6,9,22,9C22,9,22,9,22,9z M14,1h-4C9.4,1,9,1.4,9,2c0,0,0,0,0,0v20c0,0.6,0.4,1,1,1c0,0,0,0,0,0h4c0.6,0,1-0.4,1-1c0,0,0,0,0,0V2C15,1.4,14.6,1,14,1C14,1,14,1,14,1z" />
    </Svg>
  );
};

export default React.memo(StatisticsIcon);
