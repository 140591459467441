import React, {useEffect, useMemo, useState} from 'react';
import {View} from 'react-native';
import {Subtitle, SubtitlesProps} from '../../types/subtitles';
import {subtitleParser} from '../../utils/subtitles';
import AppText from '../AppText';
import styles from './styles';

const Subtitles: React.FC<SubtitlesProps> = ({
  subtitle,
  currentTime,
  containerStyle,
  textStyle,
}): JSX.Element => {
  const [subtitles, setSubtitles] = useState<Subtitle[]>([]);

  const [text, setText] = useState('');

  const mergedContainerStyle = useMemo(
    () => [styles.container, containerStyle],
    [containerStyle],
  );

  const mergedTextStyle = useMemo(() => [styles.text, textStyle], [textStyle]);

  useEffect(() => {
    subtitleParser(subtitle.file).then(result => setSubtitles(result));
  }, [subtitle.file]);

  useEffect(() => {
    if (!subtitles || subtitles.length === 0) {
      return;
    }

    let start = 0;
    let end: number = subtitles.length - 1;

    while (start <= end) {
      const mid: number = Math.floor((start + end) / 2);

      const currentSubtitle: Subtitle = subtitles[mid] || {
        start: 0,
        end: 0,
        part: '',
      };

      if (
        currentTime >= currentSubtitle.start &&
        currentTime <= currentSubtitle.end
      ) {
        setText(currentSubtitle.part.trim());
        return;
      } else if (currentTime < currentSubtitle.start) {
        end = mid - 1;
      } else {
        start = mid + 1;
      }
    }

    return setText('');
  }, [currentTime, subtitles]);

  return (
    <View style={mergedContainerStyle}>
      {text ? <AppText style={mergedTextStyle}>{text}</AppText> : null}
    </View>
  );
};

export default React.memo(Subtitles);
