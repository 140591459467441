import React from 'react';
import Svg, {G, Mask, Path, SvgProps} from 'react-native-svg';
import colors from '../theme/colors';

const NextArrowIcon: React.FC<SvgProps> = ({
  stroke = colors.black100,
  ...props
}) => {
  return (
    <Svg width={26} height={26} viewBox="0 0 26 26" fill="none" {...props}>
      <Mask
        id="mask0_669_5370"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={26}
        height={26}>
        <Path
          d="M12.625 -1.26428e-06L0 12.625L12.625 25.25L25.25 12.625L12.625 -1.26428e-06Z"
          fill="white"
        />
      </Mask>
      <G mask="url(#mask0_669_5370)">
        <Path
          d="M12.6249 7.36458L17.8853 12.625M17.8853 12.625L12.6249 17.8854M17.8853 12.625H7.3645"
          stroke={stroke}
          strokeWidth={1.85984}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  );
};

export default React.memo(NextArrowIcon);
