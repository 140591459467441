import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  absolute: {
    ...StyleSheet.absoluteFillObject,
  },
  video: {
    ...StyleSheet.absoluteFillObject,
  },
  loadingContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.black95Alpha10,
  },
  audio: {
    height: 0,
    width: 0,
    opacity: 0,
  },
  audioAnimationWrapper: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
  audioAnimation: {
    width: '85%',
    height: '100%',
  },
  audioAnimationWeb: {
    width: '70%',
    height: 'auto',
  },
  gradientBackground: {
    flex: 1,
  },
  content: {
    flex: 1,
    justifyContent: 'space-between',
  },
  label: {
    ...typography.title4,
    color: colors.whiteAlpha95,
    marginBottom: spacings.m,
    marginHorizontal: spacings.l,
    textAlign: 'center',
  },
  nextButton: {
    alignSelf: 'center',
    marginTop: spacings.m,
  },
  subtitlesContainer: {
    position: 'absolute',
    bottom: spacings.l,
    alignSelf: 'center',
  },
});

export default styles;
