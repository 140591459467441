import React from 'react';
import {Maybe, Tags} from '../generated/graphql';

export enum Languages {
  GERMAN = 'de-DE',
  ENGLISH = 'en-US',
}

export enum Countries {
  GERMANY = 'DE',
  USA = 'US',
  AUSTRIA = 'AT',
  CZECH_REPUBLIC = 'CZ',
}

export type FilterButtonType = {
  id: string;
  label: string;
  isSelected: boolean;
};

export type SelectedTagsRecord = Record<Tags['id'], boolean>;

export type CustomTag = {
  id: Tags['id'];
  label?: Maybe<string>;
  Icon?: React.FC;
};

export type CustomTagGroup = {
  id: string;
  label?: Maybe<string>;
  tags?: Maybe<CustomTag[]>;
};

export enum DataStatuses {
  PUBLISHED = 'published',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
}

export enum AppProductsLabels {
  ONBOARDING = 'ONBOARDING',

  HOME = 'HOME',
  LIBRARY = 'LIBRARY',
  BOOKING = 'BOOKING',
  NEWS = 'NEWS',

  EVENT = 'EVENT',
  EVENT_EVENTTOOL = 'EVENT_EVENTTOOL',
  GROUP_COACHING = 'SHOW_GROUP_COACHING',
  ONE_TO_ONE_SESSION = 'SHOW_ONETOONESESSION_COACHING',

  COURSES = 'COURSES',
  SESSIONS = 'SESSIONS',
}

export type ScreensSetupCategoriesAvailability = Record<
  AppProductsLabels,
  boolean
>;
