import React from 'react';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import SidebarNavSection, {
  NavSection,
} from './SidebarNavSection/SidebarNavSection';
import {useLocation} from 'react-router-dom';

type Props = {
  loading: boolean;
  sectionsData: NavSection[];
};

const SidebarNavigation = ({loading, sectionsData}: Props) => {
  const {pathname} = useLocation();

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {sectionsData?.map((section, index) => (
        <SidebarNavSection
          key={section.title}
          section={section}
          isLastSection={index === sectionsData.length - 1}
          pathname={pathname}
        />
      ))}
    </>
  );
};

export default React.memo(SidebarNavigation);
