import * as R from 'ramda';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSubscriptionsContext} from '../contexts/SubscriptionsContext';
import {
  InputMaybe,
  MindManagerNewsDetailsFragment,
  ModifiedDirectusNestedInput,
  News_TranslationsCreateWithoutNewsInput,
  News_TranslationsUpdateWithWhereUniqueWithoutNewsInput,
  useCreateOneNewsMutation,
  useFindManyMindManagerNewsQuery,
  useUpdateNewsMutation,
} from '../mindance-libs/generated/graphql';
import {useAppContext} from '../mindance-libs/store/contexts/AppContext';
import {DataStatuses, Languages} from '../mindance-libs/types/common';
import {reportError} from '../mindance-libs/utils/loggingHelpers';
import {NEWS_LANGUAGES} from '../pages/sidebar/newstool/details/page.data';
import useFileState from './useFileState';
import useNewsDetailsSubscriptions from './useNewsDetailsSubscriptions';
import useNewsInputFields from './useNewsInputFields';

const useNewsDetails = (
  id?: string,
  editMode = false,
  options?: {
    onError?: (error: string) => void;
    onResetError?: () => void;
    onCreatedOneNews?: (id: string) => void;
    onUpdatedOneNews?: () => void;
    onFileUpload?: (file: File) => Promise<string | undefined>;
    onFileRemove?: () => void;
  },
) => {
  const {t} = useTranslation();

  const {user} = useAppContext();
  const {selectedSubscriptionId} = useSubscriptionsContext();

  const {data, loading, error, previousData} = useFindManyMindManagerNewsQuery({
    skip: R.isNil(id),
    variables: {
      where: {id: id ? {equals: Number(id)} : undefined},
      take: 1,
      skip: 0,
    },
    onError: err => reportError('useFindManyMindManagerNewsQuery', err),
  });

  const [createOneNewsMutation] = useCreateOneNewsMutation();

  const [updateNewsMutation] = useUpdateNewsMutation();

  const newsData = useMemo<MindManagerNewsDetailsFragment | undefined>(
    () => data?.findManyMindManagerNews?.[0],
    [data?.findManyMindManagerNews],
  );

  const {
    areFieldsEmpty,
    changeEnabledStatus,
    changeInputValues,
    inputValue,
    isDataChanged,
    isNewsPinned,
    pinned,
    setIsDataChanged,
    setPinned,
  } = useNewsInputFields({
    editMode,
    loading,
    data: newsData,
  });

  const {fileState, removeSelectedFile, selectLocalFile} = useFileState(
    newsData?.directus_files?.filename_disk ?? undefined,
  );

  const {
    SubscriptionModal,
    selectedSubscriptionIds,
    showModal,
    subscriptionsRecord,
  } = useNewsDetailsSubscriptions(newsData?.Subscriptions_News);

  const editorName = newsData?.Users_News_AuthorUpdatedToUsers
    ? `${newsData.Users_News_AuthorUpdatedToUsers.FirstName} ${newsData.Users_News_AuthorUpdatedToUsers.LastName}`
    : newsData?.Users_News_AuthorCreatedToUsers
    ? `${newsData.Users_News_AuthorCreatedToUsers.FirstName} ${newsData.Users_News_AuthorCreatedToUsers.LastName}`
    : undefined;

  const languagesIds = useMemo<Partial<Record<Languages, string>>>(
    () =>
      NEWS_LANGUAGES.reduce<Partial<Record<Languages, string>>>(
        (acc, language) => {
          const translation = newsData?.News_translations?.find(
            ({languages_code}) => languages_code === language,
          );

          return translation ? {...acc, [language]: translation.id} : acc;
        },
        {},
      ),
    [newsData?.News_translations],
  );

  const changePinnedState = useCallback(async () => {
    if (!id || editMode || !newsData) {
      setPinned(value => !value);
      setIsDataChanged(true);
      return;
    }

    try {
      await updateNewsMutation({
        variables: {
          where: {id: Number(id)},
          data: {
            date_updated: {set: new Date().toISOString()},
            Pinned: {set: !newsData?.Pinned},
          },
        },
        optimisticResponse: () => ({
          updateNews: {
            ...newsData,
            Pinned: !newsData?.Pinned,
          },
        }),
      });
    } catch (err) {
      reportError('changePinnedState error', err);
      options?.onError?.(t('errors.something_went_wrong') ?? undefined);
    }
  }, [
    editMode,
    id,
    newsData,
    options,
    setIsDataChanged,
    setPinned,
    t,
    updateNewsMutation,
  ]);

  const createOneNews = useCallback<() => Promise<void>>(async () => {
    if (!selectedSubscriptionId) {
      return;
    }

    if (!isDataChanged || areFieldsEmpty) {
      options?.onError?.(
        t('mindManagerNewstool.fields_are_empty') ?? undefined,
      );
      return;
    }

    try {
      const file = fileState.shouldBeEmpty
        ? undefined
        : fileState.hasLocalFile?.state
        ? fileState.hasLocalFile.file
        : undefined;
      const fileId = file ? await options?.onFileUpload?.(file) : undefined;

      const languagesInput: News_TranslationsCreateWithoutNewsInput[] =
        NEWS_LANGUAGES.map<News_TranslationsCreateWithoutNewsInput>(
          language => ({
            Label: inputValue[language].title,
            Description: inputValue[language].description,
            Enabled: inputValue[language].enabled,
            languages: {connect: {code: language}},
          }),
        );

      const {data: createdOneNews} = await createOneNewsMutation({
        variables: {
          data: {
            subscription_ids: selectedSubscriptionIds.map(Number),
            Pinned: isNewsPinned,
            status: DataStatuses.PUBLISHED,
            directus_users_News_user_createdTodirectus_users: {
              connect: {id: user?.id},
            },
            News_translations: {create: languagesInput},
            ...(fileId ? {directus_files: {connect: {id: fileId}}} : {}),
          },
        },
      });

      if (createdOneNews?.createOneNews.id) {
        setIsDataChanged(false);
        options?.onResetError?.();
        options?.onCreatedOneNews?.(createdOneNews.createOneNews.id);
      }
    } catch (err) {
      reportError('createOneNews error', err);
      options?.onError?.(t('errors.something_went_wrong') ?? undefined);
    }
  }, [
    areFieldsEmpty,
    createOneNewsMutation,
    fileState.hasLocalFile,
    fileState.shouldBeEmpty,
    inputValue,
    isDataChanged,
    isNewsPinned,
    options,
    selectedSubscriptionId,
    selectedSubscriptionIds,
    setIsDataChanged,
    t,
    user?.id,
  ]);

  const updateOneNews = useCallback(async () => {
    if (!id) {
      return;
    }

    if (areFieldsEmpty) {
      options?.onError?.(
        t('mindManagerNewstool.fields_are_empty') ?? undefined,
      );
      return;
    }

    try {
      const file = fileState.shouldBeEmpty
        ? undefined
        : fileState.hasLocalFile?.state
        ? fileState.hasLocalFile.file
        : undefined;

      const fileId = file ? await options?.onFileUpload?.(file) : undefined;
      const fileIdToRemove =
        (fileState.shouldBeEmpty && fileState.hasRemote?.state) ||
        (fileState.hasLocalFile?.state && fileState.hasRemote?.state)
          ? newsData?.directus_files?.id
          : undefined;

      const directus_files: InputMaybe<ModifiedDirectusNestedInput> = {
        ...(fileId ? {connect: {id: fileId}} : {}),
        ...(fileIdToRemove && !fileId
          ? {delete: {id: {equals: fileIdToRemove}}}
          : {}),
      };

      const languagesInput: News_TranslationsUpdateWithWhereUniqueWithoutNewsInput[] =
        NEWS_LANGUAGES.map<News_TranslationsUpdateWithWhereUniqueWithoutNewsInput>(
          language => {
            const translationId = languagesIds[language];
            return {
              where: {
                languages_code: {equals: language},
                ...(translationId ? {id: Number(translationId)} : {}),
              },
              data: {
                Label: {set: inputValue[language].title},
                Description: {set: inputValue[language].description},
                Enabled: {set: inputValue[language].enabled},
              },
            };
          },
        );

      const {data: updatedOneNews} = await updateNewsMutation({
        variables: {
          where: {id: Number(id)},
          data: {
            date_updated: {set: new Date().toISOString()},
            News_translations: {update: languagesInput},
            Pinned: {set: pinned},
            ...(user?.id
              ? {
                  Users_News_AuthorUpdatedToUsers: {
                    connect: {id: Number(user.id)},
                  },
                }
              : {}),
            subscription_ids: selectedSubscriptionIds.map(Number),
            ...(!R.isEmpty(directus_files) ? {directus_files} : {}),
          },
        },
        refetchQueries: ['findManyMindManagerNews'],
      });

      if (updatedOneNews?.updateNews) {
        setIsDataChanged(false);
        options?.onResetError?.();
        options?.onUpdatedOneNews?.();
      }
    } catch (err) {
      reportError('updateOneNews error', err);
      options?.onError?.(t('errors.something_went_wrong') ?? undefined);
    }
  }, [
    areFieldsEmpty,
    fileState.hasLocalFile,
    fileState.hasRemote?.state,
    fileState.shouldBeEmpty,
    id,
    inputValue,
    languagesIds,
    newsData?.directus_files?.id,
    options,
    pinned,
    selectedSubscriptionIds,
    setIsDataChanged,
    t,
    updateNewsMutation,
    user?.id,
  ]);

  return useMemo(
    () => ({
      loading: loading && !previousData,
      error,
      data: {...newsData, Pinned: isNewsPinned},
      inputValue,
      editorName,
      isDataChanged,
      changePinnedState,
      changeInputValues,
      changeEnabledStatus,
      createOneNews,
      updateOneNews,
      selectLocalFile,
      removeSelectedFile,
      fileState,
      SubscriptionModal,
      showSubscriptionModal: showModal,
      subscriptionsRecord,
      selectedSubscriptionIds,
    }),
    [
      loading,
      previousData,
      error,
      newsData,
      isNewsPinned,
      inputValue,
      editorName,
      isDataChanged,
      changePinnedState,
      changeInputValues,
      changeEnabledStatus,
      createOneNews,
      updateOneNews,
      selectLocalFile,
      removeSelectedFile,
      fileState,
      SubscriptionModal,
      showModal,
      subscriptionsRecord,
      selectedSubscriptionIds,
    ],
  );
};

export default useNewsDetails;
