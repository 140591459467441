import classNames from 'classnames';
import * as R from 'ramda';
import React, {useMemo} from 'react';
import AppText from '../AppText';
import {BookingMetaInfoProps} from './props';
import RNstyles from './styles';
import styles from './styles.web.module.scss';
import useBookingMetaInfoData from '../../hooks/useBookingMetaInfoData';

const BookingMetaInfo: React.FC<BookingMetaInfoProps> = ({
  className,
  oneColumn = false,
  ...props
}) => {
  const mergedStyle = classNames(styles['booking-meta-info'], className);

  const data = useBookingMetaInfoData({...props});

  const Items = useMemo(
    () =>
      data.map(({Icon, label, type}) =>
        label ? (
          <div className={styles['booking-meta-info__item']} key={type}>
            <Icon width={16} height={16} />
            <AppText style={RNstyles.itemLabel}>{label}</AppText>
          </div>
        ) : null,
      ),
    [data],
  );

  const ColumnItems = useMemo(() => {
    const filteredItems = R.filter(R.isNotNil, Items);

    if (oneColumn) {
      return (
        <div className={styles['booking-meta-info__column']}>
          {filteredItems}
        </div>
      );
    }

    const middleItemIndex = Math.ceil(filteredItems.length / 2);

    const firstColumn = filteredItems.slice(0, middleItemIndex);
    const secondColumn = filteredItems.slice(middleItemIndex);

    return [firstColumn, secondColumn].map((columnItems, index) => (
      <div
        key={index.toString()}
        className={styles['booking-meta-info__column']}>
        {columnItems}
      </div>
    ));
  }, [Items, oneColumn]);

  return <div className={mergedStyle}>{ColumnItems}</div>;
};

export default React.memo(BookingMetaInfo);
