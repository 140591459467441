export enum StatusInfoType {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

type StatusInfoProps = {
  status: string;
  className?: string;
  testID?: string;
};

export default StatusInfoProps;
