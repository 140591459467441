import {JwtPayload} from 'jwt-decode';
import {Languages} from './common';
import {Gender} from './user';

export enum SystemCodes {
  SUCCESS = 'SUCCESS',
  ERROR = 'BAD_REQUEST',
}

export type TokenStatus = 'SUCCESS' | 'EXPIRED' | 'FAIL';

export type OnLogin = (
  email: string,
  password: string,
  options?: {
    showError?: boolean;
    activationCode?: string;
    onError?: (error?: unknown) => void;
    onSuccess?: (email: string) => Promise<void>;
  },
) => Promise<void>;

export type OnLogout = () => Promise<void>;

export type OnObtainNewTokens = () => Promise<{
  accessToken: string;
  refreshToken: string;
}>;

export type OnObtainMindManagerEventtoolSSOLink = (
  currentSubscriptionId: number,
) => Promise<MindManagerEventtoolSSOLinkResponse | undefined>;

export type OnDeleteAccount = () => Promise<void>;

export type OnRegister = (
  data: RegisterData,
  options?: {
    onSuccess?: (email: string) => Promise<void>;
  },
) => Promise<{success: boolean}>;

export type OnForgetPassword = (
  email: string,
) => Promise<ForgetPasswordResponse>;

export type OnResetPasswordValidate = (
  token: string,
) => Promise<ResetPasswordValidationResponse>;

export type OnEmailVerification = (
  token: string,
) => Promise<EmailVerificationResponse>;

export type OnResetPassword = (
  token: string,
  password: string,
) => Promise<ResetPasswordResponse>;

export type OnResendVerification = (
  email?: string,
) => Promise<{success: boolean}>;

export type OnChangePassword = (
  currentPassword: string,
  newPassword: string,
) => Promise<ChangePasswordResponse>;

export enum UsersRolesUpdateType {
  UPDATED = 'UPDATED',
  NO_UPDATE = 'NO_UPDATE',
  MINDMANAGER_ADDED = 'MINDMANAGER_ADDED',
  MINDMANAGER_REMOVED = 'MINDMANAGER_REMOVED',
}

export type OnValidateUsersRoles = () => Promise<
  {isValid: boolean; usersRolesUpdateType?: UsersRolesUpdateType} | undefined
>;

export type AuthErrorType = 'activation_code' | 'unknown' | 'credentials';

export type OnError = (
  error: string,
  details?: {errorType?: AuthErrorType},
) => void;

export type AuthError = {
  systemCode: string;
  message: string[];
};

export type RegisterData = {
  email: string;
  activationCode?: string;
  password: string;
  firstName: string;
  lastName: string;
  age?: number;
  gender?: Gender;
  language: Languages;
};

export type ForgetPassword = {
  email: string;
  language: Languages;
};

export type ResetPasswordValidation = {
  token: string;
};

export type ResendVerificationToken = {
  email: string;
  language: Languages;
};

export type VerifyEmailVerification = {
  token: string;
};

export type VerifyEmailResponse = {
  systemCode: SystemCodes;
  data?: object;
  message?: object;
};

export type ResetPassword = {
  token: string;
  password: string;
};

export type ChangePasswordData = {
  currentPassword: string;
  newPassword: string;
};

export type ChangePasswordResponse = {
  success: boolean;
  message?: string;
};

export type DeleteAccountResponse = {
  success: boolean;
};

export type ResetPasswordResponse = {
  systemCode: SystemCodes;
  data?: object;
  message?: object;
};

export type ResetPasswordValidationResponse = {
  state: TokenStatus;
};

export type EmailVerificationSuccessResponse = {
  systemCode: SystemCodes.SUCCESS;
  data: {
    state: TokenStatus;
  };
};

export type EmailVerificationFailResponse = {
  systemCode: SystemCodes.ERROR;
  message: string | string[] | object;
};

export type EmailVerificationResponse =
  | EmailVerificationSuccessResponse
  | EmailVerificationFailResponse;

export type LoginResponse = {
  accessToken: string;
  expiresIn: number;
  refreshExpiresIn: number;
  refreshToken: string;
  tokenType: string;
  idToken: string;
  notBeforePolicy: number;
  sessionState: string;
  scope: string;
};

export type RegisterResponse = {
  createdInKeycloak: boolean;
  createdInUserService: boolean;
  sendVerificationEmail: boolean;
  user: string; //email
};

export type ForgetPasswordResponse = {
  success: boolean;
};

export type LogoutResponse = {
  success: boolean;
};

export type MindManagerEventtoolSSOLinkResponse = {
  link: string;
};

export type AuthCredentials = {
  email: string | null;
  password: string | null;
};

export type OnHandleAccessTokenError = <T>(
  error: any,
  operation: (accessToken: string, refreshToken: string) => Promise<T>,
) => Promise<T | undefined>;

export type AccessTokenJwtPayload = JwtPayload & {
  azp: string;
};

export type ValidateUsersRolesResponse =
  | {isValid: true; usersRolesUpdateType?: UsersRolesUpdateType}
  | {
      isValid: false;
      usersRolesUpdateType?: UsersRolesUpdateType;
      userData: LoginResponse;
    };
