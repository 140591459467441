import {useAnimate} from 'framer-motion';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, ViewStyle, useWindowDimensions} from 'react-native';
import Carousel, {
  CarouselRenderItem,
  ICarouselInstance,
} from 'react-native-reanimated-carousel';
import useElementSize from '../../hooks/useElementSize';
import OnboardingHeader from '../../mindance-libs/components/OnboardingHeader';
import OnboardingDescriptionSlide from '../../mindance-libs/components/OnboardingSlides/OnboardingDescriptionSlide';
import OnboardingIntroSlide from '../../mindance-libs/components/OnboardingSlides/OnboardingIntroSlide';
import OnboardingSummarySlide from '../../mindance-libs/components/OnboardingSlides/OnboardingSummarySlide';
import OnboardingSurveySlide from '../../mindance-libs/components/OnboardingSlides/OnboardingSurveySlide';
import ScreenBase from '../../mindance-libs/components/ScreenBase';
import useErrorMessage from '../../mindance-libs/hooks/useErrorMessage';
import useOnboardingTags from '../../mindance-libs/hooks/useOnboardingTags';
import useProgressReports from '../../mindance-libs/hooks/useProgressReports';
import {useAppContext} from '../../mindance-libs/store/contexts/AppContext';
import spacings from '../../mindance-libs/theme/spacings';
import {
  OnboardingSlideProps,
  OnboardingSlides,
} from '../../mindance-libs/types/onboarding';
import {ONBOARDING_SLIDES} from '../../mindance-libs/utils/onboarding';
import NavigationKeys from '../../navigation/NavigationKeys';
import breakpointProvider from '../../mindance-libs/utils/breakpointProvider';
import styles from './page.module.scss';

const DEFAULT_BOTTOM_SPACE = 100;
const MIN_BOTTOM_SPACE = spacings.xxxl;

export default function Page() {
  const {t} = useTranslation();

  const {error, showMessage} = useErrorMessage();

  const {width: windowWidth} = useWindowDimensions();

  const carouselRef = useRef<ICarouselInstance>(null);
  const hasReported = useRef(false);

  const [headerRef, animateHeader] = useAnimate();

  const {
    onLoad: onCarouselLoad,
    ref: carouselContainerRef,
    size: carouselContainerSize,
  } = useElementSize<HTMLDivElement>();

  const [currentProgress, setCurrentProgress] = useState(0);

  const {
    loading: tagsLoading,
    selectTag,
    selectedTags,
    selectedTagsRecord,
    tagGroups,
  } = useOnboardingTags();

  const {updateUserTags, user} = useAppContext();
  const {reportOpenScreen} = useProgressReports();

  const progressBarDisplayed = currentProgress < ONBOARDING_SLIDES.length - 1;

  const goNext = useCallback(() => {
    carouselRef.current?.next({animated: true, count: 1});
  }, []);

  const goBack = useCallback(() => {
    carouselRef.current?.prev({animated: true, count: 1});
  }, []);

  const submit = useCallback(async () => {
    try {
      await updateUserTags(selectedTags);
    } catch (err) {
      showMessage(t('errors.something_went_wrong'));
    }
  }, [selectedTags, showMessage, t, updateUserTags]);

  const bottomSpace =
    windowWidth <= breakpointProvider.$gridBreakpoints.sm
      ? MIN_BOTTOM_SPACE
      : DEFAULT_BOTTOM_SPACE;

  const slideStyle = useMemo<StyleProp<ViewStyle>>(
    () => ({
      paddingBottom: bottomSpace,
    }),
    [bottomSpace],
  );

  const renderItem = useCallback<CarouselRenderItem<OnboardingSlides>>(
    ({item, index}) => {
      const focused = currentProgress === index;

      const isLastUnit = index === ONBOARDING_SLIDES.length - 1;

      const commonProps: OnboardingSlideProps = {
        focused,
        index,
        onButtonPress: isLastUnit ? submit : goNext,
        contentButtonStyle: {paddingHorizontal: spacings.xxxxl},
      };

      const Slide = {
        [OnboardingSlides.INTRO]: (
          <OnboardingIntroSlide {...commonProps} style={slideStyle} />
        ),
        [OnboardingSlides.DESCRIPTION]: (
          <OnboardingDescriptionSlide {...commonProps} style={slideStyle} />
        ),
        [OnboardingSlides.SURVEY]: (
          <OnboardingSurveySlide
            {...commonProps}
            loading={tagsLoading}
            onSelectTag={selectTag}
            selectedTagsRecord={selectedTagsRecord}
            tagGroups={tagGroups}
            buttonStyle={{marginBottom: bottomSpace}}
          />
        ),
        [OnboardingSlides.SUMMARY]: (
          <OnboardingSummarySlide
            {...commonProps}
            style={slideStyle}
            onAnimationEnd={submit}
          />
        ),
      }[item];

      return Slide;
    },
    [
      bottomSpace,
      currentProgress,
      goNext,
      selectTag,
      selectedTagsRecord,
      slideStyle,
      submit,
      tagGroups,
      tagsLoading,
    ],
  );

  const updateProgress = useCallback<
    (offsetProgress: number, absoluteProgress: number) => void
  >((_, offset) => {
    setCurrentProgress(Math.round(offset));
  }, []);

  useEffect(() => {
    animateHeader(
      headerRef.current,
      {opacity: progressBarDisplayed ? 1 : 0},
      {duration: 0.5},
    );
  }, [animateHeader, headerRef, progressBarDisplayed]);

  useEffect(() => {
    if (hasReported.current) {
      return;
    }

    const email = user?.EMail;
    if (email) {
      reportOpenScreen({email, screen: NavigationKeys.OnboardingScreen});
      hasReported.current = true;
    }
  }, [reportOpenScreen, user?.EMail]);

  return (
    <ScreenBase error={error}>
      <div className={styles.onboarding__container}>
        <OnboardingHeader
          ref={headerRef}
          canGoBack={currentProgress !== 0}
          currentPage={currentProgress + 1}
          totalPages={ONBOARDING_SLIDES.length}
          onBack={goBack}
          pointerEvents={!progressBarDisplayed ? 'none' : undefined}
        />

        <div
          className={styles.onboarding__carousel}
          ref={carouselContainerRef}
          onLoad={onCarouselLoad}>
          {carouselContainerSize.width > 0 ? (
            <Carousel
              data={ONBOARDING_SLIDES}
              renderItem={renderItem}
              vertical={false}
              loop={false}
              autoPlay={false}
              width={carouselContainerSize.width}
              height={carouselContainerSize.height}
              windowSize={ONBOARDING_SLIDES.length}
              ref={carouselRef}
              enabled={false}
              pagingEnabled
              onProgressChange={updateProgress}
            />
          ) : null}
        </div>
      </div>
    </ScreenBase>
  );
}
