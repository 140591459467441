import {faCirclePlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as R from 'ramda';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import Button from '../../../components/Button/Button';
import {AnchorTargetAttribute} from '../../../components/Button/Button.props';
import ItemsList from '../../../components/ItemsList/ItemsList';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay';
import NewsItem from '../../../components/NewsItem/NewsItem';
import StatusFilter from '../../../components/StatusFilter/StatusFilter';
import {StatusFilterOptions} from '../../../components/StatusFilter/StatusFilter.props';
import SubscriptionsDropdown from '../../../components/SubscriptionsDropdown/SubscriptionsDropdown';
import {useSubscriptionsContext} from '../../../contexts/SubscriptionsContext';
import useMindManagerNewsList from '../../../hooks/useMindManagerNewsList';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import {MindManagerNewsDetailsFragment} from '../../../mindance-libs/generated/graphql';
import {ProductLabels} from '../../../mindance-libs/types/products';
import {RouteKeys} from '../../../navigation/RouteKeys';
import {buildUrl, generateUrlWithParams} from '../../../navigation/utils';
import {getFilteredDataByStatus} from './page.data';
import styles from './page.module.scss';

export default function Page() {
  const {t, i18n} = useTranslation();
  const {pathname} = useLocation();

  const {
    mindManagerTools,
    loading: toolsLoading,
    selectedSubscriptionsByProducts,
  } = useSubscriptionsContext();

  const [selectedFilter, setSelectedFilter] = useState(StatusFilterOptions.ALL);

  const {
    data,
    error,
    loadingMore: newsLoadingMore,
    loading: newsLoading,
    fetchNext,
  } = useMindManagerNewsList({shouldUseSingleSubscription: false});

  const isOneSubscriptionSelected =
    selectedSubscriptionsByProducts[
      ProductLabels.NEWSTOOL_SHOW_RELATED_SUBSCRIPTIONS
    ]?.length === 1;

  const title = mindManagerTools.find(item =>
    pathname.includes(item.routeKey ?? ''),
  )?.ManagerTools_translations?.[0]?.ShortTitle;

  const filteredData = useMemo(
    () => getFilteredDataByStatus(data, selectedFilter),
    [data, selectedFilter],
  );

  const renderItem = useCallback<
    (item: MindManagerNewsDetailsFragment, index: number) => JSX.Element
  >(
    item => {
      const currentTranslation =
        item.News_translations?.find(
          ({languages_code}) => languages_code === i18n.language,
        ) ?? item.News_translations?.[0];

      const enabledLocales = item.News_translations?.filter(
        ({Enabled}) => !!Enabled,
      ).map(({languages_code}) => languages_code) as string[];

      const disabledLocales = item.News_translations?.filter(
        ({Enabled}) => !Enabled,
      ).map(({languages_code}) => languages_code) as string[];

      const subscriptionsLabels = item.Subscriptions_News?.map(
        ({Subscriptions}) => Subscriptions?.Label,
      );
      const filteredSubscriptionsLabels = R.filter(
        R.isNotNil,
        subscriptionsLabels ?? [],
      );

      return (
        <NewsItem
          key={item.id}
          id={item.id}
          detailsHref={buildUrl(RouteKeys.NewstoolDetails, {id: item.id})}
          timestamp={item.date_created}
          firstName={item.Users_News_AuthorCreatedToUsers?.FirstName}
          lastName={item.Users_News_AuthorCreatedToUsers?.LastName}
          isPinned={item.Pinned ?? false}
          title={currentTranslation?.Label ?? null}
          content={currentTranslation?.Description ?? null}
          imageContent={item.directus_files}
          likesCount={item._count.NewsLikes}
          enabledLocales={enabledLocales}
          disabledLocales={disabledLocales}
          subscriptionsLabels={
            !isOneSubscriptionSelected ? filteredSubscriptionsLabels : undefined
          }
        />
      );
    },
    [i18n.language, isOneSubscriptionSelected],
  );

  if (toolsLoading || newsLoading) {
    return <LoadingOverlay />;
  }

  return (
    <ScreenBase>
      <div className={styles['news-tool__container']}>
        <div className={styles['news-tool__header']}>
          <div className={styles['news-tool__header-container']}>
            <h1 className={styles['news-tool__title']}>{title}</h1>
            <StatusFilter
              selected={selectedFilter}
              changeSelected={setSelectedFilter}
            />
          </div>
          <div
            className={classNames(
              styles['news-tool__header-container'],
              styles['news-tool__header-container--centered'],
            )}>
            <SubscriptionsDropdown
              productLabel={ProductLabels.NEWSTOOL_SHOW_RELATED_SUBSCRIPTIONS}
            />
            <Button
              className={styles['news-tool__create-news-button']}
              anchor={{
                href: generateUrlWithParams(
                  RouteKeys.NewstoolDetails,
                  {id: undefined},
                  {create: true},
                ),
                title: t('mindManagerNewstool.create_new_post') ?? '',
                target: AnchorTargetAttribute.SELF,
              }}>
              <FontAwesomeIcon
                icon={faCirclePlus}
                className={styles['news-tool__create-news-icon']}
              />
              <p className={styles['news-tool__create-news-title']}>
                {t('mindManagerNewstool.create_new_post')}
              </p>
            </Button>
          </div>
        </div>

        <ItemsList
          className={styles['news-tool__feed']}
          data={filteredData}
          loading={newsLoading}
          loadingMore={newsLoadingMore}
          renderItem={renderItem}
          error={error}
          onEndReached={fetchNext}
          emptyLabel={t('mindManagerNewstool.empty_state')}
        />
      </div>
    </ScreenBase>
  );
}
