import {useEffect, useRef} from 'react';

const useInitialData = <T>(data?: T[]) => {
  const previousData = useRef(data);
  const completed = useRef(false);

  useEffect(() => {
    if (completed.current) {
      return;
    }

    if (
      (data?.length &&
        previousData.current?.length &&
        previousData.current?.length < data?.length) ||
      !previousData.current
    ) {
      completed.current = true;
      return;
    }

    previousData.current = data;
  }, [data]);

  return previousData.current;
};

export default useInitialData;
