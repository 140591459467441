import {useLayout} from '@react-native-community/hooks';
import Lottie from 'lottie-react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import LoadingIndicator from '../../../components/LoadingIndicator/LoadingIndicator';
import useElementSize from '../../../hooks/useElementSize';
import audioAnimationData from '../../assets/animations/audio_wave_animation.json';
import useAVControlsAnimation from '../../hooks/useAVControlsAnimation';
import useWebAVStatus from '../../hooks/useWebAVStatus.web';
import {SubtitlesProps} from '../../types/subtitles';
import {isAudioUnits, isVideoUnits} from '../../utils/courses';
import AVControls from '../AVControls';
import Subtitles from '../Subtitles';
import {AVUnitSlideProps} from './props';
import RNstyles from './styles';
import styles from './styles.web.module.scss';

const ANIMATION_INITIAL_SEGMENT: [number, number] = [
  2,
  audioAnimationData.layers.length,
];

const AVUnitSlide: React.FC<AVUnitSlideProps> = ({
  Label,
  focused,
  onError,
  ...rest
}) => {
  const isVideoUnitType = isVideoUnits(rest);
  const isAudioUnitType = isAudioUnits(rest);

  const {t} = useTranslation();

  const [hasError, setHasError] = useState(false);

  const uri = isVideoUnitType
    ? rest.directus_files_VideoUnits_FileTodirectus_files?.filename_disk
    : isAudioUnitType
    ? rest.directus_files?.filename_disk
    : undefined;

  const {
    cancelAnimation,
    displayed,
    hideControlsWithDelay,
    hideControls,
    opacityAnimatedStyle,
    showControls,
    showControlsAndHideWidthDelay,
  } = useAVControlsAnimation();

  const {
    loading,
    audioRef,
    videoRef,
    audioAnimation,
    durationMillis,
    playing,
    positionMillis,
    videoOrientation,

    handlePauseEvent,
    handlePlayEvent,
    pauseAV,
    playAV,
    updateMetadata,
    updatePositionMillis,
    updateProgress,
    handleAVError,
    loadingEnd,
    loadingStart,
  } = useWebAVStatus({
    isAudioUnitType,
    src: uri,
    onPlay: isVideoUnitType ? hideControlsWithDelay : undefined,
    onPause: cancelAnimation,
    onFinish: showControls,
    onUpdateProgress: isVideoUnitType ? hideControlsWithDelay : undefined,
  });

  const {height: controlsHeight, onLayout: onControlsLayout} = useLayout();

  const {
    onLoad: onContainerLoad,
    ref: containerRef,
    size: containerSize,
  } = useElementSize<HTMLDivElement>();

  const {
    onLoad: onLabelLoad,
    ref: labelRef,
    size: labelSize,
  } = useElementSize<HTMLParagraphElement>();

  const subtitles = useMemo<SubtitlesProps['subtitle'] | undefined>(() => {
    const url = isVideoUnitType
      ? rest.directus_files_VideoUnits_SubtitlesTodirectus_files?.filename_disk
      : isAudioUnitType
      ? rest.directus_files_AudioUnits_SubtitlesTodirectus_files?.filename_disk
      : undefined;

    return url ? {file: url} : undefined;
  }, [isAudioUnitType, isVideoUnitType, rest]);

  const subtitlesStyle = useMemo<React.CSSProperties>(
    () => ({
      paddingBottom: displayed ? controlsHeight ?? 120 : 24,
      backgroundImage: displayed ? 'none' : undefined,
    }),
    [controlsHeight, displayed],
  );

  const shouldResizeContent =
    videoOrientation && videoOrientation === 'landscape' && isVideoUnitType;

  const slideStyle = useMemo<React.CSSProperties | undefined>(
    () => (shouldResizeContent ? {alignItems: 'center'} : undefined),
    [shouldResizeContent],
  );

  const contentStyle = useMemo<React.CSSProperties>(() => {
    if (!shouldResizeContent) {
      return {flex: 1};
    }

    const height = videoRef.current?.videoHeight;
    const width = videoRef.current?.videoWidth;

    if (!height || height === 0 || !width || width === 0) {
      return {flex: 1};
    }

    const aspectRatio = width / height;
    const maximumWidth = containerSize.width;
    const proposedHeight = maximumWidth / aspectRatio;
    const maximumHeight = containerSize.height - labelSize.height;

    if (proposedHeight <= maximumHeight) {
      return {height: proposedHeight};
    }

    const proposedWidth = maximumHeight * aspectRatio;

    if (proposedWidth <= maximumWidth) {
      return {width: proposedWidth, height: maximumHeight};
    }

    return {flex: 1};
  }, [
    containerSize.height,
    containerSize.width,
    labelSize.height,
    shouldResizeContent,
    videoRef,
  ]);

  const handleError = useCallback(() => {
    handleAVError();
    setHasError(true);
  }, [handleAVError]);

  useEffect(() => {
    if (!focused) {
      pauseAV();
      showControls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused]);

  useEffect(() => {
    if (focused && hasError) {
      onError?.(t('errors.something_went_wrong'));
      setHasError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, hasError]);

  return (
    <div
      className={styles['av-units-slide']}
      ref={containerRef}
      onLoad={onContainerLoad}
      style={slideStyle}>
      {Label ? (
        <p
          className={styles['av-units-slide__label']}
          ref={labelRef}
          onLoad={onLabelLoad}>
          {Label}
        </p>
      ) : null}

      <div className={styles['av-units-slide__content']} style={contentStyle}>
        {isAudioUnitType ? (
          <div className={styles['av-units-slide__content__audio']}>
            <audio
              src={uri ?? undefined}
              ref={audioRef}
              autoPlay={false}
              onPause={handlePauseEvent}
              onPlaying={handlePlayEvent}
              onTimeUpdate={updateProgress}
              onLoadedMetadata={updateMetadata}
              onError={handleError}
              onLoadStart={loadingStart}
              onCanPlay={loadingEnd}
            />
            <div
              className={styles['av-units-slide__content__audio__container']}>
              <Lottie
                lottieRef={audioAnimation}
                animationData={audioAnimationData}
                loop
                autoPlay={false}
                style={RNstyles.audioAnimationWeb}
                initialSegment={ANIMATION_INITIAL_SEGMENT}
              />

              {subtitles ? (
                <div
                  className={
                    styles[
                      'av-units-slide__content__audio__container__subtitles'
                    ]
                  }>
                  <Subtitles
                    currentTime={Math.round(positionMillis / 1000)}
                    subtitle={subtitles}
                  />
                </div>
              ) : null}
            </div>
            <AVControls
              duration={durationMillis}
              playing={playing}
              progress={positionMillis}
              onPause={pauseAV}
              onPlay={playAV}
              onSetPosition={updatePositionMillis}
            />

            {loading ? (
              <div
                className={styles['av-units-slide__content__loading-overlay']}>
                <LoadingIndicator />
              </div>
            ) : null}
          </div>
        ) : (
          <div
            className={styles['av-units-slide__content__video']}
            onMouseOver={showControls}
            onMouseLeave={hideControls}
            onMouseMove={showControlsAndHideWidthDelay}>
            <video
              src={uri ?? undefined}
              ref={videoRef}
              autoPlay={false}
              onPause={handlePauseEvent}
              onPlaying={handlePlayEvent}
              onTimeUpdate={updateProgress}
              onLoadedMetadata={updateMetadata}
              onError={handleError}
              className={styles['av-units-slide__content__video__player']}
              onLoadStart={loadingStart}
              onCanPlay={loadingEnd}
            />

            <div
              className={
                styles['av-units-slide__content__video__controls-container']
              }
              style={opacityAnimatedStyle}>
              <View onLayout={onControlsLayout}>
                <AVControls
                  duration={durationMillis}
                  playing={playing}
                  progress={positionMillis}
                  onPause={pauseAV}
                  onPlay={playAV}
                  onSetPosition={updatePositionMillis}
                />
              </View>
            </div>

            {subtitles ? (
              <div
                className={styles['av-units-slide__content__video__subtitles']}
                style={subtitlesStyle}>
                <Subtitles
                  currentTime={Math.round(positionMillis / 1000)}
                  subtitle={subtitles}
                />
              </div>
            ) : null}

            {loading ? (
              <div
                className={styles['av-units-slide__content__loading-overlay']}>
                <LoadingIndicator />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(AVUnitSlide);
