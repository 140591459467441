import {Dimensions} from 'react-native';
import spacings from '../theme/spacings';

export const DEFAULT_FUNCTION_HANDLER = () => () => {};

export const CONTACT_US_EMAIL = 'support@mindance.de';

export const APP_DEEP_LINK = 'mindance://';

export const PASSWORD_MASK = '*****************';

export const BOOKING_DETAILS_HORIZONTAL_PADDING = spacings.xxl;
export const COLLAPSED_HEADER_HEIGHT = 64;
export const EXPANDED_HEADER_HEIGHT = Dimensions.get('window').height / 3;
export const HEADER_TEXT_HEIGHT = 48;

export const EURO_SYMBOL = '€';

export const YOUTUBE_LINK = 'https://www.youtube.com/';
export const PME_STREAMS_LINK = `${YOUTUBE_LINK}@pmeHealth/streams`;

export const ALL_FILTER_KEY = 'ALL';

export const HOME_SCREEN_LIST_ITEMS_TAKE_NUMBER = 5;
export const HOME_SCREEN_LIST_ITEMS_LIMIT = 25;

export const DEFAULT_LABEL = 'Default';

export const MAX_FONT_SIZE_MULTIPLIER = 1.2;

export const SCREEN_WIDTH = Dimensions.get('window').width;
export const SCREEN_HEIGHT = Dimensions.get('window').height;

export const IMAGES_ENDPOINT = 'assets/unsafe/{{width}}x{{height}}';

export const HOME_PAGE_LINK = 'https://www.mindance.de/';

export const MANAGER_TOOL_HOME_CARD_MAX_WIDTH = 800;
export const MANAGER_TOOL_HOME_CARD_MAX_HEIGHT = 448;
