import {
  AppNewsFragment,
  BaseBookingFragment,
  Maybe,
  VenueDetailsFragment,
} from '../mindance-libs/generated/graphql';
import {BookingItemType} from '../mindance-libs/types/booking';
import {LibraryItemType} from '../mindance-libs/types/library';
import {Credentials, PersonalInfo} from '../mindance-libs/types/registration';
import NavigationKeys from './NavigationKeys';
import {RouteKeys} from './RouteKeys';

export type ExtractRouteParams<T> = string extends T
  ? Record<string, string>
  : T extends `${infer _Start}:${infer Param}?${infer Rest}`
  ? {[k in Param]?: string} & ExtractRouteParams<Rest>
  : T extends `${infer _Start}:${infer Param}/${infer Rest}`
  ? {
      [k in Param | keyof ExtractRouteParams<Rest>]: string;
    } & ExtractRouteParams<Rest>
  : T extends `${infer _Start}:${infer Param}`
  ? {[k in Param]: string}
  : {};

export type PathParams<P extends RouteKeys> = ExtractRouteParams<P>;

export type LastPathSegment<T extends string> =
  T extends `${infer _Start}/${infer Rest}`
    ? LastPathSegment<Rest>
    : T extends `:${infer Last}`
    ? any
    : T;

export enum WelcomePageStatusCode {
  NoActivationCodeFound = 'no-activation-code-found',
}
type WelcomeQueryParams = {
  statusCode?: WelcomePageStatusCode;
};

type LoginQueryParams = {
  ac?: string;
};

type RegistrationQueryParams = {
  ac?: string;
};

type NewstoolDetailsQueryParams = {
  edit?: boolean;
  create?: boolean;
};

type LibraryItemsQueryParams = {
  itemsType: LibraryItemType;
  tags?: string[];
};

type BookingQueryParams = {
  selectedCategory?: BookingItemType;
};

type EventDetailsQueryParams = {
  language?: Maybe<string>;
  companyId?: BaseBookingFragment['companyId'];
  courseId?: BaseBookingFragment['courseId'];
  serviceId?: BaseBookingFragment['serviceId'];
};

type OneToOneSessionDetailsQueryParams = {
  language?: Maybe<string>;
};

type NewsQueryParams = {
  newsId?: AppNewsFragment['id'];
};

type QueryParamsByRouteKey<T extends RouteKeys> = T extends RouteKeys.Welcome
  ? WelcomeQueryParams
  : T extends RouteKeys.Login
  ? LoginQueryParams
  : T extends RouteKeys.Registration
  ? RegistrationQueryParams
  : T extends RouteKeys.NewstoolDetails
  ? NewstoolDetailsQueryParams
  : T extends RouteKeys.LibraryItems
  ? LibraryItemsQueryParams
  : T extends RouteKeys.Booking
  ? BookingQueryParams
  : T extends RouteKeys.EventDetails
  ? EventDetailsQueryParams
  : T extends RouteKeys.OneToOneSessionDetails
  ? OneToOneSessionDetailsQueryParams
  : T extends RouteKeys.News
  ? NewsQueryParams
  : undefined;

export type RouteKeysQueryParams = {
  [key in RouteKeys]: QueryParamsByRouteKey<key>;
};

type CourseState = {
  screen?: NavigationKeys;
};

type SessionState = {
  courseId?: string;
  screen?: NavigationKeys;
  courseSessionIds?: string[];
};

type NewsDetailsState = {
  origin?: string;
};

type EventBookingFormState = {
  title?: Maybe<string>;
  cost?: Maybe<string>;
  venue?: Maybe<VenueDetailsFragment>;
  startDate?: Maybe<string>;
  endDate?: Maybe<string>;
  startTime?: Maybe<string>;
  endTime?: Maybe<string>;
  bookingInfo?: Maybe<string>;
};

type RegistrationFormState = Credentials &
  PersonalInfo & {
    queryParams?: RegistrationQueryParams;
  };

type StateByRouteKey<T extends RouteKeys> = T extends RouteKeys.Course
  ? CourseState
  : T extends RouteKeys.Session
  ? SessionState
  : T extends RouteKeys.NewstoolDetails
  ? NewsDetailsState
  : T extends RouteKeys.EventBookingForm
  ? EventBookingFormState
  : T extends RouteKeys.Registration
  ? RegistrationFormState | undefined
  : T extends RouteKeys.RegistrationEmailVerification
  ? RegistrationFormState
  : any;

export type RouteKeysState = {
  [key in RouteKeys]: StateByRouteKey<key>;
};
