import React, {useCallback, useMemo} from 'react';
import {StyleProp, TextStyle, ViewProps} from 'react-native';
import {ButtonProps} from 'react-native-paper';
import {Tags} from '../../generated/graphql';
import colors from '../../theme/colors';
import {CustomTag} from '../../types/common';
import AppButton from '../AppButton';
import styles from './styles';

type Props = ViewProps &
  CustomTag & {
    isSelected: boolean;
    onPress?: (id: Tags['id']) => void;
    mode?: ButtonProps['mode'];
    labelStyle?: StyleProp<TextStyle>;
  };

const TagItem: React.FC<Props> = ({
  id,
  isSelected,
  label,
  onPress,
  testID,
  mode = 'contained',
  style,
  labelStyle,
  Icon,
  ...props
}) => {
  const buttonColor = isSelected
    ? colors.white
    : mode === 'contained'
    ? colors.brownGray
    : colors.transparent;

  const tagStyle = useMemo(() => [styles.container, style], [style]);

  const mergedLabelStyle = useMemo<StyleProp<TextStyle>>(
    () => [
      styles.label,
      isSelected ? styles.selectedLabel : undefined,
      labelStyle,
    ],
    [isSelected, labelStyle],
  );

  const handlePress = useCallback(() => onPress?.(id), [id, onPress]);

  return label ? (
    <AppButton
      testID={testID}
      disabled={!onPress}
      onPress={handlePress}
      buttonColor={buttonColor}
      textColor={isSelected ? colors.black100 : colors.white}
      contentStyle={styles.content}
      labelStyle={mergedLabelStyle}
      style={tagStyle}
      icon={Icon}
      {...props}>
      {label}
    </AppButton>
  ) : null;
};

export default React.memo(TagItem);
