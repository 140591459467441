import Sentry from '../../utils/sentry';
import {isAccessExpiredError} from './auth/authErrors';

export const devError = (...args: Parameters<typeof console.error>) => {
  __DEV__ && console.error(...args);
};

export const devLog = (...args: Parameters<typeof console.log>) => {
  __DEV__ && console.log(...args);
};

export const reportError = (message: string, error: unknown) => {
  if (isAccessExpiredError(error)) {
    return;
  }

  if (__DEV__) {
    return devError(message, error);
  }

  Sentry.captureException(error);
};
