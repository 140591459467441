import {StyleSheet} from 'react-native';
import spacings from '../../../theme/spacings';
import colors from '../../../theme/colors';

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'row',
    gap: spacings.xxs,
    alignItems: 'center',
  },
  tagsWrapper: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: spacings.xxs,
  },
  tagContainer: {
    backgroundColor: colors.whiteAlpha06,
    borderColor: colors.whiteAlpha13,
  },
  tagLabel: {
    color: colors.white,
    marginVertical: spacings.xxs,
  },
});

export default styles;
