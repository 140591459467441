import {Platform, StyleSheet} from 'react-native';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  container: {
    borderRadius: 50,
    paddingHorizontal: spacings.l,
    paddingVertical: spacings.xs,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    gap: spacings.xs,
  },
  label: {
    ...typography.footnote,
    fontSize: Platform.select({
      default: typography.footnote.fontSize ?? 12,
      web: 14,
    }),
    lineHeight: Platform.select({
      default: 14,
      web: 16,
    }),
  },
});

export default styles;
