import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, View, ViewStyle} from 'react-native';
import {Maybe} from '../../generated/graphql';
import AppText from '../AppText';
import styles from './styles';

type Props = {
  label?: Maybe<string>;
  testID?: string;
  style?: StyleProp<ViewStyle>;
};

const EmptyState: React.FC<Props> = ({label, testID, style}) => {
  const {t} = useTranslation();

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, style],
    [style],
  );

  return (
    <View style={containerStyle} testID={testID}>
      <AppText style={styles.label} testID={`${testID}.label`}>
        {label ?? t('common.empty_state')}
      </AppText>
    </View>
  );
};

export default React.memo(EmptyState);
