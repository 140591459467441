import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

export type OnMessageShow = (message: string) => void;
export type OnMessageHide = () => void;

const DISPLAY_DURATION = 5000;

const useMessage = () => {
  const [message, setMessage] = useState<string | undefined>();
  const timer = useRef<NodeJS.Timeout | null>(null);

  const clearTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, []);

  const hideMessage = useCallback<OnMessageHide>(() => {
    setMessage(undefined);
    clearTimer();
  }, [clearTimer]);

  const showMessage = useCallback<OnMessageShow>(
    text => {
      setMessage(text);

      if (timer.current) {
        return;
      }

      timer.current = setTimeout(() => {
        hideMessage();
        timer.current = null;
      }, DISPLAY_DURATION);
    },
    [hideMessage],
  );

  useEffect(() => {
    return clearTimer;
  }, [clearTimer]);

  return useMemo(
    () => ({
      hideMessage,
      showMessage,
      message,
    }),
    [message, hideMessage, showMessage],
  );
};

export default useMessage;
