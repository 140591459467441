import {StyleSheet} from 'react-native';
import spacings from '../../theme/spacings';

const styles = StyleSheet.create({
  container: {},
  item: {
    marginLeft: spacings.xl,
  },
});

export default styles;
