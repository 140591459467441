import {useCallback, useMemo, useRef, useState} from 'react';

const useAnimatedOpacity = (options?: {
  initialDisplayed?: boolean;
  onHide?: () => void;
  onShow?: () => void;
}) => {
  const timer = useRef<NodeJS.Timeout | null>(null);

  const [opacity, setOpacity] = useState(
    Number(options?.initialDisplayed ?? false),
  );

  const displayed = Boolean(opacity);

  const opacityAnimatedStyle = useMemo(() => ({opacity}), [opacity]);

  const cancelAnimation = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }, []);

  const increaseOpacity = useCallback<(timeout?: number) => void>(
    timeout => {
      const updateOpacity = () => {
        setOpacity(1);
        options?.onShow?.();
      };

      if (timeout) {
        cancelAnimation();
        timer.current = setTimeout(updateOpacity, timeout);
      } else {
        updateOpacity();
      }
    },
    [cancelAnimation, options],
  );

  const decreaseOpacity = useCallback<(timeout?: number) => void>(
    timeout => {
      const updateOpacity = () => {
        setOpacity(0);
        options?.onHide?.();
      };

      if (timeout) {
        cancelAnimation();
        timer.current = setTimeout(updateOpacity, timeout);
      } else {
        updateOpacity();
      }
    },
    [cancelAnimation, options],
  );

  const toggleOpacity = useCallback<(timeout?: number) => void>(
    timeout => {
      opacity === 1 ? decreaseOpacity(timeout) : increaseOpacity(timeout);
    },
    [decreaseOpacity, increaseOpacity, opacity],
  );

  return useMemo(
    () => ({
      displayed,
      opacityAnimatedStyle,
      increaseOpacity,
      decreaseOpacity,
      toggleOpacity,
      opacity,
      cancelAnimation,
    }),
    [
      displayed,
      opacityAnimatedStyle,
      increaseOpacity,
      decreaseOpacity,
      toggleOpacity,
      opacity,
      cancelAnimation,
    ],
  );
};

export default useAnimatedOpacity;
