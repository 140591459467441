import {
  BaseCourseFragment,
  BaseCourseResponseFragment,
  BaseSessionFragment,
  BaseSessionResponseFragment,
  DirectusFileFragment,
  Maybe,
} from '../generated/graphql';

export enum LibraryItemType {
  COURSES = 'courses',
  SESSIONS = 'sessions',
  MIXED = 'mixed',
}

export type BaseLibraryItem =
  | BaseCourseFragment
  | BaseSessionFragment
  | BaseSessionResponseFragment
  | BaseCourseResponseFragment;

export enum CardType {
  Topic = 'TOPIC',
  Format = 'FORMAT',
}

export type FormattedLibraryItem = {
  id: string;
  tags?: string[];
  label?: Maybe<string> | undefined;
  color?: Maybe<string>;
  imageFile?: Maybe<DirectusFileFragment>;
  type: CardType;
};
