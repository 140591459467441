import {WelcomeProps} from './page.props';

export const WelcomeDataLang: Record<string, WelcomeProps> = {};

WelcomeDataLang['de-DE'] = {
  links: [
    {
      title: 'Impressum',
      redirectLink: 'https://mindance.de/de/datenschutzerklarung',
    },
    {
      title: 'Datenschutzbestimmungen',
      redirectLink: 'https://mindance.de/de/impressum',
    },
    {
      title: 'Mindance 2024',
      redirectLink: 'https://mindance.de/de',
    },
  ],
};

WelcomeDataLang['en-US'] = {
  ...WelcomeDataLang.de,
  links: [
    {
      title: 'Impress',
      redirectLink: 'https://mindance.de/en/impress',
    },
    {
      title: 'Privacy policy',
      redirectLink: 'https://mindance.de/en/datenschutzerklarung',
    },
    {
      title: 'Mindance 2024',
      redirectLink: 'https://mindance.de/en',
    },
  ],
};

export function getWelcomeDataLang(lang = 'de-DE') {
  return WelcomeDataLang[lang] || WelcomeDataLang['de-DE'];
}
