import {useLayout} from '@react-native-community/hooks';
import React, {useCallback, useMemo} from 'react';
import {
  DimensionValue,
  Platform,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';
import colors from '../../theme/colors';
import {reportError} from '../../utils/loggingHelpers';
import {formatMediaDuration} from '../../utils/time';
import AVPlayButton from '../AVPlayButton';
import AVSkipButton, {SkipDirection} from '../AVSkipButton';
import AppText from '../AppText';
import Slider from '../Slider';
import styles, {SLIDER_THUMB_WIDTH} from './styles';

type Props = {
  /**
   * @property duration - The duration of the video/audio in milliseconds.
   */
  duration: number; // in milliseconds
  /**
   * @property progress - The progress of the video/audio in milliseconds.
   */
  progress: number; // in milliseconds
  playing: boolean;
  onPlay: () => Promise<void> | void;
  onPause: () => Promise<void> | void;
  onSetPosition: (value: number) => Promise<void> | void;
  style?: StyleProp<ViewStyle>;
};

const SKIP_SECONDS = 10;

const AVControls: React.FC<Props> = ({
  progress,
  duration,
  playing,
  style,
  onPause,
  onPlay,
  onSetPosition,
}) => {
  const {width: containerWidth, onLayout: onContainerLayout} = useLayout();
  const {width: buttonsWrapperWidth, onLayout: onButtonWrapperLayout} =
    useLayout();

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, style],
    [style],
  );

  const sliderWrapperStyle = useMemo<StyleProp<ViewStyle>>(() => {
    const width: DimensionValue = Platform.select({
      default:
        buttonsWrapperWidth + 40 > containerWidth
          ? containerWidth
          : buttonsWrapperWidth + 40,
      web: '90%' as DimensionValue,
    });
    return {width};
  }, [buttonsWrapperWidth, containerWidth]);

  const togglePlay = useCallback(async () => {
    playing ? await onPause() : await onPlay();
  }, [onPause, onPlay, playing]);

  const changeProgress = useCallback<(value: number) => Promise<void>>(
    async value => {
      try {
        await onSetPosition(Math.round(value));
      } catch (error) {
        reportError('change media position error', error);
      }
    },
    [onSetPosition],
  );

  const skipForward = useCallback(
    () => changeProgress(progress + SKIP_SECONDS * 1000),
    [changeProgress, progress],
  );

  const skipBackward = useCallback(
    () => changeProgress(progress - SKIP_SECONDS * 1000),
    [changeProgress, progress],
  );

  return (
    <View style={containerStyle} onLayout={onContainerLayout}>
      <View style={sliderWrapperStyle}>
        <Slider
          minimumValue={0}
          maximumValue={duration}
          progressValue={progress}
          thumbWidth={SLIDER_THUMB_WIDTH}
          minimumTrackTintColor={colors.white}
          maximumTrackTintColor={colors.whiteAlpha10}
          bubbleBackgroundColor={colors.whiteAlpha10}
          bubbleTextColor={colors.white}
          style={styles.slider}
          onBubbleText={formatMediaDuration}
          onSlidingComplete={changeProgress}
          bubbleTextStyle={styles.time}
        />
        <View style={styles.timeWrapper}>
          <AppText style={styles.time}>{formatMediaDuration(progress)}</AppText>
          <AppText style={styles.time}>{formatMediaDuration(duration)}</AppText>
        </View>
      </View>
      <View style={styles.buttonsWrapper} onLayout={onButtonWrapperLayout}>
        <AVSkipButton
          direction={SkipDirection.BACKWARD}
          skipValue={SKIP_SECONDS}
          onPress={skipBackward}
        />
        <AVPlayButton
          playing={playing}
          onPress={togglePlay}
          style={styles.playButton}
        />
        <AVSkipButton
          direction={SkipDirection.FORWARD}
          skipValue={SKIP_SECONDS}
          onPress={skipForward}
        />
      </View>
    </View>
  );
};

export default React.memo(AVControls);
