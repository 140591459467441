import classNames from 'classnames';
import React from 'react';
import styles from './DashboardItem.module.scss';
import {DEFAULT_PIE_CHART_OPTIONS} from './DefaultPieChartOptions';

const DashboardItemInfo: React.FC<{content: any}> = ({content}) => {
  const contentKeys = Object.keys(content);
  return (
    <div className={styles['dashboard-item__info']}>
      {contentKeys.map((item, index) => (
        <React.Fragment key={item}>
          <div className={styles['dashboard-item__info-text']}>
            <div className={styles['dashboard-item__info-label']}>
              <i
                className={classNames(
                  'fa-solid',
                  'fa-circle',
                  styles['dashboard-item__info-label-dot'],
                )}
                style={{color: DEFAULT_PIE_CHART_OPTIONS.colors?.[index]}}
              />
              <p className={styles['dashboard-item__info-label-text']}>
                {item}
              </p>
            </div>
            <p className={styles['dashboard-item__info-value']}>
              {content[item]}%
            </p>
          </div>
          {index !== contentKeys.length - 1 && (
            <div className={styles['dashboard-item__info-separator']} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default DashboardItemInfo;
