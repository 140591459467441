import classNames from 'classnames';
import React, {useCallback, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {Linking} from 'react-native';
import {Link} from 'react-router-dom';
import Button from '../../components/Button/Button';
import ControlBottomButtons from '../../components/ControlBottomButtons/ControlBottomButtons';
import ScreenBase from '../../mindance-libs/components/ScreenBase';
import MindanceFullLogo from '../../mindance-libs/icons/MindanceFullLogo';
import {useInfoOverlaysContext} from '../../mindance-libs/store/contexts/InfoOverlaysContext';
import {CONTACT_US_EMAIL} from '../../mindance-libs/utils/constants';
import {WelcomePageStatusCode} from '../../navigation/NavigationTypes';
import {RouteKeys} from '../../navigation/RouteKeys';
import useTypedSearchParams from '../../navigation/typed/useTypedSearchParams';
import {getWelcomeDataLang} from './page.data';
import styles from './page.module.scss';
import LocaleSwitcher from '../../components/LocaleSwitcher/LocaleSwitcher';

export default function Page() {
  const {t, i18n} = useTranslation();
  const [{statusCode}] = useTypedSearchParams<RouteKeys.Welcome>();
  const {showInfoOverlay} = useInfoOverlaysContext();

  const isInfoOverlayShown = useRef(false);

  const {links} = getWelcomeDataLang(i18n.language);

  const sendEmailToSupport = useCallback(
    () => Linking.openURL(`mailto:${CONTACT_US_EMAIL}`),
    [],
  );

  useEffect(() => {
    if (isInfoOverlayShown.current) {
      return;
    }

    if (statusCode === WelcomePageStatusCode.NoActivationCodeFound) {
      showInfoOverlay({
        animation: require('../../mindance-libs/assets/animations/screen_locked_animation.json'),
        title: t('errors.something_went_wrong_here'),
        description: t('auth.we_can_not_assign_you_to_company'),
        buttonLabel: t('settings.contact_support'),
        onSubmit: sendEmailToSupport,
        showCloseButton: true,
        showNextIcon: true,
      });
      isInfoOverlayShown.current = true;
    }
  }, [sendEmailToSupport, showInfoOverlay, statusCode, t]);

  return (
    <ScreenBase>
      <div className={styles.welcome__container}>
        <div className={styles.welcome__background}>
          <div
            className={classNames(
              styles['welcome__side-picture'],
              styles['welcome__side-picture--left'],
            )}
          />
          <div
            className={classNames(
              styles['welcome__side-picture'],
              styles['welcome__side-picture--right'],
            )}
          />
        </div>
        <div className={styles.welcome__content}>
          <div className={styles.welcome__content__top}>
            <div className={styles.welcome__logo}>
              <MindanceFullLogo />
            </div>
          </div>

          <div className={styles.welcome__content__bottom}>
            <div>
              <h1>{t('welcome.title')}</h1>

              <div className={styles.welcome__bottom}>
                <ControlBottomButtons
                  submitLink={RouteKeys.Registration}
                  showNextIcon
                  showReturnButton={false}
                  submitButtonLabel={t('welcome.create_account')}
                  submitButtonTestID="welcome.createAccountButton"
                />
              </div>
              <div className={styles['welcome__create-account']}>
                <Button
                  className={styles['welcome__create-account__link']}
                  anchor={{
                    href: RouteKeys.Login,
                  }}>
                  {t('welcome.login')}
                </Button>
              </div>
            </div>

            <div className={styles['welcome__bottom__locales']}>
              <LocaleSwitcher />
            </div>

            <div className={styles['welcome__bottom-links-container']}>
              {links &&
                links.map((link, index) => (
                  <Link
                    key={index}
                    to={link.redirectLink}
                    target="_blank"
                    className={styles['welcome__bottom-links-container-link']}>
                    {link.title}
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>
    </ScreenBase>
  );
}
