import {faThumbTack} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import colors from '../../mindance-libs/theme/colors';
import Button from '../Button/Button';
import {ButtonStyle} from '../Button/Button.props';
import styles from './PinButton.module.scss';
import PinButtonProps from './PinButton.props';

const PinButton: React.FC<PinButtonProps> = ({
  className,
  isPinned,
  onClick,
}) => {
  const {t} = useTranslation();

  const mergedStyle = classNames(styles['pin-button'], className);

  const buttonProps = useMemo(
    () => ({
      onClick,
      style: {
        backgroundColor: isPinned
          ? colors.backgroundPrimaryAlpha40
          : colors.backgroundPrimaryAlpha70,
        transition: 'background-color 0.5s',
      },
    }),
    [isPinned, onClick],
  );

  const iconStyle = useMemo<React.CSSProperties>(
    () => ({
      transform: `rotate(${isPinned ? '-30deg' : '0deg'})`,
      transition: 'transform 0.2s ease-out',
    }),
    [isPinned],
  );

  const PinIcon = useMemo(
    () => <FontAwesomeIcon icon={faThumbTack} size="xl" style={iconStyle} />,
    [iconStyle],
  );

  return (
    <Button
      button={buttonProps}
      buttonStyle={ButtonStyle.SECONDARY}
      leftIcon={PinIcon}
      className={mergedStyle}>
      {t(isPinned ? 'mindManagerNewstool.unpin' : 'mindManagerNewstool.pin')}
    </Button>
  );
};

export default React.memo(PinButton);
