import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  title: {
    ...typography.subtitleBold,
    color: colors.whiteAlpha95,
  },
  container: {
    marginTop: spacings.xxl,
  },
  list: {
    gap: spacings.xxxl,
  },
});

export default styles;
