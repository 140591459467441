import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const MindanceMiniColorfulLogo: React.FC<SvgProps> = ({...props}) => {
  return (
    <Svg width={41} height={48} viewBox="0 0 41 48" fill="none" {...props}>
      <Path
        d="M37.6397 33.9816C39.8379 30.4902 41 26.4346 41 22.2515C41 10.45 31.8038 0.848782 20.5 0.848782C9.19628 0.848782 0 10.45 0 22.2515C0 26.4348 1.16199 30.4902 3.36035 33.9816C3.40674 34.0575 3.45963 34.1287 3.52049 34.1921C3.56836 34.2429 3.62125 34.2864 3.6758 34.3267C3.68063 34.3305 3.68471 34.3351 3.68972 34.3387C4.07161 34.6128 4.58822 34.6347 4.9974 34.3492C5.3542 34.1002 5.53457 33.6811 5.51491 33.2615V25.1453C5.51491 24.4653 4.99051 23.9138 4.34383 23.9138C3.69715 23.9138 3.17275 24.4653 3.17275 25.1453V27.8948C2.62849 26.0802 2.34217 24.1823 2.34217 22.2515C2.34217 11.8081 10.4878 3.31182 20.5 3.31182C30.5122 3.31182 38.6579 11.8081 38.6579 22.2515C38.6579 24.1823 38.3713 26.0802 37.8271 27.8948V25.1453C37.8271 24.4653 37.3027 23.9138 36.656 23.9138C36.0093 23.9138 35.4849 24.4653 35.4849 25.1453V33.2615C35.4653 33.6811 35.6456 34.1002 36.0026 34.3492C36.0431 34.3775 36.0884 34.39 36.1308 34.4124C36.1644 34.4302 36.1977 34.4481 36.2331 34.4628C36.2875 34.4848 36.3411 34.5034 36.3972 34.5162C36.4326 34.5248 36.4695 34.528 36.5061 34.5328C36.554 34.5391 36.6011 34.5547 36.649 34.5547C36.731 34.5547 36.812 34.5416 36.8919 34.5239H36.892C36.9416 34.5127 36.9883 34.4944 37.0362 34.4768C37.0724 34.4636 37.1084 34.4509 37.1431 34.4341C37.1806 34.416 37.216 34.3947 37.2518 34.3721C37.2965 34.3441 37.3383 34.3131 37.379 34.2793C37.4036 34.2585 37.4289 34.239 37.4523 34.2157C37.5235 34.1459 37.5868 34.068 37.6397 33.9816Z"
        fill="white"
      />
      <Path
        d="M8.38347 34.5996C7.7368 34.5996 7.2124 34.0482 7.2124 33.3681V25.1175C7.2124 24.4374 7.7368 23.886 8.38347 23.886C9.03014 23.886 9.55459 24.4374 9.55459 25.1175V33.3681C9.55459 34.0482 9.03014 34.5996 8.38347 34.5996Z"
        fill="#D4F092"
      />
      <Path
        d="M16.4606 47.6152C15.8139 47.6152 15.2896 47.0638 15.2896 46.3837V12.0985C15.2896 11.4184 15.8139 10.867 16.4606 10.867C17.1073 10.867 17.6317 11.4184 17.6317 12.0985V46.3837C17.6317 47.0638 17.1073 47.6152 16.4606 47.6152Z"
        fill="#FEBACE"
      />
      <Path
        d="M20.4992 43.3594C19.8525 43.3594 19.3281 42.8079 19.3281 42.1279V16.3522C19.3281 15.6721 19.8525 15.1207 20.4992 15.1207C21.1459 15.1207 21.6703 15.6721 21.6703 16.3522V42.1279C21.6703 42.8079 21.1459 43.3594 20.4992 43.3594Z"
        fill="#65C0BD"
      />
      <Path
        d="M24.5392 41.3496C23.8926 41.3496 23.3682 40.7982 23.3682 40.1181V18.3618C23.3682 17.6818 23.8926 17.1303 24.5392 17.1303C25.1859 17.1303 25.7103 17.6818 25.7103 18.3618V40.1181C25.7103 40.7982 25.1859 41.3496 24.5392 41.3496Z"
        fill="#5088BA"
      />
      <Path
        d="M28.5778 34.5996C27.9311 34.5996 27.4067 34.0482 27.4067 33.3681V25.1175C27.4067 24.4374 27.9311 23.886 28.5778 23.886C29.2245 23.886 29.7489 24.4374 29.7489 25.1175V33.3681C29.7489 34.0482 29.2245 34.5996 28.5778 34.5996Z"
        fill="#F8EF49"
      />
      <Path
        d="M32.6169 43.3594C31.9702 43.3594 31.4458 42.8079 31.4458 42.1279V16.3522C31.4458 15.6721 31.9702 15.1207 32.6169 15.1207C33.2636 15.1207 33.788 15.6721 33.788 16.3522V42.1279C33.788 42.8079 33.2636 43.3594 32.6169 43.3594Z"
        fill="#BEE73F"
      />
      <Path
        d="M12.4221 41.3496C11.7754 41.3496 11.251 40.7982 11.251 40.1181V18.3618C11.251 17.6818 11.7754 17.1303 12.4221 17.1303C13.069 17.1303 13.5932 17.6818 13.5932 18.3618V40.1181C13.5932 40.7982 13.069 41.3496 12.4221 41.3496Z"
        fill="#FC3B7F"
      />
    </Svg>
  );
};

export default React.memo(MindanceMiniColorfulLogo);
