import {faArrowRight, faLock} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, {PropsWithChildren, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import colors from '../../../mindance-libs/theme/colors';
import TypedLink from '../../../navigation/typed/TypedLink';
import Button from '../../Button/Button';
import {AnchorTargetAttribute, ButtonStyle} from '../../Button/Button.props';
import styles from './ManagerToolHomeCard.module.scss';
import ManagerToolHomeCardProps from './ManagerToolHomeCard.props';
import useElementSize from '../../../hooks/useElementSize';

const CARD_RATIO = 1.75;

const ManagerToolHomeCard: React.FC<
  PropsWithChildren<ManagerToolHomeCardProps>
> = ({
  className,
  testID,
  isAvailable,
  LockedImage,
  routeKey,
  ManagerTools_translations,
  children,
}) => {
  const {t} = useTranslation();

  const {onLoad, ref, size} = useElementSize<HTMLDivElement>();

  const title = ManagerTools_translations?.[0].Label;
  const description = ManagerTools_translations?.[0].Description;
  const lockedLink = ManagerTools_translations?.[0].LockedLink;

  const mergedStyle = classNames(styles['manager-tool-home-card'], className);

  const cardStyle = useMemo<React.CSSProperties>(
    () => ({minHeight: size.width / CARD_RATIO}),
    [size.width],
  );

  const unavailableStyle = useMemo<React.CSSProperties>(
    () => ({
      backgroundImage: `url(${LockedImage})`,
      backgroundSize: '100% 100%',
      backgroundPosition: 'center',
    }),
    [LockedImage],
  );

  return (
    <div className={mergedStyle} data-testid={testID}>
      <div
        className={styles['manager-tool-home-card__content']}
        ref={ref}
        onLoad={onLoad}>
        <div
          className={styles['manager-tool-home-card__card']}
          style={cardStyle}>
          {isAvailable && routeKey ? (
            <div className={styles['manager-tool-home-card__card__available']}>
              {children}
              <TypedLink
                to={routeKey}
                className={
                  styles[
                    'manager-tool-home-card__card__available__next-button__link'
                  ]
                }>
                <div
                  className={
                    styles[
                      'manager-tool-home-card__card__available__next-button'
                    ]
                  }>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    color={colors.black100}
                    className={
                      styles[
                        'manager-tool-home-card__card__available__next-button__icon'
                      ]
                    }
                  />
                </div>
              </TypedLink>
            </div>
          ) : (
            <div
              className={styles['manager-tool-home-card__card__unavailable']}
              style={unavailableStyle}>
              <div
                className={
                  styles['manager-tool-home-card__card__unavailable__content']
                }>
                <FontAwesomeIcon
                  icon={faLock}
                  color={colors.whiteAlpha70}
                  className={
                    styles[
                      'manager-tool-home-card__card__unavailable__content__lock-icon'
                    ]
                  }
                />
                <Button
                  className={
                    styles[
                      'manager-tool-home-card__card__unavailable__content__button'
                    ]
                  }
                  buttonStyle={ButtonStyle.PRIMARY}
                  anchor={{
                    href: lockedLink ?? null,
                    title: title ?? undefined,
                    target: AnchorTargetAttribute.BLANK,
                  }}>
                  {t('common.learn_more').toLowerCase()}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={styles['manager-tool-home-card__text']}>
        {title ? <h3>{title}</h3> : null}
        {description ? <p>{description}</p> : null}
      </div>
    </div>
  );
};

export default React.memo(ManagerToolHomeCard);
