import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './mindance-libs/assets/fonts/fonts.css';
import './mindance-libs/utils/i18next';
import './styles/globals.scss';
import './utils/sentry';

import {ApolloProvider} from '@apollo/client';
import {changeLanguage} from 'i18next';
import React, {useEffect} from 'react';
import {PaperProvider} from 'react-native-paper';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {Route, Routes} from 'react-router-dom';
import Background from './components/Background/Background';
import BreakpointViewer from './components/BreakpointViewer/BreakpointViewer';
import {ModalContextProvider} from './contexts/ModalContext';
import {SubscriptionsContextProvider} from './contexts/SubscriptionsContext';
import useRootApolloClient from './mindance-libs/hooks/useRootApolloClient';
import useValidateUserRoles from './mindance-libs/hooks/useValidateUserRoles';
import {AppContextProvider} from './mindance-libs/store/contexts/AppContext';
import {AuthContextProvider} from './mindance-libs/store/contexts/AuthContext';
import {
  InfoOverlaysContextProvider,
  useInfoOverlaysContext,
} from './mindance-libs/store/contexts/InfoOverlaysContext';
import {globalTheme} from './mindance-libs/theme/paper';
import {Languages} from './mindance-libs/types/common';
import {localizationStore} from './mindance-libs/utils/i18next/helpers';
import NavigationRoutes from './navigation/NavigationRoutes';
import {RouteKeys} from './navigation/RouteKeys';
import Loading from './pages/loading/page';
import Config from './utils/config';

const NavigationProvider = React.memo(() => {
  const {InfoOverlayModal} = useInfoOverlaysContext();
  useValidateUserRoles();

  return (
    <>
      <NavigationRoutes />
      {InfoOverlayModal}
    </>
  );
});

const ApolloClientProvider = () => {
  const client = useRootApolloClient();

  return client ? (
    <ApolloProvider client={client}>
      <AppContextProvider>
        <SubscriptionsContextProvider>
          <InfoOverlaysContextProvider>
            <ModalContextProvider>
              <Background>
                <NavigationProvider />
                {Config.ENVIRONMENT === 'development' && <BreakpointViewer />}
              </Background>
            </ModalContextProvider>
          </InfoOverlaysContextProvider>
        </SubscriptionsContextProvider>
      </AppContextProvider>
    </ApolloProvider>
  ) : (
    <Routes>
      <Route path={RouteKeys.Root} element={<Loading />} />
    </Routes>
  );
};

const App = () => {
  useEffect(() => {
    localizationStore.getLanguage().then(async languageCode => {
      changeLanguage(languageCode as Languages);
    });
  }, []);

  return (
    <SafeAreaProvider>
      <PaperProvider theme={globalTheme}>
        <AuthContextProvider>
          <ApolloClientProvider />
        </AuthContextProvider>
      </PaperProvider>
    </SafeAreaProvider>
  );
};

export default App;
