import React from 'react';
import {SingleValue} from 'react-select';
import {Languages} from '../../mindance-libs/generated/graphql';

export enum SelectPrefixIcon {
  LANGUAGE = 'language',
}

export type SelectOptionType<T extends string | Languages> = {
  value: T;
  label: string;
};

type SelectProps<T extends string | Languages> = Omit<
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >,
  'value' | 'onChange'
> & {
  label?: string | null | undefined;
  placeholder?: string | null | undefined;
  placeholderClassName?: string;
  controlClassName?: string;
  valueClassName?: string;
  errorColor?: string;
  showLabel?: boolean;
  isRequired?: boolean;
  error?: string;
  prefixIcon?: SelectPrefixIcon;
  value?: T;
  options: SelectOptionType<T>[];
  onChange?: (newValue: SingleValue<SelectOptionType<T>>) => void;
};

export default SelectProps;
