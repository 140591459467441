import format from 'date-fns/format';
import {getDateFnsLocale} from './time';

/**
 * Returns `fromDate` and `toDate` depending on `toDate` and difference.
 *
 * @param {string} toDate - The to date as ISO string.
 * @param {number} daysRange - The difference (in days) between from and to dates.
 * @returns {object} The object with toDate and fromDate string values.
 */
export const getToFromDates = (toDate?: string, daysRange = 7) => {
  const startDate = toDate ? new Date(toDate) : new Date();

  const fromDate = new Date(startDate.toISOString());
  fromDate.setDate(fromDate.getDate() - daysRange);

  return {
    toDate: startDate.toISOString(),
    fromDate: fromDate.toISOString(),
  };
};

export const getFormattedDayOfWeek = (
  date: string,
  currentLanguage: string,
) => {
  const day = new Date(date);
  const dayOfWeek = format(day, 'eeeeee', {
    locale: getDateFnsLocale(currentLanguage),
  });

  return dayOfWeek;
};
