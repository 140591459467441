import Color from 'color';
import {LinearGradient} from 'expo-linear-gradient';
import React, {PropsWithChildren, useMemo} from 'react';
import {StyleProp, TouchableHighlight, View, ViewStyle} from 'react-native';
import FastImage, {Source} from 'react-native-fast-image';
import colors from '../../theme/colors';
import styles from './styles';

type Props = {
  imageUrl?: string;
  style?: StyleProp<ViewStyle>;
  onPress: () => void;
  width: number;
  aspectRatio: number;
  testID?: string;
  gradientColor?: string;
};

const BACKGROUND_GRADIENT_COLORS = [
  colors.transparent,
  colors.blueGreenGray3Alpha90,
];
const BACKGROUND_GRADIENT_POINTS = {
  start: {x: 0, y: 0},
  end: {x: 0, y: 0.95},
};

const HomeCardBase: React.FC<PropsWithChildren<Props>> = ({
  onPress,
  imageUrl,
  width,
  style,
  testID,
  children,
  aspectRatio,
  gradientColor,
}) => {
  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, style],
    [style],
  );

  const contentStyle = useMemo<StyleProp<ViewStyle>>(
    () => ({minWidth: width, height: width / aspectRatio}),
    [aspectRatio, width],
  );

  const image = useMemo<Source>(
    () =>
      imageUrl
        ? {uri: imageUrl}
        : require('../../assets/images/placeholder.png'),
    [imageUrl],
  );

  const colorfulGradientColors = useMemo(
    () =>
      gradientColor
        ? [
            Color(gradientColor).alpha(0).string(),
            gradientColor,
            gradientColor,
            Color(gradientColor).alpha(0).string(),
          ]
        : [],
    [gradientColor],
  );

  return (
    <TouchableHighlight
      style={containerStyle}
      onPress={onPress}
      testID={testID}
      underlayColor={colors.whiteAlpha10}>
      <View style={contentStyle}>
        <FastImage
          style={styles.image}
          source={image}
          testID={`${testID}.image`}
        />
        <LinearGradient
          colors={BACKGROUND_GRADIENT_COLORS}
          style={styles.gradientBackground}
          pointerEvents="none"
          start={BACKGROUND_GRADIENT_POINTS.start}
          end={BACKGROUND_GRADIENT_POINTS.end}
          testID={`${testID}.backgroundGradient`}
        />
        <LinearGradient
          colors={colorfulGradientColors}
          style={styles.colorfulGradient}
          pointerEvents="none"
          testID={`${testID}.colorfulGradient`}
        />
        {children}
      </View>
    </TouchableHighlight>
  );
};

export default React.memo(HomeCardBase);
