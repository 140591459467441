import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../components/Button/Button';
import {ButtonStyle} from '../../../../components/Button/Button.props';
import ItemsList from '../../../../components/ItemsList/ItemsList';
import useElementSize from '../../../../hooks/useElementSize';
import ExtendedFilterButton from '../../../../mindance-libs/components/ExtendedFilterButton';
import ScreenBase from '../../../../mindance-libs/components/ScreenBase';
import UserTagsModal from '../../../../mindance-libs/components/UserTagsModal';
import {Tags} from '../../../../mindance-libs/generated/graphql';
import useLibraryItems from '../../../../mindance-libs/hooks/useLibraryItems';
import useLibraryItemsInterestsModal from '../../../../mindance-libs/hooks/useLibraryItemsInterestsModal';
import useRenderLibraryItems from '../../../../mindance-libs/hooks/useRenderLibraryItems';
import {BaseLibraryItem} from '../../../../mindance-libs/types/library';
import {getLibrariesItemsScreenName} from '../../../../mindance-libs/utils/courses';
import NavigationKeys from '../../../../navigation/NavigationKeys';
import {RouteKeys} from '../../../../navigation/RouteKeys';
import useTypedNavigate from '../../../../navigation/typed/useTypedNavigate';
import useTypedSearchParams from '../../../../navigation/typed/useTypedSearchParams';
import styles from './page.module.scss';

const IMAGE_RATIO = 2;
const MAX_IMAGE_SIZE = 300;

export default function Page() {
  const {t} = useTranslation();

  const navigate = useTypedNavigate();

  const [{itemsType, tags}, updateParams] =
    useTypedSearchParams<RouteKeys.LibraryItems>();

  const updateParamsTags = useCallback<(tagsIds: Tags['id'][]) => void>(
    tagsIds => {
      const updatedParams = {itemsType, tags: tagsIds};
      updateParams(updatedParams);
    },
    [itemsType, updateParams],
  );

  const {
    closeModal,
    isModalVisible,
    onCoursePress,
    onSessionPress,
    selectTag,
    selectedTagsIds,
    selectedTagsWithExternal,
    selectedTagsRecord,
    showModal,
    submitTags,
    tagsFilters,
    tagsIds,
    deselectAllTags,
    selectAllTags,
  } = useLibraryItemsInterestsModal(tags, {onTagsSelect: updateParamsTags});

  const {data, loading, error, loadingMore, fetchNext} = useLibraryItems(
    itemsType,
    selectedTagsWithExternal,
    tagsIds,
  );

  const {
    onLoad: onContentContainerLoad,
    ref: contentContainerRef,
    size: contentContainerSize,
  } = useElementSize<HTMLDivElement>();

  const title = getLibrariesItemsScreenName(t)(itemsType);
  const subtitle = t('library.all_options');

  const imageWidth =
    contentContainerSize.width > 0 &&
    contentContainerSize.width <= MAX_IMAGE_SIZE
      ? contentContainerSize.width / 3
      : MAX_IMAGE_SIZE;

  const backButtonProps = useMemo(
    () => ({
      title: t('common.return') || '',
      onClick: () => navigate({to: RouteKeys.Library, params: {}}),
    }),
    [navigate, t],
  );

  const handleSessionPress = useCallback<(id: string) => void>(
    id => {
      onSessionPress(id);

      navigate(
        {to: RouteKeys.Session, params: {id}},
        {state: {screen: NavigationKeys.LibraryItemsScreen}},
      );
    },
    [navigate, onSessionPress],
  );

  const handleCoursePress = useCallback<
    (id: string, difference: number) => void
  >(
    (id, difference) => {
      onCoursePress(id, difference);
      navigate(
        {to: RouteKeys.Course, params: {id}},
        {state: {screen: NavigationKeys.LibraryItemsScreen}},
      );
    },
    [navigate, onCoursePress],
  );

  const renderLibraryItem = useRenderLibraryItems({
    imageWidth,
    onCoursePress: handleCoursePress,
    onSessionPress: handleSessionPress,
    imageRatio: IMAGE_RATIO,
  });

  const renderItem = useCallback<
    (item: BaseLibraryItem, index: number) => JSX.Element | null
  >(item => renderLibraryItem(item), [renderLibraryItem]);

  return (
    <ScreenBase>
      <div className={styles['library-items__container']}>
        <div>
          <Button
            showLeftArrow
            button={backButtonProps}
            className={styles['library-items__back']}
            buttonStyle={ButtonStyle.INLINE_WHITE}
          />
        </div>
        <div className={styles['library-items__header']}>
          <div>
            <h1 className={styles['library-items__header__title']}>{title}</h1>
            <p className={styles['library-items__header__subtitle']}>
              {subtitle}
            </p>
          </div>
          <ExtendedFilterButton
            selectedFiltersNumber={selectedTagsIds.length}
            onPress={showModal}
            iconPosition="right"
          />
        </div>

        <div
          className={styles['library-items__content']}
          ref={contentContainerRef}
          onLoad={onContentContainerLoad}>
          <ItemsList
            className={styles['library-items__content__items']}
            data={data}
            loading={loading}
            loadingMore={loadingMore}
            renderItem={renderItem}
            emptyLabel={t('common.empty_libs')}
            error={error}
            onEndReached={fetchNext}
          />
        </div>
      </div>

      <UserTagsModal
        isVisible={isModalVisible}
        onDismiss={closeModal}
        tagGroups={tagsFilters}
        selectedTagsRecord={selectedTagsRecord}
        onTagSelect={selectTag}
        onSubmit={submitTags}
        tagSelectionRequired={false}
        onDeselectAll={deselectAllTags}
        onSelectAll={selectAllTags}
      />
    </ScreenBase>
  );
}
