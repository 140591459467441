import {jwtDecode} from 'jwt-decode';
import Config from '../../../utils/config';
import {AccessTokenJwtPayload} from '../../types/auth';

export const checkIsMindManagerClient = (accessToken?: string | null) => {
  const decodedToken = accessToken
    ? jwtDecode<AccessTokenJwtPayload>(accessToken)
    : undefined;

  const isMindManager =
    !!decodedToken && decodedToken.azp === Config.MIND_MANAGER_CLIENT_ID;

  return isMindManager;
};

export const checkIsReactNativeAppClient = (accessToken?: string | null) => {
  const decodedToken = accessToken
    ? jwtDecode<AccessTokenJwtPayload>(accessToken)
    : undefined;

  const isMindManager =
    !!decodedToken && decodedToken.azp === Config.MOBILE_APP_CLIENT_ID;

  return isMindManager;
};
