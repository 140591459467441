import {faInfo} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {useTranslation} from 'react-i18next';
import SidebarModal from '../SidebarModal/SidebarModal';
import styles from './SubscriptionsSharedAlertModal.module.scss';
import SubscriptionsSharedAlertModalProps from './SubscriptionsSharedAlertModal.props';

const SubscriptionsSharedAlertModal: React.FC<
  SubscriptionsSharedAlertModalProps
> = ({locationsNumber, ...props}) => {
  const {t} = useTranslation();

  return (
    <SidebarModal
      className={styles['subscriptions-shared-alert-modal']}
      declineButtonTitle={t('mindManagerHome.cancel')}
      declineButtonClassName={
        styles['subscriptions-shared-alert-modal__decline-button']
      }
      {...props}>
      <div className={styles['subscriptions-shared-alert-modal__content']}>
        <div className={styles['subscriptions-shared-alert-modal__info']}>
          <FontAwesomeIcon
            icon={faInfo}
            className={styles['subscriptions-shared-alert-modal__info__icon']}
          />
        </div>
        <p className={styles['subscriptions-shared-alert-modal__title']}>
          {t('mindManagerNewstool.you_shared_this_post', {
            value: locationsNumber,
          })}
        </p>

        <p className={styles['subscriptions-shared-alert-modal__description']}>
          {t('mindManagerNewstool.do_you_want_to_continue')}
        </p>
      </div>
    </SidebarModal>
  );
};

export default React.memo(SubscriptionsSharedAlertModal);
