import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Image, StyleProp, View, ViewStyle} from 'react-native';
import {BaseBookingFragment} from '../../generated/graphql';
import GroupIcon from '../../icons/GroupIcon';
import colors from '../../theme/colors';
import {getAppointmentsLabel} from '../../utils/booking';
import {getEventDate} from '../../utils/home';
import AppText from '../AppText';
import HomeCardBase from '../HomeCardBase';
import styles from './styles';

type Props = BaseBookingFragment & {
  imageUrl?: string;
  style?: StyleProp<ViewStyle>;
  onPress: (id: BaseBookingFragment['id'], language: string) => void;
  width: number;
  aspectRatio: number;
  testID?: string;
};

const GroupCoachingHomeCard: React.FC<Props> = ({
  id,
  Label,
  StartDate: startDate,
  StartTime: startTime,
  AppointmentsNumber,
  onPress,
  imageUrl,
  Language,
  width,
  style,
  testID,
  aspectRatio,
}) => {
  const {t, i18n} = useTranslation();

  const titleStyle = useMemo(() => [styles.title, {width}], [width]);

  const dateLabel = getEventDate({
    t,
    startDate,
    startTime,
    currentLanguage: i18n.language,
    localizedTime: true,
  });

  const handlePress = useCallback(
    () => Language && onPress?.(id, Language),
    [id, Language, onPress],
  );

  return (
    <HomeCardBase
      gradientColor={colors.blue}
      width={width}
      aspectRatio={aspectRatio}
      imageUrl={imageUrl}
      style={style}
      testID={testID}
      onPress={handlePress}>
      <View style={styles.content}>
        <View style={styles.upperContent}>
          {dateLabel ? (
            <View style={styles.timeContainer} testID={`${testID}.dateLabel`}>
              <Image
                source={require('../../assets/images/calendar_icon.png')}
                style={styles.calendarImage}
              />
              <AppText style={styles.timingViewAppText}>{dateLabel}</AppText>
            </View>
          ) : null}

          <View
            style={styles.groupAndAppointments}
            testID={`${testID}.groupAndAppointments`}>
            <View style={styles.groupIconView}>
              <GroupIcon />
            </View>
            {AppointmentsNumber && AppointmentsNumber > 0 ? (
              <View
                style={styles.appointmentsLabelContainer}
                testID={`${testID}.appointmentsNumber`}>
                <AppText style={styles.appointmentsLabel}>
                  {getAppointmentsLabel(t)(AppointmentsNumber)}
                </AppText>
              </View>
            ) : null}
          </View>
        </View>

        {Label ? (
          <AppText
            style={titleStyle}
            testID={`${testID}.title`}
            numberOfLines={3}
            ellipsizeMode="tail">
            {Label}
          </AppText>
        ) : null}
      </View>
    </HomeCardBase>
  );
};

export default React.memo(GroupCoachingHomeCard);
