import * as R from 'ramda';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, View, ViewStyle} from 'react-native';
import AppText from '../AppText';
import LibraryWideCardBase from '../LibraryWideCardBase';
import SegmentedProgressBar from '../SegmentedProgressBar';
import styles from './styles';

type Props = {
  id: string;
  onPress: (id: string, difference: number) => void;
  Label?: string | null;
  imageUrl?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  imageWidth: number;
  imageRatio: number;
  totalProgress: number;
  currentProgress: number;
  difference: number;
};

const LibraryCourseWideCard: React.FC<Props> = ({
  id,
  onPress,
  imageUrl,
  style,
  testID,
  imageRatio,
  imageWidth,
  Label,
  totalProgress,
  currentProgress,
  difference,
}) => {
  const {t} = useTranslation();

  const shouldShowProgressBar = R.isNotNil(totalProgress) && totalProgress > 0;

  const subtitle = t(
    totalProgress > 1
      ? 'courses.course_duration'
      : 'courses.short_course_duration',
    {courseDuration: totalProgress},
  );

  const handlePress = useCallback(
    () => onPress(id, difference),
    [difference, id, onPress],
  );

  return (
    <LibraryWideCardBase
      label={Label}
      imageWidth={imageWidth}
      imageRatio={imageRatio}
      imageUrl={imageUrl}
      style={style}
      testID={testID}
      onPress={handlePress}>
      <View style={styles.content}>
        {shouldShowProgressBar ? (
          <SegmentedProgressBar
            style={styles.progress}
            current={currentProgress ?? 0}
            total={totalProgress}
            testID={`${testID}.progressBar`}
          />
        ) : null}
        <AppText style={styles.subtitle}>{subtitle}</AppText>
      </View>
    </LibraryWideCardBase>
  );
};

export default React.memo(LibraryCourseWideCard);
