import {useLocation} from 'react-router-dom';
import {RouteKeysState} from '../NavigationTypes';
import {RouteKeys} from '../RouteKeys';

/**
 * Type-safe version of `react-router-dom/useLocation`.
 .
 * @returns parameter object if route matches.
 */
const useTypedLocation = <P extends RouteKeys>() => {
  const location = useLocation<RouteKeysState[P]>();

  return location;
};

export default useTypedLocation;
