import * as R from 'ramda';
import {memo} from 'react';
import breakpointProvider from './breakpointProvider';

export const genericMemo: <T>(component: T) => T = memo;

export const trimObjectValues = <T extends Record<string, unknown>>(
  obj: T,
): T => {
  return R.mapObjIndexed(
    value => (typeof value === 'string' ? value.trim() : value),
    obj,
  ) as T;
};

export const filterArrayAsync = async <T>(
  items: T[],
  asyncFilterFn: (item: T) => Promise<boolean>,
): Promise<T[]> => {
  const results = await Promise.all(
    items.map(async item => {
      if (await asyncFilterFn(item)) {
        return item;
      }
    }),
  );

  return R.filter(R.isNotNil, results);
};

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const checkIsSmallWindow = (windowWidth: number) =>
  windowWidth <= breakpointProvider.$gridBreakpoints.md;
