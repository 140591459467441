import {StyleSheet} from 'react-native';
import colors from '../../../../mindance-libs/theme/colors';
import spacings from '../../../../mindance-libs/theme/spacings';
import typography from '../../../../mindance-libs/theme/typography';

const styles = StyleSheet.create({
  contactUs: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: spacings.xxs,
    marginTop: spacings.l,
    flexWrap: 'wrap',
  },
  contactUsTitle: {
    ...typography.footnote,
    fontSize: 13,
    color: colors.whiteAlpha70,
  },
});

export default styles;
