import {ApolloError} from '@apollo/client';
import * as R from 'ramda';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {
  BaseMindManagerSubscriptionsFragment,
  FindFirstMindManagerHomeQuery,
  useFindFirstMindManagerHomeQuery,
  useFindMindManagerSubscriptionsQuery,
} from '../mindance-libs/generated/graphql';
import {useAppContext} from '../mindance-libs/store/contexts/AppContext';
import {isProductLabels, ProductLabels} from '../mindance-libs/types/products';
import {
  MANAGER_TOOL_HOME_CARD_MAX_HEIGHT,
  MANAGER_TOOL_HOME_CARD_MAX_WIDTH,
} from '../mindance-libs/utils/constants';
import {getImageUrl} from '../mindance-libs/utils/files';
import {reportError} from '../mindance-libs/utils/loggingHelpers';
import {RouteKeys} from '../navigation/RouteKeys';
import {isMindManagerProductLabel} from '../types/MindManagerProducts';
import {MindManagerTool} from '../types/MindManagerTool';
import {HOME_MIND_MANAGER_TOOLS_ROUTES} from '../utils/products';

const SELECTED_SUBSCRIPTION_ID_KEY = 'selectedSubscriptionId';
const SELECTED_SUBSCRIPTIONS_BY_PRODUCT_KEY = 'selectedSubscriptions';

const getSelectedSubscriptionsByProductStorageKey = (product: ProductLabels) =>
  `${SELECTED_SUBSCRIPTIONS_BY_PRODUCT_KEY}_${product}`;

export type SubscriptionId = BaseMindManagerSubscriptionsFragment['id'];
export type SubscriptionsRecord = Record<
  SubscriptionId,
  BaseMindManagerSubscriptionsFragment
>;
export type SubscriptionsByProductsRecord = Partial<
  Record<ProductLabels, BaseMindManagerSubscriptionsFragment[]>
>;
export type SelectedSubscriptionsByProductsRecord = Partial<
  Record<ProductLabels, SubscriptionId[]>
>;

export type SubscriptionsContextType = {
  selectedSubscriptionId?: SubscriptionId;
  changeSelectedSubscriptionId: (
    newSubscriptionId: SubscriptionId | undefined,
  ) => void;
  subscriptionsRecord: SubscriptionsRecord;
  mindManagerHome?: FindFirstMindManagerHomeQuery['findFirstMindManagerHome'];
  mindManagerTools: MindManagerTool[];
  loading: boolean;
  error?: ApolloError;
  availableRoutes: RouteKeys[];
  subscriptionsByProducts: SubscriptionsByProductsRecord;
  selectedSubscriptionsByProducts: SelectedSubscriptionsByProductsRecord;
  updateSelectedSubscriptions: (
    label: ProductLabels,
    ids: SubscriptionId[],
  ) => void;
};

const SubscriptionsContext = createContext<SubscriptionsContextType | null>(
  null,
);

export const useSubscriptionsContext = (): SubscriptionsContextType => {
  const subscriptionsContext = useContext(SubscriptionsContext);

  if (subscriptionsContext === null) {
    throw new Error(
      'Subscriptions context cannot be null, please add a context provider.',
    );
  }

  return subscriptionsContext;
};

export const SubscriptionsContextProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const {i18n} = useTranslation();
  const {user} = useAppContext();

  const {
    data: homeData,
    loading: homeLoading,
    error: homeError,
  } = useFindFirstMindManagerHomeQuery({
    variables: {language: i18n.language},
    onError: err => reportError('useFindFirstMindManagerHomeQuery error', err),
    skip: !user,
  });

  const {
    data: subscriptionsData,
    loading: subscriptionsLoading,
    error: subscriptionsError,
  } = useFindMindManagerSubscriptionsQuery({
    variables: {language: i18n.language},
    onError: err =>
      reportError('useFindMindManagerSubscriptionsQuery error', err),
    skip: !user,
  });

  const subscriptionsRecord = useMemo<SubscriptionsRecord>(() => {
    if (!subscriptionsData || !subscriptionsData.findMindManagerSubscriptions) {
      return {};
    }

    return subscriptionsData.findMindManagerSubscriptions.reduce<SubscriptionsRecord>(
      (acc, subscription) => ({...acc, [subscription.id]: subscription}),
      {},
    );
  }, [subscriptionsData]);

  const subscriptionsByProducts = useMemo<SubscriptionsByProductsRecord>(() => {
    if (!subscriptionsData || !subscriptionsData.findMindManagerSubscriptions) {
      return {};
    }

    const result =
      subscriptionsData.findMindManagerSubscriptions.reduce<SubscriptionsByProductsRecord>(
        (accumulated, subscription) => {
          if (!subscription.ProductLabels) {
            return accumulated;
          }

          subscription.ProductLabels.forEach(label => {
            if (isProductLabels(label)) {
              const existed = accumulated[label];

              if (existed) {
                const hasSubscription = existed.find(
                  ({id}) => subscription.id === id,
                );
                accumulated[label] = hasSubscription
                  ? existed
                  : [...existed, subscription];
              } else {
                accumulated[label] = [subscription];
              }
            }
          });

          return accumulated;
        },
        {},
      );

    return result;
  }, [subscriptionsData]);

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<
    SubscriptionId | undefined
  >(sessionStorage.getItem(SELECTED_SUBSCRIPTION_ID_KEY) ?? undefined);

  const getInitialSelectedSubscriptionsByProducts = useCallback(
    () =>
      R.toPairs(subscriptionsByProducts).reduce((acc, pair) => {
        if (!pair) {
          return acc;
        }
        const [productLabel, subscriptions] = pair;

        if (!subscriptions || !subscriptions?.[0]?.id) {
          return acc;
        }
        const storageKey =
          getSelectedSubscriptionsByProductStorageKey(productLabel);
        const savedSubscriptions = sessionStorage.getItem(storageKey);

        const actualSubscriptionsIds = R.pluck('id', subscriptions);
        const initialId =
          selectedSubscriptionId &&
          actualSubscriptionsIds.includes(selectedSubscriptionId)
            ? selectedSubscriptionId
            : subscriptions[0].id;

        if (savedSubscriptions) {
          const parsed: SubscriptionId[] = JSON.parse(savedSubscriptions) ?? [];
          const filteredParsed = R.filter(R.isNotNil, parsed ?? []);
          const oldSubscriptionsIds = R.without(
            actualSubscriptionsIds,
            filteredParsed,
          );
          const filteredSubscriptionsIds = R.without(
            oldSubscriptionsIds,
            filteredParsed,
          );

          return {
            ...acc,
            [productLabel]:
              filteredSubscriptionsIds.length === 0
                ? [initialId]
                : filteredSubscriptionsIds,
          };
        }

        return {...acc, [productLabel]: [initialId]};
      }, {}),
    [selectedSubscriptionId, subscriptionsByProducts],
  );

  const [selectedSubscriptionsByProducts, setSelectedSubscriptionsByProducts] =
    useState<SelectedSubscriptionsByProductsRecord>(
      getInitialSelectedSubscriptionsByProducts(),
    );

  const updateSelectedSubscriptions = useCallback<
    (label: ProductLabels, ids: SubscriptionId[]) => void
  >((label, ids) => {
    setSelectedSubscriptionsByProducts(previous => ({
      ...previous,
      [label]: ids,
    }));
    const storageKey = getSelectedSubscriptionsByProductStorageKey(label);
    sessionStorage.setItem(storageKey, JSON.stringify(ids));
  }, []);

  const changeSelectedSubscriptionId = useCallback(
    (newSubscriptionId: SubscriptionId | undefined) => {
      newSubscriptionId
        ? sessionStorage.setItem(
            SELECTED_SUBSCRIPTION_ID_KEY,
            newSubscriptionId,
          )
        : sessionStorage.removeItem(SELECTED_SUBSCRIPTION_ID_KEY);

      setSelectedSubscriptionId(newSubscriptionId);

      if (newSubscriptionId) {
        R.toPairs(subscriptionsByProducts).forEach(pair => {
          if (!pair) {
            return;
          }
          const [label, subscriptions] = pair;
          const isValidSubscription = !!subscriptions?.find(
            ({id}) => newSubscriptionId === id,
          );
          isValidSubscription &&
            updateSelectedSubscriptions(label, [newSubscriptionId]);
        });
      }
    },
    [subscriptionsByProducts, updateSelectedSubscriptions],
  );

  useEffect(() => {
    if (
      (selectedSubscriptionId &&
        subscriptionsRecord &&
        subscriptionsRecord?.[selectedSubscriptionId]) ||
      subscriptionsLoading ||
      !subscriptionsRecord ||
      R.isEmpty(subscriptionsRecord)
    ) {
      return;
    }

    const selectedId = Object.keys(subscriptionsRecord)?.[0];
    if (selectedId) {
      changeSelectedSubscriptionId(selectedId);
    }
  }, [
    changeSelectedSubscriptionId,
    selectedSubscriptionId,
    subscriptionsLoading,
    subscriptionsRecord,
  ]);

  useEffect(() => {
    if (subscriptionsLoading || subscriptionsError || !subscriptionsData) {
      return;
    }

    setSelectedSubscriptionsByProducts(
      getInitialSelectedSubscriptionsByProducts(),
    );
  }, [
    getInitialSelectedSubscriptionsByProducts,
    subscriptionsData,
    subscriptionsError,
    subscriptionsLoading,
  ]);

  const mindManagerTools = useMemo<MindManagerTool[]>(() => {
    if (subscriptionsLoading || homeLoading) {
      return [];
    }

    const selectedSubscriptionsProductLabels = selectedSubscriptionId
      ? subscriptionsRecord?.[selectedSubscriptionId]?.ProductLabels
      : undefined;

    const sortedTools = R.sortBy(
      ({sort}) => sort ?? 0,
      homeData?.findFirstMindManagerHome?.MindManager_ManagerTools ?? [],
    );

    const tools =
      sortedTools.map<MindManagerTool | null>(({ManagerTools}) => {
        if (!ManagerTools) {
          return null;
        }

        const Label = ManagerTools.Products?.Label;

        const isAvailable =
          selectedSubscriptionsProductLabels && Label
            ? selectedSubscriptionsProductLabels.includes(Label)
            : false;

        const routeKey =
          Label && isMindManagerProductLabel(Label)
            ? HOME_MIND_MANAGER_TOOLS_ROUTES[Label]
            : undefined;

        const LockedImage = getImageUrl(ManagerTools.directus_files, {
          width: MANAGER_TOOL_HOME_CARD_MAX_WIDTH,
          height: MANAGER_TOOL_HOME_CARD_MAX_HEIGHT,
        });

        return {...ManagerTools, isAvailable, routeKey, LockedImage};
      }) ?? [];

    return R.filter(R.isNotNil, tools);
  }, [
    homeData?.findFirstMindManagerHome?.MindManager_ManagerTools,
    homeLoading,
    selectedSubscriptionId,
    subscriptionsLoading,
    subscriptionsRecord,
  ]);

  const availableRoutes = useMemo<RouteKeys[]>(
    () =>
      mindManagerTools?.reduce<RouteKeys[]>((acc, {isAvailable, routeKey}) => {
        return isAvailable && routeKey ? [...acc, routeKey] : acc;
      }, []),
    [mindManagerTools],
  );

  const value = useMemo<SubscriptionsContextType>(
    () => ({
      selectedSubscriptionId,
      changeSelectedSubscriptionId,
      subscriptionsRecord,
      mindManagerHome: homeData?.findFirstMindManagerHome,
      mindManagerTools,
      loading: homeLoading || subscriptionsLoading,
      error: homeError || subscriptionsError,
      availableRoutes,
      subscriptionsByProducts,
      selectedSubscriptionsByProducts,
      updateSelectedSubscriptions,
    }),
    [
      availableRoutes,
      changeSelectedSubscriptionId,
      homeData?.findFirstMindManagerHome,
      homeError,
      homeLoading,
      mindManagerTools,
      selectedSubscriptionId,
      selectedSubscriptionsByProducts,
      subscriptionsByProducts,
      subscriptionsError,
      subscriptionsLoading,
      subscriptionsRecord,
      updateSelectedSubscriptions,
    ],
  );

  return (
    <SubscriptionsContext.Provider value={value}>
      {children}
    </SubscriptionsContext.Provider>
  );
};
