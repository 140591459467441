import {StyleSheet} from 'react-native';
import spacings from '../../../theme/spacings';
import typography from '../../../theme/typography';

const styles = StyleSheet.create({
  content: {
    gap: spacings.xxl,
  },
  description: {
    ...typography.footnote,
    textAlign: 'center',
  },
  descriptionBold: {
    ...typography.footnoteBold,
  },
  totalTimeLabel: {
    ...typography.input,
    textAlign: 'center',
  },
  totalTimeLabelBold: {
    ...typography.inputBold,
  },
});

export default styles;
