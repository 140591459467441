import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {SMALL_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';

const TITLE_MARGIN_TOP = spacings.xl;
const TITLE_HEIGHT = typography.title3Bold.fontSize ?? 24;
const ROTATION_ANGLE = 20;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: SMALL_BORDER_RADIUS,
    overflow: 'hidden',
  },
  background: {
    ...StyleSheet.absoluteFillObject,
  },
  imageContainer: {
    position: 'absolute',
    borderRadius: 9,
    transform: [{rotate: `${ROTATION_ANGLE}deg`}, {scale: 1.1}],
    top: TITLE_MARGIN_TOP + TITLE_HEIGHT + spacings.l,
    overflow: 'hidden',
  },
  image: {
    flex: 1,
    transform: [
      {rotate: `-${ROTATION_ANGLE}deg`},
      {translateX: -8},
      {translateY: -8},
      {scale: 1.1},
    ],
  },
  imageShadow: {
    ...StyleSheet.absoluteFillObject,
  },
  title: {
    ...typography.title3Bold,
    lineHeight: TITLE_HEIGHT + 4,
    color: colors.white,
    marginTop: TITLE_MARGIN_TOP,
    marginLeft: spacings.xl,
  },
});

export default styles;
