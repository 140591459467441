import classNames from 'classnames';
import React, {useEffect, useMemo} from 'react';
import {useInView} from 'react-intersection-observer';
import {ActivityIndicator} from 'react-native-paper';
import EmptyState from '../../mindance-libs/components/EmptyState';
import ErrorContent from '../../mindance-libs/components/ErrorContent';
import LoadingContent from '../../mindance-libs/components/LoadingContent';
import colors from '../../mindance-libs/theme/colors';
import {genericMemo} from '../../mindance-libs/utils/common';
import styles from './ItemsList.module.scss';
import {ItemsListProps} from './ItemsList.props';

const ItemsList = <T,>({
  className,
  data,
  error,
  loading,
  loadingMore,
  emptyLabel,
  renderItem,
  onEndReached,
  ListHeader,
}: ItemsListProps<T>) => {
  const [lastItemRef, inView] = useInView({threshold: 0});

  const ListComponent = useMemo(
    () => (data ?? []).map(renderItem),
    [data, renderItem],
  );

  useEffect(() => {
    if (inView && !(loading || loadingMore)) {
      onEndReached?.();
    }
  }, [inView, loading, loadingMore, onEndReached]);

  return (
    <div className={classNames(styles['items-list'], className)}>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <ErrorContent />
      ) : !data || data?.length === 0 ? (
        <EmptyState label={emptyLabel} />
      ) : (
        <>
          {ListHeader}
          {ListComponent}
          <div ref={lastItemRef} />
          {loadingMore ? (
            <div className={styles['items-list__loading']}>
              <ActivityIndicator color={colors.whiteAlpha70} size={50} />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default genericMemo(ItemsList);
