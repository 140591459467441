import React, {useMemo} from 'react';
import {View, ViewProps} from 'react-native';
import styles from './styles';
import * as R from 'ramda';

type Props = ViewProps & {
  current: number;
  total: number;
};

const SegmentedProgressBar: React.FC<Props> = ({
  style,
  current,
  total,
  testID,
  ...props
}) => {
  const containerStyle = useMemo(() => [styles.container, style], [style]);

  const Bars = useMemo(
    () =>
      R.range(1, total + 1).map(item => (
        <View
          style={item <= current ? styles.filledBar : styles.bar}
          key={item.toString()}
        />
      )),
    [current, total],
  );

  return (
    <View style={containerStyle} testID={testID} {...props}>
      {Bars}
    </View>
  );
};

export default React.memo(SegmentedProgressBar);
