import {useCallback, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useInfoOverlaysContext} from '../store/contexts/InfoOverlaysContext';
import {CONTACT_US_EMAIL} from '../utils/constants';

const useShowNoSubscriptionsOverlay = ({onSubmit}: {onSubmit: () => void}) => {
  const {t} = useTranslation();
  const {showInfoOverlay} = useInfoOverlaysContext();
  const isShown = useRef(false);

  const showNoSubscriptionsOverlay = useCallback(() => {
    if (isShown.current) {
      return;
    }

    isShown.current = true;
    showInfoOverlay({
      title: t('infoOverlays.missing_activation_code'),
      description: t('infoOverlays.no_valid_activation_code_stored', {
        supportEmail: CONTACT_US_EMAIL,
      }),
      buttonLabel: t('infoOverlays.redeem_code_now'),
      animation: require('../assets/animations/alert_animation.json'),
      loopAnimation: false,
      showNextIcon: true,
      onSubmit,
    });
  }, [onSubmit, showInfoOverlay, t]);

  return showNoSubscriptionsOverlay;
};

export default useShowNoSubscriptionsOverlay;
