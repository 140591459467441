import {StyleSheet} from 'react-native';
import spacings from '../../../../mindance-libs/theme/spacings';
import typography from '../../../../mindance-libs/theme/typography';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: spacings.xxxl,
    paddingTop: spacings.xl,
    paddingBottom: spacings.xxxl,
    width: '100%',
  },
  space1: {
    flex: 1,
    minHeight: spacings.xxl,
  },
  space2: {
    flex: 2,
    minHeight: spacings.xxl,
  },
  title: {
    ...typography.title1,
    textAlign: 'center',
  },
  description: {
    marginTop: spacings.xxxl,
    ...typography.body,
    textAlign: 'center',
    alignSelf: 'center',
    maxWidth: 300,
  },
  buttonsWrapper: {
    flexDirection: 'column-reverse',
    gap: spacings.xxxs,
  },
  animation: {
    height: 158,
    width: 158,
    alignSelf: 'center',
  },
});

export default styles;
