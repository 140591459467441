import {Platform, StyleSheet} from 'react-native';
import spacings from '../../theme/spacings';

const INPUT_PADDING = spacings.l;

const styles = StyleSheet.create({
  inputWithValue: {
    paddingTop: INPUT_PADDING,
    paddingBottom: 0,
  },
  inputWithoutValue: {
    paddingTop: INPUT_PADDING / 2,
    paddingBottom: INPUT_PADDING / 2,
  },
  inputIconWithoutValue: {
    marginTop: Platform.select({
      default: 0,
      web: INPUT_PADDING + spacings.xs,
    }),
  },
  inputIconWithValue: {
    marginTop: Platform.select({
      default: -INPUT_PADDING / 2 - spacings.xs,
      web: INPUT_PADDING + spacings.xs,
    }),
  },
});

export const getInputStyle = (value?: string, isFocused?: boolean) =>
  !value && !isFocused ? styles.inputWithoutValue : styles.inputWithValue;

export const getIconStyle = (value?: string, isFocused?: boolean) =>
  !value && !isFocused
    ? styles.inputIconWithoutValue
    : styles.inputIconWithValue;

export default styles;
