import {Platform, TextStyle} from 'react-native';
import {FontNames} from './fontNames';

type FontType =
  | 'title1'
  | 'title2'
  | 'title3'
  | 'title3DemiBold'
  | 'title3Bold'
  | 'title4'
  | 'subtitle'
  | 'subtitleDemiBold'
  | 'subtitleBold'
  | 'subtitle2'
  | 'subtitle3'
  | 'subtitle2Bold'
  | 'headline'
  | 'body'
  | 'bodyDemiBold'
  | 'bodyBold'
  | 'input'
  | 'inputLight'
  | 'inputDemiBold'
  | 'inputBold'
  | 'placeholder'
  | 'caption'
  | 'footnote'
  | 'footnoteBold'
  | 'footnoteDemiBold'
  | 'tabBarLabelDemiBold'
  | 'tabBarLabel'
  | 'small';

const getFontWeight = (fontFamily: FontNames): TextStyle['fontWeight'] => {
  return {
    [FontNames.Thin]: '100',
    [FontNames.Light]: '300',
    [FontNames.Regular]: '400',
    [FontNames.DemiBold]: Platform.OS === 'android' ? '500' : '600',
    [FontNames.Bold]: Platform.OS === 'android' ? '500' : '700',
  }[fontFamily] as TextStyle['fontWeight'];
};

const typography: Record<
  FontType,
  Pick<TextStyle, 'fontFamily' | 'fontSize' | 'lineHeight' | 'fontWeight'>
> = {
  title1: {
    fontFamily: FontNames.Bold,
    fontSize: 32,
    lineHeight: Platform.OS === 'android' ? 40 : 36,
    fontWeight: getFontWeight(FontNames.Bold),
  },
  title2: {
    fontFamily: FontNames.Bold,
    fontSize: 24,
    lineHeight: Platform.OS === 'android' ? 35 : 32,
    fontWeight: getFontWeight(FontNames.Bold),
  },
  title3: {
    fontFamily: FontNames.Regular,
    fontSize: 18,
    lineHeight: Platform.OS === 'android' ? 28 : 24,
    fontWeight: getFontWeight(FontNames.Regular),
  },
  title3DemiBold: {
    fontFamily: FontNames.DemiBold,
    fontSize: 18,
    lineHeight: Platform.OS === 'android' ? 28 : 24,
    fontWeight: getFontWeight(FontNames.DemiBold),
  },
  title3Bold: {
    fontFamily: FontNames.Bold,
    fontSize: 18,
    lineHeight: Platform.OS === 'android' ? 28 : 24,
    fontWeight: getFontWeight(FontNames.Bold),
  },
  title4: {
    fontFamily: FontNames.DemiBold,
    fontSize: 26,
    lineHeight: Platform.OS === 'android' ? 34 : 30,
    fontWeight: getFontWeight(FontNames.DemiBold),
  },
  headline: {
    fontFamily: FontNames.Bold,
    fontSize: 13,
    lineHeight: Platform.OS === 'android' ? 36 : 32,
    fontWeight: getFontWeight(FontNames.Bold),
  },
  subtitle: {
    fontFamily: FontNames.Regular,
    fontSize: 20,
    lineHeight: Platform.OS === 'android' ? 28 : 24,
    fontWeight: getFontWeight(FontNames.Regular),
  },
  subtitleDemiBold: {
    fontFamily: FontNames.DemiBold,
    fontSize: 20,
    lineHeight: Platform.OS === 'android' ? 28 : 24,
    fontWeight: getFontWeight(FontNames.DemiBold),
  },
  subtitleBold: {
    fontFamily: FontNames.Bold,
    fontSize: 20,
    lineHeight: Platform.OS === 'android' ? 28 : 24,
    fontWeight: getFontWeight(FontNames.Bold),
  },
  subtitle2: {
    fontFamily: FontNames.Regular,
    fontSize: 22,
    lineHeight: Platform.OS === 'android' ? 28 : 24,
    fontWeight: getFontWeight(FontNames.Regular),
  },
  subtitle3: {
    fontFamily: FontNames.Regular,
    fontSize: 14,
    lineHeight: 20,
    fontWeight: getFontWeight(FontNames.Regular),
  },
  subtitle2Bold: {
    fontFamily: FontNames.Bold,
    fontSize: 22,
    lineHeight: Platform.OS === 'android' ? 28 : 24,
    fontWeight: getFontWeight(FontNames.Bold),
  },
  body: {
    fontFamily: FontNames.Regular,
    fontSize: 16,
    lineHeight: Platform.OS === 'android' ? 26 : 22,
    fontWeight: getFontWeight(FontNames.Regular),
  },
  bodyDemiBold: {
    fontFamily: FontNames.DemiBold,
    fontSize: 16,
    lineHeight: Platform.OS === 'android' ? 26 : 22,
    fontWeight: getFontWeight(FontNames.DemiBold),
  },
  bodyBold: {
    fontFamily: FontNames.Bold,
    fontSize: 16,
    lineHeight: Platform.OS === 'android' ? 26 : 22,
    fontWeight: getFontWeight(FontNames.Bold),
  },
  input: {
    fontFamily: FontNames.Regular,
    fontSize: 14,
    lineHeight: Platform.OS === 'android' ? 20 : 16,
    fontWeight: getFontWeight(FontNames.Regular),
  },
  inputLight: {
    fontFamily: FontNames.Light,
    fontSize: 14,
    lineHeight: Platform.OS === 'android' ? 20 : 16,
    fontWeight: getFontWeight(FontNames.Light),
  },
  inputDemiBold: {
    fontFamily: FontNames.DemiBold,
    fontSize: 14,
    lineHeight: Platform.OS === 'android' ? 20 : 16,
    fontWeight: getFontWeight(FontNames.DemiBold),
  },
  inputBold: {
    fontFamily: FontNames.Bold,
    fontSize: 14,
    lineHeight: Platform.OS === 'android' ? 20 : 16,
    fontWeight: getFontWeight(FontNames.Bold),
  },
  placeholder: {
    fontFamily: FontNames.Bold,
    fontSize: 14,
    lineHeight: Platform.OS === 'android' ? 24 : 20,
    fontWeight: getFontWeight(FontNames.Bold),
  },
  caption: {
    fontFamily: FontNames.Regular,
    fontSize: 10,
    lineHeight: Platform.OS === 'android' ? 18 : 14,
    fontWeight: getFontWeight(FontNames.Regular),
  },
  footnote: {
    fontFamily: FontNames.Regular,
    fontSize: 12,
    lineHeight: Platform.OS === 'android' ? 20 : 16,
    fontWeight: getFontWeight(FontNames.Regular),
  },
  footnoteBold: {
    fontFamily: FontNames.Bold,
    fontSize: 12,
    lineHeight: Platform.OS === 'android' ? 20 : 16,
    fontWeight: getFontWeight(FontNames.Bold),
  },
  footnoteDemiBold: {
    fontFamily: FontNames.DemiBold,
    fontSize: 12,
    lineHeight: Platform.OS === 'android' ? 20 : 16,
    fontWeight: getFontWeight(FontNames.DemiBold),
  },
  tabBarLabelDemiBold: {
    fontFamily: FontNames.DemiBold,
    fontSize: 11,
    lineHeight: Platform.OS === 'android' ? 20 : 16,
    fontWeight: getFontWeight(FontNames.DemiBold),
  },
  tabBarLabel: {
    fontFamily: FontNames.Bold,
    fontSize: 11,
    lineHeight: Platform.OS === 'android' ? 20 : 16,
    fontWeight: getFontWeight(FontNames.Bold),
  },
  small: {
    fontFamily: FontNames.Regular,
    fontSize: 10,
    lineHeight: Platform.OS === 'android' ? 14 : 12,
    fontWeight: getFontWeight(FontNames.Regular),
  },
};

export default typography;
