import {AuthStorageType} from '../mindance-libs/types/storage';
import Cookies from 'js-cookie';

export class AuthStorage implements AuthStorageType {
  public setItem = async (key: string, value: string) => {
    Cookies.set(key, value);
  };

  public getItem = async (key: string) => {
    const item = Cookies.get(key);

    return item === undefined ? null : item;
  };

  public removeItem = async (key: string) => {
    Cookies.remove(key);
  };
}
