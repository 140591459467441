import * as R from 'ramda';
import React, {useCallback} from 'react';
import LibraryCourseWideCard from '../components/LibraryCourseWideCard';
import LibrarySessionWideCard from '../components/LibrarySessionWideCard';
import {BaseLibraryItem} from '../types/library';
import {ProgressReportTypes} from '../types/progressReport';
import {isCourseLibraryItem, isSessionLibraryItem} from '../utils/courses';
import {getImageUrl} from '../utils/files';
import {getCurrentProgress} from '../utils/progress';
import {BaseCourseFragment, BaseSessionFragment} from '../generated/graphql';

const DEFAULT_IMAGE_RATIO = 1.2;

const useRenderLibraryItems = ({
  imageWidth,
  onCoursePress,
  onSessionPress,
  imageRatio = DEFAULT_IMAGE_RATIO,
}: {
  imageWidth: number;
  onCoursePress: (id: BaseCourseFragment['id'], difference: number) => void;
  onSessionPress: (id: BaseSessionFragment['id']) => void;
  imageRatio?: number;
}) => {
  const renderItem = useCallback<(item: BaseLibraryItem) => JSX.Element | null>(
    item => {
      const key = item.__typename + item.id;

      if (isCourseLibraryItem(item)) {
        const coursesSessions = item.Courses_Sessions ?? [];
        const progressReports = item.ProgressReports;

        const sessionIds = R.pipe(
          R.map(R.pathOr(undefined, ['Sessions', 'id'])),
          R.filter(R.is(String)),
          R.map(String),
        )(coursesSessions);

        const {total, current, difference} = getCurrentProgress({
          sessionIds,
          progressReports,
        });

        return (
          <LibraryCourseWideCard
            key={key}
            onPress={onCoursePress}
            imageUrl={getImageUrl(item.directus_files, {
              width: imageWidth,
              aspectRatio: imageRatio,
            })}
            imageWidth={imageWidth}
            imageRatio={imageRatio}
            totalProgress={total}
            currentProgress={current}
            difference={difference}
            {...item}
          />
        );
      }

      if (isSessionLibraryItem(item)) {
        const isCompleted = !!item.ProgressReports?.find(
          progressReport =>
            progressReport.Label === ProgressReportTypes.FINISHED_SESSION,
        );

        return (
          <LibrarySessionWideCard
            key={key}
            onPress={onSessionPress}
            imageUrl={getImageUrl(item.directus_files, {
              width: imageWidth,
              aspectRatio: imageRatio,
            })}
            imageWidth={imageWidth}
            imageRatio={imageRatio}
            isCompleted={isCompleted}
            {...item}
          />
        );
      }

      return null;
    },
    [imageRatio, imageWidth, onCoursePress, onSessionPress],
  );

  return renderItem;
};

export default useRenderLibraryItems;
