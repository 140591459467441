import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useWindowDimensions} from 'react-native';
import {BaseMindManagerSubscriptionsFragment} from '../../mindance-libs/generated/graphql';
import colors from '../../mindance-libs/theme/colors';
import breakpointProvider from '../../mindance-libs/utils/breakpointProvider';
import SidebarModal from '../SidebarModal/SidebarModal';
import styles from './SubscriptionsSelectModal.module.scss';
import SubscriptionsSelectModalProps from './SubscriptionsSelectModal.props';

const SubscriptionsSelectModal: React.FC<SubscriptionsSelectModalProps> = ({
  isOpened,
  description,
  selectedSubscriptionsRecord,
  subscriptions,
  onSelectSubscription,
  onDeselectAll,
  onSelectAll,
  onClose,
  onConfirm,
  closeModal,
}) => {
  const {t} = useTranslation();
  const {height, width} = useWindowDimensions();

  const handleClose = useCallback(() => {
    onClose?.();
    closeModal?.();
  }, [closeModal, onClose]);

  const selectedSubscriptionsNumber = Object.values(
    selectedSubscriptionsRecord,
  ).filter(v => !!v).length;

  const listStyle = useMemo<React.CSSProperties>(
    () => ({
      maxHeight:
        height <= 700 || width < breakpointProvider.$gridBreakpoints.sm
          ? (0.8 * height) / 3
          : height / 2.5,
    }),
    [height, width],
  );

  const renderSubscriptionListItem = useCallback<
    (
      props: Pick<BaseMindManagerSubscriptionsFragment, 'id' | 'Label'> & {
        checked: boolean;
        onChange: () => void;
      },
    ) => React.JSX.Element
  >(
    ({checked, id, Label, onChange}) => (
      <label key={id}>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className={
            styles['subscription-select-modal__list__item__default-checkbox']
          }
        />
        <div className={styles['subscription-select-modal__list__item']}>
          <div
            className={
              styles['subscription-select-modal__list__item__checkbox']
            }>
            {checked && (
              <FontAwesomeIcon
                icon={faCheck}
                color={colors.white}
                className={
                  styles['subscription-select-modal__list__item__tick']
                }
              />
            )}
          </div>
          <p className={styles['subscription-select-modal__list__item__label']}>
            {Label}
          </p>
        </div>
      </label>
    ),
    [],
  );

  const SubscriptionsList = useMemo(() => {
    if (!subscriptions) {
      return null;
    }

    const areAllChecked = Object.values(selectedSubscriptionsRecord).every(
      v => v === true,
    );

    const handleSelectAll = () => {
      areAllChecked ? onDeselectAll?.() : onSelectAll?.();
    };

    const CheckAllItem =
      subscriptions.length > 1
        ? renderSubscriptionListItem({
            id: 'all-checked',
            Label: t('mindManagerHome.select_all'),
            checked: areAllChecked,
            onChange: handleSelectAll,
          })
        : null;

    const SubscriptionsItems = subscriptions.map(({id, Label}) => {
      const checked = selectedSubscriptionsRecord[id];
      const handleChange = () => onSelectSubscription(id);

      return renderSubscriptionListItem({
        checked,
        id,
        onChange: handleChange,
        Label,
      });
    });

    return [CheckAllItem, ...SubscriptionsItems];
  }, [
    onDeselectAll,
    onSelectAll,
    onSelectSubscription,
    renderSubscriptionListItem,
    selectedSubscriptionsRecord,
    subscriptions,
    t,
  ]);

  return (
    <SidebarModal
      isOpened={isOpened}
      closeModal={handleClose}
      onDecline={onClose}
      onConfirm={onConfirm}
      confirmButtonDisabled={
        !selectedSubscriptionsNumber || selectedSubscriptionsNumber === 0
      }
      confirmButtonTitle={t('mindManagerHome.accept')}
      declineButtonTitle={t('mindManagerHome.cancel')}
      className={styles['subscription-select-modal']}
      title={t('mindManagerHome.select_locations')}
      declineButtonClassName={
        styles['subscription-select-modal__decline-button']
      }>
      <div className={styles['subscription-select-modal__content']}>
        {description ? (
          <p className={styles['subscription-select-modal__description']}>
            {description}
          </p>
        ) : null}

        {subscriptions && subscriptions.length > 0 ? (
          <>
            <p className={styles['subscription-select-modal__list-title']}>
              {t('mindManagerHome.selected_locations') +
                ' ' +
                `${
                  selectedSubscriptionsNumber > 0
                    ? `(${selectedSubscriptionsNumber})`
                    : ''
                }`}
            </p>
            <div
              className={styles['subscription-select-modal__list']}
              style={listStyle}>
              {SubscriptionsList}
            </div>
          </>
        ) : null}
      </div>
    </SidebarModal>
  );
};

export default React.memo(SubscriptionsSelectModal);
