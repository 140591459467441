import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useWindowDimensions} from 'react-native';
import ErrorOverlay from '../../../../components/ErrorOverlay/ErrorOverlay';
import LoadingOverlay from '../../../../components/LoadingOverlay/LoadingOverlay';
import useElementSize from '../../../../hooks/useElementSize';
import AppModal from '../../../../mindance-libs/components/AppModal';
import BookingFooter from '../../../../mindance-libs/components/BookingFooter';
import BookingInfoContent from '../../../../mindance-libs/components/BookingInfoContent';
import CoachDetailsContent from '../../../../mindance-libs/components/CoachDetailsContent';
import EventDetailsContent from '../../../../mindance-libs/components/EventDetailsContent';
import FilterModesBar from '../../../../mindance-libs/components/FilterModesBar';
import ImportantInfo from '../../../../mindance-libs/components/ImportantInfo';
import LinearSeparator from '../../../../mindance-libs/components/LinearSeparator';
import RoundBackButton from '../../../../mindance-libs/components/RoundBackButton';
import ScreenBase from '../../../../mindance-libs/components/ScreenBase';
import useAppModal from '../../../../mindance-libs/hooks/useAppModal';
import useEventDetails from '../../../../mindance-libs/hooks/useEventDetails';
import PersonIcon from '../../../../mindance-libs/icons/PersonIcon';
import colors from '../../../../mindance-libs/theme/colors';
import {
  BookingItemType,
  EventDetailsModeType,
} from '../../../../mindance-libs/types/booking';
import {getAppointmentsLabel} from '../../../../mindance-libs/utils/booking';
import {RouteKeys} from '../../../../navigation/RouteKeys';
import useTypedNavigate from '../../../../navigation/typed/useTypedNavigate';
import useTypedParams from '../../../../navigation/typed/useTypedParams';
import useTypedSearchParams from '../../../../navigation/typed/useTypedSearchParams';
import {
  COACH_IMAGE_RATIO,
  COACH_IMAGE_SIZE,
  coachImageStyle,
} from './page.data';
import styles from './page.module.scss';

export default function Page() {
  const {t} = useTranslation();

  const {id} = useTypedParams<RouteKeys.EventDetails>();
  const [{companyId, courseId, language, serviceId}] =
    useTypedSearchParams<RouteKeys.EventDetails>();
  const navigate = useTypedNavigate();

  const {width, height} = useWindowDimensions();
  const {
    ref: containerRef,
    onLoad: onContainerLoad,
    size: containerSize,
  } = useElementSize<HTMLDivElement>();
  const headerHeight = (containerSize.height || height) / 3;

  const {
    loading,
    error,
    AppointmentsNumber,
    Label,
    Description,
    isCompanyEvent,
    coaches,
    coachLabel,
    coachImage,
    coverImage,
    durationTime,
    mediums,
    submitButtonLabel,
    detailsModes,
    selectedMode,
    updateSelectedMode,
    ...event
  } = useEventDetails(id, {
    language,
    companyId,
    courseId,
    serviceId,
    coverImageWidth: containerSize.width || width,
    coverImageHeight: headerHeight,
    coachImageWidth: COACH_IMAGE_SIZE,
    coachImageRatio: COACH_IMAGE_RATIO,
  });

  const {isModalVisible, hideModal, showModal} = useAppModal();

  const headerStyle = useMemo<React.CSSProperties>(
    () => ({height: headerHeight}),
    [headerHeight],
  );

  const imageStyle = useMemo<React.CSSProperties>(
    () => ({
      objectFit:
        event.BookingType === BookingItemType.EVENT_EVENTTOOL
          ? 'contain'
          : 'cover',
    }),
    [event.BookingType],
  );

  const ScreenContent = useMemo(() => {
    const EventDetails = (
      <EventDetailsContent
        coach={isCompanyEvent ? coachLabel : undefined}
        cost={event.Cost}
        description={Description}
        venue={event.Venues}
        startDate={event.StartDate}
        endDate={isCompanyEvent ? event.EndDate : undefined}
        startTime={event.StartTime}
        endTime={event.EndTime}
        tags={event.TopicArea}
        contact={event.Contact}
        bookingNumber={event.BookingNumber}
        model={isCompanyEvent ? event.model : undefined}
        bookingInfo={event.BookingInfo}
      />
    );

    if (isCompanyEvent) {
      return EventDetails;
    }

    return {
      [EventDetailsModeType.COACH]: <CoachDetailsContent {...coaches?.[0]} />,
      [EventDetailsModeType.EVENT]: EventDetails,
    }[selectedMode];
  }, [
    Description,
    coachLabel,
    coaches,
    event.BookingInfo,
    event.BookingNumber,
    event.Contact,
    event.Cost,
    event.EndDate,
    event.EndTime,
    event.StartDate,
    event.StartTime,
    event.TopicArea,
    event.Venues,
    event.model,
    isCompanyEvent,
    selectedMode,
  ]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const bookEvent = useCallback(() => {
    if (isCompanyEvent) {
      event.BookingLink && window.open(event.BookingLink, '_blank');
      return;
    }

    id &&
      navigate(
        {to: RouteKeys.EventBookingForm, params: {id}},
        {
          state: {
            bookingInfo: event.BookingInfo,
            cost: event.Cost,
            endDate: event.EndDate,
            endTime: event.EndTime,
            startDate: event.StartDate,
            startTime: event.StartTime,
            title: Label,
            venue: event.Venues,
          },
        },
      );
  }, [
    Label,
    event.BookingInfo,
    event.BookingLink,
    event.Cost,
    event.EndDate,
    event.EndTime,
    event.StartDate,
    event.StartTime,
    event.Venues,
    id,
    isCompanyEvent,
    navigate,
  ]);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay />;
  }

  return (
    <ScreenBase>
      <div
        className={styles.event__container}
        ref={containerRef}
        onLoad={onContainerLoad}>
        <div className={styles.event__header} style={headerStyle}>
          <img
            className={styles.event__header__image}
            src={coverImage}
            style={imageStyle}
          />
          <RoundBackButton
            size={40}
            borderColor={colors.whiteAlpha50}
            iconColor={colors.white}
            iconSize={30}
            onPress={goBack}
          />
          <div className={styles.event__header__labels}>
            {durationTime ? (
              <div className={styles['event__header__label-container']}>
                <p>{durationTime}</p>
              </div>
            ) : (
              <div />
            )}
            {AppointmentsNumber && AppointmentsNumber > 0 ? (
              <div className={styles['event__header__label-container']}>
                <p>{getAppointmentsLabel(t)(AppointmentsNumber)}</p>
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.event__content}>
          <div className={styles.event__content__top}>
            <h1>{Label}</h1>
            {!isCompanyEvent ? (
              <div className={styles.event__content__top__subheader}>
                {coachLabel ? (
                  <div
                    className={
                      styles['event__content__top__subheader__coach-container']
                    }>
                    {coachImage ? (
                      <img src={coachImage} style={coachImageStyle} />
                    ) : (
                      <PersonIcon />
                    )}
                    <p>{coachLabel}</p>
                  </div>
                ) : (
                  <div />
                )}
                <ImportantInfo onPress={showModal} />
              </div>
            ) : null}

            {!isCompanyEvent ? (
              <FilterModesBar
                data={detailsModes}
                onItemPress={updateSelectedMode}
              />
            ) : null}

            <LinearSeparator />
          </div>

          {ScreenContent}
        </div>

        <LinearSeparator />
        <footer className={styles.event__footer}>
          <BookingFooter
            showLeft={!isCompanyEvent}
            cost={event.Cost}
            startDate={event.StartDate}
            endDate={event.EndDate}
            buttonLabel={submitButtonLabel}
            onButtonPress={bookEvent}
          />
        </footer>
      </div>

      <AppModal
        isVisible={isModalVisible}
        onDismiss={hideModal}
        title={t('common.info')}>
        <BookingInfoContent
          info={event.Infos}
          address={event.Address}
          bookingState={event.BookingState}
          registrationDeadline={event.RegistrationDeadline}
          mediums={mediums}
        />
      </AppModal>
    </ScreenBase>
  );
}
