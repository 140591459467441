import {FormikHelpers} from 'formik';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import BookingFooter from '../../../../../../mindance-libs/components/BookingFooter';
import BookingFormFields from '../../../../../../mindance-libs/components/BookingFormFields';
import RoundBackButton from '../../../../../../mindance-libs/components/RoundBackButton';
import ScreenBase from '../../../../../../mindance-libs/components/ScreenBase';
import {useUpdateUserDataMutation} from '../../../../../../mindance-libs/generated/graphql';
import useBookingFormFields from '../../../../../../mindance-libs/hooks/useBookingFormFields';
import useErrorMessage from '../../../../../../mindance-libs/hooks/useErrorMessage';
import colors from '../../../../../../mindance-libs/theme/colors';
import {BookingForm} from '../../../../../../mindance-libs/types/booking';
import {trimObjectValues} from '../../../../../../mindance-libs/utils/common';
import {reportError} from '../../../../../../mindance-libs/utils/loggingHelpers';
import useTypedNavigate from '../../../../../../navigation/typed/useTypedNavigate';
import {footerStyle} from '../page.data';
import styles from './page.module.scss';

export default function Page() {
  const {t} = useTranslation();
  const {error, showMessage} = useErrorMessage();

  const navigate = useTypedNavigate();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const [updateUserData] = useUpdateUserDataMutation({
    refetchQueries: ['profile'],
  });

  const updateData = useCallback<
    (
      values: BookingForm,
      formikHelpers: FormikHelpers<BookingForm>,
    ) => Promise<void>
  >(
    async values => {
      if (
        values.city &&
        values.phone &&
        values.department &&
        values.employer &&
        values.postCode &&
        values.personalStatus
      ) {
        try {
          await updateUserData({
            variables: {
              data: {
                ...trimObjectValues(values),
                personalStatus: Number(values.personalStatus),
              },
            },
          });

          goBack();
        } catch (e) {
          reportError('update user data', e);
        }
      }
    },
    [goBack, updateUserData],
  );

  const {
    values,
    handleFormBlur,
    handleFormFocus,
    handleFormChange,
    hasError,
    personalStatusError,
    personalStatusItems,
    personalStatusPlaceholder,
    isSubmitting,
    isValid,
    handleSubmit,
    isFocused,
  } = useBookingFormFields({onSubmit: updateData, onError: showMessage});

  return (
    <ScreenBase error={error}>
      <div className={styles['user-data__container']}>
        <div className={styles['user-data__header']}>
          <RoundBackButton
            size={40}
            borderColor={colors.whiteAlpha50}
            iconColor={colors.white}
            iconSize={30}
            onPress={goBack}
          />
        </div>
        <div className={styles['user-data__content']}>
          <div className={styles['user-data__content__header']}>
            <h1>{t('screenTitle.BookingUpdateDataScreen')}</h1>
          </div>

          <div className={styles['user-data__content__fields']}>
            <BookingFormFields
              values={values}
              handleBlur={handleFormBlur}
              handleFocus={handleFormFocus}
              handleChange={handleFormChange}
              hasError={hasError}
              personalStatusItems={personalStatusItems}
              personalStatusPlaceholder={personalStatusPlaceholder}
              personalStatusError={personalStatusError}
              isFocused={isFocused}
              editMode
            />
          </div>
        </div>

        <BookingFooter
          style={footerStyle}
          showLeft={false}
          buttonDisabled={!isValid || isSubmitting}
          buttonLabel={t('common.save')}
          onButtonPress={handleSubmit}
        />
      </div>
    </ScreenBase>
  );
}
