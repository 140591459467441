import {useCallback, useMemo, useRef, useState} from 'react';
import {
  FlatList,
  NativeScrollEvent,
  NativeSyntheticEvent,
  useWindowDimensions,
} from 'react-native';

const useSliderPosition = <T extends {}>(width?: number) => {
  const flatListRef = useRef<FlatList<T>>(null);

  const {width: windowWidth} = useWindowDimensions();

  const contentWidth = width ?? windowWidth;

  const [integerPosition, setIntegerPosition] = useState(0);

  const handleScroll = useCallback<
    (event: NativeSyntheticEvent<NativeScrollEvent>) => void
  >(
    ({nativeEvent}) => {
      const newValue = nativeEvent.contentOffset.x / contentWidth;
      const integerPart = Math.floor(newValue);
      setIntegerPosition(integerPart);
    },
    [contentWidth],
  );

  return useMemo(
    () => ({
      handleScroll,
      flatListRef,
      animatedPosition: integerPosition,
      integerPosition,
    }),
    [handleScroll, integerPosition],
  );
};

export default useSliderPosition;
