import React from 'react';
import {Insets, StyleProp, TouchableOpacity, ViewStyle} from 'react-native';
import Svg, {Path} from 'react-native-svg';
import colors from '../../theme/colors';

type Props = {
  isLiked?: boolean;
  onPress?: () => void;
  width?: number;
  height?: number;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

const ICON_COLOR = colors.lightGray;

const HIT_SLOP: Insets = {
  bottom: 5,
  left: 5,
  right: 5,
  top: 5,
};

const LikeButton: React.FC<Props> = ({
  isLiked,
  width = 16,
  height = 16,
  style,
  onPress,
  testID,
}) => {
  return (
    <TouchableOpacity
      style={style}
      testID={testID}
      onPress={onPress}
      hitSlop={HIT_SLOP}>
      <Svg width={width} height={height} viewBox="0 0 17 17" fill="none">
        <Path
          d="M8.11881 13.6627C8.11881 13.6627 1.77588 10.1209 1.77588 5.8201C1.77588 5.0598 2.04006 4.32298 2.52347 3.73501C3.00688 3.14704 3.67966 2.74423 4.42735 2.59512C5.17503 2.44602 5.95143 2.55982 6.62445 2.91717C7.29748 3.27452 7.82554 3.85334 8.11881 4.55516V4.55516C8.41207 3.85334 8.94014 3.27452 9.61316 2.91717C10.2862 2.55982 11.0626 2.44602 11.8103 2.59512C12.558 2.74423 13.2307 3.14704 13.7141 3.73501C14.1976 4.32298 14.4617 5.0598 14.4617 5.8201C14.4617 10.1209 8.11881 13.6627 8.11881 13.6627Z"
          stroke={ICON_COLOR}
          strokeWidth={0.811895}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill={isLiked ? ICON_COLOR : colors.transparent}
        />
      </Svg>
    </TouchableOpacity>
  );
};

export default React.memo(LikeButton);
