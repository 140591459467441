import {StyleSheet} from 'react-native';
import spacings from '../../../theme/spacings';
import typography from '../../../theme/typography';

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingHorizontal: spacings.xxxl,
    paddingVertical: spacings.xl,
    width: '100%',
  },
  upperSpacer: {
    flex: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  animation: {
    width: '100%',
    aspectRatio: 1,
  },
  bottomSpacer: {
    flex: 1,
  },
  title: {
    ...typography.title1,
    textAlign: 'center',
  },
  description: {
    marginTop: spacings.xxxl,
    ...typography.body,
    textAlign: 'center',
  },
  button: {
    display: 'flex',
    flex: 1,
    marginTop: spacings.xl,
    marginBottom: spacings.xxxxxxxl,
  },
});

export default styles;
