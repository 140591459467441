import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {SMALL_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  content: {
    flex: 1,
    borderRadius: SMALL_BORDER_RADIUS,
    paddingHorizontal: spacings.m,
    paddingTop: spacings.m,
    paddingBottom: spacings.xl,
  },
  upperContent: {
    flex: 0.7,
  },
  bottomContent: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  playButtonWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacings.xxs,
  },
  timeRecommendation: {
    ...typography.input,
    color: colors.whiteAlpha50,
  },
  title: {
    ...typography.bodyDemiBold,
    color: colors.whiteAlpha95,
    marginTop: spacings.xxxxs,
  },
});

export default styles;
