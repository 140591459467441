import {ChangeEvent} from 'react';
import {TextInputProps} from 'react-native';
import {SvgProps} from 'react-native-svg';
import {
  BaseBookingFragment,
  BookingInfoFragment,
  Maybe,
} from '../generated/graphql';

export enum EventDetailsModeType {
  EVENT = 'event_details',
  COACH = 'coach_details',
}

export enum BookingMetaInfoItemType {
  COACH = 'coach',
  COST = 'cost',
  LOCATION = 'location',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  DURATION = 'duration',
}

export type BookingMetaInfoItem = {
  type: BookingMetaInfoItemType;
  label: string | undefined | null;
  Icon: React.NamedExoticComponent<SvgProps>;
};

export enum BookingItemType {
  EVENT = 'EVENT',
  EVENT_EVENTTOOL = 'EVENT_EVENTTOOL',
  GROUP_COACHING = 'SHOW_GROUP_COACHING',
  ONE_TO_ONE_SESSION = 'SHOW_ONETOONESESSION_COACHING',
}

export enum EventtoolEventType {
  DAY_APPOINTMENT = 'DAY_APPOINTMENT',
  CONNECTED_SERIAL_APPOINTMENT = 'CONNECTED_SERIAL_APPOINTMENT',
  INDEPENDENT_SERIAL_APPOINTMENT = 'INDEPENDENT_SERIAL_APPOINTMENT',
}

export type ProductFilterItem = {
  id?: Maybe<string>;
  label?: Maybe<string>;
  value?: Maybe<string>;
};

export type ProductFilter = {
  id?: Maybe<string>;
  label?: Maybe<string>;
  products?: Maybe<ProductFilterItem[]>;
};

export type BookingForm = {
  postCode: string;
  city: string;
  phone: string;
  department: string;
  employer: string;
  personalStatus?: number | null | string;
};

export type BookingFormHandleBlur = Record<
  keyof BookingForm,
  TextInputProps['onBlur'] | undefined
>;

export type BookingFormHandleFocus = Record<
  keyof BookingForm,
  (() => void) | undefined
>;

export type BookingFormHandleChange = Record<
  keyof BookingForm,
  (e: string | ChangeEvent<any>) => void
>;

export type BookingFormHasError = Record<keyof BookingForm, boolean>;

export type BookingFormFocused = Record<keyof BookingForm, boolean | undefined>;

export type BookingInfo = Omit<BookingInfoFragment, '__typename' | 'model'> & {
  model?: string | null;
  BookingLink?: string | null;
};

export type OnCompanyEventPress = (
  id: BaseBookingFragment['id'],
  options: {
    language?: Maybe<string>;
    companyId?: BaseBookingFragment['companyId'];
    courseId?: BaseBookingFragment['courseId'];
    serviceId?: BaseBookingFragment['serviceId'];
  },
) => void;
