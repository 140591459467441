import classNames from 'classnames';
import React, {PropsWithChildren, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import RoundCloseButton from '../../mindance-libs/components/RoundCloseButton';
import colors from '../../mindance-libs/theme/colors';
import Button from '../Button/Button';
import {ButtonStyle} from '../Button/Button.props';
import SidebarModalProps from './SidebarModal.props';
import rnStyles from './SidebarModal.styles';
import styles from './SidebarModal.module.scss';

const SidebarModal: React.FC<PropsWithChildren<SidebarModalProps>> = ({
  className,
  testID,
  isOpened,
  closeModal,
  title,
  confirmButtonClassName,
  confirmButtonDisabled = false,
  confirmButtonTitle,
  confirmButtonVisible = true,
  declineButtonClassName,
  declineButtonDisabled = false,
  declineButtonTitle,
  declineButtonVisible = true,
  onConfirm,
  onDecline,
  children,
}) => {
  const {t} = useTranslation();

  const mergedStyle = classNames(styles['sidebar-modal__modal'], className);

  const declineButton = useMemo(
    () => ({
      onClick: () => {
        onDecline?.();
        closeModal?.();
      },
    }),
    [closeModal, onDecline],
  );

  const confirmButton = useMemo(
    () => ({
      onClick: () => {
        onConfirm?.();
        closeModal?.();
      },
    }),
    [closeModal, onConfirm],
  );

  if (!isOpened) {
    return null;
  }

  return (
    <div className={styles['sidebar-modal__overlay']} data-testid={testID}>
      <div className={mergedStyle}>
        <div className={styles['sidebar-modal__modal__head']}>
          {title ? (
            <p className={styles['sidebar-modal__modal__title']}>{title}</p>
          ) : (
            <div />
          )}
          <RoundCloseButton
            onPress={closeModal}
            style={rnStyles.closeButton}
            iconColor={colors.black100}
          />
        </div>
        {children}
        <div className={styles['sidebar-modal__modal__buttons-container']}>
          {declineButtonVisible ? (
            <Button
              buttonStyle={ButtonStyle.SECONDARY}
              button={declineButton}
              disabled={declineButtonDisabled}
              className={classNames(
                styles['sidebar-modal__modal__buttons-container__button'],
                styles[
                  'sidebar-modal__modal__buttons-container__button--declined'
                ],
                declineButtonClassName,
              )}>
              {declineButtonTitle ?? t('common.discard')}
            </Button>
          ) : null}
          {confirmButtonVisible ? (
            <Button
              buttonStyle={ButtonStyle.SECONDARY}
              button={confirmButton}
              disabled={confirmButtonDisabled}
              className={classNames(
                styles['sidebar-modal__modal__buttons-container__button'],
                confirmButtonClassName,
              )}>
              {confirmButtonTitle ?? t('common.save')}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default React.memo(SidebarModal);
