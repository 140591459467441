export const removeUndefined = <T extends Record<string, unknown>>(
  obj: T,
): T => {
  for (let key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  }
  return obj;
};
