import {ApolloError} from '@apollo/client';
import * as R from 'ramda';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {
  BaseFindMyScreenSetupFragment,
  Tags,
  useFindMyScreenSetupQuery,
  useProfileQuery,
  UserCompanyFragment,
  UserFieldsFragment,
  UserSubscriptionFragment,
  useServiceLevelQuery,
  useUpdateUserTagsMutation,
} from '../../generated/graphql';
import useMessage, {OnMessageShow} from '../../hooks/useMessage';
import useTrackAppState from '../../hooks/useTrackAppState';
import {BookingItemType} from '../../types/booking';
import {
  AppProductsLabels,
  ScreensSetupCategoriesAvailability,
} from '../../types/common';
import {LibraryItemType} from '../../types/library';
import {OnUpdateUserTags, ServiceLevel} from '../../types/user';
import {checkIsMindManagerClient} from '../../utils/auth/authClients';
import {getLibraryItemType} from '../../utils/courses';
import {reportError} from '../../utils/loggingHelpers';
import {useAuthContext} from './AuthContext';

export type AppContextType = Omit<BaseFindMyScreenSetupFragment, 'id'> & {
  isLoggedIn: boolean;
  isOnboardingPassed: boolean;
  isLoading: boolean;
  isLoggedInLoading: boolean;
  updateUserTags: OnUpdateUserTags;
  user: UserFieldsFragment | undefined;
  isBookingDataFilled: boolean;
  userTags: Tags['id'][];
  screenSetupLoading: boolean;
  screenSetupRefreshing: boolean;
  screenSetupError?: ApolloError;
  screensSetupCategoriesAvailability: ScreensSetupCategoriesAvailability;
  refetchScreenSetupData: () => void;
  showGlobalMessage: OnMessageShow;
  globalMessage: string | undefined;
  corporateBrandingCompany: UserCompanyFragment | undefined;
  serviceLevel: ServiceLevel | undefined;
  isMindManager: boolean;
  trackAppUsage: (resetTime?: boolean) => Promise<void>;
  userSubscriptions: UserSubscriptionFragment[];
};

const AppContext = createContext<AppContextType | null>(null);

export const useAppContext = (): AppContextType => {
  const appContext = useContext(AppContext);

  if (appContext === null) {
    throw new Error(
      'App context cannot be null, please add a context provider.',
    );
  }

  return appContext;
};

export const AppContextProvider: FC<PropsWithChildren<{}>> = ({children}) => {
  const {i18n} = useTranslation();

  const {
    accessToken,
    isLoading: isLoggedInLoading,
    isLoggedIn,
  } = useAuthContext();

  const {data, loading: profileLoading} = useProfileQuery({
    skip: !accessToken,
    onError: error => reportError('useProfileQuery error', error),
    variables: {language: i18n.language},
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  });

  const {data: serviceLevelData, loading: serviceLevelLoading} =
    useServiceLevelQuery({
      skip: !accessToken,
      onError: error => reportError('useServiceLevelQuery error', error),
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
    });

  const trackAppUsage = useTrackAppState({email: data?.profile.EMail});

  const {
    data: screenSetupData,
    loading: screenSetupLoading,
    error: screenSetupError,
    previousData: previousScreenSetupData,
    refetch: refetchScreenSetupData,
  } = useFindMyScreenSetupQuery({
    variables: {
      languages_code: i18n.language,
      force_refresh: true,
    },
    onError: err => reportError('useFindMyScreenSetupQuery error', err),
    skip: !isLoggedIn,
  });

  const [submitUserTags] = useUpdateUserTagsMutation({
    refetchQueries: 'active',
  });

  const {showMessage: showGlobalMessage, message: globalMessage} = useMessage();

  const profile = useMemo(() => data?.profile, [data?.profile]);

  const userTags = useMemo<Tags['id'][]>(() => {
    if (
      !profile?.Users_Tags ||
      !Array.isArray(profile?.Users_Tags) ||
      profile?.Users_Tags?.length < 1
    ) {
      return [];
    }

    const allTags = profile.Users_Tags.map(Users_Tag => Users_Tag.Tags?.id);
    return R.reject(R.isNil, allTags);
  }, [profile?.Users_Tags]);

  const isOnboardingPassed = !!(
    profile?.Users_Tags && profile?.Users_Tags?.length > 0
  );

  const isMindManager = checkIsMindManagerClient(accessToken);

  const isBookingDataFilled = !!(
    profile?.PostalCode &&
    profile?.Phone &&
    profile?.City &&
    profile?.Department &&
    profile?.Employer &&
    profile?.PersonalStatus
  );

  const isLoading = profileLoading || isLoggedInLoading || serviceLevelLoading;

  const userSubscriptions = useMemo<UserSubscriptionFragment[]>(() => {
    const allEntries = profile?.Users_ActivationCodes?.flatMap(
      ({ActivationCodes}) =>
        ActivationCodes?.Subscriptions_ActivationCodes?.flatMap(
          ({Subscriptions}) => Subscriptions,
        ),
    );

    return R.filter(R.isNotNil, allEntries ?? []);
  }, [profile?.Users_ActivationCodes]);

  const screensSetupCategoriesAvailability =
    useMemo<ScreensSetupCategoriesAvailability>(() => {
      const bookingSetup =
        screenSetupData?.findMyScreenSetup?.bookingScreenSetup;
      const bookingLabels =
        bookingSetup?.Booking_translations?.[0]?.Booking_translations_Products?.map(
          ({Products}) => Products?.Label,
        ) ?? [];

      const librarySetup =
        screenSetupData?.findMyScreenSetup?.libraryScreenSetup;
      const libraryFormatLabels =
        librarySetup?.Library_translations?.[0]?.FormatItems?.map(
          ({LibraryItems}) =>
            LibraryItems?.TagGroups?.TagGroups_translations?.[0]?.Label,
        ) ?? [];

      const libraryItems = libraryFormatLabels
        ?.filter(value => !!value)
        .map(label => getLibraryItemType(label));

      return {
        [AppProductsLabels.ONBOARDING]:
          !!screenSetupData?.findMyScreenSetup?.onboardingScreenSetup,
        [AppProductsLabels.HOME]:
          !!screenSetupData?.findMyScreenSetup?.homeScreenSetup,
        [AppProductsLabels.LIBRARY]: !!librarySetup,
        [AppProductsLabels.BOOKING]: !!bookingSetup,
        [AppProductsLabels.NEWS]:
          !!screenSetupData?.findMyScreenSetup?.newsScreenSetup,

        [AppProductsLabels.EVENT]: !!bookingLabels.find(
          label => label === BookingItemType.EVENT,
        ),
        [AppProductsLabels.EVENT_EVENTTOOL]: !!bookingLabels.find(
          label => label === BookingItemType.EVENT_EVENTTOOL,
        ),
        [AppProductsLabels.GROUP_COACHING]: !!bookingLabels.find(
          label => label === BookingItemType.GROUP_COACHING,
        ),
        [AppProductsLabels.ONE_TO_ONE_SESSION]: !!bookingLabels.find(
          label => label === BookingItemType.ONE_TO_ONE_SESSION,
        ),
        [AppProductsLabels.COURSES]: !!libraryItems.find(
          label => label === LibraryItemType.COURSES,
        ),
        [AppProductsLabels.SESSIONS]: !!libraryItems.find(
          label => label === LibraryItemType.SESSIONS,
        ),
      };
    }, [
      screenSetupData?.findMyScreenSetup?.bookingScreenSetup,
      screenSetupData?.findMyScreenSetup?.homeScreenSetup,
      screenSetupData?.findMyScreenSetup?.libraryScreenSetup,
      screenSetupData?.findMyScreenSetup?.newsScreenSetup,
      screenSetupData?.findMyScreenSetup?.onboardingScreenSetup,
    ]);

  const updateUserTags = useCallback<OnUpdateUserTags>(
    async tags => {
      try {
        await submitUserTags({variables: {tags}});
      } catch (error) {
        reportError('updateUserTags error', error);
      }
    },
    [submitUserTags],
  );

  const [corporateBrandingCompany, setCorporateBrandingCompany] = useState<
    UserCompanyFragment | undefined
  >(undefined);

  const [serviceLevel, setServiceLevel] = useState<ServiceLevel | undefined>(
    undefined,
  );

  useEffect(() => {
    if (profileLoading) {
      return;
    }

    const companies = userSubscriptions.map(({Companies}) => Companies);
    const company =
      companies?.find(
        item =>
          !!item?.PrimaryColor ||
          !!item?.SecondaryColor ||
          !!item?.TertiaryColor ||
          !!item?.BackdropColor ||
          !!item?.HighlightColor,
      ) ?? companies?.[0];

    setCorporateBrandingCompany(company ?? undefined);
  }, [profileLoading, userSubscriptions]);

  useEffect(() => {
    if (serviceLevelLoading) {
      return;
    }
    const serviceData = serviceLevelData?.serviceLevel;
    if (serviceData) {
      setServiceLevel(serviceData as ServiceLevel);
    }
  }, [serviceLevelData?.serviceLevel, serviceLevelLoading]);

  const value = useMemo<AppContextType>(
    () => ({
      isLoading,
      isLoggedIn,
      isOnboardingPassed,
      updateUserTags,
      user: data?.profile,
      isBookingDataFilled,
      userTags,
      isLoggedInLoading,
      ...screenSetupData?.findMyScreenSetup,
      screenSetupLoading: screenSetupLoading && !previousScreenSetupData,
      screenSetupRefreshing: screenSetupLoading,
      screenSetupError,
      screensSetupCategoriesAvailability,
      globalMessage,
      showGlobalMessage,
      corporateBrandingCompany,
      serviceLevel,
      refetchScreenSetupData,
      isMindManager,
      trackAppUsage,
      userSubscriptions,
    }),
    [
      isLoading,
      isLoggedIn,
      isOnboardingPassed,
      updateUserTags,
      data?.profile,
      isBookingDataFilled,
      userTags,
      isLoggedInLoading,
      screenSetupData?.findMyScreenSetup,
      screenSetupLoading,
      previousScreenSetupData,
      screenSetupError,
      screensSetupCategoriesAvailability,
      globalMessage,
      showGlobalMessage,
      corporateBrandingCompany,
      serviceLevel,
      refetchScreenSetupData,
      isMindManager,
      trackAppUsage,
      userSubscriptions,
    ],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
