import React, {useEffect, useMemo, useState} from 'react';
import {Platform, StatusBar, View} from 'react-native';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import AlertIcon from '../../icons/AlertIcon';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import AppText from '../AppText';
import styles from './styles';

const AnimatedView = Animated.createAnimatedComponent(View);

type AnimatedViewProps = React.ComponentProps<typeof AnimatedView>;
type Props = {
  message?: string;
  topSpace?: number;
  isError?: boolean;
} & AnimatedViewProps;

const MessageSnackBar: React.FC<Props> = ({
  message,
  topSpace = 0,
  style,
  testID,
  isError,
  ...props
}) => {
  const {top} = useSafeAreaInsets();

  const topHeight = Platform.select({
    ios: top,
    android: StatusBar.currentHeight,
    web: 50,
    default: 0,
  });

  const yPosition = topHeight + spacings.l + topSpace;

  const shouldShowMessage = !!(message && message.length > 0);

  const transitionY = useSharedValue(
    shouldShowMessage ? yPosition : -yPosition,
  );

  useEffect(() => {
    transitionY.value = shouldShowMessage ? yPosition : -yPosition;
  }, [shouldShowMessage, transitionY, yPosition]);

  const [backgroundColor, setBackgroundColor] = useState(
    isError ? colors.alert : colors.message,
  );

  useEffect(() => {
    if (shouldShowMessage) {
      setBackgroundColor(isError ? colors.alert : colors.message);
    }
  }, [isError, shouldShowMessage]);

  const animatedStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          translateY: withTiming(transitionY.value, {
            duration: 500,
            easing: Easing.bezier(0.25, 0.1, 0.25, 1),
          }),
        },
      ],
    }),
    [transitionY],
  );

  const containerStyle = useMemo(
    () => [
      styles.container,
      animatedStyle,
      {backgroundColor: backgroundColor},
      style,
    ],
    [animatedStyle, backgroundColor, style],
  );

  return (
    <AnimatedView style={containerStyle} testID={testID} {...props}>
      <AlertIcon fill={colors.white} testID={`${testID}.alertIcon`} />
      <View style={styles.textContainer}>
        <AppText style={styles.text} testID={`${testID}.message`}>
          {message}
        </AppText>
      </View>
    </AnimatedView>
  );
};

export default React.memo(MessageSnackBar);
