import {useApolloClient} from '@apollo/client';
import {useCallback, useMemo} from 'react';
import {useAppContext} from '../store/contexts/AppContext';
import {useAuthContext} from '../store/contexts/AuthContext';
import {reportError} from '../utils/loggingHelpers';

const useLogout = () => {
  const {logout, deleteAccount} = useAuthContext();
  const {trackAppUsage} = useAppContext();
  const client = useApolloClient();

  const onLogout = useCallback(() => {
    trackAppUsage(true);
    client
      .resetStore()
      .catch(error => reportError('client.resetStore error', error));
    logout().catch(error => reportError('logout error', error));
  }, [client, logout, trackAppUsage]);

  const onDeleteAccount = useCallback(() => {
    client
      .resetStore()
      .catch(error => reportError('client.resetStore error', error));
    deleteAccount().catch(error => reportError('deleteAccount error', error));
  }, [client, deleteAccount]);

  return useMemo(
    () => ({onLogout, onDeleteAccount}),
    [onDeleteAccount, onLogout],
  );
};

export default useLogout;
