export enum GbuPsycheHomeCardType {
  LEADERSHIP = 'LEADERSHIP',
  WORKING_ENVIRONMENT = 'WORKING_ENVIRONMENT',
}

export type GbuPsycheHomeCardData = {
  label: GbuPsycheHomeCardType;
  title: string;
};

type GbuPsycheHomeCardProps = {
  className?: string;
  testID?: string;
};

export default GbuPsycheHomeCardProps;
