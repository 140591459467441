import {FormikErrors, FormikTouched, useFormik} from 'formik';
import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {TextInputProps} from 'react-native-paper';
import useBooleanValue from './useBooleanValue';

const useInputFormikHandlers = <Values extends Record<string, any>>(
  key: keyof Values,
  {
    errors,
    touched,
    handleChange,
    handleBlur,
    onError,
    autoFocus = false,
  }: {
    errors: FormikErrors<Values>;
    touched: FormikTouched<Values>;
    handleChange: ReturnType<typeof useFormik>['handleChange'];
    handleBlur: ReturnType<typeof useFormik>['handleBlur'];
    onError?: (error: string) => void;
    autoFocus?: boolean;
  },
) => {
  const {t} = useTranslation();

  const {
    value: isFocused,
    setTrue: handleValueFocus,
    setFalse: removeFocus,
  } = useBooleanValue(autoFocus);

  const error = errors[key] ? t(errors[key] as string) : undefined;

  const setValue = useMemo(() => handleChange(key), [handleChange, key]);

  const handleValueBlur = useCallback<NonNullable<TextInputProps['onBlur']>>(
    e => {
      handleBlur(key as string)(e);
      removeFocus();

      if (error) {
        onError?.(error);
      }
    },
    [error, handleBlur, key, onError, removeFocus],
  );

  return useMemo(
    () => ({
      error,
      hasError: touched[key] && !!error,
      setValue,
      handleValueBlur,
      handleValueFocus,
      isFocused,
    }),
    [
      error,
      handleValueBlur,
      handleValueFocus,
      isFocused,
      key,
      setValue,
      touched,
    ],
  );
};

export default useInputFormikHandlers;
