import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {
  DEFAULT_BORDER_RADIUS,
  SMALL_BORDER_RADIUS,
} from '../../theme/spacings';
import typography from '../../theme/typography';

export const IMAGE_RATIO = 1.4;
export const LANGUAGE_IMAGE_SIZE = 18;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  image: {
    borderRadius: SMALL_BORDER_RADIUS,
  },
  content: {
    flex: 1,
    paddingTop: spacings.l,
    marginLeft: spacings.xl,
    flexShrink: 1,
    justifyContent: Platform.select({default: 'flex-start', web: 'center'}),
  },
  nameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
    flexWrap: 'wrap',
  },
  languageImage: {
    width: LANGUAGE_IMAGE_SIZE,
    height: LANGUAGE_IMAGE_SIZE,
    borderRadius: LANGUAGE_IMAGE_SIZE / 2,
    marginRight: spacings.xs,
  },
  name: {
    ...typography.title3DemiBold,
    lineHeight: 20,
    marginRight: spacings.xs,
  },
  specialization: {
    ...typography.input,
    color: colors.whiteAlpha75,
    marginTop: spacings.xxxxs,
  },
  tagsContainer: {
    marginTop: Platform.select({default: spacings.xxl, web: spacings.xs}),
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexShrink: 1,
  },
  tagWrapper: {
    flexDirection: 'row',
    marginTop: spacings.xs,
  },
  tagSpace: {
    width: spacings.xxs,
  },
  tag: {
    borderRadius: DEFAULT_BORDER_RADIUS,
    backgroundColor: colors.whiteAlpha10,
    paddingHorizontal: spacings.s,
    paddingBottom: spacings.xs,
    paddingTop: spacings.xxxs,
  },
  tagLabel: {
    ...typography.input,
    lineHeight: 24,
  },
});

export default styles;
