import * as R from 'ramda';
import React, {useMemo} from 'react';
import {
  Image,
  ImageSourcePropType,
  ImageStyle,
  Platform,
  StyleProp,
  View,
} from 'react-native';
import {getCoachName} from '../../utils/booking';
import {getImageUrl} from '../../utils/files';
import {getLanguageAsset} from '../../utils/i18next/helpers';
import AppText from '../AppText';
import {OneToOneCoachingBookingCardProps} from './props';
import styles, {IMAGE_RATIO} from './styles';

const OneToOneCoachingBookingCardContent: React.FC<
  OneToOneCoachingBookingCardProps
> = ({
  Coaches: coach,
  OneToOneSessions_Tags: tags,
  OneToOneSessions_languages: languages,
  width,
  isSmallWindow,
}) => {
  const name = getCoachName(coach?.Firstname, coach?.Lastname);

  const specialization = coach?.Coaches_translations?.[0]?.MainQualification;

  const mobileImageWidth = (2 * width) / 5;
  const imageWidth =
    Platform.OS !== 'web' || isSmallWindow ? mobileImageWidth : width / 1.5;
  const imageHeight = width / IMAGE_RATIO;

  const imageStyle = useMemo<StyleProp<ImageStyle>>(
    () => [styles.image, {width: imageWidth, minHeight: imageHeight}],
    [imageHeight, imageWidth],
  );

  const image = useMemo<ImageSourcePropType>(() => {
    const imageUrl = getImageUrl(coach?.directus_files, {
      width: imageWidth,
      height: imageHeight,
    });

    return imageUrl
      ? {uri: imageUrl}
      : require('../../assets/images/placeholder.png');
  }, [coach?.directus_files, imageHeight, imageWidth]);

  const CoachLanguages = useMemo(() => {
    const assets = languages?.map(({languages_code}) =>
      languages_code ? getLanguageAsset(languages_code) : null,
    );
    if (!assets) {
      return null;
    }

    return assets?.map((asset: ImageSourcePropType | null, index: number) =>
      asset ? (
        <Image
          source={asset}
          style={styles.languageImage}
          key={index.toString()}
        />
      ) : null,
    );
  }, [languages]);

  const TagsLabels = useMemo(() => {
    if (!tags) {
      return null;
    }

    const allLabels = tags.map(tag => tag.Tags?.Tags_translations?.[0]?.Label);
    const labels = R.reject(R.isNil, allLabels);

    return labels.map((label, index, list) => (
      <View style={styles.tagWrapper} key={label}>
        <View style={styles.tag}>
          <AppText style={styles.tagLabel}>{label}</AppText>
        </View>
        {index !== list.length - 1 && <View style={styles.tagSpace} />}
      </View>
    ));
  }, [tags]);

  return (
    <View style={styles.container}>
      <Image source={image} style={imageStyle} />
      <View style={styles.content}>
        <View style={styles.nameContainer}>
          {name ? <AppText style={styles.name}>{name}</AppText> : null}
          {CoachLanguages}
        </View>
        {specialization ? (
          <AppText style={styles.specialization}>{specialization}</AppText>
        ) : null}
        <View style={styles.tagsContainer}>{TagsLabels}</View>
      </View>
    </View>
  );
};

export default React.memo(OneToOneCoachingBookingCardContent);
