import {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  MutationUpdaterFunction,
} from '@apollo/client';
import * as R from 'ramda';
import {
  BaseCourseFragmentDoc,
  BaseCourseResponseFragmentDoc,
  BaseProgressReportFragment,
  BaseProgressReportFragmentDoc,
  BaseSessionFragmentDoc,
  BaseSessionResponseFragmentDoc,
  CreateProgressReportMutation,
  Exact,
  ProgressReportsCreateInput,
} from '../generated/graphql';

type FragmentProperties = {
  __typename: string;
  fieldName: keyof BaseProgressReportFragment;
  fragment: DocumentNode;
  fragmentName: string;
};

enum ModelsNames {
  Sessions = 'Sessions',
  SessionsExtended = 'SessionsExtended',
  CoursesExtended = 'CoursesExtended',
  CoursesResponseExtended = 'CoursesResponseExtended',
}

const modelsFragments: Record<ModelsNames, FragmentProperties> = {
  [ModelsNames.Sessions]: {
    __typename: 'Sessions',
    fieldName: 'Session',
    fragment: BaseSessionResponseFragmentDoc,
    fragmentName: 'BaseSessionResponse',
  },
  [ModelsNames.SessionsExtended]: {
    __typename: 'SessionsExtended',
    fieldName: 'Session',
    fragment: BaseSessionFragmentDoc,
    fragmentName: 'BaseSession',
  },
  [ModelsNames.CoursesExtended]: {
    __typename: 'CoursesExtended',
    fieldName: 'Course',
    fragment: BaseCourseFragmentDoc,
    fragmentName: 'BaseCourse',
  },
  [ModelsNames.CoursesResponseExtended]: {
    __typename: 'CoursesResponseExtended',
    fieldName: 'Course',
    fragment: BaseCourseResponseFragmentDoc,
    fragmentName: 'BaseCourseResponse',
  },
};

export const updateCourseItemsProgress: MutationUpdaterFunction<
  CreateProgressReportMutation,
  Exact<{
    data: ProgressReportsCreateInput;
  }>,
  DefaultContext,
  ApolloCache<any>
> = (cache, {data}) => {
  const progressReport = data?.CreateOneProgressReports;
  if (!progressReport) {
    return;
  }

  cache.writeFragment({
    fragment: BaseProgressReportFragmentDoc,
    data: progressReport,
    fragmentName: 'BaseProgressReport',
  });

  R.keys(modelsFragments).forEach(modelName => {
    const {fragment, fragmentName, __typename, fieldName} =
      modelsFragments[modelName];

    const id = progressReport[fieldName];

    const item = id
      ? cache.readFragment({
          id: `${modelName}:${id}`,
          fragment,
          fragmentName,
        })
      : null;

    if (item) {
      cache.modify({
        id: cache.identify({__typename, id}),
        fields: {
          ProgressReports(existingRefs, {toReference}) {
            const ref = toReference(progressReport);
            const existing = Array.isArray(existingRefs) ? existingRefs : [];

            return [...existing, ref];
          },
        },
      });
    }
  });
};
