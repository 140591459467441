import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ImageSourcePropType, View} from 'react-native';
import {BaseBookingFragment, Maybe} from '../../generated/graphql';
import GroupIcon from '../../icons/GroupIcon';
import {BookingItemType, EventtoolEventType} from '../../types/booking';
import {CompanyItemType} from '../../types/companyItem';
import {
  getAppointmentsLabel,
  getEventTime,
  getFormattedEventDate,
} from '../../utils/booking';
import AppText from '../AppText';
import CompanyItemLabel from '../CompanyItemLabel';
import styles from './styles';

const useBookingCard = ({
  imageRatio,
  width,
  imageUrl,
  StartTime,
  StartDate,
  EndTime,
  model,
  AppointmentsNumber,
  BookingType,
  labelsBackgroundColor,
  isSmallWindow = true,
  ...rest
}: BaseBookingFragment & {
  labelsBackgroundColor?: string;
  imageUrl?: Maybe<string>;
  width: number;
  imageRatio: number;
  isSmallWindow?: boolean;
}) => {
  const {t, i18n} = useTranslation();

  const imageHeight = width / imageRatio;

  const image = useMemo<ImageSourcePropType>(
    () =>
      imageUrl
        ? {uri: imageUrl}
        : require('../../assets/images/placeholder.png'),
    [imageUrl],
  );

  const time = getEventTime(StartTime, EndTime);

  const {dayOfWeek, formattedDate: formattedStartDate} = StartDate
    ? getFormattedEventDate(StartDate, i18n.language)
    : {formattedDate: '', dayOfWeek: ''};

  const localizationKey =
    model && model === EventtoolEventType.INDEPENDENT_SERIAL_APPOINTMENT
      ? 'booking.serial_event_start'
      : AppointmentsNumber && AppointmentsNumber > 1
      ? 'booking.coaching_start'
      : 'booking.event_start';

  const subtitle = StartDate
    ? t(localizationKey, {dayOfWeek, startDate: formattedStartDate})
    : null;

  const isGroupCoaching = BookingType === BookingItemType.GROUP_COACHING;

  const TimeElement = useMemo(
    () =>
      time ? (
        <View
          style={[
            styles.timeContainer,
            labelsBackgroundColor
              ? {backgroundColor: labelsBackgroundColor}
              : {},
          ]}>
          <AppText style={styles.timingViewAppText}>
            {t('booking.event_time', {time})}
          </AppText>
        </View>
      ) : null,
    [labelsBackgroundColor, t, time],
  );

  const GroupCoachingIconElement = useMemo(
    () =>
      isGroupCoaching && (
        <View style={styles.groupIconView}>
          <GroupIcon />
        </View>
      ),
    [isGroupCoaching],
  );

  const AppointmentNumberElement = useMemo(
    () =>
      AppointmentsNumber && AppointmentsNumber > 0 ? (
        <View
          style={[
            styles.appointmentsLabelContainer,
            labelsBackgroundColor
              ? {backgroundColor: labelsBackgroundColor}
              : {},
          ]}>
          <AppText style={styles.appointmentsLabel}>
            {getAppointmentsLabel(t)(AppointmentsNumber)}
          </AppText>
        </View>
      ) : null,
    [AppointmentsNumber, labelsBackgroundColor, t],
  );

  const CompanyItemElement = useMemo(
    () =>
      BookingType && BookingType === BookingItemType.EVENT_EVENTTOOL ? (
        <CompanyItemLabel
          itemType={CompanyItemType.EVENT}
          style={isSmallWindow ? styles.companyItem : undefined}
        />
      ) : null,
    [BookingType, isSmallWindow],
  );

  return useMemo(
    () => ({
      imageHeight,
      image,
      subtitle,
      GroupCoachingIconElement,
      TimeElement,
      AppointmentNumberElement,
      CompanyItemElement,
      ...rest,
    }),
    [
      AppointmentNumberElement,
      CompanyItemElement,
      GroupCoachingIconElement,
      TimeElement,
      image,
      imageHeight,
      rest,
      subtitle,
    ],
  );
};

export default useBookingCard;
