import React, {useCallback, useMemo} from 'react';
import {
  GestureResponderEvent,
  TouchableHighlight,
  TouchableHighlightProps,
  View,
} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import UpdateIcon from '../../icons/UpdateIcon';
import colors from '../../theme/colors';
import AppText from '../AppText';
import styles from './styles';

export enum SkipDirection {
  FORWARD = 'FORWARD',
  BACKWARD = 'BACKWARD',
}

type Props = TouchableHighlightProps & {
  direction: SkipDirection;
  skipValue?: number;
};

const AnimatedTouchableHighlight =
  Animated.createAnimatedComponent(TouchableHighlight);

const AVSkipButton: React.FC<Props> = ({
  style,
  direction,
  skipValue,
  onPress,
  onPressIn,
  onPressOut,
  ...props
}) => {
  const scale = useSharedValue(1);

  const valueStyle = useMemo(() => {
    const marginStyle = {
      [SkipDirection.BACKWARD]: {marginRight: 1},
      [SkipDirection.FORWARD]: {marginLeft: 1},
    }[direction];
    return [styles.value, marginStyle];
  }, [direction]);

  const iconStyle = useMemo(() => {
    const scaleX = {
      [SkipDirection.BACKWARD]: -1,
      [SkipDirection.FORWARD]: 1,
    }[direction];
    return [styles.icon, {transform: [{scaleX}]}];
  }, [direction]);

  const animatedStyle = useAnimatedStyle(
    () => ({
      transform: [{scale: scale.value}],
    }),
    [scale.value],
  );

  const animatedViewStyle = useMemo(
    () => [styles.container, style, animatedStyle],
    [animatedStyle, style],
  );

  const handlePressIn = useCallback<(event: GestureResponderEvent) => void>(
    event => {
      onPressIn?.(event);
      scale.value = withTiming(0.8, {duration: 100});
    },
    [onPressIn, scale],
  );

  const handlePressOut = useCallback<(event: GestureResponderEvent) => void>(
    event => {
      onPressOut?.(event);
      scale.value = withTiming(1, {duration: 100});
    },
    [onPressOut, scale],
  );

  return (
    <AnimatedTouchableHighlight
      style={animatedViewStyle}
      underlayColor={colors.black95Alpha60}
      onPress={onPress}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      {...props}>
      <View style={styles.content}>
        <UpdateIcon width={22} height={22} style={iconStyle} />
        {skipValue ? <AppText style={valueStyle}>{skipValue}</AppText> : null}
      </View>
    </AnimatedTouchableHighlight>
  );
};

export default React.memo(AVSkipButton);
