import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {SingleValue} from 'react-select';
import Select from '../../../components/Select/Select';
import {AppPickerProps} from './props';
import styles from './styles.web.module.scss';

type SelectOptionType<T extends string> = {
  value: T;
  label: string;
};

type ItemsParam = SelectOptionType<string>[];

const SelectOptions: React.FC<AppPickerProps> = ({
  placeholder,
  emptyPlaceholder = false,
  value,
  onValueChange,
  items,
  error,
  label,
  ...props
}) => {
  const {t} = useTranslation();

  const onChange = useCallback<
    (newValue: SingleValue<SelectOptionType<string>>) => void
  >(
    newValue => {
      onValueChange(newValue?.value, 0);
    },
    [onValueChange],
  );

  const preparedPlaceholder = emptyPlaceholder
    ? ''
    : !placeholder
    ? t('common.select_item') ?? ''
    : placeholder.label;

  return (
    <View {...props}>
      <Select
        className={styles.select__container}
        placeholderClassName={styles.select__placeholder}
        controlClassName={styles.select__control}
        valueClassName={styles['select__single-value']}
        options={items as ItemsParam}
        placeholder={preparedPlaceholder}
        label={label}
        error={error}
        value={String(value)}
        onChange={onChange}
      />
    </View>
  );
};

export default React.memo(SelectOptions);
