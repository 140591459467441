import * as R from 'ramda';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Tags} from '../generated/graphql';
import {CustomTagGroup, SelectedTagsRecord} from '../types/common';
import {getTagsRecord} from '../utils/tags';

const useSelectedOnboardingTags = ({
  loading,
  tagGroups,
}: {
  loading: boolean;
  tagGroups: CustomTagGroup[];
}) => {
  const [selectedTagsRecord, setSelectedTagsRecord] =
    useState<SelectedTagsRecord>(getTagsRecord(tagGroups, []));

  const hasConfigured = useRef(false);

  const selectedTags = useMemo(
    () => R.pipe(R.filter(R.equals(true)), R.keys)(selectedTagsRecord),
    [selectedTagsRecord],
  );

  const selectTag = useCallback<(tagId: Tags['id']) => void>(tagId => {
    setSelectedTagsRecord(previous =>
      R.assoc(tagId, !previous[tagId], previous),
    );
  }, []);

  useEffect(() => {
    if (loading || hasConfigured.current) {
      return;
    }

    setSelectedTagsRecord(getTagsRecord(tagGroups, []));
    hasConfigured.current = true;
  }, [loading, tagGroups]);

  return useMemo(
    () => ({selectedTags, selectTag, selectedTagsRecord}),
    [selectTag, selectedTags, selectedTagsRecord],
  );
};

export default useSelectedOnboardingTags;
