import * as R from 'ramda';
import {PixelRatio} from 'react-native';
import Config from '../../utils/config';
import {DirectusFileFragment, Maybe} from '../generated/graphql';
import {IMAGES_ENDPOINT} from './constants';

type RESIZE_MODE = 'contain' | 'cover';

const API_URL = Config.API_URL;

const resizeModeUrlModifiers: Record<RESIZE_MODE, string> = {
  contain: '/fit-in',
  cover: '',
};

// Empirically determined. Can be changed with more testing
const FOCAL_DIFFERENCE_TOP_LIMIT = 1.7;
const FOCAL_DIFFERENCE_BOTTOM_LIMIT = 0.5;

const getFocalPoint = ({
  fileHeight,
  fileWidth,
  focalPointX,
  focalPointY,
}: {
  focalPointX?: Maybe<number>;
  focalPointY?: Maybe<number>;
  fileWidth?: Maybe<number>;
  fileHeight?: Maybe<number>;
}) => {
  if (!fileHeight || !fileWidth || !focalPointX || !focalPointY) {
    return;
  }

  const left = focalPointX;
  const rightDistance = fileWidth - left;
  const differenceX = rightDistance / left;

  // Empirically determined since thumbor requires 4 values, but directus gives only 2
  // Can work incorrectly without this condition
  const right =
    differenceX > FOCAL_DIFFERENCE_BOTTOM_LIMIT &&
    differenceX < FOCAL_DIFFERENCE_TOP_LIMIT
      ? rightDistance
      : left + 10;

  const top = focalPointY;
  const bottomDistance = fileHeight - top;
  const differenceY = bottomDistance / top;

  // Empirically determined since thumbor requires 4 values, but directus gives only 2
  // Can work incorrectly without this condition
  const bottom =
    differenceY > FOCAL_DIFFERENCE_BOTTOM_LIMIT &&
    differenceY < FOCAL_DIFFERENCE_TOP_LIMIT
      ? bottomDistance
      : top + 10;

  return `focal(${left}x${top}:${right}x${bottom})`;
};

const formatUrl = (
  url: string,
  {
    height,
    width,
    resizeMode,
    focalPointX,
    focalPointY,
    fileWidth,
    fileHeight,
  }: {
    width: number;
    height: number;
    resizeMode: RESIZE_MODE;
    focalPointX?: Maybe<number>;
    focalPointY?: Maybe<number>;
    fileWidth?: Maybe<number>;
    fileHeight?: Maybe<number>;
  },
) => {
  const pixelRatioWidth = PixelRatio.getPixelSizeForLayoutSize(
    Math.round(width),
  );
  const pixelRatioHeight = PixelRatio.getPixelSizeForLayoutSize(
    Math.round(height),
  );

  // TODO: remove it once the issue is fixed on BE
  const upgradedUrl = url.startsWith('https://')
    ? url
    : `${API_URL}/${IMAGES_ENDPOINT}/${url}`;

  const allFilters = [
    getFocalPoint({
      focalPointX,
      focalPointY,
      fileWidth,
      fileHeight,
    }),
  ];
  const definedFilters = R.filter(R.isNotNil, allFilters);
  const filtersOption =
    definedFilters.length > 0
      ? 'filters:' + definedFilters.join(':') + '/'
      : '';

  const modifiedUrl = upgradedUrl
    .replace('/{{width}}', resizeModeUrlModifiers[resizeMode] + '/{{width}}')
    .replace('{{height}}/', `{{height}}/${filtersOption}`)
    .replace('{{width}}', String(pixelRatioWidth))
    .replace('{{height}}', String(pixelRatioHeight));

  return modifiedUrl;
};

export const getImageUrl = (
  incomingData?: Maybe<DirectusFileFragment> | string,
  options?: {
    width?: number;
    aspectRatio?: number;
    height?: number;
    resizeMode?: RESIZE_MODE;
  },
) => {
  const url = incomingData
    ? typeof incomingData === 'string'
      ? incomingData
      : incomingData?.filename_disk
    : undefined;
  const focalPointX =
    typeof incomingData === 'string' ? null : incomingData?.focal_point_x;
  const focalPointY =
    typeof incomingData === 'string' ? null : incomingData?.focal_point_y;
  const fileWidth =
    typeof incomingData === 'string' ? null : incomingData?.width;
  const fileHeight =
    typeof incomingData === 'string' ? null : incomingData?.height;

  if (!url || !options) {
    return;
  }

  const {aspectRatio, width, height, resizeMode = 'cover'} = options;

  const commonOptions = {
    resizeMode,
    focalPointX,
    focalPointY,
    fileWidth,
    fileHeight,
  };

  if (height && width) {
    return formatUrl(url, {
      width,
      height,
      ...commonOptions,
    });
  }

  if (R.isNotNil(aspectRatio) && width) {
    const imageHeight = aspectRatio !== 0 ? width / aspectRatio : width;

    return formatUrl(url, {
      width,
      height: imageHeight,
      ...commonOptions,
    });
  }

  return;
};
