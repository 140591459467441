import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSubscriptionsContext} from '../contexts/SubscriptionsContext';
import {useStatisticsQuery} from '../mindance-libs/generated/graphql';
import {Gender} from '../mindance-libs/types/user';

export enum AGE_KEYS {
  under25 = '<25',
  age25to30 = '25-30',
  age31to40 = '31-40',
  age41to50 = '41-50',
  age51to60 = '51-60',
  age60Plus = '60+',
}

const DEFAULT_PERIOD_IN_DAYS = 30;

const getDefaultStartDate = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - DEFAULT_PERIOD_IN_DAYS);
  return currentDate.toISOString();
};

const prepareTopList = <T extends {[key: string]: any}[]>({
  data,
  sortCallback,
  mapCallback,
}: {
  data: T;
  sortCallback?: (a: T[number], b: T[number]) => number;
  mapCallback?: (data: T[number]) => any;
}) => {
  let result = data?.filter(item => !!item);

  if (!result) {
    return [];
  }

  if (sortCallback) {
    result.sort(sortCallback);
  }

  if (mapCallback) {
    return result.map(mapCallback);
  }

  return result;
};

const DEFAULT_START_DATE = getDefaultStartDate();

const useDashboardData = (options?: {startDate?: string; endDate?: string}) => {
  const {t} = useTranslation();

  const {selectedSubscriptionId} = useSubscriptionsContext();

  const {data, loading, error} = useStatisticsQuery({
    variables: {
      subscription_ids: [Number(selectedSubscriptionId)],
      startDate: options?.startDate ?? DEFAULT_START_DATE,
      endDate: options?.endDate,
    },
    skip: !selectedSubscriptionId,
  });

  const statistics = useMemo(
    () => data?.getMindManagerDashboardDataBySubscription,
    [data?.getMindManagerDashboardDataBySubscription],
  );

  const ageGroups = useMemo(() => {
    const ageData = statistics?.usersOfCompany?.ageGroups;

    if (ageData === null) {
      return null;
    }

    const allAgeGroups = {
      [AGE_KEYS.under25]: ageData?.ageUnder25,
      [AGE_KEYS.age25to30]: ageData?.age25to30,
      [AGE_KEYS.age31to40]: ageData?.age31to40,
      [AGE_KEYS.age41to50]: ageData?.age41to50,
      [AGE_KEYS.age51to60]: ageData?.age51to60,
      [AGE_KEYS.age60Plus]: ageData?.age60Plus,
    };

    const filteredAgeGroups = Object.fromEntries(
      Object.entries(allAgeGroups).filter(([_, value]) => !!value && value > 0),
    );

    return filteredAgeGroups;
  }, [statistics?.usersOfCompany?.ageGroups]);

  const getGenderTranslation = useCallback<(gender: Gender) => string>(
    gender => {
      const translation = t(`user.${gender}`) ?? gender;
      return translation.toLowerCase();
    },
    [t],
  );

  const genderGroups = useMemo(() => {
    const genderData = statistics?.usersOfCompany?.gender;

    if (genderData === null) {
      return null;
    }

    return {
      [getGenderTranslation(Gender.MALE)]: genderData?.male,
      [getGenderTranslation(Gender.FEMALE)]: genderData?.female,
      [getGenderTranslation(Gender.DIVERSE)]: genderData?.diverse,
    };
  }, [getGenderTranslation, statistics?.usersOfCompany?.gender]);

  const groupCoachings = useMemo(() => {
    const groupCoachingsData = statistics?.bookings?.groupCoachings;

    return prepareTopList({
      data: groupCoachingsData || [],
      mapCallback: item => item.Label,
    });
  }, [statistics?.bookings?.groupCoachings]);

  const events = useMemo(() => {
    const eventsData = statistics?.bookings?.events;

    return prepareTopList({
      data: eventsData || [],
      mapCallback: item => item.Label,
    });
  }, [statistics?.bookings?.events]);

  const topEvents = useMemo(() => {
    const eventsData = statistics?.companyInsights?.topEvents;

    return prepareTopList({
      data: eventsData || [],
      mapCallback: item => item.name,
      sortCallback: (a, b) => b?.value - a?.value,
    });
  }, [statistics?.companyInsights?.topEvents]);

  const topNews = useMemo(() => {
    const eventsData = statistics?.companyInsights?.topNews;

    return prepareTopList({
      data: eventsData || [],
      mapCallback: item => item.Label,
      sortCallback: (a, b) => b?.NewsLikes - a?.NewsLikes,
    });
  }, [statistics?.companyInsights?.topNews]);

  const topEventsPerMonth = useMemo(() => {
    const eventsData = statistics?.companyInsights?.topEventsPerMonth;

    return prepareTopList({
      data: eventsData || [],
      mapCallback: item => item.name,
      sortCallback: (a, b) => b?.value - a?.value,
    });
  }, [statistics?.companyInsights?.topEventsPerMonth]);

  const topNewsPerMonth = useMemo(() => {
    const eventsData = statistics?.companyInsights?.topNewsPerMonth;

    return prepareTopList({
      data: eventsData || [],
      mapCallback: item => item.Label,
      sortCallback: (a, b) => b?.NewsLikes - a?.NewsLikes,
    });
  }, [statistics?.companyInsights?.topNewsPerMonth]);

  const appStoreRating = useMemo(() => {
    const rating = statistics?.general?.storeRating?.appStore;

    if (rating === null) {
      return null;
    }

    return `${rating?.currentRating}/${rating?.maximumRating}`;
  }, [statistics?.general?.storeRating?.appStore]);

  const registrations = statistics?.general?.registrations;
  const activeUsers = statistics?.general?.activeUsers;
  const groupCoachingsCount = statistics?.bookings?.groupCoachingTotal;
  const eventsCount = statistics?.bookings?.eventsTotal;
  const sessionsPerUser = statistics?.general?.sessionsPerUser;
  const eventBookingsTotal = statistics?.companyInsights?.eventBookingsTotal;
  const newsLikesTotal = statistics?.companyInsights?.newsLikesTotal;

  return useMemo(
    () => ({
      data,
      loading,
      error,
      ageGroups,
      genderGroups,
      groupCoachings,
      events,
      registrations,
      activeUsers,
      groupCoachingsCount,
      eventsCount,
      sessionsPerUser,
      appStoreRating,
      eventBookingsTotal,
      newsLikesTotal,
      topEvents,
      topNews,
      topEventsPerMonth,
      topNewsPerMonth,
    }),
    [
      data,
      loading,
      error,
      ageGroups,
      genderGroups,
      groupCoachings,
      events,
      registrations,
      activeUsers,
      groupCoachingsCount,
      eventsCount,
      sessionsPerUser,
      appStoreRating,
      eventBookingsTotal,
      newsLikesTotal,
      topEvents,
      topNews,
      topEventsPerMonth,
      topNewsPerMonth,
    ],
  );
};

export default useDashboardData;
