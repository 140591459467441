import AsyncStorage from '@react-native-async-storage/async-storage';

const getItem = (item: string) => AsyncStorage.getItem(item);
const setItem = (item: string, value: string) =>
  AsyncStorage.setItem(item, value);
const deleteItem = (item: string) => AsyncStorage.removeItem(item);

const storage = {
  getItem,
  setItem,
  deleteItem,
};

export default storage;
