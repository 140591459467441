enum NavigationKeys {
  HomeScreen = 'HomeScreen',
  LibraryScreen = 'LibraryScreen',
  LibraryItemsScreen = 'LibraryItemsScreen',
  BookingScreen = 'BookingScreen',
  MyAreaScreen = 'MyAreaScreen',
  OnboardingScreen = 'OnboardingScreen',

  AccountDetailsScreen = 'AccountDetailsScreen',
  RedeemActivationCodeScreen = 'RedeemActivationCodeScreen',
  HelpScreen = 'HelpScreen',
  HealthRequirementsScreen = 'HealthRequirementsScreen',
  TermsAndConditionsScreen = 'TermsAndConditionsScreen',
  PrivacyPolicyScreen = 'PrivacyPolicyScreen',
}

export default NavigationKeys;
