import React from 'react';
import ErrorContent from '../../mindance-libs/components/ErrorContent';
import ScreenBase from '../../mindance-libs/components/ScreenBase';
import styles from './ErrorOverlay.module.scss';
import ErrorOverlayProps from './ErrorOverlay.props';

const ErrorOverlay: React.FC<ErrorOverlayProps> = ({title, subtitle}) => {
  return (
    <ScreenBase>
      <div className={styles.overlay}>
        <ErrorContent
          errorTitle={title || undefined}
          errorSubtitle={subtitle ?? undefined}
        />
      </div>
    </ScreenBase>
  );
};

export default React.memo(ErrorOverlay);
