import React, {useMemo} from 'react';
import {StyleProp, Text, TextProps, TextStyle} from 'react-native';
import styles from './styles';
import {MAX_FONT_SIZE_MULTIPLIER} from '../../utils/constants';

const AppText: React.FC<TextProps> = ({style, ...props}) => {
  const textStyle = useMemo<StyleProp<TextStyle>>(
    () => [styles.text, style],
    [style],
  );

  return (
    <Text
      {...props}
      style={textStyle}
      maxFontSizeMultiplier={MAX_FONT_SIZE_MULTIPLIER}
    />
  );
};

export default React.memo(AppText);
