import Color from 'color';
import * as R from 'ramda';
import React, {memo, useCallback, useMemo} from 'react';
import {
  FlatList,
  FlatListProps,
  ListRenderItem,
  Platform,
  StyleProp,
  ViewStyle,
} from 'react-native';
import useCompanyConfig from '../../hooks/useCompanyConfig';
import colors from '../../theme/colors';
import {BookingItemType} from '../../types/booking';
import {FilterButtonType} from '../../types/common';
import FilterButton from '../FilterButton';
import styles from './styles';

type Props = {
  data: FilterButtonType[];
  onItemPress: (key: string) => void;
  style?: StyleProp<ViewStyle>;
};

const keyExtractor: NonNullable<
  FlatListProps<FilterButtonType>['keyExtractor']
> = ({id: key}) => key;

const FiltersBar: React.FC<Props> = ({data, onItemPress, style}) => {
  const {highlightColor} = useCompanyConfig();
  const activeColor = highlightColor ?? colors.backgroundPrimary;

  const companyItemStyle = useMemo<StyleProp<ViewStyle>>(() => {
    const backgroundColor = Color(activeColor).alpha(0.2).string();
    const borderColor = Color(activeColor).alpha(0.65).string();

    return {backgroundColor, borderColor};
  }, [activeColor]);

  const selectedCompanyItemStyle = useMemo<StyleProp<ViewStyle>>(
    () => ({backgroundColor: activeColor, borderColor: activeColor}),
    [activeColor],
  );

  const renderItem = useCallback<
    (item: FilterButtonType) => React.ReactElement | null
  >(
    item => {
      const isCompanyItem = item.id === BookingItemType.EVENT_EVENTTOOL;
      const itemStyle = isCompanyItem
        ? item.isSelected
          ? selectedCompanyItemStyle
          : companyItemStyle
        : undefined;

      return (
        <FilterButton
          {...item}
          key={item.id}
          onPress={onItemPress}
          style={itemStyle}
        />
      );
    },
    [companyItemStyle, onItemPress, selectedCompanyItemStyle],
  );

  const Items = useMemo(() => {
    if (Platform.OS === 'web') {
      return data.map(renderItem);
    }
    return null;
  }, [data, renderItem]);

  const renderFlatListItem = useCallback<ListRenderItem<FilterButtonType>>(
    ({item}) => renderItem(item),
    [renderItem],
  );

  if (R.isEmpty(data)) {
    return null;
  }

  if (Platform.OS === 'web') {
    return <>{Items}</>;
  }

  return (
    <FlatList
      horizontal
      data={data}
      renderItem={renderFlatListItem}
      style={style}
      contentContainerStyle={styles.content}
      showsHorizontalScrollIndicator={false}
      keyExtractor={keyExtractor}
    />
  );
};

export default memo(FiltersBar);
