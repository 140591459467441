import {StyleSheet} from 'react-native';
import typography from '../../theme/typography';
import spacings from '../../theme/spacings';

const styles = StyleSheet.create({
  title: {
    ...typography.subtitleBold,
  },
  wrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  container: {
    marginTop: spacings.m,
    flexDirection: 'row',
  },
  tagSpace: {
    width: spacings.xs,
  },
});

export default styles;
