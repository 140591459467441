import * as R from 'ramda';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  BaseBookingFragment,
  Maybe,
  MediumDetailsFragment,
  useEventDetailsQuery,
  useGetCompanyEventDetailQuery,
} from '../generated/graphql';
import {
  BookingInfo,
  BookingItemType,
  EventDetailsModeType,
  EventtoolEventType,
} from '../types/booking';
import {FilterButtonType} from '../types/common';
import {formatBookingInfo, getCoachName} from '../utils/booking';
import {getImageUrl} from '../utils/files';
import {reportError} from '../utils/loggingHelpers';
import {getTimeDuration} from '../utils/time';
import useCompanyEventImage from './useCompanyEventImage';

const useEventDetails = (
  eventId: BaseBookingFragment['id'] | undefined,
  {
    coachImageRatio,
    coachImageWidth,
    companyId,
    courseId,
    coverImageRatio,
    coverImageWidth,
    coverImageHeight,
    language,
    serviceId,
  }: {
    language?: Maybe<string>;
    companyId?: Maybe<string>;
    courseId?: BaseBookingFragment['courseId'];
    serviceId?: BaseBookingFragment['serviceId'];
    coverImageWidth?: number;
    coverImageHeight?: number;
    coverImageRatio?: number;
    coachImageWidth?: number;
    coachImageRatio?: number;
  },
) => {
  const {t, i18n} = useTranslation();

  const isCompanyEvent = !!companyId || !!serviceId || !!courseId;

  const {
    data: eventData,
    loading: eventLoading,
    error: eventError,
  } = useEventDetailsQuery({
    variables: {
      where: {id: Number(eventId)},
      language: language ?? i18n.language,
    },
    onError: err => reportError('useEventDetailsQuery error', err),
    skip: !eventId || Number.isNaN(Number(eventId)) || isCompanyEvent,
  });

  const {
    data: companyEventData,
    loading: companyEventLoading,
    error: companyEventError,
  } = useGetCompanyEventDetailQuery({
    variables: {
      company_id: companyId ?? '',
      course_id: courseId,
      service_id: serviceId,
      languages_code: i18n.language,
    },
    onError: err => reportError('useGetCompanyEventDetailQuery error', err),
    skip: R.isNil(companyId) || !isCompanyEvent,
  });

  const event = useMemo<BookingInfo | undefined>(() => {
    if (companyEventData) {
      return companyEventData.getCompanyEventDetail;
    }
    if (eventData) {
      return eventData.findUniqueEvent;
    }

    return undefined;
  }, [companyEventData, eventData]);

  const getEventImage = useCompanyEventImage();

  const [selectedMode, setSelectedMode] = useState(EventDetailsModeType.EVENT);

  const detailsModes = useMemo<FilterButtonType[]>(
    () =>
      Object.values(EventDetailsModeType).map(mode => ({
        id: mode,
        isSelected: selectedMode === mode,
        label: t(`booking.${mode}`),
      })),
    [selectedMode, t],
  );

  const coaches = useMemo(
    () => event?.Events_Coaches?.flatMap(({Coaches}) => Coaches),
    [event?.Events_Coaches],
  );

  const coverImage = getEventImage(
    event?.directus_files,
    isCompanyEvent ? BookingItemType.EVENT_EVENTTOOL : null,
    {
      aspectRatio: coverImageRatio,
      width: coverImageWidth,
      height: coverImageHeight,
    },
  );

  const coachImage =
    coaches?.length === 1
      ? getImageUrl(event?.Events_Coaches?.[0]?.Coaches?.directus_files, {
          aspectRatio: coachImageRatio,
          width: coachImageWidth,
        })
      : undefined;

  const coachLabel =
    !coaches || coaches.length === 0
      ? undefined
      : coaches.length === 1 && !isCompanyEvent
      ? t('booking.with_coach', {
          firstName: coaches[0]?.Firstname ?? '',
          lastName: coaches[0]?.Lastname ?? '',
        })
      : coaches
          .map(coach => getCoachName(coach?.Firstname, coach?.Lastname))
          .filter(R.isNotNil)
          .join(', ');

  const durationTime = getTimeDuration({
    options: {
      locale: {
        hours: t('time.hours_short'),
        minutes: t('time.minutes_short'),
      },
    },
    endTime: event?.EndTime,
    startTime: event?.StartTime,
    duration: event?.Duration,
  });

  const mediums = useMemo<MediumDetailsFragment[] | undefined>(
    () =>
      event?.Events_Mediums?.reduce<MediumDetailsFragment[]>(
        (acc, {Mediums}) => (Mediums ? [...acc, Mediums] : acc),
        [],
      ),
    [event?.Events_Mediums],
  );

  const submitButtonLabel =
    isCompanyEvent &&
    event?.model === EventtoolEventType.INDEPENDENT_SERIAL_APPOINTMENT
      ? t('booking.select_appointment')
      : undefined;

  return useMemo(
    () => ({
      ...event,
      BookingInfo: formatBookingInfo(event?.BookingInfo),
      isCompanyEvent,
      loading: eventLoading || companyEventLoading,
      error: eventError || companyEventError,
      detailsModes,
      selectedMode,
      updateSelectedMode: setSelectedMode,
      coaches,
      coverImage,
      coachImage,
      coachLabel,
      durationTime,
      mediums,
      submitButtonLabel,
    }),
    [
      event,
      isCompanyEvent,
      eventLoading,
      companyEventLoading,
      eventError,
      companyEventError,
      detailsModes,
      selectedMode,
      coaches,
      coverImage,
      coachImage,
      coachLabel,
      durationTime,
      mediums,
      submitButtonLabel,
    ],
  );
};

export default useEventDetails;
