import React, {useCallback, useMemo} from 'react';
import {Trans} from 'react-i18next';
import {
  Insets,
  Platform,
  StyleProp,
  TextProps,
  TextStyle,
  TouchableOpacity,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import colors from '../../theme/colors';
import AppCheckBox from '../AppCheckBox';
import AppText from '../AppText';
import styles from './styles';
import spacings from '../../theme/spacings';

type Props = ViewProps & {
  areTermsConfirmed: boolean;
  onCheckBoxPress: () => void;
  error?: boolean;
  errorColor?: string;
  onTermsAndConditionsPress: () => void;
  onPrivacyPolicyPress: () => void;
};

const HIT_SLOP: Insets = {
  bottom: 10,
  left: 10,
  right: 10,
  top: 10,
};

const TermsAndConditionsAgreement: React.FC<Props> = ({
  style,
  areTermsConfirmed,
  onCheckBoxPress,
  error = false,
  errorColor = colors.alert,
  testID,
  onPrivacyPolicyPress,
  onTermsAndConditionsPress,
  ...props
}) => {
  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, style],
    [style],
  );

  const textStyle = useMemo<StyleProp<TextStyle>>(
    () => [styles.text, {color: error ? errorColor : colors.white}],
    [error, errorColor],
  );
  const linkBorderStyle = useMemo<StyleProp<ViewStyle>>(
    () => [
      styles.linkBorder,
      {
        backgroundColor: error ? errorColor : colors.white,
        top: Platform.OS === 'web' ? 0 : spacings.xxxs,
      },
    ],
    [error, errorColor],
  );
  const linkTextStyle = useMemo<StyleProp<TextStyle>>(
    () => [
      textStyle,
      {top: Platform.OS === 'android' ? 5 : Platform.OS === 'ios' ? 3.5 : 0},
    ],
    [textStyle],
  );

  const LinkText = useCallback<(props: TextProps) => JSX.Element>(
    ({style: _, ...textProps}) => <AppText style={textStyle} {...textProps} />,
    [textStyle],
  );

  const LinkTouchableText = useCallback<
    (props: TextProps & {onPress: () => void}) => JSX.Element
  >(
    ({onPress, ...textProps}) => (
      <TouchableOpacity onPress={onPress} hitSlop={HIT_SLOP}>
        <AppText style={linkTextStyle} {...textProps} />
        <View style={linkBorderStyle} />
      </TouchableOpacity>
    ),
    [linkBorderStyle, linkTextStyle],
  );

  const linkComponents = useMemo(
    () => ({
      terms: <LinkTouchableText onPress={onTermsAndConditionsPress} />,
      regulations: <LinkTouchableText onPress={onPrivacyPolicyPress} />,
    }),
    [LinkTouchableText, onTermsAndConditionsPress, onPrivacyPolicyPress],
  );

  return (
    <View style={containerStyle} testID={testID} {...props}>
      <AppCheckBox
        isChecked={areTermsConfirmed}
        onPress={onCheckBoxPress}
        error={error}
        errorColor={errorColor}
        testID={`${testID}.checkBox`}
      />

      <View style={styles.textWrapper}>
        <Trans
          i18nKey="auth.by_creating_account_you_confirm"
          parent={LinkText}
          components={linkComponents}
        />
      </View>
    </View>
  );
};

export default React.memo(TermsAndConditionsAgreement);
