import {StyleSheet} from 'react-native';
import spacings from '../../../theme/spacings';
import typography from '../../../theme/typography';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: spacings.xxxl,
    paddingVertical: spacings.xl,
    width: '100%',
  },
  buttonsWrapper: {
    marginBottom: spacings.xxxxxxxl,
  },
  space: {
    flex: 1,
  },
  placeholder: {
    flex: 1,
    minHeight: spacings.xl,
  },
  headline: {
    ...typography.headline,
    textAlign: 'center',
  },
  title: {
    ...typography.title1,
    textAlign: 'center',
    marginTop: spacings.xxxxs,
  },
  input: {
    marginTop: spacings.xl,
  },
});

export default styles;
