import React from 'react';
import ScreenBase from '../../mindance-libs/components/ScreenBase';
import {ActivityIndicator} from 'react-native-paper';
import colors from '../../mindance-libs/theme/colors';
import styles from './page.module.scss';

export default function Page() {
  return (
    <ScreenBase>
      <div className={styles['root-container']}>
        <ActivityIndicator color={colors.whiteAlpha60} size={80} />
      </div>
    </ScreenBase>
  );
}
