import {useLayout} from '@react-native-community/hooks';
import * as R from 'ramda';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react';
import {
  FlatList,
  FlatListProps,
  ListRenderItem,
  Platform,
  View,
  ViewProps,
} from 'react-native';
import Animated from 'react-native-reanimated';
import {
  AppNewsFragment,
  BaseBookingFragment,
  Tags,
  useFindManyBookingsV3Query,
} from '../../generated/graphql';
import useBookingQueryVariables from '../../hooks/useBookingQueryVariables';
import useListQuery from '../../hooks/useListQuery';
import useNewsItems from '../../hooks/useNewsItems';
import useSliderPosition from '../../hooks/useSliderPosition';
import {useAppContext} from '../../store/contexts/AppContext';
import {BookingItemType, OnCompanyEventPress} from '../../types/booking';
import {AppProductsLabels} from '../../types/common';
import {isBaseBookingItem} from '../../utils/booking';
import CompanyEventItem from '../CompanyEventItem';
import LoadingContent from '../LoadingContent';
import NewsBanner from '../NewsBanner';
import NewsItem from '../NewsItem';
import SliderDots from '../SliderDots';
import styles from './styles';

type Props = ViewProps & {
  width: number;
  gap?: number;
  tagsIds?: Tags['id'][];
  onNewsBannerPress?: (
    eventId: BaseBookingFragment['id'],
    language: string,
  ) => void;
  onNewsItemPress?: (id: AppNewsFragment['id']) => void;
  onCompanyEventItemPress?: OnCompanyEventPress;
};

export type CompanyItemsCarouselRef = {
  refresh: () => Promise<void>;
};

type Item = AppNewsFragment | BaseBookingFragment;

const keyExtractor = (item: Item) => item.id;

const AnimatedFlatList =
  Platform.OS === 'web'
    ? FlatList
    : Animated.createAnimatedComponent<FlatListProps<Item>>(FlatList);

const EVENT_ITEMS_TAKE_NUMBER = 10;

const CompanyItemsCarousel = forwardRef<CompanyItemsCarouselRef, Props>(
  (
    {
      width,
      gap = 0,
      style,
      testID,
      tagsIds,
      onNewsBannerPress,
      onNewsItemPress,
      onCompanyEventItemPress,
      ...props
    },
    ref,
  ) => {
    const {screensSetupCategoriesAvailability} = useAppContext();

    const {
      pinnedItems: pinnedNews,
      loading: newsLoading,
      refetchWithParams: refetchWithParamsNews,
    } = useNewsItems();

    const getBookingQueryVariables = useBookingQueryVariables({
      selectedCategory: BookingItemType.EVENT_EVENTTOOL,
    });

    const {
      data: eventData,
      loading: eventsLoading,
      refetchWithParams: refetchWithParamsEvents,
    } = useListQuery(
      useFindManyBookingsV3Query,
      getBookingQueryVariables,
      'findManyBookingsV3',
      {
        take: EVENT_ITEMS_TAKE_NUMBER,
        shouldSkip:
          !screensSetupCategoriesAvailability[
            AppProductsLabels.EVENT_EVENTTOOL
          ],
      },
    );

    const {flatListRef, handleScroll, animatedPosition} =
      useSliderPosition<Item>(width);

    const {height: flatListHeight, onLayout: onFlatListLayout} = useLayout();

    const items = useMemo<Item[]>(() => {
      const newsItems = screensSetupCategoriesAvailability[
        AppProductsLabels.NEWS
      ]
        ? pinnedNews
        : [];
      const companyEventItems = screensSetupCategoriesAvailability[
        AppProductsLabels.EVENT_EVENTTOOL
      ]
        ? eventData.slice(0, EVENT_ITEMS_TAKE_NUMBER)
        : [];

      const allItems: Item[] = [...newsItems, ...companyEventItems];

      const sortedByDate = R.sort(
        R.descend(({date_created}) => date_created ?? new Date().toISOString()),
        allItems,
      );

      return sortedByDate;
    }, [eventData, pinnedNews, screensSetupCategoriesAvailability]);

    const containerStyle = useMemo(
      () => [
        styles.container,
        {marginLeft: Platform.select({default: undefined, web: gap})},
        style,
      ],
      [gap, style],
    );

    const newsBannerStyle = useMemo(
      () => [{marginHorizontal: gap}, style],
      [gap, style],
    );

    const contentContainerStyle = useMemo(
      () => ({
        gap: gap * 2,
        paddingHorizontal: gap,
      }),
      [gap],
    );

    const renderItem = useCallback<ListRenderItem<Item>>(
      ({item}) => {
        if (isBaseBookingItem(item)) {
          return (
            <CompanyEventItem
              {...item}
              onPress={onCompanyEventItemPress}
              style={{
                width,
                minHeight: Platform.select({
                  default: undefined,
                  web: flatListHeight,
                }),
              }}
            />
          );
        }

        return (
          <NewsItem
            {...item}
            style={{
              width,
              minHeight: Platform.select({
                default: undefined,
                web: flatListHeight,
              }),
            }}
            onPress={onNewsItemPress}
            disableShowMore
          />
        );
      },
      [flatListHeight, onCompanyEventItemPress, onNewsItemPress, width],
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          async refresh() {
            await refetchWithParamsNews({force_refresh: true});
            await refetchWithParamsEvents({force_refresh: true});
          },
        };
      },
      [refetchWithParamsEvents, refetchWithParamsNews],
    );

    if (newsLoading || eventsLoading) {
      return <LoadingContent />;
    }

    if (!items || items.length === 0) {
      return (
        <NewsBanner
          tagsIds={tagsIds}
          style={newsBannerStyle}
          onPress={onNewsBannerPress}
        />
      );
    }

    return (
      <View style={containerStyle} testID={testID} {...props}>
        <AnimatedFlatList
          data={items}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          onEndReachedThreshold={2}
          pagingEnabled
          contentContainerStyle={contentContainerStyle}
          style={styles.list}
          horizontal
          showsHorizontalScrollIndicator={false}
          testID={`${testID}.list`}
          onScroll={handleScroll}
          ref={flatListRef}
          onLayout={onFlatListLayout}
        />
        {items.length > 1 ? (
          <SliderDots
            animatedPosition={animatedPosition}
            count={items.length}
            style={styles.dots}
          />
        ) : null}
      </View>
    );
  },
);

export default React.memo(CompanyItemsCarousel);
