import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  StyleProp,
  TextInputProps,
  TextStyle,
  View,
  ViewProps,
} from 'react-native';
import {Maybe} from '../../generated/graphql';
import AppInput from '../AppInput';
import {htmlBookingInfoStyle} from '../EventDetailsContent/styles';
import HTMLRenderer from '../HTMLRenderer';
import styles from './styles';

type Props = ViewProps & {
  bookingInfo: Maybe<string> | undefined;
  value: string;
  onChangeValue: (value: string) => void;
  onBlur?: TextInputProps['onBlur'];
  error?: boolean;
  inputStyle?: StyleProp<TextStyle>;
};

const BookingNoteField: React.FC<Props> = ({
  value,
  onChangeValue,
  onBlur,
  error,
  testID,
  bookingInfo,
  inputStyle,
  ...props
}) => {
  const {t} = useTranslation();

  const mergedInputStyle = useMemo(
    () => [styles.input, inputStyle],
    [inputStyle],
  );

  return (
    <View testID={testID} {...props}>
      {bookingInfo ? (
        <HTMLRenderer content={bookingInfo} tagsStyles={htmlBookingInfoStyle} />
      ) : null}
      <AppInput
        label={t('booking.optional_note')}
        testID={`${testID}.noteInput`}
        style={mergedInputStyle}
        value={value}
        onChangeText={onChangeValue}
        onBlur={onBlur}
        autoCapitalize="sentences"
        autoCorrect={false}
        error={error}
      />
    </View>
  );
};

export default React.memo(BookingNoteField);
