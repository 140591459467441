import * as R from 'ramda';
import {
  AppInfoOverlay,
  InfoOverlaysLocations,
} from '../mindance-libs/types/infoOverlays';
import {RouteKeys} from '../navigation/RouteKeys';

export const infoOverlayRouteMapper: Record<InfoOverlaysLocations, RouteKeys> =
  {
    [InfoOverlaysLocations.General]: RouteKeys.Root,
    [InfoOverlaysLocations.Welcome]: RouteKeys.Welcome,
    [InfoOverlaysLocations.Registration]: RouteKeys.Registration,
    [InfoOverlaysLocations.Login]: RouteKeys.Login,
    [InfoOverlaysLocations.Onboarding]: RouteKeys.Onboarding,

    [InfoOverlaysLocations.Home]: RouteKeys.Start,
    [InfoOverlaysLocations.Library]: RouteKeys.Library,
    [InfoOverlaysLocations.Booking]: RouteKeys.Booking,
    [InfoOverlaysLocations.News]: RouteKeys.News,
    [InfoOverlaysLocations.MyArea]: RouteKeys.MyArea,

    [InfoOverlaysLocations.Settings]: RouteKeys.Settings,

    [InfoOverlaysLocations.MindManagerHome]: RouteKeys.Home,
    [InfoOverlaysLocations.MindManagerDashboard]: RouteKeys.Dashboard,
    [InfoOverlaysLocations.MindManagerNewstool]: RouteKeys.Newstool,
    [InfoOverlaysLocations.MindManagerEventtool]: RouteKeys.Eventtool,
    [InfoOverlaysLocations.MindManagerGBU]: RouteKeys.GBUPsyche,
  };

export const handleInfoOverlayScreenFocus = ({
  infoOverlays,
  onInfoOverlay,
  route,
}: {
  route: string;
  infoOverlays: AppInfoOverlay[];
  onInfoOverlay: (infoOverlay: AppInfoOverlay) => void;
}) => {
  R.toPairs(infoOverlayRouteMapper).forEach(
    ([infoOverlayLocation, currentRoute]) => {
      if (route.includes(currentRoute)) {
        const screenInfoOverlays = infoOverlays.filter(
          ({ShowOn}) => ShowOn === infoOverlayLocation,
        );

        screenInfoOverlays.forEach(onInfoOverlay);
      }
    },
  );
};
