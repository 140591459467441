import {Link} from 'react-router-dom';
import React from 'react';
import styles from './Button.module.scss';
import ButtonProps, {ButtonStyle} from './Button.props';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {ActivityIndicator} from 'react-native-paper';
import colors from '../../mindance-libs/theme/colors';

const Button: React.FC<ButtonProps> = props => {
  const buttonStyle = props.buttonStyle || ButtonStyle.INLINE;
  const isPrimary = buttonStyle === ButtonStyle.PRIMARY;

  const showArrow =
    typeof props.showArrow !== 'undefined' ? props.showArrow : isPrimary;

  const rightIconClassName = classNames(
    styles.button__icon,
    props.rightIconClassName,
  );

  const leftIconClassName = classNames(
    styles['button__left-icon'],
    props.leftIconClassName,
  );

  const RightIcon = props.rightIcon ? (
    <div className={rightIconClassName}>{props.rightIcon}</div>
  ) : showArrow ? (
    <FontAwesomeIcon icon={faArrowRight} className={rightIconClassName} />
  ) : null;

  const LeftIcon = props.leftIcon ? (
    <div className={leftIconClassName}>{props.leftIcon}</div>
  ) : props.showLeftArrow ? (
    <FontAwesomeIcon icon={faArrowLeft} className={leftIconClassName} />
  ) : null;

  if (props.anchor) {
    return (
      <Link
        {...props.anchor}
        to={props.anchor.href ?? ''}
        onClick={props.onClick}
        className={classNames(
          props.className,
          props.anchor.className,
          styles.button,
          styles[`button--${buttonStyle}`],
          {
            [styles['button--disabled']]: props.disabled,
          },
        )}
        data-testid="Button">
        {props.loading ? (
          <>
            <ActivityIndicator size="small" color={colors.black100} />
          </>
        ) : (
          <>
            {LeftIcon}
            {props.children || props.anchor.title}
            {RightIcon}
          </>
        )}
      </Link>
    );
  }

  if (props.button) {
    return (
      <button
        {...props.button}
        className={classNames(
          props.className,
          props.button.className,
          styles.button,
          styles[`button--${buttonStyle}`],
          {
            [styles['button--disabled']]: props.disabled,
          },
        )}
        type={
          (props.button.type as 'button' | 'submit' | 'reset' | undefined) ||
          'button'
        }
        data-testid="Button"
        disabled={props.disabled}>
        {props.loading ? (
          <ActivityIndicator size="small" color={colors.black100} />
        ) : (
          <>
            {LeftIcon}
            {props.children || props.button.title}
            {RightIcon}
          </>
        )}
      </button>
    );
  }

  return null;
};

export default Button;
