import * as R from 'ramda';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {SubscriptionId} from '../contexts/SubscriptionsContext';
import {BaseMindManagerSubscriptionsFragment} from '../mindance-libs/generated/graphql';
import {SelectedSubscriptionsRecord} from '../types/Subscription';

const useSelectSubscriptions = ({
  initiallySelectedSubscriptionsIds,
  subscriptions,
  onSelectSubscriptions,
}: {
  subscriptions?: BaseMindManagerSubscriptionsFragment[];
  initiallySelectedSubscriptionsIds?: SubscriptionId[];
  onSelectSubscriptions: (ids: SubscriptionId[]) => void;
}) => {
  const initialSelectedSubscriptionsRecord =
    useMemo<SelectedSubscriptionsRecord>(() => {
      if (!subscriptions) {
        return {};
      }

      return subscriptions.reduce<SelectedSubscriptionsRecord>((acc, {id}) => {
        const isSelected = !!initiallySelectedSubscriptionsIds?.find(
          subscriptionId => subscriptionId === id,
        );

        return {...acc, [id]: isSelected};
      }, {});
    }, [initiallySelectedSubscriptionsIds, subscriptions]);

  const [selectedSubscriptionsRecord, setSelectedSubscriptionsRecord] =
    useState<SelectedSubscriptionsRecord>(initialSelectedSubscriptionsRecord);

  const selectSubscription = useCallback<
    (subscriptionId: SubscriptionId) => void
  >(subscriptionId => {
    setSelectedSubscriptionsRecord(previous =>
      R.assoc(subscriptionId, !previous[subscriptionId], previous),
    );
  }, []);

  const selectAll = useCallback(() => {
    setSelectedSubscriptionsRecord(previous =>
      Object.keys(previous).reduce<SelectedSubscriptionsRecord>(
        (acc, key) => ({...acc, [key]: true}),
        {},
      ),
    );
  }, []);

  const deselectAll = useCallback(() => {
    setSelectedSubscriptionsRecord(previous =>
      Object.keys(previous).reduce<SelectedSubscriptionsRecord>(
        (acc, key) => ({...acc, [key]: false}),
        {},
      ),
    );
  }, []);

  const resetSelectedSubscriptions = useCallback(() => {
    setSelectedSubscriptionsRecord(initialSelectedSubscriptionsRecord);
  }, [initialSelectedSubscriptionsRecord]);

  const confirmSelection = useCallback(() => {
    const selectedSubscriptions = R.filter(
      value => value === true,
      selectedSubscriptionsRecord,
    );
    const selectedIds = Object.keys(selectedSubscriptions);

    onSelectSubscriptions(selectedIds);
  }, [onSelectSubscriptions, selectedSubscriptionsRecord]);

  useEffect(() => {
    resetSelectedSubscriptions();
  }, [initialSelectedSubscriptionsRecord, resetSelectedSubscriptions]);

  return useMemo(
    () => ({
      selectedSubscriptionsRecord,
      selectSubscription,
      selectAll,
      deselectAll,
      resetSelectedSubscriptions,
      confirmSelection,
    }),
    [
      confirmSelection,
      deselectAll,
      resetSelectedSubscriptions,
      selectAll,
      selectSubscription,
      selectedSubscriptionsRecord,
    ],
  );
};

export default useSelectSubscriptions;
