import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../components/Button/Button';
import {ButtonStyle} from '../../../components/Button/Button.props';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay';
import useDangerouslySetInnerHtml from '../../../hooks/useDangerouslySetInnerHtml';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import {useHealthRequirementsQuery} from '../../../mindance-libs/generated/graphql';
import useTypedNavigate from '../../../navigation/typed/useTypedNavigate';
import styles from '../page.module.scss';

export default function Page() {
  const {t, i18n} = useTranslation();

  const {data, loading} = useHealthRequirementsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {language: i18n.language},
  });

  const navigate = useTypedNavigate();

  const backButton = useMemo(
    () => ({title: t('common.return') || '', onClick: () => navigate(-1)}),
    [navigate, t],
  );

  const title =
    data?.healthRequirements.Policies_translations?.[0].Title ??
    t('screenTitle.HealthRequirementsScreen');

  const content =
    data?.healthRequirements.Policies_translations?.[0].Content ?? '';

  const dangerouslySetInnerHTML = useDangerouslySetInnerHtml(content);

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <ScreenBase>
      <div className={styles.policies__container}>
        <Button
          showLeftArrow
          button={backButton}
          className={styles.policies__button}
          buttonStyle={ButtonStyle.INLINE_WHITE}
        />
        <h1>{title}</h1>

        <div className={styles.policies__wrapper}>
          <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
        </div>
      </div>
    </ScreenBase>
  );
}
