import {useCallback, useEffect, useRef} from 'react';
import {OnLogin} from '../types/auth';
import authCredentialsStore from '../utils/auth/authCredentialsStore';
import {reportError} from '../utils/loggingHelpers';

const TIMER_INTERVAL = 5000;

const useVerifiedLogin = ({
  isLoggedIn,
  isLoading,
  login,
}: {
  isLoggedIn: boolean;
  isLoading: boolean;
  login: OnLogin;
}) => {
  const timer = useRef<NodeJS.Timeout | null>(null);

  const handleLogin = useCallback(async () => {
    try {
      const credentials = await authCredentialsStore.getCredentials();

      if (credentials.email && credentials.password) {
        await login(credentials.email, credentials.password);
      }
    } catch (error) {
      reportError('useVerifiedLogin handleLogin', error);
    }
  }, [login]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isLoggedIn) {
      timer.current && clearInterval(timer.current);
      return;
    }

    handleLogin();
    timer.current = setInterval(handleLogin, TIMER_INTERVAL);

    return () => {
      timer.current && clearInterval(timer.current);
    };
  }, [isLoggedIn, handleLogin, isLoading]);
};

export default useVerifiedLogin;
