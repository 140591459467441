import Lottie from 'lottie-react-native';
import React, {useMemo} from 'react';
import {TextUnitAlignment} from '../../types/courses';
import AppText from '../AppText';
import HTMLRenderer from '../HTMLRenderer';
import {TextUnitsProps} from './props';
import RNStyles, {htmlCustomStyle} from './styles';
import styles from './styles.web.module.scss';

const TextUnitsSlide: React.FC<TextUnitsProps> = ({
  Label,
  Description,
  Alignment,
}) => {
  const TextContent = useMemo(
    () => (
      <>
        {Label ? <AppText style={RNStyles.label}>{Label}</AppText> : null}
        {Description ? (
          <HTMLRenderer content={Description} tagsStyles={htmlCustomStyle} />
        ) : null}
      </>
    ),
    [Description, Label],
  );

  const Content = useMemo(() => {
    if (Alignment === TextUnitAlignment.BOTTOM) {
      return (
        <div className={styles['text-units-slide__bottom']}>
          <div className={styles['text-units-slide__bottom__top-part']}>
            <Lottie
              source={require('../../assets/animations/bubbles_animation.json')}
              autoPlay
              loop={true}
              style={RNStyles.animationWeb}
            />
          </div>
          <div className={styles['text-units-slide__bottom__bottom-part']}>
            {TextContent}
          </div>
        </div>
      );
    }

    if (Alignment === TextUnitAlignment.CENTER) {
      return (
        <div className={styles['text-units-slide__center']}>{TextContent}</div>
      );
    }

    return <div className={styles['text-units-slide__top']}>{TextContent}</div>;
  }, [Alignment, TextContent]);

  return <div className={styles['text-units-slide']}>{Content}</div>;
};

export default React.memo(TextUnitsSlide);
