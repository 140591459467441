import format from 'date-fns/format';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, View, ViewStyle} from 'react-native';
import NextArrowIcon from '../../../icons/NextArrowIcon';
import colors from '../../../theme/colors';
import AppText from '../../AppText';
import MyAreaEmpty from '../MyAreaEmpty';
import MyAreaError from '../MyAreaError';
import MyAreaLoading from '../MyAreaLoading';
import commonStyles from '../styles';
import MyNotesWrapper from './Wrapper';
import {MyNotesProps} from './props';
import styles from './styles';

const MyNotes: React.FC<MyNotesProps> = ({
  lastDate,
  contentStyle,
  error,
  loading,
  empty,
  onPress,
  href,
  className,
  style,
}) => {
  const {t} = useTranslation();

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [commonStyles.container, contentStyle],
    [contentStyle],
  );

  const formattedDate = lastDate
    ? format(new Date(lastDate), 'dd.MM.yyyy')
    : undefined;

  if (loading) {
    return <MyAreaLoading style={contentStyle} />;
  }

  if (error) {
    return <MyAreaError style={contentStyle} />;
  }

  if (empty) {
    return <MyAreaEmpty label={t('my_area.empty_notes')} />;
  }

  return (
    <MyNotesWrapper
      onPress={onPress}
      href={href}
      style={style}
      className={className}>
      <View style={containerStyle}>
        <View style={styles.content}>
          <View style={styles.labelWrapper}>
            {lastDate ? (
              <>
                <AppText style={styles.label}>
                  {t('my_area.last_entry', {date: formattedDate})}
                </AppText>
                <AppText style={styles.label}>
                  {t('my_area.what_is_your_stress_level')}
                </AppText>
              </>
            ) : (
              <AppText>{t('my_area.here_you_will_find_your_notes')}</AppText>
            )}
          </View>
          <NextArrowIcon stroke={colors.white} />
        </View>
      </View>
    </MyNotesWrapper>
  );
};

export default React.memo(MyNotes);
