import React, {useMemo} from 'react';
import {
  Image,
  StyleProp,
  View,
  ViewStyle,
  useWindowDimensions,
} from 'react-native';
import {checkIsSmallWindow} from '../../utils/common';
import {getCountryAsset} from '../../utils/i18next/helpers';
import AppText from '../AppText';
import PhoneButton from './PhoneButton';
import {ExpertlineItemProps} from './props';
import styles from './styles';

const DEFAULT_REGION = 'DE';

const ExpertlineItem: React.FC<ExpertlineItemProps> = ({
  regionCode,
  regionName,
  displayNumber,
  phoneNumber,
  description,
}) => {
  const {width} = useWindowDimensions();
  const isSmallWindow = checkIsSmallWindow(width);

  const contentStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.content, {flexDirection: isSmallWindow ? 'column' : 'row'}],
    [isSmallWindow],
  );

  const RegionImage = useMemo(() => {
    const asset = getCountryAsset(regionCode ?? DEFAULT_REGION);

    if (!asset) {
      return null;
    }

    return <Image source={asset} style={styles.regionImage} />;
  }, [regionCode]);

  return (
    <View style={styles.container}>
      <View style={contentStyle}>
        {regionName && (
          <View style={styles.region}>
            {RegionImage}
            <AppText style={styles.regionName}>{regionName}</AppText>
          </View>
        )}
        <View style={styles.phoneWrapper}>
          <AppText style={styles.phoneNumber}>
            {displayNumber ?? phoneNumber}
          </AppText>
          <AppText style={styles.description}>{description}</AppText>
        </View>
      </View>
      <View style={styles.buttonWrapper}>
        <PhoneButton phoneNumber={phoneNumber} />
      </View>
    </View>
  );
};

export default React.memo(ExpertlineItem);
