import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  container: {
    padding: spacings.xl,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    ...typography.subtitleDemiBold,
    textAlign: 'center',
    color: colors.white,
  },
});

export default styles;
