import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

export type OnErrorMessageShow = (error: string) => void;
export type OnErrorMessageHide = () => void;

const DISPLAY_DURATION = 5000;

const useErrorMessage = () => {
  const [error, setError] = useState<string | undefined>();
  const timer = useRef<NodeJS.Timeout | null>(null);

  const clearTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, []);

  const hideMessage = useCallback<OnErrorMessageHide>(() => {
    setError(undefined);
    clearTimer();
  }, [clearTimer]);

  const showMessage = useCallback<OnErrorMessageShow>(
    message => {
      setError(message);

      if (timer.current) {
        return;
      }

      timer.current = setTimeout(() => {
        hideMessage();
        timer.current = null;
      }, DISPLAY_DURATION);
    },
    [hideMessage],
  );

  useEffect(() => {
    return clearTimer;
  }, [clearTimer]);

  return useMemo(
    () => ({
      hideMessage,
      showMessage,
      error,
    }),
    [error, hideMessage, showMessage],
  );
};

export default useErrorMessage;
