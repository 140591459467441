import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {SMALL_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  container: {
    backgroundColor: Platform.select({
      default: colors.darkBlue,
      web: colors.darkBlueAlpha60,
    }),
    borderRadius: SMALL_BORDER_RADIUS,
    paddingVertical: spacings.xl,
    paddingHorizontal: spacings.xs,
  },
  linearSeparator: {
    backgroundColor: Platform.select({
      default: colors.whiteAlpha40,
      web: colors.transparent,
    }),
    marginVertical: Platform.select({
      default: spacings.l,
      web: spacings.s,
    }),
  },
  textWrapper: {
    marginLeft: spacings.s,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    ...Platform.select({default: typography.body, web: typography.inputLight}),
    color: colors.whiteAlpha95,
  },
  boldLabel: {
    ...typography.title3Bold,
    color: colors.white,
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: spacings.xxxs,
  },
  globalIcon: {
    marginRight: spacings.xxxs,
    marginTop: spacings.xxxs,
  },
});

export default styles;
