import {useFormik} from 'formik';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import ControlBottomButtons from '../../../components/ControlBottomButtons/ControlBottomButtons';
import AppInput from '../../../mindance-libs/components/AppInput';
import ContactUs, {
  TitleVariants,
} from '../../../mindance-libs/components/ContactUs';
import ScreenBase from '../../../mindance-libs/components/ScreenBase/index';
import useErrorMessage from '../../../mindance-libs/hooks/useErrorMessage';
import useInputFormikHandlers from '../../../mindance-libs/hooks/useInputFormikHandlers';
import MindanceBigLogo from '../../../mindance-libs/icons/MindanceBigLogo';
import {useAuthContext} from '../../../mindance-libs/store/contexts/AuthContext';
import {forgetPasswordEmailFormValidationSchema} from '../../../mindance-libs/utils/formValidations';
import {RouteKeys} from '../../../navigation/RouteKeys';
import useTypedNavigate from '../../../navigation/typed/useTypedNavigate';
import {INITIAL_FORM_VALUES} from './page.data';
import styles from './page.module.scss';
import {ForgotPasswordFormProps} from './page.props';

export default function Page() {
  const {t} = useTranslation();
  const {showMessage, error} = useErrorMessage();
  const navigate = useTypedNavigate();

  const handleError = useCallback<(error: string) => void>(
    errorMessage => {
      showMessage(errorMessage);
    },
    [showMessage],
  );

  const {forgetPassword} = useAuthContext({onError: handleError});

  const submitForm = useCallback<
    (values: ForgotPasswordFormProps) => Promise<void>
  >(
    async ({email}) => {
      const res = await forgetPassword(email.trim());
      if (res.success) {
        navigate({to: RouteKeys.ForgotPasswordSuccess, params: {}});
      }
    },
    [forgetPassword, navigate],
  );

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    validationSchema: forgetPasswordEmailFormValidationSchema,
    validateOnMount: true,
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: submitForm,
  });

  const {
    handleValueBlur: handleEmailBlur,
    hasError: hasEmailError,
    setValue: setEmail,
  } = useInputFormikHandlers<ForgotPasswordFormProps>('email', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError: showMessage,
  });

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <ScreenBase error={error}>
      <div className={styles.forgot__container}>
        <div className={styles.forgot__content}>
          <div className={styles.forgot__logo}>
            <MindanceBigLogo />
          </div>

          <h1>{t('auth.forgot_password')}</h1>
          <p>{t('auth.your_email_address')}</p>

          <div className={styles['forgot__input-fields']}>
            <AppInput
              label={t('auth.email')}
              placeholder={t('auth.email_placeholder')}
              value={values.email}
              onChangeText={setEmail}
              onBlur={handleEmailBlur}
              keyboardType="email-address"
              autoCapitalize="none"
              autoCorrect={false}
              error={hasEmailError}
              testID="forgotPassword.emailInput"
            />
          </div>

          <div className={styles.forgot__bottom}>
            <ControlBottomButtons
              onSubmit={handleSubmit}
              onReturn={goBack}
              submitButtonDisabled={!isValid || isSubmitting}
              returnButtonTestID="forgotPassword.returnButton"
              submitButtonTestID="forgotPassword.submitButton"
              submitButtonLoading={isSubmitting}
            />
            <ContactUs variant={TitleVariants.FORGOT_PASSWORD_PROBLEM} />
          </div>
        </div>
      </div>
    </ScreenBase>
  );
}
