import React, {useEffect, useMemo, useState} from 'react';
import {StyleProp, View, ViewProps, ViewStyle} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import colors from '../../theme/colors';
import styles from './styles';

type Props = ViewProps & {
  indicatorColor?: string;
  withDelay?: boolean;
};

const DELAY_TIME = 1000;

const LoadingContent: React.FC<Props> = ({
  indicatorColor = colors.whiteAlpha50,
  style,
  withDelay = true,
  ...props
}) => {
  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.content, style],
    [style],
  );

  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        setAnimating(true);
      },
      withDelay ? DELAY_TIME : 0,
    );

    return () => clearTimeout(timer);
  }, [withDelay]);

  return (
    <View style={containerStyle} {...props}>
      <ActivityIndicator
        animating={animating}
        color={indicatorColor}
        size={50}
      />
    </View>
  );
};

export default React.memo(LoadingContent);
