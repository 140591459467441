import React, {useCallback, useMemo} from 'react';
import {
  Linking,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import styles from './styles';
import AppText from '../../components/AppText';
import {useTranslation} from 'react-i18next';
import {CONTACT_US_EMAIL} from '../../utils/constants';

export enum TitleVariants {
  DEFAULT = 'auth.please_contact_us',
  TECHNICAL_PROBLEM = 'auth.contact_us_technical_problem',
  FORGOT_PASSWORD_PROBLEM = 'auth.contact_us_forgot_password',
  AUTH_PROBLEM = 'auth.contact_us_login_problem',
  YOU_NEED_HELP = 'auth.do_you_need_help_desc',
  HELP_OR_FEEDBACK = 'settings.need_help',
  STILL_HAVE_PROBLEM = 'auth.contact_us_still_have_problem',
}

type Props = {
  title?: string | null;
  variant?: TitleVariants;
  style?: ViewStyle;
  textStyle?: TextStyle;
  linkTitle?: string | null;
  isTitleDisplayed?: boolean;
};

const DEFAULT_TITLE_KEY = TitleVariants.DEFAULT;

const ContactUs: React.FC<Props> = ({
  title,
  style,
  textStyle,
  variant = DEFAULT_TITLE_KEY,
  linkTitle,
  isTitleDisplayed = true,
}) => {
  const {t} = useTranslation();

  const titleText = title ? title : t(variant);

  const onContactUsPress = useCallback(
    () => Linking.openURL(`mailto:${CONTACT_US_EMAIL}`),
    [],
  );

  const containerStyle = useMemo(() => [styles.container, style], [style]);
  const titleStyle = useMemo(() => [styles.title, textStyle], [textStyle]);
  const linkStyle = useMemo(() => [titleStyle, styles.link], [titleStyle]);

  return (
    <View style={containerStyle}>
      {isTitleDisplayed ? (
        <AppText style={titleStyle}>{titleText}</AppText>
      ) : null}
      <TouchableOpacity onPress={onContactUsPress}>
        <AppText style={linkStyle}>{linkTitle ?? CONTACT_US_EMAIL}</AppText>
      </TouchableOpacity>
    </View>
  );
};

export default React.memo(ContactUs);
