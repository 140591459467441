import 'swiper/css';
import 'swiper/css/effect-coverflow';

import classNames from 'classnames';
import * as R from 'ramda';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {EffectCoverflow, Mousewheel} from 'swiper/modules';
import {Swiper, SwiperRef, SwiperSlide} from 'swiper/react';
import CourseSessionsCarouselItem from '../../mindance-libs/components/CourseSessionsCarouselItem';
import SegmentedProgressBar from '../../mindance-libs/components/SegmentedProgressBar';
import {getCurrentProgress} from '../../mindance-libs/utils/progress';
import styles from './CourseSessionsCarousel.module.scss';
import CourseSessionsCarouselProps from './CourseSessionsCarousel.props';

const SWIPER_MODULES = [EffectCoverflow, Mousewheel];
const SWIPER_CONTAINER_ID = 'swiper-container-id';

const CourseSessionsCarousel: React.FC<CourseSessionsCarouselProps> = ({
  className,
  onOpenSession,
  progressReports,
  sessions,
  finishedSessionsIds,
  firstItemIndex,
  width,
  height,
}) => {
  const {t} = useTranslation();
  const swiperRef = useRef<SwiperRef | null>(null);

  const initialSlide =
    !firstItemIndex || firstItemIndex < 0 ? 0 : firstItemIndex;

  const [realIndex, setRealIndex] = useState(initialSlide);

  const mergedStyle = classNames(styles['course-sessions-carousel'], className);

  const sessionIds = useMemo(
    () => sessions?.map(item => item.id) ?? [],
    [sessions],
  );

  const {total, current} = useMemo(
    () => getCurrentProgress({sessionIds, progressReports}),
    [progressReports, sessionIds],
  );

  const shouldShowProgress = R.isNotNil(total) && total > 0;

  const slideWidth = (width * 2) / 3;

  const SessionsSlides = useMemo(
    () =>
      (sessions ?? []).map((item, index, arr) => {
        const handlePress = () => {
          if (R.isNil(realIndex) || realIndex === index) {
            onOpenSession?.(item.id);
            return;
          }

          realIndex === arr.length - 1
            ? index === 0
              ? swiperRef.current?.swiper?.slideNext()
              : swiperRef.current?.swiper?.slidePrev()
            : realIndex === 0
            ? index === arr.length - 1
              ? swiperRef.current?.swiper?.slidePrev()
              : swiperRef.current?.swiper?.slideNext()
            : realIndex > index
            ? swiperRef.current?.swiper?.slidePrev()
            : swiperRef.current?.swiper?.slideNext();
        };

        return (
          <SwiperSlide
            key={item.id}
            style={{width: slideWidth, height}}
            onClick={handlePress}>
            <CourseSessionsCarouselItem
              {...item}
              key={item.id}
              isCompleted={finishedSessionsIds?.includes(item.id)}
              width={slideWidth}
              style={{height, width: slideWidth}}
              order={index + 1}
            />
          </SwiperSlide>
        );
      }),
    [
      finishedSessionsIds,
      height,
      onOpenSession,
      realIndex,
      sessions,
      slideWidth,
    ],
  );

  const coverflowEffectOptions = useMemo(
    () => ({
      rotate: 0,
      stretch: (3 * slideWidth - width) / 2.55 - 1,
      depth: 100,
      modifier: 1,
      shadow: false,
      slideShadows: false,
      shadowOffset: 0,
      scale: 0.8,
    }),
    [slideWidth, width],
  );

  const swiperStyle = useMemo<React.CSSProperties>(() => ({width}), [width]);

  // Disable box-shadow. Configuration doesn't work correctly.
  const swiperContainer = document.getElementById(SWIPER_CONTAINER_ID);
  if (swiperContainer) {
    const slides = swiperContainer.getElementsByClassName('swiper-slide');
    for (let i = 0; i < slides?.length; i++) {
      if (!slides[i]) {
        break;
      }
      const slide = slides[i] as HTMLElement;
      const boxShadowElement = slide.firstChild?.firstChild as
        | HTMLElement
        | null
        | undefined;
      if (!boxShadowElement) {
        break;
      }
      boxShadowElement.style.boxShadow = 'none';
    }
  }

  const updateRealIndex = useCallback<(options: {realIndex: number}) => void>(
    ({realIndex: newRealIndex}) => {
      setRealIndex(newRealIndex);
    },
    [],
  );

  return (
    <div className={mergedStyle}>
      {shouldShowProgress ? (
        <div className={styles['course-sessions-carousel__segment']}>
          <SegmentedProgressBar current={current} total={total} />
          <p className={styles['course-sessions-carousel__segment__label']}>
            {t('courses.sessions_progress', {
              completed: current,
              total: total,
            })}
          </p>
        </div>
      ) : null}

      <div
        className={styles['course-sessions-carousel__content']}
        id={SWIPER_CONTAINER_ID}>
        <Swiper
          ref={swiperRef}
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          initialSlide={initialSlide}
          loop
          slidesPerView="auto"
          coverflowEffect={coverflowEffectOptions}
          modules={SWIPER_MODULES}
          style={swiperStyle}
          mousewheel
          onRealIndexChange={updateRealIndex}>
          {SessionsSlides}
        </Swiper>
      </div>
    </div>
  );
};

export default React.memo(CourseSessionsCarousel);
