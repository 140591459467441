import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
import colors from '../theme/colors';

const PhoneIcon: React.FC<SvgProps> = ({
  fill = colors.white,
  width = 16,
  height = 16,
  ...props
}) => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      {...props}>
      <Path
        d="M5.153.77A1.246 1.246 0 003.672.043l-2.75.75C.378.944 0 1.438 0 2.001c0 7.73 6.269 14 14 14 .563 0 1.056-.378 1.206-.922l.75-2.75a1.246 1.246 0 00-.725-1.481l-3-1.25a1.246 1.246 0 00-1.447.362L9.522 11.5A10.562 10.562 0 014.5 6.48l1.54-1.26c.429-.35.576-.937.363-1.446l-1.25-3V.769z"
        fill={fill}
      />
    </Svg>
  );
};

export default React.memo(PhoneIcon);
