import {StyleSheet} from 'react-native';
import spacings from '../../theme/spacings';

const styles = StyleSheet.create({
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacings.xl,
  },
});

export default styles;
