import * as R from 'ramda';
import React, {useMemo} from 'react';
import {useWindowDimensions} from 'react-native';
import RenderHtml, {
  HTMLSource,
  MixedStyleDeclaration,
  RenderHTMLProps,
} from 'react-native-render-html';
import colors from '../../theme/colors';
import {FontNames} from '../../theme/fontNames';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';
import {MAX_FONT_SIZE_MULTIPLIER} from '../../utils/constants';

type Props = Partial<RenderHTMLProps> & {
  content: string;
};

const SYSTEM_FONTS = Object.values(FontNames);

const HTMLRenderer: React.FC<Props> = ({
  content,
  tagsStyles = {},
  contentWidth: width,
  ...props
}) => {
  const {fontScale, width: windowWidth} = useWindowDimensions();

  const contentWidth = width ?? windowWidth;

  const contentSource = useMemo<HTMLSource>(() => ({html: content}), [content]);

  const customTagsStyles = useMemo<
    Record<string, MixedStyleDeclaration>
  >(() => {
    const fontSize = typography.body.fontSize ?? 16;
    const lineHeight = typography.body.lineHeight ?? 22;

    const updatedFontScale =
      fontScale <= MAX_FONT_SIZE_MULTIPLIER
        ? fontScale
        : MAX_FONT_SIZE_MULTIPLIER;

    const accessibleFontSize =
      fontScale <= MAX_FONT_SIZE_MULTIPLIER
        ? fontSize
        : (fontSize / fontScale) * MAX_FONT_SIZE_MULTIPLIER;

    const accessibleLineHeight =
      fontScale <= MAX_FONT_SIZE_MULTIPLIER
        ? lineHeight
        : (lineHeight / fontScale) * MAX_FONT_SIZE_MULTIPLIER;

    return R.mergeDeepRight<
      Record<string, MixedStyleDeclaration>,
      Record<string, MixedStyleDeclaration>
    >(
      {
        body: {
          ...typography.body,
          fontSize: accessibleFontSize,
          lineHeight: accessibleLineHeight,
          color: colors.white,
        },
        li: {
          marginTop: -5 * updatedFontScale,
          marginBottom: spacings.xxs,
          lineHeight: accessibleLineHeight + 4,
        },
      },
      tagsStyles,
    ) as Record<string, MixedStyleDeclaration>;
  }, [fontScale, tagsStyles]);

  return (
    <RenderHtml
      contentWidth={contentWidth}
      source={contentSource}
      tagsStyles={customTagsStyles}
      systemFonts={SYSTEM_FONTS}
      {...props}
    />
  );
};

export default React.memo(HTMLRenderer);
