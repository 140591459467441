import {useLayout} from '@react-native-community/hooks';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, View, ViewStyle} from 'react-native';
import AppUsageStatisticsChart from '../../../../components/AppUsageStatisticsChart';
import useCompanyConfig from '../../../hooks/useCompanyConfig';
import {createDateRange} from '../../../utils/time';
import AppText from '../../AppText';
import MyAreaEmpty from '../MyAreaEmpty';
import MyAreaError from '../MyAreaError';
import MyAreaLoading from '../MyAreaLoading';
import commonStyles from '../styles';
import {AppUsageStatisticsProps} from './props';
import styles from './styles';

const AppUsageStatistics: React.FC<AppUsageStatisticsProps> = ({
  appUsage,
  fromDate,
  toDate,
  style,
  error,
  loading,
  empty,
}) => {
  const {t} = useTranslation();
  const {width, onLayout} = useLayout();

  const {highlightColor} = useCompanyConfig();

  const appUsagePerGivenTimespanInMinutes =
    appUsage?.appUsagePerGivenTimespanInMinutes;

  const appUsageTotalInMinutes = appUsage?.appUsageTotalInMinutes;

  const datesRange = useMemo(
    () => createDateRange(fromDate, toDate),
    [fromDate, toDate],
  );

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [commonStyles.container, style],
    [style],
  );

  if (loading) {
    return <MyAreaLoading style={style} />;
  }

  if (error) {
    return <MyAreaError style={style} />;
  }

  if (empty) {
    return <MyAreaEmpty label={t('my_area.empty_statistics')} />;
  }

  return (
    <View style={containerStyle}>
      <View style={styles.content} onLayout={onLayout}>
        {appUsagePerGivenTimespanInMinutes ? (
          <AppText style={styles.description}>
            {t('my_area.spent_minutes_last_days_1') + ' '}
            <AppText style={styles.descriptionBold}>
              {appUsagePerGivenTimespanInMinutes.toFixed(0) +
                ' ' +
                t('time.minute', {count: appUsagePerGivenTimespanInMinutes})}
            </AppText>
            {' ' + t('my_area.spent_minutes_last_days_2')}
          </AppText>
        ) : null}

        {appUsage?.appUsagePerDates ? (
          <AppUsageStatisticsChart
            containerWidth={width}
            datesRange={datesRange}
            barColor={highlightColor}
            appUsagePerDates={appUsage.appUsagePerDates}
          />
        ) : null}

        {appUsageTotalInMinutes ? (
          <AppText style={styles.totalTimeLabel}>
            <AppText style={styles.totalTimeLabelBold}>
              {t('common.total_time') + ': '}
            </AppText>
            {appUsageTotalInMinutes.toFixed(0) +
              ' ' +
              t('time.minute', {count: appUsageTotalInMinutes}) +
              ' ' +
              t('my_area.of_mindance_time')}
          </AppText>
        ) : null}
      </View>
    </View>
  );
};

export default React.memo(AppUsageStatistics);
