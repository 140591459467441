import Lottie from 'lottie-react-native';
import React, {useCallback, useMemo, useState} from 'react';
import FastImage, {
  FastImageProps,
  Priority,
  Source,
} from 'react-native-fast-image';
import Animated from 'react-native-reanimated';
import {View} from 'react-native-web';
import styles from './styles';

type Props = Omit<FastImageProps, 'style'> & {
  uri?: string;
  priority?: Priority;
  style?: React.CSSProperties;
};

const AnimatedLottie = Animated.createAnimatedComponent(Lottie);

const ImageWithLoadingState: React.FC<Props> = ({
  style,
  testID,
  uri,
  priority = FastImage.priority.normal,
  ...props
}) => {
  const [loading, setLoading] = useState(true);

  const containerStyle = useMemo<React.CSSProperties>(
    () => ({...styles.container, ...style}),
    [style],
  );

  const source = useMemo<Source>(() => ({uri, priority}), [priority, uri]);

  const loadingStyle = useMemo<React.CSSProperties>(
    () => ({
      ...styles.loading,
      opacity: loading ? 1 : 0,
      transition: 'opacity 0.3s',
    }),
    [loading],
  );

  const showLoadedImage = useCallback(() => {
    setLoading(false);
  }, []);

  return (
    <View style={containerStyle} testID={testID}>
      <FastImage
        style={styles.image}
        source={source}
        testID={`${testID}.loadedImage`}
        onLoadEnd={showLoadedImage}
        {...props}
      />
      <View style={loadingStyle}>
        <AnimatedLottie
          source={require('../../assets/animations/image_loading_animation.json')}
          autoPlay
          loop
          testID={`${testID}.loading`}
        />
      </View>
    </View>
  );
};

export default React.memo(ImageWithLoadingState);
