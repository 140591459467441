import * as R from 'ramda';
import {
  Expertlines,
  GeneralSetting,
  Maybe,
  TagsGeneralSettingFragment,
} from '../generated/graphql';

export const getMeinFSLink = (
  tagsGeneralSettingFragment?: Maybe<TagsGeneralSettingFragment>,
) => {
  return tagsGeneralSettingFragment?.MeinFSLink;
};

export const getExpertlines = (
  generalSetting?: Maybe<GeneralSetting>,
): Expertlines[] | undefined => {
  const rawExpertlines =
    generalSetting?.GeneralSetting_translations?.[0]?.GeneralSetting_translations_Expertlines?.map(
      item => item.Expertlines,
    );

  if (!rawExpertlines) {
    return;
  }

  const filteredItems = rawExpertlines.filter(
    (item): item is Expertlines => !!item,
  );

  const sortedItems = R.sort(
    R.ascend(({sort}) => sort ?? 1000),
    filteredItems,
  );

  return sortedItems;
};
