import {DefaultTheme, MD3Theme} from 'react-native-paper';
import colors from './colors';

export const globalTheme: MD3Theme = {
  ...DefaultTheme,
  dark: true,
  roundness: 16,
  colors: {
    ...DefaultTheme.colors,

    background: colors.transparent,
    primary: colors.white,
    onPrimary: colors.black100,
    surfaceDisabled: colors.gray,
    onSurfaceDisabled: colors.black90Alpha90,
    error: colors.alert,
    onSurfaceVariant: colors.white,
    backdrop: colors.black95Alpha68,
  },
};
