import React from 'react';
import styles from './StatusFilter.module.scss';
import classNames from 'classnames';
import StatusFilterProps, {StatusFilterOptions} from './StatusFilter.props';
import {useTranslation} from 'react-i18next';

const StatusFilter: React.FC<StatusFilterProps> = ({
  selected,
  changeSelected,
  className = '',
  testID,
}) => {
  const {t} = useTranslation();
  const mergedStyle = classNames(styles['status-filter'], className);

  return (
    <div className={mergedStyle} data-testid={testID}>
      {Object.values(StatusFilterOptions).map((option, index) => {
        const isSelected = option === selected;
        const onClick = () => changeSelected(option);

        return (
          <button
            key={`${option}_${index}`}
            className={classNames(styles['status-filter__item'], {
              [styles['status-filter__item--selected']]: isSelected,
            })}
            onClick={onClick}>
            {t(`mindManagerNewstool.${option}`)}
          </button>
        );
      })}
    </div>
  );
};

export default StatusFilter;
