import * as R from 'ramda';
import React, {useMemo} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {SharedValue} from 'react-native-reanimated';
import SliderDot from '../SliderDot';
import styles from './styles';

type SliderDotsProps = {
  animatedPosition: SharedValue<number> | number;
  count: number;
  style?: StyleProp<ViewStyle>;
};

const MAX_DOTS_COUNT = 7;

const SliderDots: React.FC<SliderDotsProps> = ({
  animatedPosition,
  count,
  style,
}) => {
  const mockArray = useMemo(() => R.times(R.identity, count), [count]);

  const Dots = useMemo(
    () =>
      mockArray.slice(0, MAX_DOTS_COUNT).map(index => {
        const maxDotIndex =
          MAX_DOTS_COUNT > mockArray.length ? mockArray.length : MAX_DOTS_COUNT;
        const averageDotIndex = Math.floor(maxDotIndex / 2);
        const minThreshold = Math.floor(maxDotIndex / 2) - 1;

        return (
          <SliderDot
            key={index}
            animatedPosition={animatedPosition}
            dotIndex={index}
            totalLength={mockArray.length}
            minThreshold={minThreshold}
            maxDotIndex={maxDotIndex}
            averageDotIndex={averageDotIndex}
            style={styles.dot}
          />
        );
      }),
    [animatedPosition, mockArray],
  );

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, style],
    [style],
  );

  return <View style={containerStyle}>{Dots}</View>;
};

export default React.memo(SliderDots);
