import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {SMALL_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';

export const MAX_IMAGE_WIDTH = 160;
export const IMAGE_ASPECT_RATIO = 1;
const CALENDAR_SIZE = Platform.select({
  default: 12,
  web: 14,
});

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.whiteAlpha10,
    borderRadius: SMALL_BORDER_RADIUS,
    padding: spacings.l,
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    marginLeft: spacings.xl,
  },
  eventLabel: {
    alignSelf: 'flex-start',
  },
  image: {
    aspectRatio: 1,
    maxWidth: MAX_IMAGE_WIDTH,
    borderRadius: 7,
    backgroundColor: colors.whiteAlpha30,
  },
  dateContainer: {
    marginTop: spacings.l,
    flexDirection: 'row',
    alignItems: 'center',
  },
  calendarImage: {
    width: CALENDAR_SIZE,
    height: CALENDAR_SIZE,
    tintColor: colors.white,
  },
  dateLabel: {
    marginLeft: spacings.xs,
    ...typography.footnote,
    fontSize: Platform.select({
      default: typography.footnote.fontSize ?? 12,
      web: 14,
    }),
    lineHeight: Platform.select({
      default: 14,
      web: 16,
    }),
    color: colors.whiteAlpha95,
  },
  title: {
    ...typography.bodyDemiBold,
    fontSize: Platform.select({
      default: typography.bodyDemiBold.fontSize ?? 16,
      web: 18,
    }),
    lineHeight: Platform.select({
      default: typography.bodyDemiBold.lineHeight ?? 22,
      web: 22,
    }),
    color: colors.white,
    marginTop: spacings.xs,
  },
});

export default styles;
