import React from 'react';
import {useTranslation} from 'react-i18next';
import ContactUs, {
  TitleVariants,
} from '../../../../mindance-libs/components/ContactUs';
import ScreenBase from '../../../../mindance-libs/components/ScreenBase';
import MindanceBigLogo from '../../../../mindance-libs/icons/MindanceBigLogo';
import styles from '../page.module.scss';

export default function Page() {
  const {t} = useTranslation();

  return (
    <ScreenBase>
      <div className={styles.email_confirmation__container}>
        <div className={styles.email_confirmation__content}>
          <div className={styles.email_confirmation__logo}>
            <MindanceBigLogo />
          </div>
          <div className={styles.email_confirmation__space} />
          <div className={styles.email_confirmation__title}>
            {t('auth.great_almost_there')}
          </div>
          <div className={styles.email_confirmation__description}>
            {t('auth.we_have_sent_confirmation_code')}
          </div>
          <div className={styles.email_confirmation__space} />
          <div className={styles.email_confirmation__contactUs}>
            <ContactUs variant={TitleVariants.YOU_NEED_HELP} />
          </div>
        </div>
      </div>
    </ScreenBase>
  );
}
