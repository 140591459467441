import * as R from 'ramda';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useWindowDimensions} from 'react-native';
import Button from '../../../components/Button/Button';
import {ButtonStyle} from '../../../components/Button/Button.props';
import CourseSessionsCarousel from '../../../components/CourseSessionsCarousel/CourseSessionsCarousel';
import ErrorOverlay from '../../../components/ErrorOverlay/ErrorOverlay';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay';
import useElementSize from '../../../hooks/useElementSize';
import CourseCoachDetails from '../../../mindance-libs/components/CourseCoachDetails';
import CourseDetailsContent from '../../../mindance-libs/components/CourseDetailsContent';
import FilterModesBar from '../../../mindance-libs/components/FilterModesBar';
import RoundBackButton from '../../../mindance-libs/components/RoundBackButton';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import {BaseSessionFragment} from '../../../mindance-libs/generated/graphql';
import useCourseDetails from '../../../mindance-libs/hooks/useCourseDetails';
import colors from '../../../mindance-libs/theme/colors';
import {CourseDetailsModeType} from '../../../mindance-libs/types/courses';
import {RouteKeys} from '../../../navigation/RouteKeys';
import useTypedLocation from '../../../navigation/typed/useTypedLocation';
import useTypedNavigate from '../../../navigation/typed/useTypedNavigate';
import useTypedParams from '../../../navigation/typed/useTypedParams';
import styles from './page.module.scss';
import {
  COACH_DELIMITER,
  MAX_CAROUSEL_HEIGHT,
  MAX_CAROUSEL_WIDTH,
  PADDING_HORIZONTAL,
} from './page.options';

export default function Page() {
  const {t} = useTranslation();
  const {width, height} = useWindowDimensions();
  const {
    ref: containerRef,
    onLoad: onContainerLoad,
    size: containerSize,
  } = useElementSize<HTMLDivElement>();
  const headerHeight = (containerSize.height || height) / 3;

  const navigate = useTypedNavigate();

  const {id} = useTypedParams<RouteKeys.Course>();
  const {state} = useTypedLocation<RouteKeys.Course>();
  const {
    coach,
    coachName,
    detailsModes,
    loading,
    error,
    selectedMode,
    finishedSessionIds,
    unfinishedSessionIds,
    Details,
    Label,
    sessions,
    sessionsProgressReports,
    previewImage,
    updateSelectedMode,
    reportOpenedSession,
  } = useCourseDetails(id, {
    previewImageHeight: headerHeight,
    previewImageWidth: containerSize.width || width,
    screen: state?.screen,
  });

  const firstItemIndex = sessions?.findIndex(({id: sessionId}) =>
    unfinishedSessionIds.includes(sessionId),
  );

  const headerStyle = useMemo<React.CSSProperties>(
    () => ({height: headerHeight}),
    [headerHeight],
  );

  const openSession = useCallback<(id: BaseSessionFragment['id']) => void>(
    sessionId => {
      navigate(
        {to: RouteKeys.Session, params: {id: sessionId}},
        {
          state: {
            courseId: id,
            screen: state?.screen,
            courseSessionIds: unfinishedSessionIds,
          },
        },
      );

      reportOpenedSession(sessionId);
    },
    [id, navigate, reportOpenedSession, state?.screen, unfinishedSessionIds],
  );

  const openNextSession = useCallback(() => {
    const itemId =
      R.isNil(firstItemIndex) || firstItemIndex < 0
        ? sessions?.[0]?.id
        : sessions?.[firstItemIndex]?.id;

    if (itemId) {
      openSession(itemId);
    }
  }, [firstItemIndex, openSession, sessions]);

  const submitButton = useMemo(
    () => ({onClick: openNextSession}),
    [openNextSession],
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const Content = useMemo(() => {
    const carouselHeight =
      height / 3 >= MAX_CAROUSEL_HEIGHT ? MAX_CAROUSEL_HEIGHT : height / 3;

    const defaultCarouselWidth =
      height / 2 >= MAX_CAROUSEL_WIDTH ? MAX_CAROUSEL_WIDTH : height / 2;
    const carouselWidth =
      defaultCarouselWidth >= width - 2 * PADDING_HORIZONTAL
        ? width - 2 * PADDING_HORIZONTAL
        : defaultCarouselWidth;

    return {
      [CourseDetailsModeType.COURSE]: (
        <CourseSessionsCarousel
          sessions={sessions}
          progressReports={sessionsProgressReports}
          finishedSessionsIds={finishedSessionIds}
          firstItemIndex={firstItemIndex}
          onOpenSession={openSession}
          width={carouselWidth}
          height={carouselHeight}
          className={styles.course__content__carousel}
        />
      ),
      [CourseDetailsModeType.DETAILS]: (
        <CourseDetailsContent description={Details} />
      ),
      [CourseDetailsModeType.COACH]: (
        <CourseCoachDetails {...coach} id={coach?.id ?? ''} />
      ),
    }[selectedMode];
  }, [
    height,
    width,
    sessions,
    sessionsProgressReports,
    finishedSessionIds,
    firstItemIndex,
    openSession,
    Details,
    coach,
    selectedMode,
  ]);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay />;
  }

  return (
    <ScreenBase>
      <div
        className={styles.course__container}
        ref={containerRef}
        onLoad={onContainerLoad}>
        <div className={styles.course__header} style={headerStyle}>
          <img src={previewImage} className={styles.course__header__image} />
          <div className={styles.course__header__gradient} />
          <RoundBackButton
            size={40}
            borderColor={colors.whiteAlpha50}
            iconColor={colors.white}
            iconSize={30}
            onPress={goBack}
          />
          <div className={styles.course__header__content}>
            {Label ? (
              <p className={styles.course__header__title}>{Label}</p>
            ) : null}

            {coachName ? (
              <p className={styles.course__header__coach}>
                {t('courses.your_coach')}
                {COACH_DELIMITER}
                <span className={styles.course__header__coach__bold}>
                  {coachName}
                </span>
              </p>
            ) : null}
          </div>
        </div>

        <div className={styles['course__modes-bar']}>
          <FilterModesBar
            data={detailsModes}
            onItemPress={updateSelectedMode}
          />
        </div>

        <div className={styles.course__content}>{Content}</div>

        <div className={styles.course__footer}>
          <Button button={submitButton} buttonStyle={ButtonStyle.SECONDARY}>
            {t('courses.start_now')}
          </Button>
        </div>
      </div>
    </ScreenBase>
  );
}
