import classNames from 'classnames';
import React, {useCallback, useMemo} from 'react';
import Select, {ClassNamesConfig, OptionProps, components} from 'react-select';
import GermanyIcon from '../../mindance-libs/icons/GermanyIcon';
import UnitedStatesIcon from '../../mindance-libs/icons/UnitedStatesIcon';
import {Languages} from '../../mindance-libs/types/common';
import styles from './Select.module.scss';
import SelectProps, {SelectOptionType, SelectPrefixIcon} from './Select.props';

const Option = components.Option;

const LanguageIcon = {
  [Languages.GERMAN]: <GermanyIcon width="1rem" height="1rem" />,
  [Languages.ENGLISH]: <UnitedStatesIcon width="1rem" height="1rem" />,
};

const prefixIconRender = <T extends string | Languages>(
  type: SelectPrefixIcon,
  value?: T,
) => {
  if (!value) return '';
  if (type === SelectPrefixIcon.LANGUAGE) {
    return LanguageIcon[value as Languages];
  }

  return '';
};

const SelectOptions = <T extends string | Languages>({
  label,
  placeholder,
  placeholderClassName,
  controlClassName,
  valueClassName,
  value,
  showLabel = true,
  isRequired = false,
  className,
  prefixIcon,
  options = [],
  disabled = false,
  onChange,
}: SelectProps<T>) => {
  const formattedLabel = isRequired && label ? `${label} *` : label;

  const CustomOption = useCallback<
    (props: OptionProps<SelectOptionType<T>, any, any>) => React.JSX.Element
  >(
    props => (
      <Option {...props} className={styles.select__option}>
        {prefixIcon && (
          <div className={styles.select__icon}>
            {prefixIconRender(prefixIcon, props.data.value)}
          </div>
        )}
        {props.data.label}
      </Option>
    ),
    [prefixIcon],
  );

  const SingleValue = useCallback(
    ({children, ...props}: any) => (
      <components.SingleValue {...props} className={styles.select__selected}>
        {prefixIcon && (
          <div className={styles.select__icon}>
            {prefixIconRender(prefixIcon, value)}
          </div>
        )}
        {children}
      </components.SingleValue>
    ),
    [prefixIcon, value],
  );

  const selectComponents = useMemo(
    () => ({
      Option: CustomOption,
      SingleValue: SingleValue,
      IndicatorSeparator: () => null,
    }),
    [CustomOption, SingleValue],
  );

  const selectStyles = useMemo<
    ClassNamesConfig<SelectOptionType<any>, false, any>
  >(
    () => ({
      input: () => styles.select__input,
      control: () => classNames(styles.select__control, controlClassName),
      valueContainer: () => styles['select__input-container'],
      indicatorsContainer: () => styles['select__indicators-container'],
      option: () => styles.select__item,
      menuList: () => styles['select__menu-list'],
      placeholder: () =>
        classNames(styles.select__placeholder, placeholderClassName),
      singleValue: () =>
        classNames(styles['select__single-value'], valueClassName),
    }),
    [controlClassName, placeholderClassName, valueClassName],
  );

  return (
    <div className={classNames(styles.select__container, className)}>
      {showLabel && <p className={styles.select__label}>{formattedLabel}</p>}
      <Select
        value={options.filter(e => e.value === value)}
        isDisabled={disabled}
        placeholder={placeholder}
        classNamePrefix="select"
        options={options}
        components={selectComponents}
        isSearchable={false}
        classNames={selectStyles}
        isMulti={false}
        onChange={onChange}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
      />
    </div>
  );
};

export default React.memo(SelectOptions);
