import * as R from 'ramda';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ScrollView, View} from 'react-native';
import {OnboardingSlideProps} from '../../../types/onboarding';
import {getDisplayedInitialState} from '../../../utils/onboarding';
import AppButton from '../../AppButton';
import PsychologistMessage from '../../PsychologistMessage';
import styles from './styles';

type Props = OnboardingSlideProps;

export const MESSAGES = [
  'onboarding.approach_description',
  'onboarding.support_list',
];
export const DISPLAY_TIMEOUT = 2500;

const OnboardingDescriptionSlide: React.FC<Props> = ({
  onButtonPress,
  style,
  focused,
  contentButtonStyle,
}) => {
  const [messagesDisplayed, setMessagesDisplayed] = useState<
    Record<string, boolean>
  >(getDisplayedInitialState(MESSAGES));

  const {t} = useTranslation();

  const containerStyle = useMemo(() => [styles.container, style], [style]);

  const InteractiveMessages = useMemo(
    () =>
      MESSAGES.map(
        message =>
          messagesDisplayed[message] && (
            <PsychologistMessage
              message={t(message)}
              key={message}
              testID={message}
              style={styles.message}
            />
          ),
      ),
    [messagesDisplayed, t],
  );

  useEffect(() => {
    const timers: NodeJS.Timeout[] = [];

    if (focused) {
      MESSAGES.map((messageKey, index) => {
        const timer = setTimeout(() => {
          setMessagesDisplayed(R.assoc(messageKey, true));
        }, DISPLAY_TIMEOUT * index + 500);

        timers.push(timer);
      });
    }

    return () => {
      timers.map(clearTimeout);
    };
  }, [focused]);

  return (
    <View style={containerStyle}>
      <View style={styles.content}>
        <ScrollView
          contentContainerStyle={styles.contentScrollView}
          showsVerticalScrollIndicator={false}>
          {InteractiveMessages}
        </ScrollView>
      </View>

      <View style={styles.buttonWrapper}>
        <AppButton
          onPress={onButtonPress}
          showNextIcon
          testID="onboardingDescription.nextButton"
          contentStyle={contentButtonStyle}>
          {t('common.next')}
        </AppButton>
      </View>
    </View>
  );
};

export default React.memo(OnboardingDescriptionSlide);
