export const removeTags = (htmlString: string) => {
  const text = htmlString
    .replace(/<p\s*\/?>/gi, '\n') // Replace <p> with newline
    .replace(/<(?!\/?(p)\s*\/?)[^<>]*>/gi, '') // Remove all other tags
    .replace(/<\/p>/gi, '') // Remove </p> tags
    .replace(/\n{2,}/g, '\n') // Replace consecutive newlines with one newline
    .trim();

  return text.endsWith('\n') ? text.slice(0, -1) : text;
};
