import {faCircleUser} from '@fortawesome/free-regular-svg-icons';
import {IconDefinition, faPen} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import useDashboardData from '../../../hooks/useDashboardData';
import colors from '../../../mindance-libs/theme/colors';
import {PERIOD_IN_DAYS, START_DATE} from './DashboardHomeCard.data';
import styles from './DashboardHomeCard.module.scss';
import DashboardHomeCardProps, {
  DashboardHomeCardData,
  DashboardHomeCardType,
} from './DashboardHomeCard.props';

const Icons: Record<DashboardHomeCardType, IconDefinition> = {
  [DashboardHomeCardType.ACTIVE_USERS]: faCircleUser,
  [DashboardHomeCardType.REGISTRATIONS]: faPen,
};

const LONG_DASH = <code>&#8212;</code>;

const DashboardHomeCard: React.FC<DashboardHomeCardProps> = ({
  className,
  testID,
}) => {
  const {t, i18n} = useTranslation();

  const {activeUsers, registrations} = useDashboardData({
    startDate: START_DATE,
  });

  const cardsData = useMemo<DashboardHomeCardData[]>(() => {
    return [
      {
        label: DashboardHomeCardType.ACTIVE_USERS,
        title: t('mindManagerDashboard.active_users'),
        // TODO: add options for a period > 1 month, ask Michele for translations
        subtitle: t('mindManagerDashboard.last_period_days', {
          period: PERIOD_IN_DAYS,
        }),
        value: activeUsers,
      },
      {
        label: DashboardHomeCardType.REGISTRATIONS,
        title: t('mindManagerDashboard.registrations'),
        value: registrations,
      },
    ];
  }, [activeUsers, registrations, t]);

  const mergedStyle = classNames(styles['dashboard-home-card'], className);

  return (
    <div className={mergedStyle} data-testid={testID}>
      {cardsData.map(({label, title, subtitle, value}) => (
        <div className={styles['dashboard-home-card__container']} key={label}>
          <div className={styles['dashboard-home-card__container__box']}>
            <FontAwesomeIcon
              icon={Icons[label]}
              color={colors.white}
              className={styles['dashboard-home-card__container__box__icon']}
            />
            <span>
              {value
                ? value.toLocaleString(i18n.language, {
                    maximumFractionDigits: 3,
                  })
                : LONG_DASH}
            </span>
          </div>

          <div className={styles['dashboard-home-card__container__text']}>
            <p
              className={styles['dashboard-home-card__container__text__title']}>
              {title}
            </p>
            <p
              className={
                styles['dashboard-home-card__container__text__subtitle']
              }>
              {subtitle}&nbsp;
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardHomeCard;
