import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, View, ViewStyle} from 'react-native';
import {Maybe} from '../../generated/graphql';
import {getStringRoundedMinutes} from '../../utils/time';
import AppText from '../AppText';
import LibraryWideCardBase from '../LibraryWideCardBase';
import SegmentedProgressBar from '../SegmentedProgressBar';
import styles from './styles';

type Props = {
  id: string;
  onPress: (id: string) => void;
  Label?: string | null;
  imageUrl?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  imageWidth: number;
  imageRatio: number;
  isCompleted: boolean;
  Duration?: Maybe<string>;
};

const LibrarySessionWideCard: React.FC<Props> = ({
  id,
  onPress,
  imageUrl,
  style,
  testID,
  imageRatio,
  imageWidth,
  Label,
  isCompleted,
  Duration,
}) => {
  const {t} = useTranslation();

  const subtitle = Duration
    ? t('courses.session_duration', {
        sessionDuration: getStringRoundedMinutes(Duration),
      })
    : null;

  const currentProgress = isCompleted ? 1 : 0;

  const handlePress = useCallback(() => onPress(id), [id, onPress]);

  return (
    <LibraryWideCardBase
      label={Label}
      imageWidth={imageWidth}
      imageRatio={imageRatio}
      imageUrl={imageUrl}
      style={style}
      testID={testID}
      onPress={handlePress}>
      <View style={styles.content}>
        <SegmentedProgressBar
          style={styles.progress}
          current={currentProgress}
          total={1}
          testID={`${testID}.progressBar`}
        />
        <AppText style={styles.subtitle}>{subtitle}</AppText>
      </View>
    </LibraryWideCardBase>
  );
};

export default React.memo(LibrarySessionWideCard);
