import React, {useMemo} from 'react';
import DashboardItem from '../../../components/DashboardItem/DashboardItem';
import DashboardSectionTitle from '../../../components/DashboardSectionTitle/DashboardSectionTitle';
import ErrorOverlay from '../../../components/ErrorOverlay/ErrorOverlay';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay';
import {useSubscriptionsContext} from '../../../contexts/SubscriptionsContext';
import useDashboardData from '../../../hooks/useDashboardData';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import {ProductLabels} from '../../../mindance-libs/types/products';
import {DashboardComponentTemplates} from '../../../types/DashboardComponents';
import styles from './page.module.scss';

export default function Page() {
  const {mindManagerHome} = useSubscriptionsContext();

  const {
    activeUsers,
    ageGroups,
    error,
    events,
    eventsCount,
    genderGroups,
    groupCoachings,
    groupCoachingsCount,
    loading,
    registrations,
    sessionsPerUser,
    appStoreRating,
    eventBookingsTotal,
    newsLikesTotal,
    topEvents,
    topNews,
    data,

    // TODO
    // topEventsPerMonth,
    // topNewsPerMonth,
  } = useDashboardData();

  const dashboardSetup = useMemo(
    () =>
      mindManagerHome?.MindManager_ManagerTools?.find(
        ({ManagerTools}) =>
          ManagerTools?.Products?.Label ===
          ProductLabels.DASHBOARD_SHOW_RELATED_SUBSCRIPTIONS,
      )?.ManagerTools,
    [mindManagerHome?.MindManager_ManagerTools],
  );

  const DashboardItems = useMemo(() => {
    if (!dashboardSetup?.ManagerTools_DashboardComponents) {
      return null;
    }

    return dashboardSetup.ManagerTools_DashboardComponents.map(
      ({DashboardComponents}) => {
        if (!DashboardComponents) {
          return null;
        }

        const {
          Template,
          GridSize = '2',
          DashboardComponents_translations,
          id,
        } = DashboardComponents;

        const title = DashboardComponents_translations?.[0]?.Label || '';

        const commonProps = {
          className: styles[`dashboard--grid-size-${GridSize}`],
          title,
          key: id,
        };

        switch (Template) {
          case DashboardComponentTemplates.ACTIVE_USERS:
            return (
              <DashboardItem
                {...commonProps}
                iconKey="fa-solid fa-user"
                contentType="value"
                content={activeUsers}
              />
            );
          case DashboardComponentTemplates.AGES:
            return (
              <DashboardItem
                {...commonProps}
                iconKey="fa-solid fa-user-group"
                contentType="bar-chart"
                content={ageGroups}
              />
            );
          case DashboardComponentTemplates.EVENTS_COUNT:
            return (
              <DashboardItem
                {...commonProps}
                iconKey="fa-solid fa-chalkboard-user"
                contentType="value"
                content={eventsCount}
              />
            );
          case DashboardComponentTemplates.EVENTS_TOP:
            return (
              !!eventsCount && (
                <DashboardItem
                  {...commonProps}
                  contentType="top-chart"
                  content={events}
                />
              )
            );
          case DashboardComponentTemplates.GENDER:
            return (
              <DashboardItem
                {...commonProps}
                iconKey="fa-solid fa-database"
                contentType="pie-chart"
                content={genderGroups}
              />
            );
          case DashboardComponentTemplates.GROUP_COACHING_COUNT:
            return (
              <DashboardItem
                {...commonProps}
                iconKey="fa-solid fa-users-rectangle"
                contentType="value"
                content={groupCoachingsCount}
              />
            );
          case DashboardComponentTemplates.GROUP_COACHING_TOP:
            return (
              !!groupCoachingsCount && (
                <DashboardItem
                  {...commonProps}
                  contentType="top-chart"
                  content={groupCoachings}
                />
              )
            );
          case DashboardComponentTemplates.REGISTRATIONS:
            return (
              <DashboardItem
                {...commonProps}
                iconKey="fa-solid fa-pen"
                contentType="value"
                content={registrations}
              />
            );
          case DashboardComponentTemplates.SECTION_TITLE:
            return <DashboardSectionTitle {...commonProps} />;
          case DashboardComponentTemplates.SESSIONS_PER_USER:
            return (
              <DashboardItem
                {...commonProps}
                iconKey="fa-solid fa-clock"
                contentType="value"
                content={sessionsPerUser}
              />
            );
          case DashboardComponentTemplates.STORE_RATING:
            return (
              <DashboardItem
                {...commonProps}
                iconKey="fa-solid fa-star"
                contentType="value"
                content={appStoreRating}
              />
            );
          case DashboardComponentTemplates.COMPANY_INSIGHTS_EVENT_BOOKINGS:
            return (
              <DashboardItem
                {...commonProps}
                iconKey="fa-solid fa-users-rectangle"
                contentType="value"
                content={eventBookingsTotal}
              />
            );
          case DashboardComponentTemplates.COMPANY_INSIGHTS_NEWS_LIKES:
            return (
              <DashboardItem
                {...commonProps}
                iconKey="fa-solid fa-message"
                contentType="value"
                content={newsLikesTotal}
              />
            );
          case DashboardComponentTemplates.COMPANY_INSIGHTS_NEWS_TOP:
            return (
              <DashboardItem
                {...commonProps}
                iconKey="fa-solid fa-message"
                contentType="top-chart"
                content={topNews}
              />
            );
          case DashboardComponentTemplates.COMPANY_INSIGHTS_EVENTS_TOP:
            return (
              <DashboardItem
                {...commonProps}
                iconKey="fa-solid fa-users-rectangle"
                contentType="top-chart"
                content={topEvents}
              />
            );

          default:
            return null;
        }
      },
    );
  }, [
    activeUsers,
    ageGroups,
    appStoreRating,
    dashboardSetup?.ManagerTools_DashboardComponents,
    eventBookingsTotal,
    events,
    eventsCount,
    genderGroups,
    groupCoachings,
    groupCoachingsCount,
    newsLikesTotal,
    registrations,
    sessionsPerUser,
    topEvents,
    topNews,
  ]);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error || (!loading && !data)) {
    return <ErrorOverlay />;
  }

  return (
    <ScreenBase>
      <div className={styles.dashboard__container}>
        <div className={styles['dashboard__grid-container']}>
          {DashboardItems}
        </div>
      </div>
    </ScreenBase>
  );
}
