import {
  ApolloError,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import * as R from 'ramda';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {InputMaybe} from '../generated/graphql';
import {reportError} from '../utils/loggingHelpers';

const INITIAL_OFFSET = 0;
const TAKE_SIZE = 10;

const filterDuplicates = <
  TKey extends string,
  TData extends {[key: string]: any} & {
    id: string | number;
    __typename?: string;
  },
  TQuery extends Record<TKey, Array<TData>>,
>(
  arr?: TQuery[TKey],
): TQuery[TKey] | undefined => {
  if (!arr) {
    return;
  }

  const uniqueItems = new Map<string, TQuery[TKey][number]>();

  for (const item of arr) {
    const key = `${item.__typename}-${item.id}`;
    if (!uniqueItems.has(key)) {
      uniqueItems.set(key, item);
    }
  }
  return Array.from(uniqueItems.values()) as TQuery[TKey];
};

const useListQuery = <
  TKey extends string,
  TData extends {[key: string]: any} & {
    id: string | number;
    __typename?: string;
  },
  TQuery extends Record<TKey, Array<TData>>,
  TVariables extends OperationVariables & {
    skip?: InputMaybe<number>;
    take?: InputMaybe<number>;
  },
>(
  useQuery: (
    baseOptions?: QueryHookOptions<TQuery, TVariables>,
  ) => QueryResult<TQuery, TVariables>,
  getQueryVariables: (input?: Partial<TVariables>) => TVariables,
  key: TKey,
  options?: {
    skip?: number;
    take?: number;
    limit?: number;
    fetchPolicy?: WatchQueryFetchPolicy;
    shouldSkip?: boolean;
  },
) => {
  const take = options?.take ?? TAKE_SIZE;
  const skip = options?.skip ?? INITIAL_OFFSET;
  const limit = options?.limit;
  const fetchPolicy = options?.fetchPolicy ?? 'cache-and-network';
  const shouldSkip = options?.shouldSkip ?? false;

  const [offset, setOffset] = useState(skip);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  const defaultVariables: TVariables = useMemo(
    () => ({skip, take} as unknown as TVariables),
    [skip, take],
  );

  const queryVariables = useMemo<TVariables>(
    () => getQueryVariables(defaultVariables as Partial<TVariables>),
    [defaultVariables, getQueryVariables],
  );

  const resetParams = useCallback(() => {
    setOffset(INITIAL_OFFSET);
    setHasMore(true);
  }, []);

  useEffect(() => {
    // reset params if query parameters change
    resetParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVariables]);

  const handleError = useCallback<(error: ApolloError) => void>(
    error => {
      reportError(`${key} error`, error);
    },
    [key],
  );

  const {data, loading, error, refetch, fetchMore, previousData} = useQuery({
    variables: queryVariables,
    fetchPolicy,
    onError: handleError,
    skip: shouldSkip,
  });

  const items = useMemo(() => filterDuplicates(data?.[key]) ?? [], [data, key]);

  const previousItems = useMemo(
    () => previousData?.[key] ?? [],
    [key, previousData],
  );

  const defaultRefetch = useCallback(() => {
    refetch(getQueryVariables(defaultVariables as Partial<TVariables>));
    resetParams();
  }, [defaultVariables, getQueryVariables, refetch, resetParams]);

  const refetchWithParams = useCallback<
    (params?: Partial<TVariables>) => Promise<void>
  >(
    async params => {
      setRefreshing(true);
      try {
        const variables = params
          ? R.mergeDeepRight(defaultVariables, params)
          : defaultVariables;
        await refetch(getQueryVariables(variables as Partial<TVariables>));
        resetParams();
      } catch (e) {
        reportError('refetchWithParams error', e);
      } finally {
        setRefreshing(false);
      }
    },
    [defaultVariables, getQueryVariables, refetch, resetParams],
  );

  const fetchNext = useCallback(async () => {
    try {
      if (
        loading ||
        (limit && items?.length && items?.length >= limit) ||
        !hasMore
      ) {
        return;
      }

      setLoadingMore(true);
      const newOffset = offset + take;
      const result = await fetchMore({
        variables: getQueryVariables({
          ...defaultVariables,
          skip: newOffset,
        } as Partial<TVariables>),
      });

      setLoadingMore(false);

      if (result.data?.[key]?.length < take) {
        setHasMore(false);
      }

      if (result.data?.[key]?.length > 0) {
        setOffset(newOffset);
      }
    } catch (e) {
      reportError('fetch more list items error', e);
    }
  }, [
    defaultVariables,
    fetchMore,
    getQueryVariables,
    hasMore,
    items?.length,
    key,
    limit,
    loading,
    offset,
    take,
  ]);

  return useMemo(
    () => ({
      data: items,
      previousData: previousItems,
      refetch: defaultRefetch,
      refetchWithParams,
      loading,
      loadingMore,
      refreshing,
      error,
      fetchNext,
    }),
    [
      items,
      previousItems,
      defaultRefetch,
      refetchWithParams,
      loading,
      loadingMore,
      refreshing,
      error,
      fetchNext,
    ],
  );
};

export default useListQuery;
