import {useCallback, useMemo} from 'react';
import useBooleanValue from './useBooleanValue';

export type OnModalShow = () => void;
export type OnModalHide = () => void;

export type AppModalHook = {
  isModalVisible: boolean;
  showModal: (options?: {onModalShow?: OnModalShow}) => void;
  hideModal: (options?: {onModalHide?: OnModalHide}) => void;
};

const useAppModal = (): AppModalHook => {
  const {setFalse, setTrue, value: isModalVisible} = useBooleanValue(false);

  const showModal = useCallback<AppModalHook['showModal']>(
    options => {
      setTrue();
      options?.onModalShow?.();
    },
    [setTrue],
  );

  const hideModal = useCallback<AppModalHook['hideModal']>(
    options => {
      setFalse();
      options?.onModalHide?.();
    },
    [setFalse],
  );

  return useMemo(
    () => ({isModalVisible, hideModal, showModal}),
    [hideModal, isModalVisible, showModal],
  );
};

export default useAppModal;
