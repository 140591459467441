type DashboardHomeCardProps = {
  className?: string;
  testID?: string;
};

export enum DashboardHomeCardType {
  ACTIVE_USERS = 'ACTIVE_USERS',
  REGISTRATIONS = 'REGISTRATIONS',
}

export type DashboardHomeCardData = {
  label: DashboardHomeCardType;
  title: string;
  subtitle?: string | null;
  value?: number | null;
};

export default DashboardHomeCardProps;
