import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {SMALL_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';

const LANGUAGE_IMAGE_SIZE = 18;

const styles = StyleSheet.create({
  container: {
    borderRadius: SMALL_BORDER_RADIUS,
    backgroundColor: colors.whiteAlpha10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: spacings.xl,
    paddingVertical: spacings.l,
    alignItems: 'center',
  },
  regionImage: {
    width: LANGUAGE_IMAGE_SIZE,
    height: LANGUAGE_IMAGE_SIZE,
    borderRadius: LANGUAGE_IMAGE_SIZE / 2,
  },
  content: {
    flex: 1,
    gap: spacings.xs,
  },
  phoneWrapper: {
    flex: 1,
  },
  region: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacings.xs,
  },
  regionName: {
    ...typography.bodyDemiBold,
  },
  description: {
    ...typography.footnote,
    color: colors.whiteAlpha70,
  },
  phoneNumber: {
    ...typography.subtitle2Bold,
  },
  buttonWrapper: {
    flex: 0.2,
    alignItems: 'flex-end',
  },
  phoneButton: {
    backgroundColor: colors.whiteAlpha30,
    padding: spacings.s,
    borderRadius: 50,
  },
});

export default styles;
