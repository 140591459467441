import {TFunction} from 'i18next';
import {RouteKeys} from '../../../navigation/RouteKeys';

export const SettingsList = (
  t: TFunction<'translation', undefined, 'translation'>,
) => [
  {
    title: t('settings.account_details'),
    href: RouteKeys.SettingsAccountDetails,
  },
  {
    title: t('settings.redeem_activation_code'),
    href: RouteKeys.SettingsRedeemActivationCode,
  },
  {
    title: t('settings.help'),
    href: RouteKeys.SettingsHelp,
  },
  {
    title: t('settings.health_requirements'),
    href: RouteKeys.SettingsHealthRequirements,
  },
  {
    title: t('settings.terms_and_conditions'),
    href: RouteKeys.SettingsTermsAndConditions,
  },
  {
    title: t('settings.privacy_policy'),
    href: RouteKeys.SettingsPrivacyPolicy,
  },
];
