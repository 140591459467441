import {StyleSheet} from 'react-native';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';
import colors from '../../theme/colors';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textWrapper: {
    marginLeft: spacings.s,
    flexShrink: 1,
  },
  text: {
    ...typography.footnote,
  },
  linkBorder: {
    width: '100%',
    height: 1,
    backgroundColor: colors.white,
  },
});

export default styles;
