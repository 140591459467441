import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import Button from '../../../components/Button/Button';
import {ButtonStyle} from '../../../components/Button/Button.props';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay';
import {useModalContext} from '../../../contexts/ModalContext';
import useDangerouslySetInnerHtml from '../../../hooks/useDangerouslySetInnerHtml';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import {useTermsAndConditionsQuery} from '../../../mindance-libs/generated/graphql';
import styles from '../page.module.scss';

type Props = {
  modal?: boolean;
};

export default function Page({modal}: Props) {
  const {t, i18n} = useTranslation();

  const {data, loading} = useTermsAndConditionsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {language: i18n.language},
  });

  const {closeModal} = useModalContext();

  const navigate = useNavigate();

  const backButton = useMemo(
    () => ({
      title: t('common.return') || '',
      onClick: () => (modal ? closeModal() : navigate(-1)),
    }),
    [closeModal, modal, navigate, t],
  );

  const title =
    data?.termsAndConditions.Policies_translations?.[0].Title ??
    t('screenTitle.TermsAndConditionsScreen');

  const content =
    data?.termsAndConditions.Policies_translations?.[0].Content ?? '';

  const dangerouslySetInnerHTML = useDangerouslySetInnerHtml(content);

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <ScreenBase>
      <div className={styles.policies__container}>
        <Button
          showLeftArrow
          button={backButton}
          className={styles.policies__button}
          buttonStyle={ButtonStyle.INLINE_WHITE}
        />
        <h1>{title}</h1>

        <div className={styles.policies__wrapper}>
          <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
        </div>
      </div>
    </ScreenBase>
  );
}
