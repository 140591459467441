import React, {useMemo} from 'react';
import {
  Insets,
  StyleProp,
  TouchableHighlight,
  View,
  ViewStyle,
} from 'react-native';
import TickIcon from '../../icons/TickIcon';
import colors from '../../theme/colors';
import styles from './styles';

type Props = {
  isChecked: boolean;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  tickSize?: number;
  error?: boolean;
  errorColor?: string;
  testID?: string;
  accessibilityLabel?: string;
};

const HIT_SLOP: Insets = {
  bottom: 5,
  left: 5,
  right: 5,
  top: 5,
};

const AppCheckBox: React.FC<Props> = ({
  style,
  isChecked,
  onPress,
  tickSize = 16,
  error = false,
  errorColor = colors.alert,
  testID,
  accessibilityLabel,
}) => {
  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [
      styles.container,
      {borderColor: error ? errorColor : colors.whiteAlpha40},
      style,
    ],
    [error, errorColor, style],
  );
  return (
    <TouchableHighlight
      onPress={onPress}
      style={containerStyle}
      hitSlop={HIT_SLOP}
      testID={testID}
      accessibilityLabel={accessibilityLabel}>
      <View>
        {isChecked && (
          <TickIcon
            height={tickSize}
            width={tickSize}
            fill={error ? errorColor : colors.white}
            testID={`${testID}.tickIcon`}
          />
        )}
      </View>
    </TouchableHighlight>
  );
};

export default React.memo(AppCheckBox);
