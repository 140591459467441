import format from 'date-fns/format';
import {TFunction} from 'i18next';
import * as R from 'ramda';
import {
  BaseBookingFragment,
  Maybe,
  MediumDetailsFragment,
  MediumTranslationFragment,
} from '../generated/graphql';
import {BookingItemType} from '../types/booking';
import {EURO_SYMBOL} from './constants';
import {getDateFnsLocale, getHardcodedLocalizedStringTime} from './time';

const BOOKING_DEADLINE_HOURS_GAP = 12;

export const getBookingDeadlineDate = (): string => {
  const currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + BOOKING_DEADLINE_HOURS_GAP);
  return currentDate.toISOString();
};

export const getEventTime = (
  startTime?: Maybe<string>,
  endTime?: Maybe<string>,
): string | null => {
  if ((!startTime && !endTime) || !startTime) {
    return null;
  }

  const stringifiedStartTime = getHardcodedLocalizedStringTime(startTime);

  if (!endTime) {
    return stringifiedStartTime;
  }

  return `${stringifiedStartTime} - ${getHardcodedLocalizedStringTime(
    endTime,
  )}`;
};

export const getFormattedEventDate = (
  incomingDate: string,
  currentLanguage: string,
) => {
  const date = new Date(incomingDate);
  const formattedDate = format(date, 'dd.MM.yyyy');
  const dayOfWeek = format(date, 'eeee', {
    locale: getDateFnsLocale(currentLanguage),
  });

  return {formattedDate, dayOfWeek};
};

export const getMediumLabel = (
  mediums?: Maybe<MediumDetailsFragment>[] | null,
): string | undefined => {
  const mediumTranslations =
    mediums?.reduce<MediumTranslationFragment[]>((acc, medium) => {
      const translation = medium?.Mediums_translations?.[0];
      return translation ? [...acc, translation] : acc;
    }, []) ?? [];

  if (!mediumTranslations) {
    return;
  }

  const labels = R.pluck('Label', mediumTranslations);
  const filtered = R.reject(R.isNil, labels);

  if (R.isEmpty(filtered)) {
    return;
  }

  return filtered.join(', ');
};

export const isBaseBookingItem = (item: any): item is BaseBookingFragment => {
  const bookingTypename: BaseBookingFragment['__typename'] = 'BookingsV2';

  return item.__typename === bookingTypename;
};

export const isEventItem = (item: BaseBookingFragment): boolean =>
  item.BookingType !== BookingItemType.ONE_TO_ONE_SESSION;

export const isOneToOneSessionItem = (item: BaseBookingFragment): boolean =>
  item.BookingType === BookingItemType.ONE_TO_ONE_SESSION;

export const isCompanyBookingItem = (item: BaseBookingFragment): boolean =>
  item.BookingType === BookingItemType.EVENT_EVENTTOOL;

export const getCostLabel =
  (t: TFunction) =>
  (cost?: Maybe<string>, short: boolean = true) => {
    if (!cost) {
      return t(short ? 'booking.cost_free' : 'booking.cost_will_be_covered');
    }

    const formattedCost = Number(cost.replace(',', '.'));

    return isNaN(formattedCost) ? cost : `${formattedCost} ${EURO_SYMBOL}`;
  };

export const getCoachName = (
  firstName?: string | null,
  lastName?: string | null,
): string | null => {
  const result = [firstName, lastName].filter(R.isNotNil).join(' ');
  return result === '' ? null : result;
};

const APPOINTMENTS_LIMIT = 30;

export const getAppointmentsLabel =
  (t: TFunction) => (appointmentsNumber: number) => {
    const appointmentsValue =
      appointmentsNumber <= APPOINTMENTS_LIMIT
        ? appointmentsNumber
        : `+${APPOINTMENTS_LIMIT}`;

    const appointmentsLabel =
      appointmentsNumber > 1 ? 'booking.appointments' : 'booking.appointment';

    return t(appointmentsLabel, {value: appointmentsValue});
  };

export const formatBookingInfo = (bookingInfo?: string | null) => {
  if (!bookingInfo) {
    return bookingInfo;
  }

  const unreadableSpaceSymbolPattern = /[\x0B]/g;

  const output = bookingInfo.replace(unreadableSpaceSymbolPattern, '<br>');
  return output;
};

// TODO: change it when one-to-one sessions will be only in one selected language
// it can causes issues!
export const getOneToOnceCoachingLanguageParam = (
  appLanguage: string,
  oneToOneCoachingLanguages: BaseBookingFragment['OneToOneSessions_languages'],
) => {
  const currentLanguage = oneToOneCoachingLanguages?.find(
    ({languages_code}) => languages_code === appLanguage,
  );

  const language =
    currentLanguage?.languages_code ??
    oneToOneCoachingLanguages?.[0]?.languages_code ??
    appLanguage;

  return language;
};
