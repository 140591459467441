import {useCallback, useEffect, useMemo} from 'react';
import {Maybe, TagsGeneralSettingFragment} from '../generated/graphql';
import {useAppContext} from '../store/contexts/AppContext';
import {CustomTagGroup} from '../types/common';
import {getFormattedTagsGroups} from '../utils/tags';
import useAppModal from './useAppModal';
import useSelectTags from './useSelectTags';

const useHomeInterestsModal = (
  tagsGeneralSettingFragment?: Maybe<TagsGeneralSettingFragment>,
) => {
  const {userTags, updateUserTags} = useAppContext();

  const {hideModal, isModalVisible, showModal} = useAppModal();

  const tagsFilters = useMemo<CustomTagGroup[]>(
    () => getFormattedTagsGroups(tagsGeneralSettingFragment),
    [tagsGeneralSettingFragment],
  );

  const {
    deselectAllTags,
    resetSelectedTags,
    selectAllTags,
    selectTag,
    selectedTagsIds,
    selectedTagsRecord,
    tagsIds,
    tags,
  } = useSelectTags({initiallySelectedTags: userTags, tagsFilters});

  const updateTags = useCallback(async () => {
    await updateUserTags(selectedTagsIds);
  }, [selectedTagsIds, updateUserTags]);

  const closeModal = useCallback(() => {
    hideModal({onModalHide: resetSelectedTags});
  }, [hideModal, resetSelectedTags]);

  const submitTags = useCallback(async () => {
    await updateTags();
    hideModal();
  }, [hideModal, updateTags]);

  const openInterestsModal = useCallback(() => showModal(), [showModal]);

  useEffect(() => {
    // keep a synchronous info about the selected tags
    resetSelectedTags();
  }, [resetSelectedTags]);

  return useMemo(
    () => ({
      selectedTagsRecord,
      selectTag,
      isModalVisible,
      closeModal,
      submitTags,
      openInterestsModal,
      tagsFilters,
      tags,
      tagsIds,
      userTags,
      selectedTagsIds,
      deselectAllTags,
      selectAllTags,
    }),
    [
      selectedTagsRecord,
      selectTag,
      isModalVisible,
      closeModal,
      submitTags,
      openInterestsModal,
      tagsFilters,
      tags,
      tagsIds,
      userTags,
      selectedTagsIds,
      deselectAllTags,
      selectAllTags,
    ],
  );
};

export default useHomeInterestsModal;
