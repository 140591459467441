import {useLayout} from '@react-native-community/hooks';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Image,
  StyleProp,
  TouchableHighlight,
  View,
  ViewStyle,
} from 'react-native';
import FastImage, {ImageStyle, Source} from 'react-native-fast-image';
import {BaseBookingFragment} from '../../generated/graphql';
import useCompanyEventImage from '../../hooks/useCompanyEventImage';
import colors from '../../theme/colors';
import {BookingItemType, OnCompanyEventPress} from '../../types/booking';
import {CompanyItemType} from '../../types/companyItem';
import {getEventDate} from '../../utils/home';
import AppText from '../AppText';
import CompanyItemLabel from '../CompanyItemLabel';
import styles, {IMAGE_ASPECT_RATIO, MAX_IMAGE_WIDTH} from './styles';

type Props = BaseBookingFragment & {
  onPress?: OnCompanyEventPress;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

const CompanyEventItem: React.FC<Props> = ({
  id,
  companyId,
  courseId,
  serviceId,
  StartDate,
  StartTime,
  Label,
  directus_files,
  style,
  testID,
  onPress,
}) => {
  const {t, i18n} = useTranslation();

  const {width: containerWidth, onLayout: onContainerLayout} = useLayout();

  const getEventImage = useCompanyEventImage();

  const imageWidth = containerWidth > 0 ? containerWidth / 3 : MAX_IMAGE_WIDTH;

  const dateLabel = getEventDate({
    t,
    startDate: StartDate,
    startTime: StartTime,
    currentLanguage: i18n.language,
  });

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, style],
    [style],
  );

  const imageStyle = useMemo<StyleProp<ImageStyle>>(
    () => [styles.image, {width: imageWidth}],
    [imageWidth],
  );

  const imageSource = useMemo<Source>(() => {
    const uri = getEventImage(directus_files, BookingItemType.EVENT_EVENTTOOL, {
      width: imageWidth,
      aspectRatio: IMAGE_ASPECT_RATIO,
    });

    return uri ? {uri} : require('../../assets/images/placeholder.png');
  }, [directus_files, getEventImage, imageWidth]);

  const handlePress = useCallback(() => {
    onPress?.(id, {courseId, serviceId, companyId});
  }, [companyId, courseId, id, onPress, serviceId]);

  return (
    <TouchableHighlight
      style={containerStyle}
      underlayColor={colors.whiteAlpha13}
      testID={testID}
      onPress={handlePress}
      disabled={!onPress}
      onLayout={onContainerLayout}>
      <>
        <FastImage
          source={imageSource}
          style={imageStyle}
          resizeMode="contain"
        />

        <View style={styles.content}>
          <CompanyItemLabel
            itemType={CompanyItemType.EVENT}
            style={styles.eventLabel}
          />

          <View style={styles.dateContainer}>
            <Image
              source={require('../../assets/images/calendar_icon.png')}
              style={styles.calendarImage}
            />
            {dateLabel ? (
              <AppText style={styles.dateLabel} testID={`${testID}.dateLabel`}>
                {dateLabel}
              </AppText>
            ) : null}
          </View>

          {Label ? (
            <AppText
              style={styles.title}
              testID={`${testID}.title`}
              ellipsizeMode="tail"
              numberOfLines={2}>
              {Label}
            </AppText>
          ) : null}
        </View>
      </>
    </TouchableHighlight>
  );
};

export default React.memo(CompanyEventItem);
