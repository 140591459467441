import {useFormik} from 'formik';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Linking} from 'react-native';
import Button from '../../../components/Button/Button';
import ControlBottomButtons from '../../../components/ControlBottomButtons/ControlBottomButtons';
import AppInput from '../../../mindance-libs/components/AppInput';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import useErrorMessage from '../../../mindance-libs/hooks/useErrorMessage';
import useInputFormikHandlers from '../../../mindance-libs/hooks/useInputFormikHandlers';
import MindanceBigLogo from '../../../mindance-libs/icons/MindanceBigLogo';
import {useAuthContext} from '../../../mindance-libs/store/contexts/AuthContext';
import {useInfoOverlaysContext} from '../../../mindance-libs/store/contexts/InfoOverlaysContext';
import {OnError} from '../../../mindance-libs/types/auth';
import {CONTACT_US_EMAIL} from '../../../mindance-libs/utils/constants';
import {loginFormValidationSchema} from '../../../mindance-libs/utils/formValidations';
import {RouteKeys} from '../../../navigation/RouteKeys';
import useTypedNavigate from '../../../navigation/typed/useTypedNavigate';
import useTypedSearchParams from '../../../navigation/typed/useTypedSearchParams';
import {INITIAL_FORM_VALUES, getLoginDataLang} from './page.data';
import styles from './page.module.scss';
import {LoginFormProps} from './page.props';

export default function Page() {
  const {t, i18n} = useTranslation();
  const {showMessage, error} = useErrorMessage();
  const {showInfoOverlay} = useInfoOverlaysContext();

  const [{ac: activationCode}] = useTypedSearchParams<RouteKeys.Login>();

  const {subtitle} = getLoginDataLang(i18n.language);

  const navigate = useTypedNavigate();

  const sendEmailToSupport = useCallback(
    () => Linking.openURL(`mailto:${CONTACT_US_EMAIL}`),
    [],
  );

  const handleError = useCallback<OnError>(
    (errorMessage, details) => {
      const isActivationCodeError = details?.errorType === 'activation_code';

      if (isActivationCodeError) {
        showInfoOverlay({
          title: t('errors.something_went_wrong_here'),
          description: t('auth.we_can_not_assign_you_to_company'),
          buttonLabel: t('settings.contact_support'),
          animation: require('../../../mindance-libs/assets/animations/screen_locked_animation.json'),
          showNextIcon: true,
          showCloseButton: true,
          onSubmit: sendEmailToSupport,
        });
        return;
      }

      showMessage(errorMessage);
    },
    [sendEmailToSupport, showInfoOverlay, showMessage, t],
  );

  const {login} = useAuthContext({onError: handleError});

  const submitLoginForm = useCallback<
    (values: LoginFormProps) => Promise<void>
  >(
    async ({email, password}) => {
      await login(email.trim(), password, {activationCode, showError: true});
    },
    [activationCode, login],
  );

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    validationSchema: loginFormValidationSchema,
    validateOnMount: true,
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: submitLoginForm,
  });

  const {
    handleValueBlur: handleEmailBlur,
    hasError: hasEmailError,
    setValue: setEmail,
  } = useInputFormikHandlers<LoginFormProps>('email', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError: showMessage,
  });

  const {
    handleValueBlur: handlePasswordBlur,
    hasError: hasPasswordError,
    setValue: setPassword,
  } = useInputFormikHandlers<LoginFormProps>('password', {
    errors,
    handleBlur,
    handleChange,
    touched,
    onError: showMessage,
  });

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <ScreenBase error={error}>
      <div className={styles.login__container}>
        <div className={styles.login__content}>
          <div className={styles.login__logo}>
            <MindanceBigLogo />
          </div>

          <h1>{t('welcome.title')}</h1>
          <h2>{subtitle}</h2>

          <div className={styles['login__input-fields']}>
            <form>
              <AppInput
                label={t('auth.email')}
                placeholder={t('auth.email_placeholder')}
                value={values.email}
                onChangeText={setEmail}
                onBlur={handleEmailBlur}
                keyboardType="email-address"
                autoCapitalize="none"
                autoCorrect={false}
                error={hasEmailError}
                testID="login.emailInput"
              />

              <div className={styles['login__input-fields__password']}>
                <AppInput
                  label={t('auth.password')}
                  value={values.password}
                  onChangeText={setPassword}
                  onBlur={handlePasswordBlur}
                  autoCapitalize="none"
                  isPassword
                  error={hasPasswordError}
                  testID="login.passwordInput"
                />
              </div>
            </form>
          </div>
          <div className={styles['login__forget-password']}>
            <Button
              className={styles['login__forget-password__link']}
              anchor={{
                href: RouteKeys.ForgotPassword,
              }}>
              {t('auth.forget_password')}
            </Button>
          </div>

          <div className={styles.login__bottom}>
            <ControlBottomButtons
              onSubmit={handleSubmit}
              showReturnButton={false}
              onReturn={goBack}
              submitButtonDisabled={!isValid || isSubmitting}
              returnButtonTestID="login.returnButton"
              submitButtonTestID="login.submitButton"
              submitButtonLoading={isSubmitting}
            />
          </div>
        </div>
      </div>
    </ScreenBase>
  );
}
