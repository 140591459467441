import {StyleSheet} from 'react-native';
import spacings from '../../theme/spacings';

const styles = StyleSheet.create({
  container: {
    overflow: 'visible',
  },
  list: {
    overflow: 'visible',
  },
  dots: {
    marginTop: spacings.xs,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
