import React, {useMemo} from 'react';
import {StyleProp, View, ViewProps, ViewStyle} from 'react-native';
import styles from './styles';

const LinearSeparator: React.FC<ViewProps> = ({style, ...props}) => {
  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, style],
    [style],
  );

  return <View style={containerStyle} {...props} />;
};

export default React.memo(LinearSeparator);
