import * as R from 'ramda';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  MindManagerNewsDetailsFragment,
  NewsTranslationsFragment,
} from '../mindance-libs/generated/graphql';
import {Languages} from '../mindance-libs/types/common';
import {NEWS_LANGUAGES} from '../pages/sidebar/newstool/details/page.data';
import {NewsInputFields} from '../types/NewsInputFields';

const getInitialInputFields = (
  language: Languages,
  translations?: NewsTranslationsFragment[] | null,
): NewsInputFields => {
  const currentTranslation = translations?.find(
    ({languages_code}) => languages_code === language,
  );

  if (!translations || translations.length === 0 || !currentTranslation) {
    return {title: '', description: '', enabled: false};
  }

  return {
    title: currentTranslation?.Label ?? '',
    description: currentTranslation?.Description ?? '',
    enabled: currentTranslation?.Enabled ?? false,
  };
};

const useNewsInputFields = ({
  editMode,
  loading,
  data,
}: {
  data?: MindManagerNewsDetailsFragment;
  loading: boolean;
  editMode: boolean;
}) => {
  const [isDataChanged, setIsDataChanged] = useState(false);

  const [pinned, setPinned] = useState<boolean>(data?.Pinned ?? false);
  const isNewsPinned = data?.id && !editMode ? data?.Pinned ?? false : pinned;

  const [inputValue, setInputValue] = useState<
    Record<Languages, NewsInputFields>
  >({
    [Languages.ENGLISH]: getInitialInputFields(
      Languages.ENGLISH,
      data?.News_translations,
    ),
    [Languages.GERMAN]: getInitialInputFields(
      Languages.GERMAN,
      data?.News_translations,
    ),
  });

  useEffect(() => {
    if (loading) {
      return;
    }

    setInputValue({
      [Languages.ENGLISH]: getInitialInputFields(
        Languages.ENGLISH,
        data?.News_translations,
      ),
      [Languages.GERMAN]: getInitialInputFields(
        Languages.GERMAN,
        data?.News_translations,
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const areFieldsEmpty = NEWS_LANGUAGES.every(language => {
    const values = Object.values(inputValue[language]);
    const textFields = values.filter(
      value => typeof value === 'string',
    ) as string[];
    const plainTexts = textFields.map(value => value.replace(/<[^>]*>/g, ''));

    return plainTexts.every(value => value === '');
  });

  const changeInputValues = useCallback<
    <T extends keyof NewsInputFields>(
      language: Languages,
      key: T,
    ) => (value: NewsInputFields[T]) => void
  >(
    (language, key) => value => {
      setIsDataChanged(true);
      setInputValue(R.assocPath([language, key], value));
    },
    [setInputValue],
  );

  const changeEnabledStatus = useCallback<(language: Languages) => () => void>(
    language => () => {
      changeInputValues(language, 'enabled')(!inputValue[language].enabled);
    },
    [changeInputValues, inputValue],
  );

  return useMemo(
    () => ({
      isDataChanged,
      setIsDataChanged,
      isNewsPinned,
      pinned,
      setPinned,
      inputValue,
      changeInputValues,
      changeEnabledStatus,
      areFieldsEmpty,
    }),
    [
      areFieldsEmpty,
      changeEnabledStatus,
      changeInputValues,
      inputValue,
      isDataChanged,
      isNewsPinned,
      pinned,
    ],
  );
};

export default useNewsInputFields;
