import * as R from 'ramda';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import InfoOverlay from '../../components/InfoOverlay';
import {
  BaseInfoOverlayFragment,
  useFindManyInfoOverlaysQuery,
} from '../../generated/graphql';
import useAppModal from '../../hooks/useAppModal';
import {AppInfoOverlay} from '../../types/infoOverlays';
import {filterArrayAsync} from '../../utils/common';
import {
  displayedInfoOverlaysStore,
  filterByDisplayedTimesAsync,
  mapItemToAppInfoOverlay,
} from '../../utils/infoOverlays';
import {reportError} from '../../utils/loggingHelpers';
import {useAppContext} from './AppContext';

type InfoOverlayId = BaseInfoOverlayFragment['id'];

export type InfoOverlaysContextType = {
  loading: boolean;
  infoOverlays: AppInfoOverlay[];
  InfoOverlayModal: JSX.Element | null;
  showInfoOverlay: (infoOverlay: AppInfoOverlay) => void;
};

const InfoOverlaysContext = createContext<InfoOverlaysContextType | null>(null);

export const useInfoOverlaysContext = (): InfoOverlaysContextType => {
  const infoOverlaysContext = useContext(InfoOverlaysContext);

  if (infoOverlaysContext === null) {
    throw new Error(
      'InfoOverlays context cannot be null, please add a context provider.',
    );
  }

  return infoOverlaysContext;
};

const today = new Date().toISOString();

export const InfoOverlaysContextProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const sessionDisplayedInfoOverlays = useRef<Record<InfoOverlayId, boolean>>(
    {},
  );
  const [currentInfoOverlay, setCurrentInfoOverlay] = useState<
    AppInfoOverlay | undefined
  >(undefined);
  const [infoOverlayQueue, setInfoOverlayQueue] = useState<AppInfoOverlay[]>(
    [],
  );

  const {i18n} = useTranslation();

  const {user} = useAppContext();

  const {hideModal, isModalVisible, showModal} = useAppModal();

  const {data, loading, error, refetch} = useFindManyInfoOverlaysQuery({
    variables: {
      languages_code: i18n.language,
      where: {
        ShowFrom: {lte: today},
        ShowUntil: {gte: today},
      },
    },
    onError: err => reportError('useFindManyInfoOverlaysQuery error', err),
  });

  useEffect(() => {
    refetch({
      force_refresh: true,
      languages_code: i18n.language,
      where: {
        ShowFrom: {lte: today},
        ShowUntil: {gte: today},
      },
    });
    // refetch when user's props change or the language
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, i18n.language]);

  const [infoOverlays, setInfoOverlays] = useState<AppInfoOverlay[]>([]);
  const [filterLoading, setFilterLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!data || !data?.findManyInfoOverlays || error) {
      setFilterLoading(false);
      setInfoOverlays([]);
      return;
    }

    const filterItems = async () => {
      try {
        const items = await filterArrayAsync<BaseInfoOverlayFragment>(
          data.findManyInfoOverlays,
          filterByDisplayedTimesAsync,
        );

        const appItems = items.map<AppInfoOverlay>(mapItemToAppInfoOverlay);

        setInfoOverlays(appItems);
        setFilterLoading(false);
      } catch (err) {
        reportError('error on filter info overlays', err);
        setFilterLoading(false);
        setInfoOverlays([]);
      } finally {
        setFilterLoading(false);
      }
    };

    filterItems();
  }, [data, error, loading]);

  const trackDisplayedInfoOverlay = useCallback<
    (id: InfoOverlayId) => Promise<void>
  >(async id => {
    try {
      sessionDisplayedInfoOverlays.current = R.assoc(
        id,
        true,
        sessionDisplayedInfoOverlays.current,
      );
      await displayedInfoOverlaysStore.increaseDisplayedTimes(id);
    } catch (err) {
      reportError('error during setting displayed times of info overlays', err);
    }
  }, []);

  const closeInfoOverlay = useCallback(() => {
    hideModal();
    setCurrentInfoOverlay(undefined);
  }, [hideModal]);

  const showInfoOverlay = useCallback<(infoOverlay: AppInfoOverlay) => void>(
    infoOverlay => {
      // additionally check if the overlay was shown before
      const shouldDisplay = infoOverlay.id
        ? !sessionDisplayedInfoOverlays.current[infoOverlay.id]
        : true;

      if (!shouldDisplay) {
        return;
      }

      setInfoOverlayQueue(previous => [...previous, infoOverlay]);
    },
    [],
  );

  useEffect(() => {
    if (currentInfoOverlay) {
      return;
    }

    const updateQueue = async () => {
      if (infoOverlayQueue.length > 0) {
        const infoOverlay = infoOverlayQueue[0];

        const shouldDisplay = infoOverlay.id
          ? !sessionDisplayedInfoOverlays.current[infoOverlay.id]
          : true;
        if (shouldDisplay) {
          setCurrentInfoOverlay(infoOverlay);
          infoOverlay.id && (await trackDisplayedInfoOverlay(infoOverlay.id));
          showModal();
        }

        setInfoOverlayQueue(previous => previous.slice(1));
      }
    };
    updateQueue();
  }, [
    currentInfoOverlay,
    infoOverlayQueue,
    showModal,
    trackDisplayedInfoOverlay,
  ]);

  const InfoOverlayModal = useMemo(() => {
    if (!currentInfoOverlay) {
      return null;
    }

    const onClose = () => {
      currentInfoOverlay.onClose?.();
      closeInfoOverlay();
    };

    return (
      <InfoOverlay
        {...currentInfoOverlay}
        isVisible={isModalVisible}
        onClose={onClose}
      />
    );
  }, [closeInfoOverlay, currentInfoOverlay, isModalVisible]);

  const value = useMemo<InfoOverlaysContextType>(
    () => ({
      loading: loading || filterLoading,
      infoOverlays,
      InfoOverlayModal,
      showInfoOverlay,
    }),
    [InfoOverlayModal, filterLoading, infoOverlays, loading, showInfoOverlay],
  );

  return (
    <InfoOverlaysContext.Provider value={value}>
      {children}
    </InfoOverlaysContext.Provider>
  );
};
