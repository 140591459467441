import {DataStatuses} from '../../mindance-libs/types/common';
import {StatusInfoType} from './StatusInfo.props';

const ONLINE_STATUSES = [DataStatuses.PUBLISHED, 'online', true];
const OFFLINE_STATUSES = [
  DataStatuses.DRAFT,
  DataStatuses.ARCHIVED,
  'offline',
  false,
];

export const getStatusInfoType = (status: string | boolean): StatusInfoType => {
  if (ONLINE_STATUSES.includes(status)) {
    return StatusInfoType.ONLINE;
  }

  if (OFFLINE_STATUSES.includes(status)) {
    return StatusInfoType.OFFLINE;
  }

  return StatusInfoType.OFFLINE;
};
