const colors = {
  black100: '#000000',
  black100Alpha95: 'rgba(0, 0, 0, 0.95)',
  black95: 'rgb(6, 17, 19)',
  black95Alpha90: 'rgba(6, 17, 19, 0.9)',
  black95Alpha68: 'rgba(6, 17, 19, 0.68)',
  black95Alpha60: 'rgba(6, 17, 19, 0.60)',
  black95Alpha55: 'rgba(6, 17, 19, 0.55)',
  black95Alpha50: 'rgba(6, 17, 19, 0.5)',
  black95Alpha20: 'rgba(6, 17, 19, 0.2)',
  black95Alpha10: 'rgba(6, 17, 19, 0.1)',
  black90: '#192022',
  black90Alpha90: 'rgba(25, 32, 34, 0.9)',
  black90Alpha80: 'rgba(25, 32, 34, 0.8)',
  brownGray: '#282921',
  blueGreenGray: '#323D41',
  blueGreenGray2: 'rgb(27, 41, 44)',
  blueGreenGray3: 'rgb(13, 33, 36)',
  blueGreenGray3Alpha90: 'rgba(13, 33, 36, 0.9)',
  greenGray: '#5E612B',
  gray: '#6F6F6F',
  lightGray: 'rgb(217, 217, 217)',
  white: '#FFFFFF',
  whiteAlpha95: 'rgba(255, 255, 255, 0.95)',
  whiteAlpha90: 'rgba(255, 255, 255, 0.9)',
  whiteAlpha80: 'rgba(255, 255, 255, 0.8)',
  whiteAlpha75: 'rgba(255, 255, 255, 0.75)',
  whiteAlpha70: 'rgba(255, 255, 255, 0.7)',
  whiteAlpha60: 'rgba(255, 255, 255, 0.6)',
  whiteAlpha50: 'rgba(255, 255, 255, 0.5)',
  whiteAlpha40: 'rgba(255, 255, 255, 0.4)',
  whiteAlpha30: 'rgba(255, 255, 255, 0.3)',
  whiteAlpha20: 'rgba(255, 255, 255, 0.2)',
  whiteAlpha13: 'rgba(255, 255, 255, 0.13)',
  whiteAlpha10: 'rgba(255, 255, 255, 0.1)',
  whiteAlpha09: 'rgba(255, 255, 255, 0.09)',
  whiteAlpha06: 'rgba(255, 255, 255, 0.06)',
  alert: '#F5565F',
  message: '#00a61c',
  darkBlue: 'rgb(0, 137, 176)',
  darkBlueAlpha90: 'rgba(0, 137, 176, 0.9)',
  darkBlueAlpha80: 'rgba(0, 137, 176, 0.8)',
  darkBlueAlpha60: 'rgba(0, 137, 176, 0.6)',
  blue: 'rgb(83, 169, 189)',
  blueAlpha90: 'rgba(83, 169, 189, 0.9)',
  blueAlpha80: 'rgba(83, 169, 189, 0.8)',
  blueAlpha70: 'rgba(83, 169, 189, 0.7)',
  blueAlpha60: 'rgba(83, 169, 189, 0.6)',
  blueAlpha50: 'rgba(83, 169, 189, 0.5)',
  blueAlpha40: 'rgba(83, 169, 189, 0.4)',
  blueAlpha30: 'rgba(83, 169, 189, 0.3)',
  blueAlpha20: 'rgba(83, 169, 189, 0.2)',
  blueAlpha10: 'rgba(83, 169, 189, 0.1)',
  raspberry: '#EC0D6D',
  bitterLemon: '#C8D300',
  darkOrange: 'rgb(252, 138, 5)',
  darkOrangeAlpha60: 'rgba(252, 138, 5, 0.6)',
  persianPlum: 'rgb(110, 19, 32)',
  persianPlumAlpha60: 'rgba(110, 19, 32, 0.6)',
  transparent: 'transparent',

  pmeDarkBlue: 'rgb(44, 92, 133)',
  pmeDarkBlueAlpha50: 'rgba(44, 92, 133, 0.5)',

  backgroundPrimary: 'rgb(202, 209, 67)',
  backgroundPrimaryAlpha70: 'rgba(202, 209, 67, 0.7)',
  backgroundPrimaryAlpha40: 'rgba(202, 209, 67, 0.4)',
  backgroundSecondary: '#305964',
  backgroundTertiary: '#EAB6C3',
  backgroundBackdrop: '#000000',

  barChart: 'rgb(202, 209, 67)',
  pieChart1: 'rgba(202, 209, 67, 1)',
  pieChart2: 'rgba(234, 236, 200, 1)',
  pieChart3: 'rgba(102, 107, 0, 1)',

  timifyColor: 'rgb(80, 90, 94)',
};

export default colors;
