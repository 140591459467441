import humps from 'humps';
import {Platform} from 'react-native';
import Config from '../../../utils/config';
import {
  ChangePasswordData,
  ChangePasswordResponse,
  DeleteAccountResponse,
  EmailVerificationResponse,
  ForgetPassword,
  ForgetPasswordResponse,
  LoginResponse,
  LogoutResponse,
  MindManagerEventtoolSSOLinkResponse,
  RegisterData,
  RegisterResponse,
  ResendVerificationToken,
  ResetPassword,
  ResetPasswordResponse,
  ResetPasswordValidation,
  ResetPasswordValidationResponse,
  SystemCodes,
  ValidateUsersRolesResponse,
  VerifyEmailVerification,
} from '../../types/auth';
import {devError, reportError} from '../loggingHelpers';
import queryString from 'query-string';

const AUTH_URL = Config.AUTH_URL;

const CLIENT_ID = Platform.select({
  web: Config.MIND_MANAGER_CLIENT_ID,
  default: Config.MOBILE_APP_CLIENT_ID,
});

export class AuthRequests {
  private authUrl: string;

  private grantType = 'password';

  constructor(authUrl: string) {
    this.authUrl = authUrl;
  }

  private sendRequest = async (
    endpoint: string,
    {
      body,
      headers,
      queryParams,
    }: {
      headers?: Record<string, string>;
      body?: Record<string, any>;
      queryParams?: Record<string, string | number | boolean>;
    },
    method: string = 'POST',
  ) => {
    const url = new URL(`${this.authUrl}/${endpoint}`);
    if (queryParams) {
      url.search = queryString.stringify(queryParams);
    }

    const result = await fetch(url.toString(), {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      body: body ? JSON.stringify(humps.decamelizeKeys(body)) : undefined,
    });

    const data = await result.json();

    const formattedData = humps.camelizeKeys(data);

    if (result.ok) {
      return formattedData.data ?? formattedData;
    }

    throw formattedData;
  };

  // TODO: remove this once 'reset-password/validate' response is fixed from the BE
  private sendValidationRequest = async (
    endpoint: string,
    {
      body,
      headers,
    }: {headers?: Record<string, string>; body?: Record<string, any>},
    method: string = 'POST',
  ) => {
    const result = await fetch(`${this.authUrl}/${endpoint}`, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      body: body ? JSON.stringify(humps.decamelizeKeys(body)) : undefined,
    });

    const data = await result.json();

    const formattedData = humps.camelizeKeys(data);

    if (result.ok) {
      return formattedData as any;
    }

    throw formattedData;
  };

  public sendLoginRequest = async (
    email: string,
    password: string,
    activationCode?: string,
  ): Promise<LoginResponse> => {
    try {
      return await this.sendRequest('login', {
        body: {
          username: email,
          password,
          grantType: this.grantType,
          client_id: CLIENT_ID,
          ...(activationCode ? {activation_code: activationCode} : {}),
        },
      });
    } catch (error) {
      reportError('sendLoginRequest', error);
      throw error;
    }
  };

  public sendRefreshTokenRequest = async (
    refreshToken: string,
  ): Promise<LoginResponse> => {
    try {
      return await this.sendRequest('refresh-token', {
        body: {refreshToken},
      });
    } catch (error) {
      reportError('sendRefreshTokenRequest', error);
      throw error;
    }
  };

  public sendLogoutRequest = async (
    accessToken: string,
    refreshToken: string,
  ): Promise<LogoutResponse> => {
    try {
      return await this.sendRequest('logout', {
        headers: {Authorization: `Bearer ${accessToken}`},
        body: {refreshToken},
      });
    } catch (error) {
      reportError('sendLogoutRequest', error);
      throw error;
    }
  };

  public sendMindManagerEventtoolSSOLinkRequest = async (
    accessToken: string,
    consumerSubscriptionId: number,
  ): Promise<MindManagerEventtoolSSOLinkResponse> => {
    try {
      return await this.sendRequest(
        'mind-manager-eventtool-sso-link',
        {
          headers: {Authorization: `Bearer ${accessToken}`},
          queryParams: {consumerSubscriptionId},
        },
        'GET',
      );
    } catch (error) {
      reportError('sendMindManagerEventtoolSSOLinkRequest', error);
      throw error;
    }
  };

  public sendRegisterRequest = async (
    data: RegisterData,
  ): Promise<RegisterResponse> => {
    try {
      return await this.sendRequest('register', {
        body: data,
      });
    } catch (error) {
      reportError('sendRegisterRequest', error);
      throw error;
    }
  };

  public forgetPassword = async (
    data: ForgetPassword,
  ): Promise<ForgetPasswordResponse> => {
    try {
      return await this.sendRequest('reset-password/request', {
        body: data,
      });
    } catch (error) {
      reportError('forgetPasswordError', error);
      throw error;
    }
  };

  public validateResetPasswordToken = async (
    data: ResetPasswordValidation,
  ): Promise<ResetPasswordValidationResponse> => {
    try {
      return await this.sendRequest('reset-password/validate', {
        body: data,
      });
    } catch (error) {
      reportError('validateResetPasswordToken', error);
      throw error;
    }
  };

  public resetPassword = async (
    data: ResetPassword,
  ): Promise<ResetPasswordResponse> => {
    return await this.sendValidationRequest(
      'reset-password/update',
      {
        body: data,
      },
      'PUT',
    );
  };

  public verifyEmailVerificationToken = async (
    data: VerifyEmailVerification,
  ): Promise<EmailVerificationResponse> => {
    try {
      return await this.sendValidationRequest('verify-verification-token', {
        body: data,
      });
    } catch (error) {
      reportError('verifyEmailVerificationToken', error);
      throw error;
    }
  };

  public resendVerificationToken = async (data: ResendVerificationToken) => {
    try {
      return await this.sendRequest('resend-verification-token', {
        body: data,
      });
    } catch (error) {
      reportError('resendVerificationTokenError', error);
      throw error;
    }
  };

  public sendDeleteAccountRequest = async (
    accessToken: string,
  ): Promise<DeleteAccountResponse> => {
    try {
      const result = await this.sendRequest('delete-account', {
        headers: {Authorization: `Bearer ${accessToken}`},
      });

      return {success: result?.systemCode === SystemCodes.SUCCESS};
    } catch (error) {
      devError('sendDeleteAccountRequest', error);
      throw error;
    }
  };

  public sendChangePasswordRequest = async (
    accessToken: string,
    data: ChangePasswordData,
  ): Promise<ChangePasswordResponse> => {
    try {
      return await this.sendRequest(
        'change-password',
        {
          headers: {Authorization: `Bearer ${accessToken}`},
          body: data,
        },
        'PUT',
      );
    } catch (error) {
      devError('sendChangePasswordRequest', error);
      throw error;
    }
  };

  public validateUsersRoles = async (
    accessToken: string,
    refreshToken: string,
  ): Promise<ValidateUsersRolesResponse> => {
    try {
      return await this.sendRequest(
        'validate-users-roles',
        {
          headers: {Authorization: `Bearer ${accessToken}`},
          body: {refreshToken},
        },
        'POST',
      );
    } catch (error) {
      devError('validateUsersRoles', error);
      throw error;
    }
  };
}

const authRequests = new AuthRequests(AUTH_URL ?? '');

export default authRequests;
