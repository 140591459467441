export type ChangePasswordData = {
  currentPassword: string;
  password: string;
  confirmPassword: string;
};

export const INITIAL_FORM_VALUES: ChangePasswordData = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
};
