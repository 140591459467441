import React, {useMemo} from 'react';
import styles from './SidebarNavItem.module.scss';
import Button from '../../../Button/Button';
import classNames from 'classnames';
import {AnchorTargetAttribute, ButtonStyle} from '../../../Button/Button.props';
import Color from 'color';
import colors from '../../../../mindance-libs/theme/colors';

export type NavItem = {
  id: string;
  routeKey: string;
  label: string;
  shortTitle: string;
  iconKey: string;
  highlightColor?: string;
};

type Props = {
  item: NavItem;
  isCurrent: boolean;
};

const ACTIVE_COLOR = colors.white;
const INACTIVE_COLOR = Color(ACTIVE_COLOR).alpha(0.5).toString();

const getColors = (highlightColor: string | undefined) => {
  const active = highlightColor ? highlightColor : ACTIVE_COLOR;

  const inactive = highlightColor
    ? Color(highlightColor).alpha(0.7).toString()
    : INACTIVE_COLOR;

  return {active, inactive};
};

const SidebarNavItem = ({item, isCurrent}: Props) => {
  const isHighlighted = !!item.highlightColor;
  const {active, inactive} = getColors(item.highlightColor);

  const style = useMemo(
    () => ({
      color: isCurrent ? active : inactive,
    }),
    [isCurrent, active, inactive],
  );

  return (
    <li className={styles['sidebar-nav-item']} key={item.id}>
      <Button
        className={classNames(styles['sidebar-nav-item__button'], {
          [styles['sidebar-nav-item__button--selected']]: isCurrent,
        })}
        buttonStyle={ButtonStyle.INLINE}
        anchor={{
          href: item.routeKey,
          title: item.label,
          target: AnchorTargetAttribute.SELF,
        }}>
        <i
          className={classNames(
            item.iconKey,
            styles['sidebar-nav-item__icon'],
            {
              [styles['sidebar-nav-item__icon--selected']]: isCurrent,
              [styles['sidebar-nav-item__icon--highlighted']]: isHighlighted,
            },
          )}
          style={style}
        />
        <p
          className={classNames(styles['sidebar-nav-item__title'], {
            [styles['sidebar-nav-item__title--selected']]: isCurrent,
            [styles['sidebar-nav-item__title--highlighted']]: isHighlighted,
          })}
          style={style}>
          {item.shortTitle}
        </p>
      </Button>
    </li>
  );
};

export default React.memo(SidebarNavItem);
