import {useMemo} from 'react';
import {useAppContext} from '../store/contexts/AppContext';
import {CustomTagGroup} from '../types/common';
import {getFormattedTagsGroups} from '../utils/tags';
import useSelectedOnboardingTags from './useSelectedOnboardingTags';

const useOnboardingTags = () => {
  const {onboardingScreenSetup, screenSetupLoading: loading} = useAppContext();

  const tagGroups = useMemo<CustomTagGroup[]>(
    () =>
      getFormattedTagsGroups(
        onboardingScreenSetup?.GeneralSetting_Onboarding_GeneralSettingToGeneralSetting,
      ),
    [
      onboardingScreenSetup?.GeneralSetting_Onboarding_GeneralSettingToGeneralSetting,
    ],
  );

  const {selectTag, selectedTags, selectedTagsRecord} =
    useSelectedOnboardingTags({loading, tagGroups});

  return useMemo(
    () => ({
      loading,
      tagGroups,
      selectTag,
      selectedTags,
      selectedTagsRecord,
    }),
    [loading, selectTag, selectedTags, selectedTagsRecord, tagGroups],
  );
};

export default useOnboardingTags;
