import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ActivityIndicator} from 'react-native-paper';
import ControlBottomButtons from '../../../components/ControlBottomButtons/ControlBottomButtons';
import ContactUs, {
  TitleVariants,
} from '../../../mindance-libs/components/ContactUs';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import useErrorMessage from '../../../mindance-libs/hooks/useErrorMessage';
import MindanceBigLogo from '../../../mindance-libs/icons/MindanceBigLogo';
import {useAuthContext} from '../../../mindance-libs/store/contexts/AuthContext';
import {SystemCodes, TokenStatus} from '../../../mindance-libs/types/auth';
import {reportError} from '../../../mindance-libs/utils/loggingHelpers';
import {LastPathSegment} from '../../../navigation/NavigationTypes';
import {RouteKeys} from '../../../navigation/RouteKeys';
import useTypedNavigate from '../../../navigation/typed/useTypedNavigate';
import useTypedParams from '../../../navigation/typed/useTypedParams';
import styles from './page.module.scss';
import Request from './request/page';
import Success from './success/page';

const SUCCESS_ENDPOINT: LastPathSegment<RouteKeys.EmailConfirmationSuccess> =
  'success';
const REQUEST_ENDPOINT: LastPathSegment<RouteKeys.EmailConfirmationRequest> =
  'request';

export default function Page() {
  const {t} = useTranslation();
  const {showMessage, error} = useErrorMessage();

  const {verifyEmailVerificationToken} = useAuthContext({onError: showMessage});

  const {token} = useTypedParams<RouteKeys.EmailConfirmation>();

  const navigate = useTypedNavigate();

  const [accessState, setAccessState] = useState<
    SystemCodes | TokenStatus | undefined
  >();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      !token ||
      ([SUCCESS_ENDPOINT, REQUEST_ENDPOINT] as string[]).includes(token)
    ) {
      return;
    }

    const fetchAccessState = async () => {
      setLoading(true);
      try {
        const response = await verifyEmailVerificationToken(token);

        if (response.systemCode === SystemCodes.SUCCESS) {
          return setAccessState(response.data.state);
        }

        setAccessState(response.systemCode);
      } catch (e) {
        reportError('fetchAccessState verifyEmailVerificationToken error', e);
      } finally {
        setLoading(false);
      }
    };

    fetchAccessState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LinkExpiredContent = useMemo(
    () => (
      <>
        <div className={styles.email_confirmation__space} />
        <h1 className={styles.email_confirmation__title}>
          {t('auth.registation_not_successful_title')}
        </h1>
        <span className={styles.email_confirmation__description}>
          {t('auth.registation_not_successful_desc')}
        </span>
        <div className={styles.email_confirmation__space} />
        <div className={styles.email_confirmation__button}>
          <ControlBottomButtons
            submitLink={RouteKeys.EmailConfirmationRequest}
            showNextIcon
            submitButtonLabel={t('auth.request_link_again_button')}
            submitButtonTestID="registration.requestAgainButton"
            showReturnButton={false}
          />
        </div>
        <div className={styles.email_confirmation__space} />
        <div className={styles.email_confirmation__contactUs}>
          <ContactUs variant={TitleVariants.YOU_NEED_HELP} />
        </div>
      </>
    ),
    [t],
  );

  const TechnicalIssuesContent = useMemo(
    () => (
      <>
        <div className={styles.email_confirmation__space} />
        <h1 className={styles.email_confirmation__title}>
          {t('auth.registration_not_successful_tecnical_title')}
        </h1>
        <span className={styles.email_confirmation__description}>
          {t('auth.registration_not_successful_tecnical_desc')}
        </span>
        <div className={styles.email_confirmation__space} />
        <div className={styles.email_confirmation__contactUs}>
          <ContactUs variant={TitleVariants.DEFAULT} />
        </div>
      </>
    ),
    [t],
  );

  const SuccessContent = useMemo(
    () => (
      <>
        <div className={styles.email_confirmation__space} />
        <h1 className={styles.email_confirmation__title}>
          {t('auth.registration_was_successful_title')}
        </h1>
        <span className={styles.email_confirmation__description}>
          {t('auth.registration_was_successful_desc_1')}
        </span>
        <span className={styles.email_confirmation__description}>
          {t('auth.registration_was_successful_desc_2')}
        </span>
        <div className={styles.email_confirmation__space} />
        <div className={styles.email_confirmation__contactUs}>
          <ContactUs variant={TitleVariants.AUTH_PROBLEM} />
        </div>
      </>
    ),
    [t],
  );

  const Content = useMemo(() => {
    switch (accessState) {
      case 'EXPIRED':
        return LinkExpiredContent;

      case SystemCodes.SUCCESS:
        return SuccessContent;

      default:
        return TechnicalIssuesContent;
    }
  }, [LinkExpiredContent, SuccessContent, TechnicalIssuesContent, accessState]);

  if (!token) {
    navigate({to: RouteKeys.Login, params: {}}, {replace: true});
  }

  if (token === REQUEST_ENDPOINT) {
    return <Request />;
  }

  if (token === SUCCESS_ENDPOINT) {
    return <Success />;
  }

  if (loading) {
    return (
      <ScreenBase error={error}>
        <div className={styles.loading}>
          <ActivityIndicator />
        </div>
      </ScreenBase>
    );
  }

  return (
    <ScreenBase error={error}>
      <div className={styles.email_confirmation__container}>
        <div className={styles.email_confirmation__content}>
          <div className={styles.email_confirmation__logo}>
            <MindanceBigLogo />
          </div>
          {Content}
        </div>
      </div>
    </ScreenBase>
  );
}
