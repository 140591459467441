import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useAuthContext} from '../mindance-libs/store/contexts/AuthContext';
import {isAccessExpiredError} from '../mindance-libs/utils/auth/authErrors';
import filesRequests from '../mindance-libs/utils/filesRequests';
import {reportError} from '../mindance-libs/utils/loggingHelpers';

const useUploadFile = (options?: {
  onError?: (error: string) => void;
  onResetError?: () => void;
}) => {
  const {accessToken, handleAccessTokenExpirationError} = useAuthContext();

  const {t} = useTranslation();

  const handleError = useCallback<(error: any) => void>(
    err => {
      reportError('upload file error', err);
      options?.onError?.(t('errors.something_went_wrong') ?? undefined);
    },
    [options, t],
  );

  const uploadFile = useCallback<(file: File) => Promise<string | undefined>>(
    async file => {
      if (!accessToken) {
        return;
      }

      const operation = async (token: string) => {
        const result = await filesRequests.uploadFile(file, {
          accessToken: token,
        });
        options?.onResetError?.();
        return result.id;
      };

      try {
        return operation(accessToken);
      } catch (err) {
        if (!isAccessExpiredError(err)) {
          handleError(err);
          return;
        }

        return handleAccessTokenExpirationError(err, operation);
      }
    },
    [accessToken, handleAccessTokenExpirationError, handleError, options],
  );

  return uploadFile;
};

export default useUploadFile;
