import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 1,
    backgroundColor: Platform.select({
      default: colors.whiteAlpha06,
      web: colors.whiteAlpha30,
    }),
  },
});

export default styles;
