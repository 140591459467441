import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, View, ViewProps, ViewStyle} from 'react-native';
import AppButton from '../AppButton';
import styles from './styles';

type Props = ViewProps & {
  returnButtonLabel?: string | null;
  submitButtonLabel?: string | null;
  showNextIcon?: boolean;
  onSubmit: () => void;
  onReturn: () => void;
  submitButtonDisabled?: boolean;
  returnButtonTestID?: string;
  submitButtonTestID?: string;
  showReturnButton?: boolean;
  submitButtonLoading?: boolean;
};

const ControlBottomButtons: React.FC<Props> = ({
  onSubmit,
  onReturn,
  returnButtonLabel,
  submitButtonDisabled = false,
  submitButtonLabel,
  showNextIcon = true,
  submitButtonTestID,
  returnButtonTestID,
  showReturnButton = true,
  submitButtonLoading = false,
  style,
  ...props
}) => {
  const {t} = useTranslation();

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, style],
    [style],
  );

  return (
    <View style={containerStyle} {...props}>
      {showReturnButton && (
        <View style={styles.space}>
          <AppButton
            mode="text"
            style={styles.leftButton}
            onPress={onReturn}
            testID={returnButtonTestID}>
            {returnButtonLabel ?? t('common.return')}
          </AppButton>
        </View>
      )}
      <View style={styles.space}>
        <AppButton
          loading={submitButtonLoading}
          disabled={submitButtonDisabled}
          showNextIcon={showNextIcon}
          onPress={onSubmit}
          testID={submitButtonTestID}>
          {submitButtonLabel ?? t('common.next')}
        </AppButton>
      </View>
    </View>
  );
};

export default React.memo(ControlBottomButtons);
