import {isApolloError} from '@apollo/client';
import * as R from 'ramda';
import {AuthError} from '../../types/auth';

export enum AuthErrorMessages {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  USER_IS_ALREADY_REGISTERED = 'USER_IS_ALREADY_REGISTERED',
  PASSWORD_IS_INVALID = 'PASSWORD_IS_INVALID',
  USER_ALREADY_VERIFIED = 'USER_ALREADY_VERIFIED',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  CURRENT_PASSWORD_IS_INCORRECT = 'CURRENT_PASSWORD_IS_INCORRECT',
  EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
  ACCESS_DENIED = 'ACCESS_DENIED',
  NOT_FOUND = 'NOT_FOUND',
  ACTIVATION_CODE_DOES_NOT_EXISTS = 'ACTIVATION_CODE_DOES_NOT_EXISTS',
  EXCEED_LIMIT_OF_AMOUNT_ACTIVATIONS = 'EXCEED_LIMIT_OF_AMOUNT_ACTIVATIONS',
  ACTIVATION_FROM_DOES_NOT_MATCH = 'ACTIVATION_FROM_DOES_NOT_MATCH',
  ACTIVATION_UNTIL_DOES_NOT_MATCH = 'ACTIVATION_UNTIL_DOES_NOT_MATCH',
  ALREADY_SUBSCRIBED = 'ALREADY_SUBSCRIBED',
}

const messagesErrorMapper: Record<AuthErrorMessages, string> = {
  [AuthErrorMessages.INVALID_CREDENTIALS]: 'Invalid user credentials',
  [AuthErrorMessages.USER_IS_ALREADY_REGISTERED]:
    'User exists with same username',
  [AuthErrorMessages.PASSWORD_IS_INVALID]: 'Password policy not met',
  [AuthErrorMessages.USER_ALREADY_VERIFIED]: 'User already verified',
  [AuthErrorMessages.USER_NOT_FOUND]: 'USER_NOT_FOUND',
  [AuthErrorMessages.CURRENT_PASSWORD_IS_INCORRECT]:
    'Current password is incorrect',
  [AuthErrorMessages.EMAIL_ALREADY_EXISTS]: 'Email already exists',
  [AuthErrorMessages.ACCESS_DENIED]: 'Access denied',
  [AuthErrorMessages.NOT_FOUND]: 'NOT_FOUND',
  [AuthErrorMessages.ACTIVATION_CODE_DOES_NOT_EXISTS]:
    'ACTIVATION_CODE_DOES_NOT_EXISTS',
  [AuthErrorMessages.EXCEED_LIMIT_OF_AMOUNT_ACTIVATIONS]:
    'EXCEED_LIMIT_OF_AMOUNT_ACTIVATIONS',
  [AuthErrorMessages.ACTIVATION_FROM_DOES_NOT_MATCH]:
    'ACTIVATION_FROM_DOES_NOT_MATCH',
  [AuthErrorMessages.ACTIVATION_UNTIL_DOES_NOT_MATCH]:
    'ACTIVATION_UNTIL_DOES_NOT_MATCH',
  [AuthErrorMessages.ALREADY_SUBSCRIBED]: 'ALREADY_SUBSCRIBED',
};

export const isAuthError = (error: any): error is AuthError =>
  error &&
  error?.hasOwnProperty('systemCode') &&
  error?.hasOwnProperty('message');

export const getAuthErrorMessageKind = (
  error: AuthError,
): AuthErrorMessages | undefined => {
  if (!error.message) {
    return;
  }

  const authError = R.toPairs(messagesErrorMapper).find(
    ([_, message]) => !!error.message.find(value => value.includes(message)),
  );

  return authError && authError[0]
    ? (authError[0] as AuthErrorMessages)
    : undefined;
};

const UNAUTHORIZED_CODE = 'UNAUTHORIZED';
const UNAUTHORIZED_MESSAGES = [
  'Unauthorized',
  'Token is not active',
  'Invalid refresh token',
];

export const isAccessExpiredError = (error: any): boolean => {
  if (isAuthError(error)) {
    return (
      error.systemCode.toUpperCase() === UNAUTHORIZED_CODE ||
      error.message.some(message => UNAUTHORIZED_MESSAGES.includes(message))
    );
  }

  if (error && isApolloError(error)) {
    return error.message.toUpperCase() === UNAUTHORIZED_CODE;
  }

  return false;
};

export const isInvalidClientInRefreshToken = (error: any): boolean => {
  if (isAuthError(error)) {
    return error.message.some(
      message =>
        message ===
        "Invalid refresh token. Token client and authorized client don't match",
    );
  }

  return false;
};
