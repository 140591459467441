import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform} from 'react-native';
import usePersistedAuthTokens, {
  OnTokensUpdate,
} from '../../hooks/usePersistedAuthTokens';
import {
  AuthCredentials,
  AuthErrorType,
  OnChangePassword,
  OnDeleteAccount,
  OnEmailVerification,
  OnError,
  OnForgetPassword,
  OnHandleAccessTokenError,
  OnLogin,
  OnLogout,
  OnObtainMindManagerEventtoolSSOLink,
  OnObtainNewTokens,
  OnRegister,
  OnResendVerification,
  OnResetPassword,
  OnResetPasswordValidate,
  OnValidateUsersRoles,
  SystemCodes,
  UsersRolesUpdateType,
} from '../../types/auth';
import authCredentialsStore from '../../utils/auth/authCredentialsStore';
import {
  AuthErrorMessages,
  getAuthErrorMessageKind,
  isAccessExpiredError,
  isAuthError,
  isInvalidClientInRefreshToken,
} from '../../utils/auth/authErrors';
import authRequests from '../../utils/auth/authRequests';
import {DEFAULT_FUNCTION_HANDLER} from '../../utils/constants';
import {getLanguage} from '../../utils/i18next/helpers';
import {devError, reportError} from '../../utils/loggingHelpers';
import {PasswordManager} from '../../utils/passwordManager';

export type AuthContextType = {
  isLoggedIn: boolean;
  isLoading: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  login: OnLogin;
  register: OnRegister;
  forgetPassword: OnForgetPassword;
  validateResetPassword: OnResetPasswordValidate;
  verifyEmailVerificationToken: OnEmailVerification;
  obtainMindManagerEventtoolSSOLink: OnObtainMindManagerEventtoolSSOLink;
  resetPassword: OnResetPassword;
  resendVerificationToken: OnResendVerification;
  logout: OnLogout;
  obtainNewTokens: OnObtainNewTokens;
  deleteAccount: OnDeleteAccount;
  updateTokens: OnTokensUpdate;
  changePassword: OnChangePassword;
  setOnError: React.Dispatch<React.SetStateAction<OnError>>;
  handleAccessTokenExpirationError: OnHandleAccessTokenError;
  validateUsersRoles: OnValidateUsersRoles;
};

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuthContext = (options?: {
  onError?: OnError;
}): AuthContextType => {
  const authContext = useContext(AuthContext);

  if (authContext === null) {
    throw new Error(
      'Auth context cannot be null, please add a context provider.',
    );
  }

  const {setOnError} = authContext;
  const onError = options?.onError ?? DEFAULT_FUNCTION_HANDLER;

  useEffect(() => {
    if (onError !== DEFAULT_FUNCTION_HANDLER) {
      setOnError(() => onError);
      return () => setOnError(DEFAULT_FUNCTION_HANDLER);
    }
  }, [onError, setOnError]);

  return authContext;
};

export const AuthContextProvider: FC<PropsWithChildren> = ({children}) => {
  const [onError, setOnError] = useState<OnError>(DEFAULT_FUNCTION_HANDLER);

  const {
    accessToken,
    refreshToken,
    removeTokens,
    updateTokens,
    accessTokenLoading,
    refreshTokenLoading,
  } = usePersistedAuthTokens();

  const isLoggedIn = !!accessToken && !!refreshToken;
  const isLoading = accessTokenLoading || refreshTokenLoading;

  const isSubmittingLogin = useRef(false);

  const {t, i18n} = useTranslation();
  const removeCredentials = useCallback(async () => {
    try {
      await authCredentialsStore.removeCredentials();
    } catch (error) {
      reportError('removeCredentials error', error);
    }
  }, []);

  const handleCredentials = useCallback<
    (credentials?: AuthCredentials) => Promise<void>
  >(async credentials => {
    try {
      if (!isSubmittingLogin.current) {
        credentials && (await authCredentialsStore.setCredentials(credentials));
      }
    } catch (error) {
      reportError('handleFailedVerificationLogin error', error);
    }
  }, []);

  const handleError = useCallback<(error: any) => string | undefined>(
    error => {
      try {
        const generalErrorMessage = t('errors.something_went_wrong');

        if (!isAuthError(error)) {
          onError(generalErrorMessage, {errorType: 'unknown'});
          return generalErrorMessage;
        }

        const errorMessageKind = getAuthErrorMessageKind(error);

        if (!errorMessageKind) {
          onError(generalErrorMessage, {errorType: 'unknown'});
          return generalErrorMessage;
        }

        const errorMessage = {
          [AuthErrorMessages.INVALID_CREDENTIALS]: 'auth.login_failed',
          [AuthErrorMessages.USER_IS_ALREADY_REGISTERED]: 'auth.email_is_taken',
          [AuthErrorMessages.PASSWORD_IS_INVALID]: 'common.info_is_not_correct',
          [AuthErrorMessages.USER_ALREADY_VERIFIED]:
            'auth.user_already_verified',
          [AuthErrorMessages.USER_NOT_FOUND]: 'auth.user_not_found',
          [AuthErrorMessages.CURRENT_PASSWORD_IS_INCORRECT]:
            'settings.password_is_incorrect',
          [AuthErrorMessages.EMAIL_ALREADY_EXISTS]: 'auth.email_is_taken',
          [AuthErrorMessages.ACCESS_DENIED]: 'auth.access_denied',
          [AuthErrorMessages.NOT_FOUND]: 'errors.something_went_wrong',
          [AuthErrorMessages.ACTIVATION_CODE_DOES_NOT_EXISTS]:
            'auth.invalid_activation_code',
          [AuthErrorMessages.EXCEED_LIMIT_OF_AMOUNT_ACTIVATIONS]:
            'errors.activation_code_is_not_valid',
          [AuthErrorMessages.ACTIVATION_FROM_DOES_NOT_MATCH]:
            'errors.activation_code_is_not_valid',
          [AuthErrorMessages.ACTIVATION_UNTIL_DOES_NOT_MATCH]:
            'errors.activation_code_is_not_valid',
          [AuthErrorMessages.ALREADY_SUBSCRIBED]:
            'errors.activation_code_already_applied',
        }[errorMessageKind];

        const errorType: AuthErrorType = {
          [AuthErrorMessages.INVALID_CREDENTIALS]: 'credentials',
          [AuthErrorMessages.USER_IS_ALREADY_REGISTERED]: 'credentials',
          [AuthErrorMessages.PASSWORD_IS_INVALID]: 'credentials',
          [AuthErrorMessages.USER_ALREADY_VERIFIED]: 'credentials',
          [AuthErrorMessages.USER_NOT_FOUND]: 'credentials',
          [AuthErrorMessages.CURRENT_PASSWORD_IS_INCORRECT]: 'credentials',
          [AuthErrorMessages.EMAIL_ALREADY_EXISTS]: 'credentials',
          [AuthErrorMessages.ACCESS_DENIED]: 'credentials',
          [AuthErrorMessages.NOT_FOUND]: 'activation_code',
          [AuthErrorMessages.ACTIVATION_CODE_DOES_NOT_EXISTS]:
            'activation_code',
          [AuthErrorMessages.EXCEED_LIMIT_OF_AMOUNT_ACTIVATIONS]:
            'activation_code',
          [AuthErrorMessages.ACTIVATION_FROM_DOES_NOT_MATCH]: 'activation_code',
          [AuthErrorMessages.ACTIVATION_UNTIL_DOES_NOT_MATCH]:
            'activation_code',
          [AuthErrorMessages.ALREADY_SUBSCRIBED]: 'activation_code',
        }[errorMessageKind] as AuthErrorType;

        if (!errorMessage || !t(errorMessage)) {
          return;
        }

        onError(t(errorMessage), {errorType});
        return errorMessage;
      } catch (e) {
        reportError('handleError error', e);
      }
    },
    [onError, t],
  );

  const handleLoginError = useCallback<
    (
      error: any,
      options: {credentials: AuthCredentials; showError?: boolean},
    ) => Promise<void>
  >(
    async (error, options) => {
      try {
        if (isAuthError(error)) {
          if (!error.message.includes('Account is not fully set up')) {
            handleError(error);
            return;
          }

          await handleCredentials(options?.credentials);
        }

        if (options.showError) {
          const message =
            (Array.isArray(error.message) ? error.message[0] : error.message) ??
            t('errors.something_went_wrong');
          onError(message);
          reportError('handleLoginError', error);
        }
      } catch (err) {
        reportError('handleLoginError error', err);
      }
    },
    [handleCredentials, handleError, onError, t],
  );

  const login = useCallback<OnLogin>(
    async (email, password, options) => {
      try {
        isSubmittingLogin.current = true;
        const result = await authRequests.sendLoginRequest(
          email,
          password,
          options?.activationCode,
        );
        await updateTokens(result.accessToken, result.refreshToken);

        isSubmittingLogin.current = false;
        await removeCredentials();

        PasswordManager.savePassword(email, password);

        if (options?.onSuccess) {
          await options?.onSuccess(email);
        }
      } catch (error) {
        isSubmittingLogin.current = false;
        devError('login error', error);
        if (options?.onError) {
          options?.onError(error);
          return;
        }

        handleLoginError(error, {
          credentials: {email, password},
          showError: options?.showError,
        });
      }
    },
    [handleLoginError, removeCredentials, updateTokens],
  );

  const register = useCallback<OnRegister>(
    async (data, options) => {
      try {
        const response = await authRequests.sendRegisterRequest(data);

        if (
          response.user &&
          response.createdInKeycloak &&
          response.createdInUserService &&
          response.sendVerificationEmail
        ) {
          await handleCredentials({email: data.email, password: data.password});

          PasswordManager.savePassword(data.email, data.password);

          if (options?.onSuccess) {
            await options?.onSuccess(data.email);
          }

          return {success: true};
        }

        devError('register error', response);
        return {success: false};
      } catch (error) {
        reportError('register error', error);
        handleError(error);
        return {success: false};
      }
    },
    [handleCredentials, handleError],
  );

  const forgetPassword = useCallback<OnForgetPassword>(
    async email => {
      try {
        const result = await authRequests.forgetPassword({
          email,
          language: getLanguage(i18n.language),
        });

        return result;
      } catch (error) {
        reportError('forgetPasswordError', error);
        handleError(error);
        return {success: false};
      }
    },
    [handleError, i18n.language],
  );

  const validateResetPassword = useCallback<OnResetPasswordValidate>(
    async token => {
      try {
        const result = await authRequests.validateResetPasswordToken({token});
        return result;
      } catch (error) {
        reportError('validateResetPassword', error);
        handleError(error);
        return {state: 'FAIL'};
      }
    },
    [handleError],
  );

  const verifyEmailVerificationToken = useCallback<OnEmailVerification>(
    async token => {
      try {
        const result = await authRequests.verifyEmailVerificationToken({token});

        return result;
      } catch (error) {
        reportError('verifyEmailVerificationToken', error);
        handleError(error);
        return {
          systemCode: SystemCodes.ERROR,
          message: (error as any)?.message ?? ['error'],
        };
      }
    },
    [handleError],
  );

  const resetPassword = useCallback<OnResetPassword>(
    async (token, password) => {
      try {
        const result = await authRequests.resetPassword({
          token,
          password,
        });

        return result;
      } catch (error) {
        reportError('resetPassword', error);
        handleError(error);
        return {systemCode: SystemCodes.ERROR};
      }
    },
    [handleError],
  );

  const logout = useCallback<OnLogout>(async () => {
    try {
      if (!accessToken || !refreshToken) {
        return;
      }

      authRequests
        .sendLogoutRequest(accessToken, refreshToken)
        .catch(error => devError('sendLogoutRequest error', error));
    } catch (error) {
    } finally {
      await removeTokens();
      await removeCredentials();
    }
  }, [accessToken, refreshToken, removeCredentials, removeTokens]);

  const obtainNewTokens = useCallback<OnObtainNewTokens>(async () => {
    try {
      if (isLoading || !refreshToken) {
        throw new Error(
          'The tokens are loading or there are no refresh token to update them',
        );
      }

      const result = await authRequests.sendRefreshTokenRequest(refreshToken);

      return {
        accessToken: result.accessToken,
        refreshToken: result.refreshToken,
      };
    } catch (error) {
      reportError('obtainNewTokens error', error);
      await logout();
      throw error;
    }
  }, [isLoading, logout, refreshToken]);

  const resendVerificationToken = useCallback<OnResendVerification>(
    async requestedEmail => {
      try {
        const credentials = await authCredentialsStore.getCredentials();
        const email = requestedEmail ?? credentials?.email;
        if (email) {
          await authRequests.resendVerificationToken({
            email,
            language: getLanguage(i18n.language),
          });
          return {success: true};
        }

        onError(t('auth.email_not_empty'));
        return {success: false};
      } catch (error) {
        reportError('resendVerificationLinkError', error);
        handleError(error);
        return {success: false};
      }
    },
    [handleError, i18n.language, onError, t],
  );

  const handleAccessTokenExpirationError =
    useCallback<OnHandleAccessTokenError>(
      async (error, operation) => {
        if (!isAccessExpiredError(error)) {
          return;
        }

        try {
          const newTokens = await obtainNewTokens();
          await updateTokens(newTokens.accessToken, newTokens.refreshToken);

          return await operation(newTokens.accessToken, newTokens.refreshToken);
        } catch (e) {
          reportError('handleAccessTokenExpirationError error', e);
          handleError(e);
        }
      },
      [handleError, obtainNewTokens, updateTokens],
    );

  const deleteAccount = useCallback<OnDeleteAccount>(async () => {
    const operation = async (token: string) => {
      const result = await authRequests.sendDeleteAccountRequest(token);
      result.success && (await logout());
    };

    try {
      if (!accessToken) {
        await logout();
        return;
      }

      await operation(accessToken);
    } catch (error) {
      devError('deleteAccount error', error);

      if (!isAccessExpiredError(error)) {
        handleError(error);
      } else {
        handleAccessTokenExpirationError(error, operation);
      }
    }
  }, [accessToken, handleAccessTokenExpirationError, handleError, logout]);

  const changePassword = useCallback<OnChangePassword>(
    async (currentPassword, newPassword) => {
      const operation = async (token: string) =>
        await authRequests.sendChangePasswordRequest(token, {
          currentPassword,
          newPassword,
        });

      try {
        if (!accessToken) {
          return {success: false};
        }

        return await operation(accessToken);
      } catch (error) {
        reportError('changePassword error', error);

        if (!isAccessExpiredError(error)) {
          const message = handleError(error);
          return {success: false, message};
        }

        const tokenResult = await handleAccessTokenExpirationError(
          error,
          operation,
        );
        return tokenResult ?? {success: false};
      }
    },
    [accessToken, handleAccessTokenExpirationError, handleError],
  );

  const obtainMindManagerEventtoolSSOLink =
    useCallback<OnObtainMindManagerEventtoolSSOLink>(
      async currentSubscriptionId => {
        const operation = async (token: string) =>
          await authRequests.sendMindManagerEventtoolSSOLinkRequest(
            token,
            currentSubscriptionId,
          );

        try {
          if (!accessToken) {
            return;
          }

          return await operation(accessToken);
        } catch (error) {
          reportError('obtainMindManagerEventtoolSSOLink error', error);

          if (!isAccessExpiredError(error)) {
            return;
          }

          const result = await handleAccessTokenExpirationError(
            error,
            operation,
          );

          return result;
        }
      },
      [accessToken, handleAccessTokenExpirationError],
    );

  const validateUsersRoles = useCallback<OnValidateUsersRoles>(async () => {
    const operation = async (access: string, refresh: string) => {
      const result = await authRequests.validateUsersRoles(access, refresh);

      if (result.isValid) {
        return {isValid: true};
      }

      const {usersRolesUpdateType, userData: updatedUserData} = result;

      if (
        Platform.OS !== 'web' ||
        !usersRolesUpdateType ||
        [
          UsersRolesUpdateType.NO_UPDATE,
          UsersRolesUpdateType.UPDATED,
          UsersRolesUpdateType.MINDMANAGER_ADDED,
        ].includes(usersRolesUpdateType)
      ) {
        await updateTokens(
          updatedUserData.accessToken,
          updatedUserData.refreshToken,
        );
      }

      return {
        isValid: false,
        usersRolesUpdateType: result.usersRolesUpdateType,
      };
    };

    try {
      if (!accessToken || !refreshToken) {
        return;
      }

      return await operation(accessToken, refreshToken);
    } catch (error) {
      if (isInvalidClientInRefreshToken(error)) {
        return {
          isValid: false,
          usersRolesUpdateType: UsersRolesUpdateType.MINDMANAGER_REMOVED,
        };
      }

      if (!isAccessExpiredError(error)) {
        reportError('validateUsersRoles error', error);
        return;
      }

      return await handleAccessTokenExpirationError(error, operation);
    }
  }, [
    accessToken,
    handleAccessTokenExpirationError,
    refreshToken,
    updateTokens,
  ]);

  const value = useMemo<AuthContextType>(
    () => ({
      accessToken,
      refreshToken,
      login,
      logout,
      isLoggedIn,
      isLoading,
      obtainNewTokens,
      updateTokens,
      register,
      forgetPassword,
      validateResetPassword,
      resetPassword,
      setOnError,
      verifyEmailVerificationToken,
      resendVerificationToken,
      deleteAccount,
      changePassword,
      handleAccessTokenExpirationError,
      obtainMindManagerEventtoolSSOLink,
      validateUsersRoles,
    }),
    [
      accessToken,
      refreshToken,
      login,
      logout,
      isLoggedIn,
      isLoading,
      obtainNewTokens,
      updateTokens,
      register,
      forgetPassword,
      validateResetPassword,
      resetPassword,
      verifyEmailVerificationToken,
      resendVerificationToken,
      deleteAccount,
      changePassword,
      handleAccessTokenExpirationError,
      obtainMindManagerEventtoolSSOLink,
      validateUsersRoles,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
