import {ApexOptions} from 'apexcharts';
import React, {useMemo} from 'react';
import Chart from 'react-apexcharts';
import {useTranslation} from 'react-i18next';
import {AppUsageStatisticsChartPros} from '../../mindance-libs/components/MyArea/AppUsageStatistics/props';
import colors from '../../mindance-libs/theme/colors';
import {getFormattedDayOfWeek} from '../../mindance-libs/utils/myArea';
import styles from './AppUsageStatisticsChart.module.scss';

const LABELS_WIDTH = 50;
const BAR_SPACING = 4;
const MAX_BAR_WIDTH = 20;

const DEFAULT_BAR_CHART_OPTIONS: ApexOptions = {
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  chart: {
    type: 'bar',
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    enabled: false,
  },
  xaxis: {
    labels: {
      show: true,
      style: {
        cssClass: styles['app-usage-statistics-chart__axis-x'],
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    tickAmount: 4,
    labels: {
      formatter: (value: number) => value.toFixed(0),
      show: true,
      style: {
        cssClass: styles['app-usage-statistics-chart__axis-y'],
      },
    },
  },
  grid: {
    borderColor: colors.whiteAlpha06,
    show: true,
    strokeDashArray: 0,
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    colors: [colors.barChart],
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: MAX_BAR_WIDTH / 2,
      columnWidth: `${MAX_BAR_WIDTH}px`,
    },
  },
};

const AppUsageStatisticsChart: React.FC<AppUsageStatisticsChartPros> = ({
  appUsagePerDates,
  datesRange,
  barColor = colors.barChart,
  containerWidth,
}) => {
  const {i18n} = useTranslation();

  const options = useMemo<ApexOptions>(() => {
    const categories = datesRange.map(date =>
      getFormattedDayOfWeek(date, i18n.language).toUpperCase(),
    );
    const barWidth =
      categories.length > 0
        ? (containerWidth - LABELS_WIDTH - categories.length * BAR_SPACING) /
          categories.length
        : MAX_BAR_WIDTH;
    const columnWidth = barWidth <= MAX_BAR_WIDTH ? barWidth : MAX_BAR_WIDTH;
    const borderRadius = columnWidth / 2;

    return {
      ...DEFAULT_BAR_CHART_OPTIONS,
      fill: {colors: [barColor]},
      xaxis: {
        ...DEFAULT_BAR_CHART_OPTIONS.xaxis,
        categories,
      },
      plotOptions: {
        bar: {
          borderRadius,
          columnWidth: `${columnWidth}px`,
        },
      },
    };
  }, [barColor, containerWidth, datesRange, i18n.language]);

  const statisticsData = useMemo(() => {
    if (!appUsagePerDates || datesRange.length === 0) {
      return;
    }

    const barItems = datesRange.map(date => {
      const appUsageInfo = appUsagePerDates.find(appUsagePerDate =>
        appUsagePerDate.date.includes(date),
      );
      const value = appUsageInfo?.minutes ?? 0;

      return value;
    });

    return [{data: barItems}];
  }, [appUsagePerDates, datesRange]);

  return (
    <div className={styles['app-usage-statistics-chart__bar-chart']}>
      <Chart
        options={options}
        series={statisticsData}
        type="bar"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default React.memo(AppUsageStatisticsChart);
