import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import typography from '../../theme/typography';
import spacings from '../../theme/spacings';

const IMAGE_SIZE = 34;
const BUTTON_SIZE = 24;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    gap: 10,
  },
  psychologistImage: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    borderRadius: IMAGE_SIZE / 2,
  },
  messageContainer: {
    borderRadius: 22,
    borderBottomLeftRadius: 5,
    backgroundColor: colors.whiteAlpha20,
    flex: 1,
    paddingHorizontal: 20,
    paddingVertical: 16,
  },
  linearSeparator: {
    backgroundColor: colors.whiteAlpha20,
    marginVertical: spacings.l,
  },
  header: {
    flexDirection: 'row',
    gap: 8,
    marginBottom: 8,
    alignItems: 'center',
  },
  headerText: {
    ...typography.title3Bold,
    color: colors.white,
  },
  subtitleText: {
    ...typography.subtitle3,
    color: colors.whiteAlpha70,
  },
  footer: {
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
  },
  footerText: {
    ...typography.subtitle3,
    color: colors.whiteAlpha95,
  },
  button: {
    position: 'absolute',
    bottom: '-10%',
    right: 0,
    width: BUTTON_SIZE,
    height: BUTTON_SIZE,
    borderRadius: BUTTON_SIZE / 2,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  blur: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    flex: 1,
    borderRadius: BUTTON_SIZE / 2,
    backgroundColor: colors.whiteAlpha40,
    borderWidth: 1,
    borderColor: colors.whiteAlpha50,
  },
  arrow: {
    zIndex: 1,
  },
});

export default styles;
