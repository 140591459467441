import React, {ComponentProps, useMemo} from 'react';
import {TextInput, useTheme} from 'react-native-paper';
import PencilIcon from '../../icons/PencilIcon';
import colors from '../../theme/colors';
import AppInput from '../AppInput';
import {getIconStyle, getInputStyle} from './styles';

type Props = ComponentProps<typeof AppInput> & {
  isFocused?: boolean;
  showIcon?: boolean;
  onPress?: () => void;
};

const Pencil = () => <PencilIcon />;

const EditAppInput: React.FC<Props> = ({
  value,
  isFocused = false,
  showIcon = true,
  style,
  testID,
  onPress,
  ...props
}) => {
  const theme = useTheme();

  const inputStyle = useMemo(
    () => [getInputStyle(value, isFocused), style],
    [isFocused, style, value],
  );

  const inputTheme = useMemo(
    () => ({
      ...theme,
      colors: {
        ...theme.colors,
        onSurfaceVariant: value ? colors.whiteAlpha50 : colors.white,
      },
    }),
    [theme, value],
  );

  const iconStyle = useMemo(
    () => getIconStyle(value, isFocused),
    [isFocused, value],
  );

  const PencilIconPostfix = useMemo(
    () =>
      showIcon ? (
        <TextInput.Icon
          onPress={onPress}
          icon={Pencil}
          style={iconStyle}
          testID={`${testID}.icon`}
        />
      ) : null,
    [showIcon, onPress, iconStyle, testID],
  );

  return (
    <AppInput
      showLabel
      right={PencilIconPostfix}
      theme={inputTheme}
      style={inputStyle}
      value={value}
      testID={testID}
      {...props}
    />
  );
};

export default React.memo(EditAppInput);
