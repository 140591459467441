import {Platform, StyleSheet} from 'react-native';
import spacings from '../../../theme/spacings';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: spacings.xl,
  },
  space: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingHorizontal: spacings.s,
    paddingVertical: spacings.l,
  },
  contentScrollView: {
    flexGrow: 1,
    justifyContent: Platform.select({
      default: 'flex-end',
      web: 'flex-start',
    }),
    paddingBottom: spacings.xxl,
  },
  message: {
    marginTop: Platform.select({
      web: spacings.xxl,
      default: spacings.l,
    }),
  },
  buttonWrapper: {
    flexDirection: 'row',
    paddingHorizontal: spacings.xxxl,
    justifyContent: 'center',
  },
});

export default styles;
