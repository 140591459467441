import React, {useEffect, useMemo, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Image, ScrollView, TextProps, View} from 'react-native';
import {OnboardingSlideProps} from '../../../types/onboarding';
import AppButton from '../../AppButton';
import AppText from '../../AppText';
import PsychologistMessage from '../../PsychologistMessage';
import styles from './styles';

type Props = OnboardingSlideProps;

const PsychologistCaptionText: React.FC<TextProps> = React.memo(props => (
  <AppText style={styles.psychologistTitle} {...props} />
));
const PsychologistCaptionBoldText: React.FC<TextProps> = React.memo(props => (
  <AppText style={styles.psychologistTitleBold} {...props} />
));

const psychologistTexts = {
  bold: <PsychologistCaptionBoldText />,
};

const DISPLAY_TIMEOUT = 500;

const OnboardingIntroSlide: React.FC<Props> = ({
  onButtonPress,
  style,
  focused,
  contentButtonStyle,
}) => {
  const [isMessageDisplayed, setIsMessageDisplayed] = useState(false);

  const {t} = useTranslation();

  const containerStyle = useMemo(() => [styles.container, style], [style]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (focused) {
      timer = setTimeout(() => {
        setIsMessageDisplayed(true);
      }, DISPLAY_TIMEOUT);
    }

    return () => {
      timer && clearTimeout(timer);
      timer = undefined;
    };
  }, [focused]);

  return (
    <View style={containerStyle}>
      <ScrollView
        contentContainerStyle={styles.scrollView}
        showsVerticalScrollIndicator={false}>
        <View style={styles.psychologistWrapper}>
          <Image
            source={require('../../../assets/images/psychologist_onboarding.jpg')}
            style={styles.psychologistImage}
          />
          <View style={styles.psychologistTitleWrapper}>
            <Trans
              i18nKey="onboarding.psychologist_intro"
              parent={PsychologistCaptionText}
              components={psychologistTexts}
            />
          </View>
        </View>

        <View style={styles.bottomContainer}>
          <View style={styles.content}>
            {isMessageDisplayed && (
              <PsychologistMessage
                message={t('onboarding.registration_complete')}
                testID="onboardingIntro.psychoMessage"
              />
            )}
          </View>
          <View style={styles.buttonWrapper}>
            <AppButton
              onPress={onButtonPress}
              showNextIcon
              testID="onboardingIntro.nextButton"
              contentStyle={contentButtonStyle}>
              {t('common.next')}
            </AppButton>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default React.memo(OnboardingIntroSlide);
