import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings, {DEFAULT_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';

export const PSYCHOLOGIST_IMAGE_SIZE = Platform.select({
  default: 32,
  web: 48,
});
export const MESSAGE_IMAGE_SPACE = Platform.select({
  default: spacings.xs,
  web: spacings.l,
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  message: {
    flex: 1,
    backgroundColor: colors.brownGray,
    borderWidth: 1,
    borderColor: colors.whiteAlpha30,
    borderTopLeftRadius: DEFAULT_BORDER_RADIUS,
    borderTopRightRadius: DEFAULT_BORDER_RADIUS,
    borderBottomRightRadius: DEFAULT_BORDER_RADIUS,
    borderBottomLeftRadius: 4,
    paddingVertical: spacings.xl,
    paddingHorizontal: spacings.xxl,
    marginLeft: MESSAGE_IMAGE_SPACE,
  },
  messageText: {
    ...typography.body,
  },
  image: {
    width: PSYCHOLOGIST_IMAGE_SIZE,
    height: PSYCHOLOGIST_IMAGE_SIZE,
    borderRadius: PSYCHOLOGIST_IMAGE_SIZE / 2,
  },
});

export default styles;
