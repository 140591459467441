import {StyleSheet} from 'react-native';
import spacings from '../../theme/spacings';
import colors from '../../theme/colors';
import typography from '../../theme/typography';

const HORIZONTAL_SPACING = spacings.xxxxxxxl;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    right: HORIZONTAL_SPACING,
    left: HORIZONTAL_SPACING,
    borderRadius: 15,
    backgroundColor: colors.alert,
    paddingVertical: spacings.xs,
    paddingHorizontal: spacings.m,
    flexDirection: 'row',
    alignItems: 'center',
  },
  textContainer: {
    flex: 1,
    marginLeft: spacings.xs,
  },
  text: {
    ...typography.placeholder,
    color: colors.white,
  },
});

export default styles;
