import {ApexOptions} from 'apexcharts';
import colors from '../../mindance-libs/theme/colors';

export const DEFAULT_PIE_CHART_OPTIONS: ApexOptions = {
  colors: [colors.pieChart1, colors.pieChart2, colors.pieChart3],
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: false,
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: [colors.pieChart1, colors.pieChart2, colors.pieChart3],
  },
  tooltip: {
    enabled: false,
    theme: 'light',
  },
};
