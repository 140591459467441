import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, StyleProp, View, ViewProps, ViewStyle} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import spacings from '../../theme/spacings';
import {getCostLabel} from '../../utils/booking';
import {getDateInterval} from '../../utils/time';
import AppButton from '../AppButton';
import AppText from '../AppText';
import styles from './styles';

type Props = ViewProps & {
  buttonLabel?: string | null;
  buttonDisabled?: boolean;
  buttonLoading?: boolean;
  showLeft?: boolean;
  left?: React.ReactNode;
  cost?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  onButtonPress?: () => void;
};

const BookingFooter: React.FC<Props> = ({
  buttonLabel,
  buttonDisabled,
  buttonLoading,
  showLeft = true,
  left,
  cost,
  startDate,
  endDate,
  onButtonPress,
  style,
  testID,
  ...props
}) => {
  const {t, i18n} = useTranslation();

  const {bottom} = useSafeAreaInsets();

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, {paddingBottom: bottom + spacings.s}, style],
    [bottom, style],
  );

  const LeftComponent = useMemo<React.ReactNode | null>(() => {
    if (!showLeft) {
      return null;
    }

    if (left) {
      return left;
    }

    const costLabel = getCostLabel(t)(cost);

    const datesLabel = startDate
      ? getDateInterval({
          startDate,
          endDate,
          currentLanguage: i18n.language,
        })
      : null;

    return (
      <View testID={`${testID}.left`}>
        {costLabel ? (
          <AppText style={styles.costLabel}>{costLabel}</AppText>
        ) : null}
        {datesLabel ? (
          <AppText style={styles.dateLabel}>{datesLabel}</AppText>
        ) : null}
      </View>
    );
  }, [cost, endDate, i18n.language, left, showLeft, startDate, t, testID]);

  return (
    <View {...props}>
      <View style={containerStyle} testID={testID}>
        <View style={styles.wrapper}>{LeftComponent}</View>
        <View style={styles.wrapper} testID={`${testID}.right`}>
          <AppButton
            onPress={onButtonPress}
            testID={`${testID}.button`}
            disabled={buttonDisabled}
            loading={buttonLoading}
            style={Platform.OS === 'web' ? styles.buttonWeb : undefined}>
            {buttonLabel ?? t('booking.book')}
          </AppButton>
        </View>
      </View>
    </View>
  );
};

export default React.memo(BookingFooter);
