export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  DIVERSE = 'diverse',
}

export enum ServiceLevel {
  BASIC = '[BASIC]',
  PREMIUM = '[PREMIUM]',
  ENTERPRISE = '[ENTERPRISE]',
}

export type OnUpdateUserTags = (tags: string[]) => Promise<void>;
