import AsyncStorage from '@react-native-async-storage/async-storage';
import {Countries, Languages} from '../../types/common';
import {systemLanguage} from './language';
import {ImageSourcePropType} from 'react-native';

export const AVAILABLE_LANGUAGES = Object.values(Languages);

const language =
  systemLanguage && systemLanguage.length > 2
    ? systemLanguage.substring(0, 2)
    : systemLanguage;

const availableLanguage = AVAILABLE_LANGUAGES.find(
  lng => lng.substring(0, 2) === language,
);

export class LocalizationStore {
  protected key: string;

  constructor(key: string) {
    this.key = key;
  }

  public getLanguage = async () => {
    const savedLanguage = await AsyncStorage.getItem(this.key);

    return savedLanguage ?? availableLanguage ?? Languages.GERMAN;
  };

  public setLanguage = async (value: Languages) =>
    await AsyncStorage.setItem(this.key, value);
}

const LANGUAGE_KEY = '@currentLanguage';
export const localizationStore = new LocalizationStore(LANGUAGE_KEY);

export const isAppLanguage = (lng: any): lng is Languages =>
  AVAILABLE_LANGUAGES.includes(lng);

export const getLanguage = (lng: string): Languages =>
  isAppLanguage(lng) ? lng : Languages.GERMAN;

export const getLanguageAsset = (lang: string): ImageSourcePropType => {
  const lng = getLanguage(lang);

  return {
    [Languages.GERMAN]: require('../../assets/images/german.png'),
    [Languages.ENGLISH]: require('../../assets/images/usa.png'),
  }[lng];
};

export const getCountryAsset = (lang: string): ImageSourcePropType => {
  return {
    [Countries.GERMANY]: require('../../assets/images/german.png'),
    [Countries.USA]: require('../../assets/images/usa.png'),
    [Countries.AUSTRIA]: require('../../assets/images/austria.png'),
    [Countries.CZECH_REPUBLIC]: require('../../assets/images/czech.png'),
  }[lang];
};
