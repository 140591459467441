import React, {useMemo} from 'react';
import {View} from 'react-native-web';
import colors from '../../theme/colors';
import {SliderDotProps} from './props';
import styles from './styles';

const SliderDot: React.FC<SliderDotProps> = ({
  style,
  dotIndex,
  animatedPosition,
  averageDotIndex,
  maxDotIndex,
  minThreshold,
  totalLength,
}) => {
  const containerStyle = useMemo<React.CSSProperties>(() => {
    const position =
      typeof animatedPosition === 'number'
        ? animatedPosition
        : animatedPosition.value;

    const adjustedPosition =
      !minThreshold || !averageDotIndex || !totalLength || !maxDotIndex
        ? position
        : Math.floor(position) <= minThreshold
        ? position
        : totalLength - Math.floor(position) - 1 <= minThreshold
        ? maxDotIndex - (totalLength - Math.floor(position))
        : averageDotIndex;

    return {
      ...styles.container,
      backgroundColor:
        adjustedPosition === dotIndex ? colors.white : colors.whiteAlpha20,
      transition: 'background-color 0.3s ease',
      ...(Array.isArray(style)
        ? style.reduce((acc, current) => ({...acc, ...current}))
        : style),
    };
  }, [
    minThreshold,
    averageDotIndex,
    totalLength,
    maxDotIndex,
    animatedPosition,
    dotIndex,
    style,
  ]);

  return <View style={containerStyle} />;
};

export default React.memo(SliderDot);
