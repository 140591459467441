import {StyleSheet} from 'react-native';
import spacings from '../../theme/spacings';

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: spacings.l,
    gap: spacings.xxs,
  },
});

export default styles;
