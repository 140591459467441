import {useCallback, useMemo, useState} from 'react';

const useBooleanValue = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggleValue = useCallback(() => setValue(previous => !previous), []);

  return useMemo(
    () => ({value, setValue, setTrue, setFalse, toggleValue}),
    [setFalse, setTrue, toggleValue, value],
  );
};

export default useBooleanValue;
