import {Platform, StyleSheet} from 'react-native';
import spacings, {SMALL_BORDER_RADIUS} from '../../theme/spacings';
import typography from '../../theme/typography';
import colors from '../../theme/colors';

export const IMAGE_RATIO = 4 / 3;
const IMAGE_MIN_WIDTH = 200;
const IMAGE_MIN_HEIGHT = IMAGE_MIN_WIDTH / IMAGE_RATIO;
const IMAGE_MAX_WIDTH = 350;
const IMAGE_MAX_HEIGHT = IMAGE_MAX_WIDTH / IMAGE_RATIO;

const styles = StyleSheet.create({
  modalContent: {
    padding: spacings.xxl,
  },
  description: {
    ...typography.input,
    fontSize: Platform.select({
      default: typography.input.fontSize ?? 14,
      web: 16,
    }),
    lineHeight: Platform.select({
      default: typography.input.lineHeight ?? 16,
      web: 18,
    }),
  },
  imageContainer: {
    minWidth: IMAGE_MIN_WIDTH,
    minHeight: IMAGE_MIN_HEIGHT,
  },
  image: {
    alignSelf: 'center',
    marginBottom: spacings.xxl,
    minWidth: IMAGE_MIN_WIDTH,
    minHeight: IMAGE_MIN_HEIGHT,
    maxWidth: IMAGE_MAX_WIDTH,
    maxHeight: IMAGE_MAX_HEIGHT,
    borderRadius: SMALL_BORDER_RADIUS,
  },
  p: {
    marginTop: spacings.xxxxs,
    marginBottom: 0,
  },
  a: {
    color: colors.white,
    textDecorationLine: 'underline',
    textDecorationColor: colors.white,
  },
  br: {
    margin: 0,
    padding: 0,
  },
  blockquote: {
    marginLeft: 0,
    paddingLeft: spacings.xs,
    borderLeftWidth: 3,
    borderLeftColor: colors.lightGray,
  },
});

export const descriptionTagStyles = {
  body: styles.description,
  p: {...styles.p},
  a: {...styles.a},
  br: {...styles.br},
  li: {...styles.description, marginTop: -1},
  blockquote: {...styles.blockquote},
};

export default styles;
