import React from 'react';
import {useWindowDimensions} from 'react-native';
import {Link} from 'react-router-dom';
import {checkIsSmallWindow} from '../../utils/common';
import Content from './Content';
import {OneToOneCoachingBookingCardProps} from './props';

const LINK_STYLE: React.CSSProperties = {textDecoration: 'none'};

const MAX_IMAGE_SIZE = 300;

const OneToOneCoachingBookingCard: React.FC<
  OneToOneCoachingBookingCardProps
> = ({href, width, ...rest}) => {
  const {width: windowWidth} = useWindowDimensions();

  const isSmallWindow = checkIsSmallWindow(windowWidth);

  const imageWidth =
    width > 0 && width <= MAX_IMAGE_SIZE ? width : MAX_IMAGE_SIZE;

  return (
    <Link to={href ?? ''} style={LINK_STYLE}>
      <Content isSmallWindow={isSmallWindow} width={imageWidth} {...rest} />
    </Link>
  );
};

export default React.memo(OneToOneCoachingBookingCard);
