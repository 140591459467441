import {useCallback, useEffect, useRef} from 'react';
import {AppState, AppStateStatus, Platform} from 'react-native';
import appUsageStore from '../utils/appUsage';
import {reportError} from '../utils/loggingHelpers';
import useProgressReports from './useProgressReports';
import useWindowStateTracking from './useWindowStateTracking';

type Props = {
  email?: string | null;
};

enum Status {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

const isAndroid = Platform.OS === 'android';

const useTrackAppState = ({email}: Props) => {
  const {reportAppUsage} = useProgressReports();

  const initialTimestampSaved = useRef(false);
  const currentAppStatus = useRef<Status | null>(null);

  const trackState = useCallback<(resetTime?: boolean) => Promise<void>>(
    async resetTime => {
      try {
        const startTimestamp = await appUsageStore.getAppStartedTimestamp();
        reportAppUsage({email, startTimestamp});

        if (resetTime) {
          appUsageStore.setAppStartedTimestamp();
        }
      } catch (error) {
        reportError('trackState error', error);
      }
    },
    [email, reportAppUsage],
  );

  const handleAppState = useCallback(
    async (nextAppState: AppStateStatus) => {
      if (!email) {
        return;
      }

      switch (nextAppState) {
        case 'active':
          if (currentAppStatus.current === Status.ACTIVE) {
            return;
          }
          currentAppStatus.current = Status.ACTIVE;
          appUsageStore.setAppStartedTimestamp();
          return;

        case 'inactive':
        case 'background':
        case 'extension':
          if (currentAppStatus.current === Status.INACTIVE) {
            return;
          }
          currentAppStatus.current = Status.INACTIVE;
          trackState();
          return;

        default:
          return;
      }
    },
    [email, trackState],
  );

  const handleBlur = useCallback(
    () => handleAppState('background'),
    [handleAppState],
  );

  const handleFocus = useCallback(
    () => handleAppState('active'),
    [handleAppState],
  );

  // web  only
  useWindowStateTracking({handleBlur, handleFocus});

  useEffect(() => {
    if (
      !email ||
      initialTimestampSaved.current ||
      currentAppStatus.current === Status.ACTIVE
    ) {
      return;
    }

    currentAppStatus.current = Status.ACTIVE;
    initialTimestampSaved.current = true;
    appUsageStore.setAppStartedTimestamp();
  }, [email]);

  useEffect(() => {
    const onChange = AppState.addEventListener('change', handleAppState);

    // Android only
    const onBlur = isAndroid
      ? AppState.addEventListener('blur', handleBlur)
      : null;
    const onFocus = isAndroid
      ? AppState.addEventListener('focus', handleFocus)
      : null;

    return () => {
      onChange.remove();

      onBlur?.remove();
      onFocus?.remove();
    };
  }, [handleAppState, handleBlur, handleFocus]);

  return trackState;
};

export default useTrackAppState;
