import React from 'react';
import {ICON_SIZE, TICK_ICON_COLOR} from './styles';

const style: React.CSSProperties = {
  color: TICK_ICON_COLOR,
  fontSize: ICON_SIZE,
};

const RoundTickIcon: React.FC = () => {
  return <i className="fa-regular fa-circle-check" style={style} />;
};

export default RoundTickIcon;
