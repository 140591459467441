import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
import colors from '../theme/colors';

const GroupIcon: React.FC<SvgProps> = ({
  fill = colors.whiteAlpha90,
  ...props
}) => {
  return (
    <Svg
      width="18px"
      height="18px"
      viewBox="0 0 1200 1200"
      fill={fill}
      {...props}>
      <Path
        id="path10706"
        d="M596.847,188.488c-103.344,0-187.12,97.81-187.12,218.465
                c0,83.678,40.296,156.352,99.468,193.047l-68.617,31.801l-182.599,84.688c-17.64,8.821-26.444,23.778-26.444,44.947
                c0,67.034,0,134.067,0,201.102c1.451,25.143,16.537,48.577,40.996,48.974h649.62c27.924-2.428,42.05-24.92,42.325-48.974
                c0-67.034,0-134.068,0-201.102c0-21.169-8.804-36.126-26.443-44.947l-175.988-84.688l-73.138-34.65
                c56.744-37.521,95.061-108.624,95.061-190.197C783.967,286.298,700.19,188.488,596.847,188.488L596.847,188.488z M295.023,265.312
                c-44.473,1.689-79.719,20.933-106.497,51.596c-29.62,36.918-44.06,80.75-44.339,124.354c1.819,64.478,30.669,125.518,82.029,157.446
                L21.163,693.997C7.05,699.289,0,711.636,0,731.041v161.398c1.102,21.405,12.216,39.395,33.055,39.703h136.284V761.436
                c2.255-45.639,23.687-82.529,62.196-100.531l136.247-64.817c10.584-6.175,20.731-14.568,30.433-25.152
                c-56.176-86.676-63.977-190.491-27.773-281.801C346.895,274.724,320.432,265.463,295.023,265.312L295.023,265.312z M903.609,265.312
                c-29.083,0.609-55.96,11.319-78.039,26.444c35.217,92.137,25.503,196.016-26.482,276.52c11.467,13.23,23.404,23.377,35.753,30.434
                l130.965,62.195c39.897,21.881,60.47,59.098,60.866,100.532v170.707h140.235c23.063-1.991,32.893-20.387,33.093-39.704V731.042
                c0-17.641-7.05-29.987-21.163-37.045l-202.431-96.618c52.498-38.708,78.859-96.72,79.369-156.117
                c-1.396-47.012-15.757-90.664-44.339-124.354C981.57,284.509,944.526,265.655,903.609,265.312L903.609,265.312z"
      />
    </Svg>
  );
};

export default React.memo(GroupIcon);
