import {AuthCredentials} from '../../types/auth';
import {reportError} from '../loggingHelpers';
import storage from '../../../utils/storage';

export class AuthCredentialsStore {
  private emailKey: string;
  private passwordKey: string;

  constructor({
    emailKey,
    passwordKey,
  }: {
    emailKey: string;
    passwordKey: string;
  }) {
    this.emailKey = emailKey;
    this.passwordKey = passwordKey;
  }

  public setCredentials = async ({email, password}: AuthCredentials) => {
    try {
      email && (await storage.setItem(this.emailKey, email));
      password && (await storage.setItem(this.passwordKey, password));
    } catch (error) {
      reportError('setCredentials error', error);
      throw error;
    }
  };

  public getCredentials = async (): Promise<AuthCredentials> => {
    try {
      const email = await storage.getItem(this.emailKey);
      const password = await storage.getItem(this.passwordKey);

      return {email, password};
    } catch (error) {
      reportError('getCredentials error', error);
      return {email: null, password: null};
    }
  };

  public removeCredentials = async () => {
    try {
      await storage.deleteItem(this.emailKey);
      await storage.deleteItem(this.passwordKey);
    } catch (error) {
      reportError('removeCredentials error', error);
      throw error;
    }
  };
}

const EMAIL_KEY = 'userEmail';
const PASSWORD_KEY = 'userPassword';
const authCredentialsStore = new AuthCredentialsStore({
  emailKey: EMAIL_KEY,
  passwordKey: PASSWORD_KEY,
});

export default authCredentialsStore;
