import React from 'react';

export enum ButtonStyle {
  /** green with right arrow */
  PRIMARY = 'primary',

  /** white */
  SECONDARY = 'secondary',

  /** default */
  INLINE = 'inline',

  INLINE_WHITE = 'inline-white',
}

export enum AnchorTargetAttribute {
  SELF = '_self',
  BLANK = '_blank',
  PARENT = '_parent',
  TOP = '_top',
}

export default interface ButtonProps {
  onClick?: () => void;
  className?: string;
  rightIconClassName?: string;
  leftIconClassName?: string;
  buttonStyle?: ButtonStyle;
  children?: React.ReactNode;
  button?: React.HTMLAttributes<HTMLButtonElement> &
    React.ButtonHTMLAttributes<HTMLButtonElement>;
  anchor?: Omit<
    React.HTMLAttributes<HTMLAnchorElement> &
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
    keyof {href: any}
  > & {href: string | null};
  showArrow?: boolean;
  showLeftArrow?: boolean;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  loading?: boolean;
  disabled?: boolean;
}
