import {useCallback} from 'react';
import {browserName, deviceType, osName, osVersion} from 'react-device-detect';
import {useWindowDimensions} from 'react-native';
import {v5 as uuidv5} from 'uuid';
import packageJson from '../../../package.json';
import Config from '../../utils/config';
import {OnGetDeviceInfo} from '../types/device';

const useGetDeviceInfo = (): OnGetDeviceInfo => {
  const {height, width, fontScale} = useWindowDimensions();

  const getDeviceInfo = useCallback<OnGetDeviceInfo>(
    payload => {
      const deviceUnique = `${deviceType}_${osName}_${browserName}`;
      const uniqueIdentifier = payload?.email
        ? `${payload?.email}_${deviceUnique}`
        : deviceUnique;
      const namespace = Config.DEVICE_ID_NAMESPACE;
      const id = namespace
        ? uuidv5(uniqueIdentifier, namespace)
        : uuidv5(uniqueIdentifier, 'namespace');

      const appVersion = packageJson.version;

      return {
        id,
        AppVersion: appVersion,
        FontScale: fontScale.toString(),
        ScreenHeight: Math.floor(height),
        ScreenWidth: Math.floor(width),
        OS: `${osName} ${osVersion}`,
      };
    },
    [fontScale, height, width],
  );

  return getDeviceInfo;
};
export default useGetDeviceInfo;
