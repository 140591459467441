import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, View, ViewStyle} from 'react-native';
import AppText from '../AppText';
import {CommonProps} from './props';
import styles from './styles';

const MyAreaError: React.FC<CommonProps> = ({style}) => {
  const {t} = useTranslation();

  const centerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, styles.center, style],
    [style],
  );

  return (
    <View style={centerStyle}>
      <AppText style={styles.label}>{t('errors.something_went_wrong')}</AppText>
    </View>
  );
};

export default React.memo(MyAreaError);
