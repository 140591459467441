import * as R from 'ramda';
import React, {useMemo} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {Maybe} from '../../generated/graphql';
import colors from '../../theme/colors';
import AppText from '../AppText';
import HomeCardBase from '../HomeCardBase';
import PlayButton from '../PlayButton';
import SegmentedProgressBar from '../SegmentedProgressBar';
import styles from './styles';

type Props = {
  title?: Maybe<string>;
  timeRecommendation?: Maybe<string>;
  currentProgress?: number;
  totalProgress?: number;
  imageUrl?: string;
  style?: StyleProp<ViewStyle>;
  onPress: () => void;
  width: number;
  aspectRatio: number;
  testID?: string;
};

const CourseHomeCard: React.FC<Props> = ({
  title,
  timeRecommendation,
  currentProgress,
  totalProgress,
  onPress,
  imageUrl,
  width,
  style,
  testID,
  aspectRatio,
}) => {
  const shouldShowProgressBar = R.isNotNil(totalProgress) && totalProgress > 0;

  const textWrapperStyle = useMemo(
    () => [styles.bottomContent, {width}],
    [width],
  );

  return (
    <HomeCardBase
      gradientColor={colors.bitterLemon}
      width={width}
      aspectRatio={aspectRatio}
      imageUrl={imageUrl}
      style={style}
      testID={testID}
      onPress={onPress}>
      <View style={styles.content}>
        <View style={styles.upperContent}>
          {shouldShowProgressBar ? (
            <SegmentedProgressBar
              current={currentProgress ?? 0}
              total={totalProgress}
              testID={`${testID}.progressBar`}
            />
          ) : null}
        </View>

        <View style={styles.playButtonWrapper}>
          <PlayButton
            size={54}
            testID={`${testID}.playButton`}
            onPress={onPress}
          />
        </View>

        <View style={textWrapperStyle}>
          {timeRecommendation ? (
            <AppText
              style={styles.timeRecommendation}
              testID={`${testID}.timeRecommendation`}>
              {timeRecommendation}
            </AppText>
          ) : null}

          {title ? (
            <AppText
              style={styles.title}
              testID={`${testID}.title`}
              numberOfLines={3}
              ellipsizeMode="tail">
              {title}
            </AppText>
          ) : null}
        </View>
      </View>
    </HomeCardBase>
  );
};

export default React.memo(CourseHomeCard);
