import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    ...(Platform.OS === 'web'
      ? {
          shadowColor: colors.black95Alpha55,
          shadowOffset: {
            height: 2,
            width: 2,
          },
          shadowOpacity: 0.2,
          shadowRadius: 12,
        }
      : {}),
  },
});

export default styles;
