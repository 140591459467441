import React, {PropsWithChildren, useMemo} from 'react';
import {
  Platform,
  StyleProp,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import GradientBackground from '../GradientBackground';
import styles from './styles';

type Props = ViewProps & {
  primaryColor?: string;
  secondaryColor?: string;
  backdropColor?: string;
  tertiaryColor?: string;
  includeBackground?: boolean;
};

const ScreenBackground: React.FC<PropsWithChildren<Props>> = ({
  children,
  style,
  primaryColor,
  secondaryColor,
  backdropColor,
  tertiaryColor,
  includeBackground = Platform.OS !== 'web',
  ...props
}) => {
  const mergedContainerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, style],
    [style],
  );

  return (
    <View style={mergedContainerStyle} {...props}>
      {includeBackground && (
        <View style={StyleSheet.absoluteFill}>
          <GradientBackground
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            backdropColor={backdropColor}
            tertiaryColor={tertiaryColor}
            testID="gradientBackground"
          />
        </View>
      )}
      {children}
    </View>
  );
};

export default React.memo(ScreenBackground);
