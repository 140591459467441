import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {TextStyle, ViewStyle} from 'react-native';
import {useNavigate} from 'react-router-dom';
import Button from '../../../components/Button/Button';
import {ButtonStyle} from '../../../components/Button/Button.props';
import ContactUs from '../../../mindance-libs/components/ContactUs';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import colors from '../../../mindance-libs/theme/colors';
import spacings from '../../../mindance-libs/theme/spacings';
import styles from '../page.module.scss';

const contactUseContainerStyle: ViewStyle = {
  alignItems: 'flex-start',
  marginTop: spacings.xxxl,
};
const contactUseTextStyle: TextStyle = {color: colors.whiteAlpha75};

export default function Page() {
  const {t} = useTranslation();

  const navigate = useNavigate();

  const backButton = useMemo(
    () => ({title: t('common.return') || '', onClick: () => navigate(-1)}),
    [navigate, t],
  );

  return (
    <ScreenBase>
      <div className={styles.policies__container}>
        <Button
          showLeftArrow
          button={backButton}
          className={styles.policies__button}
          buttonStyle={ButtonStyle.INLINE_WHITE}
        />
        <h1>{t('settings.help')}</h1>

        <div className={styles.policies__wrapper}>
          <p>{t('settings.need_help')}</p>
          <ContactUs
            isTitleDisplayed={false}
            style={contactUseContainerStyle}
            textStyle={contactUseTextStyle}
          />
        </div>
      </div>
    </ScreenBase>
  );
}
