export enum ProductLabels {
  DASHBOARD_SHOW_RELATED_SUBSCRIPTIONS = 'DASHBOARD_SHOW_RELATED_SUBSCRIPTIONS',
  EVENTTOOL_SHOW_RELATED_SUBSCRIPTIONS = 'EVENTTOOL_SHOW_RELATED_SUBSCRIPTIONS',
  NEWSTOOL_SHOW_RELATED_SUBSCRIPTIONS = 'NEWSTOOL_SHOW_RELATED_SUBSCRIPTIONS',
  GBU_PSYCHE_SHOW_RELATED_SUBSCRIPTIONS = 'GBU_PSYCHE_SHOW_RELATED_SUBSCRIPTIONS',
}

export const isProductLabels = (label: any): label is ProductLabels => {
  return Object.values(ProductLabels).includes(label);
};
