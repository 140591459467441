import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ScrollView, View} from 'react-native';
import styles from './styles';
import MindanceMiniLogo from '../../../icons/MindanceMiniLogo';
import Lottie from 'lottie-react-native';
import AppText from '../../AppText';
import ControlBottomButtons from '../../ControlBottomButtons';
import {RegistrationSlideProps} from '../../../types/registration';

type Props = RegistrationSlideProps;

const RegistrationPrivacySlide: React.FC<Props> = ({
  onSubmitButtonPress,
  onBackButtonPress,
  style,
}) => {
  const {t} = useTranslation();

  const containerStyle = useMemo(() => [styles.container, style], [style]);

  return (
    <View style={containerStyle}>
      <ScrollView
        contentContainerStyle={styles.container}
        showsVerticalScrollIndicator={false}>
        <View style={styles.logo}>
          <MindanceMiniLogo />
        </View>
        <View style={styles.upperSpacer}>
          <Lottie
            source={require('../../../assets/animations/lock_animation.json')}
            autoPlay
            loop={false}
            style={styles.animation}
          />
        </View>
        <AppText style={styles.title}>{t('auth.privacy_is_important')}</AppText>
        <AppText style={styles.description}>
          {t('auth.personal_information_is_secure')}
        </AppText>
        <View style={styles.bottomSpacer} />
        <ControlBottomButtons
          onSubmit={onSubmitButtonPress}
          onReturn={onBackButtonPress}
          style={styles.button}
        />
      </ScrollView>
    </View>
  );
};

export default React.memo(RegistrationPrivacySlide);
