import React, {useMemo} from 'react';
import {StyleProp, TouchableHighlight, ViewStyle} from 'react-native';
import colors from '../../theme/colors';
import styles from './styles';
import PlayIcon from '../../icons/PlayIcon';

type Props = {
  size?: number;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

const ICON_SIZE_PROPORTION = 0.26;

const PlayButton: React.FC<Props> = ({size = 54, onPress, style, testID}) => {
  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [
      styles.container,
      {width: size, height: size, borderRadius: size / 2},
      style,
    ],
    [size, style],
  );

  const iconSize = size * ICON_SIZE_PROPORTION;

  return (
    <TouchableHighlight
      style={containerStyle}
      onPress={onPress}
      underlayColor={colors.black95Alpha55}
      testID={testID}>
      <PlayIcon height={iconSize} width={iconSize} />
    </TouchableHighlight>
  );
};

export default React.memo(PlayButton);
