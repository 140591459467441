import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
import colors from '../theme/colors';

const InfoIcon: React.FC<SvgProps> = ({...props}) => {
  return (
    <Svg
      width={11}
      height={11}
      viewBox="0 0 11 11"
      fill={colors.white}
      {...props}>
      <Path d="M6.05 3.85H4.95V2.75H6.05V3.85ZM6.05 8.25H4.95V4.95H6.05V8.25ZM5.5055 11C8.5415 11 11 8.536 11 5.5C11 2.464 8.5415 0 5.5055 0C2.464 0 0 2.464 0 5.5C0 8.536 2.464 11 5.5055 11ZM5.5 1.1C7.931 1.1 9.9 3.069 9.9 5.5C9.9 7.931 7.931 9.9 5.5 9.9C3.069 9.9 1.1 7.931 1.1 5.5C1.1 3.069 3.069 1.1 5.5 1.1Z" />
    </Svg>
  );
};

export default React.memo(InfoIcon);
