import {Platform, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
  },
  space: {
    width: '30%',
    justifyContent: 'center',
    minHeight: Platform.select({
      web: 52,
      default: undefined,
    }),
  },
  progressBar: {
    width: '40%',
    justifyContent: 'center',
  },
});

export default styles;
