import * as R from 'ramda';
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import Slider from 'react-slick';
import Button from '../../../components/Button/Button';
import {ButtonStyle} from '../../../components/Button/Button.props';
import ErrorOverlay from '../../../components/ErrorOverlay/ErrorOverlay';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay';
import AnimatedProgressBar from '../../../mindance-libs/components/AnimatedProgressBar';
import GeneralUnitSlide from '../../../mindance-libs/components/GeneralUnitSlide';
import NoteUnitsSlide from '../../../mindance-libs/components/NoteUnitsSlide';
import RoundBackButton from '../../../mindance-libs/components/RoundBackButton';
import RoundCloseButton from '../../../mindance-libs/components/RoundCloseButton';
import ScreenBase from '../../../mindance-libs/components/ScreenBase';
import TextUnitsSlide from '../../../mindance-libs/components/TextUnitsSlide';
import useSessionDetails from '../../../mindance-libs/hooks/useSessionDetails';
import colors from '../../../mindance-libs/theme/colors';
import {UnitSlideProps} from '../../../mindance-libs/types/courses';
import {
  isAudioUnits,
  isGeneralUnits,
  isNoteUnits,
  isTextUnits,
  isVideoUnits,
} from '../../../mindance-libs/utils/courses';
import {RouteKeys} from '../../../navigation/RouteKeys';
import useTypedLocation from '../../../navigation/typed/useTypedLocation';
import useTypedNavigate from '../../../navigation/typed/useTypedNavigate';
import useTypedParams from '../../../navigation/typed/useTypedParams';
import styles from './page.module.scss';
import {PROGRESS_BAR_ITEMS_HEIGHT, updateSliderStyle} from './page.options';
import AVUnitSlide from '../../../mindance-libs/components/AVUnitSlide';

export default function Page() {
  const {t} = useTranslation();
  const sliderRef = useRef<Slider>(null);

  const navigate = useTypedNavigate();

  const {id} = useTypedParams<RouteKeys.Session>();
  const {state} = useTypedLocation<RouteKeys.Session>();

  const {
    Label,
    currentProgress,
    error,
    finishUnit,
    handleError,
    loading,
    onNoteSaved,
    slideError,
    units,
    updateCurrentProgress,
    updateFinishProgress,
  } = useSessionDetails(id, {
    courseId: state?.courseId,
    screen: state?.screen,
  });

  const isCourseOrigin = !!state?.courseId;

  const openPreviousSlide = useCallback(() => {
    sliderRef.current?.slickPrev();
  }, []);

  const closeSession = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const goBack = useCallback(
    () => (currentProgress === 0 ? closeSession() : openPreviousSlide()),
    [closeSession, currentProgress, openPreviousSlide],
  );

  const finishSession = useCallback(() => {
    updateFinishProgress();

    const updatedSessionIds = R.without<string>(
      [id],
      state?.courseSessionIds ?? [],
    );

    if (updatedSessionIds.length === 0) {
      return closeSession();
    }

    sliderRef.current?.innerSlider?.changeSlide(
      {message: 'index', index: 0},
      true,
    );
    navigate(
      {to: RouteKeys.Session, params: {id: updatedSessionIds[0]}},
      {
        replace: true,
        state: {
          courseId: state?.courseId,
          screen: state?.screen,
          courseSessionIds: updatedSessionIds,
        },
      },
    );
  }, [
    updateFinishProgress,
    id,
    state?.courseSessionIds,
    state?.courseId,
    state?.screen,
    navigate,
    closeSession,
  ]);

  const nextButton = useMemo(
    () => ({
      onClick: () => {
        currentProgress < units.length - 1
          ? sliderRef.current?.slickNext()
          : finishSession();
      },
    }),
    [currentProgress, finishSession, units.length],
  );

  const handleSlideChange = useCallback<
    (currentSlide: number, nextSlide: number) => void
  >(
    (currentSlide, nextSlide) => {
      finishUnit(currentSlide);
      updateCurrentProgress(nextSlide);
    },
    [finishUnit, updateCurrentProgress],
  );

  const returnButton = useMemo(
    () => ({
      onClick: () => {
        updateFinishProgress();
        closeSession();
      },
    }),
    [closeSession, updateFinishProgress],
  );

  const Slides = useMemo(() => {
    return units.map((item, index) => {
      const focused = currentProgress === index;

      const commonProps: UnitSlideProps = {
        key: item.id,
        focused,
        onError: handleError,
        index,
        currentProgress,
      };

      const isLastUnit = index === units.length - 1;

      if (isTextUnits(item)) {
        return <TextUnitsSlide {...item} {...commonProps} />;
      }

      if (isNoteUnits(item)) {
        return (
          <NoteUnitsSlide
            {...item}
            {...commonProps}
            onNoteSaved={onNoteSaved}
          />
        );
      }

      if (isVideoUnits(item) || isAudioUnits(item)) {
        return <AVUnitSlide {...item} {...commonProps} />;
      }

      if (isGeneralUnits(item) && isLastUnit) {
        return <GeneralUnitSlide {...item} {...commonProps} />;
      }

      if (isGeneralUnits(item)) {
        return <GeneralUnitSlide {...item} {...commonProps} />;
      }

      return <View key="empty" />;
    });
  }, [currentProgress, handleError, onNoteSaved, units]);

  useEffect(() => {
    // update all slides style again in case the units length changed
    if (units.length) {
      updateSliderStyle();
    }
  }, [units.length]);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay />;
  }

  return (
    <ScreenBase error={slideError}>
      <div className={styles.session__container}>
        <div className={styles.session__header}>
          <RoundBackButton
            size={PROGRESS_BAR_ITEMS_HEIGHT}
            borderColor={colors.whiteAlpha50}
            iconColor={colors.white}
            iconSize={22}
            onPress={goBack}
          />
          <div className={styles['session__header__progress-bar-container']}>
            <AnimatedProgressBar
              current={currentProgress + 1}
              total={units.length}
            />
            {Label ? (
              <p
                className={
                  styles['session__header__progress-bar-container__title']
                }>
                {Label}
              </p>
            ) : null}
          </div>
          <RoundCloseButton
            size={PROGRESS_BAR_ITEMS_HEIGHT}
            borderColor={colors.whiteAlpha50}
            iconColor={colors.white}
            iconSize={14}
            onPress={closeSession}
          />
        </div>

        <div className={styles.session__content}>
          <div className={styles['session__content__slider-wrapper']}>
            <Slider
              onInit={updateSliderStyle}
              ref={sliderRef}
              slidesToShow={1}
              beforeChange={handleSlideChange}
              className={styles.session__content__slider}
              arrows={false}
              autoplay={false}
              infinite={false}
              swipeToSlide={false}
              draggable={false}
              initialSlide={0}>
              {Slides}
            </Slider>
          </div>
        </div>

        <div className={styles.session__footer}>
          {currentProgress === units.length - 1 && isCourseOrigin ? (
            <Button
              button={returnButton}
              className={styles['session__footer__return-button']}>
              {t('booking.return_to_overview')}
            </Button>
          ) : (
            <div />
          )}
          <Button
            showArrow
            button={nextButton}
            buttonStyle={ButtonStyle.SECONDARY}
            rightIconClassName={styles['session__footer__next-button__icon']}
            className={styles['session__footer__next-button']}
          />
        </div>
      </div>
    </ScreenBase>
  );
}
