import {Platform, StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import spacings from '../../theme/spacings';
import typography from '../../theme/typography';
import {BOOKING_DETAILS_HORIZONTAL_PADDING} from '../../utils/constants';

const styles = StyleSheet.create({
  container: {
    backgroundColor: Platform.select({
      web: colors.transparent,
      default: colors.black90,
    }),
    paddingLeft: Platform.select({
      web: 0,
      default: BOOKING_DETAILS_HORIZONTAL_PADDING,
    }),
    paddingRight: Platform.select({
      web: 0,
      default: spacings.s,
    }),
    paddingBottom: spacings.xs,
    paddingTop: Platform.select({default: spacings.xs, web: spacings.xl}),
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexShrink: 1,
    gap: spacings.m,
  },
  wrapper: {
    flex: 1,
    minWidth: Platform.select({default: undefined, web: 180}),
  },
  spacer: {},
  costLabel: {
    ...typography.inputDemiBold,
    color: colors.white,
  },
  dateLabel: {
    ...typography.input,
    color: colors.whiteAlpha50,
    marginTop: spacings.xxxs,
  },
  buttonWeb: {
    alignSelf: 'flex-end',
    width: 180,
  },
});

export default styles;
