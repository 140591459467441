import React from 'react';
import Svg, {
  Defs,
  RadialGradient,
  Stop,
  Rect,
  SvgProps,
} from 'react-native-svg';
import colors from '../../theme/colors';

type Props = SvgProps & {
  primaryColor?: string;
  secondaryColor?: string;
  tertiaryColor?: string;
  backdropColor?: string;
};

const GradientBackground: React.FC<Props> = ({
  primaryColor = colors.backgroundPrimary,
  secondaryColor = colors.backgroundSecondary,
  tertiaryColor = colors.backgroundTertiary,
  backdropColor = colors.backgroundBackdrop,
  ...props
}) => {
  return (
    <Svg width="100%" height="100%" {...props}>
      <Defs>
        <RadialGradient id="a" cx="0%" cy="0%" r="100%">
          <Stop offset="0%" stopColor={primaryColor} stopOpacity={1} />
          <Stop offset="60%" stopColor={primaryColor} stopOpacity={0} />
        </RadialGradient>

        <RadialGradient id="b" cx="95%" cy="-10%" r="100%">
          <Stop offset="0%" stopColor={secondaryColor} stopOpacity={1} />
          <Stop offset="30%" stopColor={secondaryColor} stopOpacity={0} />
        </RadialGradient>

        <RadialGradient id="c" cx="100%" cy="70%" r="100%">
          <Stop offset="0%" stopColor={secondaryColor} stopOpacity={1} />
          <Stop offset="60%" stopColor={secondaryColor} stopOpacity={0} />
        </RadialGradient>

        <RadialGradient id="d" cx="0%" cy="95%" r="40%">
          <Stop offset="0%" stopColor={tertiaryColor} stopOpacity={0.7} />
          <Stop offset="60%" stopColor={tertiaryColor} stopOpacity={0} />
        </RadialGradient>
      </Defs>

      <Rect width="100%" height="100%" fill={backdropColor} />
      <Rect width="100%" height="100%" fill="url(#a)" />
      <Rect width="100%" height="100%" fill="url(#b)" />
      <Rect width="100%" height="100%" fill="url(#c)" />
      <Rect width="100%" height="100%" fill="url(#d)" />
    </Svg>
  );
};

export default React.memo(GradientBackground);
