import {faAngleDown, faCircleCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  SubscriptionId,
  useSubscriptionsContext,
} from '../../contexts/SubscriptionsContext';
import useSelectSubscriptions from '../../hooks/useSelectSubscriptions';
import useAppModal from '../../mindance-libs/hooks/useAppModal';
import colors from '../../mindance-libs/theme/colors';
import Button from '../Button/Button';
import {ButtonStyle} from '../Button/Button.props';
import SubscriptionsSelectModal from '../SubscriptionsSelectModal/SubscriptionsSelectModal';
import styles from './SubscriptionsDropdown.module.scss';
import SubscriptionsDropdownProps from './SubscriptionsDropdown.props';

const RightIcon = <FontAwesomeIcon icon={faAngleDown} color={colors.white} />;
const LeftIcon = <FontAwesomeIcon icon={faCircleCheck} color={colors.white} />;

const SubscriptionsDropdown: React.FC<SubscriptionsDropdownProps> = ({
  productLabel,
  className,
}) => {
  const {t} = useTranslation();

  const {
    subscriptionsByProducts,
    selectedSubscriptionsByProducts,
    selectedSubscriptionId,
    subscriptionsRecord,
    updateSelectedSubscriptions,
  } = useSubscriptionsContext();

  const updateProductSelectedSubscriptions = useCallback<
    (ids: SubscriptionId[]) => void
  >(
    ids => {
      if (!productLabel) {
        return;
      }

      updateSelectedSubscriptions(productLabel, ids);
    },
    [productLabel, updateSelectedSubscriptions],
  );

  const productSubscriptions = useMemo(
    () => (productLabel ? subscriptionsByProducts[productLabel] : undefined),
    [productLabel, subscriptionsByProducts],
  );

  const {
    confirmSelection,
    resetSelectedSubscriptions,
    selectSubscription,
    deselectAll,
    selectAll,
    selectedSubscriptionsRecord,
  } = useSelectSubscriptions({
    onSelectSubscriptions: updateProductSelectedSubscriptions,
    initiallySelectedSubscriptionsIds: productLabel
      ? selectedSubscriptionsByProducts[productLabel]
      : selectedSubscriptionId
      ? [selectedSubscriptionId]
      : undefined,
    subscriptions: productSubscriptions,
  });

  const {hideModal, isModalVisible, showModal} = useAppModal();

  const selectedSubscriptionsIds = productLabel
    ? selectedSubscriptionsByProducts[productLabel]
    : selectedSubscriptionId;

  const hasMultipleSelectedSubscriptions =
    !!selectedSubscriptionsIds &&
    Array.isArray(selectedSubscriptionsIds) &&
    selectedSubscriptionsIds.length > 1;

  const singleSubscriptionId =
    hasMultipleSelectedSubscriptions || !selectedSubscriptionsIds
      ? undefined
      : Array.isArray(selectedSubscriptionsIds)
      ? selectedSubscriptionsIds.length === 1
        ? selectedSubscriptionsIds[0]
        : undefined
      : selectedSubscriptionsIds;

  const title = hasMultipleSelectedSubscriptions
    ? t('mindManagerHome.locations', {value: selectedSubscriptionsIds.length})
    : singleSubscriptionId
    ? subscriptionsRecord[singleSubscriptionId]?.Label
    : undefined;

  const buttonProps = useMemo(
    () => ({onClick: () => showModal()}),
    [showModal],
  );

  if ((!singleSubscriptionId && !hasMultipleSelectedSubscriptions) || !title) {
    return null;
  }

  return (
    <>
      <Button
        className={classNames(styles['subscription-dropdown'], className)}
        button={buttonProps}
        buttonStyle={ButtonStyle.INLINE}
        rightIcon={RightIcon}
        leftIcon={hasMultipleSelectedSubscriptions ? LeftIcon : undefined}>
        <span className={styles['subscription-dropdown__title']}>{title}</span>
      </Button>
      {productLabel ? (
        <SubscriptionsSelectModal
          isOpened={isModalVisible}
          selectedSubscriptionsRecord={selectedSubscriptionsRecord}
          subscriptions={productSubscriptions}
          onSelectSubscription={selectSubscription}
          onSelectAll={selectAll}
          onDeselectAll={deselectAll}
          onConfirm={confirmSelection}
          onClose={resetSelectedSubscriptions}
          closeModal={hideModal}
          description={t('mindManagerNewstool.select_locations_descriptions')}
        />
      ) : null}
    </>
  );
};

export default SubscriptionsDropdown;
