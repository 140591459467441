import Config from '../../utils/config';
import {DirectusFileFragment} from '../generated/graphql';

const FILES_URL = Config.FILES_URL;

export class FilesRequests {
  private contentUrl: string;

  constructor(contentUrl: string) {
    this.contentUrl = contentUrl;
  }

  public uploadFile = async (
    file: File,
    {accessToken}: {accessToken: string},
  ): Promise<DirectusFileFragment> => {
    var body = new FormData();
    body.append('file', file);

    const result = await fetch(`${this.contentUrl}/files/upload`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    });

    const data = await result.json();

    if (result.ok) {
      return data.data ?? data;
    }

    throw data;
  };
}

const filesRequests = new FilesRequests(FILES_URL ?? '');

export default filesRequests;
