import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  LayoutChangeEvent,
  StyleProp,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import {Maybe, VenueDetailsFragment} from '../../generated/graphql';
import AppText from '../AppText';
import BookingMetaInfo from '../BookingMetaInfo';
import HTMLRenderer from '../HTMLRenderer';
import LinearSeparator from '../LinearSeparator';
import styles, {htmlCustomStyle} from './styles';

type Props = ViewProps & {
  coach?: string;
  description?: string | null;
  cost?: string | null;
  venue?: Maybe<VenueDetailsFragment> | null;
  startDate?: string | null;
  endDate?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  tags?: string | null;
  contact?: string | null;
  bookingNumber?: string | null;
  onContentLayout?: (e: LayoutChangeEvent) => void;
  placeholderHeight?: number;
  model?: string | null;
  bookingInfo?: string | null;
};

const SEMICOLON_DELIMITER = ': ';

const EventDetailsContent: React.FC<Props> = ({
  cost,
  description,
  venue,
  startDate,
  endDate,
  startTime,
  endTime,
  tags,
  contact,
  bookingNumber,
  onContentLayout,
  placeholderHeight,
  coach,
  model,
  bookingInfo,
  ...props
}) => {
  const {t} = useTranslation();

  const data = useMemo(
    () => [
      {label: tags, title: t('booking.topic')},
      {label: contact, title: t('booking.contact')},
      {label: bookingNumber, title: t('booking.VA_number')},
    ],
    [bookingNumber, contact, t, tags],
  );

  const PointItems = useMemo(
    () =>
      data.map(({title, label}) =>
        label ? (
          <AppText style={styles.point} key={title}>
            <AppText style={styles.pointTitle}>
              {title}
              {SEMICOLON_DELIMITER}
            </AppText>
            {label}
          </AppText>
        ) : null,
      ),
    [data],
  );

  const placeholderStyle = useMemo<StyleProp<ViewStyle>>(
    () => ({
      height:
        placeholderHeight && placeholderHeight > 0 ? placeholderHeight : 0,
    }),
    [placeholderHeight],
  );

  return (
    <View {...props}>
      <View onLayout={onContentLayout}>
        <BookingMetaInfo
          coach={coach}
          cost={cost}
          venue={venue}
          startDate={startDate}
          endDate={endDate}
          startTime={startTime}
          endTime={endTime}
          model={model}
        />
        <LinearSeparator />

        <View style={styles.description}>
          {description ? (
            <HTMLRenderer content={description} tagsStyles={htmlCustomStyle} />
          ) : null}

          {bookingInfo ? (
            <HTMLRenderer content={bookingInfo} tagsStyles={htmlCustomStyle} />
          ) : null}

          <View>{PointItems}</View>
        </View>
      </View>
      <View style={placeholderStyle} />
    </View>
  );
};

export default React.memo(EventDetailsContent);
