import React, {useMemo} from 'react';
import {View, ViewProps} from 'react-native';
import {ButtonProps} from 'react-native-paper';
import {Tags} from '../../generated/graphql';
import {CustomTagGroup, SelectedTagsRecord} from '../../types/common';
import AppText from '../AppText';
import TagItem from '../TagItem';
import styles from './styles';

type Props = Omit<ViewProps, 'id'> &
  CustomTagGroup & {
    selectedTags: SelectedTagsRecord;
    onPress: (tagId: Tags['id']) => void;
    tagMode?: ButtonProps['mode'];
  };

const TagGroupItem: React.FC<Props> = ({
  id: _,
  testID,
  tags,
  label,
  selectedTags,
  onPress,
  tagMode,
  ...props
}) => {
  const TagsButtons = useMemo(() => {
    if (!tags) {
      return null;
    }

    return tags.map((tag, index, list) => (
      <View style={styles.container} key={tag.id}>
        <TagItem
          {...tag}
          isSelected={!!selectedTags[tag.id]}
          onPress={onPress}
          testID={`${testID}.tagItem`}
          mode={tagMode}
        />
        {index !== list.length - 1 && <View style={styles.tagSpace} />}
      </View>
    ));
  }, [onPress, selectedTags, tagMode, tags, testID]);

  return (
    <View testID={testID} {...props}>
      {label ? <AppText style={styles.title}>{label}</AppText> : null}
      <View style={styles.wrapper}>{TagsButtons}</View>
    </View>
  );
};

export default React.memo(TagGroupItem);
