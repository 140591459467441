import {StyleSheet} from 'react-native';
import colors from '../../mindance-libs/theme/colors';

const styles = StyleSheet.create({
  closeButton: {
    backgroundColor: colors.white,
  },
});

export default styles;
