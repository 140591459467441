export enum ErrorMessages {
  SORRY_SOMETHING_WENT_WRONG = 'SORRY_SOMETHING_WENT_WRONG',
  NOT_FOUND = 'NOT_FOUND',
  BAD_REQUEST = 'BAD_REQUEST',
  FORBIDDEN = 'FORBIDDEN',
  ACTIVATION_CODE_DOES_NOT_EXISTS = 'ACTIVATION_CODE_DOES_NOT_EXISTS',
  EXCEED_LIMIT_OF_AMOUNT_ACTIVATIONS = 'EXCEED_LIMIT_OF_AMOUNT_ACTIVATIONS',
  ACTIVATION_FROM_DOES_NOT_MATCH = 'ACTIVATION_FROM_DOES_NOT_MATCH',
  ACTIVATION_UNTIL_DOES_NOT_MATCH = 'ACTIVATION_UNTIL_DOES_NOT_MATCH',
  ALREADY_SUBSCRIBED = 'ALREADY_SUBSCRIBED',
}
