import React from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import EmailConfirmation from '../pages/auth/email-confirmation/page';
import ForgotPassword from '../pages/auth/forgot-password/page';
import ForgotPasswordSuccess from '../pages/auth/forgot-password/success/page';
import Login from '../pages/auth/login/page';
import PasswordChange from '../pages/auth/password-change/page';
import RegistrationEmailVerification from '../pages/auth/registration/email-verification/page';
import Registration from '../pages/auth/registration/page';
import RootLayout from '../pages/layout';
import NotFound from '../pages/not-found/page';
import Onboarding from '../pages/onboarding/page';
import HealthRequirements from '../pages/policies/health-requirements/page';
import Help from '../pages/policies/help/page';
import PrivacyPolicy from '../pages/policies/privacy-policy/page';
import TermsAndConditions from '../pages/policies/terms-and-conditions/page';
import EventBookingForm from '../pages/sidebar/booking/event/booking-form/page';
import EventBookingSuccess from '../pages/sidebar/booking/event/booking-form/success/page';
import UserBookingForm from '../pages/sidebar/booking/event/booking-form/user-data/page';
import Event from '../pages/sidebar/booking/event/page';
import OneToOneSession from '../pages/sidebar/booking/one-to-one-session/page';
import Expertline from '../pages/sidebar/booking/expertline/page';
import Booking from '../pages/sidebar/booking/page';
import Course from '../pages/sidebar/course/page';
import Dashboard from '../pages/sidebar/dashboard/page';
import Eventool from '../pages/sidebar/eventtool/page';
import Home from '../pages/sidebar/home/page';
import Sidebar from '../pages/sidebar/layout';
import LibraryItems from '../pages/sidebar/library/items/page';
import Library from '../pages/sidebar/library/page';
import MyNotes from '../pages/sidebar/my-area/notes/page';
import MyArea from '../pages/sidebar/my-area/page';
import News from '../pages/sidebar/news/page';
import NewstoolDetails from '../pages/sidebar/newstool/details/page';
import Newstool from '../pages/sidebar/newstool/page';
import Session from '../pages/sidebar/session/page';
import ChangePassword from '../pages/sidebar/settings/account-details/change-password/page';
import AccountDetails from '../pages/sidebar/settings/account-details/page';
import Settings from '../pages/sidebar/settings/page';
import RedeemActivationCode from '../pages/sidebar/settings/redeem-activation-code/page';
import Start from '../pages/sidebar/start/page';
import Welcome from '../pages/welcome/page';
import {RouteKeys} from './RouteKeys';
import AuthRoute from './routes/AuthRoute';
import OnboardingRoute from './routes/OnboardingRoute';
import ProtectedRoute from './routes/ProtectedRoute';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={RouteKeys.Root} element={<RootLayout />}>
        <Route
          path={RouteKeys.Welcome}
          element={
            <AuthRoute>
              <Welcome />
            </AuthRoute>
          }
          index
        />
        <Route
          path={RouteKeys.Login}
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />
        <Route
          path={RouteKeys.Registration}
          element={
            <AuthRoute>
              <Registration />
            </AuthRoute>
          }
        />
        <Route
          path={RouteKeys.RegistrationEmailVerification}
          element={
            <AuthRoute>
              <RegistrationEmailVerification />
            </AuthRoute>
          }
        />
        <Route
          path={RouteKeys.PasswordChange}
          element={
            <AuthRoute>
              <PasswordChange />
            </AuthRoute>
          }
        />
        <Route
          path={RouteKeys.ForgotPassword}
          element={
            <AuthRoute>
              <ForgotPassword />
            </AuthRoute>
          }
        />
        <Route
          path={RouteKeys.ForgotPasswordSuccess}
          element={
            <AuthRoute>
              <ForgotPasswordSuccess />
            </AuthRoute>
          }
        />
        <Route
          path={RouteKeys.EmailConfirmation}
          element={
            <AuthRoute>
              <EmailConfirmation />
            </AuthRoute>
          }
        />

        <Route
          path={RouteKeys.Onboarding}
          element={
            <OnboardingRoute>
              <Onboarding />
            </OnboardingRoute>
          }
        />

        <Route
          path={RouteKeys.Root}
          element={
            <ProtectedRoute>
              <Sidebar />
            </ProtectedRoute>
          }>
          <Route
            index
            path={RouteKeys.Home}
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.Settings}
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.SettingsAccountDetails}
            element={
              <ProtectedRoute>
                <AccountDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.SettingsChangePassword}
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.SettingsRedeemActivationCode}
            element={
              <ProtectedRoute>
                <RedeemActivationCode />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.SettingsHelp}
            element={
              <ProtectedRoute>
                <Help />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.SettingsPrivacyPolicy}
            element={
              <ProtectedRoute>
                <PrivacyPolicy />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.SettingsTermsAndConditions}
            element={
              <ProtectedRoute>
                <TermsAndConditions />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.SettingsHealthRequirements}
            element={
              <ProtectedRoute>
                <HealthRequirements />
              </ProtectedRoute>
            }
          />

          <Route
            path={RouteKeys.Eventtool}
            element={
              <ProtectedRoute>
                <Eventool />
              </ProtectedRoute>
            }
          />
          <Route
            index
            path={RouteKeys.Newstool}
            element={
              <ProtectedRoute>
                <Newstool />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.NewstoolDetails}
            element={
              <ProtectedRoute>
                <NewstoolDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.Dashboard}
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path={RouteKeys.Start}
            element={
              <ProtectedRoute>
                <Start />
              </ProtectedRoute>
            }
          />

          <Route
            path={RouteKeys.Library}
            element={
              <ProtectedRoute>
                <Library />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.LibraryItems}
            element={
              <ProtectedRoute>
                <LibraryItems />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.Course}
            element={
              <ProtectedRoute>
                <Course />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.Session}
            element={
              <ProtectedRoute>
                <Session />
              </ProtectedRoute>
            }
          />

          <Route
            path={RouteKeys.Booking}
            element={
              <ProtectedRoute>
                <Booking />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.Expertline}
            element={
              <ProtectedRoute>
                <Expertline />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.EventDetails}
            element={
              <ProtectedRoute>
                <Event />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.OneToOneSessionDetails}
            element={
              <ProtectedRoute>
                <OneToOneSession />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.EventBookingForm}
            element={
              <ProtectedRoute>
                <EventBookingForm />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.EventBookingSuccess}
            element={
              <ProtectedRoute>
                <EventBookingSuccess />
              </ProtectedRoute>
            }
          />
          <Route
            path={RouteKeys.UserBookingForm}
            element={
              <ProtectedRoute>
                <UserBookingForm />
              </ProtectedRoute>
            }
          />

          <Route
            path={RouteKeys.News}
            element={
              <ProtectedRoute>
                <News />
              </ProtectedRoute>
            }
          />

          <Route
            path={RouteKeys.MyArea}
            element={
              <ProtectedRoute>
                <MyArea />
              </ProtectedRoute>
            }
          />

          <Route
            path={RouteKeys.MyNotes}
            element={
              <ProtectedRoute>
                <MyNotes />
              </ProtectedRoute>
            }
          />

          <Route path={'*'} element={<NotFound />} />
        </Route>

        <Route path={RouteKeys.Help} element={<Help />} />
        <Route path={RouteKeys.PrivacyPolicy} element={<PrivacyPolicy />} />
        <Route
          path={RouteKeys.TermsAndConditions}
          element={<TermsAndConditions />}
        />
        <Route
          path={RouteKeys.HealthRequirements}
          element={<HealthRequirements />}
        />
        <Route path={'*'} element={<NotFound />} />
      </Route>
    </>,
  ),
);

const NavigationRoutes: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default NavigationRoutes;
