import React from 'react';
import {Link} from 'react-router-dom';
import SettingsIcon from '../../icons/SettingsIcon';
import {SettingsIconButtonProps} from './props';
import styles from './styles.web.module.scss';

const SettingsIconButton: React.FC<SettingsIconButtonProps> = ({href}) => {
  return (
    <Link to={href ?? ''} className={styles['settings-icon-button']}>
      <SettingsIcon width={20} height={20} />
    </Link>
  );
};

export default React.memo(SettingsIconButton);
