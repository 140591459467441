import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
import colors from '../theme/colors';

const StarIcon: React.FC<SvgProps> = ({...props}) => {
  return (
    <Svg
      fill={colors.white}
      width="12px"
      height="12px"
      viewBox="0 0 13 13"
      {...props}>
      <Path d="M6.50021 0C6.72683 0 6.93374 0.132021 7.03227 0.342748L8.72203 3.93017L12.4957 4.50396C12.7174 4.53696 12.9021 4.69691 12.9711 4.91779C13.04 5.13868 12.9834 5.37733 12.8257 5.53982L10.0891 8.33765L10.7345 12.2881C10.7714 12.5166 10.6803 12.7477 10.498 12.8848C10.3157 13.0219 10.0719 13.0371 9.87481 12.9279L6.50021 11.0695L3.12808 12.9254C2.92856 13.0346 2.68716 13.0193 2.50488 12.8822C2.32261 12.7451 2.22901 12.5141 2.26595 12.2856L2.91131 8.33511L0.174687 5.53982C0.0145782 5.37733 -0.0396125 5.13614 0.0293575 4.91779C0.0983274 4.69945 0.283068 4.5395 0.504757 4.50396L4.2784 3.93017L5.96816 0.342748C6.06915 0.132021 6.2736 0 6.50021 0ZM6.50021 2.00571L5.20703 4.75277C5.12082 4.93303 4.95578 5.05997 4.76119 5.09044L1.84721 5.5322L3.96311 7.69278C4.09858 7.83242 4.16263 8.03045 4.1306 8.22594L3.63057 11.265L6.22187 9.83813C6.39676 9.74165 6.60613 9.74165 6.77856 9.83813L9.36985 11.265L8.87229 8.22848C8.84027 8.03299 8.90184 7.83496 9.03978 7.69532L11.1557 5.53474L8.2417 5.09044C8.04957 5.05997 7.88208 4.93557 7.79586 4.75277L6.50021 2.00571Z" />
    </Svg>
  );
};

export default React.memo(StarIcon);
