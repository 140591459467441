import {faPlus, faThumbtack} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import format from 'date-fns/format';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ActivityIndicator} from 'react-native-paper';
import useMindManagerNewsList from '../../../hooks/useMindManagerNewsList';
// import useCompanyConfig from '../../../mindance-libs/hooks/useCompanyConfig';
import colors from '../../../mindance-libs/theme/colors';
import {getImageUrl} from '../../../mindance-libs/utils/files';
import {RouteKeys} from '../../../navigation/RouteKeys';
import TypedLink from '../../../navigation/typed/TypedLink';
import {generateUrlWithParams} from '../../../navigation/utils';
import Button from '../../Button/Button';
import {AnchorTargetAttribute} from '../../Button/Button.props';
import {removeTags} from './NewstoolHomeCard.data';
import styles from './NewstoolHomeCard.module.scss';
import NewstoolHomeCardProps from './NewstoolHomeCard.props';
import useCompanyConfig from '../../../mindance-libs/hooks/useCompanyConfig';

const ITEMS_LENGTH = 2;
// const AUTHOR_AVATAR_SIZE = 24;
const CONTENT_IMAGE_SIZE = 100;
const DESCRIPTION_TEXT_LIMIT = 100;

const NewstoolHomeCard: React.FC<NewstoolHomeCardProps> = ({
  className,
  testID,
}) => {
  const {t, i18n} = useTranslation();

  const {highlightColor} = useCompanyConfig();

  const pinColor = highlightColor ?? colors.backgroundPrimary;

  const pinnedItemStyle = useMemo<React.CSSProperties>(
    () => ({border: `1px solid ${pinColor}`}),
    [pinColor],
  );

  const {data, loading} = useMindManagerNewsList({take: ITEMS_LENGTH});

  const isEmpty = data.length === 0;

  // const authorLogo = getLogoUrl({
  //   width: AUTHOR_AVATAR_SIZE,
  //   height: AUTHOR_AVATAR_SIZE,
  // });

  const mergedStyle = classNames(styles['newstool-home-card'], className);

  const EmptyView = useMemo(
    () => (
      <div className={styles['newstool-home-card__empty']}>
        <div>
          <h3 className={styles['newstool-home-card__empty__title']}>
            {t('mindManagerHome.newstool_title')}
          </h3>
          <p className={styles['newstool-home-card__empty__description']}>
            {t('mindManagerHome.newstool_description')}
          </p>
        </div>
        <div className={styles['newstool-home-card__empty__button-container']}>
          <Button
            className={styles['newstool-home-card__empty__button']}
            anchor={{
              href: generateUrlWithParams(
                RouteKeys.NewstoolDetails,
                {id: undefined},
                {create: true},
              ),
              title: t('mindManagerNewstool.create_new_post') ?? '',
              target: AnchorTargetAttribute.SELF,
            }}>
            <div
              className={
                styles['newstool-home-card__empty__button__icon-border']
              }>
              <FontAwesomeIcon
                icon={faPlus}
                className={styles['newstool-home-card__empty__button__icon']}
              />
            </div>
            <p className={styles['newstool-home-card__empty__button__title']}>
              {t('mindManagerNewstool.create_new_post')}
            </p>
          </Button>
        </div>
      </div>
    ),
    [t],
  );

  const Content = useMemo(
    () =>
      data
        .slice(0, ITEMS_LENGTH)
        .map(
          ({
            id,
            Pinned,
            date_created,
            Users_News_AuthorCreatedToUsers,
            News_translations,
            directus_files,
          }) => {
            const firstName = Users_News_AuthorCreatedToUsers?.FirstName ?? '';
            const lastName = Users_News_AuthorCreatedToUsers?.LastName ?? '';
            const name = `${firstName} ${lastName}`;
            const translation =
              News_translations?.find(
                ({languages_code}) => languages_code === i18n.language,
              ) ?? News_translations?.[0];

            const contentImage = getImageUrl(directus_files, {
              width: CONTENT_IMAGE_SIZE,
              height: CONTENT_IMAGE_SIZE,
            });

            const descriptionWithoutTags = translation?.Description
              ? removeTags(translation.Description)
              : undefined;
            const trimmedDescription =
              descriptionWithoutTags &&
              descriptionWithoutTags.length >= DESCRIPTION_TEXT_LIMIT
                ? descriptionWithoutTags.slice(0, DESCRIPTION_TEXT_LIMIT) +
                  '... '
                : descriptionWithoutTags;

            return (
              <TypedLink
                to={RouteKeys.NewstoolDetails}
                params={{id}}
                key={id}
                className={styles['newstool-home-card__content__item']}
                style={pinnedItemStyle}>
                {Pinned && (
                  <FontAwesomeIcon
                    className={styles['newstool-home-card__content__item__pin']}
                    color={pinColor}
                    icon={faThumbtack}
                  />
                )}
                <div
                  className={
                    styles['newstool-home-card__content__item__texts']
                  }>
                  {date_created ? (
                    <p>
                      {t('mindManagerNewstool.time', {
                        time: format(new Date(date_created), 'dd.MM.yy, H:mm'),
                      })}
                    </p>
                  ) : null}
                  <div
                    className={
                      styles[
                        'newstool-home-card__content__item__texts__name-container'
                      ]
                    }>
                    {/* {authorLogo ? (
                      <img
                        src={authorLogo}
                        className={
                          styles[
                            'newstool-home-card__content__item__texts__avatar'
                          ]
                        }
                      />
                    ) : null} */}
                    <p
                      className={
                        styles[
                          'newstool-home-card__content__item__texts__title'
                        ]
                      }>
                      {name}
                    </p>
                  </div>
                  {translation?.Label ? (
                    <p
                      className={
                        styles[
                          'newstool-home-card__content__item__texts__title'
                        ]
                      }>
                      {translation?.Label}
                    </p>
                  ) : null}
                  {trimmedDescription ? (
                    <p
                      className={
                        styles[
                          'newstool-home-card__content__item__texts__description'
                        ]
                      }>
                      {trimmedDescription}
                    </p>
                  ) : null}
                </div>

                {contentImage ? (
                  <div
                    className={
                      styles['newstool-home-card__content__item__media']
                    }>
                    <img
                      src={contentImage}
                      className={
                        styles[
                          'newstool-home-card__content__item__media__image'
                        ]
                      }
                    />
                  </div>
                ) : null}
              </TypedLink>
            );
          },
        ),
    [data, i18n.language, pinColor, pinnedItemStyle, t],
  );

  return (
    <div className={mergedStyle} data-testid={testID}>
      {loading ? (
        <div className={styles['newstool-home-card__loading']}>
          <ActivityIndicator color={colors.whiteAlpha70} size={50} />
        </div>
      ) : isEmpty ? (
        EmptyView
      ) : (
        <div className={styles['newstool-home-card__content']}>{Content}</div>
      )}
    </div>
  );
};

export default NewstoolHomeCard;
