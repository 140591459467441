import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, TextStyle, View, ViewStyle} from 'react-native';
import NextArrowIcon from '../../icons/NextArrowIcon';
import colors from '../../theme/colors';
import AppText from '../AppText';
import {MyAreaEmptyProps} from './props';
import styles from './styles';

const MyAreaEmpty: React.FC<MyAreaEmptyProps> = ({
  label,
  style,
  showNextArrow = false,
}) => {
  const {t} = useTranslation();

  const centerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, showNextArrow ? styles.row : styles.center, style],
    [showNextArrow, style],
  );

  const labelStyle = useMemo<StyleProp<TextStyle>>(
    () => [styles.label, showNextArrow ? {textAlign: 'left'} : {}],
    [showNextArrow],
  );

  return (
    <View style={centerStyle}>
      <AppText style={labelStyle}>{label ?? t('common.empty_state')}</AppText>
      {showNextArrow ? <NextArrowIcon stroke={colors.white} /> : null}
    </View>
  );
};

export default React.memo(MyAreaEmpty);
