import {faHandHoldingHeart} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import ErrorOverlay from '../../../../components/ErrorOverlay/ErrorOverlay';
import LoadingOverlay from '../../../../components/LoadingOverlay/LoadingOverlay';
import ExpertlineImage from '../../../../mindance-libs/assets/images/expertline.jpg';
import ExpertlineItem from '../../../../mindance-libs/components/ExpertlineItem';
import RoundBackButton from '../../../../mindance-libs/components/RoundBackButton';
import ScreenBase from '../../../../mindance-libs/components/ScreenBase';
import useExpertlines from '../../../../mindance-libs/hooks/useExpertlines';
import colors from '../../../../mindance-libs/theme/colors';
import useTypedNavigate from '../../../../navigation/typed/useTypedNavigate';
import styles from './page.module.scss';

export default function Page() {
  const {t} = useTranslation();

  const navigate = useTypedNavigate();

  const {data, error, loading} = useExpertlines();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const ExpertlineList = useMemo(
    () =>
      data?.map(
        ({
          PhoneNumber,
          id,
          DisplayNumber,
          RegionIcon,
          Expertlines_translations,
        }) => (
          <ExpertlineItem
            key={id}
            phoneNumber={PhoneNumber}
            displayNumber={DisplayNumber}
            regionCode={RegionIcon}
            description={Expertlines_translations?.[0]?.Description}
            regionName={Expertlines_translations?.[0]?.RegionName}
          />
        ),
      ),
    [data],
  );

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <ErrorOverlay />;
  }

  return (
    <ScreenBase>
      <div className={styles.expertline__container}>
        <RoundBackButton
          size={40}
          borderColor={colors.whiteAlpha50}
          iconColor={colors.white}
          iconSize={30}
          onPress={goBack}
        />

        <div className={styles.expertline__content}>
          <div className={styles.expertline__content__header}>
            <div className={styles.expertline__content__header__texts}>
              <h1>
                <FontAwesomeIcon
                  icon={faHandHoldingHeart}
                  className={styles.expertline__content__header__texts__icon}
                />
                {t('expertline.title')}
              </h1>
              <p
                className={styles.expertline__content__header__texts__subtitle}>
                {t('expertline.subtitle')}
              </p>
              <p
                className={
                  styles.expertline__content__header__texts__description
                }>
                {t('expertline.description')}
              </p>
            </div>

            <img
              src={ExpertlineImage}
              className={styles.expertline__content__header__image}
            />
          </div>

          <div className={styles.expertline__content__list}>
            {ExpertlineList}
          </div>
        </div>
      </div>
    </ScreenBase>
  );
}
