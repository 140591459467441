import {LoginFormProps, LoginProps} from './page.props';

export const LoginDataLang: Record<string, LoginProps> = {};

LoginDataLang['de-DE'] = {
  subtitle: 'Logge dich ein.',
};

LoginDataLang['en-US'] = {
  ...LoginDataLang.de,
  subtitle: 'Log in.',
};

export function getLoginDataLang(lang = 'de-DE') {
  return LoginDataLang[lang] || LoginDataLang['de-DE'];
}

export const INITIAL_FORM_VALUES: LoginFormProps = {
  email: '',
  password: '',
};
