import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../components/Button/Button';
import {ButtonStyle} from '../../../../components/Button/Button.props';
import ScreenBase from '../../../../mindance-libs/components/ScreenBase/index';
import MindanceBigLogo from '../../../../mindance-libs/icons/MindanceBigLogo';
import useTypedNavigate from '../../../../navigation/typed/useTypedNavigate';
import styles from './page.module.scss';

export default function Page() {
  const {t} = useTranslation();
  const navigate = useTypedNavigate();

  const button = useMemo(() => ({onClick: () => navigate(-2)}), [navigate]);

  return (
    <ScreenBase>
      <div className={styles.forgot_success__container}>
        <div className={styles.forgot_success__content}>
          <div className={styles.forgot_success__logo}>
            <MindanceBigLogo />
          </div>

          <h1>{t('auth.email_sent')}</h1>
          <p>{t('auth.email_sent_desc')}</p>

          <div className={styles.forgot_success__center}>
            <Button
              button={button}
              buttonStyle={ButtonStyle.SECONDARY}
              showLeftArrow>
              {t('common.return')}
            </Button>
          </div>
        </div>
      </div>
    </ScreenBase>
  );
}
