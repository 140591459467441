import * as R from 'ramda';
import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import NavigationKeys from '../../navigation/NavigationKeys';
import {
  SessionUnitsFragment,
  useSessionsUnitsQuery,
} from '../generated/graphql';
import {useAppContext} from '../store/contexts/AppContext';
import {
  isAudioUnits,
  isNoteUnits,
  isTextUnits,
  isVideoUnits,
} from '../utils/courses';
import {reportError} from '../utils/loggingHelpers';
import useErrorMessage from './useErrorMessage';
import useProgressReports from './useProgressReports';
import useSessionUnitsRules from './useSessionUnitsRules';

const useSessionDetails = (
  sessionId: string | undefined,
  {courseId, screen}: {courseId?: string; screen?: NavigationKeys},
) => {
  const {t, i18n} = useTranslation();

  const {user} = useAppContext();

  const {reportFinishedUnit, reportFinishedSession} = useProgressReports();

  const {error: slideError, showMessage: setSlideError} = useErrorMessage();

  const {data, loading, error, previousData} = useSessionsUnitsQuery({
    variables: {
      language: i18n.language,
      where: {
        id: {equals: Number(sessionId)},
      },
      take: 1,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onError: err => reportError('useSessionsUnitsQuery error', err),
    skip: R.isNil(sessionId),
  });

  const [currentProgress, setCurrentProgress] = useState(0);

  const session = useMemo<SessionUnitsFragment | undefined>(
    () => data?.findManySessions?.[0],
    [data?.findManySessions],
  );

  const {onNoteSaved, units} = useSessionUnitsRules(
    session?.Sessions_Units,
    sessionId,
  );

  const finishUnit = useCallback<(currentIndex?: number) => void>(
    currentIndex => {
      if (!currentIndex) {
        return;
      }

      const unit = units?.[currentIndex];

      if (
        sessionId &&
        unit &&
        (isTextUnits(unit) ||
          isAudioUnits(unit) ||
          isVideoUnits(unit) ||
          isNoteUnits(unit))
      ) {
        reportFinishedUnit({
          email: user?.EMail ?? '',
          unit,
          sessionId,
          courseId,
          screen,
        });
      }
    },
    [courseId, screen, reportFinishedUnit, sessionId, units, user?.EMail],
  );

  const updateFinishProgress = useCallback(() => {
    sessionId &&
      reportFinishedSession({
        email: user?.EMail ?? '',
        courseId,
        sessionId,
        screen,
      });
  }, [courseId, reportFinishedSession, screen, sessionId, user?.EMail]);

  const handleError = useCallback<(errorMessage: string) => void>(
    errorMessage => {
      reportError('session details error', errorMessage);
      setSlideError(t('errors.something_went_wrong'));
    },
    [setSlideError, t],
  );

  return useMemo(
    () => ({
      ...session,
      units,
      loading: loading && !previousData,
      error,
      currentProgress,
      slideError,
      handleError,
      updateFinishProgress,
      updateCurrentProgress: setCurrentProgress,
      onNoteSaved,
      finishUnit,
    }),
    [
      currentProgress,
      error,
      finishUnit,
      handleError,
      loading,
      onNoteSaved,
      previousData,
      session,
      slideError,
      units,
      updateFinishProgress,
    ],
  );
};

export default useSessionDetails;
