import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';

const BORDER_RADIUS = 60;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 5,
    borderRadius: BORDER_RADIUS,
    flexDirection: 'row',
  },
  background: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.whiteAlpha10,
    borderRadius: BORDER_RADIUS,
  },
  topBar: {
    borderRadius: BORDER_RADIUS,
    backgroundColor: colors.white,
  },
});

export default styles;
