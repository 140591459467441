import RCSlider from 'rc-slider';
import React, {useCallback, useMemo} from 'react';
import colors from '../../theme/colors';
import {SliderProps} from './props';

import 'rc-slider/assets/index.css';

const Slider: React.FC<SliderProps> = ({
  maximumValue,
  minimumValue,
  progressValue,
  thumbWidth,
  onSlidingComplete,
  minimumTrackTintColor = colors.white,
  maximumTrackTintColor = colors.whiteAlpha10,
  bubbleBackgroundColor = colors.whiteAlpha10,
}) => {
  const styles = useMemo(
    () => ({
      rail: {backgroundColor: maximumTrackTintColor},
      track: {backgroundColor: minimumTrackTintColor},
      handle: {
        boxShadow: `${bubbleBackgroundColor} 0px 0px 0px 3px `,
        backgroundColor: minimumTrackTintColor,
        width: thumbWidth,
        height: thumbWidth,
        borderColor: minimumTrackTintColor,
        opacity: 1,
      },
    }),
    [
      bubbleBackgroundColor,
      maximumTrackTintColor,
      minimumTrackTintColor,
      thumbWidth,
    ],
  );

  const onChange = useCallback<(value: number | number[]) => void>(
    value => {
      if (Array.isArray(value)) {
        value[0] && onSlidingComplete?.(value[0]);
        return;
      }
      onSlidingComplete?.(value);
    },
    [onSlidingComplete],
  );

  return (
    <RCSlider
      min={minimumValue}
      max={maximumValue}
      value={progressValue}
      step={100}
      onChange={onChange}
      styles={styles}
    />
  );
};

export default React.memo(Slider);
