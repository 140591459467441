import * as R from 'ramda';
import {useCallback, useMemo, useState} from 'react';
import {CustomTag, CustomTagGroup, SelectedTagsRecord} from '../types/common';
import {
  getTagsFromTagGroups,
  getTagsIdsFromTagGroups,
  getTagsRecord,
} from '../utils/tags';

const useSelectTags = ({
  tagsFilters,
  initiallySelectedTags,
}: {
  tagsFilters?: CustomTagGroup[];
  initiallySelectedTags?: CustomTag['id'][];
}) => {
  const tagsRecord = useMemo<SelectedTagsRecord>(
    () => getTagsRecord(tagsFilters, initiallySelectedTags ?? []),
    [tagsFilters, initiallySelectedTags],
  );

  const [selectedTagsRecord, setSelectedTagsRecord] = useState(tagsRecord);

  const tagsIds = useMemo<CustomTag['id'][]>(
    () => getTagsIdsFromTagGroups(tagsFilters ?? []),
    [tagsFilters],
  );

  const tags = useMemo<CustomTag[]>(
    () => getTagsFromTagGroups(tagsFilters ?? []),
    [tagsFilters],
  );

  const selectedTagsIds = useMemo<CustomTag['id'][]>(
    () => R.pipe(R.filter(R.equals(true)), R.keys)(selectedTagsRecord),
    [selectedTagsRecord],
  );

  const selectTag = useCallback<(tagId: CustomTag['id']) => void>(tagId => {
    setSelectedTagsRecord(previous =>
      R.assoc(tagId, !previous[tagId], previous),
    );
  }, []);

  const selectAllTags = useCallback(() => {
    setSelectedTagsRecord(previous =>
      Object.keys(previous).reduce<SelectedTagsRecord>(
        (acc, key) => ({...acc, [key]: true}),
        {},
      ),
    );
  }, []);

  const deselectAllTags = useCallback(() => {
    setSelectedTagsRecord(previous =>
      Object.keys(previous).reduce<SelectedTagsRecord>(
        (acc, key) => ({...acc, [key]: false}),
        {},
      ),
    );
  }, []);

  const resetSelectedTags = useCallback(() => {
    setSelectedTagsRecord(tagsRecord);
  }, [tagsRecord]);

  return useMemo(
    () => ({
      selectedTagsRecord,
      tagsIds,
      selectedTagsIds,
      tags,
      selectTag,
      selectAllTags,
      deselectAllTags,
      resetSelectedTags,
      setSelectedTagsRecord,
    }),
    [
      deselectAllTags,
      resetSelectedTags,
      selectAllTags,
      selectTag,
      selectedTagsIds,
      selectedTagsRecord,
      tags,
      tagsIds,
    ],
  );
};

export default useSelectTags;
