export const PROGRESS_BAR_ITEMS_HEIGHT = 38;

export const updateSliderStyle = () => {
  const slickLists = document.getElementsByClassName('slick-list');
  for (let i = 0; i < slickLists?.length; i++) {
    if (!slickLists[i]) {
      break;
    }
    const slickList = slickLists[i] as HTMLElement;

    slickList.style.height = '65vh';
  }

  const slickTracks = document.getElementsByClassName('slick-track');
  for (let i = 0; i < slickTracks?.length; i++) {
    if (!slickTracks[i]) {
      break;
    }
    const slickTrack = slickTracks[i] as HTMLElement;

    slickTrack.style.height = '100%';
  }

  const slickSlides = document.getElementsByClassName('slick-slide');
  for (let i = 0; i < slickSlides?.length; i++) {
    if (!slickSlides[i]) {
      break;
    }
    const slickSlide = slickSlides[i] as HTMLElement;

    slickSlide.style.height = '100%';
    const slickSlideChild = slickSlide.firstChild as HTMLElement;
    slickSlideChild.style.height = '100%';
    slickSlideChild.style.paddingRight = '1rem';
    slickSlideChild.style.paddingLeft = '1rem';
  }
};
