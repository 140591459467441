import {ProductLabels} from '../mindance-libs/types/products';

export const MIND_MANAGER_PRODUCTS = [
  ProductLabels.DASHBOARD_SHOW_RELATED_SUBSCRIPTIONS,
  ProductLabels.EVENTTOOL_SHOW_RELATED_SUBSCRIPTIONS,
  ProductLabels.GBU_PSYCHE_SHOW_RELATED_SUBSCRIPTIONS,
  ProductLabels.NEWSTOOL_SHOW_RELATED_SUBSCRIPTIONS,
] as const;

export type MindManagerProductLabel = (typeof MIND_MANAGER_PRODUCTS)[number];

export const isMindManagerProductLabel = (
  label: any,
): label is MindManagerProductLabel => {
  return MIND_MANAGER_PRODUCTS.includes(label);
};
