import {useCallback, useMemo} from 'react';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import {RouteKeysQueryParams} from '../NavigationTypes';
import {RouteKeys} from '../RouteKeys';
import queryParamsHelper from '../queryParams';

const useTypedSearchParams = <K extends RouteKeys>(): [
  RouteKeysQueryParams[K],
  (params: RouteKeysQueryParams[K]) => void,
] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const parsedParams = useMemo<RouteKeysQueryParams[K]>(
    () => queryParamsHelper.parse(searchParams),
    [searchParams],
  );

  const updateSearchParams = useCallback<
    (params: RouteKeysQueryParams[K]) => void
  >(
    params => {
      const stringifiedParams = queryParamsHelper.stringify(params);
      setSearchParams(createSearchParams(stringifiedParams));
    },
    [setSearchParams],
  );

  return [parsedParams, updateSearchParams];
};

export default useTypedSearchParams;
