import React from 'react';
import {ActivityIndicator} from 'react-native-paper';
import styles from './LoadingIndicator.module.scss';
import colors from '../../mindance-libs/theme/colors';

const LoadingIndicator = () => (
  <div className={styles['loading-indicator']}>
    <ActivityIndicator color={colors.whiteAlpha70} size={40} />
  </div>
);

export default React.memo(LoadingIndicator);
