import * as R from 'ramda';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StyleProp, TouchableOpacity, View, ViewStyle} from 'react-native';
import NextArrowIcon from '../../../icons/NextArrowIcon';
import colors from '../../../theme/colors';
import {CustomTag} from '../../../types/common';
import TagItem from '../../TagItem';
import MyAreaEmpty from '../MyAreaEmpty';
import MyAreaError from '../MyAreaError';
import MyAreaLoading from '../MyAreaLoading';
import commonStyles from '../styles';
import {MyInterestsProps} from './props';
import styles from './styles';

const TAGS_LIMIT = 5;

const MyInterests: React.FC<MyInterestsProps> = ({
  tags,
  style,
  error,
  loading,
  empty,
  onPress,
}) => {
  const {t} = useTranslation();

  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [commonStyles.container, style],
    [style],
  );

  const TagsLabels = useMemo(() => {
    const difference = tags ? tags.length - TAGS_LIMIT : 0;

    const moreTag: CustomTag | undefined =
      difference >= 1
        ? {id: 'more-tag', label: t('common.count_more', {value: difference})}
        : undefined;

    const allTags = tags ? [...tags.slice(0, TAGS_LIMIT), moreTag] : undefined;
    const filteredTags = R.filter(R.isNotNil, allTags ?? []);

    const tagItems = filteredTags.map(tag => (
      <TagItem
        {...tag}
        key={tag.id}
        isSelected={false}
        mode="outlined"
        style={styles.tagContainer}
        labelStyle={styles.tagLabel}
      />
    ));

    return tagItems;
  }, [t, tags]);

  if (loading) {
    return <MyAreaLoading style={style} />;
  }

  if (error) {
    return <MyAreaError style={style} />;
  }

  if (empty) {
    return (
      <TouchableOpacity activeOpacity={0.8} style={style} onPress={onPress}>
        <MyAreaEmpty label={t('common.empty_state')} showNextArrow />
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={containerStyle}
      onPress={onPress}>
      <View style={styles.content}>
        <View style={styles.tagsWrapper}>{TagsLabels}</View>
        <NextArrowIcon stroke={colors.white} />
      </View>
    </TouchableOpacity>
  );
};

export default React.memo(MyInterests);
