import React, {useMemo} from 'react';
import {SvgProps} from 'react-native-svg';
import {StyleProp, ViewStyle} from 'react-native';
import MindanceBasicMiniColorfulLogo from '../../icons/MindanceBasicMiniColorfulLogo';
import MindanceEnterpriseMiniColorfulLogo from '../../icons/MindanceEnterpriseMiniColorfulLogo';
import MindanceMiniColorfulLogo from '../../icons/MindanceMiniColorfulLogo';
import MindancePremiumMiniColorfulLogo from '../../icons/MindancePremiumMiniColorfulLogo';
import {useAppContext} from '../../store/contexts/AppContext';
import {ServiceLevel} from '../../types/user';

const logoMap: Record<ServiceLevel | 'default', React.FC<SvgProps>> = {
  [ServiceLevel.BASIC]: MindanceBasicMiniColorfulLogo,
  [ServiceLevel.PREMIUM]: MindancePremiumMiniColorfulLogo,
  [ServiceLevel.ENTERPRISE]: MindanceEnterpriseMiniColorfulLogo,
  default: MindanceMiniColorfulLogo,
};

const DefaultLogo: React.FC<{size?: number; style?: StyleProp<ViewStyle>}> = ({
  size,
  style,
}) => {
  const {serviceLevel} = useAppContext();

  const LogoComponent = useMemo(
    () => logoMap[serviceLevel ?? 'default'],
    [serviceLevel],
  );

  return <LogoComponent height={size} style={style} />;
};

export default React.memo(DefaultLogo);
