import {Platform, StyleSheet} from 'react-native';
import spacings, {DEFAULT_BORDER_RADIUS} from '../../theme/spacings';
import colors from '../../theme/colors';
import typography from '../../theme/typography';

const containerStyle = {
  backgroundColor: colors.black90,
  borderRadius: DEFAULT_BORDER_RADIUS,
  borderWidth: 1,
  borderColor: colors.blueGreenGray,
  marginHorizontal: spacings.s,
  padding: spacings.xl,
};

const styles = StyleSheet.create({
  container: {
    ...containerStyle,
  },
  containerWeb: {
    ...containerStyle,
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -10%)',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  controlsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    ...typography.subtitle,
    color: colors.whiteAlpha95,
  },
  scrollView: {
    marginTop: spacings.l,
  },
  content: {
    flexGrow: 1,
  },
  submitButton: {
    marginTop: spacings.m,
    marginHorizontal: spacings.xxxxxxxl,
    alignSelf: Platform.select({default: undefined, web: 'center'}),
  },
  buttonContent: {
    paddingHorizontal: Platform.select({
      default: undefined,
      web: spacings.xxxl,
    }),
  },
  contentWeb: {
    paddingBottom: spacings.l,
  },
});

export default styles;
