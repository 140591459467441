import React, {useMemo} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import colors from '../../theme/colors';
import {CommonProps} from './props';
import styles from './styles';

const MyAreaLoading: React.FC<CommonProps> = ({style}) => {
  const centerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [styles.container, styles.center, style],
    [style],
  );

  return (
    <View style={centerStyle}>
      <ActivityIndicator color={colors.whiteAlpha75} size={40} />
    </View>
  );
};

export default React.memo(MyAreaLoading);
