import React, {useMemo} from 'react';
import {Insets, StyleProp, TouchableHighlight, ViewStyle} from 'react-native';
import InfoIcon from '../../icons/InfoIcon';
import colors from '../../theme/colors';
import styles from './styles';

type Props = {
  size?: number;
  iconSize?: number;
  iconColor?: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

const HIT_SLOP: Insets = {bottom: 10, left: 10, right: 10, top: 10};

const RoundInfoButton: React.FC<Props> = ({
  iconSize = 11,
  size = 20,
  iconColor = colors.white,
  onPress,
  style,
  testID,
}) => {
  const containerStyle = useMemo<StyleProp<ViewStyle>>(
    () => [
      styles.container,
      {width: size, height: size, borderRadius: size / 2},
      style,
    ],
    [size, style],
  );

  return (
    <TouchableHighlight
      style={containerStyle}
      onPress={onPress}
      underlayColor={colors.whiteAlpha10}
      hitSlop={HIT_SLOP}
      testID={testID}>
      <InfoIcon width={iconSize} height={iconSize} fill={iconColor} />
    </TouchableHighlight>
  );
};

export default React.memo(RoundInfoButton);
