import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {SingleValue} from 'react-select';
import Button from '../../../../components/Button/Button';
import {ButtonStyle} from '../../../../components/Button/Button.props';
import Input from '../../../../components/Input/Input';
import {InputPostfix} from '../../../../components/Input/Input.props';
import Select from '../../../../components/Select/Select';
import {
  SelectOptionType,
  SelectPrefixIcon,
} from '../../../../components/Select/Select.props';
import ScreenBase from '../../../../mindance-libs/components/ScreenBase';
import {useUpdateUserDataMutation} from '../../../../mindance-libs/generated/graphql';
import useErrorMessage from '../../../../mindance-libs/hooks/useErrorMessage';
import useLanguage from '../../../../mindance-libs/hooks/useLanguage';
import useOneFieldForm from '../../../../mindance-libs/hooks/useOneFieldForm';
import {useAppContext} from '../../../../mindance-libs/store/contexts/AppContext';
import {Languages} from '../../../../mindance-libs/types/common';
import {PASSWORD_MASK} from '../../../../mindance-libs/utils/constants';
import {
  firstNameFormValidationSchema,
  lastNameFormValidationSchema,
} from '../../../../mindance-libs/utils/formValidations';
import {isAppLanguage} from '../../../../mindance-libs/utils/i18next/helpers';
import {reportError} from '../../../../mindance-libs/utils/loggingHelpers';
import {RouteKeys} from '../../../../navigation/RouteKeys';
import useTypedNavigate from '../../../../navigation/typed/useTypedNavigate';
import styles from './page.module.scss';

export default function Page() {
  const {t, i18n} = useTranslation();
  const {user} = useAppContext();
  const {error, showMessage} = useErrorMessage();
  const {changeLanguage} = useLanguage();

  const navigate = useTypedNavigate();

  const [updateUserData] = useUpdateUserDataMutation({
    refetchQueries: 'active',
  });

  const backButton = useMemo(
    () => ({title: t('common.return') || '', onClick: () => navigate(-1)}),
    [navigate, t],
  );

  const updateFirstName = useCallback<(value: string) => Promise<void>>(
    async first_name => {
      try {
        await updateUserData({
          variables: {data: {first_name: first_name.trim()}},
        });
      } catch (e) {
        reportError('updateFirstName error', e);
        showMessage(t('errors.something_went_wrong'));
      }
    },
    [showMessage, t, updateUserData],
  );

  const updateLastName = useCallback<(value: string) => Promise<void>>(
    async last_name => {
      try {
        await updateUserData({
          variables: {data: {last_name: last_name.trim()}},
        });
      } catch (e) {
        reportError('updateLastName error', e);
        showMessage(t('errors.something_went_wrong'));
      }
    },
    [showMessage, t, updateUserData],
  );

  const updateUserLanguage = useCallback<
    (value: SingleValue<SelectOptionType<Languages | string>>) => Promise<void>
  >(
    async value => {
      const newLanguage = value?.value;
      try {
        if (isAppLanguage(newLanguage)) {
          await changeLanguage(newLanguage);
          await updateUserData({variables: {data: {language: newLanguage}}});
        }
      } catch (e) {
        reportError('updateUserLanguage error', e);
      }
    },
    [changeLanguage, updateUserData],
  );

  const {
    value: firstName,
    hasError: hasFirstNameError,
    onChange: setFirstName,
    onBlur: handleFirstNameBlur,
    isFocused: isFirstNameFocused,
    onFocus: handleFirstNameFocus,
    error: firstNameError,
  } = useOneFieldForm({
    initialValue: user?.FirstName ?? '',
    key: 'firstName',
    validation: firstNameFormValidationSchema,
    onBlur: updateFirstName,
    onError: showMessage,
  });

  const {
    value: lastName,
    hasError: hasLastNameError,
    onChange: setLastName,
    onBlur: handleLastNameBlur,
    isFocused: isLastNameFocused,
    onFocus: handleLastNameFocus,
    error: lastNameError,
  } = useOneFieldForm({
    initialValue: user?.LastName ?? '',
    key: 'lastName',
    validation: lastNameFormValidationSchema,
    onBlur: updateLastName,
    onError: showMessage,
  });

  const languageOptions = useMemo(
    () =>
      Object.values(Languages).map(lang => ({
        value: lang,
        label: t(`languages.${lang}`),
      })),
    [t],
  );

  const navigateToChangePasswordPage = useCallback(() => {
    navigate({to: RouteKeys.SettingsChangePassword, params: {}});
  }, [navigate]);

  return (
    <ScreenBase error={error}>
      <div className={styles.account__container}>
        <Button
          showLeftArrow
          button={backButton}
          className={styles.account__button}
          buttonStyle={ButtonStyle.INLINE_WHITE}
        />
        <h1>{t('settings.account_details')}</h1>

        <div className={styles['account__group-inputs']}>
          <Input
            className={styles['account__input-field']}
            label={t('auth.email')}
            placeholder={t('auth.email_placeholder')}
            defaultValue={user?.EMail ?? ''}
            disabled
          />
          <Input
            className={styles['account__input-field']}
            label={t('auth.password')}
            postfix={InputPostfix.EDIT}
            isSecure
            defaultValue={PASSWORD_MASK}
            onFocus={navigateToChangePasswordPage}
            onClick={navigateToChangePasswordPage}
          />
          <Input
            className={styles['account__input-field']}
            label={t('user.first_name')}
            postfix={isFirstNameFocused ? InputPostfix.EDIT : undefined}
            defaultValue={firstName}
            onChangeText={setFirstName}
            onBlur={handleFirstNameBlur}
            onFocus={handleFirstNameFocus}
            hasError={hasFirstNameError}
            error={firstNameError}
          />
          <Input
            className={styles['account__input-field']}
            label={t('user.last_name')}
            postfix={isLastNameFocused ? InputPostfix.EDIT : undefined}
            defaultValue={lastName}
            onChangeText={setLastName}
            onBlur={handleLastNameBlur}
            onFocus={handleLastNameFocus}
            hasError={hasLastNameError}
            error={lastNameError}
          />
          <Select
            className={styles['account__input-field']}
            label={t('user.language')}
            options={languageOptions}
            value={i18n.language}
            prefixIcon={SelectPrefixIcon.LANGUAGE}
            onChange={updateUserLanguage}
          />
        </div>
      </div>
    </ScreenBase>
  );
}
