import {StyleSheet} from 'react-native';
import colors from '../../theme/colors';
import typography from '../../theme/typography';

const styles = StyleSheet.create({
  text: {
    color: colors.white,
    ...typography.body,
  },
});

export default styles;
