import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Platform,
  TouchableHighlight,
  TouchableHighlightProps,
  View,
} from 'react-native';
import FamilienServiceIcon from '../../icons/FamilienServiceIcon';
import GlobalIcon from '../../icons/GlobalIcon';
import colors from '../../theme/colors';
import AppText from '../AppText';
import LinearSeparator from '../LinearSeparator';
import styles from './styles';

const MeinFSConnection: React.FC<TouchableHighlightProps> = ({
  onPress,
  style,
  ...props
}) => {
  const {t} = useTranslation();

  const containerStyle = useMemo(() => [styles.container, style], [style]);

  return (
    <TouchableHighlight
      style={containerStyle}
      underlayColor={colors.darkBlueAlpha90}
      onPress={onPress}
      {...props}>
      <>
        <View style={styles.textWrapper}>
          <AppText style={styles.label}>{t('webview.portal_title')}</AppText>
        </View>
        <LinearSeparator style={styles.linearSeparator} />
        <View style={styles.textWrapper}>
          <View>
            <AppText style={styles.label}>
              {t('webview.portal_description')}
            </AppText>
            <View style={styles.labelContainer}>
              {Platform.OS === 'web' ? (
                <GlobalIcon style={styles.globalIcon} />
              ) : null}
              <AppText style={styles.boldLabel}>
                {t('webview.portal_name')}
              </AppText>
            </View>
          </View>

          <FamilienServiceIcon />
        </View>
      </>
    </TouchableHighlight>
  );
};

export default React.memo(MeinFSConnection);
