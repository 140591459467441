import {
  faBars,
  faCheck,
  faChevronDown,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useSubscriptionsContext} from '../../contexts/SubscriptionsContext';
import useSidebarNavigationItems from '../../hooks/useSidebarNavigationItems';
import useLogout from '../../mindance-libs/hooks/useLogout';
import {ProductLabels} from '../../mindance-libs/types/products';
import Button from '../Button/Button';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import LocaleSwitcher from '../LocaleSwitcher/LocaleSwitcher';
import styles from './Sidebar.module.scss';
import SidebarProps from './Sidebar.props';
// import Image from '../Image/Image';
// import useCompanyConfig from '../../mindance-libs/hooks/useCompanyConfig';
import {useWindowDimensions} from 'react-native';
import useElementSize from '../../hooks/useElementSize';
import useCompanyConfig from '../../mindance-libs/hooks/useCompanyConfig';
import {useAppContext} from '../../mindance-libs/store/contexts/AppContext';
import {HOME_MIND_MANAGER_TOOLS_ROUTES} from '../../utils/products';
import SidebarNavigation from './SidebarNavigation/SidebarNavigation';
import DefaultLogo from '../../mindance-libs/components/DefaultLogo';

const HIDDEN_DROPDOWN_PRODUCTS = [
  ProductLabels.NEWSTOOL_SHOW_RELATED_SUBSCRIPTIONS,
];

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const {pathname} = useLocation();
  const {onLogout} = useLogout();
  const {
    selectedSubscriptionId,
    changeSelectedSubscriptionId,
    subscriptionsRecord,
    loading: subscriptionsLoading,
  } = useSubscriptionsContext();
  const {isMindManager} = useAppContext();
  const {sectionsData, loading} = useSidebarNavigationItems();

  const {getLogoUrl} = useCompanyConfig();

  const {
    onLoad: onSidebarLoad,
    ref: sidebarRef,
    size: sidebarSize,
  } = useElementSize<HTMLDivElement>();
  const {width: windowWidth} = useWindowDimensions();

  const isDropdownHidden =
    !isMindManager ||
    !!HIDDEN_DROPDOWN_PRODUCTS.find(productLabel => {
      const route = HOME_MIND_MANAGER_TOOLS_ROUTES[productLabel];
      return pathname.includes(route);
    }) ||
    !Object.values(subscriptionsRecord).length;

  const logoSize = useMemo(() => {
    const width =
      sidebarSize.width === windowWidth ? 100 : sidebarSize.width / 1.5;
    return {width, height: 60};
  }, [sidebarSize.width, windowWidth]);

  const logoUrl = getLogoUrl(logoSize);

  const [menuOpened, setMenuOpened] = useState(false);
  const toggleMenuOpened = useCallback(
    () => setMenuOpened(current => !current),
    [],
  );
  const closeMenu = useCallback(() => setMenuOpened(false), []);

  const [subListExpanded, setSubListExpanded] = useState(false);
  const toggleSubList = () => setSubListExpanded(current => !current);

  const selectedSubscription = selectedSubscriptionId
    ? subscriptionsRecord[selectedSubscriptionId]
    : null;

  const mergedStyle = classNames(
    styles.sidebar,
    {[styles['sidebar--expanded']]: menuOpened},
    props.className,
  );

  // close the menu when the pathname is changed
  useEffect(() => {
    setMenuOpened(false);
  }, [pathname]);

  useEffect(() => {
    menuOpened
      ? document.body.classList.add(styles['custom-body'])
      : document.body.classList.remove(styles['custom-body']);

    return () => {
      document.body.classList.remove(styles['custom-body']);
    };
  }, [menuOpened]);

  useEffect(() => {
    window.addEventListener('resize', closeMenu);

    return () => {
      window.removeEventListener('resize', closeMenu);
    };
  }, [closeMenu]);

  return (
    <aside
      className={mergedStyle}
      data-testid={props.testID}
      ref={sidebarRef}
      onLoad={onSidebarLoad}>
      <div
        className={classNames(styles.sidebar__header, {
          [styles['sidebar__header--expanded']]: menuOpened,
        })}>
        <Button className={styles.sidebar__logo} {...props.logo.link}>
          {logoUrl ? (
            <img
              src={logoUrl}
              className={styles.sidebar__logo__image}
              style={logoSize}
            />
          ) : (
            <DefaultLogo />
          )}
        </Button>

        <FontAwesomeIcon
          className={styles['sidebar__menu-icon']}
          icon={menuOpened ? faXmark : faBars}
          onClick={toggleMenuOpened}
        />
      </div>
      <div
        className={classNames(styles.sidebar__menu, {
          [styles['sidebar__menu--expanded']]: menuOpened,
        })}>
        {subscriptionsLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <SidebarNavigation loading={loading} sectionsData={sectionsData} />

            <div className={styles.sidebar__spacer} />

            <LocaleSwitcher />

            {!isDropdownHidden ? (
              <div className={styles['sidebar__sub-list']}>
                <div
                  className={classNames(styles['sidebar__sub-list-dropdown'], {
                    [styles['sidebar__sub-list-dropdown--expanded']]:
                      subListExpanded,
                  })}>
                  <div className={styles['sidebar__sub-list-dropdown-inner']}>
                    {Object.values(subscriptionsRecord).map(item => {
                      const isCurrent = item.id === selectedSubscriptionId;

                      const selectSubscription = () => {
                        changeSelectedSubscriptionId(item.id);
                        setSubListExpanded(false);
                      };

                      return (
                        <Button
                          key={item.id}
                          button={{
                            onClick: selectSubscription,
                            className:
                              styles['sidebar__sub-list-dropdown-item'],
                          }}>
                          <p
                            className={
                              styles['sidebar__sub-list-dropdown-item-label']
                            }>
                            {item.Label}
                          </p>
                          {isCurrent && (
                            <FontAwesomeIcon
                              icon={faCheck}
                              className={
                                styles['sidebar__sub-list-dropdown-item-check']
                              }
                            />
                          )}
                        </Button>
                      );
                    })}
                  </div>
                </div>

                <Button
                  button={{
                    className: styles['sidebar__selected-sub'],
                    onClick: toggleSubList,
                  }}>
                  <div id="spacer" />
                  <p className={styles['sidebar__selected-sub-title']}>
                    {selectedSubscription?.Label}
                  </p>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={classNames(
                      styles['sidebar__selected-sub-chevron'],
                      {
                        [styles['sidebar__selected-sub-chevron--expanded']]:
                          subListExpanded,
                      },
                    )}
                  />
                </Button>
              </div>
            ) : null}

            <div className={styles.sidebar__footer}>
              {/* <Button
                {...props.profile.link}
                className={styles['sidebar__profile-button']}>
                <Image
                  src={logoUrl}
                  className={styles['sidebar__profile-image']}
                  alt={props.profile.image.alt}
                  width={props.profile.image.width}
                  height={props.profile.image.height}
                />
              </Button> */}
              <Button
                button={{
                  onClick: onLogout,
                  title: props.logoutButton.button?.title,
                }}
                className={styles['sidebar__logout-button']}
              />
            </div>
          </>
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
